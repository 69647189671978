import { gql, useQuery } from "@apollo/client";
import { FieldError } from "react-hook-form";

import { PlanIdDropdownQuery } from "../../__generated__/graphql";
import IdDropdown from "../../common/IdDropdown";

interface PlanIdDropdownProps {
  value: string;
  fieldError?: FieldError;
  width?: string;
  onChange: (value: string) => void;
}

const PlanIdDropdown: React.FunctionComponent<PlanIdDropdownProps> = ({
  value,
  fieldError,
  width,
  onChange,
}) => {
  const { data, loading } = useQuery<PlanIdDropdownQuery>(gql`
    query PlanIdDropdownQuery {
      platform_plan {
        platform_id
        name
        code
        platform_product {
          name
        }
      }
    }
  `);

  const ids = (data?.platform_plan || []).map((plan) => ({
    id: plan.platform_id,
    label: plan.name,
    displayId: plan.code || undefined,
    displayLabel: plan.code
      ? `${plan.name} (${plan.platform_id})`
      : !plan.name && !!plan.platform_product?.name
      ? plan.platform_product.name
      : undefined,
  }));

  return (
    <IdDropdown
      ids={ids}
      value={value}
      onChange={onChange}
      isLoading={loading}
      placeholder="Select a plan…"
      fieldError={fieldError}
      width={width}
    />
  );
};

export default PlanIdDropdown;
