import gql from "graphql-tag";

import FlowPlatformEcommerceProductFragment from "./FlowPlatformEcommerceProductFragment";

export default gql`
  fragment FlowPlatformConnectionFragment on platform_connection {
    platform
    platform_ecommerce_products(limit: 50) {
      ...FlowPlatformEcommerceProductFragment
    }
  }
  ${FlowPlatformEcommerceProductFragment}
`;
