import gql from "graphql-tag";

export default gql`
  fragment CampaignVersionFragment on campaign_version {
    id
    campaign_id
    offer_rule_group_id
    trigger_type
    trigger_url
    delay
    css
    collect_payment
    redirect_url
  }
`;
