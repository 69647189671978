import "react-app-polyfill/stable";
import "tailwindcss/tailwind.css";
import "./styles";
import "./ms";
import "@stripe/stripe-js";

import React from "react";
import ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import { Router } from "react-router";
import { ToastProvider } from "react-toast-notifications";

import history from "./app/history";
import DefaultToast from "./common/DefaultToast";
import env from "./common/env";
import ErrorBoundary from "./features/errors/ErrorBoundary";
import IndexRoutes from "./IndexRoutes";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary env={env("NODE_ENV")}>
      <HelmetProvider>
        <ToastProvider
          autoDismiss={true}
          transitionDuration={200}
          components={{ Toast: DefaultToast }}
          placement="top-center"
        >
          <Router history={history}>
            <IndexRoutes />
          </Router>
        </ToastProvider>
      </HelmetProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
