import gql from "graphql-tag";

import AppOfferFragment from "../fragments/AppOfferFragment";

export default gql`
  query ExistingOffersQuery {
    offer(where: { deleted_at: { _is_null: true } }) {
      ...AppOfferFragment
    }
  }
  ${AppOfferFragment}
`;
