import gql from "graphql-tag";

import SegmentConditionGroupEntryFragment from "./SegmentConditionGroupEntryFragment";

export default gql`
  fragment PrimarySegmentConditionGroupFragment on segment_condition_group {
    id
    boolean_operator
    segment_condition_group_entries(order_by: { position: asc }) {
      ...SegmentConditionGroupEntryFragment
    }
  }
  ${SegmentConditionGroupEntryFragment}
`;
