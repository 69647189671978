import { getWeeksInMonth } from "@internationalized/date";
import { useLocale } from "@react-aria/i18n";
import { range } from "lodash";
import { useCalendarGrid } from "react-aria";
import { CalendarState } from "react-stately";

import CalendarCell from "./CalendarCell";

interface CalendarGridProps {
  state: CalendarState;
}

const CalendarGrid: React.FunctionComponent<CalendarGridProps> = ({
  state,
}) => {
  const { locale } = useLocale();
  const { gridProps, weekDays } = useCalendarGrid({}, state);

  return (
    <div tw="px-3 py-2 w-[374px] h-[348px]">
      <div {...gridProps} tw="grid grid-cols-7 gap-2 auto-rows-min">
        {weekDays.map((day, i) => (
          <div
            key={i}
            tw="flex items-center justify-center h-[2.5rem] font-semibold"
            className="calendar__grid__day"
          >
            {day}
          </div>
        ))}
        {range(getWeeksInMonth(state.visibleRange.start, locale)).map((week) =>
          state
            .getDatesInWeek(week)
            .map((date, i) =>
              date ? (
                <CalendarCell key={i} state={state} date={date} />
              ) : (
                <td key={i} />
              )
            )
        )}
      </div>
    </div>
  );
};

export default CalendarGrid;
