import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import tw from "twin.macro";

interface ExpanderProps {
  title?: string;
  defaultExpanded?: boolean;
  expandDisabled?: boolean;
  className?: string;
}

const Expander: React.FunctionComponent<ExpanderProps> = ({
  title,
  defaultExpanded,
  expandDisabled,
  className,
  children,
}) => {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);

  return (
    <div tw="w-full" className={className}>
      {!!title && (
        <div tw="w-full border-b border-divider py-2">
          {!expandDisabled ? (
            <button
              type="button"
              onClick={() => setIsExpanded(!isExpanded)}
              tw="text-left ml-[-5px]"
            >
              <FontAwesomeIcon
                icon={isExpanded ? faChevronDown : faChevronRight}
                fixedWidth
                transform="shrink-4"
              />{" "}
              <span tw="font-bold">{title}</span>
            </button>
          ) : (
            <span tw="font-bold">{title}</span>
          )}
        </div>
      )}
      <div
        css={[
          tw`overflow-hidden transition-all duration-200`,
          isExpanded ? tw`overflow-visible h-auto` : tw`h-0`,
        ]}
      >
        {children}
      </div>
    </div>
  );
};

export default Expander;
