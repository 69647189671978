import { Editor, Transforms } from "slate";

import { OFFER_TAG, OfferTag } from "./types";

export default function insertOfferTag(editor: Editor, tag: OfferTag) {
  Transforms.insertNodes(editor, {
    type: OFFER_TAG,
    tag,
    children: [{ text: "" }],
  });
  Transforms.move(editor);
}
