import { ApolloProvider } from "@apollo/client";
import { Route, Switch } from "react-router-dom";

import { createApolloClient } from "../../../app/apollo";
import CustomerPortalHome from "./CustomerPortalHome";
import Layout from "./Layout";
import Login from "./Login";

const CustomerPortalRoutes: React.FunctionComponent = () => (
  <Layout>
    <Switch>
      <Route
        path="/p/portal/:clientId/session/:sessionToken"
        render={(props) =>
          props.match.params["sessionToken"] ? (
            <ApolloProvider
              client={createApolloClient(
                undefined,
                {
                  "X-Hasura-Campaign-Token": "",
                  "X-Hasura-Flow-Token": "",
                  "X-Hasura-Portal-Token": props.match.params["sessionToken"],
                },
                props.match.params["sessionToken"]
              )}
            >
              <CustomerPortalHome />
            </ApolloProvider>
          ) : null
        }
      />
      <Route
        path="/p/portal/:clientId/:sessionStatus?"
        render={(props) => (
          <ApolloProvider
            client={createApolloClient(undefined, {
              "X-Hasura-Campaign-Token": "",
              "X-Hasura-Flow-Token": "",
              "X-Hasura-Portal-Token": props.match.params["clientId"],
            })}
          >
            <Login />
          </ApolloProvider>
        )}
      />
    </Switch>
  </Layout>
);

export default CustomerPortalRoutes;
