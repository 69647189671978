import gql from "graphql-tag";

import FlowQuestionSettingVersionFragment from "./FlowQuestionSettingVersionFragment";

export default gql`
  fragment FlowQuestionSettingFragment on question_setting {
    question_id
    key
    published_version {
      ...FlowQuestionSettingVersionFragment
    }
    draft_version @include(if: $includeDrafts) {
      ...FlowQuestionSettingVersionFragment
    }
  }
  ${FlowQuestionSettingVersionFragment}
`;
