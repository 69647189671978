import {
  faAngleDown,
  faAngleRight,
  faPlus,
  faTimesCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import tw, { styled, theme } from "twin.macro";

import {
  language_enum,
  OfferPlatformEcommerceProductsQuery,
  platform_enum,
} from "../../__generated__/graphql";
import EditorSelector from "../../common/editor2/EditorSelector";
import Button from "../../common/form/Button";
import FieldCheckboxInput from "../../common/form/FieldCheckboxInput";
import FieldError from "../../common/form/FieldError";
import FieldHint from "../../common/form/FieldHint";
import FieldInput from "../../common/form/FieldInput";
import FieldLabel from "../../common/form/FieldLabel";
import FieldRow from "../../common/form/FieldRow";
import FieldRowBlock from "../../common/form/FieldRowBlock";
import FieldSetTitle from "../../common/form/FieldSetTitle";
import FormMode from "../../common/form/FormMode";
import ImageInput from "../../common/form/input/ImageInput";
import SelectInput from "../../common/form/input/SelectInput";
import TagsInput from "../../common/form/input/TagsInput";
import TextInput from "../../common/form/input/TextInput";
import useTranslatableForm, {
  TranslatedForms,
} from "../../common/form/useTranslatableForm";
import HelpIcon from "../../common/HelpIcon";
import IdDropdown from "../../common/IdDropdown";
import MetadataInput from "../../common/MetadataInput";
import PillRadio from "../../common/PillRadio";
import { useTranslations } from "../../common/translations/TranslationsProvider";
import useAccountFeatures from "../../common/useAccountFeatures";
import useFocusFirstEmptyInput from "../../common/useFocusFirstEmptyInput";
import LanguageRadio from "../flow/edit/LanguageRadio";
import getDefaultFormValues from "./forms/lib/getDefaultFormValues";
import getTranslatableFields from "./forms/lib/getTranslatableFields";
import { ProductSwapOfferFormValues } from "./forms/types";
import useValidateHeadline from "./lib/useValidateHeadline";
import useValidateName from "./lib/useValidateName";
import validateMetadata from "./lib/validateMetadata";
import ProductSwapOfferVariants from "./ProductSwapOfferVariants";

interface ProductSwapOfferFormProps {
  mode: FormMode;
  onChange: (values: TranslatedForms<ProductSwapOfferFormValues>) => void;
  onSubmit: (values: TranslatedForms<ProductSwapOfferFormValues>) => void;
  initialValues?: TranslatedForms<ProductSwapOfferFormValues>;
  onChangeEditingLanguage: (language: language_enum) => void;
  isSubmitting: boolean;
  products:
    | OfferPlatformEcommerceProductsQuery["platform_ecommerce_product"]
    | undefined;
  tags: string[];
  platform: platform_enum;
}

const ProductsWrapper = styled.div`
  display: flex;
  padding: 0.875rem 0rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.9375rem;
  align-self: stretch;
`;

const ProductWrapper = styled.div<{
  collapsed: boolean;
}>`
  ${tw`bg-gray-50 border border-gray-100`}
  display: flex;
  padding: 0.625rem 0.75rem 0.75rem 0.75rem;
  flex-direction: column;
  align-items: flex-start;
  gap: ${(props) => (props.collapsed ? "0" : "0.625rem")};
  align-self: stretch;
  border-radius: 0.375rem;
  box-shadow: 0px 2px 2px 0px rgba(177, 177, 177, 0.1);
`;

const ProductHeader = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.625rem;
  align-self: stretch;
`;

const ProductTitle = styled.div`
  flex: 1 0 0;
  color: var(--Gray-1, #333);
  font-family: Source Sans Pro;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem; /* 150% */
`;

const CollapsibleProduct = styled.div<{
  collapsed: boolean;
}>`
  display: ${(props) => (props.collapsed ? "none" : "block")};
  width: 100%;
`;

const CollapsibleInner = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 0.625rem;
`;

const ProductSwapOfferForm: React.FunctionComponent<
  ProductSwapOfferFormProps
> = ({
  mode,
  onChange,
  onSubmit,
  initialValues,
  onChangeEditingLanguage,
  isSubmitting,
  products,
  tags,
  platform,
}) => {
  const { defaultLanguage, enabledLanguages } = useTranslations();
  const defaultValues =
    initialValues ||
    getDefaultFormValues<ProductSwapOfferFormValues>({
      type: "product_swap",
      enabledLanguages,
      platform,
      products,
    });

  const {
    register,
    watch,
    formState,
    handleSubmit,
    control,
    editingLanguage,
    setEditingLanguage,
    forms,
    trigger,
    setValue,
  } = useTranslatableForm<ProductSwapOfferFormValues>({
    defaultValues,
    initialLanguage: defaultLanguage,
    languages: enabledLanguages,
    defaultLanguage,
    translatableFields: getTranslatableFields("product_swap"),
  });
  const [formRef, setFormRef] = useState<HTMLFormElement | null>(null);
  useFocusFirstEmptyInput(formRef);
  const validateName = useValidateName(formState.dirtyFields.name || false);
  const values = watch();
  const validateHeadline = useValidateHeadline(values.image);
  const [productMode, setProductMode] = useState<string>("any");
  const { features } = useAccountFeatures();

  const {
    fields: productFields,
    append: productFieldsAppend,
    replace: productFieldsReplace,
    update: productFieldsUpdate,
    remove: productFieldsRemove,
  } = useFieldArray({
    control,
    name: "products",
  });
  const watchProductFields = watch("products");

  const [collapsedState, setCollapsedState] = useState<boolean[]>([false]);

  useEffect(() => {
    if (
      mode === "edit" &&
      initialValues &&
      initialValues[defaultLanguage]?.specificProducts
    ) {
      setCollapsedState(
        initialValues[defaultLanguage]?.products.map((p) => true) || [false]
      );
    }
  }, [mode, initialValues, defaultLanguage]);

  useEffect(() => {
    if (mode === "edit" && initialValues) {
      setProductMode(
        !initialValues[defaultLanguage]?.specificProducts ? "any" : "specific"
      );
    }
  }, [initialValues, defaultLanguage, mode]);

  useEffect(() => {
    onChange(forms);
  }, [forms, onChange]);

  useEffect(() => {
    if (mode !== "create") {
      return;
    }

    if (productMode === "any") {
      setValue("specificProducts", false);
      productFieldsReplace(
        products?.map((product) => ({
          productId: product.platform_id,
          name: product.name || "Product",
          imageUrl: product.image_url || null,
          discountAmount:
            product.platform_plan_ecommerce_products[0]?.discount_amount || "0",
          discountType:
            product.platform_plan_ecommerce_products[0]?.discount_type || null,
          variants: product.platform_variants.map((variant) => ({
            variantId: variant.platform_id,
            name: variant.name,
            price: variant.price ? Number(variant.price) : 0,
            value: true,
            options: variant.platform_variant_options.map((option) => ({
              key: option.key,
              value: option.value,
            })),
          })),
        })) || []
      );
    } else {
      setValue("specificProducts", true);
      productFieldsReplace([
        {
          productId: null,
          name: "Product",
          imageUrl: null,
          discountAmount: "0",
          discountType: null,
          variants: [],
        },
      ]);
    }
  }, [mode, productFieldsReplace, productMode, products, setValue]);

  const getVariants = (productId: string) => {
    const x = products?.find((product) => product.platform_id === productId);
    return x;
  };

  return (
    <form
      id="create-offer"
      onChange={() => onChange(forms)}
      ref={(ref) => setFormRef(ref)}
      onSubmit={handleSubmit(onSubmit)}
    >
      <fieldset disabled={isSubmitting}>
        <input {...register("type")} type="hidden" value="coupon" />
        <FieldRow>
          <FieldLabel>
            <label htmlFor="name">Name</label>
          </FieldLabel>
          <FieldInput>
            <TextInput
              {...register("name", {
                required: true,
                validate: validateName,
              })}
              id="name"
              width="full"
              fieldError={formState.errors.name}
            />
            <FieldError error={formState.errors.name} />
            {platform !== "custom" && (
              <FieldHint>May appear on receipts and invoices.</FieldHint>
            )}
          </FieldInput>
        </FieldRow>
        <FieldRow>
          <FieldLabel>Tags</FieldLabel>
          <FieldInput>
            <Controller
              control={control}
              name="tags"
              render={({ field }) => (
                <TagsInput
                  tags={tags}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </FieldInput>
        </FieldRow>
        <FieldRow>
          <FieldLabel>
            <label htmlFor="style">Style</label>
          </FieldLabel>
          <FieldInput>
            <SelectInput {...register("style")} id="style" defaultValue="modal">
              <option value="modal">Modal</option>
              <option value="step">Step</option>
            </SelectInput>
            <FieldHint>
              {values.style === "modal" ? (
                <>Offer will be shown in a pop-up modal dialog.</>
              ) : (
                <>Offer will be shown as a full flow step.</>
              )}
            </FieldHint>
          </FieldInput>
        </FieldRow>
        <FieldRow>
          <FieldLabel>
            <label>
              Allow override{" "}
              <HelpIcon content="By default, an offer will not be displayed to a subscriber if they have previously accepted another offer. This setting will allow this offer to override any previously accepted offers." />
            </label>
          </FieldLabel>
          <FieldCheckboxInput>
            <input
              {...register("allowOverride")}
              id="allowOverride"
              type="checkbox"
            />
            <label htmlFor="allowOverride">Override other offers</label>
          </FieldCheckboxInput>
        </FieldRow>
        <fieldset disabled={mode === "edit"}>
          <FieldSetTitle>Product swap details</FieldSetTitle>
          <>
            <PillRadio
              value={productMode}
              options={[
                {
                  label: "Any available",
                  value: "any",
                  disabled: mode === "edit",
                },
                {
                  label: "Specific products",
                  value: "specific",
                  disabled: mode === "edit",
                },
              ]}
              onChange={(value) => setProductMode(value)}
              tw="mt-4 mb-2"
            />
            {productMode === "any" ? (
              <FieldHint>
                Subscription platform will present all available products
              </FieldHint>
            ) : (
              <FieldHint>Choose the products presented in this offer</FieldHint>
            )}
          </>
          {productMode === "specific" && (
            <>
              <ProductsWrapper>
                {productFields.map((item, index) => (
                  <ProductWrapper
                    key={item.id}
                    collapsed={collapsedState[index]}
                  >
                    {/* header */}
                    <ProductHeader
                      onClick={() => {
                        setCollapsedState(
                          collapsedState.map((collapsed, i) =>
                            index === i ? !collapsed : collapsed
                          )
                        );
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <FontAwesomeIcon
                        icon={
                          collapsedState[index] ? faAngleRight : faAngleDown
                        }
                        transform={{ y: 5 }}
                        tw="w-4"
                      />
                      <ProductTitle>Product {index + 1}</ProductTitle>
                      {mode === "create" && (
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          style={{
                            opacity: 0.7,
                            cursor: mode === "create" ? "pointer" : "default",
                            color: `var(--Red, ${theme`colors.red.DEFAULT`})`,
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            if (mode === "create") {
                              productFieldsRemove(index);
                              setCollapsedState(
                                collapsedState.filter(
                                  (collapsed, i) => index !== i
                                )
                              );
                            }
                          }}
                        />
                      )}
                    </ProductHeader>
                    <CollapsibleProduct collapsed={collapsedState[index]}>
                      <CollapsibleInner>
                        <div tw="bg-white">
                          <Controller
                            control={control}
                            {...register(
                              `products.${index}.productId` as const
                            )}
                            rules={{ required: true }}
                            render={({ field }) => (
                              <IdDropdown
                                displayImages={true}
                                ids={(products || [])
                                  .filter((product) => {
                                    for (const [
                                      usedIndex,
                                      usedProduct,
                                    ] of watchProductFields.entries()) {
                                      if (usedIndex === index) {
                                        continue;
                                      }

                                      if (
                                        usedProduct.productId ===
                                        product.platform_id
                                      ) {
                                        return false;
                                      }
                                    }
                                    return true;
                                  })
                                  .map((plan) => ({
                                    id: plan.platform_id,
                                    label: plan.name,
                                    img: plan.image_url || undefined,
                                  }))}
                                value={field.value || ""}
                                onChange={(v) => {
                                  const matchingProduct = products?.find(
                                    (p) => p.platform_id === v
                                  );
                                  productFieldsUpdate(index, {
                                    productId:
                                      matchingProduct?.platform_id || null,
                                    name: matchingProduct?.name || "Product",
                                    imageUrl:
                                      matchingProduct?.image_url || null,
                                    discountAmount:
                                      matchingProduct
                                        ?.platform_plan_ecommerce_products[0]
                                        ?.discount_amount || "0",
                                    discountType:
                                      matchingProduct
                                        ?.platform_plan_ecommerce_products[0]
                                        ?.discount_type || null,
                                    variants:
                                      matchingProduct?.platform_variants.map(
                                        (v) => ({
                                          variantId: v.platform_id,
                                          name: v.name,
                                          price: v.price ? Number(v.price) : 0,
                                          value: true,
                                          options:
                                            v.platform_variant_options.map(
                                              (o) => ({
                                                key: o.key,
                                                value: o.value,
                                              })
                                            ),
                                        })
                                      ) || [],
                                  });

                                  field.onChange(v);
                                }}
                                width="100%"
                                placeholder="Select a product…"
                                tw="h-[3.375rem] bg-white"
                              />
                            )}
                          />
                        </div>

                        {/* variants */}
                        {(getVariants(watchProductFields[index].productId || "")
                          ?.platform_variants?.length || 0) > 1 && (
                          <ProductSwapOfferVariants
                            key={watchProductFields[index].productId || ""}
                            mode={mode}
                            variants={
                              getVariants(
                                watchProductFields[index].productId || ""
                              )?.platform_variants
                            }
                            initialValues={
                              initialValues
                                ? initialValues[defaultLanguage]?.products.find(
                                    (p) =>
                                      p.productId ===
                                      watchProductFields[index].productId
                                  )?.variants
                                : null
                            }
                            control={control}
                            register={register}
                            setValue={setValue}
                            watch={watch}
                            productIndex={index}
                          />
                        )}
                      </CollapsibleInner>
                    </CollapsibleProduct>
                    {collapsedState[index] && (
                      <div tw="flex gap-[0.375rem]">
                        <span tw="text-gray-500 text-sm">
                          {item.productId ? item.name : "None selected"}
                        </span>
                        {(getVariants(watchProductFields[index].productId || "")
                          ?.platform_variants?.length || 0) > 1 && (
                          <>
                            <span tw="text-gray-500 text-sm"> | </span>
                            <span tw="text-gray-500 text-sm">
                              {
                                watchProductFields
                                  .find((p) => p.productId === item.productId)
                                  ?.variants.filter((v) => v.value).length
                              }{" "}
                              variants selected
                            </span>
                          </>
                        )}
                      </div>
                    )}
                  </ProductWrapper>
                ))}
              </ProductsWrapper>
              <div>
                <Button
                  tw="py-1 leading-[0]"
                  type="button"
                  buttonType="alternate-secondary"
                  size="sm"
                  onClick={() => {
                    for (const product of watchProductFields) {
                      if (product.productId === null) {
                        return; // Don't add new product if there's an empty one
                      }
                    }

                    productFieldsAppend({
                      productId: null,
                      name: "Product",
                      imageUrl: null,
                      discountAmount: "0",
                      discountType: null,
                      variants: [],
                    });

                    setCollapsedState([...collapsedState, false]);
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} /> Add product
                </Button>
              </div>
            </>
          )}
        </fieldset>
        <FieldSetTitle>Offer {values.style}</FieldSetTitle>
        <FieldRow css={tw`hidden`}>
          <FieldLabel>
            <label>Banner</label>
          </FieldLabel>
          <FieldCheckboxInput>
            <input
              {...register("showBanner")}
              id="showBanner"
              type="checkbox"
            />
            <label htmlFor="showBanner">Show "Special Offer" banner</label>
          </FieldCheckboxInput>
        </FieldRow>
        {enabledLanguages.length > 1 && (
          <LanguageRadio
            value={editingLanguage}
            languages={enabledLanguages}
            defaultLanguage={defaultLanguage}
            onChange={(language) => {
              setEditingLanguage(language);
              onChangeEditingLanguage(language);
            }}
          />
        )}
        <FieldRow>
          <FieldLabel>
            <label htmlFor="headline">Headline</label>
          </FieldLabel>
          <FieldInput>
            <TextInput
              {...register("headline", { validate: validateHeadline })}
              id="headline"
              width="full"
              fieldError={formState.errors.headline}
            />
            <FieldError error={formState.errors.headline} />
          </FieldInput>
        </FieldRow>
        <FieldRow>
          <FieldLabel>
            <label>Image</label>
          </FieldLabel>
          <FieldInput>
            <Controller
              control={control}
              name="image"
              render={({ field }) => (
                <ImageInput
                  value={field.value}
                  onChange={(logoUrl) => {
                    field.onChange(logoUrl);
                    if (formState.isSubmitted) {
                      trigger();
                    }
                  }}
                />
              )}
            />
          </FieldInput>
        </FieldRow>
        <FieldRowBlock>
          <FieldLabel>
            <label>Content</label>
          </FieldLabel>
          <Controller
            control={control}
            name="content"
            render={({ field }) => {
              const form = forms[editingLanguage];
              if (!form) {
                return <></>;
              }

              return (
                <EditorSelector
                  initialValueKey={editingLanguage}
                  isOfferContent
                  hasCustomProperties={!!features.custom_properties}
                  format={form.contentFormat}
                  value={form.content}
                  onChange={(value) => {
                    field.onChange(value);
                    onChange({
                      ...forms,
                      [editingLanguage]: {
                        ...forms[editingLanguage],
                        content: value,
                      },
                    });
                  }}
                />
              );
            }}
          />
        </FieldRowBlock>
        <FieldRow>
          <FieldLabel>
            <label>Button text</label>
          </FieldLabel>
          <FieldInput>
            <TextInput
              {...register("buttonText", { required: true })}
              id="buttonText"
              width="md"
              fieldError={formState.errors.buttonText}
            />
          </FieldInput>
        </FieldRow>
        <FieldSetTitle>Metadata</FieldSetTitle>
        <FieldRow>
          <FieldInput>
            <Controller
              control={control}
              name="metadata"
              render={({ field }) => (
                <MetadataInput value={field.value} onChange={field.onChange} />
              )}
              rules={{
                validate: validateMetadata,
              }}
            />
            <FieldError error={formState.errors.metadata} />
          </FieldInput>
        </FieldRow>
      </fieldset>
    </form>
  );
};

export default ProductSwapOfferForm;
