import { gql } from "@apollo/client";
import { Suspense, useCallback, useEffect, useState } from "react";
import React from "react";
import { Route, Switch } from "react-router-dom";
import { createGlobalStyle } from "styled-components";

import {
  IndexRoutesDomainRouteQuery,
  IndexRoutesDomainRouteQueryVariables,
} from "./__generated__/graphql";
import { createApolloClient } from "./app/apollo";
import { Auth0Provider } from "./app/auth0";
import history from "./app/history";
import UserClaimsProvider from "./app/UserClaimsProvider";
import env from "./common/env";
import Spinner from "./common/Spinner";
import PublicRoutes from "./features/public/PublicRoutes";

const handleRedirectCallback = (appState: any) => {
  history.push(appState?.targetUrl || "/");
};

const GlobalStyle = createGlobalStyle`
  body, html, #root {
    height: 100%;
  }
`;

const App = React.lazy(() => import("./app/App"));

const IndexRoutes: React.FunctionComponent = () => {
  const [ready, setReady] = useState(false);

  const checkDomain = useCallback(async (domain: string) => {
    // Check if this is a custom domain.
    const client = createApolloClient(undefined, {
      "X-Hasura-Campaign-Token": "",
      "X-Hasura-Flow-Token": "",
      "X-Hasura-Portal-Token": "",
    });

    const result = await client.query<
      IndexRoutesDomainRouteQuery,
      IndexRoutesDomainRouteQueryVariables
    >({
      query: gql`
        query IndexRoutesDomainRouteQuery($input: DomainRouteInput!) {
          domainRoute(input: $input) {
            clientId
          }
        }
      `,
      variables: {
        input: {
          domain,
        },
      },
    });

    if (result.data.domainRoute.clientId) {
      history.replace(`/p/flow/${result.data.domainRoute.clientId}/session`);
      setReady(true);
    } else {
      history.replace("/not-found");
    }
  }, []);

  useEffect(() => {
    if (ready) {
      return;
    }

    const appUrl = new URL(env("REACT_APP_APP_URL"));
    const hostname = window.location.hostname;

    if (
      appUrl.hostname !== hostname &&
      !window.location.pathname.startsWith("/p/")
    ) {
      checkDomain(hostname);
    } else {
      setReady(true);
    }
  }, [checkDomain, ready]);

  return !ready ? (
    <>
      <GlobalStyle />
      <Spinner />
    </>
  ) : (
    <Switch>
      <Route path="/p/">
        <PublicRoutes />
      </Route>
      <Route>
        <Auth0Provider
          domain={env("REACT_APP_AUTH0_DOMAIN")}
          client_id={env("REACT_APP_AUTH0_CLIENT_ID")}
          audience={env("REACT_APP_JWT_AUDIENCE", "hasura")}
          onRedirectCallback={handleRedirectCallback}
          useRefreshTokens={true}
          cacheLocation="localstorage"
        >
          <UserClaimsProvider>
            <Suspense
              fallback={
                <>
                  <GlobalStyle />
                  <Spinner />
                </>
              }
            >
              <App />
            </Suspense>
          </UserClaimsProvider>
        </Auth0Provider>
      </Route>
    </Switch>
  );
};

export default IndexRoutes;
