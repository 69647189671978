import { faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { lighten } from "polished";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import tw, { styled, theme } from "twin.macro";

import { logoHeight } from "../../../features/public/flow/lib/variables";
import env from "../../env";
import { useRequiredUserClaims } from "../../PrivateRoute";

const Wrapper = styled.div`
  ${tw`flex`}
  button {
    ${tw`ml-2 text-red-500 opacity-75 hover:opacity-100 focus:outline-none`}

    fieldset:disabled & {
      ${tw`pointer-events-none text-red/50`}
      color: ${(props) => lighten(0.1, theme`colors.red.500`)};
    }
  }
`;

const Container = styled.div<{
  dragActive: boolean;
  fullWidth: boolean;
  hasImage: boolean;
}>`
  ${tw`border-2 border-dashed border-gray-200 rounded p-2`}
  ${(props) => props.dragActive && tw`border-blue-500`}

  width: ${(props) =>
    props.fullWidth ? "auto" : props.hasImage ? "15rem" : "16rem"};

  fieldset:disabled & {
    ${tw`bg-gray-100 pointer-events-none`}
  }
`;

const Image = styled.div<{ maxWidth: string; maxHeight: string }>`
  img {
    max-width: ${(props) => props.maxWidth};
    max-height: ${(props) => props.maxHeight};
  }
`;

const Help = styled.div`
  ${tw`text-type-light py-2 leading-[0px]`}

  fieldset:disabled & {
    ${tw`text-gray-400`}
  }
`;

interface ImageInputProps {
  className?: string;
  value: string | undefined;
  onChange: (value: string) => void;
  maxWidth?: string;
  maxHeight?: string;
  allowClear?: boolean;
  fullWidth?: boolean;
  adminUpload?: boolean;
}

const ImageInput: React.FunctionComponent<ImageInputProps> = ({
  className,
  value,
  onChange,
  maxWidth = "none",
  maxHeight = logoHeight,
  allowClear = true,
  fullWidth = false,
  adminUpload = false,
}) => {
  const userClaims = useRequiredUserClaims();

  const onDrop = useCallback(
    async (files) => {
      const formData = new FormData();
      formData.append("file", files[0]);

      const url =
        `${env("REACT_APP_SERVER_URL")}/upload` +
        (adminUpload ? "?admin=true" : "");

      const response = await fetch(url, {
        method: "POST",
        headers: {
          authorization: `Bearer ${userClaims.token.raw}`,
          "x-hasura-role": userClaims.defaultRole,
        },
        body: formData,
      });

      const location = response.headers.get("Location");

      if (location) {
        onChange(location);
      }
    },
    [adminUpload, onChange, userClaims.defaultRole, userClaims.token.raw]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Wrapper className={className}>
      <Container
        {...getRootProps()}
        dragActive={isDragActive}
        fullWidth={fullWidth}
        hasImage={!!value}
      >
        <input {...getInputProps()} />
        {value ? (
          <Image maxWidth={maxWidth} maxHeight={maxHeight}>
            <img src={value} alt="" />
          </Image>
        ) : (
          <Help>Drop image or click here to upload</Help>
        )}
      </Container>
      {value && allowClear && (
        <button
          onClick={() => {
            onChange("");
          }}
        >
          <FontAwesomeIcon icon={faTimesCircle} />
        </button>
      )}
    </Wrapper>
  );
};

export default ImageInput;
