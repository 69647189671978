import { CalendarDate } from "@internationalized/date";
import classNames from "classnames";
import { useRef } from "react";
import { useCalendarCell } from "react-aria";
import { CalendarState } from "react-stately";
import tw from "twin.macro";

interface CalendarCellProps {
  state: CalendarState;
  date: CalendarDate;
}

const CalendarCell: React.FunctionComponent<CalendarCellProps> = ({
  state,
  date,
}) => {
  const ref = useRef(null);

  const {
    cellProps,
    buttonProps,
    isSelected,
    isOutsideVisibleRange,
    isDisabled,
    isUnavailable,
    formattedDate,
  } = useCalendarCell({ date }, state, ref);

  return (
    <div
      {...cellProps}
      tw="flex items-center justify-center"
      className="calendar__grid__cell"
    >
      <div
        {...buttonProps}
        ref={ref}
        hidden={isOutsideVisibleRange}
        css={[
          tw`outline-none focus:outline-none`,
          (isDisabled || isUnavailable) && tw`pointer-events-none`,
        ]}
        className={classNames({ active: isSelected })}
      >
        <div
          css={[
            tw`flex items-center justify-center w-[2.5rem] h-[2.5rem] rounded-full`,
            !isDisabled && !isUnavailable && tw`hover:bg-gray-100`,
            isSelected && tw`bg-blue hover:bg-blue text-white`,
            (isDisabled || isUnavailable) && tw`text-gray-300`,
          ]}
        >
          {formattedDate}
        </div>
      </div>
    </div>
  );
};

export default CalendarCell;
