export default function env(key: string, defaultValue: null): string | null;
export default function env<T>(key: string, defaultValue: T): string | T;
export default function env(key: string): string;

export default function env(
  key: string,
  defaultValue: string | number | undefined | null = undefined
): string | number | null {
  if (!process.env[key]) {
    if (defaultValue !== undefined) {
      return defaultValue;
    }

    throw new Error(
      `Missing configuration key ${key}! Update your .env.local file.`
    );
  }

  return process.env[key]!;
}
