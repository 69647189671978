import {
  faCheckCircle,
  faExclamationTriangle,
  faInfoCircle,
  faTimesCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import tw, { styled, theme } from "twin.macro";

interface CalloutProps {
  type: "success" | "error" | "warning" | "info" | "neutral";
  heading: string;
  className?: string;
}

const Container = styled.div<{ bg: string; borderColor: string }>`
  ${tw`flex items-stretch rounded-md shadow-sm relative overflow-hidden border px-1 py-2`}
  background-color: ${(props) => props.bg};
  border-color: ${(props) => props.borderColor};
`;

const Icon = tw.div`text-center flex justify-center pt-[0.65rem] min-w-[2.5rem]`;

const Message = styled.div<{ color: string; linkColor: string }>`
  ${tw`p-2 pl-0`}

  color: ${(props) => props.color};

  position: relative;
  top: -1px;

  p {
    ${tw`mb-2 last:mb-0`}
  }

  a {
    color: ${(props) => props.linkColor};
    text-decoration: underline;
  }
`;

const Heading = styled.div<{ color: string }>`
  ${tw`font-semibold`}

  color: ${(props) => props.color};
`;

const types = {
  success: {
    bg: theme`colors.green.50`,
    icon: faCheckCircle,
    headingColor: theme`colors.green.800`,
    messageColor: theme`colors.green.700`,
    linkColor: theme`colors.green.800`,
    iconColor: theme`colors.green.500`,
    borderColor: theme`colors.green.200`,
  },
  error: {
    bg: theme`colors.red.50`,
    icon: faTimesCircle,
    headingColor: theme`colors.red.800`,
    messageColor: theme`colors.red.700`,
    linkColor: theme`colors.red.800`,
    iconColor: theme`colors.red.500`,
    borderColor: theme`colors.red.100`,
  },
  warning: {
    bg: theme`colors.yellow.50`,
    icon: faExclamationTriangle,
    headingColor: theme`colors.yellow.800`,
    messageColor: theme`colors.yellow.700`,
    linkColor: theme`colors.yellow.800`,
    iconColor: theme`colors.yellow.500`,
    borderColor: theme`colors.yellow.200`,
  },
  info: {
    bg: theme`colors.blue.50`,
    icon: faInfoCircle,
    headingColor: theme`colors.blue.800`,
    messageColor: theme`colors.blue.700`,
    linkColor: theme`colors.blue.800`,
    iconColor: theme`colors.blue.500`,
    borderColor: theme`colors.blue.100`,
  },
  neutral: {
    bg: theme`colors.gray.50`,
    icon: faInfoCircle,
    headingColor: theme`colors.gray.800`,
    messageColor: theme`colors.gray.700`,
    linkColor: theme`colors.gray.800`,
    iconColor: theme`colors.gray.500`,
    borderColor: theme`colors.gray.200`,
  },
};

const Callout: React.FunctionComponent<CalloutProps> = ({
  type,
  heading,
  className,
  children,
}) => {
  const typeConfig = types[type];

  return (
    <Container
      bg={typeConfig.bg}
      borderColor={typeConfig.borderColor}
      className={classNames(className, "callout")}
    >
      <Icon>
        <FontAwesomeIcon icon={typeConfig.icon} color={typeConfig.iconColor} />
      </Icon>
      <Message color={typeConfig.messageColor} linkColor={typeConfig.linkColor}>
        <Heading color={typeConfig.headingColor}>{heading}</Heading>
        {children}
      </Message>
    </Container>
  );
};

export default Callout;
