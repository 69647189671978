import { faLanguage } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import tw, { styled } from "twin.macro";

import { language_enum } from "../../../__generated__/graphql";
import { languageDescription, sortLanguages } from "../../../common/languages";

interface LanguageRadioProps {
  value: language_enum;
  languages: language_enum[];
  defaultLanguage: language_enum;
  onChange: (language: language_enum) => void;
}

const Wrapper = tw.div`py-3 pb-1 border-b border-gray-100 flex`;
const PillWrapper = tw.div`flex flex-wrap items-center`;
const Pill = styled.span<{ active: boolean }>`
  ${tw`border border-gray-200 rounded-full cursor-pointer whitespace-nowrap mb-2`}
  padding: 0 10px 2px 10px;
  margin-left: 6px;

  ${(props) => props.active && tw`bg-gray-800 text-white border-gray-800`}
`;
const Icon = styled(FontAwesomeIcon)`
  ${tw`mr-1 text-gray-800`}
  font-size: 28px;
`;

const LanguageRadio: React.FunctionComponent<LanguageRadioProps> = ({
  value,
  languages,
  defaultLanguage,
  onChange,
}) => {
  const sorted = sortLanguages(languages, defaultLanguage);

  return (
    <Wrapper>
      <Icon icon={faLanguage} />
      <PillWrapper>
        {sorted.map((language) => (
          <Pill
            key={language}
            active={value === language}
            onClick={() => onChange(language)}
          >
            {languageDescription(language)}
          </Pill>
        ))}
      </PillWrapper>
    </Wrapper>
  );
};

export default LanguageRadio;
