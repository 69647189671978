import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";

import { language_enum, platform_enum } from "../../__generated__/graphql";
import EditorSelector from "../../common/editor2/EditorSelector";
import FieldCheckboxInput from "../../common/form/FieldCheckboxInput";
import FieldError from "../../common/form/FieldError";
import FieldHint from "../../common/form/FieldHint";
import FieldInput from "../../common/form/FieldInput";
import FieldLabel from "../../common/form/FieldLabel";
import FieldRow from "../../common/form/FieldRow";
import FieldRowBlock from "../../common/form/FieldRowBlock";
import FieldSet from "../../common/form/FieldSet";
import FormMode from "../../common/form/FormMode";
import ImageInput from "../../common/form/input/ImageInput";
import SelectInput from "../../common/form/input/SelectInput";
import TagsInput from "../../common/form/input/TagsInput";
import TextInput from "../../common/form/input/TextInput";
import useTranslatableForm, {
  TranslatedForms,
} from "../../common/form/useTranslatableForm";
import HelpIcon from "../../common/HelpIcon";
import MetadataInput from "../../common/MetadataInput";
import PillRadio from "../../common/PillRadio";
import {
  PlatformFeature,
  platformHasFeature,
} from "../../common/platform/features";
import { useTranslations } from "../../common/translations/TranslationsProvider";
import useAccountFeatures from "../../common/useAccountFeatures";
import useFocusFirstEmptyInput from "../../common/useFocusFirstEmptyInput";
import LanguageRadio from "../flow/edit/LanguageRadio";
import MultipleAddOnIdsDropdown from "../segments/MultipleAddOnIdsDropdown";
import MultiplePlanIdsDropdown from "../segments/MultiplePlanIdsDropdown";
import MultipleTextsInput from "../segments/MultipleTextsInput";
import getDefaultFormValues from "./forms/lib/getDefaultFormValues";
import getTranslatableFields from "./forms/lib/getTranslatableFields";
import { ModifySubscriptionOfferFormValues } from "./forms/types";
import useValidateHeadline from "./lib/useValidateHeadline";
import useValidateName from "./lib/useValidateName";
import validateMetadata from "./lib/validateMetadata";

interface ModifySubscriptionOfferFormProps {
  mode: FormMode;
  platform: platform_enum;
  onChange: (
    values: TranslatedForms<ModifySubscriptionOfferFormValues>
  ) => void;
  onSubmit: (forms: TranslatedForms<ModifySubscriptionOfferFormValues>) => void;
  initialValues?: TranslatedForms<ModifySubscriptionOfferFormValues>;
  onChangeEditingLanguage: (language: language_enum) => void;
  isSubmitting: boolean;
  tags: string[];
}

const ModifySubscriptionOfferForm: React.FunctionComponent<
  ModifySubscriptionOfferFormProps
> = ({
  mode,
  platform,
  onChange,
  onSubmit,
  initialValues,
  onChangeEditingLanguage,
  isSubmitting,
  tags,
}) => {
  const { defaultLanguage, enabledLanguages } = useTranslations();
  const defaultValues =
    initialValues ||
    getDefaultFormValues<ModifySubscriptionOfferFormValues>({
      type: "modify_subscription",
      enabledLanguages,
      platform,
    });

  const {
    register,
    watch,
    formState,
    setValue,
    handleSubmit,
    control,
    editingLanguage,
    setEditingLanguage,
    forms,
    trigger,
  } = useTranslatableForm<ModifySubscriptionOfferFormValues>({
    defaultValues,
    initialLanguage: defaultLanguage,
    languages: enabledLanguages,
    defaultLanguage,
    translatableFields: getTranslatableFields("modify_subscription"),
  });
  const [formRef, setFormRef] = useState<HTMLFormElement | null>(null);
  useFocusFirstEmptyInput(formRef);
  const validateName = useValidateName(formState.dirtyFields.name || false);
  const values = watch();
  const validateHeadline = useValidateHeadline(values.image);

  const { features } = useAccountFeatures();

  useEffect(() => {
    onChange(forms);
  }, [forms, onChange]);

  useEffect(() => {
    if (values.modifyAt === "period_end") {
      setValue("prorate", false);
    }
  }, [values.modifyAt, setValue]);

  const addPlans: string[] = JSON.parse(values.addPlans);
  const removePlans: string[] = JSON.parse(values.removePlans);
  const addAddons: string[] = JSON.parse(values.addAddons);
  const removeAddons: string[] = JSON.parse(values.removeAddons);
  const addCustomPlans: string[] = JSON.parse(values.addCustomPlans);
  const removeCustomPlans: string[] = JSON.parse(values.removeCustomPlans);

  const validate = () => {
    if (
      !addPlans.length &&
      !removePlans.length &&
      !addAddons.length &&
      !removeAddons.length &&
      !addCustomPlans.length &&
      !removeCustomPlans.length
    ) {
      return "At least one option must be selected.";
    }
  };

  return (
    <form
      id="create-offer"
      onChange={() => onChange(forms)}
      ref={(ref) => setFormRef(ref)}
      onSubmit={handleSubmit(onSubmit)}
    >
      <fieldset disabled={isSubmitting}>
        <input
          {...register("type")}
          type="hidden"
          value="modify_subscription"
        />
        <FieldRow>
          <FieldLabel>
            <label htmlFor="name">Name</label>
          </FieldLabel>
          <FieldInput>
            <TextInput
              {...register("name", {
                required: true,
                validate: validateName,
              })}
              id="name"
              width="full"
              fieldError={formState.errors.name}
            />
            <FieldError error={formState.errors.name} />
          </FieldInput>
        </FieldRow>
        <FieldRow>
          <FieldLabel>Tags</FieldLabel>
          <FieldInput>
            <Controller
              control={control}
              name="tags"
              render={({ field }) => (
                <TagsInput
                  tags={tags}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </FieldInput>
        </FieldRow>
        <FieldRow>
          <FieldLabel>
            <label htmlFor="style">Style</label>
          </FieldLabel>
          <FieldInput>
            <SelectInput {...register("style")} id="style" defaultValue="modal">
              <option value="modal">Modal</option>
              <option value="step">Step</option>
            </SelectInput>
            <FieldHint>
              {values.style === "modal" ? (
                <>Offer will be shown in a pop-up modal dialog.</>
              ) : (
                <>Offer will be shown as a full flow step.</>
              )}
            </FieldHint>
          </FieldInput>
        </FieldRow>
        <FieldRow>
          <FieldLabel>
            <label>
              Allow override{" "}
              <HelpIcon content="By default, an offer will not be displayed to a subscriber if they have previously accepted another offer. This setting will allow this offer to override any previously accepted offers." />
            </label>
          </FieldLabel>
          <FieldCheckboxInput>
            <input
              {...register("allowOverride")}
              id="allowOverride"
              type="checkbox"
            />
            <label htmlFor="allowOverride">Override other offers</label>
          </FieldCheckboxInput>
        </FieldRow>

        <FieldSet title="Modify details">
          {platformHasFeature(platform, PlatformFeature.SubscriptionItems) && (
            <fieldset disabled={mode === "edit"}>
              <FieldRow>
                <FieldLabel>
                  <label>Add plans</label>
                </FieldLabel>
                <FieldInput>
                  <Controller
                    control={control}
                    name="addPlans"
                    render={({ field }) => (
                      <MultiplePlanIdsDropdown
                        value={addPlans}
                        onChange={(value) => {
                          field.onChange(JSON.stringify(value));

                          if (formState.isSubmitted) {
                            trigger();
                          }
                        }}
                        excludeIds={removePlans}
                        width="240px"
                      />
                    )}
                    rules={{
                      validate: validate,
                    }}
                  />
                  <FieldHint>
                    Add each of the selected plans to the subscription.
                  </FieldHint>
                </FieldInput>
              </FieldRow>

              <FieldRow>
                <FieldLabel>
                  <label>Remove plans</label>
                </FieldLabel>
                <FieldInput>
                  <Controller
                    control={control}
                    name="removePlans"
                    render={({ field }) => (
                      <MultiplePlanIdsDropdown
                        value={removePlans}
                        onChange={(value) => {
                          field.onChange(JSON.stringify(value));

                          if (formState.isSubmitted) {
                            trigger();
                          }
                        }}
                        excludeIds={addPlans}
                        width="240px"
                      />
                    )}
                  />
                  <FieldHint>
                    Remove each of the selected plans from the subscription, if
                    they exist.
                  </FieldHint>
                </FieldInput>
              </FieldRow>
            </fieldset>
          )}

          {platformHasFeature(platform, PlatformFeature.AddOns) && (
            <fieldset disabled={mode === "edit"}>
              <FieldRow>
                <FieldLabel>
                  <label>Add add-ons</label>
                </FieldLabel>
                <FieldInput>
                  <Controller
                    control={control}
                    name="addAddons"
                    render={({ field }) => (
                      <MultipleAddOnIdsDropdown
                        value={addAddons}
                        onChange={(value) => {
                          field.onChange(JSON.stringify(value));

                          if (formState.isSubmitted) {
                            trigger();
                          }
                        }}
                        excludeIds={removeAddons}
                        width="240px"
                      />
                    )}
                    rules={{
                      validate: validate,
                    }}
                  />
                  <FieldHint>
                    Add each of the selected add-ons to the subscription.
                  </FieldHint>
                </FieldInput>
              </FieldRow>

              <FieldRow>
                <FieldLabel>
                  <label>Remove add-ons</label>
                </FieldLabel>
                <FieldInput>
                  <Controller
                    control={control}
                    name="removeAddons"
                    render={({ field }) => (
                      <MultipleAddOnIdsDropdown
                        value={removeAddons}
                        onChange={(value) => {
                          field.onChange(JSON.stringify(value));

                          if (formState.isSubmitted) {
                            trigger();
                          }
                        }}
                        excludeIds={addAddons}
                        width="240px"
                      />
                    )}
                  />
                  <FieldHint>
                    Remove each of the selected add-ons from the subscription,
                    if they exist.
                  </FieldHint>
                </FieldInput>
              </FieldRow>

              <div>
                <FieldError
                  tw="mb-4"
                  error={
                    formState.errors.addPlans ||
                    formState.errors.addAddons ||
                    formState.errors.addCustomPlans
                  }
                />
              </div>
            </fieldset>
          )}

          {platform === "custom" && (
            <fieldset disabled={mode === "edit"}>
              <FieldRow>
                <FieldLabel>
                  <label>Add plans</label>
                </FieldLabel>
                <FieldInput>
                  <Controller
                    control={control}
                    name="addCustomPlans"
                    render={({ field }) => (
                      <MultipleTextsInput
                        value={addCustomPlans}
                        onChange={(value) => {
                          field.onChange(JSON.stringify(value));

                          if (formState.isSubmitted) {
                            trigger();
                          }
                        }}
                        placeholder="Enter a plan ID…"
                      />
                    )}
                  />
                  <FieldHint>
                    Plan IDs from your billing system to add to the
                    subscription.
                  </FieldHint>
                </FieldInput>
              </FieldRow>

              <FieldRow>
                <FieldLabel>
                  <label>Remove plans</label>
                </FieldLabel>
                <FieldInput>
                  <Controller
                    control={control}
                    name="removeCustomPlans"
                    render={({ field }) => (
                      <MultipleTextsInput
                        value={removeCustomPlans}
                        onChange={(value) => {
                          field.onChange(JSON.stringify(value));

                          if (formState.isSubmitted) {
                            trigger();
                          }
                        }}
                        placeholder="Enter a plan ID…"
                      />
                    )}
                  />
                  <FieldHint>
                    Plan IDs from your billing system to remove from the
                    subscription.
                  </FieldHint>
                </FieldInput>
              </FieldRow>
            </fieldset>
          )}

          {platformHasFeature(
            platform,
            PlatformFeature.ModifySubscriptionChangeAtEndOfPeriod
          ) && (
            <FieldRow>
              <FieldLabel>
                <label>Modify at</label>
              </FieldLabel>
              <FieldInput>
                <Controller
                  control={control}
                  name="modifyAt"
                  render={({ field }) => (
                    <PillRadio
                      value={field.value}
                      options={[
                        {
                          label: "Immediately",
                          value: "immediately",
                          tooltip:
                            "Apply the specified modifications immediately",
                        },
                        {
                          label: "End of period",
                          value: "period_end",
                          tooltip:
                            "Apply the specified modifications at the end of the billing period",
                        },
                      ]}
                      onChange={field.onChange}
                    />
                  )}
                />
              </FieldInput>
            </FieldRow>
          )}
          {platformHasFeature(
            platform,
            PlatformFeature.ModifySubscriptionProration
          ) && (
            <FieldRow>
              <FieldLabel>
                <label>Proration</label>
              </FieldLabel>
              <FieldCheckboxInput>
                <input
                  {...register("prorate")}
                  id="prorate"
                  type="checkbox"
                  disabled={values.modifyAt === "period_end"}
                />
                <label htmlFor="prorate">
                  Apply prorated charges or credits
                </label>
              </FieldCheckboxInput>
            </FieldRow>
          )}
        </FieldSet>

        <FieldSet title={`Offer ${values.style}`}>
          {values.style === "modal" && (
            <FieldRow>
              <FieldLabel>
                <label>Banner</label>
              </FieldLabel>
              <FieldCheckboxInput>
                <input
                  {...register("showBanner")}
                  id="showBanner"
                  type="checkbox"
                />
                <label htmlFor="showBanner">Show "Special Offer" banner</label>
              </FieldCheckboxInput>
            </FieldRow>
          )}
          {enabledLanguages.length > 1 && (
            <LanguageRadio
              value={editingLanguage}
              languages={enabledLanguages}
              defaultLanguage={defaultLanguage}
              onChange={(language) => {
                setEditingLanguage(language);
                onChangeEditingLanguage(language);
              }}
            />
          )}
          <FieldRow>
            <FieldLabel>
              <label htmlFor="headline">Headline</label>
            </FieldLabel>
            <FieldInput>
              <TextInput
                {...register("headline", { validate: validateHeadline })}
                id="headline"
                width="full"
                fieldError={formState.errors.headline}
              />
              <FieldError error={formState.errors.headline} />
            </FieldInput>
          </FieldRow>
          <FieldRow>
            <FieldLabel>
              <label>Image</label>
            </FieldLabel>
            <FieldInput>
              <Controller
                control={control}
                name="image"
                render={({ field }) => (
                  <ImageInput
                    value={field.value}
                    onChange={(logoUrl) => {
                      field.onChange(logoUrl);
                      if (formState.isSubmitted) {
                        trigger();
                      }
                    }}
                  />
                )}
              />
            </FieldInput>
          </FieldRow>
          <FieldRowBlock>
            <FieldLabel>
              <label>Content</label>
            </FieldLabel>
            <Controller
              control={control}
              name="content"
              render={({ field }) => {
                const form = forms[editingLanguage];
                if (!form) {
                  return <></>;
                }

                return (
                  <EditorSelector
                    initialValueKey={editingLanguage}
                    isOfferContent
                    hasCustomProperties={!!features.custom_properties}
                    format={form.contentFormat}
                    value={form.content}
                    onChange={(value) => {
                      field.onChange(value);
                      onChange({
                        ...forms,
                        [editingLanguage]: {
                          ...forms[editingLanguage],
                          content: value,
                        },
                      });
                    }}
                  />
                );
              }}
            />
          </FieldRowBlock>
          <FieldRow>
            <FieldLabel>
              <label>Button text</label>
            </FieldLabel>
            <FieldInput>
              <TextInput
                {...register("buttonText", { required: true })}
                id="buttonText"
                width="md"
                fieldError={formState.errors.buttonText}
              />
            </FieldInput>
          </FieldRow>
        </FieldSet>

        <FieldSet title="Metadata">
          <FieldRow>
            <FieldInput>
              <Controller
                control={control}
                name="metadata"
                render={({ field }) => (
                  <MetadataInput
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
                rules={{
                  validate: validateMetadata,
                }}
              />
              <FieldError error={formState.errors.metadata} />
            </FieldInput>
          </FieldRow>
        </FieldSet>
      </fieldset>
    </form>
  );
};

export default ModifySubscriptionOfferForm;
