import gql from "graphql-tag";

export default gql`
  fragment OfferTestFragment on offer_test {
    id
    token
    name
    goal
    control_weight
    created_at
    winning_offer {
      id
      name
      token
      type
    }
    control_offer {
      id
      name
      token
      type
    }
    winning_offer {
      id
      name
      token
      type
    }
    offer_test_offers {
      offer_test_id
      offer_id
      weight
      offer {
        id
        name
        token
        type
      }
    }
    report_offer_test_stats {
      offer_id
      views
      accepts
      declines
    }
  }
`;
