import { Temporal } from "@js-temporal/polyfill";
import classNames from "classnames";
import { range } from "lodash";
import tw from "twin.macro";

import Button from "../form/Button";

interface CalendarMonthViewProps {
  selectedMonth: number;
  onSelectMonth: (month: number) => void;
  onCancel: () => void;
}

const CalendarMonthView: React.FunctionComponent<CalendarMonthViewProps> = ({
  selectedMonth,
  onSelectMonth,
  onCancel,
}) => (
  <div>
    <div tw="grid grid-cols-4 auto-rows-min gap-2 w-[374px] h-[283px] p-4">
      {range(1, 12).map((month) => (
        <div
          key={month}
          role="button"
          css={[
            tw`p-2 rounded-full text-center hover:bg-gray-100`,
            month === selectedMonth && tw`bg-blue text-white hover:bg-blue`,
          ]}
          onClick={() => onSelectMonth(month)}
          className={classNames({
            calendar__grid__month: true,
            active: month === selectedMonth,
          })}
        >
          {Temporal.PlainDate.from({
            year: 2000,
            month,
            day: 1,
          }).toLocaleString(undefined, { month: "short" })}
        </div>
      ))}
    </div>
    <div tw="flex border-t border-divider p-4">
      <div tw="ml-auto">
        <Button
          type="button"
          buttonType="transparent-secondary-no-border"
          onClick={onCancel}
        >
          Cancel
        </Button>
      </div>
    </div>
  </div>
);

export default CalendarMonthView;
