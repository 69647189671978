import {
  faCalendar,
  faCheckSquare,
  faClock,
} from "@fortawesome/pro-regular-svg-icons";
import { faCalculator, faFont } from "@fortawesome/pro-solid-svg-icons";

import { property_type_enum } from "../../../__generated__/graphql";

export default function propertyTypeIcon(type: property_type_enum) {
  switch (type) {
    case "text":
      return faFont;

    case "number":
      return faCalculator;

    case "boolean":
      return faCheckSquare;

    case "date":
      return faCalendar;

    case "timestamp":
      return faClock;

    default:
      throw new Error(`Invalid property type: ${type}`);
  }
}
