import { property_type_enum } from "../../../__generated__/graphql";

export default function propertyTypeLabel(type: property_type_enum) {
  switch (type) {
    case "text":
      return "Text";

    case "number":
      return "Number";

    case "boolean":
      return "True or false";

    case "date":
      return "Date";

    case "timestamp":
      return "Date and time";

    default:
      throw new Error(`Invalid property type: ${type}`);
  }
}
