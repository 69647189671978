import { createGlobalStyle } from "styled-components";
import { GlobalStyles as BaseStyles, theme } from "twin.macro";

const CustomStyles = createGlobalStyle`
  body {
    background: white;
    color: ${theme`colors.type.DEFAULT`};
    margin: 0;
    font-family: 'Source Sans Pro', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;    
  }  

  code {
    font-family: "Source Code Pro", monospace;
    font-size: 0.9rem;
  }  
`;

const GlobalStyles = () => (
  <>
    <BaseStyles />
    <CustomStyles />
  </>
);

export default GlobalStyles;
