import { gql, useQuery } from "@apollo/client";

import { MultipleCouponIdsDropdownQuery } from "../../__generated__/graphql";
import MultipleIdsDropdown from "./MultipleIdsDropdown";

interface MultipleCouponIdsDropdownProps {
  value: string[];
  width?: string;
  placeholder?: string;
  onChange: (value: string[]) => void;
}

const MultipleCouponIdsDropdown: React.FunctionComponent<
  MultipleCouponIdsDropdownProps
> = ({
  value,
  width = "190px",
  placeholder = "Select a coupon…",
  onChange,
}) => {
  const { data, loading } = useQuery<MultipleCouponIdsDropdownQuery>(gql`
    query MultipleCouponIdsDropdownQuery {
      platform_coupon {
        platform_id
        name
      }
    }
  `);

  const ids = (data?.platform_coupon || []).map((coupon) => ({
    id: coupon.platform_id,
    label: coupon.name || "",
  }));

  return (
    <MultipleIdsDropdown
      ids={ids}
      value={value}
      onChange={onChange}
      isLoading={loading}
      width={width}
      placeholder={placeholder}
    />
  );
};

export default MultipleCouponIdsDropdown;
