import FieldSetTitle from "./FieldSetTitle";

interface FieldSetProps {
  title?: string;
  disabled?: boolean;
}

const FieldSet: React.FunctionComponent<FieldSetProps> = ({
  title,
  disabled,
  children,
}) => (
  <fieldset disabled={disabled}>
    {!!title && <FieldSetTitle>{title}</FieldSetTitle>}
    {children}
  </fieldset>
);

export default FieldSet;
