import { useEffect, useState } from "react";
import { css } from "twin.macro";

interface VimeoProps {
  videoId: string;
  autoPlay: boolean;
}

const Vimeo: React.FunctionComponent<VimeoProps> = ({ videoId, autoPlay }) => {
  const [loaded, setLoaded] = useState(false);
  const [ratio, setRatio] = useState<number>();

  useEffect(() => {
    let ignore = false;

    if (ratio) {
      return;
    }

    (async () => {
      const result = await fetch(
        `https://vimeo.com/api/oembed.json?${new URLSearchParams({
          url: `https://vimeo.com/${videoId}`,
        })}`
      );

      const json = await result.json();

      if (!ignore) {
        setRatio(json.height / json.width);
      }
    })();

    return () => {
      ignore = true;
    };
  }, [ratio, videoId]);

  return ratio ? (
    <>
      <div
        css={css`
          padding: ${ratio * 100}% 0 0 0;
          position: relative;
        `}
      >
        <iframe
          css={css`
            opacity: ${loaded ? "1" : "0"};
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          `}
          title="Video"
          src={`https://player.vimeo.com/video/${videoId}?autoplay=${
            autoPlay ? "1" : "0"
          }`}
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          allowTransparency
          onLoad={() => setLoaded(true)}
        ></iframe>
      </div>
      <script src="https://player.vimeo.com/api/player.js"></script>
    </>
  ) : null;
};

export default Vimeo;
