import { isPresent } from "ts-is-present";

import { FlowOfferRulesQuery } from "../../../../__generated__/graphql";
import getFlowObjectVersion from "../../../../common/flow/getFlowObjectVersion";

export default function getOfferRuleConditionedQuestionIds(
  offerRules: FlowOfferRulesQuery | undefined | null
) {
  if (!offerRules) {
    return {
      questionIds: [],
      questionOptionIds: [],
      otherSpecifyQuestionIds: [],
    };
  }

  const flow = offerRules.flow[0];
  const flowVersion = getFlowObjectVersion(flow, "draft");
  const flowSteps = flowVersion.flow_version_flow_steps?.map(
    (s) => s.flow_step
  );

  // Intervention steps can have offer rules too, but those rules do not contain any questions.
  const offerRuleGroupIds = [
    ...new Set(
      [
        ...flowSteps.map(
          (s) => s?.flow_step_offer_rule_group?.offer_rule_group_id
        ),
        ...flowSteps.map(
          (s) => s?.flow_step_deflection_rule_group?.offer_rule_group_id
        ),
      ].filter(isPresent)
    ),
  ];

  const questionIds: number[] = [];
  const questionOptionIds: number[] = [];
  const conditionedOtherQuestionIds: number[] = [];

  for (const ruleGroup of [
    ...offerRules.offer_rule_group,
    ...offerRules.deflection_rule_group,
  ]) {
    if (!offerRuleGroupIds.includes(ruleGroup.id)) {
      continue;
    }

    const groupVersion = getFlowObjectVersion(ruleGroup, "draft");

    for (const rule of groupVersion?.offer_rule_group_version_offer_rules ||
      []) {
      if (!rule.offer_rule) {
        continue;
      }

      const ruleVersion = getFlowObjectVersion(rule.offer_rule, "draft");
      if (ruleVersion) {
        const otherQuestionIds =
          ruleVersion.include_other_in_question_ids.filter(isPresent);
        if (otherQuestionIds.length) {
          conditionedOtherQuestionIds.push(...otherQuestionIds);
        }

        for (const option of ruleVersion.offer_rule_version_question_options) {
          if (!option.question_option) {
            continue;
          }

          questionIds.push(option.question_option.question_id);
          questionOptionIds.push(option.question_option.id);
        }
      }
    }
  }

  return {
    questionIds: [...new Set([...questionIds, ...conditionedOtherQuestionIds])],
    questionOptionIds: [...new Set(questionOptionIds)],
    otherSpecifyQuestionIds: [...new Set(conditionedOtherQuestionIds)],
  };
}
