import { FieldError } from "react-hook-form";
import tw, { css, styled } from "twin.macro";

import sharedInputStyles from "./sharedInputStyles";

interface TextInputProps {
  width?: "xs" | "sm" | "md" | "lg" | "xl" | "full";
  fieldError?: FieldError;
}

const TextInput = styled.input.attrs((props: TextInputProps) => ({
  width: props.width || "md",
}))<TextInputProps>`
  ${sharedInputStyles}
  ${(props) =>
    props.width === "xs" &&
    css`
      width: 100px;
    `}
  ${(props) =>
    props.width === "sm" &&
    css`
      width: 150px;
    `}
  ${(props) =>
    props.width === "md" &&
    css`
      width: 200px;
    `}
  ${(props) =>
    props.width === "lg" &&
    css`
      width: 300px;
    `}
  ${(props) =>
    props.width === "xl" &&
    css`
      width: 400px;
    `}
  ${(props) =>
    props.width === "full" &&
    css`
      width: 100%;
    `}
  ${(props) =>
    props.fieldError &&
    tw`border-red-500 focus:ring-red-200 focus:border-red-500`}
`;

export default TextInput;
