import gql from "graphql-tag";

import OfferTimerFragment from "../../../common/fragments/OfferTimerFragment";
import TranslationFragment from "../../../common/fragments/TranslationFragment";

export default gql`
  fragment OfferFragment on offer {
    id
    token
    type
    name
    show_banner
    override_active_offer
    style
    goal
    offer_timer {
      ...OfferTimerFragment
    }
    created_at
    updated_at
    headline_translation {
      ...TranslationFragment
    }
    content_translation {
      ...TranslationFragment
    }
    button_text_translation {
      ...TranslationFragment
    }
    offer_coupon {
      type
      amount_off
      duration
      months
      override_active_coupon
      platform_coupon {
        platform_id
        apply_to
        apply_to_product_groups
        apply_to_products
        apply_to_plans
        apply_to_addons
        valid
        platform_coupon_product_groups {
          platform_product_group_id
        }
        platform_coupon_products {
          platform_product_id
        }
        platform_coupon_plans {
          platform_plan_id
        }
        platform_coupon_addons {
          platform_addon_id
        }
      }
    }
    offer_change_plan {
      platform_plan_id
      prorate
      offer_coupon_offer {
        id
        token
        type
        name
        show_banner
        override_active_offer
        style
        goal
        created_at
        updated_at
        headline_translation {
          ...TranslationFragment
        }
        content_translation {
          ...TranslationFragment
        }
        button_text_translation {
          ...TranslationFragment
        }
        offer_coupon {
          type
          amount_off
          duration
          months
          override_active_coupon
          platform_coupon {
            platform_id
            apply_to
            apply_to_product_groups
            apply_to_products
            apply_to_plans
            apply_to_addons
            valid
            platform_coupon_product_groups {
              platform_product_group_id
            }
            platform_coupon_products {
              platform_product_id
            }
            platform_coupon_plans {
              platform_plan_id
            }
            platform_coupon_addons {
              platform_addon_id
            }
          }
        }
      }
    }
    offer_modify_subscription {
      prorate
      modify_at
      offer_modify_subscription_options {
        type
        platform_connection_id
        platform_plan_id
        platform_addon_id
        platform_addon {
          platform_id
          platform_addon_plans {
            platform_plan_id
          }
        }
        platform_plan {
          platform_id
          interval
          interval_count
        }
      }
    }
    offer_custom {
      link_href
      link_text
    }
    offer_trial_extension {
      days
    }
    offer_pause_subscription {
      offer_pause_subscription_options {
        interval
        interval_count
      }
    }
    offer_upgrade {
      platform_plan_id
      change_at
    }
    offer_downgrade {
      platform_plan_id
      change_at
    }
    offer_product_swap {
      specific_products
      offer_product_swap_platform_ecommerce_products {
        platform_ecommerce_product_id
      }
    }
  }
  ${TranslationFragment}
  ${OfferTimerFragment}
`;
