import { gql, useQuery } from "@apollo/client";
import { faArrowRightLong } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { css } from "twin.macro";

import {
  CustomerPortalSubscriptionQuery,
  CustomerPortalSubscriptionQueryVariables,
} from "../../../__generated__/graphql";
import Spinner from "../../../common/Spinner";
import SubscriptionSimpleDescription from "./SubscriptionSimpleDescription";

interface SubscriptionRowProps {
  clientId: string;
  sessionToken: string;
  platformSubscriptionId: string;
}

const SubscriptionRow: React.FunctionComponent<SubscriptionRowProps> = ({
  clientId,
  sessionToken,
  platformSubscriptionId,
}) => {
  const { data, loading } = useQuery<
    CustomerPortalSubscriptionQuery,
    CustomerPortalSubscriptionQueryVariables
  >(
    gql`
      query CustomerPortalSubscriptionQuery(
        $input: PortalSubscriptionDetailsInput!
      ) {
        portalSubscriptionDetails(input: $input) {
          name
          platformId
          status
          trialEndsAt
          amount
          currency
          interval
          intervalCount
        }
      }
    `,
    {
      variables: {
        input: {
          portalSessionToken: sessionToken,
          platformSubscriptionId,
        },
      },
    }
  );

  return (
    <Link
      to={`/p/portal/${clientId}/session/${sessionToken}/subscriptions/${data?.portalSubscriptionDetails.platformId}`}
      tw="block px-4 hover:bg-gray-50 cursor-pointer"
      css={css`
        &:last-child div {
          border-bottom-width: 0;
        }
      `}
    >
      {loading ? (
        <Spinner padding="0.5rem" />
      ) : (
        <div tw="flex border-b border-divider pt-3 pb-3">
          <div>
            <div tw="font-semibold">{data?.portalSubscriptionDetails.name}</div>
            <div tw="text-type-light">
              {data?.portalSubscriptionDetails && (
                <SubscriptionSimpleDescription
                  subscriptionDetails={data.portalSubscriptionDetails}
                />
              )}
            </div>
          </div>
          <div tw="ml-auto mr-2 flex items-center">
            <FontAwesomeIcon icon={faArrowRightLong} />
          </div>
        </div>
      )}
    </Link>
  );
};

export default SubscriptionRow;
