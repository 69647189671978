
import orgDefaultContentValues from "../common/editor2/defaultContentValues";
import { language_enum } from "./graphql";
      
export const defaultContentValues: Record<keyof typeof orgDefaultContentValues, Partial<Record<language_enum, any>>> = {
  "campaign.confirmation": {
    "en_us": {
      "root": {
        "children": [
          {
            "children": [],
            "direction": null,
            "format": "",
            "indent": 0,
            "type": "paragraph",
            "version": 1
          }
        ],
        "direction": null,
        "format": "",
        "indent": 0,
        "type": "root",
        "version": 1
      }
    }
  },
  "flow.confirmation.canceled": {
    "en_us": {
      "root": {
        "children": [
          {
            "children": [
              {
                "detail": 0,
                "format": 0,
                "mode": "normal",
                "style": "",
                "text": "Your subscription has been canceled.",
                "type": "text",
                "version": 1
              }
            ],
            "direction": null,
            "format": "",
            "indent": 0,
            "type": "paragraph",
            "version": 1
          }
        ],
        "direction": null,
        "format": "",
        "indent": 0,
        "type": "root",
        "version": 1
      }
    }
  },
  "flow.confirmation.saved": {
    "en_us": {
      "root": {
        "children": [
          {
            "children": [
              {
                "detail": 0,
                "format": 0,
                "mode": "normal",
                "style": "",
                "text": "Your offer has been accepted.",
                "type": "text",
                "version": 1
              }
            ],
            "direction": null,
            "format": "",
            "indent": 0,
            "type": "paragraph",
            "version": 1
          }
        ],
        "direction": null,
        "format": "",
        "indent": 0,
        "type": "root",
        "version": 1
      }
    }
  },
  "flow.text.headline": {
    "en_us": {
      "root": {
        "children": [
          {
            "children": [
              {
                "detail": 0,
                "format": 0,
                "mode": "normal",
                "style": "",
                "text": "We’re sorry to see you go!",
                "type": "text",
                "version": 1
              }
            ],
            "direction": null,
            "format": "",
            "indent": 0,
            "type": "paragraph",
            "version": 1
          }
        ],
        "direction": null,
        "format": "",
        "indent": 0,
        "type": "root",
        "version": 1
      }
    }
  },
  "flow.preventIfCanceled": {
    "en_us": {
      "root": {
        "children": [
          {
            "children": [
              {
                "detail": 0,
                "format": 0,
                "mode": "normal",
                "style": "",
                "text": "Your subscription has already been canceled.",
                "type": "text",
                "version": 1
              }
            ],
            "direction": null,
            "format": "",
            "indent": 0,
            "type": "paragraph",
            "version": 1
          }
        ],
        "direction": null,
        "format": "",
        "indent": 0,
        "type": "root",
        "version": 1
      }
    }
  },
  "offer.conversion.coupon.default": {
    "en_us": {
      "root": {
        "children": [
          {
            "children": [
              {
                "detail": 0,
                "format": 0,
                "mode": "normal",
                "style": "",
                "text": "Get ",
                "type": "text",
                "version": 1
              },
              {
                "type": "offer_description",
                "version": 1
              },
              {
                "detail": 0,
                "format": 0,
                "mode": "normal",
                "style": "",
                "text": " when you accept this special offer.",
                "type": "text",
                "version": 1
              }
            ],
            "direction": null,
            "format": "",
            "indent": 0,
            "type": "paragraph",
            "version": 1
          }
        ],
        "direction": null,
        "format": "",
        "indent": 0,
        "type": "root",
        "version": 1
      }
    }
  },
  "offer.conversion.custom.default": {
    "en_us": {
      "root": {
        "children": [
          {
            "children": [],
            "direction": null,
            "format": "",
            "indent": 0,
            "type": "paragraph",
            "version": 1
          }
        ],
        "direction": null,
        "format": "",
        "indent": 0,
        "type": "root",
        "version": 1
      }
    }
  },
  "offer.retention.change_plan.default": {
    "en_us": {
      "root": {
        "children": [
          {
            "children": [],
            "direction": null,
            "format": "",
            "indent": 0,
            "type": "paragraph",
            "version": 1
          }
        ],
        "direction": null,
        "format": "",
        "indent": 0,
        "type": "root",
        "version": 1
      }
    }
  },
  "offer.retention.change_plan.preview": {
    "en_us": {
      "root": {
        "children": [
          {
            "children": [
              {
                "detail": 0,
                "format": 0,
                "mode": "normal",
                "style": "",
                "text": "Not happy with your current plan?",
                "type": "text",
                "version": 1
              }
            ],
            "direction": null,
            "format": "",
            "indent": 0,
            "type": "paragraph",
            "version": 1
          },
          {
            "children": [
              {
                "detail": 0,
                "format": 0,
                "mode": "normal",
                "style": "",
                "text": "Switch to the Super Saver plan for only ",
                "type": "text",
                "version": 1
              },
              {
                "detail": 0,
                "format": 8,
                "mode": "normal",
                "style": "",
                "text": "$29.99 per month",
                "type": "text",
                "version": 1
              },
              {
                "detail": 0,
                "format": 0,
                "mode": "normal",
                "style": "",
                "text": " instead!",
                "type": "text",
                "version": 1
              }
            ],
            "direction": null,
            "format": "",
            "indent": 0,
            "type": "paragraph",
            "version": 1
          }
        ],
        "direction": null,
        "format": "",
        "indent": 0,
        "type": "root",
        "version": 1
      }
    }
  },
  "offer.retention.coupon.default": {
    "en_us": {
      "root": {
        "children": [
          {
            "children": [
              {
                "detail": 0,
                "format": 0,
                "mode": "normal",
                "style": "",
                "text": "Get ",
                "type": "text",
                "version": 1
              },
              {
                "type": "offer_description",
                "version": 1
              },
              {
                "detail": 0,
                "format": 0,
                "mode": "normal",
                "style": "",
                "text": " when you accept this special offer.",
                "type": "text",
                "version": 1
              }
            ],
            "direction": null,
            "format": "",
            "indent": 0,
            "type": "paragraph",
            "version": 1
          },
          {
            "children": [
              {
                "detail": 0,
                "format": 0,
                "mode": "normal",
                "style": "",
                "text": "Effective on your next billing date.",
                "type": "text",
                "version": 1
              }
            ],
            "direction": null,
            "format": "",
            "indent": 0,
            "type": "paragraph",
            "version": 1
          }
        ],
        "direction": null,
        "format": "",
        "indent": 0,
        "type": "root",
        "version": 1
      }
    }
  },
  "offer.retention.coupon.ecommerce": {
    "en_us": {
      "root": {
        "children": [
          {
            "children": [
              {
                "detail": 0,
                "format": 0,
                "mode": "normal",
                "style": "",
                "text": "Get ",
                "type": "text",
                "version": 1
              },
              {
                "type": "offer_description",
                "version": 1
              },
              {
                "detail": 0,
                "format": 0,
                "mode": "normal",
                "style": "",
                "text": " when you accept this special offer.",
                "type": "text",
                "version": 1
              }
            ],
            "direction": null,
            "format": "",
            "indent": 0,
            "type": "paragraph",
            "version": 1
          }
        ],
        "direction": null,
        "format": "",
        "indent": 0,
        "type": "root",
        "version": 1
      }
    }
  },
  "offer.retention.custom.default": {
    "en_us": {
      "root": {
        "children": [
          {
            "children": [],
            "direction": null,
            "format": "",
            "indent": 0,
            "type": "paragraph",
            "version": 1
          }
        ],
        "direction": null,
        "format": "",
        "indent": 0,
        "type": "root",
        "version": 1
      }
    }
  },
  "offer.retention.downgrade.default": {
    "en_us": {
      "root": {
        "children": [
          {
            "children": [],
            "direction": null,
            "format": "",
            "indent": 0,
            "type": "paragraph",
            "version": 1
          }
        ],
        "direction": null,
        "format": "",
        "indent": 0,
        "type": "root",
        "version": 1
      }
    }
  },
  "offer.retention.downgrade.preview": {
    "en_us": {
      "root": {
        "children": [
          {
            "children": [
              {
                "detail": 0,
                "format": 0,
                "mode": "normal",
                "style": "",
                "text": "Not happy with your current plan?",
                "type": "text",
                "version": 1
              }
            ],
            "direction": null,
            "format": "",
            "indent": 0,
            "type": "paragraph",
            "version": 1
          },
          {
            "children": [
              {
                "detail": 0,
                "format": 0,
                "mode": "normal",
                "style": "",
                "text": "Switch to the Super Saver plan for only ",
                "type": "text",
                "version": 1
              },
              {
                "detail": 0,
                "format": 8,
                "mode": "normal",
                "style": "",
                "text": "$9.99 per month",
                "type": "text",
                "version": 1
              },
              {
                "detail": 0,
                "format": 0,
                "mode": "normal",
                "style": "",
                "text": " instead!",
                "type": "text",
                "version": 1
              }
            ],
            "direction": null,
            "format": "",
            "indent": 0,
            "type": "paragraph",
            "version": 1
          }
        ],
        "direction": null,
        "format": "",
        "indent": 0,
        "type": "root",
        "version": 1
      }
    }
  },
  "offer.retention.modify_subscription.default": {
    "en_us": {
      "root": {
        "children": [
          {
            "children": [],
            "direction": null,
            "format": "",
            "indent": 0,
            "type": "paragraph",
            "version": 1
          }
        ],
        "direction": null,
        "format": "",
        "indent": 0,
        "type": "root",
        "version": 1
      }
    }
  },
  "offer.retention.modify_subscription.preview": {
    "en_us": {
      "root": {
        "children": [
          {
            "children": [
              {
                "detail": 0,
                "format": 0,
                "mode": "normal",
                "style": "",
                "text": "Need extra support?",
                "type": "text",
                "version": 1
              }
            ],
            "direction": null,
            "format": "",
            "indent": 0,
            "type": "paragraph",
            "version": 1
          },
          {
            "children": [
              {
                "detail": 0,
                "format": 0,
                "mode": "normal",
                "style": "",
                "text": "Get access to our ",
                "type": "text",
                "version": 1
              },
              {
                "detail": 0,
                "format": 8,
                "mode": "normal",
                "style": "",
                "text": "Premium Support",
                "type": "text",
                "version": 1
              },
              {
                "detail": 0,
                "format": 0,
                "mode": "normal",
                "style": "",
                "text": " plan!",
                "type": "text",
                "version": 1
              }
            ],
            "direction": null,
            "format": "",
            "indent": 0,
            "type": "paragraph",
            "version": 1
          }
        ],
        "direction": null,
        "format": "",
        "indent": 0,
        "type": "root",
        "version": 1
      }
    }
  },
  "offer.retention.pause_subscription.default": {
    "en_us": {
      "root": {
        "children": [
          {
            "children": [
              {
                "detail": 0,
                "format": 0,
                "mode": "normal",
                "style": "",
                "text": "Instead of canceling, you can pause your subscription ",
                "type": "text",
                "version": 1
              },
              {
                "type": "offer_description",
                "version": 1
              },
              {
                "detail": 0,
                "format": 0,
                "mode": "normal",
                "style": "",
                "text": ".",
                "type": "text",
                "version": 1
              }
            ],
            "direction": null,
            "format": "",
            "indent": 0,
            "type": "paragraph",
            "version": 1
          }
        ],
        "direction": null,
        "format": "",
        "indent": 0,
        "type": "root",
        "version": 1
      }
    }
  },
  "offer.retention.pause_subscription.no_tag": {
    "en_us": {
      "root": {
        "children": [
          {
            "children": [
              {
                "detail": 0,
                "format": 0,
                "mode": "normal",
                "style": "",
                "text": "Instead of canceling, you can pause your subscription.",
                "type": "text",
                "version": 1
              }
            ],
            "direction": null,
            "format": "",
            "indent": 0,
            "type": "paragraph",
            "version": 1
          }
        ],
        "direction": null,
        "format": "",
        "indent": 0,
        "type": "root",
        "version": 1
      }
    }
  },
  "offer.retention.product_swap.default": {
    "en_us": {
      "root": {
        "children": [
          {
            "children": [],
            "direction": null,
            "format": "",
            "indent": 0,
            "type": "paragraph",
            "version": 1
          }
        ],
        "direction": null,
        "format": "",
        "indent": 0,
        "type": "root",
        "version": 1
      }
    }
  },
  "offer.retention.product_swap.preview": {
    "en_us": {
      "root": {
        "children": [
          {
            "children": [
              {
                "detail": 0,
                "format": 0,
                "mode": "normal",
                "style": "",
                "text": "Would an alternative better fit your needs?",
                "type": "text",
                "version": 1
              }
            ],
            "direction": null,
            "format": "",
            "indent": 0,
            "type": "paragraph",
            "version": 1
          }
        ],
        "direction": null,
        "format": "",
        "indent": 0,
        "type": "root",
        "version": 1
      }
    }
  },
  "offer.retention.reschedule_order.default": {
    "en_us": {
      "root": {
        "children": [
          {
            "children": [
              {
                "detail": 0,
                "format": 0,
                "mode": "normal",
                "style": "",
                "text": "Skip or reschedule your next order.",
                "type": "text",
                "version": 1
              }
            ],
            "direction": null,
            "format": "",
            "indent": 0,
            "type": "paragraph",
            "version": 1
          }
        ],
        "direction": null,
        "format": "",
        "indent": 0,
        "type": "root",
        "version": 1
      }
    }
  },
  "offer.retention.trial_extension.default": {
    "en_us": {
      "root": {
        "children": [
          {
            "children": [
              {
                "detail": 0,
                "format": 0,
                "mode": "normal",
                "style": "",
                "text": "Extend your trial for ",
                "type": "text",
                "version": 1
              },
              {
                "type": "offer_description",
                "version": 1
              },
              {
                "detail": 0,
                "format": 0,
                "mode": "normal",
                "style": "",
                "text": ".",
                "type": "text",
                "version": 1
              }
            ],
            "direction": null,
            "format": "",
            "indent": 0,
            "type": "paragraph",
            "version": 1
          }
        ],
        "direction": null,
        "format": "",
        "indent": 0,
        "type": "root",
        "version": 1
      }
    }
  },
  "offer.retention.upgrade.default": {
    "en_us": {
      "root": {
        "children": [
          {
            "children": [],
            "direction": null,
            "format": "",
            "indent": 0,
            "type": "paragraph",
            "version": 1
          }
        ],
        "direction": null,
        "format": "",
        "indent": 0,
        "type": "root",
        "version": 1
      }
    }
  },
  "offer.retention.upgrade.preview": {
    "en_us": {
      "root": {
        "children": [
          {
            "children": [
              {
                "detail": 0,
                "format": 0,
                "mode": "normal",
                "style": "",
                "text": "Not happy with your current plan?",
                "type": "text",
                "version": 1
              }
            ],
            "direction": null,
            "format": "",
            "indent": 0,
            "type": "paragraph",
            "version": 1
          },
          {
            "children": [
              {
                "detail": 0,
                "format": 0,
                "mode": "normal",
                "style": "",
                "text": "Upgrade to the Super Saver plan for only ",
                "type": "text",
                "version": 1
              },
              {
                "detail": 0,
                "format": 2,
                "mode": "normal",
                "style": "",
                "text": "$29.99 per month",
                "type": "text",
                "version": 1
              },
              {
                "detail": 0,
                "format": 0,
                "mode": "normal",
                "style": "",
                "text": " instead!",
                "type": "text",
                "version": 1
              }
            ],
            "direction": null,
            "format": "",
            "indent": 0,
            "type": "paragraph",
            "version": 1
          }
        ],
        "direction": null,
        "format": "",
        "indent": 0,
        "type": "root",
        "version": 1
      }
    }
  },
  "deflection.heading.default": {
    "en_us": {
      "root": {
        "children": [
          {
            "children": [
              {
                "detail": 0,
                "format": 0,
                "mode": "normal",
                "style": "",
                "text": "Wait, you're losing out on so much!",
                "type": "text",
                "version": 1
              }
            ],
            "direction": null,
            "format": "",
            "indent": 0,
            "type": "paragraph",
            "version": 1
          }
        ],
        "direction": null,
        "format": "",
        "indent": 0,
        "type": "root",
        "version": 1
      }
    }
  },
  "deflection.content.default": {
    "en_us": {
      "root": {
        "children": [
          {
            "children": [
              {
                "detail": 0,
                "format": 0,
                "mode": "normal",
                "style": "",
                "text": "If you leave, you'll lose access to all the features you've come to rely on.",
                "type": "text",
                "version": 1
              }
            ],
            "direction": null,
            "format": "",
            "indent": 0,
            "type": "paragraph",
            "version": 1
          }
        ],
        "direction": null,
        "format": "",
        "indent": 0,
        "type": "root",
        "version": 1
      }
    }
  },
  "deflection_snapshot_item.text.default": {
    "en_us": {
      "root": {
        "children": [
          {
            "children": [],
            "direction": null,
            "format": "",
            "indent": 0,
            "type": "paragraph",
            "version": 1
          }
        ],
        "direction": null,
        "format": "",
        "indent": 0,
        "type": "root",
        "version": 1
      }
    }
  },
  "flow_action.text.default": {
    "en_us": {
      "root": {
        "children": [
          {
            "children": [],
            "direction": null,
            "format": "",
            "indent": 0,
            "type": "paragraph",
            "version": 1
          }
        ],
        "direction": null,
        "format": "",
        "indent": 0,
        "type": "root",
        "version": 1
      }
    }
  },
  "flow_routes.eligibility_message.default": {
    "en_us": {
      "root": {
        "children": [
          {
            "children": [],
            "direction": null,
            "format": "",
            "indent": 0,
            "type": "paragraph",
            "version": 1
          }
        ],
        "direction": null,
        "format": "",
        "indent": 0,
        "type": "root",
        "version": 1
      }
    }
  },
};
