import { UnreachableCaseError } from "ts-essentials";

import { offer_autopilot_success_metric_enum } from "../../../../__generated__/graphql";

export default function autopilotFriendlySuccessMetric(
  metric: offer_autopilot_success_metric_enum
) {
  switch (metric) {
    case offer_autopilot_success_metric_enum.revenue_potential:
      return "Revenue potential";

    case offer_autopilot_success_metric_enum.acceptance_rate:
      return "Acceptance rate";

    default:
      throw new UnreachableCaseError(metric);
  }
}
