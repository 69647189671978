import gql from "graphql-tag";

import TranslationFragment from "./TranslationFragment";

export default gql`
  fragment AppFlowActionFragment on flow_action {
    id
    token
    code
    type
    account_id
    appearance
    url
    reroute_to_flow_id
    text_translation {
      ...TranslationFragment
    }
  }
  ${TranslationFragment}
`;
