import { gql, useQuery } from "@apollo/client";

import {
  LocationInputCountriesQuery,
  LocationInputStatesQuery,
  LocationInputStatesQueryVariables,
} from "../../__generated__/graphql";
import IdDropdown from "../../common/IdDropdown";

interface LocationInputProps {
  value: { country: string; state: string };
  onChange: (value: { country: string; state: string }) => void;
}

const LocationInput: React.FunctionComponent<LocationInputProps> = ({
  value,
  onChange,
}) => {
  const { data: countriesData, loading: countriesLoading } =
    useQuery<LocationInputCountriesQuery>(gql`
      query LocationInputCountriesQuery {
        country(order_by: { name: asc }) {
          iso
          name
        }
      }
    `);

  const { data: statesData, loading: statesLoading } = useQuery<
    LocationInputStatesQuery,
    LocationInputStatesQueryVariables
  >(
    gql`
      query LocationInputStatesQuery($country: citext!) {
        state(where: { country: { _eq: $country } }, order_by: { name: asc }) {
          code
          name
        }
      }
    `,
    {
      variables: {
        country: value.country,
      },
      skip: !value.country,
    }
  );

  const countries = (countriesData?.country || []).map((c) => ({
    code: c.iso,
    name: c.name,
  }));

  const states = (statesData?.state || []).map((s) => ({
    code: s.code,
    name: s.name,
  }));

  return (
    <>
      <div>
        <IdDropdown
          isLoading={countriesLoading}
          value={value.country}
          onChange={(country) =>
            onChange({
              ...value,
              country,
            })
          }
          placeholder="Select a country…"
          ids={countries.map((c) => ({
            id: c.code,
            label: c.name,
          }))}
          width="100%"
          showId={false}
        />
      </div>
      {!!value.country && !!states.length && (
        <div tw="mt-2">
          <IdDropdown
            isLoading={statesLoading}
            value={value.state}
            onChange={(state) =>
              onChange({
                ...value,
                state,
              })
            }
            placeholder="Select a state…"
            ids={states.map((s) => ({
              id: s.code,
              label: s.name,
            }))}
            width="100%"
            showId={false}
            resettable
          />
        </div>
      )}
    </>
  );
};

export default LocationInput;
