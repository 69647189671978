import gql from "graphql-tag";

import PrimarySegmentConditionGroupFragment from "../../fragments/PrimarySegmentConditionGroupFragment";

export default gql`
  fragment RuleInlineSegmentFragment on segment {
    id
    primary_segment_condition_group {
      ...PrimarySegmentConditionGroupFragment
    }
  }
  ${PrimarySegmentConditionGroupFragment}
`;
