import { gql, useQuery } from "@apollo/client";
import {
  faCalendarAlt,
  faCalendarClock,
  faChevronsDown,
  faChevronsUp,
  faCut,
  faFlask,
  faMap,
  faPauseCircle,
  faReceipt,
  faSwap,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import { cloneDeep } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import tw, { styled, theme } from "twin.macro";

import {
  AppOfferFragment,
  language_enum,
  offer_goal_enum,
  offer_type_enum,
  OfferDetailsFragment,
  OfferPanelQuery,
  platform_enum,
} from "../../__generated__/graphql";
import Badge from "../../common/badge/Badge";
import Button from "../../common/form/Button";
import FieldInput from "../../common/form/FieldInput";
import FieldLabel from "../../common/form/FieldLabel";
import FieldRow from "../../common/form/FieldRow";
import FormMode from "../../common/form/FormMode";
import { TranslatedForms } from "../../common/form/useTranslatableForm";
import ModalClose from "../../common/modal/ModalClose";
import Panel, { PanelProps } from "../../common/panel/Panel";
import PanelBody from "../../common/panel/PanelBody";
import PanelButtons from "../../common/panel/PanelButtons";
import PanelFormBody from "../../common/panel/PanelFormBody";
import PanelHeader from "../../common/panel/PanelHeader";
import PanelTitle from "../../common/panel/PanelTitle";
import {
  PlatformFeature,
  platformHasFeature,
} from "../../common/platform/features";
import StandardLinkButton from "../../common/StandardLinkButton";
import useTags from "../../common/tags/useTags";
import TranslationsProvider from "../../common/translations/TranslationsProvider";
import useAccountFeatures from "../../common/useAccountFeatures";
import { PropertyValuesProvider } from "../properties/lib/propertyValues";
import DefaultStyles from "../public/flow/DefaultStyles";
import usePaidFeature from "../upgrade-account/usePaidFeature";
import { useUpsellBanner } from "../upgrade-account/useUpsellBanner";
import getInitialFormValues from "./forms/lib/getInitialFormValues";
import OfferFormContainer, {
  OfferFormContainerProps,
} from "./forms/OfferFormContainer";
import { OfferFormValues } from "./forms/types";
import {
  generatePreviewChangePlanOfferFormValues,
  generatePreviewCouponOfferFormValues,
  generatePreviewCustomOfferFormValues,
  generatePreviewDowngradeOfferFormValues,
  generatePreviewModifySubscriptionOfferFormValues,
  generatePreviewPauseSubscriptionOfferFormValues,
  generatePreviewProductSwapOfferFormValues,
  generatePreviewRescheduleOrderOfferFormValues,
  generatePreviewTrialExtensionOfferFormValues,
  generatePreviewUpgradeOfferFormValues,
} from "./lib/generatePreviewOfferFormValues";
import offerFriendlyType from "./lib/offerFriendlyType";
import offerIcon from "./lib/offerIcon";
import swappableProductsPreviewExampleData from "./lib/swappableProductsPreviewExampleData";
import useSwappableProducts from "./lib/useSwappableProducts";
import OfferPreview from "./OfferPreview";
import PreviewHint, { PreviewHintProps } from "./PreviewHint";

type OfferPanelProps = PanelProps & {
  mode: FormMode;
  offer?: OfferDetailsFragment;
  onClose: (offer: AppOfferFragment | null) => void;
  platform: platform_enum;
  goal?: offer_goal_enum;
};

const previewWidth = 560;
const conversionOfferPreviewWidth = 620;
const previewTransition = 150;

const Container = tw.div`flex h-full`;
const FormContainer = styled.div`
  ${tw`flex flex-col`}
  width: 450px;
`;
const OfferButton = tw.button`w-full border-gray-200 border rounded p-4 text-left text-xl 
  mb-3 hover:border-gray-400 flex items-center
  focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200
  disabled:opacity-50 disabled:hover:border-gray-200 disabled:hover:cursor-default`;
const Icon = styled(FontAwesomeIcon)`
  ${tw`mr-5`}

  .fa-primary {
    fill: ${(props) => theme`colors.gray.800`};
  }

  .fa-secondary {
    fill: ${(props) => theme`colors.gray.800`};
    opacity: 0.65;
  }
`;
const Preview = styled.div<{ goal: string }>`
  ${tw`bg-gray-100 h-full z-50`}
  width: ${(props) =>
    props.goal === "conversion" ? conversionOfferPreviewWidth : previewWidth}px;
`;
const PreviewHeader = styled(PanelHeader)`
  height: 59px;
  ${tw`bg-gray-100 text-sm flex items-center`}
`;
const PreviewBody = styled.div<{ offerStyle: string }>`
  ${tw`py-8`}
  height: calc(100% - 55px);
`;
const PreviewModalWrapper = styled.div<{
  hasRibbon: boolean;
  offerGoal: string;
}>`
  ${tw`h-full`}
  ${(props) => props.hasRibbon && tw`pl-4`}
  ${(props) => props.offerGoal === "conversion" && tw`px-4`}
`;

const PreviewTransitionWrapper = styled.div`
  &.preview-enter {
    opacity: 0;
  }
  &.preview-exit {
    opacity: 1;
  }
  &.preview-enter-active {
    opacity: 1;
  }
  &.preview-exit-active {
    opacity: 0;
  }
  &.preview-enter-active,
  &.preview-exit-active {
    transition: opacity ${previewTransition}ms;
  }
`;

const OfferPanel: React.FunctionComponent<OfferPanelProps> = ({
  mode,
  platform,
  offer,
  isOpen,
  onClose,
  goal = offer_goal_enum.retention,
}) => {
  const getType: () => offer_type_enum | undefined = useCallback(
    () => (offer ? offer.type : undefined),
    [offer]
  );

  const { setPaidFeatureRef } = usePaidFeature();
  const { enabled: isFreeMode } = useUpsellBanner(
    "Upgrade to access all offer settings"
  );

  const [type, setType] = useState<offer_type_enum | undefined>(getType());

  const [previewType, setPreviewType] = useState<offer_type_enum>();
  const [formValues, setFormValues] =
    useState<TranslatedForms<OfferFormValues>>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [enabledLanguages, setEnabledLanguages] = useState<language_enum[]>([
    language_enum.en_us,
  ]);

  const previewCouponOffer = generatePreviewCouponOfferFormValues(goal);
  const previewChangePlanOffer = generatePreviewChangePlanOfferFormValues();
  const previewModifySubscriptionOffer =
    generatePreviewModifySubscriptionOfferFormValues();
  const previewPauseSubscriptionOffer =
    generatePreviewPauseSubscriptionOfferFormValues();
  const previewProductSwapOffer = generatePreviewProductSwapOfferFormValues();
  const previewRescheduleOrderOffer =
    generatePreviewRescheduleOrderOfferFormValues();
  const previewTrialExtensionOffer =
    generatePreviewTrialExtensionOfferFormValues();
  const previewUpgradeOffer = generatePreviewUpgradeOfferFormValues();
  const previewDowngradeOffer = generatePreviewDowngradeOfferFormValues();
  const previewCustomOffer = generatePreviewCustomOfferFormValues();

  const { tags } = useTags();

  const { data } = useQuery<OfferPanelQuery>(gql`
    query OfferPanelQuery {
      flow {
        id
        default_language
        flow_languages {
          flow_id
          language
        }
        account {
          id
        }
      }

      platform_plan(limit: 2) {
        platform_id
      }

      property(where: { deleted_at: { _is_null: true } }) {
        id
        name
        type
        format
      }
    }
  `);

  const flow = (data?.flow.length && data.flow[0]) || undefined;
  const defaultLanguage = flow?.default_language || language_enum.en_us;

  const [editingLanguage, setEditingLanguage] = useState(defaultLanguage);

  const { features } = useAccountFeatures();

  const hasAtLeastTwoPlans =
    platform === "custom" || (data?.platform_plan || []).length > 1;

  const propertyConfig = (data?.property || []).reduce(
    (prev, current) => ({
      ...prev,
      [current.id]: {
        name: current.name,
        type: current.type,
        numberFormat: current.format,
      },
    }),
    {}
  );

  useEffect(() => {
    if (data?.flow.length && !!features.translations) {
      let languages: language_enum[] = [];
      for (const flow of data.flow) {
        for (const language of flow.flow_languages) {
          if (languages.includes(language.language)) {
            continue;
          }
          languages.push(language.language);
        }
      }

      setEnabledLanguages(languages);
    }
  }, [data, features.translations]);

  useEffect(() => {
    setType(getType());
  }, [getType]);

  useEffect(() => {
    if (isOpen) {
      // TODO: Reset form
      setIsSubmitting(false);
      setFormValues(undefined);
      setType(getType());
    }
  }, [getType, isOpen]);

  useEffect(() => {
    if (offer && enabledLanguages) {
      setFormValues(getInitialFormValues(offer, enabledLanguages));
    }
  }, [offer, enabledLanguages]);

  const { data: ecommerceProductsData, swappableProducts } =
    useSwappableProducts(type !== "product_swap");

  const handleClickOffer = (type: offer_type_enum) => {
    setType(type);
  };

  const handleMouseEnterOffer = (type: offer_type_enum) => {
    setPreviewType(type);
  };

  const handleClickChangeType = () => {
    setFormValues(undefined);
    setType(undefined);
    setPreviewType(offer_type_enum.coupon);
  };

  const handleFormChange = (forms: TranslatedForms<OfferFormValues>) => {
    if (JSON.stringify(forms) !== JSON.stringify(formValues)) {
      setFormValues(cloneDeep(forms));
    }
  };

  const offerFormContainerProps: Omit<OfferFormContainerProps, "offerType"> = {
    mode,
    offer,
    initialValues: formValues,
    goal,
    platform,
    tags,
    ecommerceProducts: ecommerceProductsData?.platform_ecommerce_product,
    editingLanguage,
    onSubmit: () => setIsSubmitting(true),
    onChange: handleFormChange,
    onClose,
    onChangeEditingLanguage: setEditingLanguage,
    isFreeMode,
  };

  const previewHintProps: PreviewHintProps = {
    formValues,
    previewType,
    goal,
  };

  return (
    <>
      <DefaultStyles
        isEditMode={true}
        modal={false}
        fullScreen={true}
        showOfferTimer={false}
      />
      <Panel
        isOpen={isOpen}
        width={goal === offer_goal_enum.retention ? 1010 : 1060}
      >
        <Container>
          <FormContainer>
            <PanelHeader>
              <PanelTitle>
                {mode === "create" ? <>Create offer</> : <>Edit offer</>}
              </PanelTitle>
              {!!type && (
                <PanelButtons>
                  <Button
                    buttonType="primary"
                    form="create-offer"
                    isLoading={isSubmitting}
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                  <Button
                    type="button"
                    buttonType="default"
                    onClick={() => onClose(null)}
                    disabled={isSubmitting}
                  >
                    Cancel
                  </Button>
                </PanelButtons>
              )}
            </PanelHeader>

            {!!type ? (
              <>
                <PanelFormBody ref={(ref) => setPaidFeatureRef(ref)}>
                  <FieldRow>
                    <FieldLabel>
                      <label htmlFor="type">Type</label>
                    </FieldLabel>
                    <FieldInput tw="flex items-center leading-normal">
                      <Badge
                        color="transparent"
                        icon={offerIcon(type)}
                        tw="inline mr-2 pl-[1px]"
                      >
                        {offerFriendlyType(type)}
                      </Badge>{" "}
                      {mode === "create" && (
                        <StandardLinkButton onClick={handleClickChangeType}>
                          Change
                        </StandardLinkButton>
                      )}
                    </FieldInput>
                  </FieldRow>
                  <TranslationsProvider
                    language={editingLanguage}
                    defaultLanguage={defaultLanguage}
                    enabledLanguages={enabledLanguages || [language_enum.en_us]}
                  >
                    <PropertyValuesProvider
                      propertyValues={{}}
                      propertyConfig={propertyConfig}
                      showPlaceholders={true}
                    >
                      <OfferFormContainer
                        {...offerFormContainerProps}
                        offerType={type}
                      />
                    </PropertyValuesProvider>
                  </TranslationsProvider>
                </PanelFormBody>
              </>
            ) : (
              <PanelBody>
                {platformHasFeature(platform, PlatformFeature.Coupons, {
                  defaultForCustom: true,
                }) && (
                  <OfferButton
                    onClick={() => handleClickOffer(offer_type_enum.coupon)}
                    onMouseEnter={() =>
                      handleMouseEnterOffer(offer_type_enum.coupon)
                    }
                  >
                    <Icon icon={faCut} size="2x" fixedWidth /> Coupon
                  </OfferButton>
                )}
                {platformHasFeature(platform, PlatformFeature.Upgrade) && (
                  <OfferButton
                    onClick={() => handleClickOffer(offer_type_enum.upgrade)}
                    onMouseEnter={() =>
                      handleMouseEnterOffer(offer_type_enum.upgrade)
                    }
                  >
                    <Icon icon={faChevronsUp} size="2x" fixedWidth /> Upgrade
                  </OfferButton>
                )}

                {platformHasFeature(platform, PlatformFeature.Downgrade) && (
                  <OfferButton
                    onClick={() => handleClickOffer(offer_type_enum.downgrade)}
                    onMouseEnter={() =>
                      handleMouseEnterOffer(offer_type_enum.downgrade)
                    }
                  >
                    <Icon icon={faChevronsDown} size="2x" fixedWidth />{" "}
                    Downgrade
                  </OfferButton>
                )}
                {goal === offer_goal_enum.retention &&
                  platformHasFeature(platform, PlatformFeature.ProductSwap) && (
                    <OfferButton
                      onClick={() =>
                        handleClickOffer(offer_type_enum.product_swap)
                      }
                      onMouseEnter={() =>
                        handleMouseEnterOffer(offer_type_enum.product_swap)
                      }
                    >
                      <Icon icon={faSwap} size="2x" fixedWidth /> Product swap
                    </OfferButton>
                  )}
                {goal === offer_goal_enum.retention &&
                  platformHasFeature(
                    platform,
                    PlatformFeature.RescheduleOrder
                  ) && (
                    <OfferButton
                      onClick={() =>
                        handleClickOffer(offer_type_enum.reschedule_order)
                      }
                      onMouseEnter={() =>
                        handleMouseEnterOffer(offer_type_enum.reschedule_order)
                      }
                    >
                      <Icon icon={faCalendarClock} size="2x" fixedWidth />{" "}
                      Reschedule order
                    </OfferButton>
                  )}
                {platformHasFeature(platform, PlatformFeature.ChangePlan, {
                  defaultForCustom: true,
                }) && (
                  <Tippy
                    content="You must have at least two plans available to create a change plan offer."
                    disabled={hasAtLeastTwoPlans}
                  >
                    <div>
                      <OfferButton
                        onClick={() =>
                          handleClickOffer(offer_type_enum.change_plan)
                        }
                        onMouseEnter={() =>
                          handleMouseEnterOffer(offer_type_enum.change_plan)
                        }
                        disabled={!hasAtLeastTwoPlans}
                      >
                        <Icon icon={faMap} size="2x" fixedWidth /> Change plan
                      </OfferButton>
                    </div>
                  </Tippy>
                )}
                {platformHasFeature(platform, PlatformFeature.TrialExtension, {
                  defaultForCustom: true,
                }) && (
                  <OfferButton
                    onClick={() =>
                      handleClickOffer(offer_type_enum.trial_extension)
                    }
                    onMouseEnter={() =>
                      handleMouseEnterOffer(offer_type_enum.trial_extension)
                    }
                  >
                    <Icon icon={faCalendarAlt} size="2x" fixedWidth /> Trial
                    extension
                  </OfferButton>
                )}
                {goal === offer_goal_enum.retention &&
                  platformHasFeature(
                    platform,
                    PlatformFeature.PauseSubscription,
                    {
                      defaultForCustom: true,
                    }
                  ) && (
                    <OfferButton
                      onClick={() =>
                        handleClickOffer(offer_type_enum.pause_subscription)
                      }
                      onMouseEnter={() =>
                        handleMouseEnterOffer(
                          offer_type_enum.pause_subscription
                        )
                      }
                    >
                      <Icon icon={faPauseCircle} size="2x" fixedWidth /> Pause
                      subscription
                    </OfferButton>
                  )}
                {goal === offer_goal_enum.retention &&
                  platformHasFeature(
                    platform,
                    PlatformFeature.ModifySubscription,
                    { defaultForCustom: true }
                  ) && (
                    <OfferButton
                      onClick={() =>
                        handleClickOffer(offer_type_enum.modify_subscription)
                      }
                      onMouseEnter={() =>
                        handleMouseEnterOffer(
                          offer_type_enum.modify_subscription
                        )
                      }
                    >
                      <Icon icon={faReceipt} size="2x" fixedWidth /> Modify
                      subscription
                    </OfferButton>
                  )}
                <OfferButton
                  onClick={() => handleClickOffer(offer_type_enum.custom)}
                  onMouseEnter={() =>
                    handleMouseEnterOffer(offer_type_enum.custom)
                  }
                >
                  <Icon icon={faFlask} size="2x" fixedWidth /> Custom
                </OfferButton>
              </PanelBody>
            )}
          </FormContainer>
          <Preview goal={goal}>
            <PreviewHeader>
              Preview <ModalClose onClose={() => onClose(null)} />
            </PreviewHeader>
            <PreviewBody
              offerStyle={
                (formValues ? formValues[editingLanguage]?.style : "modal") ||
                "modal"
              }
            >
              {formValues && (
                <PreviewModalWrapper
                  offerGoal={goal}
                  hasRibbon={!!formValues[editingLanguage]?.showBanner}
                >
                  <TranslationsProvider
                    language={editingLanguage}
                    defaultLanguage={defaultLanguage}
                    enabledLanguages={enabledLanguages || [language_enum.en_us]}
                  >
                    <PropertyValuesProvider
                      propertyValues={{}}
                      propertyConfig={propertyConfig}
                      showPlaceholders={true}
                    >
                      <OfferPreview
                        formValues={formValues[editingLanguage]}
                        goal={goal}
                        swappableProducts={swappableProducts}
                        pauseReasons={
                          platform === "naviga"
                            ? [
                                {
                                  __typename: "PauseReason",
                                  code: "1",
                                  reason: "Sample reason 1",
                                },
                                {
                                  __typename: "PauseReason",
                                  code: "2",
                                  reason: "Sample reason 2",
                                },
                              ]
                            : undefined
                        }
                      />
                    </PropertyValuesProvider>
                  </TranslationsProvider>
                </PreviewModalWrapper>
              )}

              {!formValues && (
                <PropertyValuesProvider
                  propertyValues={{}}
                  propertyConfig={propertyConfig}
                  showPlaceholders={true}
                >
                  <SwitchTransition>
                    <CSSTransition
                      key={previewType}
                      timeout={previewTransition}
                      classNames="preview"
                    >
                      {previewType === "coupon" ? (
                        <PreviewTransitionWrapper>
                          <PreviewModalWrapper
                            offerGoal={goal}
                            hasRibbon={previewCouponOffer.showBanner}
                          >
                            <OfferPreview
                              key="coupon"
                              formValues={previewCouponOffer}
                              goal={goal}
                            />
                          </PreviewModalWrapper>
                          <PreviewHint {...previewHintProps} />
                        </PreviewTransitionWrapper>
                      ) : previewType === "change_plan" ? (
                        <PreviewTransitionWrapper>
                          <PreviewModalWrapper
                            offerGoal={goal}
                            hasRibbon={previewChangePlanOffer.showBanner}
                          >
                            <OfferPreview
                              key="change_plan"
                              formValues={previewChangePlanOffer}
                              goal={goal}
                            />
                          </PreviewModalWrapper>
                          <PreviewHint {...previewHintProps} />
                        </PreviewTransitionWrapper>
                      ) : previewType === "modify_subscription" ? (
                        <PreviewTransitionWrapper>
                          <PreviewModalWrapper
                            offerGoal={goal}
                            hasRibbon={
                              previewModifySubscriptionOffer.showBanner
                            }
                          >
                            <OfferPreview
                              key="modify_subscription"
                              formValues={previewModifySubscriptionOffer}
                            />
                          </PreviewModalWrapper>
                          <PreviewHint {...previewHintProps} />
                        </PreviewTransitionWrapper>
                      ) : previewType === "trial_extension" ? (
                        <PreviewTransitionWrapper>
                          <PreviewModalWrapper
                            offerGoal={goal}
                            hasRibbon={previewTrialExtensionOffer.showBanner}
                          >
                            <OfferPreview
                              key="trial_extension"
                              formValues={previewTrialExtensionOffer}
                              goal={goal}
                            />
                          </PreviewModalWrapper>
                          <PreviewHint {...previewHintProps} />
                        </PreviewTransitionWrapper>
                      ) : previewType === "pause_subscription" ? (
                        <PreviewTransitionWrapper>
                          <PreviewModalWrapper
                            offerGoal={goal}
                            hasRibbon={previewPauseSubscriptionOffer.showBanner}
                          >
                            <OfferPreview
                              key="pause_subscription"
                              formValues={previewPauseSubscriptionOffer}
                              pauseReasons={
                                platform === "naviga"
                                  ? [
                                      {
                                        __typename: "PauseReason",
                                        code: "1",
                                        reason: "Sample reason 1",
                                      },
                                      {
                                        __typename: "PauseReason",
                                        code: "2",
                                        reason: "Sample reason 2",
                                      },
                                    ]
                                  : undefined
                              }
                            />
                          </PreviewModalWrapper>
                          <PreviewHint {...previewHintProps} />
                        </PreviewTransitionWrapper>
                      ) : previewType === "upgrade" ? (
                        <PreviewTransitionWrapper>
                          <PreviewModalWrapper
                            offerGoal={goal}
                            hasRibbon={previewUpgradeOffer.showBanner}
                          >
                            <OfferPreview
                              key="upgrade"
                              formValues={previewUpgradeOffer}
                              goal={goal}
                            />
                          </PreviewModalWrapper>
                          <PreviewHint {...previewHintProps} />
                        </PreviewTransitionWrapper>
                      ) : previewType === "downgrade" ? (
                        <PreviewTransitionWrapper>
                          <PreviewModalWrapper
                            offerGoal={goal}
                            hasRibbon={previewDowngradeOffer.showBanner}
                          >
                            <OfferPreview
                              key="downgrade"
                              formValues={previewDowngradeOffer}
                              goal={goal}
                            />
                          </PreviewModalWrapper>
                          <PreviewHint {...previewHintProps} />
                        </PreviewTransitionWrapper>
                      ) : previewType === "product_swap" ? (
                        <PreviewTransitionWrapper>
                          <PreviewModalWrapper
                            offerGoal={goal}
                            hasRibbon={false}
                          >
                            <OfferPreview
                              key="product_swap"
                              formValues={previewProductSwapOffer}
                              goal={goal}
                              swappableProducts={
                                swappableProductsPreviewExampleData
                              }
                            />
                          </PreviewModalWrapper>
                          <PreviewHint {...previewHintProps} />
                        </PreviewTransitionWrapper>
                      ) : previewType === "reschedule_order" ? (
                        <PreviewTransitionWrapper>
                          <PreviewModalWrapper
                            offerGoal={goal}
                            hasRibbon={false}
                          >
                            <OfferPreview
                              key="reschedule_order"
                              formValues={previewRescheduleOrderOffer}
                              goal={goal}
                              swappableProducts={
                                swappableProductsPreviewExampleData
                              }
                            />
                          </PreviewModalWrapper>
                          <PreviewHint {...previewHintProps} />
                        </PreviewTransitionWrapper>
                      ) : (
                        <PreviewTransitionWrapper>
                          <PreviewModalWrapper
                            offerGoal={goal}
                            hasRibbon={previewCustomOffer.showBanner}
                          >
                            <OfferPreview
                              key="custom"
                              formValues={previewCustomOffer}
                              goal={goal}
                            />
                          </PreviewModalWrapper>
                          <PreviewHint {...previewHintProps} />
                        </PreviewTransitionWrapper>
                      )}
                    </CSSTransition>
                  </SwitchTransition>
                </PropertyValuesProvider>
              )}
            </PreviewBody>
          </Preview>
        </Container>
      </Panel>
    </>
  );
};

export default OfferPanel;
