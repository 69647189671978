import { gql, useMutation } from "@apollo/client";

import {
  UseUpdateOfferUpgradeMutation,
  UseUpdateOfferUpgradeMutationVariables,
} from "../../../__generated__/graphql";

export default function useUpdateOfferUpgrade() {
  const [updateOfferUpgrade] = useMutation<
    UseUpdateOfferUpgradeMutation,
    UseUpdateOfferUpgradeMutationVariables
  >(gql`
    mutation UseUpdateOfferUpgradeMutation(
      $offerId: Int!
      $changeAt: offer_change_plan_change_at_enum!
    ) {
      update_offer_upgrade_by_pk(
        pk_columns: { offer_id: $offerId }
        _set: { change_at: $changeAt }
      ) {
        offer_id
        change_at
        offer {
          id
          offer_upgrade {
            offer_id
            change_at
          }
        }
      }
    }
  `);

  return updateOfferUpgrade;
}
