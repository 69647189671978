import tw, { css, styled } from "twin.macro";

import Spinner from "../Spinner";

interface RuleEditorOverlayProps {
  visible: boolean;
  zIndex?: number;
}

const Overlay = styled.div<{ visible: boolean; zIndex: number }>`
  ${tw`absolute bg-white inset-0 rounded-b w-full h-full -z-10 opacity-0 transition-all duration-300`}

  ${(props) =>
    props.visible &&
    css`
      ${tw`opacity-50`}
      z-index: ${props.zIndex};
    `}
`;

const RuleEditorOverlay: React.FunctionComponent<RuleEditorOverlayProps> = ({
  visible,
  zIndex = 100,
  children,
}) => (
  <Overlay visible={visible} zIndex={zIndex}>
    {visible ? (
      children ? (
        children
      ) : (
        <Spinner opacity="100%" padding="0 0" />
      )
    ) : null}
  </Overlay>
);

export default RuleEditorOverlay;
