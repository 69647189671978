import React, { useEffect, useState } from "react";
import { isPresent } from "ts-is-present";
import tw from "twin.macro";

import { FlowDeflectionFragment } from "../../../../../__generated__/graphql";
import isTranslationContentEmpty from "../../../../../common/isTranslationContentEmpty";
import { useTranslations } from "../../../../../common/translations/TranslationsProvider";
import { usePropertyValues } from "../../../../properties/lib/propertyValues";
import renderDeflectionContent from "../../lib/renderDeflectionContent";
import FlowButton from "../../ui/FlowButton";

interface FlowActionProps {
  deflection: FlowDeflectionFragment;
  onClick: (deflectionId: number, flowActionId: number) => void;
  previewMode: boolean;
}

const DeflectionActions: React.FunctionComponent<FlowActionProps> = ({
  deflection,
  onClick,
  previewMode,
}) => {
  const { language, defaultLanguage } = useTranslations();
  const { propertyValues, propertyConfig, showPlaceholders } =
    usePropertyValues();

  const actions = deflection.deflection_flow_actions
    .map((a) => a.flow_action)
    .filter(isPresent);

  const [ids, setIds] = useState(actions.map((a) => a.id.toString()));

  useEffect(() => {
    const newIds = actions.map((a) => a.id.toString());
    if (newIds.length !== ids.length) {
      setIds(newIds);
    }
  }, [actions, ids]);

  return (
    <div tw="flex items-center" className="flow-step__deflection-actions">
      {ids.map((id) => {
        const action = actions.find((a) => a.id.toString() === id);
        if (!action) {
          return null;
        }

        const isEmpty =
          previewMode &&
          isTranslationContentEmpty(
            action.text_translation,
            language,
            defaultLanguage
          );

        return (
          <div key={id}>
            <FlowButton
              className="flow-step__deflection-actions__action-button"
              css={[
                tw`min-h-[2.5rem]`,
                isEmpty && tw`!border-opacity-50 border-dashed min-w-[6rem]`,
              ]}
              buttonType="action"
              onClick={(e) => {
                if (previewMode) {
                  return;
                }

                e.preventDefault();

                if (action.type === "url" && action.url) {
                  window.open(action.url, "_blank");
                }

                onClick(deflection.id, action.id);
              }}
            >
              {renderDeflectionContent({
                translation: action.text_translation,
                placeholderText: "",
                previewMode,
                language,
                defaultLanguage,
                propertyValues,
                propertyConfig,
                showPropertyPlaceholders: showPlaceholders,
                disableVideoAutoPlay: true,
              })}
            </FlowButton>
          </div>
        );
      })}
    </div>
  );
};

export default DeflectionActions;
