import { Temporal } from "@js-temporal/polyfill";
import { createRef, useState } from "react";

import {
  FlowOfferGroupFragment,
  FlowSubscriptionFragment,
  PauseReason,
} from "../../../__generated__/graphql";
import renderContent from "../../../common/editor/lib/renderContent";
import ScrollIndicator from "../../../common/ScrollIndicator";
import { useTranslations } from "../../../common/translations/TranslationsProvider";
import { usePropertyValues } from "../../properties/lib/propertyValues";
import { FlowError, FlowText } from "./lib/types";
import OfferContainer from "./OfferContainer";
import FlowErrorAlert from "./ui/FlowErrorAlert";

interface OfferGroupContainerProps {
  offerGroup: FlowOfferGroupFragment | null;
  previewMode: boolean;
  baseClassName: string;
  disableVideoAutoPlay?: boolean;
  onImageLoad?: () => void;
  onDismiss?: () => void;
  pauseReasons?: PauseReason[];
  flowText: FlowText;
  onAccept: (
    selectedOptionIndex: number,
    offerId: string,
    selectedVariantId: string | null | undefined,
    rescheduleTo: Temporal.PlainDate | null | undefined,
    pauseReasonCode: string | null | undefined,
    pauseAt: Temporal.PlainDate | null | undefined,
    resumeAt: Temporal.PlainDate | null | undefined
  ) => void;
  onDecline: () => void;
  isFinalStep: boolean;
  nextOrderDate?: Temporal.PlainDate;
  swappableProducts?: FlowSubscriptionFragment | null;
  startTimerFrom?: Date | null | undefined;
  showCloseX?: boolean;
  showDeclineButton?: boolean;
  error?: FlowError;
  offerButtonsKey?: string;
  onClickPauseOption?: (id: number) => void;
}

const OfferGroupContainer: React.FunctionComponent<
  OfferGroupContainerProps
> = ({
  offerGroup,
  previewMode,
  baseClassName,
  disableVideoAutoPlay,
  onImageLoad,
  onDismiss = () => {},
  pauseReasons,
  flowText,
  onAccept,
  onDecline,
  isFinalStep,
  nextOrderDate,
  swappableProducts = null,
  startTimerFrom = null,
  showDeclineButton = true,
  showCloseX = false,
  error,
  offerButtonsKey,
  onClickPauseOption = () => {},
  ...props
}) => {
  const [isCanceling, setIsCanceling] = useState(false);

  const { translationValue } = useTranslations();
  const { propertyValues, propertyConfig, showPlaceholders } =
    usePropertyValues();

  const target = createRef() as React.RefObject<HTMLDivElement>;

  const offers = offerGroup?.offer_group_offers.filter((o) => !!o.offer);

  const headlineText = translationValue(offerGroup?.headline_translation).value;
  const contentText = offerGroup?.content_translation
    ? renderContent(
        translationValue(offerGroup?.content_translation).value,
        false,
        propertyValues,
        propertyConfig,
        showPlaceholders
      )
    : "";
  const cancelText = translationValue(
    offerGroup?.cancel_text_translation
  ).value;

  return (
    <>
      {!!error && (
        <div className="flow-group-modal__error">
          <FlowErrorAlert error={error} />
        </div>
      )}

      <div className="flow-group-modal__header">
        <h2>{headlineText}</h2>
        <h4>{contentText}</h4>
      </div>

      <div className="flow-group-modal__body" ref={target}>
        {offerGroup &&
          offers &&
          offers.map(
            (offer) =>
              offer.offer && (
                <div
                  className="flow-group-offer"
                  data-vulcan="offer"
                  data-vulcan-id={offerGroup?.token}
                  key={offer.offer.token}
                >
                  <OfferContainer
                    offer={offer.offer}
                    previewMode={previewMode}
                    onImageLoad={onImageLoad}
                    baseClassName="flow-group-offer"
                    key={offer.offer.token}
                    flowText={flowText}
                    onAccept={() => {}}
                    onAcceptGroupOffer={onAccept}
                    onDecline={onDecline}
                    isFinalStep={isFinalStep}
                    showCloseX={showCloseX}
                    showDeclineButton={false}
                    isGrouped={true}
                    showImages={offerGroup.show_images}
                    offerButtonsKey={offerButtonsKey}
                    pauseReasons={pauseReasons}
                    swappableProducts={swappableProducts}
                    nextOrderDate={nextOrderDate}
                    isCanceling={isCanceling}
                  />
                </div>
              )
          )}
      </div>
      <div className="flow-group-modal__scroll-indicators">
        <ScrollIndicator target={target} count={offers?.length || 0} />
      </div>
      <div className="flow-group-modal__footer">
        <button
          disabled={isCanceling}
          onClick={() => {
            if (previewMode) {
              return;
            }

            setIsCanceling(true);

            setTimeout(() => {
              onDecline();
            }, 100);
          }}
        >
          {cancelText}
        </button>
      </div>
    </>
  );
};

export default OfferGroupContainer;
