import TimePicker from "react-time-picker";
import { styled } from "twin.macro";

import sharedInputStyles from "./form/input/sharedInputStyles";

export default styled(TimePicker)`
  .react-time-picker {
    display: inline-flex;
    position: relative;
  }

  .react-time-picker,
  .react-time-picker *,
  .react-time-picker *:before,
  .react-time-picker *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .react-time-picker--disabled {
    background-color: #f0f0f0;
    color: #6d6d6d;
  }

  .react-time-picker__wrapper {
    display: flex;
    flex-grow: 0;
    flex-shrink: 1;
    width: 8em;
    ${sharedInputStyles}
  }

  .react-time-picker__inputGroup {
    min-width: calc((4px * 3) + 1.5em * 6 + 0.217em * 2);
    flex-grow: 1;
    padding: 0 2px;
    box-sizing: content-box;
  }

  .react-time-picker__inputGroup__divider {
    padding: 1px 0;
    white-space: pre;
  }

  .react-time-picker__inputGroup__divider,
  .react-time-picker__inputGroup__leadingZero {
    display: inline-block;
  }

  .react-time-picker__inputGroup__input {
    min-width: 1.5em;
    height: 100%;
    position: relative;
    padding: 0 1px;
    border: 0;
    background: none;
    color: currentColor;
    font: inherit;
    box-sizing: content-box;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;

    text-align: center;
  }

  .react-time-picker__inputGroup__input::-webkit-outer-spin-button,
  .react-time-picker__inputGroup__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }

  .react-time-picker__inputGroup__input:invalid {
    background: rgba(255, 0, 0, 0.1);
  }

  .react-time-picker__inputGroup__leadingZero {
    padding-left: 0.25em;
  }

  .react-time-picker__inputGroup__input--hasLeadingZero {
    /* margin-left: -1.5em;
    padding-left: calc(1px + 1.5em); */
    min-width: 1.2em;
    margin-left: -0.7em;
    padding-left: calc(1px + 0.3em);
  }

  .react-time-picker__inputGroup__amPm {
    font: inherit;
    -webkit-appearance: menulist;
    -moz-appearance: menulist;
    appearance: menulist;
  }

  .react-time-picker__button {
    border: 0;
    background: transparent;
    padding: 4px 6px;
  }

  .react-time-picker__button:enabled {
    cursor: pointer;
  }

  .react-time-picker__button:enabled:hover .react-time-picker__button__icon,
  .react-time-picker__button:enabled:focus .react-time-picker__button__icon {
    stroke: #0078d7;
  }

  .react-time-picker__button:disabled .react-time-picker__button__icon {
    stroke: #6d6d6d;
  }

  .react-time-picker__button svg {
    display: inherit;
  }

  .react-time-picker__clock {
    width: 200px;
    height: 200px;
    max-width: 100vw;
    padding: 25px;
    background-color: white;
    border: thin solid #a0a096;
    z-index: 1;
  }

  .react-time-picker__clock--closed {
    display: none;
  }
`;
