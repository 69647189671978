import classNames from "classnames";
import tw, { styled } from "twin.macro";

const FieldRow = styled.div.attrs((props: { className?: string }) => ({
  className: classNames("field-row", props.className),
}))<{
  className: string;
}>`
  ${tw`flex`};

  .panel-form-body > &:first-child {
    ${tw`-mt-2`}
  }
`;

export default FieldRow;
