import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface ModalCloseProps {
  onClose: () => void;
}

const ModalClose: React.FunctionComponent<ModalCloseProps> = ({ onClose }) => (
  <button
    type="button"
    onClick={onClose}
    tw="absolute text-gray-400 outline-none focus:outline-none hover:text-gray-600 text-xl transition-all duration-300 top-[13px] right-[17px]"
  >
    <FontAwesomeIcon icon={faTimes} />
  </button>
);

export default ModalClose;
