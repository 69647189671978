import { Editor } from "slate";

import { options } from "../../TextEditor";

export default function withOfferTag<T extends Editor>(editor: T) {
  const { isInline, isVoid } = editor;

  editor.isInline = (element) =>
    element.type === options.offer_tag.type ? true : isInline(element);

  editor.isVoid = (element) =>
    element.type === options.offer_tag.type ? true : isVoid(element);

  return editor;
}
