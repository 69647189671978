import { useApolloClient } from "@apollo/client";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy, { useSingleton } from "@tippyjs/react";
import classNames from "classnames";
import { nanoid } from "nanoid";
import { useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
  ResponderProvided,
} from "react-beautiful-dnd";
import { useToasts } from "react-toast-notifications";
import tw, { css, styled } from "twin.macro";

import {
  FlowFormFragment,
  language_enum,
  question_type_enum,
} from "../../../../__generated__/graphql";
import getFlowObjectVersion from "../../../../common/flow/getFlowObjectVersion";
import { TranslatedForms } from "../../../../common/form/useTranslatableForm";
import TheFlowFormFragment from "../../../../common/fragments/FlowFormFragment";
import { useTranslations } from "../../../../common/translations/TranslationsProvider";
import AddQuestionModal from "../../../flow/edit/AddQuestionModal";
import { FormValues } from "../../../flow/edit/AddQuestionModal";
import { FormManager, reorderQuestions } from "../../../flow/edit/formManager";
import { QuestionManager } from "../../../flow/edit/questionManager";
import usePaidFeature from "../../../upgrade-account/usePaidFeature";
import { useFlowVersion } from "../FlowVersionProvider";
import { FlowText, FormStepQuestion, QuestionAnswer } from "../lib/types";
import FlowButton from "../ui/FlowButton";
import FormQuestion from "./FormQuestion";

interface FormProps {
  form: FlowFormFragment;
  formStepQuestions: FormStepQuestion[];
  isEditMode: boolean;
  isSubmitting: boolean;
  flowText: FlowText;
  onChange: (value: QuestionAnswer) => void;
  formManager?: FormManager;
  enabledLanguages: language_enum[];
  questionManager?: QuestionManager;
  isFreeMode?: boolean;
}

const FormQuestions = styled.div<{ isEditMode: boolean }>`
  ${tw`w-full -mt-2`}

  ${(props) => props.isEditMode && tw`-ml-2`}

  &.questions-are-draggable {
    ${tw`-mt-4`}
  }

  &:has(.question-is-dragging) {
    button,
    [class$="inline-text-context-pencil"],
    [class$="inline-text-context-action-menu"] {
      ${tw`!opacity-0`}
    }
  }
`;

const FormQuestionContainer = styled.div`
  ${tw`mb-4`}

  &.questions-are-draggable {
    ${tw`pl-3 py-2`}
  }

  &.question-is-dragging {
    ${tw`bg-white rounded border border-gray-200`}
    z-index: 10;
  }
`;

const Form: React.FunctionComponent<FormProps> = ({
  form,
  formStepQuestions,
  isEditMode,
  isSubmitting,
  flowText,
  onChange,
  formManager,
  enabledLanguages,
  questionManager,
  isFreeMode = false,
}) => {
  const apollo = useApolloClient();

  const { getObjectVersion } = useFlowVersion();

  const { defaultLanguage } = useTranslations();

  const { addToast } = useToasts();

  const [source, target] = useSingleton({ disabled: !isFreeMode });
  const { setPaidFeatureRef } = usePaidFeature();

  const [addQuestionModalIsOpen, setAddQuestionModalIsOpen] = useState(false);
  const [addQuestionModalKey, setAddQuestionModalKey] = useState(nanoid());
  const [isDragging, setIsDragging] = useState(false);
  const version = getObjectVersion(form);

  const questions = formStepQuestions.map(
    (formStepQuestion) => formStepQuestion.question
  );

  const onDragEnd = async (result: DropResult, provided: ResponderProvided) => {
    if (!result.destination || !formManager) {
      setIsDragging(false);
      return;
    }

    const movingQuestion = formStepQuestions[result.source.index].question;
    const movingQuestionVersion = getFlowObjectVersion(movingQuestion, "draft");

    const reordered = reorderQuestions(
      questions,
      result.source.index,
      result.destination.index
    );

    // Check if any previous questions would depend on the moving question.
    const leadingQuestions = reordered.slice(0, result.destination.index);

    const leadingConditionedOptionIds = leadingQuestions.flatMap(
      (q) => getFlowObjectVersion(q, "draft").condition_question_option_ids
    );
    const leadingConditionedQuestionIds = leadingQuestions.flatMap(
      (q) =>
        getFlowObjectVersion(q, "draft").condition_include_other_in_question_ids
    );

    if (
      leadingConditionedOptionIds?.some((optionId) =>
        movingQuestionVersion.question_option_ids?.includes(optionId)
      ) ||
      leadingConditionedQuestionIds?.includes(movingQuestion.id)
    ) {
      addToast(
        <div>
          Can't move question here because a preceding one would depend on it.
        </div>,
        { appearance: "error" }
      );

      setIsDragging(false);
      return;
    }

    if (
      movingQuestionVersion.condition_question_option_ids.length ||
      movingQuestionVersion.condition_include_other_in_question_ids.length
    ) {
      // Check if the moving question would depend on any trailing question.
      const trailingQuestions = reordered.slice(
        result.destination.index + 1,
        reordered.length
      );
      const trailingQuestionIds = trailingQuestions.map((q) => q.id);
      const trailingQuestionOptionIds = trailingQuestions
        .filter((q) => q.type === "radio")
        .flatMap((q) => getFlowObjectVersion(q, "draft").question_option_ids);

      if (
        movingQuestionVersion.condition_question_option_ids?.some(
          (optionId: number) => trailingQuestionOptionIds?.includes(optionId)
        ) ||
        movingQuestionVersion.condition_include_other_in_question_ids?.some(
          (id: number) => trailingQuestionIds?.includes(id)
        )
      ) {
        addToast(
          <div>
            Can't move question here because it would depend on a later
            question.
          </div>,
          { appearance: "error" }
        );

        setIsDragging(false);
        return;
      }
    }

    apollo.writeFragment<FlowFormFragment>({
      id: apollo.cache.identify({ ...form }),
      fragment: TheFlowFormFragment,
      fragmentName: "FlowFormFragment",
      data: {
        ...form,
        draft_version: {
          ...version,
          form_version_questions: reordered.map((q) => ({
            __typename: "form_version_question",
            question_id: q.id,
            form_version_id: version.id,
            question: q,
          })),
        },
      },
      variables: {
        includeDrafts: true,
      },
    });

    await formManager.moveQuestion(
      form,
      result.source.index,
      result.destination.index
    );

    setIsDragging(false);
  };

  const handleAddQuestion = async (
    forms: TranslatedForms<FormValues>,
    type: question_type_enum
  ) => {
    if (!formManager || !questionManager) {
      throw new Error("Failed to add question");
    }

    const id = await questionManager.addQuestion(type, forms, false);

    if (formManager) {
      formManager.addExistingQuestion(form, id);
    }
  };

  const handleDeleteQuestion = (questionId: number) => {
    if (formManager) {
      formManager.deleteQuestion(form, questionId);
    }
  };

  const formFormId = `form-${form.id}`;

  return (
    <form
      id={formFormId}
      autoComplete="off"
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <>
        <Tippy singleton={source} delay={600} placement="bottom" />
        <FormQuestions
          className={classNames(isEditMode && "questions-are-draggable")}
          isEditMode={isEditMode}
        >
          <DragDropContext
            onDragStart={() => setIsDragging(true)}
            onDragEnd={onDragEnd}
          >
            <Droppable droppableId={formFormId} isDropDisabled={!isEditMode}>
              {(provided, droppableSnapshot) => (
                <>
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {formStepQuestions
                      .filter((formStepQuestion) => !formStepQuestion.isHidden)
                      .map((formStepQuestion, index) => (
                        <Draggable
                          key={formStepQuestion.question.id}
                          draggableId={String(formStepQuestion.question.id)}
                          index={index}
                          isDragDisabled={!isEditMode}
                        >
                          {(provided, snapshot) => (
                            <FormQuestionContainer
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              className={classNames(
                                "form-question--container",
                                isEditMode && "questions-are-draggable",
                                snapshot.isDragging && "question-is-dragging"
                              )}
                            >
                              <FormQuestion
                                form={form}
                                formStepQuestion={formStepQuestion}
                                isEditMode={isEditMode}
                                isSubmitting={isSubmitting}
                                questionManager={questionManager}
                                formManager={formManager}
                                flowText={flowText}
                                onChange={onChange}
                                onDelete={handleDeleteQuestion}
                                enabledLanguages={enabledLanguages}
                                dragHandleProps={provided.dragHandleProps}
                                isFreeMode={isFreeMode}
                              />
                            </FormQuestionContainer>
                          )}
                        </Draggable>
                      ))}
                  </div>
                </>
              )}
            </Droppable>
            {isEditMode && (
              <div tw="flex shrink ml-8" ref={(ref) => setPaidFeatureRef(ref)}>
                <Tippy
                  singleton={target}
                  content="Upgrade to add more questions."
                >
                  <div>
                    <FlowButton
                      buttonType="action"
                      onClick={() => setAddQuestionModalIsOpen(!isFreeMode)}
                      disabled={isFreeMode}
                      css={
                        isDragging
                          ? css`
                              opacity: 0;
                            `
                          : undefined
                      }
                    >
                      <FontAwesomeIcon icon={faPlus} /> Add question
                    </FlowButton>
                  </div>
                </Tippy>
              </div>
            )}
          </DragDropContext>
        </FormQuestions>
      </>
      {isEditMode && !!formManager && !!questionManager && (
        <AddQuestionModal
          key={addQuestionModalKey}
          mode="create"
          language={language_enum.en_us}
          enabledLanguages={enabledLanguages}
          defaultLanguage={defaultLanguage}
          isOpen={addQuestionModalIsOpen}
          onSave={async (forms, type) => {
            try {
              handleAddQuestion(forms, type);
            } catch (e) {
              addToast(<div>Oops! The question couldn't be created.</div>, {
                appearance: "error",
              });
            }
          }}
          onClose={() => {
            setAddQuestionModalIsOpen(false);
            setAddQuestionModalKey(nanoid());
          }}
        />
      )}
    </form>
  );
};

export default Form;
