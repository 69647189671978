import { gql, useMutation, useQuery } from "@apollo/client";
import { faWeightHanging } from "@fortawesome/pro-regular-svg-icons";
import {
  faInfoCircle,
  faPlus,
  faTimesCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Decimal from "decimal.js";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import tw, { styled, theme } from "twin.macro";

import {
  offer_goal_enum,
  OfferTestFragment,
  OfferTestPanelMutation,
  OfferTestPanelMutationVariables,
  OfferTestPanelQuery,
  OfferTestPanelUpdateTestMutation,
  OfferTestPanelUpdateTestMutationVariables,
  OfferTestPanelUpdateVariantMutation,
  OfferTestPanelUpdateVariantMutationVariables,
} from "../../__generated__/graphql";
import Button from "../../common/form/Button";
import FieldError from "../../common/form/FieldError";
import FieldInput from "../../common/form/FieldInput";
import FieldLabel from "../../common/form/FieldLabel";
import FieldRow from "../../common/form/FieldRow";
import FormMode from "../../common/form/FormMode";
import TextInput from "../../common/form/input/TextInput";
import TheOfferTestFragment from "../../common/fragments/OfferTestFragment";
import HelpBlock from "../../common/HelpBlock";
import Panel, { PanelProps } from "../../common/panel/Panel";
import PanelBody from "../../common/panel/PanelBody";
import PanelButtons from "../../common/panel/PanelButtons";
import PanelTitle from "../../common/panel/PanelTitle";
import StandardExternalLink from "../../common/StandardExternalLink";
import { TRACK_EVENT_OFFER_TEST_CREATED } from "../../common/track-events/events";
import useAccountFeatures from "../../common/useAccountFeatures";
import useFocusFirstEmptyInput from "../../common/useFocusFirstEmptyInput";
import usePrevious from "../../common/usePrevious";
import useTrackEvent from "../../common/useTrackEvent";
import OfferIdDropdown from "../segments/OfferIdDropdown";

interface FormValues {
  name: string;
  controlOfferId: string;
  controlOfferWeight: number;
  variantOffer1Id: string;
  variantOffer1Weight: number;
  variantOffer2Id: string;
  variantOffer2Weight: number;
}

type OfferTestPanelProps = PanelProps & {
  mode: FormMode;
  offerTest?: OfferTestFragment;
  goal?: offer_goal_enum;
  onClose: (offerTest: OfferTestFragment | undefined) => void;
};

const DeleteButton = styled.button`
  ${tw`flex justify-between items-center text-red-500 opacity-75 hover:opacity-100 focus:outline-none
  disabled:text-gray-400 disabled:opacity-100 disabled:cursor-default -ml-2`}
`;

const OfferTestPanel: React.FunctionComponent<OfferTestPanelProps> = ({
  mode,
  offerTest,
  goal = offer_goal_enum.retention,
  onClose,
  ...props
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [numVariants, setNumVariants] = useState(
    offerTest ? offerTest.offer_test_offers.length : 1
  );
  const trackEvent = useTrackEvent();
  const [formRef, setFormRef] = useState<HTMLFormElement | null>(null);
  useFocusFirstEmptyInput(formRef);

  const { features } = useAccountFeatures();

  const { data, loading, refetch } = useQuery<OfferTestPanelQuery>(
    gql`
      query OfferTestPanelQuery($goal: offer_goal_enum!) {
        offer(where: { goal: { _eq: $goal }, deleted_at: { _is_null: true } }) {
          id
          token
          name
        }

        offer_test(where: { deleted_at: { _is_null: true } }) {
          id
          name
        }
      }
    `,
    { variables: { goal }, fetchPolicy: "cache-and-network" }
  );

  const isOpen = props.isOpen;
  const prevIsOpen = usePrevious(isOpen);

  useEffect(() => {
    if (!prevIsOpen && isOpen) {
      refetch();
    }
  }, [isOpen, prevIsOpen, refetch]);

  const [createTest] = useMutation<
    OfferTestPanelMutation,
    OfferTestPanelMutationVariables
  >(gql`
    mutation OfferTestPanelMutation($object: offer_test_insert_input!) {
      insert_offer_test_one(object: $object) {
        ...OfferTestFragment
      }
    }
    ${TheOfferTestFragment}
  `);

  const [updateTest] = useMutation<
    OfferTestPanelUpdateTestMutation,
    OfferTestPanelUpdateTestMutationVariables
  >(gql`
    mutation OfferTestPanelUpdateTestMutation(
      $id: Int!
      $name: String!
      $controlWeight: Int!
    ) {
      update_offer_test_by_pk(
        pk_columns: { id: $id }
        _set: { name: $name, control_weight: $controlWeight }
      ) {
        id
        name
        control_weight
      }
    }
  `);

  const [updateVariant] = useMutation<
    OfferTestPanelUpdateVariantMutation,
    OfferTestPanelUpdateVariantMutationVariables
  >(gql`
    mutation OfferTestPanelUpdateVariantMutation(
      $offerTestId: Int!
      $offerId: Int!
      $weight: Int!
    ) {
      update_offer_test_offer_by_pk(
        pk_columns: { offer_test_id: $offerTestId, offer_id: $offerId }
        _set: { weight: $weight }
      ) {
        offer_test_id
        offer_id
        weight
      }
    }
  `);

  const { register, handleSubmit, watch, setValue, formState, control } =
    useForm<FormValues>({
      defaultValues: offerTest
        ? {
            name: offerTest.name,
            controlOfferId: offerTest.control_offer.token,
            controlOfferWeight: offerTest.control_weight,
            variantOffer1Id: offerTest.offer_test_offers[0].offer.token,
            variantOffer1Weight: offerTest.offer_test_offers[0].weight,
            variantOffer2Id:
              offerTest.offer_test_offers.length > 1
                ? offerTest.offer_test_offers[1].offer.token
                : undefined,
            variantOffer2Weight:
              offerTest.offer_test_offers.length > 1
                ? offerTest.offer_test_offers[1].weight
                : 100,
          }
        : {
            controlOfferWeight: 100,
            variantOffer1Weight: 100,
            variantOffer2Weight: 100,
          },
    });

  const controlOfferId = watch("controlOfferId");
  const variantOffer1Id = watch("variantOffer1Id");
  const variantOffer2Id = watch("variantOffer2Id");

  const values = watch();

  let totalWeight =
    Number(values.controlOfferWeight) + Number(values.variantOffer1Weight);
  if (numVariants > 1) {
    totalWeight += Number(values.variantOffer2Weight);
  }

  useEffect(() => {
    if (values.controlOfferWeight < 1) {
      setValue("controlOfferWeight", 1);
    }
    if (values.controlOfferWeight > 100) {
      setValue("controlOfferWeight", 100);
    }

    if (values.variantOffer1Weight < 1) {
      setValue("variantOffer1Weight", 1);
    }
    if (values.variantOffer1Weight > 100) {
      setValue("variantOffer1Weight", 100);
    }

    if (values.variantOffer2Weight < 1) {
      setValue("variantOffer2Weight", 1);
    }
    if (values.variantOffer2Weight > 100) {
      setValue("variantOffer2Weight", 100);
    }
  }, [setValue, values]);

  const onSubmit = handleSubmit(async (values) => {
    setIsSaving(true);

    const controlOffer = controlOfferId
      ? data?.offer.find((o) => o.token === controlOfferId)
      : undefined;
    const variantOffer1 = variantOffer1Id
      ? data?.offer.find((o) => o.token === variantOffer1Id)
      : undefined;
    const variantOffer2 = variantOffer2Id
      ? data?.offer.find((o) => o.token === variantOffer2Id)
      : undefined;

    if (!controlOffer || !variantOffer1) {
      throw new Error();
    }

    const variants: Array<{ offerId: number; weight: number }> = [];
    variants.push({
      offerId: variantOffer1.id,
      weight: values.variantOffer1Weight,
    });
    if (variantOffer2 && numVariants > 1) {
      variants.push({
        offerId: variantOffer2.id,
        weight: values.variantOffer2Weight,
      });
    }

    if (mode === "create") {
      const result = await createTest({
        variables: {
          object: {
            name: values.name,
            confidence_threshold: 0.95,
            control_offer_id: controlOffer.id,
            control_weight: values.controlOfferWeight,
            offer_test_offers: {
              data: variants.map((v) => ({
                offer_id: v.offerId,
                weight: v.weight,
              })),
            },
          },
        },
      });

      if (!result.data?.insert_offer_test_one) {
        throw new Error();
      }

      await trackEvent(TRACK_EVENT_OFFER_TEST_CREATED, {
        offer_test_id: result.data.insert_offer_test_one.token,
      });

      onClose(result.data.insert_offer_test_one);
    } else {
      if (!offerTest) {
        throw new Error();
      }

      await updateTest({
        variables: {
          id: offerTest.id,
          name: values.name,
          controlWeight: values.controlOfferWeight,
        },
      });

      await updateVariant({
        variables: {
          offerTestId: offerTest.id,
          offerId: offerTest.offer_test_offers[0].offer_id,
          weight: values.variantOffer1Weight,
        },
      });

      if (offerTest.offer_test_offers.length > 1) {
        await updateVariant({
          variables: {
            offerTestId: offerTest.id,
            offerId: offerTest.offer_test_offers[1].offer_id,
            weight: values.variantOffer2Weight,
          },
        });
      }

      onClose(undefined);
    }
  });

  const excludeIds: string[] = [];
  if (controlOfferId) {
    excludeIds.push(controlOfferId);
  }
  if (variantOffer1Id) {
    excludeIds.push(variantOffer1Id);
  }
  if (variantOffer2Id && numVariants > 1) {
    excludeIds.push(variantOffer2Id);
  }

  return (
    <Panel
      {...props}
      isLoading={loading}
      header={
        <>
          <PanelTitle>
            {mode === "create" ? "Create A/B test" : "Edit A/B test"}
          </PanelTitle>
          <PanelButtons>
            <Button
              buttonType="primary"
              form="create-offer-test"
              isLoading={isSaving}
              onClick={() => {}}
            >
              Save
            </Button>
            <Button
              type="button"
              buttonType="default"
              onClick={() => onClose(undefined)}
            >
              Cancel
            </Button>
          </PanelButtons>
        </>
      }
    >
      <PanelBody>
        {mode === "create" && (
          <HelpBlock
            tw="mt-4 mb-4"
            size="sm"
            color="gray"
            icon={
              <FontAwesomeIcon
                icon={faInfoCircle}
                color={theme`colors.gray.400`}
              />
            }
            content={
              <>
                Select multiple offers to A/B test them automatically.{" "}
                <StandardExternalLink
                  href="https://prosperstack.com/docs/ab-testing/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  See the documentation.
                </StandardExternalLink>
              </>
            }
          />
        )}
        <form
          id="create-offer-test"
          onSubmit={onSubmit}
          ref={(ref) => setFormRef(ref)}
        >
          <FieldRow>
            <FieldLabel>
              <label>Name</label>
            </FieldLabel>
            <FieldInput>
              <TextInput
                {...register("name", {
                  required: true,
                  validate: async (value) => {
                    if (!formState.dirtyFields.name) {
                      return undefined;
                    }

                    if (
                      data?.offer_test.find(
                        (t) =>
                          t.id !== offerTest?.id &&
                          t.name.toLowerCase().trim() ===
                            value.toLowerCase().trim()
                      )
                    ) {
                      return "An A/B test with that name already exists.";
                    }
                  },
                })}
                width="full"
                fieldError={formState.errors.name}
              />
              <FieldError error={formState.errors.name} />
            </FieldInput>
          </FieldRow>
          <FieldRow>
            <FieldLabel>
              <label>Control</label>
            </FieldLabel>
            <FieldInput>
              <Controller
                control={control}
                name="controlOfferId"
                rules={{ required: true }}
                render={({ field }) => (
                  <OfferIdDropdown
                    value={field.value || ""}
                    onChange={field.onChange}
                    placeholder="Select an offer…"
                    width="100%"
                    filterGoal={goal}
                    disabled={mode === "edit"}
                    excludeAutopilot={true}
                  />
                )}
              />
              {!!features.ab_test_weights && (
                <div tw="mt-3">
                  <span tw="text-gray-400">
                    <FontAwesomeIcon icon={faWeightHanging} /> Weight:{" "}
                  </span>
                  <TextInput
                    {...register("controlOfferWeight", {
                      min: 1,
                      max: 100,
                    })}
                    type="number"
                    min={1}
                    max={100}
                    tw="py-0 ml-2 pr-0 w-[60px]"
                  />
                  <span tw="ml-2 text-gray-400">
                    (
                    {new Decimal(Number(values.controlOfferWeight))
                      .dividedBy(totalWeight)
                      .times(100)
                      .toDecimalPlaces(1)
                      .toString()}
                    %)
                  </span>
                </div>
              )}
            </FieldInput>
          </FieldRow>
          <FieldRow>
            <FieldLabel>
              <label>Variant 1</label>
            </FieldLabel>
            <FieldInput>
              <Controller
                control={control}
                name="variantOffer1Id"
                rules={{ required: true }}
                render={({ field }) => (
                  <OfferIdDropdown
                    value={field.value || ""}
                    onChange={field.onChange}
                    placeholder="Select an offer…"
                    excludeIds={excludeIds.filter(
                      (id) => id !== variantOffer1Id
                    )}
                    width="100%"
                    filterGoal={goal}
                    disabled={mode === "edit"}
                    excludeAutopilot={true}
                  />
                )}
              />
              {!!features.ab_test_weights && (
                <div tw="mt-3">
                  <span tw="text-gray-400">
                    <FontAwesomeIcon icon={faWeightHanging} /> Weight:{" "}
                  </span>
                  <TextInput
                    {...register("variantOffer1Weight", {
                      min: 1,
                      max: 100,
                    })}
                    type="number"
                    min={1}
                    max={100}
                    tw="py-0 ml-2 pr-0 w-[60px]"
                  />
                  <span tw="ml-2 text-gray-400">
                    (
                    {new Decimal(Number(values.variantOffer1Weight))
                      .dividedBy(totalWeight)
                      .times(100)
                      .toDecimalPlaces(1)
                      .toString()}
                    %)
                  </span>
                </div>
              )}
            </FieldInput>
          </FieldRow>
          <FieldRow css={numVariants < 2 ? tw`hidden` : undefined}>
            <FieldLabel>
              <label>Varant 2</label>
            </FieldLabel>
            <FieldInput>
              <Controller
                control={control}
                name="variantOffer2Id"
                render={({ field }) => (
                  <OfferIdDropdown
                    value={field.value || ""}
                    onChange={field.onChange}
                    placeholder="Select an offer…"
                    excludeIds={excludeIds.filter(
                      (id) => id !== variantOffer2Id
                    )}
                    width={mode === "create" ? "290px" : undefined}
                    filterGoal={goal}
                    disabled={mode === "edit"}
                    excludeAutopilot={true}
                  />
                )}
              />
              {!!features.ab_test_weights && (
                <div tw="mt-3">
                  <span tw="text-gray-400">
                    <FontAwesomeIcon icon={faWeightHanging} /> Weight:{" "}
                  </span>
                  <TextInput
                    {...register("variantOffer2Weight", {
                      min: 1,
                      max: 100,
                    })}
                    type="number"
                    min={1}
                    max={100}
                    tw="py-0 ml-2 pr-0 w-[60px]"
                  />
                  <span tw="ml-2 text-gray-400">
                    (
                    {new Decimal(Number(values.variantOffer2Weight))
                      .dividedBy(totalWeight)
                      .times(100)
                      .toDecimalPlaces(1)
                      .toString()}
                    ) %
                  </span>
                </div>
              )}
            </FieldInput>
            {mode === "create" && (
              <DeleteButton
                type="button"
                onClick={() => {
                  setNumVariants(numVariants - 1);
                }}
              >
                <FontAwesomeIcon icon={faTimesCircle} />
              </DeleteButton>
            )}
          </FieldRow>
          {mode === "create" && (
            <div tw="mt-3">
              <Button
                type="button"
                buttonType="alternate-secondary"
                size="sm"
                onClick={() => {
                  setNumVariants(numVariants + 1);
                }}
                disabled={numVariants > 1}
              >
                <FontAwesomeIcon icon={faPlus} /> Add variant
              </Button>
            </div>
          )}
        </form>
      </PanelBody>
    </Panel>
  );
};

export default OfferTestPanel;
