import { useState } from "react";
import { Helmet } from "react-helmet-async";

import Spinner from "../../../common/Spinner";
import ChargebeePaymentForm from "./ChargebeePaymentForm";
import { paymentFormOnComplete } from "./onComplete";

interface ChargebeePaymentProps {
  site: string;
  publishableKey: string;
  onReady?: () => void;
  onComplete: paymentFormOnComplete;
  onCancel: () => void;
}

const ChargebeePayment: React.FunctionComponent<ChargebeePaymentProps> = ({
  site,
  publishableKey,
  onReady,
  onComplete,
  onCancel,
}) => {
  const [ready, setReady] = useState(false);

  return (
    <>
      <Helmet
        onChangeClientState={(newState, addedTags) => {
          if (!addedTags.scriptTags) {
            return;
          }

          for (const scriptTag of addedTags.scriptTags) {
            if (scriptTag.src === "https://js.chargebee.com/v2/chargebee.js") {
              scriptTag.addEventListener("load", () => {
                (window as any).Chargebee.init({
                  site,
                  publishableKey,
                });

                setReady(true);
              });
            }
          }
        }}
      >
        <script src="https://js.chargebee.com/v2/chargebee.js" />
      </Helmet>
      {ready ? (
        <ChargebeePaymentForm
          onReady={onReady}
          onComplete={onComplete}
          onCancel={onCancel}
        />
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default ChargebeePayment;
