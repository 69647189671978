import { groupBy } from "lodash";

interface AnswerCondition {
  questionId: number;
  questionOptionId: number | null;
}

interface RadioAnswer {
  questionId: number;
  questionOptionIds: number[];
  isOther: boolean;
}

const questionMatches = (
  options: AnswerCondition[],
  answers: RadioAnswer[]
): boolean => {
  for (const option of options) {
    const answer = answers.find(
      (answer) => answer.questionId === option.questionId
    );

    if (
      option.questionOptionId &&
      answer?.questionOptionIds.includes(option.questionOptionId)
    ) {
      return true;
    }

    if (answer?.isOther && option.questionOptionId === null) {
      return true;
    }
  }

  return false;
};

const answersMatch = (
  options: AnswerCondition[],
  answers: RadioAnswer[]
): boolean => {
  if (options.length) {
    // Do matching per question
    const groupedByQuestion = groupBy(options, "questionId");

    for (const questionId in groupedByQuestion) {
      const optionsForGroup = groupedByQuestion[questionId];
      if (
        optionsForGroup.length &&
        !questionMatches(optionsForGroup, answers)
      ) {
        return false;
      }
    }
  }

  return true;
};

export default answersMatch;
