import gql from "graphql-tag";

import FlowQuestionOptionFragment from "./FlowQuestionOptionFragment";
import FlowSegmentGroupFragment from "./FlowSegmentGroupFragment";
import TranslationFragment from "./TranslationFragment";

export default gql`
  fragment FlowQuestionVersionFragment on question_version {
    id
    question_id
    required
    title_translation_id
    hint_translation_id
    placeholder_translation_id
    condition_segment_ids
    condition_question_option_ids
    condition_include_other_in_question_ids
    title_translation {
      ...TranslationFragment
    }
    hint_translation {
      ...TranslationFragment
    }
    placeholder_translation {
      ...TranslationFragment
    }
    question_option_ids
    question_version_question_options(order_by: { position: asc }) {
      question_option_id
      question_version_id
      question_option {
        ...FlowQuestionOptionFragment
      }
    }

    question_version_condition_question_options {
      question_option_id
      question_option {
        id
        question_id
        published_version {
          id
          title_translation {
            ...TranslationFragment
          }
        }
        draft_version @include(if: $includeDrafts) {
          id
          title_translation {
            ...TranslationFragment
          }
        }
      }
    }

    question_version_condition_segments {
      segment_id
      segment {
        id
        name
      }
    }

    question_version_condition_segment_groups {
      segment_group_id
      segment_group {
        ...FlowSegmentGroupFragment
      }
    }
  }
  ${TranslationFragment}
  ${FlowQuestionOptionFragment}
  ${FlowSegmentGroupFragment}
`;
