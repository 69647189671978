import gql from "graphql-tag";

import OfferTimerFragment from "./OfferTimerFragment";
import TranslationFragment from "./TranslationFragment";

export default gql`
  fragment CampaignOfferFragment on offer {
    id
    token
    type
    name
    show_banner
    goal
    style
    confirmation_enabled
    offer_timer {
      ...OfferTimerFragment
    }
    image_url
    created_at
    updated_at
    headline_translation {
      ...TranslationFragment
    }
    content_translation {
      ...TranslationFragment
    }
    button_text_translation {
      ...TranslationFragment
    }
    offer_coupon {
      type
      amount_off
      duration
      months
    }
    offer_change_plan {
      platform_plan_id
      prorate
      change_at
    }
    offer_trial_extension {
      days
    }
    offer_custom {
      link_href
      link_text
    }
  }
  ${TranslationFragment}
  ${OfferTimerFragment}
`;
