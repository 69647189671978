import { Temporal } from "@js-temporal/polyfill";

import {
  FlowOfferFragment,
  FlowSubscriptionFragment,
  PauseReason,
} from "../../../../__generated__/graphql";
import { FlowError, FlowText } from "../lib/types";
import OfferContainer from "../OfferContainer";

interface OfferProps {
  offer: FlowOfferFragment;
  nextOrderDate?: Temporal.PlainDate;
  pauseReasons?: PauseReason[];
  flowText: FlowText;
  onAccept: (
    selectedOptionIndex: number,
    groupOffer: null,
    selectedVariantId: string | null | undefined,
    rescheduleTo: Temporal.PlainDate | null | undefined,
    pauseReasonCode: string | null | undefined,
    pauseAt: Temporal.PlainDate | null | undefined,
    resumeAt: Temporal.PlainDate | null | undefined
  ) => void;
  swappableProducts?: FlowSubscriptionFragment | null;
  disableVideoAutoPlay?: boolean;
  error?: FlowError;
  onImageLoad?: () => void;
}

const Offer: React.FunctionComponent<OfferProps> = ({
  offer,
  nextOrderDate,
  pauseReasons,
  flowText,
  onAccept,
  swappableProducts = null,
  disableVideoAutoPlay,
  error,
  onImageLoad = () => {},
}) => (
  <div className="flow-step__offer">
    <OfferContainer
      offer={offer}
      previewMode={false}
      baseClassName="flow-step__offer"
      disableVideoAutoPlay={disableVideoAutoPlay}
      pauseReasons={pauseReasons}
      flowText={flowText}
      onAccept={onAccept}
      onAcceptGroupOffer={() => {}}
      onDecline={() => {}}
      nextOrderDate={nextOrderDate}
      swappableProducts={swappableProducts}
      showDeclineButton={false}
      isFinalStep={false}
      error={error}
      onImageLoad={onImageLoad}
    />
  </div>
);
export default Offer;
