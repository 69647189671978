import { faArrowRightArrowLeft } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { FlowDeflectionFragment } from "../../../../__generated__/graphql";
import {
  DeflectionBox,
  NoDeflectionBox,
} from "../../../../common/rules/RuleBoxes";

interface RuleRule {
  offerIds: number[];
  offerTestIds: number[];
  offerGroupIds: number[];
  deflectionIds: number[];
  includePresentNoOffer: boolean;
}

interface OfferRuleDeflectionsProps {
  rule: RuleRule;
  deflections: FlowDeflectionFragment[];
}

const OfferRuleDeflections: React.FunctionComponent<
  OfferRuleDeflectionsProps
> = ({ rule, deflections }) => (
  <div tw="flex flex-wrap -m-1">
    {(rule.includePresentNoOffer || !rule.deflectionIds.length) && (
      <NoDeflectionBox
        key="any"
        isOn={rule.includePresentNoOffer}
        isReallyOn={!rule.deflectionIds.length}
      >
        Present no deflection
      </NoDeflectionBox>
    )}

    {deflections.map((deflection) => {
      const isOn = rule.deflectionIds.includes(deflection.id);

      if (!isOn) {
        return null;
      }

      return (
        <DeflectionBox key={deflection.id} isOn={true}>
          <FontAwesomeIcon
            icon={faArrowRightArrowLeft}
            transform="shrink-3"
            tw="mr-1"
          />{" "}
          {deflection.title}
        </DeflectionBox>
      );
    })}
  </div>
);

export default OfferRuleDeflections;
