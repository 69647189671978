import { ApolloClient, gql, useApolloClient } from "@apollo/client";
import { cloneDeep } from "@apollo/client/utilities";
import { isPresent } from "ts-is-present";

import {
  CreateFormDraftMutation,
  CreateFormDraftMutationVariables,
  CreateFormDraftQuery,
  CreateFormDraftQueryVariables,
  CreateFormMutation,
  CreateFormMutationVariables,
  CreateFormVersionMutation,
  CreateFormVersionMutationVariables,
  FlowFormFragment,
  FlowQuestionFragment,
  FormManagerAddExistingQuestionMutation,
  FormManagerAddExistingQuestionMutationVariables,
  FormManagerDeleteQuestionMutation,
  FormManagerDeleteQuestionMutationVariables,
  ReorderQuestionsMutation,
  ReorderQuestionsMutationVariables,
  SetFormVersionMutation,
  SetFormVersionMutationVariables,
} from "../../../__generated__/graphql";
import TheFlowFormFragment from "../../../common/fragments/FlowFormFragment";
import TheFlowFormVersionFragment from "../../../common/fragments/FlowFormVersionFragment";

const updateFormVersion = (
  apollo: ApolloClient<any>,
  formId: number,
  publishedVersionId: number,
  draftVersionId?: number | null
) =>
  apollo.mutate<SetFormVersionMutation, SetFormVersionMutationVariables>({
    mutation: gql`
      mutation SetFormVersionMutation(
        $formId: Int!
        $publishedVersionId: Int!
        $draftVersionId: Int
      ) {
        update_form_by_pk(
          pk_columns: { id: $formId }
          _set: {
            published_version_id: $publishedVersionId
            draft_version_id: $draftVersionId
          }
        ) {
          id
        }
      }
    `,
    variables: {
      formId,
      publishedVersionId,
      draftVersionId,
    },
    fetchPolicy: "no-cache",
  });

export const createFormDraft = async (
  apollo: ApolloClient<any>,
  formId: number
) => {
  const existingVersionResult = await apollo.query<
    CreateFormDraftQuery,
    CreateFormDraftQueryVariables
  >({
    query: gql`
      query CreateFormDraftQuery(
        $formId: Int!
        $includeDrafts: Boolean! = true
      ) {
        form_by_pk(id: $formId) {
          id
          published_version {
            ...FlowFormVersionFragment
          }
          draft_version {
            ...FlowFormVersionFragment
          }
        }
      }
      ${TheFlowFormVersionFragment}
    `,
    variables: { formId },
    fetchPolicy: "no-cache",
  });

  if (existingVersionResult.data?.form_by_pk?.draft_version) {
    return existingVersionResult.data.form_by_pk.draft_version;
  }

  if (!existingVersionResult.data?.form_by_pk?.published_version) {
    throw new Error("Form has no published version");
  }

  const existingVersion =
    existingVersionResult.data.form_by_pk.published_version;

  const newVersionResult = await apollo.mutate<
    CreateFormDraftMutation,
    CreateFormDraftMutationVariables
  >({
    mutation: gql`
      mutation CreateFormDraftMutation(
        $object: form_version_insert_input!
        $includeDrafts: Boolean! = true
      ) {
        insert_form_version_one(object: $object) {
          ...FlowFormVersionFragment
        }
      }
      ${TheFlowFormVersionFragment}
    `,
    variables: {
      object: {
        form_id: existingVersion.form_id,
        question_ids: existingVersion.question_ids,
      },
    },
    fetchPolicy: "no-cache",
  });

  if (!newVersionResult.data?.insert_form_version_one?.id) {
    throw new Error("Unable to create new form version");
  }

  await updateFormVersion(
    apollo,
    formId,
    existingVersion.id,
    newVersionResult.data.insert_form_version_one.id
  );

  return newVersionResult.data.insert_form_version_one;
};

interface AddFormOptions {
  title: string;
}

const addForm = async (
  apollo: ApolloClient<any>,
  { title }: AddFormOptions
): Promise<number> => {
  const formResult = await apollo.mutate<
    CreateFormMutation,
    CreateFormMutationVariables
  >({
    mutation: gql`
      mutation CreateFormMutation($object: form_insert_input!) {
        insert_form_one(object: $object) {
          id
        }
      }
    `,
    variables: { object: { title } },
  });

  const id = formResult.data?.insert_form_one?.id;

  if (!id) {
    throw new Error("Failed to create form");
  }

  const versionResult = await apollo.mutate<
    CreateFormVersionMutation,
    CreateFormVersionMutationVariables
  >({
    mutation: gql`
      mutation CreateFormVersionMutation($object: form_version_insert_input!) {
        insert_form_version_one(object: $object) {
          id
        }
      }
    `,
    variables: {
      object: {
        form_id: id,
      },
    },
  });

  if (!versionResult.data?.insert_form_version_one?.id) {
    throw new Error("Failed to create form version");
  }

  await updateFormVersion(
    apollo,
    id,
    versionResult.data.insert_form_version_one.id
  );

  return id;
};

export const reorderQuestions = (
  list: FlowQuestionFragment[],
  startIndex: number,
  endIndex: number
): FlowQuestionFragment[] => {
  const result = Array.from(cloneDeep(list));
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const moveQuestion = async (
  apollo: ApolloClient<any>,
  form: FlowFormFragment,
  sourceIndex: number,
  destinationIndex: number
) => {
  const version = form.draft_version || form.published_version;

  if (!version?.form_version_questions) {
    throw new Error("Form does not have questions");
  }

  const draftVersion = await createFormDraft(apollo, form.id);

  const questions = version.form_version_questions
    .map((q) => q.question)
    .filter(isPresent);

  const reordered = reorderQuestions(questions, sourceIndex, destinationIndex);

  return apollo.mutate<
    ReorderQuestionsMutation,
    ReorderQuestionsMutationVariables
  >({
    mutation: gql`
      mutation ReorderQuestionsMutation(
        $draftVersionId: Int!
        $questionIds: jsonb!
        $includeDrafts: Boolean! = true
      ) {
        update_form_version_by_pk(
          pk_columns: { id: $draftVersionId }
          _set: { question_ids: $questionIds }
        ) {
          id
          form {
            ...FlowFormFragment
          }
        }
      }
      ${TheFlowFormFragment}
    `,
    variables: {
      draftVersionId: draftVersion.id,
      questionIds: reordered.map((q) => q.id),
    },
  });
};

const addExistingQuestion = async (
  apollo: ApolloClient<any>,
  form: FlowFormFragment,
  questionId: number
) => {
  const draftFormVersion = await createFormDraft(apollo, form.id);

  return apollo.mutate<
    FormManagerAddExistingQuestionMutation,
    FormManagerAddExistingQuestionMutationVariables
  >({
    mutation: gql`
      mutation FormManagerAddExistingQuestionMutation(
        $formVersionId: Int!
        $questionIds: jsonb!
        $includeDrafts: Boolean! = true
      ) {
        update_form_version_by_pk(
          pk_columns: { id: $formVersionId }
          _set: { question_ids: $questionIds }
        ) {
          id
          form {
            ...FlowFormFragment
          }
        }
      }
      ${TheFlowFormFragment}
    `,
    variables: {
      formVersionId: draftFormVersion.id,
      questionIds: [...draftFormVersion.question_ids, questionId],
    },
  });
};

export const deleteQuestion = async (
  apollo: ApolloClient<any>,
  form: FlowFormFragment,
  questionId: number
) => {
  const version = form.draft_version || form.published_version;
  if (!version) {
    throw new Error("Form has no published version");
  }

  const draftVersion = await createFormDraft(apollo, form.id);

  return apollo.mutate<
    FormManagerDeleteQuestionMutation,
    FormManagerDeleteQuestionMutationVariables
  >({
    mutation: gql`
      mutation FormManagerDeleteQuestionMutation(
        $draftVersionId: Int!
        $remainingQuestionIds: jsonb!
        $includeDrafts: Boolean! = true
      ) {
        update_form_version_by_pk(
          pk_columns: { id: $draftVersionId }
          _set: { question_ids: $remainingQuestionIds }
        ) {
          id
          form {
            ...FlowFormFragment
          }
        }
      }
      ${TheFlowFormFragment}
    `,
    variables: {
      draftVersionId: draftVersion.id,
      remainingQuestionIds: draftVersion.question_ids.filter(
        (id: number) => id !== questionId
      ),
    },
  });
};

interface GetFormManagerOptions {
  apollo: ApolloClient<object>;
  trackUpdate: <T>(promise: Promise<T>) => Promise<T>;
  flowToken: string;
}

export const getFormManager = ({
  apollo,
  trackUpdate,
}: GetFormManagerOptions) => ({
  addForm: (options: AddFormOptions) => trackUpdate(addForm(apollo, options)),

  addExistingQuestion: (form: FlowFormFragment, questionId: number) =>
    trackUpdate(addExistingQuestion(apollo, form, questionId)),

  deleteQuestion: (form: FlowFormFragment, questionId: number) =>
    trackUpdate(deleteQuestion(apollo, form, questionId)),

  moveQuestion: (
    form: FlowFormFragment,
    sourceIndex: number,
    destinationIndex: number
  ) => trackUpdate(moveQuestion(apollo, form, sourceIndex, destinationIndex)),
});

export const useFormManager = (
  options: Omit<GetFormManagerOptions, "apollo">
) => {
  const apollo = useApolloClient();

  return getFormManager({
    ...options,
    apollo,
  });
};

export type FormManager = ReturnType<typeof getFormManager>;
