import { ApolloClient, gql, useApolloClient } from "@apollo/client";
import { cloneDeep } from "@apollo/client/utilities";
import { isPresent } from "ts-is-present";

import {
  AddQuestionOptionSetOptionIdsMutation,
  AddQuestionOptionSetOptionIdsMutationVariables,
  AddQuestionOptionVersionMutation,
  AddQuestionOptionVersionMutationVariables,
  CreateQuestionDraftMutation,
  CreateQuestionDraftMutationVariables,
  CreateQuestionDraftQuery,
  CreateQuestionDraftQueryVariables,
  CreateQuestionMutation,
  CreateQuestionMutationVariables,
  CreateQuestionOptionDraftMutation,
  CreateQuestionOptionDraftMutationVariables,
  CreateQuestionOptionDraftQuery,
  CreateQuestionOptionDraftQueryVariables,
  CreateQuestionVersionMutation,
  CreateQuestionVersionMutationVariables,
  CreateSettingDraftExistingVersionQuery,
  CreateSettingDraftExistingVersionQueryVariables,
  CreateSettingDraftNewVersionMutation,
  CreateSettingDraftNewVersionMutationVariables,
  DeleteQuestionOptionMutation,
  DeleteQuestionOptionMutationVariables,
  flow_language_insert_input,
  FlowQuery,
  FlowQueryVariables,
  FlowQuestionFragment,
  FlowQuestionOptionFragment,
  language_enum,
  question_setting_key_enum,
  question_type_enum,
  ReorderQuestionOptionsMutation,
  ReorderQuestionOptionsMutationVariables,
  SetQuestionOptionVersionMutation,
  SetQuestionOptionVersionMutationVariables,
  SetQuestionRequiredMutation,
  SetQuestionRequiredMutationVariables,
  SetQuestionSettingCreateSettingMutation,
  SetQuestionSettingCreateSettingMutationVariables,
  SetQuestionSettingExistingSettingQuery,
  SetQuestionSettingExistingSettingQueryVariables,
  SetQuestionSettingMutation,
  SetQuestionSettingMutationVariables,
  SetQuestionVersionMutation,
  SetQuestionVersionMutationVariables,
  translation_value_format_enum,
  translation_value_insert_input,
  UpdateFlowLanguageSettingsMutation,
  UpdateFlowLanguageSettingsMutationVariables,
  UpdateQuestionConditionMutation,
  UpdateQuestionConditionMutationVariables,
  UpdateQuestionOptionDraftMutation,
  UpdateQuestionOptionDraftMutationVariables,
  UpdateQuestionOptionReasonCodeMutation,
  UpdateQuestionOptionReasonCodeMutationVariables,
  UpdateQuestionOptionTranslationsMutation,
  UpdateQuestionOptionTranslationsMutationVariables,
  UpdateQuestionTranslationsMutation,
  UpdateQuestionTranslationsMutationVariables,
  UpdateQuestionUpdateDraftMutation,
  UpdateQuestionUpdateDraftMutationVariables,
  UpdateSettingVersionMutation,
  UpdateSettingVersionMutationVariables,
} from "../../../__generated__/graphql";
import { FormattedQuestionCondition } from "../../../common/flow/formatQuestionConditions";
import { TheFlowQuery } from "../../../common/flow/useFlowByToken";
import { TranslatedForms } from "../../../common/form/useTranslatableForm";
import TheFlowQuestionFragment from "../../../common/fragments/FlowQuestionFragment";
import TheFlowQuestionOptionFragment from "../../../common/fragments/FlowQuestionOptionFragment";
import TheFlowQuestionOptionVersionFragment from "../../../common/fragments/FlowQuestionOptionVersionFragment";
import FlowQuestionSettingFragment from "../../../common/fragments/FlowQuestionSettingFragment";
import FlowQuestionSettingVersionFragment from "../../../common/fragments/FlowQuestionSettingVersionFragment";
import FlowQuestionVersionFragment from "../../../common/fragments/FlowQuestionVersionFragment";

export type QuestionTranslations = TranslatedForms<{
  title: string;
  hint: string;
  placeholder?: string;
}>;

export type OptionTranslations = TranslatedForms<{
  title: string;
}>;

const mapQuestionTranslations = (translations: QuestionTranslations) => {
  const titleTranslationValues: translation_value_insert_input[] = [];
  const hintTranslationValues: translation_value_insert_input[] = [];
  const placeholderTranslationValues: translation_value_insert_input[] = [];
  for (const [language, values] of Object.entries(translations)) {
    if (!values) {
      continue;
    }

    titleTranslationValues.push({
      language: language as language_enum,
      value: values.title,
      format: translation_value_format_enum.text,
    });
    hintTranslationValues.push({
      language: language as language_enum,
      value: values.hint,
      format: translation_value_format_enum.text,
    });
    if (values.placeholder) {
      placeholderTranslationValues.push({
        language: language as language_enum,
        value: values.placeholder,
        format: translation_value_format_enum.text,
      });
    }
  }

  return {
    titleTranslationValues,
    hintTranslationValues,
    placeholderTranslationValues,
  };
};

const mapOptionTranslations = (translations: OptionTranslations) => {
  const titleTranslationValues: translation_value_insert_input[] = [];

  for (const [language, values] of Object.entries(translations)) {
    if (!values) {
      continue;
    }

    titleTranslationValues.push({
      language: language as language_enum,
      value: values.title,
      format: translation_value_format_enum.text,
    });
  }

  return {
    titleTranslationValues,
  };
};

const updateQuestionVersion = (
  apollo: ApolloClient<any>,
  questionId: number,
  publishedVersionId: number,
  draftVersionId?: number | null
) =>
  apollo.mutate<
    SetQuestionVersionMutation,
    SetQuestionVersionMutationVariables
  >({
    mutation: gql`
      mutation SetQuestionVersionMutation(
        $questionId: Int!
        $publishedVersionId: Int!
        $draftVersionId: Int
      ) {
        update_question_by_pk(
          pk_columns: { id: $questionId }
          _set: {
            published_version_id: $publishedVersionId
            draft_version_id: $draftVersionId
          }
        ) {
          id
        }
      }
    `,
    variables: {
      questionId,
      publishedVersionId,
      draftVersionId,
    },
    fetchPolicy: "no-cache",
  });

const updateQuestionOptionVersion = (
  apollo: ApolloClient<any>,
  optionId: number,
  publishedVersionId: number,
  draftVersionId?: number | null
) =>
  apollo.mutate<
    SetQuestionOptionVersionMutation,
    SetQuestionOptionVersionMutationVariables
  >({
    mutation: gql`
      mutation SetQuestionOptionVersionMutation(
        $optionId: Int!
        $publishedVersionId: Int!
        $draftVersionId: Int
        $includeDrafts: Boolean! = true
      ) {
        update_question_option_by_pk(
          pk_columns: { id: $optionId }
          _set: {
            published_version_id: $publishedVersionId
            draft_version_id: $draftVersionId
          }
        ) {
          ...FlowQuestionOptionFragment
        }
      }
      ${TheFlowQuestionOptionFragment}
    `,
    variables: {
      optionId,
      publishedVersionId,
      draftVersionId,
    },
  });

const createQuestionDraft = async (
  apollo: ApolloClient<any>,
  questionId: number
) => {
  const existingVersionResult = await apollo.query<
    CreateQuestionDraftQuery,
    CreateQuestionDraftQueryVariables
  >({
    query: gql`
      query CreateQuestionDraftQuery(
        $questionId: Int!
        $includeDrafts: Boolean! = true
      ) {
        question_by_pk(id: $questionId) {
          id
          published_version {
            ...FlowQuestionVersionFragment
          }
          draft_version {
            ...FlowQuestionVersionFragment
          }
        }
      }
      ${FlowQuestionVersionFragment}
    `,
    variables: { questionId },
    fetchPolicy: "no-cache",
  });

  if (existingVersionResult.data?.question_by_pk?.draft_version) {
    return existingVersionResult.data.question_by_pk.draft_version;
  }

  if (!existingVersionResult.data?.question_by_pk?.published_version) {
    throw new Error("Question has no published version");
  }

  const existingVersion =
    existingVersionResult.data.question_by_pk.published_version;

  const newVersionResult = await apollo.mutate<
    CreateQuestionDraftMutation,
    CreateQuestionDraftMutationVariables
  >({
    mutation: gql`
      mutation CreateQuestionDraftMutation(
        $object: question_version_insert_input!
        $includeDrafts: Boolean! = true
      ) {
        insert_question_version_one(object: $object) {
          ...FlowQuestionVersionFragment
        }
      }
      ${FlowQuestionVersionFragment}
    `,
    variables: {
      object: {
        question_id: existingVersion.question_id,
        title_translation_id: existingVersion.title_translation_id,
        hint_translation_id: existingVersion.hint_translation_id,
        placeholder_translation_id: existingVersion.placeholder_translation_id,
        required: existingVersion.required,
        question_option_ids: existingVersion.question_option_ids,
        condition_segment_ids: existingVersion.condition_segment_ids,
        condition_question_option_ids:
          existingVersion.condition_question_option_ids,
        condition_include_other_in_question_ids:
          existingVersion.condition_include_other_in_question_ids,
      },
    },
    fetchPolicy: "no-cache",
  });

  if (!newVersionResult.data?.insert_question_version_one?.id) {
    throw new Error("Unable to create new question version");
  }

  await updateQuestionVersion(
    apollo,
    questionId,
    existingVersion.id,
    newVersionResult.data.insert_question_version_one.id
  );

  return newVersionResult.data.insert_question_version_one;
};

const createQuestionOptionDraft = async (
  apollo: ApolloClient<any>,
  optionId: number
) => {
  const existingVersionResult = await apollo.query<
    CreateQuestionOptionDraftQuery,
    CreateQuestionOptionDraftQueryVariables
  >({
    query: gql`
      query CreateQuestionOptionDraftQuery(
        $optionId: Int!
        $includeDrafts: Boolean! = true
      ) {
        question_option_by_pk(id: $optionId) {
          ...FlowQuestionOptionFragment
        }
      }
      ${TheFlowQuestionOptionFragment}
    `,
    variables: { optionId },
  });

  if (existingVersionResult.data?.question_option_by_pk?.draft_version) {
    return existingVersionResult.data.question_option_by_pk.draft_version;
  }

  const existingVersion =
    existingVersionResult.data?.question_option_by_pk?.published_version;

  if (!existingVersion) {
    throw new Error("Question option has no published version");
  }

  const newVersionResult = await apollo.mutate<
    CreateQuestionOptionDraftMutation,
    CreateQuestionOptionDraftMutationVariables
  >({
    mutation: gql`
      mutation CreateQuestionOptionDraftMutation(
        $object: question_option_version_insert_input!
      ) {
        insert_question_option_version_one(object: $object) {
          ...FlowQuestionOptionVersionFragment
        }
      }
      ${TheFlowQuestionOptionVersionFragment}
    `,
    variables: {
      object: {
        question_option_id: existingVersion.question_option_id,
        title_translation_id: existingVersion.title_translation_id,
      },
    },
  });

  if (!newVersionResult.data?.insert_question_option_version_one) {
    throw new Error("Unable to create new question option version");
  }

  await updateQuestionOptionVersion(
    apollo,
    optionId,
    existingVersion.id,
    newVersionResult.data.insert_question_option_version_one.id
  );

  return newVersionResult.data.insert_question_option_version_one;
};

const updateSettingVersion = (
  apollo: ApolloClient<any>,
  questionId: number,
  key: question_setting_key_enum,
  publishedVersionId: number,
  draftVersionId?: number | null
) =>
  apollo.mutate<
    UpdateSettingVersionMutation,
    UpdateSettingVersionMutationVariables
  >({
    mutation: gql`
      mutation UpdateSettingVersionMutation(
        $questionId: Int!
        $key: question_setting_key_enum!
        $publishedVersionId: Int!
        $draftVersionId: Int
        $includeDrafts: Boolean! = true
      ) {
        update_question_setting_by_pk(
          pk_columns: { question_id: $questionId, key: $key }
          _set: {
            published_version_id: $publishedVersionId
            draft_version_id: $draftVersionId
          }
        ) {
          ...FlowQuestionSettingFragment
        }
      }
      ${FlowQuestionSettingFragment}
    `,
    variables: {
      questionId,
      key,
      publishedVersionId,
      draftVersionId,
    },
  });

const createSettingDraft = async (
  apollo: ApolloClient<any>,
  questionId: number,
  key: question_setting_key_enum
) => {
  const existingVersionResult = await apollo.query<
    CreateSettingDraftExistingVersionQuery,
    CreateSettingDraftExistingVersionQueryVariables
  >({
    query: gql`
      query CreateSettingDraftExistingVersionQuery(
        $questionId: Int!
        $key: question_setting_key_enum!
        $includeDrafts: Boolean! = true
      ) {
        question_setting_by_pk(key: $key, question_id: $questionId) {
          ...FlowQuestionSettingFragment
        }
      }
      ${FlowQuestionSettingFragment}
    `,
    variables: {
      questionId,
      key,
    },
    fetchPolicy: "network-only",
  });

  if (existingVersionResult.data?.question_setting_by_pk?.draft_version) {
    return existingVersionResult.data.question_setting_by_pk.draft_version;
  }

  const existingVersion =
    existingVersionResult.data?.question_setting_by_pk?.published_version;
  if (!existingVersion) {
    throw new Error("Question setting has no published version");
  }

  const newVersionResult = await apollo.mutate<
    CreateSettingDraftNewVersionMutation,
    CreateSettingDraftNewVersionMutationVariables
  >({
    mutation: gql`
      mutation CreateSettingDraftNewVersionMutation(
        $object: question_setting_version_insert_input!
      ) {
        insert_question_setting_version_one(object: $object) {
          ...FlowQuestionSettingVersionFragment
        }
      }
      ${FlowQuestionSettingVersionFragment}
    `,
    variables: {
      object: {
        question_id: existingVersion.question_id,
        key: existingVersion.key,
        value: existingVersion.value,
      },
    },
  });

  if (!newVersionResult.data?.insert_question_setting_version_one) {
    throw new Error("Unable to insert new question setting version");
  }

  await updateSettingVersion(
    apollo,
    existingVersion.question_id,
    existingVersion.key,
    existingVersion.id,
    newVersionResult.data.insert_question_setting_version_one.id
  );

  return newVersionResult.data.insert_question_setting_version_one;
};

const addQuestion = async (
  apollo: ApolloClient<any>,
  type: question_type_enum,
  translations: QuestionTranslations,
  required: boolean
): Promise<number> => {
  const {
    titleTranslationValues,
    hintTranslationValues,
    placeholderTranslationValues,
  } = mapQuestionTranslations(translations);

  const questionResult = await apollo.mutate<
    CreateQuestionMutation,
    CreateQuestionMutationVariables
  >({
    mutation: gql`
      mutation CreateQuestionMutation($object: question_insert_input!) {
        insert_question_one(object: $object) {
          id
          token
          type
        }
      }
    `,
    variables: {
      object: {
        type,
      },
    },
  });

  const id = questionResult.data?.insert_question_one?.id;

  if (!id) {
    throw new Error("Failed to create question");
  }

  const versionResult = await apollo.mutate<
    CreateQuestionVersionMutation,
    CreateQuestionVersionMutationVariables
  >({
    mutation: gql`
      mutation CreateQuestionVersionMutation(
        $object: question_version_insert_input!
        $includeDrafts: Boolean! = true
      ) {
        insert_question_version_one(object: $object) {
          id
          question {
            ...FlowQuestionFragment
          }
        }
      }
      ${TheFlowQuestionFragment}
    `,
    variables: {
      object: {
        question_id: id,
        required,
        question_option_ids: type === question_type_enum.radio ? [] : null,
        title_translation: {
          data: {
            translation_values: {
              data: titleTranslationValues,
            },
          },
        },
        hint_translation: {
          data: {
            translation_values: {
              data: hintTranslationValues,
            },
          },
        },
        placeholder_translation: placeholderTranslationValues.length
          ? {
              data: {
                translation_values: {
                  data: placeholderTranslationValues,
                },
              },
            }
          : null,
      },
    },
  });

  if (!versionResult.data?.insert_question_version_one?.id) {
    throw new Error("Failed to create question version");
  }

  await updateQuestionVersion(
    apollo,
    id,
    versionResult.data.insert_question_version_one.id
  );

  return id;
};

const updateQuestion = async (
  apollo: ApolloClient<any>,
  questionId: number,
  required: boolean,
  translations: QuestionTranslations
) => {
  const {
    titleTranslationValues,
    hintTranslationValues,
    placeholderTranslationValues,
  } = mapQuestionTranslations(translations);

  const draftVersion = await createQuestionDraft(apollo, questionId);

  const translationsResult = await apollo.mutate<
    UpdateQuestionTranslationsMutation,
    UpdateQuestionTranslationsMutationVariables
  >({
    mutation: gql`
      mutation UpdateQuestionTranslationsMutation(
        $titleTranslation: translation_insert_input!
        $hintTranslation: translation_insert_input!
        $placeholderTranslation: translation_insert_input!
      ) {
        title: insert_translation_one(object: $titleTranslation) {
          id
        }
        hint: insert_translation_one(object: $hintTranslation) {
          id
        }
        placeholder: insert_translation_one(object: $placeholderTranslation) {
          id
        }
      }
    `,
    variables: {
      titleTranslation: {
        translation_values: {
          data: titleTranslationValues,
        },
      },
      hintTranslation: {
        translation_values: {
          data: hintTranslationValues,
        },
      },
      placeholderTranslation: {
        translation_values: {
          data: placeholderTranslationValues,
        },
      },
    },
  });

  if (
    !translationsResult.data?.title?.id ||
    !translationsResult.data.hint?.id
  ) {
    throw new Error("Unable to create new question translations");
  }

  const result = await apollo.mutate<
    UpdateQuestionUpdateDraftMutation,
    UpdateQuestionUpdateDraftMutationVariables
  >({
    mutation: gql`
      mutation UpdateQuestionUpdateDraftMutation(
        $draftVersionId: Int!
        $update: question_version_set_input!
        $includeDrafts: Boolean! = true
      ) {
        update_question_version_by_pk(
          pk_columns: { id: $draftVersionId }
          _set: $update
        ) {
          id
          question {
            ...FlowQuestionFragment
          }
        }
      }
      ${TheFlowQuestionFragment}
    `,
    variables: {
      draftVersionId: draftVersion.id,
      update: {
        title_translation_id: translationsResult.data.title.id,
        hint_translation_id: translationsResult.data.hint.id,
        placeholder_translation_id: translationsResult.data.placeholder?.id,
        required,
      },
    },
  });

  if (!result.data?.update_question_version_by_pk) {
    throw new Error("Unable to update question");
  }

  return result.data?.update_question_version_by_pk;
};

export const updateQuestionOption = async (
  apollo: ApolloClient<any>,
  optionId: number,
  translations: OptionTranslations,
  reasonCode: string | null | undefined
) => {
  const { titleTranslationValues } = mapOptionTranslations(translations);

  const draftVersion = await createQuestionOptionDraft(apollo, optionId);

  const translationsResult = await apollo.mutate<
    UpdateQuestionOptionTranslationsMutation,
    UpdateQuestionOptionTranslationsMutationVariables
  >({
    mutation: gql`
      mutation UpdateQuestionOptionTranslationsMutation(
        $titleTranslation: translation_insert_input!
      ) {
        insert_translation_one(object: $titleTranslation) {
          id
        }
      }
    `,
    variables: {
      titleTranslation: {
        translation_values: {
          data: titleTranslationValues,
        },
      },
    },
  });

  if (!translationsResult.data?.insert_translation_one?.id) {
    throw new Error("Unable to create new question option translations");
  }

  await apollo.mutate<
    UpdateQuestionOptionDraftMutation,
    UpdateQuestionOptionDraftMutationVariables
  >({
    mutation: gql`
      mutation UpdateQuestionOptionDraftMutation(
        $draftVersionId: Int!
        $update: question_option_version_set_input!
        $includeDrafts: Boolean! = true
      ) {
        update_question_option_version_by_pk(
          pk_columns: { id: $draftVersionId }
          _set: $update
        ) {
          id
          question_option {
            id
            question {
              ...FlowQuestionFragment
            }
          }
        }
      }
      ${TheFlowQuestionFragment}
    `,
    variables: {
      draftVersionId: draftVersion.id,
      update: {
        title_translation_id: translationsResult.data.insert_translation_one.id,
      },
    },
  });

  await apollo.mutate<
    UpdateQuestionOptionReasonCodeMutation,
    UpdateQuestionOptionReasonCodeMutationVariables
  >({
    mutation: gql`
      mutation UpdateQuestionOptionReasonCodeMutation(
        $id: Int!
        $reasonCode: String
      ) {
        update_question_option_by_pk(
          pk_columns: { id: $id }
          _set: { reason_code: $reasonCode }
        ) {
          id
          reason_code
        }
      }
    `,
    variables: {
      id: optionId,
      reasonCode,
    },
  });
};

export const setQuestionRequired = async (
  apollo: ApolloClient<any>,
  questionId: number,
  required: boolean
) => {
  const draftVersion = await createQuestionDraft(apollo, questionId);

  await apollo.mutate<
    SetQuestionRequiredMutation,
    SetQuestionRequiredMutationVariables
  >({
    mutation: gql`
      mutation SetQuestionRequiredMutation(
        $versionId: Int!
        $required: Boolean!
        $includeDrafts: Boolean! = true
      ) {
        update_question_version_by_pk(
          pk_columns: { id: $versionId }
          _set: { required: $required }
        ) {
          id
          question {
            ...FlowQuestionFragment
          }
        }
      }
      ${TheFlowQuestionFragment}
    `,
    variables: {
      versionId: draftVersion.id,
      required,
    },
  });
};

export const setQuestionSetting = async (
  apollo: ApolloClient<any>,
  questionId: number,
  key: question_setting_key_enum,
  value: any
) => {
  const existingSettingResult = await apollo.query<
    SetQuestionSettingExistingSettingQuery,
    SetQuestionSettingExistingSettingQueryVariables
  >({
    query: gql`
      query SetQuestionSettingExistingSettingQuery(
        $questionId: Int!
        $key: question_setting_key_enum!
        $includeDrafts: Boolean! = true
      ) {
        question_setting_by_pk(key: $key, question_id: $questionId) {
          ...FlowQuestionSettingFragment
        }
      }
      ${FlowQuestionSettingFragment}
    `,
    variables: {
      questionId,
      key,
    },
  });

  if (!existingSettingResult.data?.question_setting_by_pk) {
    const createSettingResult = await apollo.mutate<
      SetQuestionSettingCreateSettingMutation,
      SetQuestionSettingCreateSettingMutationVariables
    >({
      mutation: gql`
        mutation SetQuestionSettingCreateSettingMutation(
          $object: question_setting_version_insert_input!
        ) {
          insert_question_setting_version_one(object: $object) {
            id
          }
        }
      `,
      variables: {
        object: {
          value,
          question_setting: {
            data: {
              question_id: questionId,
              key,
            },
          },
        },
      },
    });

    if (!createSettingResult.data?.insert_question_setting_version_one?.id) {
      throw new Error("Unable to create new question setting");
    }

    await updateSettingVersion(
      apollo,
      questionId,
      key,
      createSettingResult.data.insert_question_setting_version_one.id
    );
  }

  const draftVersion = await createSettingDraft(apollo, questionId, key);

  await apollo.mutate<
    SetQuestionSettingMutation,
    SetQuestionSettingMutationVariables
  >({
    mutation: gql`
      mutation SetQuestionSettingMutation(
        $versionId: Int!
        $value: jsonb!
        $includeDrafts: Boolean! = true
      ) {
        update_question_setting_version_by_pk(
          pk_columns: { id: $versionId }
          _set: { value: $value }
        ) {
          question {
            ...FlowQuestionFragment
          }
        }
      }
      ${TheFlowQuestionFragment}
    `,
    variables: {
      versionId: draftVersion.id,
      value,
    },
  });
};

export const reorderQuestionOptions = (
  list: FlowQuestionOptionFragment[],
  startIndex: number,
  endIndex: number
): FlowQuestionOptionFragment[] => {
  const result = Array.from(cloneDeep(list));
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const moveQuestionOption = async (
  apollo: ApolloClient<any>,
  question: FlowQuestionFragment,
  sourceIndex: number,
  destinationIndex: number
) => {
  const version = question.draft_version || question.published_version;

  if (!version?.question_version_question_options) {
    throw new Error("Question does not have options");
  }

  const draftVersion = await createQuestionDraft(apollo, question.id);

  const options = version.question_version_question_options
    .map((o) => o.question_option)
    .filter(isPresent);

  const reordered = reorderQuestionOptions(
    options,
    sourceIndex,
    destinationIndex
  );

  return apollo.mutate<
    ReorderQuestionOptionsMutation,
    ReorderQuestionOptionsMutationVariables
  >({
    mutation: gql`
      mutation ReorderQuestionOptionsMutation(
        $draftVersionId: Int!
        $optionIds: jsonb!
        $includeDrafts: Boolean! = true
      ) {
        update_question_version_by_pk(
          pk_columns: { id: $draftVersionId }
          _set: { question_option_ids: $optionIds }
        ) {
          id
          question {
            ...FlowQuestionFragment
          }
        }
      }
      ${TheFlowQuestionFragment}
    `,
    variables: {
      draftVersionId: draftVersion.id,
      optionIds: reordered.map((o) => o.id),
    },
  });
};

export const addQuestionOption = async (
  apollo: ApolloClient<any>,
  questionId: number,
  translations: OptionTranslations,
  reasonCode: string | null | undefined
) => {
  const draftQuestionVersion = await createQuestionDraft(apollo, questionId);

  const { titleTranslationValues } = mapOptionTranslations(translations);

  const optionResult = await apollo.mutate<
    AddQuestionOptionVersionMutation,
    AddQuestionOptionVersionMutationVariables
  >({
    mutation: gql`
      mutation AddQuestionOptionVersionMutation(
        $object: question_option_version_insert_input!
      ) {
        insert_question_option_version_one(object: $object) {
          id
          question_option_id
        }
      }
    `,
    variables: {
      object: {
        title_translation: {
          data: {
            translation_values: {
              data: titleTranslationValues,
            },
          },
        },
        question_option: {
          data: {
            question_id: questionId,
            reason_code: reasonCode,
          },
        },
      },
    },
  });

  if (!optionResult.data?.insert_question_option_version_one?.id) {
    throw new Error("Unable to create option");
  }

  await apollo.mutate<
    AddQuestionOptionSetOptionIdsMutation,
    AddQuestionOptionSetOptionIdsMutationVariables
  >({
    mutation: gql`
      mutation AddQuestionOptionSetOptionIdsMutation(
        $questionVersionId: Int!
        $optionIds: jsonb!
        $optionId: Int!
        $optionDraftVersionId: Int!
        $includeDrafts: Boolean! = true
      ) {
        update_question_version_by_pk(
          pk_columns: { id: $questionVersionId }
          _set: { question_option_ids: $optionIds }
        ) {
          id
        }

        update_question_option_by_pk(
          pk_columns: { id: $optionId }
          _set: { published_version_id: $optionDraftVersionId }
        ) {
          id
          question {
            ...FlowQuestionFragment
          }
        }
      }
      ${TheFlowQuestionFragment}
    `,
    variables: {
      questionVersionId: draftQuestionVersion.id,
      optionIds: [
        ...draftQuestionVersion.question_option_ids,
        optionResult.data.insert_question_option_version_one.question_option_id,
      ],
      optionId:
        optionResult.data.insert_question_option_version_one.question_option_id,
      optionDraftVersionId:
        optionResult.data.insert_question_option_version_one.id,
    },
  });
};

export const deleteQuestionOption = async (
  apollo: ApolloClient<any>,
  question: FlowQuestionFragment,
  optionId: number
) => {
  const version = question.draft_version || question.published_version;
  if (!version) {
    throw new Error("Question has no version");
  }

  if (version.question_version_question_options.length === 1) {
    throw new Error("At least one option must be visible");
  }

  const draftVersion = await createQuestionDraft(apollo, question.id);

  return apollo.mutate<
    DeleteQuestionOptionMutation,
    DeleteQuestionOptionMutationVariables
  >({
    mutation: gql`
      mutation DeleteQuestionOptionMutation(
        $draftVersionId: Int!
        $optionIds: jsonb!
        $includeDrafts: Boolean! = true
      ) {
        update_question_version_by_pk(
          pk_columns: { id: $draftVersionId }
          _set: { question_option_ids: $optionIds }
        ) {
          id
          question {
            ...FlowQuestionFragment
          }
        }
      }
      ${TheFlowQuestionFragment}
    `,
    variables: {
      draftVersionId: draftVersion.id,
      optionIds: draftVersion.question_option_ids.filter(
        (id: number) => id !== optionId
      ),
    },
  });
};

export const updateQuestionCondition = async (
  apollo: ApolloClient<any>,
  condition: FormattedQuestionCondition
) => {
  const questionDraftVersion = await createQuestionDraft(
    apollo,
    condition.questionId
  );

  await apollo.mutate<
    UpdateQuestionConditionMutation,
    UpdateQuestionConditionMutationVariables
  >({
    mutation: gql`
      mutation UpdateQuestionConditionMutation(
        $versionId: Int!
        $segmentIds: jsonb!
        $questionOptionIds: jsonb!
        $includeOtherInQuestionIds: jsonb!
        $includeDrafts: Boolean! = true
      ) {
        update_question_version_by_pk(
          pk_columns: { id: $versionId }
          _set: {
            condition_segment_ids: $segmentIds
            condition_question_option_ids: $questionOptionIds
            condition_include_other_in_question_ids: $includeOtherInQuestionIds
          }
        ) {
          id
          question {
            ...FlowQuestionFragment
          }
        }
      }
      ${TheFlowQuestionFragment}
    `,
    variables: {
      versionId: questionDraftVersion.id,
      segmentIds: condition.segments.map((s) => s.id),
      includeOtherInQuestionIds: condition.includeOtherInQuestionIds,
      questionOptionIds: condition.questionOptions.map((q) => q.id),
    },
  });
};

export const updateLanguageSettings = async (
  apollo: ApolloClient<any>,
  flowToken: string,
  defaultLanguage: language_enum,
  enabledLanguages: language_enum[]
) => {
  const flowData = apollo.cache.readQuery<FlowQuery, FlowQueryVariables>({
    query: TheFlowQuery,
    variables: { token: flowToken },
  });

  const flow = (flowData?.flow.length && flowData.flow[0]) || undefined;

  if (!flow) {
    return;
  }

  const flowLanguages: flow_language_insert_input[] = enabledLanguages.map(
    (language) => ({
      flow_id: flow.id,
      language,
    })
  );

  await apollo.mutate<
    UpdateFlowLanguageSettingsMutation,
    UpdateFlowLanguageSettingsMutationVariables
  >({
    mutation: gql`
      mutation UpdateFlowLanguageSettingsMutation(
        $flowId: Int!
        $defaultLanguage: language_enum!
        $flowLanguages: [flow_language_insert_input!]!
      ) {
        update_flow_by_pk(
          pk_columns: { id: $flowId }
          _set: { default_language: $defaultLanguage }
        ) {
          id
          flow_languages {
            flow_id
            language
          }
        }

        delete_flow_language(where: { flow_id: { _eq: $flowId } }) {
          affected_rows
        }

        insert_flow_language(objects: $flowLanguages) {
          returning {
            flow_id
            language
            flow {
              id
              flow_languages {
                flow_id
                language
              }
            }
          }
        }
      }
    `,
    variables: {
      flowId: flow.id,
      defaultLanguage,
      flowLanguages,
    },
  });
};

interface GetQuestionManagerOptions {
  apollo: ApolloClient<object>;
  trackUpdate: <T>(promise: Promise<T>) => Promise<T>;
  flowToken: string;
}

export const getQuestionManager = ({
  apollo,
  trackUpdate,
  flowToken,
}: GetQuestionManagerOptions) => ({
  addQuestion: (
    type: question_type_enum,
    translations: QuestionTranslations,
    required: boolean
  ) => trackUpdate(addQuestion(apollo, type, translations, required)),

  updateQuestion: (
    questionId: number,
    required: boolean,
    translations: QuestionTranslations
  ) => trackUpdate(updateQuestion(apollo, questionId, required, translations)),

  updateQuestionOption: (
    optionId: number,
    translations: OptionTranslations,
    reasonCode: string | null | undefined
  ) =>
    trackUpdate(
      updateQuestionOption(apollo, optionId, translations, reasonCode)
    ),

  setQuestionRequired: (questionId: number, required: boolean) =>
    trackUpdate(setQuestionRequired(apollo, questionId, required)),

  setQuestionSetting: (
    questionId: number,
    key: question_setting_key_enum,
    value: any
  ) => trackUpdate(setQuestionSetting(apollo, questionId, key, value)),

  addQuestionOption: (
    questionId: number,
    translations: OptionTranslations,
    reasonCode: string | null | undefined
  ) =>
    trackUpdate(
      addQuestionOption(apollo, questionId, translations, reasonCode)
    ),

  deleteQuestionOption: (question: FlowQuestionFragment, optionId: number) =>
    trackUpdate(deleteQuestionOption(apollo, question, optionId)),

  updateQuestionCondition: (condition: FormattedQuestionCondition) =>
    trackUpdate(updateQuestionCondition(apollo, condition)),

  moveQuestionOption: (
    question: FlowQuestionFragment,
    sourceIndex: number,
    destinationIndex: number
  ) =>
    trackUpdate(
      moveQuestionOption(apollo, question, sourceIndex, destinationIndex)
    ),

  updateLanguageSettings: (
    defaultLanguage: language_enum,
    enabledLanguages: language_enum[]
  ) =>
    updateLanguageSettings(
      apollo,
      flowToken,
      defaultLanguage,
      enabledLanguages
    ),
});

export const useQuestionManager = (
  options: Omit<GetQuestionManagerOptions, "apollo">
) => {
  const apollo = useApolloClient();

  return getQuestionManager({
    ...options,
    apollo,
  });
};

export type QuestionManager = ReturnType<typeof getQuestionManager>;
