import classNames from "classnames";

import { FlowStep } from "../lib/types";

interface PaginationProps {
  steps: FlowStep[];
  current: string;
  onSetStep: (id: string) => void;
  allDisabled: boolean;
}

const Pagination: React.FunctionComponent<PaginationProps> = ({
  steps,
  current,
  onSetStep,
  allDisabled,
}) => {
  const total = steps.length;
  const currentIndex = steps.findIndex((s) => s.id === current);

  if (total < 2) {
    return null;
  }

  return (
    <div className="flow-pagination">
      {steps.map((step, i) => {
        const disabled = i + 1 > currentIndex || allDisabled;
        return (
          <button
            key={i}
            className={classNames({
              "flow-pagination__page": true,
              "flow-pagination__page--active": current === step.id,
            })}
            onClick={() => onSetStep(step.id)}
            disabled={disabled}
          />
        );
      })}
    </div>
  );
};

export default Pagination;
