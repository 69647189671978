import { useMutation } from "@apollo/client";
import gql from "graphql-tag";

import {
  language_enum,
  offer_set_input,
  OfferDetailsFragment,
  translation_value_format_enum,
  translation_value_insert_input,
  UpdateOfferMutation,
  UpdateOfferMutationVariables,
} from "../../../__generated__/graphql";
import TheOfferDetailsFragment from "../../../common/fragments/OfferDetailsFragment";
import TranslationFragment from "../../../common/fragments/TranslationFragment";

export default function useUpdateOffer() {
  const [updateOffer] = useMutation<
    UpdateOfferMutation,
    UpdateOfferMutationVariables
  >(gql`
    mutation UpdateOfferMutation(
      $id: Int!
      $offer: offer_set_input!
      $translationValues: [translation_value_insert_input!]!
    ) {
      update_offer_by_pk(pk_columns: { id: $id }, _set: $offer) {
        ...OfferDetailsFragment
      }

      insert_translation_value(
        objects: $translationValues
        on_conflict: {
          constraint: translation_value_pkey
          update_columns: [value]
        }
      ) {
        returning {
          translation_id
          language
          value
          translation {
            ...TranslationFragment
          }
        }
      }
    }
    ${TheOfferDetailsFragment}
    ${TranslationFragment}
  `);

  return (
    offer: OfferDetailsFragment,
    set: offer_set_input,
    translations: Partial<
      Record<
        language_enum,
        {
          headline?: any;
          content?: any;
          contentFormat?: translation_value_format_enum;
          buttonText?: any;
          confirmationContent?: any;
          confirmationConfirmButtonText?: any;
          confirmationCancelButtonText?: any;
        }
      >
    >
  ) => {
    const translationValues: translation_value_insert_input[] = [];

    for (const [language, values] of Object.entries(translations)) {
      if (!values) {
        continue;
      }

      translationValues.push({
        translation_id: offer.headline_translation.id,
        language: language as language_enum,
        value: values.headline,
        format: translation_value_format_enum.text,
      });

      if (!!values.content) {
        translationValues.push({
          translation_id: offer.content_translation.id,
          language: language as language_enum,
          value: JSON.parse(values.content),
          format: values.contentFormat,
        });
      }

      translationValues.push({
        translation_id: offer.button_text_translation.id,
        language: language as language_enum,
        value: values.buttonText,
        format: translation_value_format_enum.text,
      });
      if (offer.confirmation_content_translation) {
        const value = values.confirmationContent
          ? JSON.parse(values.confirmationContent)
          : "";

        translationValues.push({
          translation_id: offer.confirmation_content_translation.id,
          language: language as language_enum,
          value,
          format: translation_value_format_enum.lexical,
        });
      }
      if (offer.confirmation_confirm_button_text_translation) {
        translationValues.push({
          translation_id: offer.confirmation_confirm_button_text_translation.id,
          language: language as language_enum,
          value: values.confirmationConfirmButtonText,
          format: translation_value_format_enum.text,
        });
      }
      if (offer.confirmation_cancel_button_text_translation) {
        translationValues.push({
          translation_id: offer.confirmation_cancel_button_text_translation.id,
          language: language as language_enum,
          value: values.confirmationCancelButtonText,
          format: translation_value_format_enum.text,
        });
      }
    }

    return updateOffer({
      variables: {
        id: offer.id,
        offer: set,
        translationValues,
      },
    });
  };
}
