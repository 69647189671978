import { useApolloClient } from "@apollo/client";

import { ExistingOffersQuery } from "../../../__generated__/graphql";
import TheExistingOffersQuery from "../../../common/queries/ExistingOffersQuery";

export default function useValidateName(isDirty: boolean) {
  const apollo = useApolloClient();

  return async (value: string) => {
    if (!isDirty) {
      return undefined;
    }

    const result = await apollo.query<ExistingOffersQuery>({
      query: TheExistingOffersQuery,
    });

    if (result.data?.offer.find((offer) => offer.name === value)) {
      return "An offer with that name already exists.";
    }
  };
}
