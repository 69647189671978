import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ForwardedRef, forwardRef } from "react";
import { UnreachableCaseError } from "ts-essentials";
import tw, { theme } from "twin.macro";

export type BadgeColor =
  | "transparent"
  | "gray"
  | "gray-border"
  | "dark-gray"
  | "darker-gray"
  | "green"
  | "green-transparent"
  | "blue"
  | "red"
  | "orange"
  | "yellow"
  | "cyan";

interface BadgeProps {
  className?: string;
  color: BadgeColor;
  icon?: IconDefinition;
  iconSpin?: boolean;
  prefix?: string;
  size?: "xs" | "sm" | "md";
  forwardedRef?: ForwardedRef<HTMLSpanElement>;
  children?: React.ReactNode;
}

const Badge: React.FunctionComponent<BadgeProps> = ({
  className,
  color,
  icon,
  iconSpin,
  prefix,
  size = "md",
  forwardedRef,
  children,
}) => {
  const badgeCss = (() => {
    switch (color) {
      case "transparent":
        return tw`bg-white border-white text-gray-700 px-0`;

      case "gray":
        return tw`bg-gray-100 border-gray-100 text-gray-700 `;

      case "gray-border":
        return tw`bg-white border-gray-200 text-gray-700`;

      case "dark-gray":
        return tw`bg-gray-200 border-gray-200 text-gray-800`;

      case "darker-gray":
        return tw`bg-gray-400 border-gray-400 text-white`;

      case "green":
        return tw`bg-green border-green text-white`;

      case "green-transparent":
        return tw`bg-white border-white text-green font-semibold px-0`;

      case "blue":
        return tw`bg-blue border-blue text-white`;

      case "red":
        return tw`bg-red border-red text-white`;

      case "orange":
        return tw`bg-orange-300 border-orange-300 text-white`;

      case "yellow":
        return tw`bg-yellow-200 border-yellow-500 text-yellow-700`;

      case "cyan":
        return tw`bg-cyan-600 border-cyan-600 text-white`;

      default:
        throw new UnreachableCaseError(color);
    }
  })();

  const iconColor = (() => {
    switch (color) {
      case "transparent":
        return theme`colors.gray.600`;

      case "gray":
        return theme`colors.gray.600`;

      case "gray-border":
        return theme`colors.gray.600`;

      case "dark-gray":
        return theme`colors.gray.700`;

      case "darker-gray":
        return "white";

      case "green":
        return "white";

      case "green-transparent":
        return theme`colors.green.DEFAULT`;

      case "blue":
        return "white";

      case "red":
        return "white";

      case "orange":
        return "white";

      case "yellow":
        return theme`colors.yellow.700`;

      case "cyan":
        return "white";

      default:
        throw new UnreachableCaseError(color);
    }
  })();

  return (
    <span
      ref={forwardedRef}
      className={className}
      css={[
        tw`inline-block px-2 rounded border overflow-ellipsis whitespace-nowrap overflow-hidden truncate max-w-full`,
        badgeCss,
        size === "sm" ? tw`text-sm` : size === "xs" ? tw`text-xs` : undefined,
      ]}
    >
      {prefix && <span tw="font-semibold mr-2">{prefix}</span>}
      {icon && (
        <span css={iconSpin ? tw`mr-1` : undefined}>
          <FontAwesomeIcon
            icon={icon}
            spin={iconSpin}
            color={iconColor}
            fixedWidth
            transform={!iconSpin ? "shrink-2 down-0.5 left-3" : undefined}
          />
        </span>
      )}
      {children}
    </span>
  );
};

export default forwardRef<HTMLSpanElement, BadgeProps>((props, ref) => (
  <Badge {...props} forwardedRef={ref} />
));
