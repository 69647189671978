import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getSelection } from "lexical";
import { useEffect, useState } from "react";

const InitialFocusPlugin: React.FunctionComponent = () => {
  const [focused, setFocused] = useState(false);
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (focused) {
      return;
    }

    editor.update(() => {
      const selection = $getSelection();

      if (!selection) {
        editor.focus();
        setFocused(true);
      }
    });
  }, [editor, focused]);

  return null;
};

export default InitialFocusPlugin;
