import gql from "graphql-tag";

import FlowQuestionOptionVersionFragment from "./FlowQuestionOptionVersionFragment";

export default gql`
  fragment FlowQuestionOptionFragment on question_option {
    id
    question_id
    token
    reason_code
    published_version {
      ...FlowQuestionOptionVersionFragment
    }
    draft_version @include(if: $includeDrafts) {
      ...FlowQuestionOptionVersionFragment
    }
  }
  ${FlowQuestionOptionVersionFragment}
`;
