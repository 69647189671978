import {
  language_enum,
  TranslationFragment,
} from "../../../../__generated__/graphql";
import renderContent from "../../../../common/editor/lib/renderContent";
import Editor from "../../../../common/editor2/Editor";
import isTranslationContentEmpty from "../../../../common/isTranslationContentEmpty";
import translationValue from "../../../../common/translationValue";
import { PropertyConfig, PropertyValues } from "../../../properties/lib/types";

interface RenderDeflectionContentOptions {
  translation: TranslationFragment | null | undefined;
  placeholderText: string;
  previewMode: boolean;
  baseFontSize?: string;
  language: language_enum;
  defaultLanguage: language_enum;
  propertyValues: PropertyValues;
  propertyConfig: PropertyConfig;
  showPropertyPlaceholders: boolean;
  disableVideoAutoPlay?: boolean;
}

export default function renderDeflectionContent({
  translation,
  placeholderText = "Content",
  previewMode,
  baseFontSize,
  language,
  defaultLanguage,
  propertyValues,
  propertyConfig,
  showPropertyPlaceholders,
  disableVideoAutoPlay,
}: RenderDeflectionContentOptions) {
  const showContentPlaceholder = previewMode;

  if (!translation) {
    return null;
  }

  const contentTranslationValue = translationValue(
    translation,
    language,
    defaultLanguage
  );

  const state =
    typeof contentTranslationValue.value === "string" &&
    (contentTranslationValue.format === "lexical" ||
      contentTranslationValue.format === "slate")
      ? JSON.parse(contentTranslationValue.value)
      : contentTranslationValue.value;

  const isEmpty = isTranslationContentEmpty(
    translation,
    language,
    defaultLanguage
  );

  if (!state || isEmpty) {
    if (!showContentPlaceholder) {
      return null;
    }

    return (
      <p>
        <span tw="text-type-light">{placeholderText}</span>
      </p>
    );
  }

  const initialValue =
    typeof contentTranslationValue.value === "string"
      ? contentTranslationValue.value
      : JSON.stringify(contentTranslationValue.value);

  if (contentTranslationValue.format === "lexical") {
    return (
      <Editor
        initialValue={initialValue}
        initialValueKey={initialValue}
        allowVideoAutoPlay={!disableVideoAutoPlay}
        baseFontSize={baseFontSize}
        isReadOnly
      />
    );
  }

  return renderContent(
    state,
    disableVideoAutoPlay,
    propertyValues,
    propertyConfig,
    showPropertyPlaceholders
  );
}
