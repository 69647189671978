import { useForm } from "react-hook-form";
import { css } from "twin.macro";

import GlobalStyles from "../../../app/GlobalStyles";
import Card from "../../../common/card/Card";
import CardFormBody from "../../../common/card/CardFormBody";
import Button from "../../../common/form/Button";
import FieldInput from "../../../common/form/FieldInput";
import FieldLabel from "../../../common/form/FieldLabel";
import FieldRow from "../../../common/form/FieldRow";
import TextInput from "../../../common/form/input/TextInput";

interface FormValues {
  name: string;
  title: string;
  email: string;
  phone: string;
}

const formatPhone = (phone: string) => {
  const cleaned = ("" + phone).replace(/\D/g, "");
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }

  return null;
};

const EmailSignature: React.FunctionComponent = () => {
  const { register, watch } = useForm<FormValues>();

  const { name, title, email, phone } = watch();

  let actualPhone = (phone || "").replace(/\D/g, "");
  if (actualPhone.length === 10) {
    actualPhone = `1${actualPhone}`;
  }

  const formattedPhone = formatPhone(actualPhone);

  const copy = () => {
    const iframeNode = document.getElementById("email-signature-iframe");
    if (!iframeNode) {
      return;
    }

    const iframeWindow = (iframeNode as HTMLIFrameElement).contentWindow;
    if (!iframeWindow) {
      return;
    }

    const iframeDocument = iframeWindow.document;
    if (!iframeDocument) {
      return;
    }

    const selection = iframeWindow.getSelection();
    if (!selection) {
      return;
    }

    selection.removeAllRanges();

    const node = iframeDocument.getElementById("email-signature");
    const range = iframeDocument.createRange();

    if (node) {
      range.selectNode(node);
    }

    selection.addRange(range);
    iframeDocument.execCommand("copy");
  };

  return (
    <>
      <GlobalStyles />
      <div
        css={css`
          max-width: 560px;
          margin: 40px auto;
        `}
      >
        <Card>
          <div tw="p-4">
            <iframe
              id="email-signature-iframe"
              title="Email Signature"
              allow="clipboard-read; clipboard-write"
              css={css`
                height: 170px;
              `}
              srcDoc={`
                <table
                  id="email-signature"
                  cellpadding="0"
                  cellspacing="0"
                  style="vertical-align: -webkit-baseline-middle; font-size: medium; font-family: Arial;"
                >
                  <tbody>
                    <tr>
                      <td style="vertical-align: top">
                        <img
                          src="https://prosperstack.com/images/logo-square-light.png"
                          role="presentation"
                          width="100"
                          style="max-width: 100px; display: block"
                        />
                      </td>
                      <td width="10"><div></div></td>
                      <td style="vertical-align: top">
                        <table
                          cellpadding="0"
                          cellspacing="0"
                          style="vertical-align: -webkit-baseline-middle; font-size: medium; font-family: Arial;"
                        >
                          <tbody>
                            <tr>
                              <td>
                                <h3
                                  color="#232E3B"
                                  style="margin: 0px 0px 2px 0px; font-size: 16px; color: rgb(35, 46, 59);"
                                >
                                  ${name || "Name"}
                                </h3>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <p
                                  style="margin: 0px; color: #232e3b; font-size: 14px; line-height: 22px;"
                                >
                                  ${title || "Title"}
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td height="6"></td>
                            </tr>
                            <tr>
                              <td
                                color="#2F75FF"
                                direction="horizontal"
                                height="1"
                                style="width: 100%; border-bottom: 1px solid rgb(47, 117, 255); border-left: none; display: block;"
                              ></td>
                            </tr>
                            <tr>
                              <td height="6"></td>
                            </tr>
                            <tr>
                              <td>
                                <p
                                  style="margin: 0px; color: #626f7f; font-size: 14px; line-height: 22px;"
                                >
                                  <a
                                    href="mailto:${email}"
                                    color="#626f7f"
                                    style="color: #626f7f; text-decoration: none"
                                    >${email || "email@prosperstack.com"}</a
                                  >
                                </p>
                                ${
                                  formattedPhone
                                    ? `
                                <p
                                  style="margin: 0px; color: #626f7f; font-size: 14px; line-height: 22px;"
                                >
                                  <a
                                    href="tel:+${actualPhone}"
                                    color="#626f7f"
                                    style="color: #626f7f; text-decoration: none"
                                    >${formattedPhone}</a
                                  >
                                </p>
                                `
                                    : ""
                                }                                
                                <p
                                  style="margin: 0px; color: #626f7f; font-size: 14px; line-height: 22px;"
                                >
                                  <a
                                    href="https://prosperstack.com"
                                    color="#626f7f"
                                    style="color: #626f7f; text-decoration: none"
                                    >prosperstack.com</a
                                  >
                                </p>
                              </td>
                            </tr>
                            <tr height="10">
                              <div></div>
                            </tr>
                            <tr>
                              <td>
                                <table
                                  cellpadding="0"
                                  cellspacing="0"
                                  style="vertical-align: -webkit-baseline-middle; font-size: medium; font-family: Arial;"
                                >
                                  <tbody>
                                    <tr style="text-align: center">
                                      <td>
                                        <a
                                          href="https://twitter.com/ProsperStack"
                                          color="#2F75FF"
                                          style="display: inline-block; padding: 0px"
                                          ><img
                                            src="https://prosperstack.com/images/email-signature/twitter-icon.png"
                                            alt="twitter"
                                            color="#2F75FF"
                                            height="20"
                                            style="max-width: 20px; display: block"
                                        /></a>
                                      </td>
                                      <td width="5"><div></div></td>
                                      <td>
                                        <a
                                          href="https://www.linkedin.com/company/prosperstack/"
                                          color="#2F75FF"
                                          style="display: inline-block; padding: 0px"
                                          ><img
                                            src="https://prosperstack.com/images/email-signature/linkedin-icon.png"
                                            alt="linkedin"
                                            color="#2F75FF"
                                            height="20"
                                            style="max-width: 20px; display: block"
                                        /></a>
                                      </td>
                                      <td width="5"><div></div></td>
                                      <td>
                                        <a
                                          href="https://www.facebook.com/ProsperStack"
                                          color="#2F75FF"
                                          style="display: inline-block; padding: 0px"
                                          ><img
                                            src="https://prosperstack.com/images/email-signature/facebook-icon.png"
                                            alt="facebook"
                                            color="#2F75FF"
                                            height="20"
                                            style="max-width: 20px; display: block"
                                        /></a>
                                      </td>
                                      <td width="5"><div></div></td>
                                      <td>
                                        <a
                                          href="https://www.instagram.com/prosperstack/"
                                          color="#2F75FF"
                                          style="display: inline-block; padding: 0px"
                                          ><img
                                            src="https://prosperstack.com/images/email-signature/instagram-icon.png"
                                            alt="instagram"
                                            color="#2F75FF"
                                            height="20"
                                            style="max-width: 20px; display: block"
                                        /></a>
                                      </td>
                                      <td width="5"><div></div></td>
                                      <td>
                                        <a
                                          href="https://prosperstack.medium.com/"
                                          color="#2F75FF"
                                          style="display: inline-block; padding: 0px"
                                          ><img
                                            src="https://prosperstack.com/images/email-signature/medium-icon.png"
                                            alt="medium"
                                            color="#2F75FF"
                                            height="20"
                                            style="max-width: 20px; display: block"
                                        /></a>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>          
            `}
            />
          </div>
        </Card>
        <Card>
          <CardFormBody>
            <form>
              <FieldRow>
                <FieldLabel>
                  <label>Name</label>
                </FieldLabel>
                <FieldInput>
                  <TextInput {...register("name")} width="full" />
                </FieldInput>
              </FieldRow>
              <FieldRow>
                <FieldLabel>
                  <label>Title</label>
                </FieldLabel>
                <FieldInput>
                  <TextInput {...register("title")} width="full" />
                </FieldInput>
              </FieldRow>
              <FieldRow>
                <FieldLabel>
                  <label>Email</label>
                </FieldLabel>
                <FieldInput>
                  <TextInput {...register("email")} width="full" />
                </FieldInput>
              </FieldRow>
              <FieldRow>
                <FieldLabel>
                  <label>Phone</label>
                </FieldLabel>
                <FieldInput>
                  <TextInput {...register("phone")} />
                </FieldInput>
              </FieldRow>
            </form>
            <Button
              buttonType="secondary"
              tw="mt-4"
              onClick={copy}
              disabled={!name || !title || !email}
            >
              Copy
            </Button>
          </CardFormBody>
        </Card>
      </div>
    </>
  );
};

export default EmailSignature;
