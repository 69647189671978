import React from "react";

import StandardExternalLink from "../../common/StandardExternalLink";
import ErrorPage from "./ErrorPage";

interface Props {
  env: string;
}

interface State {
  hasError: boolean;
}

export default class ErrorBoundary extends React.Component<Props> {
  state: State = { hasError: false };
  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorPage
          heading="Something went wrong"
          message={
            <p>
              This isn't supposed to happen! Try reloading the page and
              attempting the action again.{" "}
              <StandardExternalLink href="/">
                Take me home.
              </StandardExternalLink>
            </p>
          }
        />
      );
    }

    return this.props.children;
  }
}
