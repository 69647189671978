import { platform_enum } from "../../../__generated__/graphql";
import {
  PlatformFeature,
  platformHasFeature,
} from "../../../common/platform/features";

const defaultInterval = (platform: platform_enum) =>
  !platformHasFeature(
    platform,
    PlatformFeature.PauseSubscriptionForTemporalLength,
    { defaultForCustom: true }
  ) &&
  platformHasFeature(platform, PlatformFeature.PauseSubscriptionForBillingCycle)
    ? "billing_cycle"
    : "month";

export default defaultInterval;
