import gql from "graphql-tag";

import FlowAcknowledgementFragment from "./FlowAcknowledgementFragment";
import TranslationFragment from "./TranslationFragment";

export default gql`
  fragment FlowAcknowledgementGroupVersionFragment on acknowledgement_group_version {
    id
    acknowledgement_group_id
    title_translation {
      ...TranslationFragment
    }
    acknowledgement_ids
    acknowledgement_group_version_acknowledgements {
      acknowledgement_group_version_id
      acknowledgement_id
      acknowledgement {
        ...FlowAcknowledgementFragment
      }
    }
  }
  ${TranslationFragment}
  ${FlowAcknowledgementFragment}
`;
