import tw, { css } from "twin.macro";

import image from "../../assets/icons/error.svg";

interface ErrorPageProps {
  title?: string;
  heading?: string;
  message: React.ReactNode;
}

const ErrorPage: React.FunctionComponent<ErrorPageProps> = ({
  title,
  heading,
  message,
}) => (
  <div
    css={[
      tw`w-[30rem] mt-[10rem] mb-0 mx-auto text-center`,
      css`
        a {
          ${tw`text-link hover:underline`}
        }
      `,
    ]}
  >
    {title && (
      <h3 tw="text-[4rem] leading-[4.2rem] font-title font-bold text-blue">
        {title}
      </h3>
    )}
    {heading && <div tw="text-blue font-semibold text-2xl">{heading}</div>}
    <div tw="mt-2 text-type-light">{message}</div>
    <img src={image} alt="" tw="mt-8 w-full" />
  </div>
);

export default ErrorPage;
