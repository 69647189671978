import gql from "graphql-tag";

import FlowOfferRuleGroupVersionFragment from "./FlowOfferRuleGroupVersionFragment";

export default gql`
  fragment FlowOfferRuleGroupFragment on offer_rule_group {
    id
    title
    published_version {
      ...FlowOfferRuleGroupVersionFragment
    }
    draft_version @include(if: $includeDrafts) {
      ...FlowOfferRuleGroupVersionFragment
    }
  }
  ${FlowOfferRuleGroupVersionFragment}
`;
