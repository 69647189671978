import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import tw, { css } from "twin.macro";

import TextInput from "../../common/form/input/TextInput";

interface MultipleTextsInputProps {
  value: string[];
  placeholder?: string;
  onChange: (value: string[]) => void;
}

const DeleteButton = tw.button`flex justify-between items-center text-gray-400 hover:text-gray-500 focus:outline-none disabled:opacity-0 disabled:cursor-default ml-2`;

const MultipleTextsInput: React.FunctionComponent<MultipleTextsInputProps> = ({
  value,
  placeholder,
  onChange,
}) => {
  const valueObject = value.reduce<Record<string, string>>(
    (prev, value, i) => ({
      ...prev,
      [i.toString()]: value,
    }),
    {}
  );

  const handleValueChange = (value: string, index: number) => {
    onChange(
      Object.values({
        ...valueObject,
        [index.toString()]: value,
      })
    );
  };

  const handleClickDelete = (index: number) => {
    const clonedValues = { ...valueObject };
    delete clonedValues[index.toString()];

    const newValues = Object.values(clonedValues).reduce(
      (prev, value, i) => ({
        ...prev,
        [i.toString()]: value,
      }),
      {}
    );

    onChange(Object.values(newValues));
  };

  const count = value.length + 1;

  const inputs = [...Array(count).keys()].map((i) => (
    <div key={i} tw="flex mt-2 first:mt-0">
      <TextInput
        value={valueObject[i.toString()]}
        onChange={(e) => handleValueChange(e.currentTarget.value, i)}
        css={css`
          width: 240px;
        `}
        placeholder={placeholder}
      />

      <DeleteButton
        type="button"
        onClick={() => handleClickDelete(i)}
        disabled={!valueObject[i.toString()]}
      >
        <FontAwesomeIcon icon={faTimes} />
      </DeleteButton>
    </div>
  ));

  return <div>{inputs}</div>;
};

export default MultipleTextsInput;
