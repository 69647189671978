import { useState } from "react";
import { css } from "twin.macro";

interface YouTubeProps {
  videoId: string;
  autoPlay: boolean;
}

const YouTube: React.FunctionComponent<YouTubeProps> = ({
  videoId,
  autoPlay,
}) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <>
      <div
        css={css`
          position: relative;
          padding-bottom: 56.25%;
          height: 0;
          overflow: hidden;
          max-width: 100%;
          iframe,
          object,
          embed {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        `}
      >
        <iframe
          css={css`
            opacity: ${loaded ? "1" : "0"};
          `}
          title="Video"
          src={`https://www.youtube.com/embed/${videoId}?modestbranding=1&autoplay=${
            autoPlay ? "1" : "0"
          }`}
          frameBorder="0"
          allowFullScreen
          allow="autoplay; fullscreen"
          allowTransparency
          onLoad={() => setLoaded(true)}
        ></iframe>
      </div>
    </>
  );
};

export default YouTube;
