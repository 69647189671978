import { ELEMENT_PARAGRAPH } from "@udecode/slate-plugins";
import { Editor, Transforms } from "slate";

import serializeToString from "../serializeToString";
import { ELEMENT_VIDEO } from ".";

export default function insertVideo(
  editor: Editor,
  url: string,
  options: { autoPlay: boolean }
) {
  const parentEntry = Editor.above(editor, {
    match: (n) => n.type === ELEMENT_PARAGRAPH,
  });

  const element = {
    type: ELEMENT_VIDEO,
    url,
    options,
    children: [{ text: "" }],
  };

  if (parentEntry) {
    const [parentNode] = parentEntry;
    if (serializeToString([parentNode]) === "") {
      Transforms.setNodes(editor, element);
      return;
    }
  }

  Transforms.insertNodes(editor, element);

  Transforms.move(editor);
}
