import gql from "graphql-tag";

import FlowDeflectionRuleRuleFragment from "./FlowDeflectionRuleRuleFragment";
import FlowSegmentFragment from "./FlowSegmentFragment";
import FlowSegmentGroupFragment from "./FlowSegmentGroupFragment";
import TranslationFragment from "./TranslationFragment";

export default gql`
  fragment FlowDeflectionRuleVersionFragment on offer_rule_version {
    id
    offer_rule_id
    segment_group_ids
    segment_ids
    question_option_ids
    include_present_no_offer
    include_other_in_question_ids
    offer_rule_rule_ids

    offer_rule_version_segment_groups(
      order_by: { segment_group: { name: asc } }
    ) {
      offer_rule_version_id
      segment_group_id
      segment_group {
        ...FlowSegmentGroupFragment
      }
    }

    offer_rule_version_segments(order_by: { segment: { name: asc } }) {
      offer_rule_version_id
      segment_id
      segment {
        ...FlowSegmentFragment
      }
    }

    offer_rule_version_question_options(order_by: { position: asc }) {
      offer_rule_version_id
      question_option_id
      question_option {
        id
        question_id
        published_version {
          id
          title_translation {
            ...TranslationFragment
          }
        }
        draft_version @include(if: $includeDrafts) {
          id
          title_translation {
            ...TranslationFragment
          }
        }
      }
    }

    offer_rule_version_offer_rule_rules {
      offer_rule_version_id
      offer_rule_rule_id
      offer_rule_rule {
        ...FlowDeflectionRuleRuleFragment
      }
    }
  }
  ${FlowSegmentGroupFragment}
  ${FlowSegmentFragment}
  ${TranslationFragment}
  ${FlowDeflectionRuleRuleFragment}
`;
