import { useState } from "react";
import { Controller } from "react-hook-form";

import {
  language_enum,
  TranslationFragment,
} from "../../../__generated__/graphql";
import Editor from "../../../common/editor2/Editor";
import Button from "../../../common/form/Button";
import FieldHint from "../../../common/form/FieldHint";
import FieldInput from "../../../common/form/FieldInput";
import FieldLabel from "../../../common/form/FieldLabel";
import FieldRow from "../../../common/form/FieldRow";
import FormMode from "../../../common/form/FormMode";
import TextInput from "../../../common/form/input/TextInput";
import useTranslatableForm, {
  TranslatedForms,
} from "../../../common/form/useTranslatableForm";
import Modal, { ModalProps } from "../../../common/modal/Modal";
import ModalFooter from "../../../common/modal/ModalFooter";
import ModalFormBody from "../../../common/modal/ModalFormBody";
import ModalHeader from "../../../common/modal/ModalHeader";
import useFocusFirstEmptyInput from "../../../common/useFocusFirstEmptyInput";
import LanguageRadio from "./LanguageRadio";

export interface FormValues {
  title: string;
  label: string;
}

type AddAcknowledgementModalProps = ModalProps & {
  mode: FormMode;
  flowId: number;
  flowToken: string;
  language: language_enum;
  enabledLanguages: language_enum[];
  defaultLanguage: language_enum;
  labelTranslation?: TranslationFragment;
  onClose: () => void;
  onSave: (forms: TranslatedForms<FormValues>) => Promise<void>;
};

const AddAcknowledgementModal: React.FunctionComponent<
  AddAcknowledgementModalProps
> = ({
  mode,
  flowId,
  flowToken,
  language,
  enabledLanguages,
  defaultLanguage,
  labelTranslation,
  onClose,
  onSave,
  ...props
}) => {
  const defaultValues = enabledLanguages.reduce((prev, enabledLanguage) => {
    const label = labelTranslation?.translation_values.find(
      (value) => value.language === enabledLanguage
    );

    let labelValue: any = undefined;

    if (label?.format === "lexical") {
      labelValue = label.value;
    } else if (label?.format === "text") {
      labelValue = {
        root: {
          type: "root",
          format: "",
          indent: 0,
          version: 1,
          children: [
            {
              type: "paragraph",
              format: "",
              indent: 0,
              version: 1,
              children: [
                {
                  mode: "normal",
                  text: label.value,
                  type: "text",
                  style: "",
                  detail: 0,
                  format: 0,
                  version: 1,
                },
              ],
              direction: "ltr",
            },
          ],
          direction: "ltr",
        },
      };
    }

    return {
      ...prev,
      [enabledLanguage]: {
        label: labelValue ? JSON.stringify(labelValue) : undefined,
      },
    };
  }, {});

  const {
    register,
    handleSubmit,
    editingLanguage,
    setEditingLanguage,
    control,
    forms,
  } = useTranslatableForm<FormValues>({
    initialLanguage: language,
    languages: enabledLanguages,
    defaultLanguage,
    defaultValues,
    translatableFields: ["label"],
  });
  const [formRef, setFormRef] = useState<HTMLFormElement | null>(null);
  useFocusFirstEmptyInput(formRef);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = handleSubmit(async (forms) => {
    setIsSubmitting(true);
    await onSave(forms);
    onClose();
  });

  return (
    <Modal {...props}>
      <ModalHeader>
        {mode === "create" ? "Add acknowledgement" : "Edit acknowledgement"}
      </ModalHeader>
      <ModalFormBody>
        <form
          id="add-acknowledgement"
          onSubmit={onSubmit}
          ref={(ref) => {
            setFormRef(ref);
          }}
        >
          <fieldset disabled={isSubmitting}>
            {mode === "create" && (
              <FieldRow>
                <FieldLabel>
                  <label htmlFor="title">Title</label>
                </FieldLabel>
                <FieldInput>
                  <TextInput
                    {...register("title", { required: true })}
                    id="title"
                    width="lg"
                  />
                  <FieldHint>
                    For internal reference only. Not displayed to subscribers.
                  </FieldHint>
                </FieldInput>
              </FieldRow>
            )}

            {enabledLanguages.length > 1 ? (
              <LanguageRadio
                value={editingLanguage}
                languages={enabledLanguages}
                defaultLanguage={defaultLanguage}
                onChange={(newLanguage) => setEditingLanguage(newLanguage)}
              />
            ) : null}
            <FieldRow>
              <FieldLabel>
                <label htmlFor="label">Label</label>
              </FieldLabel>
              <div tw="py-3 flex-grow">
                <Controller
                  key={editingLanguage}
                  control={control}
                  name="label"
                  render={({ field }) => (
                    <Editor
                      initialValue={forms[editingLanguage]?.label}
                      initialValueKey={editingLanguage}
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                      isInline={true}
                      linksEnabled={false}
                    />
                  )}
                />
                <FieldHint>
                  Displayed to the subscriber, e.g. "I acknowledge that..."
                </FieldHint>
              </div>
            </FieldRow>
          </fieldset>
        </form>
      </ModalFormBody>
      <ModalFooter>
        <Button
          buttonType="primary"
          form="add-acknowledgement"
          isLoading={isSubmitting}
          disabled={isSubmitting}
        >
          {mode === "create" ? "Add" : "Save"}
        </Button>
        <Button buttonType="default" onClick={onClose} disabled={isSubmitting}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddAcknowledgementModal;
