import { FieldError } from "react-hook-form";
import tw, { css, styled } from "twin.macro";

import sharedInputStyles from "./sharedInputStyles";

interface SelectInputProps {
  width?: string;
  fieldError?: FieldError;
}

const SelectInput = styled.select.attrs<SelectInputProps>(
  (props: SelectInputProps) => ({
    width: props.width || "md",
  })
)<SelectInputProps>`
  ${sharedInputStyles}
  appearance: none;
  padding-right: 23px;
  text-overflow: ellipsis;
  background-color: #fff;

  ${(props) =>
    !props.multiple &&
    css`
      background-image: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0.3) 0%,
          rgba(255, 255, 255, 0.3) 100%
        ),
        url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20320%20512%22%3E%3Cpath%20fill%3D%22currentColor%22%20d%3D%22M31.3%20192h257.3c17.8%200%2026.7%2021.5%2014.1%2034.1L174.1%20354.8c-7.8%207.8-20.5%207.8-28.3%200L17.2%20226.1C4.6%20213.5%2013.5%20192%2031.3%20192z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E");
      background-repeat: repeat, no-repeat;
      background-position: 0 0, right 0.5em top 50%;
      background-size: 100%, 0.65em auto;
    `}

  ${(props) =>
    props.width === "xs" &&
    css`
      width: 100px;
    `}
  ${(props) =>
    props.width === "sm" &&
    css`
      width: 150px;
    `}
  ${(props) =>
    props.width === "md" &&
    css`
      width: 200px;
    `}
  ${(props) =>
    props.width === "lg" &&
    css`
      width: 300px;
    `}
  ${(props) =>
    props.width === "xl" &&
    css`
      width: 400px;
    `}
  ${(props) => props.fieldError && tw`border-red-500 focus:ring-red-200`}
`;

export default SelectInput;
