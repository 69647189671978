import { ELEMENT_IMAGE, ELEMENT_PARAGRAPH } from "@udecode/slate-plugins";
import { Editor, Transforms } from "slate";

import serializeToString from "../serializeToString";

export default function insertImage(editor: Editor, url: string) {
  const parentEntry = Editor.above(editor, {
    match: (n) => n.type === ELEMENT_PARAGRAPH,
  });

  const element = {
    type: ELEMENT_IMAGE,
    url,
    children: [{ text: "" }],
  };

  if (parentEntry) {
    const [parentNode] = parentEntry;
    if (serializeToString([parentNode]) === "") {
      Transforms.setNodes(editor, element);
      return;
    }
  }

  Transforms.insertNodes(editor, element);

  Transforms.move(editor);
}
