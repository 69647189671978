import { language_enum } from "../__generated__/graphql";

const descriptions = {
  [language_enum.en_us]: "English",
  [language_enum.en_gb]: "English (UK)",
  [language_enum.fr]: "French",
  [language_enum.de]: "German",
  [language_enum.pl]: "Polish",
  [language_enum.nl]: "Dutch",
  [language_enum.fi]: "Finnish",
  [language_enum.sv]: "Swedish",
  [language_enum.it]: "Italian",
  [language_enum.es]: "Spanish",
  [language_enum.pt]: "Portuguese (Portugal)",
  [language_enum.ru]: "Russian",
  [language_enum.pt_br]: "Portuguese (Brazil)",
  [language_enum.es_mx]: "Spanish (Mexico)",
  [language_enum.zh_cn]: "Chinese (PRC)",
  [language_enum.zh_tw]: "Chinese (Taiwan)",
  [language_enum.ja]: "Japanese",
  [language_enum.ko]: "Korean",
};

export const availableLanguages = [
  language_enum.en_us,
  language_enum.en_gb,
  language_enum.fr,
  language_enum.de,
  language_enum.pl,
  language_enum.nl,
  language_enum.fi,
  language_enum.sv,
  language_enum.it,
  language_enum.es,
  language_enum.pt,
  language_enum.pt_br,
];

export const supportedLanguages = [language_enum.en_us];

export const languageDescription = (language: language_enum) =>
  descriptions[language];

export const isoToEnum = (iso: string) => {
  const possible = iso.replace("-", "_").toLowerCase();
  if (availableLanguages.includes(possible as language_enum)) {
    return possible as language_enum;
  }

  const split = possible.split("_");
  if (
    split.length === 2 &&
    availableLanguages.includes(split[0] as language_enum)
  ) {
    return split[0] as language_enum;
  }

  return null;
};

export const enumToIso = (language: language_enum) => {
  const split = language.split("_");
  if (split.length < 2) {
    return split[0];
  }

  return split[0] + "-" + split[1].toUpperCase();
};

export const sortLanguages = (
  languages: language_enum[],
  defaultLanguage: language_enum
) => {
  const cloned = [...languages];

  cloned.sort((a, b) =>
    languageDescription(a) > languageDescription(b) ? 1 : -1
  );
  cloned.splice(cloned.indexOf(defaultLanguage), 1);
  cloned.unshift(defaultLanguage);

  return cloned;
};
