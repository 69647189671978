import { Editor } from "slate";

import { ELEMENT_VIDEO } from ".";

export default function withVideo<T extends Editor>(editor: T) {
  const { isVoid } = editor;

  editor.isVoid = (element) =>
    element.type === ELEMENT_VIDEO ? true : isVoid(element);

  return editor;
}
