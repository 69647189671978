import gql from "graphql-tag";

import FlowAcknowledgementGroupVersionFragment from "./FlowAcknowledgementGroupVersionFragment";

export default gql`
  fragment FlowAcknowledgementGroupFragment on acknowledgement_group {
    id
    published_version {
      ...FlowAcknowledgementGroupVersionFragment
    }
    draft_version @include(if: $includeDrafts) {
      ...FlowAcknowledgementGroupVersionFragment
    }
  }
  ${FlowAcknowledgementGroupVersionFragment}
`;
