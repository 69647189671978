import { isPresent } from "ts-is-present";

import getFlowObjectVersion from "../../../../common/flow/getFlowObjectVersion";
import { FlowVersion } from "../../../../common/flow/types";
import sanitizeAndFormatQuestionAnswers from "./sanitizeAndFormatQuestionAnswers";
import { FlowStep, FormStep, QuestionAnswer, QuestionStep } from "./types";

// Ensure all displayed questions have an answer and that conditionally hidden
// questions do not have an answer
const sanitizeAndFormatStepAnswers = (
  questionAnswers: QuestionAnswer[],
  steps: FlowStep[],
  flowVersion: FlowVersion
): QuestionAnswer[] => {
  const flowQuestions = steps
    .map((step) =>
      step.type === "form"
        ? getFlowObjectVersion(
            (step as FormStep).form,
            flowVersion
          ).form_version_questions.map((q) => q.question)
        : step.type === "question"
        ? [(step as QuestionStep).question]
        : []
    )
    .reduce((prev, questions) => [...prev, ...questions], [])
    .filter(isPresent);

  return sanitizeAndFormatQuestionAnswers(
    flowQuestions,
    questionAnswers,
    flowVersion
  );
};

export default sanitizeAndFormatStepAnswers;
