import Tippy from "@tippyjs/react";
import classNames from "classnames";
import tw, { styled } from "twin.macro";

export interface PillRadioOption {
  value: string;
  label: React.ReactNode;
  tooltip?: string;
  disabled?: boolean;
}

interface PillRadioProps {
  className?: string;
  value: string;
  options: PillRadioOption[];
  onChange: (value: string) => void;
  borderHighlight?: boolean;
}

const Pill = styled.span<{
  active: boolean;
  borderHighlight: boolean;
  disabled?: boolean;
}>`
  ${tw`rounded-full bg-white whitespace-nowrap border cursor-pointer mr-1 border-gray-300`}
  padding: 2px 9px 3px 9px;
  ${(props) =>
    props.active &&
    !props.borderHighlight &&
    tw`bg-blue-500 border-blue-500 text-white`}
  ${(props) => props.active && props.borderHighlight && tw`border-blue-500`}

  ${(props) => props.disabled && tw`opacity-50 pointer-events-none`}

  fieldset:disabled & {
    ${tw`opacity-50 pointer-events-none`}
  }
`;

const PillRadio: React.FunctionComponent<PillRadioProps> = ({
  className,
  value,
  options,
  onChange,
  borderHighlight = false,
}) => (
  <div className={className} tw="inline-block mr-6 leading-8">
    {options.map((option) => (
      <Tippy
        key={option.value}
        content={option.tooltip}
        disabled={!option.tooltip}
      >
        <span>
          <Pill
            active={option.value === value}
            onClick={() => onChange(option.value)}
            borderHighlight={borderHighlight}
            disabled={option.disabled}
            className={classNames(option.value === value && "selected")}
          >
            {option.label}
          </Pill>
        </span>
      </Tippy>
    ))}
  </div>
);

export default PillRadio;
