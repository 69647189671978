import gql from "graphql-tag";

import FlowQuestionSettingFragment from "./FlowQuestionSettingFragment";
import FlowQuestionVersionFragment from "./FlowQuestionVersionFragment";

export default gql`
  fragment FlowQuestionFragment on question {
    id
    token
    type
    primary
    published_version {
      ...FlowQuestionVersionFragment
    }
    draft_version @include(if: $includeDrafts) {
      ...FlowQuestionVersionFragment
    }
    question_settings {
      ...FlowQuestionSettingFragment
    }
  }
  ${FlowQuestionVersionFragment}
  ${FlowQuestionSettingFragment}
`;
