import {
  faBold,
  faH1,
  faH2,
  faImage,
  faItalic,
  faLinkSimple,
  faListOl,
  faListUl,
  faTag,
  faUnderline,
  faVideo,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import {
  $isListNode,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListNode,
  REMOVE_LIST_COMMAND,
} from "@lexical/list";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  $createHeadingNode,
  $isHeadingNode,
  HeadingTagType,
} from "@lexical/rich-text";
import { $wrapNodes } from "@lexical/selection";
import { $getNearestNodeOfType, mergeRegister } from "@lexical/utils";
import {
  $createParagraphNode,
  $getRoot,
  $getSelection,
  $isRangeSelection,
  $setSelection,
  COMMAND_PRIORITY_CRITICAL,
  FORMAT_TEXT_COMMAND,
  SELECTION_CHANGE_COMMAND,
} from "lexical";
import { nanoid } from "nanoid";
import { useCallback, useEffect, useState } from "react";

import { property_format_enum } from "../../../__generated__/graphql";
import DropdownMenu, { MenuItem } from "../../DropdownMenu";
import InsertImageModal from "../../editor/InsertImageModal";
import InsertPropertyTagModal, {
  InsertPropertTagFormValues,
} from "../../editor/InsertPropertyTagModal";
import InsertVideoModal from "../../editor/InsertVideoModal";
import { PropertyTag } from "../../editor/lib/PropertyTagPlugin/types";
import hotkey from "../../hotkey";
import useModal from "../../modal/useModal";
import { getSelectedNode } from "../lib";
import LinkModal from "../ui/LinkModal";
import ToolbarButton from "../ui/ToolbarButton";
import { INSERT_IMAGE_COMMAND } from "./ImagePlugin";
import { INSERT_OFFER_DESCRIPTION_COMMAND } from "./OfferDescriptionPlugin";
import { INSERT_PROPERTY_COMMAND } from "./PropertyPlugin";
import { INSERT_VIDEO_COMMAND } from "./VideoPlugin";

interface ToolbarPluginProps {
  isInline: boolean;
  tagsEnabled: boolean;
  linksEnabled: boolean;
  listsEnabled: boolean;
  imagesEnabled: boolean;
  videosEnabled: boolean;
  customPropertiesEnabled: boolean;
  isOfferContent: boolean;
}

const ToolbarPlugin: React.FunctionComponent<ToolbarPluginProps> = ({
  isInline,
  tagsEnabled,
  linksEnabled,
  listsEnabled,
  imagesEnabled,
  videosEnabled,
  customPropertiesEnabled,
  isOfferContent,
}) => {
  const [editor] = useLexicalComposerContext();
  const [activeEditor, setActiveEditor] = useState(editor);
  const [blockType, setBlockType] = useState("paragraph");

  const [isLink, setIsLink] = useState(false);
  const [linkURL, setLinkURL] = useState<string | null>(null);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);

  const [hasSelection, setHasSelection] = useState(false);
  const [selectionIsCollapsed, setSelectionIsCollapsed] = useState(false);

  const [
    insertPropertyTagModalIsOpen,
    setInsertPropertyTagModalIsOpen,
    insertPropertyTagModalKey,
  ] = useModal();

  const [insertImageModalIsOpen, setInsertImageModalIsOpen] = useState(false);
  const [insertImageModalKey, setInsertImageModalKey] = useState(nanoid());
  const [insertVideoModalIsOpen, setInsertVideoModalIsOpen] = useState(false);
  const [insertVideoModalKey, setInsertVideoModalKey] = useState(nanoid());
  const [linkModalIsOpen, setLinkModalIsOpen] = useState(false);
  const [linkModalKey, setLinkModalKey] = useState(nanoid());

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    setHasSelection(!!selection);

    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === "root"
          ? anchorNode
          : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = activeEditor.getElementByKey(elementKey);

      // Update text format
      setIsBold(selection.hasFormat("bold"));
      setIsItalic(selection.hasFormat("italic"));
      setIsUnderline(selection.hasFormat("underline"));

      setSelectionIsCollapsed(selection.isCollapsed());

      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
        setLinkURL(null);
      }
      if ($isLinkNode(node)) {
        setLinkURL(node.getURL());
      }
      if ($isLinkNode(parent)) {
        setLinkURL(parent.getURL());
      }

      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType<ListNode>(
            anchorNode,
            ListNode
          );
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();
          setBlockType(type);
        }
      }
    }
  }, [activeEditor]);

  useEffect(
    () =>
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, newEditor) => {
          updateToolbar();
          setActiveEditor(newEditor);
          return false;
        },
        COMMAND_PRIORITY_CRITICAL
      ),
    [editor, updateToolbar]
  );

  useEffect(
    () =>
      mergeRegister(
        activeEditor.registerUpdateListener(({ editorState }) => {
          editorState.read(() => {
            updateToolbar();
          });
        })
      ),
    [activeEditor, updateToolbar]
  );

  const setSelection = () => {
    editor.update(() => {
      const selection = $getSelection();
      if (!selection) {
        const selection = $getRoot().selectEnd();
        $setSelection(selection);
      }
    });
  };

  const formatHeading = (headingTag: HeadingTagType) => {
    setSelection();

    if (blockType !== headingTag) {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode(headingTag));
        }
      });
    } else {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createParagraphNode());
        }
      });
    }
  };

  const formatBulletedList = () => {
    setSelection();

    if (blockType !== "ul") {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  const formatNumberedList = () => {
    setSelection();

    if (blockType !== "ol") {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  const handleInsertProperty = (
    result?: InsertPropertTagFormValues,
    tag?: PropertyTag
  ) => {
    if (!result) {
      setInsertPropertyTagModalIsOpen(false);
      return;
    }

    if (!tag) {
      throw new Error();
    }

    setSelection();

    activeEditor.dispatchCommand(INSERT_PROPERTY_COMMAND, {
      tag,
      propertyId: result.propertyId,
      dateFormat: result.dateFormat,
      numberFormat: result.numberFormat as property_format_enum,
      fallback: result.fallback,
      isArray: result.isArray,
    });

    setInsertPropertyTagModalIsOpen(false);
  };

  return (
    <>
      <div tw="border-b border-divider p-1 bg-white">
        {!isInline && (
          <>
            <ToolbarButton
              onClick={() => formatHeading("h4")}
              isActive={blockType === "h4"}
              tooltip="Heading 1"
            >
              <FontAwesomeIcon icon={faH1} />
            </ToolbarButton>
            <ToolbarButton
              onClick={() => formatHeading("h5")}
              isActive={blockType === "h5"}
              tooltip="Heading 2"
            >
              <FontAwesomeIcon icon={faH2} />
            </ToolbarButton>
          </>
        )}
        <ToolbarButton
          onClick={() => {
            setSelection();
            activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
          }}
          isActive={isBold}
          tooltip={`Bold (${hotkey("mod+b")})`}
        >
          <FontAwesomeIcon icon={faBold} />
        </ToolbarButton>
        <ToolbarButton
          onClick={() => {
            setSelection();
            activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
          }}
          isActive={isItalic}
          tooltip={`Italic (${hotkey("mod+i")})`}
        >
          <FontAwesomeIcon icon={faItalic} />
        </ToolbarButton>
        <ToolbarButton
          onClick={() => {
            setSelection();
            activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline");
          }}
          isActive={isUnderline}
          tooltip={`Underline (${hotkey("mod+u")})`}
        >
          <FontAwesomeIcon icon={faUnderline} />
        </ToolbarButton>

        {!isInline && listsEnabled && (
          <>
            <ToolbarButton
              onClick={() => formatBulletedList()}
              isActive={blockType === "ul"}
              tooltip="Bulleted list"
            >
              <FontAwesomeIcon icon={faListUl} />
            </ToolbarButton>
            <ToolbarButton
              onClick={() => formatNumberedList()}
              isActive={blockType === "ol"}
              tooltip="Numbered list"
            >
              <FontAwesomeIcon icon={faListOl} />
            </ToolbarButton>
          </>
        )}

        {linksEnabled && (
          <ToolbarButton
            onClick={() => setLinkModalIsOpen(true)}
            isActive={isLink}
            disabled={!hasSelection || (selectionIsCollapsed && !isLink)}
            tooltip="Link"
          >
            <FontAwesomeIcon icon={faLinkSimple} />
          </ToolbarButton>
        )}

        {tagsEnabled && (
          <>
            {isOfferContent ? (
              <DropdownMenu
                zIndex={60}
                items={
                  [
                    {
                      label: "Offer description",
                      onClick: () => {
                        setSelection();

                        activeEditor.dispatchCommand(
                          INSERT_OFFER_DESCRIPTION_COMMAND,
                          undefined
                        );
                      },
                    },
                    customPropertiesEnabled
                      ? {
                          label: "Other properties...",
                          onClick: () => setInsertPropertyTagModalIsOpen(true),
                        }
                      : undefined,
                  ] as MenuItem[]
                }
                render={(props) => (
                  <span>
                    <ToolbarButton {...props} tooltip="Property tag">
                      <FontAwesomeIcon icon={faTag} />
                    </ToolbarButton>
                  </span>
                )}
              />
            ) : (
              <ToolbarButton
                onClick={() => setInsertPropertyTagModalIsOpen(true)}
                tooltip="Property tag"
              >
                <FontAwesomeIcon icon={faTag} />
              </ToolbarButton>
            )}
          </>
        )}

        {!isInline && imagesEnabled && (
          <ToolbarButton
            onClick={() => setInsertImageModalIsOpen(true)}
            tooltip="Image"
          >
            <FontAwesomeIcon icon={faImage} />
          </ToolbarButton>
        )}

        {!isInline && videosEnabled && (
          <ToolbarButton
            onClick={() => setInsertVideoModalIsOpen(true)}
            tooltip="Video"
          >
            <FontAwesomeIcon icon={faVideo} />
          </ToolbarButton>
        )}
      </div>

      <LinkModal
        key={linkModalKey + linkURL}
        isOpen={linkModalIsOpen}
        initialValues={
          linkURL
            ? {
                url: linkURL,
              }
            : undefined
        }
        onClose={(values) => {
          if (values) {
            activeEditor.dispatchCommand(
              TOGGLE_LINK_COMMAND,
              !!values.url ? values.url : null
            );
          }

          setLinkModalIsOpen(false);
          setLinkModalKey(nanoid());
        }}
      />

      <InsertImageModal
        key={insertImageModalKey}
        mode="insert"
        isOpen={insertImageModalIsOpen}
        onClose={(result) => {
          setInsertImageModalIsOpen(false);
          setInsertImageModalKey(nanoid());

          if (result) {
            setSelection();

            activeEditor.dispatchCommand(INSERT_IMAGE_COMMAND, {
              src: result.url,
            });
          }
        }}
      />

      <InsertVideoModal
        key={insertVideoModalKey}
        mode="insert"
        isOpen={insertVideoModalIsOpen}
        onClose={(result) => {
          setInsertVideoModalIsOpen(false);
          setInsertVideoModalKey(nanoid());

          if (result) {
            setSelection();

            activeEditor.dispatchCommand(INSERT_VIDEO_COMMAND, {
              src: result.url,
              autoPlay: result.autoPlay,
            });
          }
        }}
      />

      <InsertPropertyTagModal
        key={insertPropertyTagModalKey}
        mode="insert"
        isOpen={insertPropertyTagModalIsOpen}
        onClose={handleInsertProperty}
      />
    </>
  );
};

export default ToolbarPlugin;
