import gql from "graphql-tag";

import TranslationFragment from "./TranslationFragment";

export default gql`
  fragment FlowAcknowledgementVersionFragment on acknowledgement_version {
    id
    acknowledgement_id
    label_translation {
      ...TranslationFragment
    }
  }
  ${TranslationFragment}
`;
