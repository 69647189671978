import { parseHotkey } from "is-hotkey";

export default function hotkey(hotkey: string) {
  const isMac =
    typeof window !== "undefined" &&
    /Mac|iPod|iPhone|iPad/.test(window.navigator.platform);

  const parsed = parseHotkey(hotkey, { byKey: true });

  const keys = [];

  if (isMac) {
    if (parsed.ctrlKey) {
      keys.push("⌃");
    }

    if (parsed.altKey) {
      keys.push("⌥");
    }

    if (parsed.shiftKey) {
      keys.push("⇧");
    }

    if (parsed.metaKey) {
      keys.push("⌘");
    }
  } else {
    if (parsed.ctrlKey || parsed.metaKey) {
      keys.push("Ctrl");
    }

    if (parsed.shiftKey) {
      keys.push("Shift");
    }

    if (parsed.altKey) {
      keys.push("Alt");
    }
  }

  keys.push((parsed.key || "").toUpperCase());

  return isMac ? keys.join("") : keys.join(" + ");
}
