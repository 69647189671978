import classNames from "classnames";
import tw, { styled, theme } from "twin.macro";

import Spinner from "../Spinner";

export type ButtonType =
  | "default"
  | "primary"
  | "primary-border"
  | "secondary"
  | "secondary-border"
  | "neutral"
  | "neutral-border"
  | "danger"
  | "danger-border"
  | "transparent"
  | "transparent-no-border"
  | "transparent-secondary-no-border"
  | "link"
  | "alternate-primary"
  | "alternate-secondary"
  | "alternate-danger"
  | "alternate-neutral";

export type ButtonSize = "xs" | "sm" | "md" | "lg";

interface ButtonProps {
  buttonType?: ButtonType;
  isLoading?: boolean;
  size?: ButtonSize;
  flex?: boolean;
  spinnerSize?: "sm" | "md";
}

const StyledButton = styled.button`
  ${tw`
    bg-white
    font-body font-semibold text-base border rounded shadow-button relative outline-none
    active:shadow-inner active:outline-none
    disabled:cursor-default disabled:shadow-none disabled:opacity-50 disabled:pointer-events-none
    focus:outline-none focus:ring focus:ring-blue-200
    transition-all duration-150 ease-in-out
  `}

  &:active:enabled > div {
    top: 1px;
  }

  &.size-xs {
    ${tw`text-sm`}
  }

  &.size-sm {
    ${tw`rounded-md`}
  }

  &.size-lg {
    ${tw`text-lg`}
  }

  &.size-xl {
    ${tw`text-lg`}
  }

  &.default {
    ${tw`
      bg-white
      border-gray-300
      hover:bg-gray-50
      disabled:bg-white disabled:text-gray-700
      focus:border-blue-500
      text-gray-700
    `}
  }

  &.primary,
  &.secondary,
  &.neutral,
  &.danger {
    ${tw`text-white border-transparent disabled:text-white`}
  }

  &.primary {
    ${tw`focus:ring-green-300 bg-green-500 hover:bg-green-600`}
  }

  &.secondary {
    ${tw`bg-blue-500 hover:bg-blue-600`}
  }

  &.alternate-primary {
    ${tw`bg-green-50 hover:bg-green-100 text-green border-transparent shadow-none focus:border-green-500 focus:ring-green-200`}
  }

  &.alternate-secondary {
    ${tw`bg-blue-50 hover:bg-blue-100 text-blue border-transparent shadow-none focus:border-blue-500`}
  }

  &.alternate-danger {
    ${tw`bg-red-50 hover:bg-red-100 text-red border-transparent shadow-none focus:border-red-500 focus:ring-red-200`}
  }

  &.alternate-neutral {
    ${tw`bg-gray-50 hover:bg-gray-100 text-gray-500 border-transparent shadow-none focus:border-blue-500 focus:ring-blue-200`}
  }

  &.neutral {
    ${tw`focus:ring-gray-300 bg-gray-800 hover:bg-gray-900`}
  }

  &.danger {
    ${tw`focus:ring-red-200 bg-red-500 hover:bg-red-600`}
  }

  &.transparent {
    ${tw`focus:border-blue-500 border-gray-400 bg-transparent text-gray-700 hover:bg-white`}
  }

  &.transparent-no-border {
    ${tw`focus:border-blue-500 bg-transparent border-transparent hover:bg-gray-100 shadow-none`}
  }

  &.transparent-secondary-no-border {
    ${tw`text-blue focus:border-blue-500 bg-transparent border-transparent hover:bg-gray-100 shadow-none`}
  }

  &.link {
    ${tw`border-transparent shadow-none text-link hover:underline focus:ring-0`}

    &:active:enabled > div {
      top: 0;
    }
  }

  &.primary-border {
    ${tw`bg-white border border-green text-green disabled:text-green hover:bg-green-50`}
  }

  &.secondary-border {
    ${tw`bg-white border border-blue text-blue disabled:text-blue hover:bg-blue-50`}
  }

  &.neutral-border {
    ${tw`bg-white border border-gray-800 text-gray-800 disabled:text-gray-800 hover:bg-gray-50`}
  }

  &.danger-border {
    ${tw`bg-white border border-red text-red disabled:text-red hover:bg-red-50`}
  }

  & + .btn {
    ${tw`ml-2`}
  }

  &.transparent-no-border + .btn {
    ${tw`ml-1`}
  }

  &.transparent-secondary-no-border + .btn {
    ${tw`ml-1`}
  }

  & + .btn.size-sm {
    ${tw`ml-2`}
  }

  & + .btn.link {
    ${tw`ml-1`}
  }
`;

interface ButtonContentProps {
  isLoading: boolean;
  isExtraSmall: boolean;
  isSmall: boolean;
  isFlex: boolean;
}

const ButtonContent = styled.div<ButtonContentProps>`
  ${tw`py-1 px-3 relative whitespace-nowrap`}
  ${(props) => props.isLoading && tw`invisible`}
  ${(props) => props.isExtraSmall && tw`py-0 px-1`}
  ${(props) => props.isSmall && tw`py-0 px-2`}
  ${(props) => props.isFlex && tw`flex items-center font-normal`}
`;

const SpinnerWrapper = tw.div`absolute top-0 left-0 w-full h-full box-border scale-[0.8]`;

const Button: React.FunctionComponent<
  ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({
  className,
  children,
  buttonType = "default",
  disabled = false,
  isLoading = false,
  size = "md",
  flex = false,
  spinnerSize = "md",
  ...props
}) => {
  if (isLoading) {
    disabled = true;
  }
  const classes = classNames(
    className,
    buttonType.endsWith("-border") && buttonType.replace("-border", ""),
    buttonType,
    `size-${size}`,
    "btn"
  );
  return (
    <StyledButton {...props} disabled={disabled} className={classes}>
      <ButtonContent
        className="button-content"
        isLoading={isLoading}
        isExtraSmall={size === "xs"}
        isSmall={size === "sm"}
        isFlex={flex}
      >
        {children}
      </ButtonContent>
      {isLoading && (
        <SpinnerWrapper>
          <Spinner
            color={
              buttonType === "default"
                ? theme`colors.gray.700`
                : buttonType === "primary-border"
                ? theme`colors.green.DEFAULT`
                : buttonType === "secondary-border"
                ? theme`colors.blue.DEFAULT`
                : buttonType === "danger-border"
                ? theme`colors.red.DEFAULT`
                : buttonType === "neutral-border"
                ? theme`colors.gray.800`
                : buttonType === "transparent"
                ? theme`colors.gray.700`
                : buttonType === "alternate-primary"
                ? theme`colors.green.DEFAULT`
                : buttonType === "alternate-secondary"
                ? theme`colors.blue.DEFAULT`
                : buttonType === "alternate-danger"
                ? theme`colors.red.DEFAULT`
                : buttonType === "alternate-neutral"
                ? theme`colors.gray.700`
                : "white"
            }
            opacity="1"
            padding="0"
            size={spinnerSize}
          />
        </SpinnerWrapper>
      )}
    </StyledButton>
  );
};

export default Button;
