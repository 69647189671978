import {
  FlowTextFragment,
  language_enum,
  translation_value_format_enum,
} from "../../../../__generated__/graphql";
import { options } from "../../../../common/editor/TextEditor";
import getFlowObjectVersion from "../../../../common/flow/getFlowObjectVersion";
import { FlowVersion } from "../../../../common/flow/types";
import createIntl from "../../../../common/i18n";
import { FlowText } from "./types";

interface Formatting {
  underline?: boolean;
}

export const nodes = (text: string, formatting: Formatting = {}) => [
  {
    type: options.span.type,
    children: [{ text, ...formatting }],
  },
];

const languages = [language_enum.en_us].map((language) => ({
  language,
  intl: createIntl(language),
}));

export const defaultTranslationValues = {
  headline: languages.map(({ language, intl }) => ({
    language,
    value: nodes(
      intl.formatMessage({
        defaultMessage: "We’re sorry to see you go!",
        id: "N5rr70",
      })
    ),
    format: translation_value_format_enum.slate,
  })),
  subheadline: languages.map(({ language, intl }) => ({
    language,
    value: nodes(
      intl.formatMessage({
        defaultMessage: "We’ll walk you through canceling your subscription.",
        id: "zMt53C",
      })
    ),
    format: translation_value_format_enum.slate,
  })),
  abort_button: languages.map(({ language, intl }) => ({
    language,
    value: nodes(
      intl.formatMessage({
        defaultMessage: "I don’t want to cancel",
        id: "y0lmpV",
      }),
      { underline: true }
    ),
    format: translation_value_format_enum.slate,
  })),
  next_button: languages.map(({ language, intl }) => ({
    language,
    value: nodes(
      intl.formatMessage({
        defaultMessage: "Next",
        id: "9+Ddtu",
      })
    ),
    format: translation_value_format_enum.slate,
  })),
  cancel_button: languages.map(({ language, intl }) => ({
    language,
    value: nodes(
      intl.formatMessage({
        defaultMessage: "Cancel subscription",
        id: "UWVETE",
      })
    ),
    format: translation_value_format_enum.slate,
  })),
  close_button: languages.map(({ language, intl }) => ({
    language,
    value: nodes(
      intl.formatMessage({
        defaultMessage: "Close",
        id: "rbrahO",
      })
    ),
    format: translation_value_format_enum.slate,
  })),
  decline_offer_button: languages.map(({ language, intl }) => ({
    language,
    value: nodes(
      intl.formatMessage({
        defaultMessage: "Continue to cancel",
        id: "NleDj/",
      })
    ),
    format: translation_value_format_enum.slate,
  })),
  immediate_cancel_button: languages.map(({ language, intl }) => ({
    language,
    value: nodes(
      intl.formatMessage({
        defaultMessage: "No thanks, cancel my subscription immediately",
        id: "gUm2mB",
      })
    ),
    format: translation_value_format_enum.slate,
  })),
  other_option: languages.map(({ language, intl }) => ({
    language,
    value: intl.formatMessage({
      defaultMessage: "Other",
      id: "/VnDMl",
    }),
    format: translation_value_format_enum.text,
  })),
  specify_placeholder: languages.map(({ language, intl }) => ({
    language,
    value: intl.formatMessage({
      defaultMessage: "Please specify",
      id: "BHZsP+",
    }),
    format: translation_value_format_enum.text,
  })),
};

export const defaultTranslationMap: FlowText = {
  headline: {
    __typename: "translation",
    id: -1,
    translation_values: defaultTranslationValues.headline.map((v) => ({
      __typename: "translation_value",
      translation_id: -1,
      ...v,
    })),
  },
  subheadline: {
    __typename: "translation",
    id: -1,
    translation_values: defaultTranslationValues.subheadline.map((v) => ({
      __typename: "translation_value",
      translation_id: -1,
      ...v,
    })),
  },
  abort_button: {
    __typename: "translation",
    id: -1,
    translation_values: defaultTranslationValues.abort_button.map((v) => ({
      __typename: "translation_value",
      translation_id: -1,
      ...v,
    })),
  },
  next_button: {
    __typename: "translation",
    id: -1,
    translation_values: defaultTranslationValues.next_button.map((v) => ({
      __typename: "translation_value",
      translation_id: -1,
      ...v,
    })),
  },
  cancel_button: {
    __typename: "translation",
    id: -1,
    translation_values: defaultTranslationValues.cancel_button.map((v) => ({
      __typename: "translation_value",
      translation_id: -1,
      ...v,
    })),
  },
  close_button: {
    __typename: "translation",
    id: -1,
    translation_values: defaultTranslationValues.close_button.map((v) => ({
      __typename: "translation_value",
      translation_id: -1,
      ...v,
    })),
  },
  decline_offer_button: {
    __typename: "translation",
    id: -1,
    translation_values: defaultTranslationValues.decline_offer_button.map(
      (v) => ({
        __typename: "translation_value",
        translation_id: -1,
        ...v,
      })
    ),
  },
  immediate_cancel_button: {
    __typename: "translation",
    id: -1,
    translation_values: defaultTranslationValues.immediate_cancel_button.map(
      (v) => ({
        __typename: "translation_value",
        translation_id: -1,
        ...v,
      })
    ),
  },
  other_option: {
    __typename: "translation",
    id: -1,
    translation_values: defaultTranslationValues.other_option.map((v) => ({
      __typename: "translation_value",
      translation_id: -1,
      ...v,
    })),
  },
  specify_placeholder: {
    __typename: "translation",
    id: -1,
    translation_values: defaultTranslationValues.specify_placeholder.map(
      (v) => ({
        __typename: "translation_value",
        translation_id: -1,
        ...v,
      })
    ),
  },
};

export default function mapFlowText(
  texts: FlowTextFragment[],
  version: FlowVersion
) {
  return texts.reduce<FlowText>(
    (prev, text) => {
      const textVersion = getFlowObjectVersion(text, version);

      prev[text.key] = textVersion.translation;
      return prev;
    },
    { ...defaultTranslationMap }
  );
}
