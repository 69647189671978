import FieldInput from "../../../../common/form/FieldInput";
import FieldLabel from "../../../../common/form/FieldLabel";
import FieldRow from "../../../../common/form/FieldRow";
import SelectInput from "../../../../common/form/input/SelectInput";
import useTranslatableFormContext from "../../../../common/form/useTranslatableFormContext";
import { OfferBaseFormValues } from "../types";
import ConversionOfferTimeModePartial from "./ConversionOfferTimeModePartial";

const ConversionOfferFormPartial: React.FunctionComponent = () => {
  const { register } = useTranslatableFormContext<OfferBaseFormValues>();

  return (
    <>
      <FieldRow>
        <FieldLabel>
          <label htmlFor="style">Style</label>
        </FieldLabel>
        <FieldInput>
          <SelectInput {...register("style")} id="style" defaultValue="modal">
            <option value="modal">Modal</option>
            <option value="banner">Banner</option>
          </SelectInput>
        </FieldInput>
      </FieldRow>

      <ConversionOfferTimeModePartial />
    </>
  );
};

export default ConversionOfferFormPartial;
