import classNames from "classnames";
import React from "react";

import FlowSpinner from "./FlowSpinner";

export type ButtonType =
  | "default"
  | "primary"
  | "secondary"
  | "danger"
  | "action";

interface FlowButtonProps extends React.ComponentPropsWithRef<"button"> {
  buttonType?: ButtonType;
  isLoading?: boolean;
  isEditMode?: boolean;
}

const FlowButton = React.forwardRef<HTMLButtonElement, FlowButtonProps>(
  (
    {
      className,
      children,
      buttonType = "default",
      disabled = false,
      isLoading = false,
      isEditMode = false,
      ...props
    },
    ref
  ) => {
    if (isLoading) {
      disabled = true;
    }
    return (
      <button
        {...props}
        ref={ref}
        disabled={disabled}
        className={classNames(
          "flow-button",
          className,
          `flow-button--${buttonType}`,
          {
            "flow-button--loading": isLoading,
            "flow-button--edit-mode": isEditMode,
          }
        )}
      >
        <div className={classNames("flow-button__content")}>{children}</div>
        {isLoading && (
          <div className="flow-button__spinner">
            <FlowSpinner />
          </div>
        )}
      </button>
    );
  }
);

export default FlowButton;
