import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { UnreachableCaseError } from "ts-essentials";

import { FlowError } from "../lib/types";

interface FlowErrorAlertProps {
  className?: string;
  error: FlowError;
}

const FlowErrorAlert: React.FunctionComponent<FlowErrorAlertProps> = ({
  className,
  error,
}) => {
  const { title, message } = (() => {
    switch (error.type) {
      case "accept_offer":
        switch (error.code) {
          case "PLATFORM_TEMPORARY_ERROR":
            return {
              title: "Unable to apply offer",
              message:
                "Something went wrong when applying this offer to your subscription. Please try again in a few minutes.",
            };

          case "PLATFORM_PERMANENT_ERROR":
          case "UNKNOWN_ERROR":
            return {
              title: "Unable to apply offer",
              message:
                "This offer is unable to be applied to your subscription at this time. Please contact support for assistance.",
            };

          default:
            throw new UnreachableCaseError(error.code);
        }

      case "cancel":
        switch (error.code) {
          case "PLATFORM_TEMPORARY_ERROR":
            return {
              title: "Unable to cancel subscription",
              message:
                "Something went wrong when canceling your subscription. Please try again in a few minutes.",
            };

          case "PLATFORM_PERMANENT_ERROR":
          case "UNKNOWN_ERROR":
            return {
              title: "Unable to cancel subscription",
              message:
                "Something went wrong when canceling your subscription. Please try again later or contact support for assistance.",
            };

          default:
            throw new UnreachableCaseError(error.code);
        }

      default:
        throw new UnreachableCaseError(error.type);
    }
  })();

  return (
    <div
      className={classNames("flow-error", className, {
        "flow-error--cancel": error.type === "cancel",
      })}
    >
      <div className="flow-error__icon">
        <FontAwesomeIcon icon={faExclamationCircle} />
      </div>
      <div>
        <div className="flow-error__title">{title}</div>
        <div className="flow-error__message">{message}</div>
      </div>
    </div>
  );
};

export default FlowErrorAlert;
