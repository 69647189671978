import { createContext, useContext } from "react";
import { IntlProvider } from "react-intl";

import {
  language_enum,
  TranslationFragment,
} from "../../__generated__/graphql";
import { messages } from "../i18n";
import { enumToIso } from "../languages";
import translationText from "../translationText";
import translationValue from "../translationValue";

interface TranslationsProviderProps {
  language: language_enum;
  defaultLanguage: language_enum;
  enabledLanguages: language_enum[];
}

interface TranslationsContext {
  language: language_enum;
  defaultLanguage: language_enum;
  enabledLanguages: language_enum[];
  translationText: (
    translation: TranslationFragment | null | undefined
  ) => ReturnType<typeof translationText>;
  translationValue: (
    translation: TranslationFragment | null | undefined
  ) => ReturnType<typeof translationValue>;
}

const Context = createContext<TranslationsContext | undefined>(undefined);

export const useTranslations = () => {
  const context = useContext(Context);
  if (!context) {
    throw new Error(
      "useTranslations() called outside of TranslationsProvider context"
    );
  }

  return context;
};

const TranslationsProvider: React.FunctionComponent<
  TranslationsProviderProps
> = ({ language, defaultLanguage, enabledLanguages, children }) => {
  const translationTextImpl = (
    translation: TranslationFragment | null | undefined
  ) => translationText(translation, language, defaultLanguage);

  const translationValueImpl = (
    translation: TranslationFragment | null | undefined
  ) => translationValue(translation, language, defaultLanguage);

  const iso = enumToIso(language);

  return (
    <Context.Provider
      value={{
        language,
        defaultLanguage,
        enabledLanguages,
        translationText: translationTextImpl,
        translationValue: translationValueImpl,
      }}
    >
      <IntlProvider
        locale={iso}
        defaultLocale="en-US"
        messages={messages(language)}
        textComponent="span"
        onError={() => {}}
      >
        {children}
      </IntlProvider>
    </Context.Provider>
  );
};

export default TranslationsProvider;
