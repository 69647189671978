const FlowSpinner: React.FunctionComponent = () => (
  <div className="flow-spinner">
    <div>
      <div className="flow-spinner__dot flow-spinner__dot--1" />
      <div className="flow-spinner__dot flow-spinner__dot--2" />
      <div className="flow-spinner__dot flow-spinner__dot--3" />
    </div>
  </div>
);

export default FlowSpinner;
