import { UnreachableCaseError } from "ts-essentials";

import { offer_autopilot_strategy_enum } from "../../../../__generated__/graphql";

export default function autopilotFriendlyStrategy(
  strategy: offer_autopilot_strategy_enum
) {
  switch (strategy) {
    case offer_autopilot_strategy_enum.copy:
      return "Copywriting";

    case offer_autopilot_strategy_enum.discount:
      return "Discount";

    case offer_autopilot_strategy_enum.discount_copy:
      return "Discount and copywriting";

    default:
      throw new UnreachableCaseError(strategy);
  }
}
