export const PROPERTY_TAG = "property_tag";
export const PROPERTY_TAG_FIRST_NAME = "first_name";
export const PROPERTY_TAG_LAST_NAME = "last_name";
export const PROPERTY_TAG_EMAIL = "email";
export const PROPERTY_TAG_CUSTOM_PROPERTY = "custom_property";
export const PROPERTY_TAG_OFFER_DESCRIPTION = "offer_description";
export const PROPERTY_TAG_SEGMENT_PROPERTY = "segment_property";

export type PropertyTag =
  | typeof PROPERTY_TAG_FIRST_NAME
  | typeof PROPERTY_TAG_LAST_NAME
  | typeof PROPERTY_TAG_EMAIL
  | typeof PROPERTY_TAG_CUSTOM_PROPERTY
  | typeof PROPERTY_TAG_OFFER_DESCRIPTION
  | typeof PROPERTY_TAG_SEGMENT_PROPERTY;
