import { QueryResult, useQuery } from "@apollo/client";
import gql from "graphql-tag";

import { FlowQuery, FlowQueryVariables } from "../../__generated__/graphql";
import FlowHasUnsavedChangesFlowFragment from "../../features/flow/edit/fragments/FlowHasUnsavedChangesFlowFragment";
import FlowFragment from "../fragments/FlowFragment";

export const TheFlowQuery = gql`
  query FlowQuery(
    $token: String!
    $includeDrafts: Boolean! = false
    $includeUnsavedChangesData: Boolean! = false
  ) {
    flow(
      where: {
        _or: [
          { token: { _eq: $token } }
          { subscriber_flows: { token: { _eq: $token } } }
        ]
      }
    ) {
      ...FlowFragment
      ...FlowHasUnsavedChangesFlowFragment
        @include(if: $includeUnsavedChangesData)
      subscriber_flows(where: { token: { _eq: $token } }) {
        id
        token
        version
        status
        subscriber {
          id
          name
          email
          subscriber_properties {
            subscriber_id
            property_id
            value
            property {
              id
              name
              type
              format
            }
          }
        }
        subscription {
          id
          subscription_properties {
            subscription_id
            property_id
            value
            property {
              id
              name
              type
              format
            }
          }
          platform_subscription {
            can_cancel
          }
        }
        segment_values
      }
    }
  }
  ${FlowFragment}
  ${FlowHasUnsavedChangesFlowFragment}
`;

export default function useFlowByToken(
  token: string,
  includeDrafts: boolean = false,
  includeUnsavedChangesData: boolean = false
): QueryResult<FlowQuery, FlowQueryVariables> {
  return useQuery<FlowQuery, FlowQueryVariables>(TheFlowQuery, {
    variables: { token, includeDrafts, includeUnsavedChangesData },
  });
}
