import gql from "graphql-tag";

export default gql`
  fragment CustomerPortalSubscriptionDetailsInvoiceFragment on PortalSubscriptionDetailsInvoice {
    platformId
    periodEnd
    status
    total
    amountPaid
    number
  }
`;
