import { useState } from "react";
import { useToasts } from "react-toast-notifications";

import {
  AppOfferFragment,
  coupon_duration_enum,
  coupon_duration_interval_enum,
  language_enum,
  offer_autopilot_strategy_enum,
  offer_autopilot_success_metric_enum,
  offer_change_plan_change_at_enum,
  offer_coupon_apply_to_enum,
  offer_coupon_type_enum,
  offer_goal_enum,
  offer_insert_input,
  offer_modify_subscription_modify_at_enum,
  offer_modify_subscription_option_type_enum,
  offer_pause_subscription_interval_enum,
  offer_pause_subscription_option_insert_input,
  offer_pause_subscription_option_type_enum,
  offer_pause_subscription_pause_at_enum,
  offer_style_enum,
  offer_timer_mode_enum,
  offer_type_enum,
  OfferDetailsFragment,
  translation_value_format_enum,
} from "../../../../__generated__/graphql";
import FormMode from "../../../../common/form/FormMode";
import { TranslatedForms } from "../../../../common/form/useTranslatableForm";
import useUpdateTags from "../../../../common/mutations/useUpdateTags";
import { TRACK_EVENT_OFFER_CREATED } from "../../../../common/track-events/events";
import useTrackEvent from "../../../../common/useTrackEvent";
import useViewer from "../../../../common/useViewer";
import useCreateChangePlanOffer from "../../lib/useCreateChangePlanOffer";
import useCreateCouponOffer from "../../lib/useCreateCouponOffer";
import useCreateCustomOffer from "../../lib/useCreateCustomOffer";
import useCreateDowngradeOffer from "../../lib/useCreateDowngradeOffer";
import useCreateModifySubscriptionOffer from "../../lib/useCreateModifySubscriptionOffer";
import useCreateOfferTimer from "../../lib/useCreateOfferTimer";
import useCreatePauseSubscriptionOffer from "../../lib/useCreatePauseSubscriptionOffer";
import useCreateProductSwapOffer from "../../lib/useCreateProductSwapOffer";
import useCreateRescheduleOrderOffer from "../../lib/useCreateRescheduleOrderOffer";
import useCreateTrialExtensionOffer from "../../lib/useCreateTrialExtensionOffer";
import useCreateUpgradeOffer from "../../lib/useCreateUpgradeOffer";
import useDeleteOfferTimer from "../../lib/useDeleteOfferTimer";
import useUpdateOffer from "../../lib/useUpdateOffer";
import useUpdateOfferChangePlan from "../../lib/useUpdateOfferChangePlan";
import useUpdateOfferCoupon from "../../lib/useUpdateOfferCoupon";
import useUpdateOfferDowngrade from "../../lib/useUpdateOfferDowngrade";
import useUpdateOfferPauseSubscription from "../../lib/useUpdateOfferPauseSubscription";
import useUpdateOfferRescheduleOrder from "../../lib/useUpdateOfferRescheduleOrder";
import useUpdateOfferTimer from "../../lib/useUpdateOfferTimer";
import useUpdateOfferUpgrade from "../../lib/useUpdateOfferUpgrade";
import {
  OfferFormValues,
  PauseSubscriptionOfferFormValues,
  RescheduleOrderOfferFormValues,
} from "../types";

interface UseSubmitOfferProps {
  mode: FormMode;
  offer?: OfferDetailsFragment;
  goal: offer_goal_enum;
  defaultLanguage: language_enum;
  onClose: (offer: AppOfferFragment | null) => void;
  strategy?: offer_autopilot_strategy_enum;
  successMetric?: offer_autopilot_success_metric_enum;
}

export default function useSubmitOffer({
  mode,
  offer,
  goal,
  defaultLanguage,
  onClose,
  strategy,
  successMetric,
}: UseSubmitOfferProps) {
  const { viewer } = useViewer();
  const { addToast } = useToasts();
  const trackEvent = useTrackEvent();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const createCouponOffer = useCreateCouponOffer();
  const createChangePlanOffer = useCreateChangePlanOffer();
  const createModifySubscriptionOffer = useCreateModifySubscriptionOffer();
  const createCustomOoffer = useCreateCustomOffer();
  const createPauseSubscriptionOffer = useCreatePauseSubscriptionOffer();
  const createTrialExtensionOffer = useCreateTrialExtensionOffer();
  const createUpgradeOffer = useCreateUpgradeOffer();
  const createDowngradeOffer = useCreateDowngradeOffer();
  const createProductSwapOffer = useCreateProductSwapOffer();
  const createRescheduleOrderOffer = useCreateRescheduleOrderOffer();
  const createOfferTimer = useCreateOfferTimer();

  const updateOffer = useUpdateOffer();
  const updateOfferCoupon = useUpdateOfferCoupon();
  const updateOfferChangePlan = useUpdateOfferChangePlan();
  const updateOfferPauseSubscription = useUpdateOfferPauseSubscription();
  const updateOfferUpgrade = useUpdateOfferUpgrade();
  const updateOfferDowngrade = useUpdateOfferDowngrade();
  const updateOfferRescheduleOrder = useUpdateOfferRescheduleOrder();
  const updateOfferTimer = useUpdateOfferTimer();

  const deleteOfferTimer = useDeleteOfferTimer();

  const updateTags = useUpdateTags();

  const handleSubmitOffer = async (forms: TranslatedForms<OfferFormValues>) => {
    if (!viewer) {
      throw new Error("No viewer");
    }

    setIsSubmitting(true);

    const values = forms[defaultLanguage];
    if (!values) {
      throw new Error();
    }

    const metadata: Array<{ key: string; value: string }> = JSON.parse(
      values.metadata
    );

    const tagIds = await updateTags(values.tags);

    const autopilot = !!strategy && !!successMetric;

    if (mode === "create") {
      const headlineTranslation = {
        data: {
          translation_values: {
            data: Object.entries(forms)
              .map(([language, values]) => ({
                language: language as language_enum,
                value: values?.headline,
                format: translation_value_format_enum.text,
              }))
              .filter((v) => !!v.value),
          },
        },
      };

      const contentTranslation = {
        data: {
          translation_values: {
            data: Object.entries(forms)
              .filter(([, values]) => !!values.content)
              .map(([language, values]) => ({
                language: language as language_enum,
                value: JSON.parse(values.content),
                format: values.contentFormat,
              }))
              .filter((v) => !!v.value),
          },
        },
      };

      const buttonTextTranslation = {
        data: {
          translation_values: {
            data: Object.entries(forms)
              .map(([language, values]) => ({
                language: language as language_enum,
                value: values?.buttonText,
                format: translation_value_format_enum.text,
              }))
              .filter((v) => !!v.value),
          },
        },
      };

      const offerData: offer_insert_input = {
        type: values.type as offer_type_enum,
        name: values.name,
        tag_ids: tagIds,
        override_active_offer: values.allowOverride,
        show_banner: values.showBanner,
        image_url: values.image,
        confirmation_enabled: values.confirmationEnabled,
        metadata: metadata
          .filter((r) => !!r.key && !!r.value)
          .reduce((acc, { key, value }) => ({ ...acc, [key]: value }), {}),
        headline_translation: headlineTranslation,
        content_translation: contentTranslation,
        button_text_translation: buttonTextTranslation,
        confirmation_content_translation: {
          data: {
            translation_values: {
              data: Object.entries(forms).map(([language, values]) => ({
                language: language as language_enum,
                value: values?.confirmationContent
                  ? JSON.parse(values.confirmationContent)
                  : "",
                format: translation_value_format_enum.lexical,
              })),
            },
          },
        },
        confirmation_confirm_button_text_translation: {
          data: {
            translation_values: {
              data: Object.entries(forms)
                .map(([language, values]) => ({
                  language: language as language_enum,
                  value: values?.confirmationConfirmButtonText,
                  format: translation_value_format_enum.text,
                }))
                .filter((v) => !!v.value),
            },
          },
        },
        confirmation_cancel_button_text_translation: {
          data: {
            translation_values: {
              data: Object.entries(forms)
                .map(([language, values]) => ({
                  language: language as language_enum,
                  value: values?.confirmationCancelButtonText,
                  format: translation_value_format_enum.text,
                }))
                .filter((v) => !!v.value),
            },
          },
        },
        goal: goal,
        style: values.style as offer_style_enum,
        ...(!!autopilot && {
          offer_autopilot: {
            data: { strategy, success_metric: successMetric },
          },
        }),
      };

      const newOffer = await (async () => {
        switch (values.type) {
          case "coupon": {
            if (!viewer.account?.platform_connection?.platform) {
              throw new Error("No platform connection");
            }

            return createCouponOffer(
              viewer.account.platform_connection.platform,
              offerData,
              {
                type: values.couponType as offer_coupon_type_enum,
                amount_off: values.amountOff,
                duration: values.duration as coupon_duration_enum,
                duration_interval:
                  values.duration === "repeating"
                    ? values.couponId
                      ? (values.durationInterval as coupon_duration_interval_enum)
                      : coupon_duration_interval_enum.month
                    : null,
                duration_count: values.couponId
                  ? values.durationCount
                  : values.months,
                months: values.months,
                apply_to: values.applyTo as offer_coupon_apply_to_enum,
                override_active_coupon: values.allowCouponOverride,
                platform_connection_id: values.couponId
                  ? viewer.account.platform_connection.id
                  : null,
                platform_id: values.couponId,
              }
            );
          }

          case "change_plan": {
            const result = await createChangePlanOffer({
              variables: {
                object: {
                  platform_plan_id: values.planId,
                  prorate: values.prorate,
                  offer_coupon_id: values.couponId ? +values.couponId : null,
                  change_at:
                    values.changeAt as offer_change_plan_change_at_enum,
                  reset_billing_date: values.resetBillingDate,
                  offer: {
                    data: offerData,
                  },
                  platform_connection_id:
                    viewer.account!.platform_connection!.id,
                },
              },
            });

            return result.data?.insert_offer_change_plan_one?.offer;
          }

          case "modify_subscription": {
            const addPlans: string[] = JSON.parse(values.addPlans);
            const removePlans: string[] = JSON.parse(values.removePlans);
            const addAddons: string[] = JSON.parse(values.addAddons);
            const removeAddons: string[] = JSON.parse(values.removeAddons);
            const addCustomPlans: string[] = JSON.parse(values.addCustomPlans);
            const removeCustomPlans: string[] = JSON.parse(
              values.removeCustomPlans
            );

            const result = await createModifySubscriptionOffer({
              variables: {
                object: {
                  prorate: values.prorate,
                  modify_at:
                    values.modifyAt as offer_modify_subscription_modify_at_enum,
                  offer_modify_subscription_options: {
                    data: [
                      ...addPlans.map((p) => ({
                        type: offer_modify_subscription_option_type_enum.add_plan,
                        platform_connection_id:
                          viewer.account!.platform_connection!.id,
                        platform_plan_id: p,
                      })),
                      ...removePlans.map((p) => ({
                        type: offer_modify_subscription_option_type_enum.remove_plan,
                        platform_connection_id:
                          viewer.account!.platform_connection!.id,
                        platform_plan_id: p,
                      })),
                      ...addAddons.map((a) => ({
                        type: offer_modify_subscription_option_type_enum.add_addon,
                        platform_connection_id:
                          viewer.account!.platform_connection!.id,
                        platform_addon_id: a,
                      })),
                      ...removeAddons.map((a) => ({
                        type: offer_modify_subscription_option_type_enum.remove_addon,
                        platform_connection_id:
                          viewer.account!.platform_connection!.id,
                        platform_addon_id: a,
                      })),
                      ...addCustomPlans.map((p) => ({
                        type: offer_modify_subscription_option_type_enum.add_plan,
                        platform_connection_id:
                          viewer.account!.platform_connection!.id,
                        custom_plan_id: p,
                      })),
                      ...removeCustomPlans.map((p) => ({
                        type: offer_modify_subscription_option_type_enum.remove_plan,
                        platform_connection_id:
                          viewer.account!.platform_connection!.id,
                        custom_plan_id: p,
                      })),
                    ],
                  },
                  offer: {
                    data: offerData,
                  },
                },
              },
            });

            return result.data?.insert_offer_modify_subscription_one?.offer;
          }

          case "custom": {
            const result = await createCustomOoffer({
              variables: {
                object: {
                  link_href: "",
                  link_text: "",
                  offer: {
                    data: offerData,
                  },
                },
              },
            });

            return result.data?.insert_offer_custom_one?.offer;
          }

          case "pause_subscription": {
            const options: offer_pause_subscription_option_insert_input[] = [];

            const createOptionInput = (index: 1 | 2 | 3) => {
              const metadata: Array<{ key: string; value: string }> =
                JSON.parse(values[`option${index}Metadata`]);

              const option: offer_pause_subscription_option_insert_input = {
                position: index - 1,
                type:
                  values[`option${index}Interval`] === "date"
                    ? offer_pause_subscription_option_type_enum.date
                    : offer_pause_subscription_option_type_enum.interval,
                interval:
                  values[`option${index}Interval`] === "date"
                    ? null
                    : (values[
                        `option${index}Interval`
                      ] as offer_pause_subscription_interval_enum),

                interval_count: values[`option${index}IntervalCount`],
                metadata: metadata
                  .filter((r) => !!r.key && !!r.value)
                  .reduce(
                    (acc, { key, value }) => ({ ...acc, [key]: value }),
                    {}
                  ),
              };

              const translationValues = Object.entries(forms)
                .map(([language, values]) => ({
                  language: language as language_enum,
                  value: (values as PauseSubscriptionOfferFormValues)[
                    `option${index}Text`
                  ],
                  format: translation_value_format_enum.lexical,
                }))
                .filter((v) => !!v.value)
                .map((v) => ({ ...v, value: JSON.parse(v.value || "") }));

              if (translationValues.length) {
                option.text_translation = {
                  data: { translation_values: { data: translationValues } },
                };
              }

              return option;
            };

            if (values.option1Present) {
              options.push(createOptionInput(1));
            }
            if (values.option2Present) {
              options.push(createOptionInput(2));
            }
            if (values.option3Present) {
              options.push(createOptionInput(3));
            }

            const result = await createPauseSubscriptionOffer({
              variables: {
                object: {
                  pause_at:
                    values.pauseAt as offer_pause_subscription_pause_at_enum,
                  offer: {
                    data: offerData,
                  },
                  offer_pause_subscription_options: {
                    data: options,
                  },
                },
              },
            });

            return result.data?.insert_offer_pause_subscription_one?.offer;
          }

          case "trial_extension": {
            const result = await createTrialExtensionOffer({
              variables: {
                object: {
                  days: values.days,
                  offer: {
                    data: offerData,
                  },
                },
              },
            });

            return result.data?.insert_offer_trial_extension_one?.offer;
          }

          case "upgrade": {
            const result = await createUpgradeOffer({
              variables: {
                object: {
                  platform_plan_id: values.planId,
                  platform_connection_id:
                    viewer.account!.platform_connection!.id,
                  change_at:
                    values.changeAt as offer_change_plan_change_at_enum,
                  offer: {
                    data: offerData,
                  },
                },
              },
            });

            return result.data?.insert_offer_upgrade_one?.offer;
          }

          case "downgrade": {
            const result = await createDowngradeOffer({
              variables: {
                object: {
                  platform_plan_id: values.planId,
                  platform_connection_id:
                    viewer.account!.platform_connection!.id,
                  change_at:
                    values.changeAt as offer_change_plan_change_at_enum,
                  offer: {
                    data: offerData,
                  },
                },
              },
            });

            return result.data?.insert_offer_downgrade_one?.offer;
          }

          case "product_swap": {
            const result = await createProductSwapOffer({
              variables: {
                object: {
                  specific_products: values.specificProducts,
                  offer_product_swap_platform_ecommerce_products: {
                    data: values.products.map((product) => ({
                      platform_ecommerce_product_id: product.productId,
                      platform_connection_id:
                        viewer.account?.platform_connection?.id,
                      offer_product_swap_platform_ecommerce_product_platform_variants:
                        {
                          data: product.variants
                            .filter((variant) => variant.value)
                            .map((variant) => ({
                              platform_variant_id: variant.variantId,
                              platform_connection_id:
                                viewer.account?.platform_connection?.id,
                            })),
                        },
                    })),
                  },
                  offer: {
                    data: offerData,
                  },
                },
              },
            });

            return result.data?.insert_offer_product_swap_one?.offer;
          }

          case "reschedule_order": {
            const result = await createRescheduleOrderOffer({
              variables: {
                object: {
                  skip: values.skipEnabled,
                  reschedule: values.rescheduleEnabled,
                  skip_button_text_translation: {
                    data: {
                      translation_values: {
                        data: Object.entries(forms)
                          .map(([language, values]) => ({
                            language: language as language_enum,
                            value: (values as RescheduleOrderOfferFormValues)
                              .skipButtonText,
                            format: translation_value_format_enum.text,
                          }))
                          .filter((v) => !!v.value),
                      },
                    },
                  },
                  reschedule_button_text_translation: {
                    data: {
                      translation_values: {
                        data: Object.entries(forms)
                          .map(([language, values]) => ({
                            language: language as language_enum,
                            value: (values as RescheduleOrderOfferFormValues)
                              .rescheduleButtonText,
                            format: translation_value_format_enum.text,
                          }))
                          .filter((v) => !!v.value),
                      },
                    },
                  },
                  offer: {
                    data: offerData,
                  },
                },
              },
            });

            return result.data?.insert_offer_reschedule_order_one?.offer;
          }
        }
      })();

      if (!newOffer) {
        setIsSubmitting(false);
        addToast(<div>Oops! The offer couldn't be created.</div>, {
          appearance: "error",
        });
        return;
      }

      if (
        goal === offer_goal_enum.conversion &&
        (values.type === "coupon" ||
          values.type === "change_plan" ||
          values.type === "trial_extension" ||
          values.type === "custom") &&
        values.timerMode !== ""
      ) {
        createOfferTimer({
          variables: {
            object: {
              offer_timer_mode: values.timerMode as offer_timer_mode_enum,
              start_time: values.startTime
                ?.toZonedDateTime("UTC")
                .toInstant()
                .toString(),
              end_time: values.endTime
                ?.toZonedDateTime("UTC")
                .toInstant()
                .toString(),
              duration: values.offerLength || null,
              show_timer: values.countdownTimer,
              offer_id: newOffer.id,
            },
          },
        });
      }

      await trackEvent(TRACK_EVENT_OFFER_CREATED, {
        offer_id: newOffer.token,
      });

      onClose(newOffer);
    } else {
      if (!offer) {
        throw new Error("offer is not set");
      }

      if (
        goal === offer_goal_enum.conversion &&
        (values.type === "coupon" ||
          values.type === "change_plan" ||
          values.type === "trial_extension" ||
          values.type === "custom")
      ) {
        if (!offer.offer_timer && !!values.timerMode) {
          await createOfferTimer({
            variables: {
              object: {
                offer_timer_mode: values.timerMode as offer_timer_mode_enum,
                start_time: values.startTime
                  ?.toZonedDateTime("UTC")
                  .toInstant()
                  .toString(),
                end_time: values.endTime
                  ?.toZonedDateTime("UTC")
                  .toInstant()
                  .toString(),
                duration: values.offerLength || null,
                show_timer: values.countdownTimer,
                offer_id: offer.id,
              },
            },
          });
        } else if (!!offer.offer_timer && !!values.timerMode) {
          const timerMode = values.timerMode as offer_timer_mode_enum;

          await updateOfferTimer({
            variables: {
              object: {
                offer_timer_mode: values.timerMode as offer_timer_mode_enum,
                start_time:
                  timerMode === "fixed"
                    ? values.startTime
                        ?.toZonedDateTime("UTC")
                        .toInstant()
                        .toString()
                    : null,
                end_time:
                  timerMode === "fixed"
                    ? values.endTime
                        ?.toZonedDateTime("UTC")
                        .toInstant()
                        .toString()
                    : null,
                duration:
                  timerMode === "from_first_seen"
                    ? values.offerLength || null
                    : null,
                show_timer: values.countdownTimer,
                offer_id: offer.id,
              },
            },
          });
        } else if (!!offer.offer_timer && !values.timerMode) {
          await deleteOfferTimer({
            variables: {
              offerId: offer.id,
            },
          });
        }
      }

      const result = await updateOffer(
        offer,
        {
          name: values.name,
          tag_ids: tagIds,
          override_active_offer: values.allowOverride,
          show_banner: values.showBanner,
          image_url: values.image,
          style: values.style as offer_style_enum,
          confirmation_enabled: values.confirmationEnabled,
          metadata: metadata
            .filter((r) => !!r.key && !!r.value)
            .reduce((acc, { key, value }) => ({ ...acc, [key]: value }), {}),
        },
        forms
      );

      if (values.type === "coupon" && offer.offer_coupon) {
        await updateOfferCoupon({
          variables: {
            id: offer.offer_coupon.id,
            set: {
              override_active_coupon: values.allowCouponOverride,
              apply_to: values.applyTo as offer_coupon_apply_to_enum,
            },
          },
        });
      }

      if (
        values.type === "pause_subscription" &&
        offer.offer_pause_subscription
      ) {
        const newTextTranslations: Record<
          number,
          Record<language_enum, any>
        > = {};

        for (const [language, langValues] of Object.entries(
          forms as TranslatedForms<PauseSubscriptionOfferFormValues>
        )) {
          if (langValues.option1Text) {
            newTextTranslations[0] = {
              ...newTextTranslations[0],
              [language]: JSON.parse(langValues.option1Text),
            };
          }
          if (langValues.option2Text) {
            newTextTranslations[1] = {
              ...newTextTranslations[1],
              [language]: JSON.parse(langValues.option2Text),
            };
          }
          if (langValues.option3Text) {
            newTextTranslations[2] = {
              ...newTextTranslations[2],
              [language]: JSON.parse(langValues.option3Text),
            };
          }
        }

        const option1Metadata: Array<{ key: string; value: string }> =
          JSON.parse(values.option1Metadata);
        const option2Metadata: Array<{ key: string; value: string }> =
          JSON.parse(values.option2Metadata);
        const option3Metadata: Array<{ key: string; value: string }> =
          JSON.parse(values.option3Metadata);

        await updateOfferPauseSubscription(
          {
            offerId: offer.id,
            pauseAt: values.pauseAt as offer_pause_subscription_pause_at_enum,
          },
          newTextTranslations,
          [
            option1Metadata
              .filter((r) => !!r.key && !!r.value)
              .reduce((acc, { key, value }) => ({ ...acc, [key]: value }), {}),
            option2Metadata
              .filter((r) => !!r.key && !!r.value)
              .reduce((acc, { key, value }) => ({ ...acc, [key]: value }), {}),
            option3Metadata
              .filter((r) => !!r.key && !!r.value)
              .reduce((acc, { key, value }) => ({ ...acc, [key]: value }), {}),
          ]
        );
      }

      if (values.type === "change_plan" && offer.offer_change_plan) {
        await updateOfferChangePlan({
          variables: {
            offerId: offer.id,
            prorate: values.prorate,
            changeAt: values.changeAt as offer_change_plan_change_at_enum,
            resetBillingDate: values.resetBillingDate,
          },
        });
      }

      if (values.type === "upgrade" && offer.offer_upgrade) {
        await updateOfferUpgrade({
          variables: {
            offerId: offer.id,
            changeAt: values.changeAt as offer_change_plan_change_at_enum,
          },
        });
      }

      if (values.type === "downgrade" && offer.offer_downgrade) {
        await updateOfferDowngrade({
          variables: {
            offerId: offer.id,
            changeAt: values.changeAt as offer_change_plan_change_at_enum,
          },
        });
      }

      if (values.type === "reschedule_order" && offer.offer_reschedule_order) {
        await updateOfferRescheduleOrder({
          variables: {
            offerId: offer.id,
            skip: values.skipEnabled,
            reschedule: values.rescheduleEnabled,
          },
        });
      }

      if (!result.data?.update_offer_by_pk) {
      } else {
        onClose(result.data.update_offer_by_pk);
      }
    }
  };

  return { handleSubmitOffer, isSubmitting };
}
