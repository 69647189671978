import { useApolloClient } from "@apollo/client";
import {
  faEyeSlash,
  faGripVertical,
  faTimes,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import classNames from "classnames";
import { shuffle } from "lodash";
import { nanoid } from "nanoid";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  DragDropContext,
  Draggable,
  DraggableProvidedDragHandleProps,
  Droppable,
  DropResult,
  ResponderProvided,
} from "react-beautiful-dnd";
import { useForm } from "react-hook-form";
import TextareaAutosize from "react-textarea-autosize";
import { isPresent } from "ts-is-present";
import tw, { styled } from "twin.macro";

import {
  FlowFormFragment,
  FlowQuestionFragment,
  FlowQuestionOptionFragment,
  language_enum,
  question_setting_key_enum,
  question_type_enum,
} from "../../../../__generated__/graphql";
import ConfirmationModal from "../../../../common/ConfirmationModal";
import { TranslatedForms } from "../../../../common/form/useTranslatableForm";
import TheFlowQuestionFragment from "../../../../common/fragments/FlowQuestionFragment";
import { useTranslations } from "../../../../common/translations/TranslationsProvider";
import { FormValues as QuestionFormValues } from "../../../flow/edit/AddQuestionModal";
import { FormValues as QuestionOptionFormValues } from "../../../flow/edit/AddRadioOptionModal";
import AddRadioOptionModal from "../../../flow/edit/AddRadioOptionModal";
import EditableQuestionOptionTitle from "../../../flow/edit/EditableQuestionOptionTitle";
import EditableQuestionTitle from "../../../flow/edit/EditableQuestionTitle";
import { FormManager } from "../../../flow/edit/formManager";
import FormQuestionActions from "../../../flow/edit/FormQuestionActions";
import {
  QuestionManager,
  reorderQuestionOptions,
} from "../../../flow/edit/questionManager";
import FlowVersionProvider, { useFlowVersion } from "../FlowVersionProvider";
import getQuestionInputId from "../lib/getQuestionInputId";
import { FlowText, FormStepQuestion, QuestionAnswer } from "../lib/types";
import { transitionDuration } from "../lib/variables";
import LikelihoodToReturn from "../ui/LikelihoodToReturn";

interface FormQuestionProps {
  className?: string;
  form: FlowFormFragment;
  formStepQuestion: FormStepQuestion;
  isEditMode: boolean;
  isSubmitting: boolean;
  flowText: FlowText;
  onChange: (value: QuestionAnswer) => void;
  onDelete: (questionId: number) => void;
  questionManager?: QuestionManager;
  formManager?: FormManager;
  enabledLanguages: language_enum[];
  dragHandleProps?: DraggableProvidedDragHandleProps;
  isFreeMode?: boolean;
}

interface FormValues {
  [key: string]: any;
}

const Wrapper = styled.div`
  ${tw`flex flex-col flex-grow relative rounded py-2`}

  &.questions-are-draggable {
    ${tw`hover:border`}
  }
`;

const FormQuestionTitle = styled.h3`
  ${tw`text-2xl leading-8 mb-4`}

  &.disabled {
    ${tw`text-gray-400`}
  }
`;

const QuestionDragHandle = tw.div`text-gray-400 ml-2 mr-3`;
const DisabledQuestionIcon = tw.div`mr-2`;

const FormQuestionHint = styled.h3`
  ${tw`text-lg text-gray-400 mb-6`}

  &.questions-are-draggable {
    ${tw`ml-9`}
  }
`;

const FormQuestionTextField = styled.div`
  ${tw`mr-16`}

  &.questions-are-draggable {
    ${tw`ml-9 mr-3`}
  }
`;

const FormQuestionLikelihoodToReturnField = styled.div`
  ${tw`mr-16 max-w-[464px]`}

  &.questions-are-draggable {
    ${tw`ml-9 mr-3`}
  }
`;

const OptionDragHandle = styled.div`
  ${tw`text-gray-400 ml-2 mr-3 relative`}
  top: 2px;
`;

const Options = styled.div`
  &.options-are-draggable {
    ${tw`ml-1`}
  }
`;

const Option = styled.div`
  ${tw`relative flex mb-1 border border-transparent`}

  &.option-is-dragging {
    ${tw`bg-white rounded border-gray-200`}
    z-index: 10;

    button {
      display: none;
    }
  }

  button {
    ${tw`bg-red-500 border-red-500 cursor-pointer opacity-0`}
    display: flex;
    justify-content: center;
    align-items: center;
    width: 19px;
    height: 19px;
    border-radius: 50%;
    font-size: 13px;
    border-radius: 50%;
    transition: all 0.3s ease;
    transition-property: background-color, border-color;
    outline: none;
    margin: 4px 0 0 10px;
    opacity: 0;

    svg {
      position: relative;
      left: 0.035px;
    }
  }

  &:hover {
    button {
      ${tw`opacity-75 hover:opacity-100`}

      &:disabled,
      &.disabled {
        ${tw`cursor-default`}
      }
    }
  }
`;

const optionId = (option: { id: number | string }) => `option_${option.id}`;

const reorder = (options: FlowQuestionOptionFragment[], order: number[]) => {
  const result = [];
  for (let i = 0; i < order.length; i++) {
    result[i] = options[order[i]];
  }
  return result;
};

const FormQuestion: React.FunctionComponent<FormQuestionProps> = ({
  className,
  form,
  formStepQuestion,
  isEditMode,
  isSubmitting,
  flowText,
  onChange,
  onDelete,
  questionManager,
  formManager,
  enabledLanguages,
  dragHandleProps,
  isFreeMode = false,
}) => {
  const apollo = useApolloClient();
  const { getObjectVersion } = useFlowVersion();

  const question = formStepQuestion.question;
  const version = getObjectVersion(question);
  const isDisabled = !formStepQuestion.isEnabled;

  let options = version.question_version_question_options
    .map((o) => o.question_option)
    .filter(isPresent);

  const { translationText, language, defaultLanguage } = useTranslations();

  const [randomOrder] = useState(shuffle(Array.from(options.keys())));
  const [addOptionModalIsOpen, setAddOptionModalIsOpen] = useState(false);
  const [addOptionModalKey, setAddOptionModalKey] = useState(nanoid());
  const formRef = useRef<HTMLFormElement | null>(null);
  const { register, unregister, watch, setValue } = useForm<FormValues>();
  const [confirmDeleteOptionId, setConfirmOptionDeleteId] = useState<
    number | null
  >(null);

  const isRandomized = (question.question_settings || []).some((setting) => {
    if (setting.key === question_setting_key_enum.randomize) {
      const settingVersion = getObjectVersion(setting);
      return settingVersion.value;
    }

    return false;
  });

  if (!isEditMode && isRandomized) {
    options = reorder(options, randomOrder);
  }

  const selectMultiple = (question.question_settings || []).some((setting) => {
    if (setting.key === question_setting_key_enum.select_multiple) {
      const settingVersion = getObjectVersion(setting);
      return settingVersion.value;
    }

    return false;
  });

  const otherSpecify = (question.question_settings || []).some((setting) => {
    if (setting.key === question_setting_key_enum.other_specify) {
      const settingVersion = getObjectVersion(setting);
      return settingVersion.value;
    }

    return false;
  });

  const nextPosition = options.length;

  const confirmDeleteOption = confirmDeleteOptionId
    ? options.find((option) => option.id === confirmDeleteOptionId)
    : null;

  const inputId = getQuestionInputId(question);

  const values = watch();

  const showPleaseSpecify =
    otherSpecify && selectMultiple
      ? (values[inputId] || []).includes("other")
      : values[inputId] === "other";

  const prevValuesRef = useRef<FormValues>();

  useEffect(() => {
    prevValuesRef.current = values;
  }, [values]);

  const prevValues = prevValuesRef.current;

  useEffect(() => {
    if (JSON.stringify(prevValues) !== JSON.stringify(values)) {
      const id = question.id;
      const versionId = version.id;
      let value = values[inputId];
      switch (question.type) {
        case question_type_enum.radio:
          if (!value) {
            value = [];
          }
          if (typeof value === "string" && value !== "other") {
            value = JSON.parse(value);
          }
          if (!Array.isArray(value)) {
            value = [value];
          } else {
            value = value.map((item) => {
              if (typeof item === "string" && item !== "other") {
                return JSON.parse(item);
              }
              return item;
            });
          }
          const hasOther = otherSpecify && value.includes("other");
          value = value.filter((val: any) => val !== "other");

          onChange({
            id,
            versionId,
            type: question_type_enum.radio,
            value,
            ...(hasOther && { specify: values[`${inputId}_specify`] || "" }),
          });
          break;

        case question_type_enum.text:
          onChange({
            id,
            versionId,
            type: question_type_enum.text,
            value: value || "",
          });
          break;

        case question_type_enum.likelihood_to_return:
          onChange({
            id,
            versionId,
            type: question_type_enum.likelihood_to_return,
            value: value,
          });
          break;

        default:
          throw new Error("Invalid question type");
      }
    }
  }, [
    onChange,
    question,
    prevValues,
    values,
    inputId,
    otherSpecify,
    version.id,
  ]);

  useEffect(() => {
    if (!formRef || !formRef.current || isEditMode) {
      return;
    }

    const firstEmptyInput = formRef.current.querySelector<
      HTMLInputElement | HTMLTextAreaElement
    >("input, textarea");

    if (firstEmptyInput) {
      // Hack to not bork the transition effect
      setTimeout(() => {
        firstEmptyInput.focus();
      }, transitionDuration + 50);
    }
  }, [formRef, isEditMode]);

  useLayoutEffect(() => {
    if (showPleaseSpecify && formRef.current) {
      const specify = formRef.current.querySelector<HTMLInputElement>(
        ".flow-check__please-specify input"
      );
      if (specify) {
        specify.focus();
      }
    }
  }, [showPleaseSpecify, formRef]);

  useEffect(() => {
    register(inputId, { required: version.required });

    return () => unregister(inputId);
  }, [register, unregister, inputId, version.required]);

  const onDragEnd = async (result: DropResult, provided: ResponderProvided) => {
    if (!result.destination) {
      return;
    }

    if (questionManager) {
      options = reorderQuestionOptions(
        options,
        result.source.index,
        result.destination.index
      );

      apollo.writeFragment<FlowQuestionFragment>({
        id: apollo.cache.identify({ ...question }),
        fragment: TheFlowQuestionFragment,
        fragmentName: "FlowQuestionFragment",
        data: {
          ...question,
          draft_version: {
            ...version,
            question_version_question_options: options.map((o) => ({
              __typename: "question_version_question_option",
              question_option_id: o.id,
              question_version_id: version.id,
              question_option: o,
            })),
          },
        },
        variables: {
          includeDrafts: true,
        },
      });

      await questionManager.moveQuestionOption(
        question,
        result.source.index,
        result.destination.index
      );
    }
  };

  const handleAddOption = async (
    forms: TranslatedForms<QuestionOptionFormValues>
  ) => {
    if (questionManager) {
      const reasonCode = forms[defaultLanguage]?.reasonCode;
      questionManager.addQuestionOption(question.id, forms, reasonCode);
    }
  };

  const handleDeleteOption = (optionId: number) => {
    if (questionManager) {
      questionManager.deleteQuestionOption(question, optionId);
    }
  };

  const handleSaveQuestion = async (
    forms: TranslatedForms<QuestionFormValues>
  ) => {
    if (questionManager) {
      await questionManager.updateQuestion(
        question.id,
        version.required,
        forms
      );
    }
  };

  const handleSaveQuestionOption = async (
    optionId: number,
    forms: TranslatedForms<QuestionOptionFormValues>
  ) => {
    if (questionManager) {
      const reasonCode = forms[defaultLanguage]?.reasonCode;

      await questionManager.updateQuestionOption(optionId, forms, reasonCode);
    }
  };

  return !isEditMode && isDisabled ? (
    <></>
  ) : (
    <Wrapper
      className={classNames(
        "inline-text-context",
        "flow-form-question-context",
        className
      )}
    >
      {formManager && questionManager && (
        <FlowVersionProvider version="draft">
          <FormQuestionActions
            form={form}
            formManager={formManager}
            questionManager={questionManager}
            onDelete={onDelete}
            formStepQuestion={formStepQuestion}
            isFreeMode={isFreeMode}
          />
        </FlowVersionProvider>
      )}

      <FormQuestionTitle
        className={classNames({
          "flow-form-question--title": true,
          "flow-form-question--title--required": version.required,
          disabled: isEditMode && isDisabled,
        })}
      >
        <EditableQuestionTitle
          isEditable={isEditMode}
          type={question.type}
          titleTranslation={version.title_translation!}
          hintTranslation={version.hint_translation!}
          placeholderTranslation={version.placeholder_translation}
          language={language}
          enabledLanguages={enabledLanguages}
          defaultLanguage={defaultLanguage}
          onSave={handleSaveQuestion}
        >
          <div className="flow-form-question--title__content" tw="inline-flex">
            {isEditMode && !!dragHandleProps && (
              <QuestionDragHandle {...dragHandleProps}>
                <FontAwesomeIcon
                  icon={faGripVertical}
                  size="sm"
                  transform="right-1.5"
                />
              </QuestionDragHandle>
            )}
            {isEditMode && isDisabled && (
              <DisabledQuestionIcon>
                <FontAwesomeIcon
                  icon={faEyeSlash}
                  size="xs"
                  transform="shrink-4"
                />
              </DisabledQuestionIcon>
            )}
            <span>{translationText(version.title_translation)}</span>
          </div>
        </EditableQuestionTitle>
      </FormQuestionTitle>
      {translationText(version.hint_translation) && (
        <FormQuestionHint
          className={classNames(
            "form-question--hint",
            isEditMode && "questions-are-draggable"
          )}
        >
          {translationText(version.hint_translation)}
        </FormQuestionHint>
      )}
      {question.type === "text" ? (
        <FormQuestionTextField
          className={classNames({
            "flow-form-question__content": true,
            "questions-are-draggable": isEditMode,
          })}
        >
          <TextareaAutosize
            className="flow-textarea"
            minRows={1}
            maxRows={8}
            name={getQuestionInputId(question)}
            placeholder={translationText(version.placeholder_translation)}
            onChange={(e) => {
              setValue(getQuestionInputId(question), e.target.value);
            }}
            disabled={isSubmitting}
          />
        </FormQuestionTextField>
      ) : question.type === "likelihood_to_return" ? (
        <FormQuestionLikelihoodToReturnField
          className={classNames({
            "flow-form-question__content": true,
            "questions-are-draggable": isEditMode,
          })}
        >
          <LikelihoodToReturn
            onChange={(value) => setValue(getQuestionInputId(question), value)}
            isFormQuestion={true}
          />
        </FormQuestionLikelihoodToReturnField>
      ) : (
        <>
          {isEditMode && (
            <ConfirmationModal
              isOpen={!!confirmDeleteOptionId}
              onClose={(confirmed) => {
                if (confirmed && confirmDeleteOptionId) {
                  handleDeleteOption(confirmDeleteOptionId);
                }
                setConfirmOptionDeleteId(null);
              }}
              title="Remove option"
              content={
                <>
                  Are you sure you want to remove "
                  {translationText(
                    confirmDeleteOption &&
                      getObjectVersion(confirmDeleteOption).title_translation
                  )}
                  "?
                </>
              }
              confirmButtonType="danger"
              confirmText="Remove"
            />
          )}
          <Options
            className={classNames({
              "flow-form-question__content": true,
              "options-are-draggable": isEditMode,
            })}
          >
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable
                droppableId={String(getQuestionInputId(question))}
                isDropDisabled={!isEditMode}
              >
                {(provided, droppableSnapshot) => (
                  <>
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {options.map((option, index) => {
                        const optionVersion = getObjectVersion(option);

                        return (
                          <Draggable
                            key={option.id}
                            draggableId={String(option.id)}
                            index={index}
                            isDragDisabled={!isEditMode}
                          >
                            {(provided, snapshot) => (
                              <Option
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                className={classNames(
                                  "flow-form-question--option",
                                  snapshot.isDragging
                                    ? "option-is-dragging"
                                    : isEditMode
                                    ? "option-inline-text-context"
                                    : undefined
                                )}
                              >
                                {isEditMode && (
                                  <OptionDragHandle
                                    {...provided.dragHandleProps}
                                  >
                                    <FontAwesomeIcon icon={faGripVertical} />
                                  </OptionDragHandle>
                                )}
                                <div
                                  className={classNames("flow-check", {
                                    "flow-check--radio": !selectMultiple,
                                    "flow-check--checkbox": selectMultiple,
                                    "flow-check--disabled": isSubmitting,
                                  })}
                                >
                                  <fieldset disabled={isSubmitting}>
                                    <input
                                      {...register(
                                        getQuestionInputId(question),
                                        {
                                          required: version.required,
                                        }
                                      )}
                                      id={optionId(option)}
                                      type={
                                        selectMultiple ? "checkbox" : "radio"
                                      }
                                      value={JSON.stringify({
                                        id: option.id,
                                        versionId: optionVersion.id,
                                      })}
                                    />
                                    <label htmlFor={optionId(option)}>
                                      <EditableQuestionOptionTitle
                                        questionId={question.id}
                                        titleTranslation={
                                          optionVersion.title_translation
                                        }
                                        reasonCode={option.reason_code}
                                        isEditable={
                                          isEditMode &&
                                          !droppableSnapshot.isDraggingOver
                                        }
                                        language={language}
                                        enabledLanguages={enabledLanguages}
                                        defaultLanguage={defaultLanguage}
                                        onSave={(forms) =>
                                          handleSaveQuestionOption(
                                            option.id,
                                            forms
                                          )
                                        }
                                      >
                                        {translationText(
                                          optionVersion.title_translation
                                        )}
                                      </EditableQuestionOptionTitle>
                                    </label>
                                  </fieldset>
                                </div>
                                {(() => {
                                  if (!isEditMode || options.length === 1) {
                                    return null;
                                  }

                                  const inUseByRule =
                                    formStepQuestion.optionIdsReferencedByOfferRules.includes(
                                      option.id
                                    );

                                  const inUseByStepCondition =
                                    formStepQuestion.stepConditionsReferencedBy?.some(
                                      (condition) =>
                                        condition.questionOptions.some(
                                          (test) => test.id === option.id
                                        )
                                    );

                                  const inUseByQuestionCondition =
                                    formStepQuestion.questionConditionsReferencedBy?.some(
                                      (condition) =>
                                        condition.questionOptions.some(
                                          (test) => test.id === option.id
                                        )
                                    );

                                  const tippyContent = inUseByRule
                                    ? "Cannot be removed because it is in use by flow rules."
                                    : inUseByStepCondition
                                    ? "Cannot be removed because it referenced by a conditional step."
                                    : inUseByQuestionCondition
                                    ? "Cannot be removed because it referenced by a conditional question."
                                    : "";

                                  return (
                                    <Tippy
                                      disabled={
                                        !inUseByRule &&
                                        !inUseByStepCondition &&
                                        !inUseByQuestionCondition
                                      }
                                      content={tippyContent}
                                      hideOnClick={false}
                                    >
                                      <button
                                        type="button"
                                        className={classNames(
                                          (inUseByRule ||
                                            inUseByStepCondition) &&
                                            "disabled"
                                        )}
                                        style={{
                                          display:
                                            droppableSnapshot.isDraggingOver
                                              ? "none"
                                              : "block",
                                        }}
                                        onClick={(e) => {
                                          if (
                                            inUseByRule ||
                                            inUseByStepCondition ||
                                            inUseByQuestionCondition
                                          ) {
                                            return;
                                          }
                                          setConfirmOptionDeleteId(option.id);
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          icon={faTimes}
                                          color="white"
                                        />
                                      </button>
                                    </Tippy>
                                  );
                                })()}
                              </Option>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </div>
                    {otherSpecify && (
                      <Option>
                        {isEditMode && (
                          <OptionDragHandle style={{ visibility: "hidden" }}>
                            <FontAwesomeIcon icon={faGripVertical} />
                          </OptionDragHandle>
                        )}
                        <div
                          className={classNames("flow-check", {
                            "flow-check--radio": !selectMultiple,
                            "flow-check--checkbox": selectMultiple,
                            "flow-check--disabled": isSubmitting,
                          })}
                        >
                          <fieldset disabled={isSubmitting}>
                            <input
                              {...register(getQuestionInputId(question), {
                                required: version.required,
                              })}
                              id={optionId({ id: `other-${question.id}` })}
                              type={selectMultiple ? "checkbox" : "radio"}
                              value="other"
                              data-vulcan="other"
                            />
                            <label
                              htmlFor={optionId({ id: `other-${question.id}` })}
                            >
                              {translationText(flowText.other_option)}
                            </label>
                          </fieldset>
                        </div>

                        {showPleaseSpecify && (
                          <div className="flow-check__please-specify">
                            <fieldset disabled={isSubmitting}>
                              <input
                                {...register(
                                  `${getQuestionInputId(question)}_specify`,
                                  { required: true }
                                )}
                                type="text"
                                defaultValue=""
                                className="flow-input-text"
                                placeholder={translationText(
                                  flowText.specify_placeholder
                                )}
                                data-vulcan="specify"
                              />
                            </fieldset>
                          </div>
                        )}
                      </Option>
                    )}
                    {isEditMode && (
                      <Option>
                        <OptionDragHandle style={{ visibility: "hidden" }}>
                          <FontAwesomeIcon icon={faGripVertical} />
                        </OptionDragHandle>
                        <div
                          className={classNames(
                            "flow-check",
                            "flow-check--add",
                            {
                              "flow-check--radio": !selectMultiple,
                              "flow-check--checkbox": selectMultiple,
                            }
                          )}
                        >
                          <label
                            onClick={() => setAddOptionModalIsOpen(true)}
                            className="radio"
                          >
                            {isDisabled
                              ? "Add option to enable question"
                              : "Add option"}
                          </label>
                        </div>
                      </Option>
                    )}
                  </>
                )}
              </Droppable>
            </DragDropContext>
          </Options>
          {isEditMode && (
            <AddRadioOptionModal
              key={addOptionModalKey}
              mode="create"
              language={language}
              enabledLanguages={enabledLanguages}
              defaultLanguage={defaultLanguage}
              isOpen={addOptionModalIsOpen}
              questionId={question.id}
              position={nextPosition}
              onClose={() => {
                setAddOptionModalIsOpen(false);
                setAddOptionModalKey(nanoid());
              }}
              onSave={handleAddOption}
            />
          )}
        </>
      )}
    </Wrapper>
  );
};

export default FormQuestion;
