import { faPlus, faVial } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  RuleFlowFragment,
  RuleFlowTestFragment,
} from "../../__generated__/graphql";
import Button from "../form/Button";
import HelpIcon from "../HelpIcon";
import useAccountFeatures from "../useAccountFeatures";
import {
  BoxContainer,
  BoxHeading,
  EligibilityMessageBox,
  FlowBox,
  FlowTestBox,
} from "./RuleBoxes";

export interface RuleFlowPickerValue {
  flowIds: number[];
  flowTestIds: number[];
  eligibilityMessageIds: number[];
}

interface RuleFlowPickerProps {
  flows: RuleFlowFragment[];
  flowTests: RuleFlowTestFragment[];
  eligibilityMessages: any[];
  value: RuleFlowPickerValue;
  onChange: (value: RuleFlowPickerValue) => void;
  onClickCreateMessage: (id?: number) => void;
  onClickDeleteMessage: (id: number) => void;
}

const RuleFlowPicker: React.FunctionComponent<RuleFlowPickerProps> = ({
  flows,
  flowTests,
  eligibilityMessages,
  value,
  onChange,
  onClickCreateMessage,
  onClickDeleteMessage,
}) => {
  const { features } = useAccountFeatures();

  const handleToggleFlow = (flowId: number) => {
    const newValue = {
      ...value,
      flowIds: value.flowIds.includes(flowId)
        ? value.flowIds.filter((id) => id !== flowId)
        : [...value.flowIds, flowId],
      flowTestIds: [],
      eligibilityMessageIds: [],
    };

    onChange(newValue);
  };

  const handleToggleFlowTest = (flowTestId: number) => {
    const newValue = {
      ...value,
      flowIds: [],
      flowTestIds: value.flowTestIds.includes(flowTestId) ? [] : [flowTestId],
      eligibilityMessageIds: [],
    };

    onChange(newValue);
  };

  const handleToggleEligibilityMessage = (eligibilityMessageId: number) => {
    const newValue = {
      ...value,
      flowIds: [],
      flowTestIds: [],
      eligibilityMessageIds: value.eligibilityMessageIds.includes(
        eligibilityMessageId
      )
        ? []
        : [eligibilityMessageId],
    };

    onChange(newValue);
  };

  return (
    <div>
      <div>
        <BoxHeading>Flows</BoxHeading>
        <BoxContainer>
          {flows.map((flow) => (
            <FlowBox
              key={flow.id}
              isOn={value.flowIds.includes(flow.id)}
              onClick={() => handleToggleFlow(flow.id)}
            >
              {flow.title}
            </FlowBox>
          ))}
        </BoxContainer>
      </div>

      {!!flowTests.length && (
        <div tw="mt-2">
          <BoxHeading>A/B test</BoxHeading>
          <BoxContainer>
            {flowTests.map((flowTest) => (
              <FlowTestBox
                key={flowTest.id}
                isOn={value.flowTestIds.includes(flowTest.id)}
                onClick={() => handleToggleFlowTest(flowTest.id)}
              >
                <FontAwesomeIcon icon={faVial} transform="shrink-3" />{" "}
                {flowTest.name}
              </FlowTestBox>
            ))}
          </BoxContainer>
        </div>
      )}

      {!!eligibilityMessages.length && (
        <div tw="mt-2 w-full border-b border-divider py-3">
          <BoxHeading>
            Eligibility Messages{" "}
            <HelpIcon content="An eligibility message is shown to your users prior to a flow if they are unable to cancel." />
          </BoxHeading>
          <BoxContainer>
            {eligibilityMessages.map((eligibilityMessage) => (
              <EligibilityMessageBox
                key={eligibilityMessage.id}
                isOn={value.eligibilityMessageIds.includes(
                  eligibilityMessage.id
                )}
                onClick={() =>
                  handleToggleEligibilityMessage(eligibilityMessage.id)
                }
                isEditable={true}
                isDeletable={true}
                onClickDelete={() =>
                  onClickDeleteMessage(eligibilityMessage.id)
                }
                onClickEdit={() => {
                  onClickCreateMessage(eligibilityMessage.id);
                }}
              >
                {eligibilityMessage.name}
              </EligibilityMessageBox>
            ))}
          </BoxContainer>
        </div>
      )}

      {features.eligibility_messages && (
        <div tw="mt-3 flex gap-2">
          <span>
            <Button
              type="button"
              buttonType="alternate-secondary"
              size="sm"
              onClick={() => onClickCreateMessage()}
              disabled={false}
            >
              <FontAwesomeIcon icon={faPlus} /> Create message
            </Button>
          </span>
        </div>
      )}
    </div>
  );
};

export default RuleFlowPicker;
