import createAuth0Client, {
  Auth0Client,
  Auth0ClientOptions,
  getIdTokenClaimsOptions,
  GetTokenSilentlyOptions,
  IdToken,
  LogoutOptions,
  RedirectLoginOptions,
  RedirectLoginResult,
  User,
} from "@auth0/auth0-spa-js";
import jwtDecode from "jwt-decode";
import { createContext, useContext, useEffect, useState } from "react";

import { Token } from "../@types";

interface Auth0Context {
  isAuthenticated: boolean;
  user: User | undefined;
  loading: boolean;
  handleRedirectCallback(): Promise<RedirectLoginResult>;
  getIdTokenClaims(o?: getIdTokenClaimsOptions): Promise<IdToken | undefined>;
  loginWithRedirect(o: RedirectLoginOptions): Promise<void>;
  getTokenSilently(o?: GetTokenSilentlyOptions): Promise<Token | undefined>;
  logout(o?: LogoutOptions): void;
}

interface Auth0ProviderOptions {
  children: React.ReactElement;
  onRedirectCallback?(result: RedirectLoginResult): void;
}

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

const Context = createContext<Auth0Context | null>(null);

export const useAuth0 = () => useContext(Context)!;

export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}: Auth0ProviderOptions & Auth0ClientOptions) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<User | undefined>();
  const [auth0Client, setAuth0] = useState<Auth0Client>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions);
      setAuth0(auth0FromHook);

      if (
        !window.location.pathname.match(/\/connect\//) &&
        window.location.search.includes("code=") &&
        !window.location.search.includes("state=samlp")
      ) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser();
        setUser(user);
      }

      setLoading(false);
    };

    initAuth0();
    // eslint-disable-next-line
  }, []);

  const handleRedirectCallback = async () => {
    setLoading(true);

    const result = await auth0Client!.handleRedirectCallback();
    const user = await auth0Client!.getUser();

    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);

    return result;
  };

  return (
    <Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        handleRedirectCallback,
        getIdTokenClaims: (o: getIdTokenClaimsOptions | undefined) =>
          auth0Client!.getIdTokenClaims(o),
        loginWithRedirect: (o: RedirectLoginOptions) =>
          auth0Client!.loginWithRedirect(o),
        getTokenSilently: async (o: GetTokenSilentlyOptions | undefined) => {
          const token = await auth0Client!.getTokenSilently(o);
          return { raw: token, decoded: jwtDecode(token) };
        },
        logout: (o: LogoutOptions | undefined) => auth0Client!.logout(o),
      }}
    >
      {children}
    </Context.Provider>
  );
};
