import gql from "graphql-tag";

export default gql`
  fragment CustomerPortalAccountFragment on account {
    id
    token
    title
    logo_url
    favicon_url
    default_flow {
      id
      logo_url
    }
    platform_connection {
      id
      platform
    }
  }
`;
