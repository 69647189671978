import { gql, useQuery } from "@apollo/client";

import {
  account_feature_key_enum,
  UseAccountFeaturesQuery,
  UseAccountFeaturesQueryVariables,
} from "../__generated__/graphql";
import { defaultFeatures } from "./accountFeatures";
import AccountFeatureFragment from "./fragments/AccountFeatureFragment";

export default function useAccountFeatures() {
  const { data, loading } = useQuery<
    UseAccountFeaturesQuery,
    UseAccountFeaturesQueryVariables
  >(gql`
    query UseAccountFeaturesQuery {
      account_feature {
        ...AccountFeatureFragment
      }
    }
    ${AccountFeatureFragment}
  `);

  return {
    loading,
    features: Object.entries(defaultFeatures).reduce<
      Partial<Record<account_feature_key_enum, boolean | number>>
    >((prev, [key, defaultValue]) => {
      const feature = data?.account_feature.find((f) => f.key === key);

      let value = feature?.value;

      if (!loading && (typeof value === "undefined" || value === null)) {
        value = defaultValue;
      }

      return {
        ...prev,
        [key]: value,
      };
    }, {}),
  };
}
