import { useMutation } from "@apollo/client";
import gql from "graphql-tag";

import {
  UseSwitchAccountMutation,
  UseSwitchAccountMutationVariables,
} from "../__generated__/graphql";
import { useAuth0 } from "../app/auth0";

export default function useSwitchAccount(isAdmin: boolean) {
  const { getTokenSilently } = useAuth0();

  const [switchAccountMutation] = useMutation<
    UseSwitchAccountMutation,
    UseSwitchAccountMutationVariables
  >(gql`
    mutation UseSwitchAccountMutation($input: SwitchAccountInput!) {
      switchAccount(input: $input) {
        success
      }
    }
  `);

  const switchAccount = async (
    accountId: number,
    pathname: string | undefined = undefined
  ) => {
    await switchAccountMutation({
      variables: { input: { accountId } },
    });

    await getTokenSilently({ ignoreCache: true });

    const switchToRootPath =
      isAdmin ||
      window.location.pathname.startsWith("/create-account") ||
      window.location.pathname.startsWith("/connect");

    if (switchToRootPath) {
      window.location.replace("/");
    } else {
      window.location.replace(pathname || window.location.pathname);
    }
  };

  const switchToAdmin = () => {
    window.location.replace("/a/accounts");
  };

  return { switchAccount, switchToAdmin };
}
