import { LexicalEditor as LexicalEditorType } from "lexical";
import { ReactEditor } from "slate-react";

import {
  translation_value_format_enum,
  TranslationValueFragment,
} from "../../__generated__/graphql";
import EditorSelector from "./EditorSelector";
import { EditorType } from "./lib";

interface TranslationValueEditorSelectorProps {
  translationValue: TranslationValueFragment | null;
  isInline?: boolean;
  defaultFormat: translation_value_format_enum;
  baseFontSize?: string;
  initialFocus?: boolean;
  onChange: (value: string, editorType: EditorType) => void;
  lexicalEditorRef?: (editor: LexicalEditorType) => void;
  slateEditorRef?: (editor: ReactEditor) => void;
}

const TranslationValueEditorSelector: React.FunctionComponent<
  TranslationValueEditorSelectorProps
> = ({
  translationValue,
  isInline,
  defaultFormat,
  baseFontSize,
  initialFocus,
  onChange,
  lexicalEditorRef,
  slateEditorRef,
}) => {
  const format = translationValue?.format || defaultFormat;

  return (
    <EditorSelector
      format={format}
      value={translationValue?.value}
      isInline={isInline}
      baseFontSize={baseFontSize}
      initialFocus={initialFocus}
      onChange={onChange}
      lexicalEditorRef={lexicalEditorRef}
      slateEditorRef={slateEditorRef}
    />
  );
};

export default TranslationValueEditorSelector;
