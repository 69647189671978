import { LexicalComposer } from "@lexical/react/LexicalComposer";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { EditorState, LexicalEditor } from "lexical";
import { FieldError } from "react-hook-form";
import tw from "twin.macro";

import { editorConfig } from "./lib/config";
import { EditorSettingsProvider } from "./lib/EditorSettings";
import EditorRefPlugin from "./plugins/EditorRefPlugin";
import ImagePlugin from "./plugins/ImagePlugin";
import InitialFocusPlugin from "./plugins/InitialSelectionPlugin";
import InlinePlugin from "./plugins/InlinePlugin";
import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevelPlugin";
import OfferDescriptionPlugin from "./plugins/OfferDescriptionPlugin";
import PropertyPlugin from "./plugins/PropertyPlugin";
import TargetBlankLinkPlugin from "./plugins/TargetBlankLinkPlugin";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import VideoPlugin from "./plugins/VideoPlugin";
import ContentEditable from "./ui/ContentEditable";
import Placeholder from "./ui/Placeholder";
import { editorStyles } from "./ui/styles";

interface EditorProps {
  initialValue?: string;
  initialValueKey?: string;
  isReadOnly?: boolean;
  allowVideoAutoPlay?: boolean;
  baseFontSize?: string;
  isInline?: boolean;
  tagsEnabled?: boolean;
  linksEnabled?: boolean;
  listsEnabled?: boolean;
  imagesEnabled?: boolean;
  videosEnabled?: boolean;
  customPropertiesEnabled?: boolean;
  initialFocus?: boolean;
  isOfferContent?: boolean;
  height?: string;
  fieldError?: FieldError;
  onChange?: (value: string) => void;
  editorRef?: (editor: LexicalEditor) => void;
}

const Editor: React.FunctionComponent<EditorProps> = ({
  initialValue,
  initialValueKey,
  isReadOnly,
  allowVideoAutoPlay = true,
  baseFontSize,
  isInline = false,
  tagsEnabled = true,
  linksEnabled = true,
  listsEnabled = true,
  imagesEnabled = true,
  videosEnabled = true,
  customPropertiesEnabled = true,
  initialFocus = false,
  isOfferContent = false,
  height,
  fieldError,
  onChange,
  editorRef,
}) => {
  const handleChange = (editorState: EditorState) => {
    if (onChange) {
      onChange(JSON.stringify(editorState.toJSON()));
    }
  };

  return (
    <EditorSettingsProvider
      editorSettings={{
        allowVideoAutoPlay,
      }}
    >
      <div
        css={[
          !isReadOnly
            ? [
                tw`border border-divider rounded overflow-hidden shadow-sm`,
                fieldError
                  ? tw`border-red-500 focus-within:ring focus-within:ring-red-200 focus-within:border-red-500`
                  : undefined,
              ]
            : undefined,
          editorStyles,
        ]}
      >
        <LexicalComposer
          key={initialValueKey}
          initialConfig={{
            ...editorConfig,
            editable: !isReadOnly,
            editorState: initialValue,
          }}
        >
          <EditorRefPlugin editorRef={editorRef} />
          {!isReadOnly ? (
            <ToolbarPlugin
              isInline={isInline}
              tagsEnabled={tagsEnabled}
              linksEnabled={linksEnabled}
              listsEnabled={listsEnabled}
              imagesEnabled={imagesEnabled}
              videosEnabled={videosEnabled}
              customPropertiesEnabled={customPropertiesEnabled}
              isOfferContent={isOfferContent}
            />
          ) : (
            <></>
          )}
          <RichTextPlugin
            key={initialValueKey}
            contentEditable={
              <ContentEditable
                isInline={isInline}
                isEditable={!isReadOnly}
                fontSize={baseFontSize}
                height={height}
              />
            }
            placeholder={<Placeholder />}
            ErrorBoundary={LexicalErrorBoundary}
          />
          {isInline ? <InlinePlugin /> : <></>}
          <OnChangePlugin onChange={handleChange} />
          <HistoryPlugin />
          <LinkPlugin />
          <ListPlugin />
          <ListMaxIndentLevelPlugin maxDepth={3} />
          <PropertyPlugin />
          <ImagePlugin />
          <VideoPlugin />
          <TargetBlankLinkPlugin />
          {initialFocus ? <InitialFocusPlugin /> : <></>}
          {isOfferContent ? <OfferDescriptionPlugin /> : <></>}
        </LexicalComposer>
      </div>
    </EditorSettingsProvider>
  );
};

export default Editor;
