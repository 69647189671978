import { faSearch, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ForwardedRef, forwardRef, useImperativeHandle, useRef } from "react";
import tw, { css, styled, theme } from "twin.macro";

import TextInput from "../form/input/TextInput";

type SearchInputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  onClear: () => void;
  isActive?: boolean;
  width?: string;
  forwardedRef?: ForwardedRef<HTMLInputElement>;
  className?: string;
};

const Wrapper = styled.div<{ isActive: boolean }>`
  ${tw`relative inline-block`};

  input {
    padding-left: 32px;
  }

  svg.search-icon {
    position: absolute;
    top: 9px;
    left: 8px;
  }

  svg.clear-icon {
    position: absolute;
    top: 9px;
    right: 12px;

    &:hover {
      ${(props) => (props.isActive ? tw`text-yellow-800` : tw`text-gray-800`)}
      ${tw`cursor-pointer`}
    }
  }
`;

const SearchInput: React.FunctionComponent<SearchInputProps> = ({
  onClear,
  isActive = false,
  width = "22rem",
  className,
  forwardedRef,
  ...props
}) => {
  const innerRef = useRef<HTMLInputElement>(null);
  useImperativeHandle(forwardedRef, () => innerRef.current!);

  return (
    <Wrapper isActive={isActive} className={className}>
      <FontAwesomeIcon
        className="search-icon"
        icon={faSearch}
        color={isActive ? theme`colors.yellow.700` : theme`colors.gray.400`}
        transform="shrink-1"
      />
      <TextInput
        ref={innerRef}
        placeholder={props.placeholder || "Search"}
        {...props}
        width="full"
        css={[
          css`
            width: ${width};
          `,
          tw`text-gray-900`,
          isActive &&
            tw`bg-yellow-200 border border-orange-300 focus:border-orange-400 focus:ring focus:ring-orange-100`,
        ]}
        className={className}
      />
      {props.value && props.value.toString().trim() !== "" && (
        <FontAwesomeIcon
          className="clear-icon"
          icon={faTimes}
          color={isActive ? theme`colors.yellow.700` : theme`colors.gray.400`}
          transform="shrink-1"
          onClick={() => {
            onClear();
            if (innerRef.current) {
              innerRef.current.focus();
            }
          }}
        />
      )}
    </Wrapper>
  );
};

export default forwardRef<HTMLInputElement, SearchInputProps>((props, ref) => (
  <SearchInput {...props} forwardedRef={ref} />
));
