import { property_format_enum } from "../../../../__generated__/graphql";
import propertyTagLabel from "../../../../features/properties/lib/propertyTagLabel";
import {
  PropertyConfig,
  PropertyValues,
} from "../../../../features/properties/lib/types";
import formatPropertyValue from "../../../properties/formatPropertyValue";
import { propertyConfigs, SegmentPropertyKey } from "../../../segments/lib";
import { PropertyTag } from "./types";

export default function renderPropertyTag(
  propertyValues: PropertyValues,
  propertyConfig: PropertyConfig,
  tag: PropertyTag,
  propertyId: string,
  dateFormat: string,
  numberFormat: property_format_enum | null,
  fallback: string = "",
  isArray: boolean = false,
  showPlaceholder: boolean = false
) {
  const label =
    tag === "custom_property"
      ? propertyConfig[propertyId]?.name
      : tag === "segment_property"
      ? propertyConfigs[propertyId as SegmentPropertyKey]?.name
      : propertyTagLabel(tag);

  let propertyValue = ["custom_property", "segment_property"].includes(tag)
    ? propertyValues[propertyId]
    : propertyValues[tag];

  if (
    tag === "custom_property" &&
    propertyValue !== null &&
    typeof propertyValue !== "undefined"
  ) {
    const config = propertyConfig[propertyId];

    if (config) {
      propertyValue = formatPropertyValue(
        config.type,
        propertyValue,
        numberFormat || config.numberFormat || null,
        dateFormat || "MMMM d, yyyy",
        isArray
      );
    }
  }

  if (
    tag === "segment_property" &&
    propertyId &&
    typeof propertyValue !== "undefined" &&
    propertyValue !== null
  ) {
    const type = propertyConfigs[propertyId as SegmentPropertyKey]?.type;

    propertyValue = formatPropertyValue(
      type,
      propertyValue,
      numberFormat,
      dateFormat || "MMMM d, yyyy",
      isArray
    );
  }

  return (
    <span>
      {showPlaceholder ? (
        <>
          {label}
          {fallback && ` | ${fallback}`}
        </>
      ) : (
        propertyValue || fallback
      )}
    </span>
  );
}
