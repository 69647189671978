import { Controller, useForm } from "react-hook-form";

import Button from "../form/Button";
import FieldInput from "../form/FieldInput";
import FieldLabel from "../form/FieldLabel";
import FieldRow from "../form/FieldRow";
import ImageInput from "../form/input/ImageInput";
import Modal, { ModalProps } from "../modal/Modal";
import ModalFooter from "../modal/ModalFooter";
import ModalFormBody from "../modal/ModalFormBody";
import ModalHeader from "../modal/ModalHeader";

interface FormValues {
  url: string;
}

type InsertImageModalProps = ModalProps & {
  mode: "insert" | "edit";
  initialValues?: FormValues;
  onClose: (values: FormValues | undefined) => void;
};

const InsertImageModal: React.FunctionComponent<InsertImageModalProps> = ({
  mode,
  initialValues,
  onClose,
  ...props
}) => {
  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: initialValues,
  });

  const onSubmit = handleSubmit((values) => {
    onClose(values);
  });

  return (
    <Modal {...props} size="xl">
      <ModalHeader>
        {mode === "insert" ? "Insert image" : "Edit image"}
      </ModalHeader>
      <ModalFormBody>
        <form id="insert-image" onSubmit={onSubmit}>
          <FieldRow>
            <FieldLabel>Image</FieldLabel>
            <FieldInput>
              <Controller
                control={control}
                name="url"
                rules={{ required: true }}
                render={({ field }) => (
                  <ImageInput
                    value={field.value}
                    onChange={field.onChange}
                    maxWidth="528px"
                    maxHeight="none"
                    allowClear={false}
                    fullWidth={true}
                  />
                )}
              />
            </FieldInput>
          </FieldRow>
        </form>
      </ModalFormBody>
      <ModalFooter>
        <Button type="button" buttonType="primary" onClick={onSubmit}>
          {mode === "insert" ? "Insert image" : "Save"}
        </Button>
        <Button
          buttonType="default"
          onMouseDown={(e) => {
            e.preventDefault();
            onClose(undefined);
          }}
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default InsertImageModal;
