import { createGlobalStyle } from "styled-components";
import tw, { GlobalStyles as BaseStyles, theme } from "twin.macro";

import { TRANSPARENT_LOGIN_HEADER_HEIGHT } from "../common/layout/header/TransparentLoginHeader";

const CustomStyles = createGlobalStyle`
  body {
    background: white;
    color: ${theme`colors.type.DEFAULT`};
    margin: 0;
    font-family: 'Source Sans Pro', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body.has-transparent-login {
    padding-top: ${TRANSPARENT_LOGIN_HEADER_HEIGHT};
  }

  body.modal-open {
    overflow-x: hidden;
    overflow-y: visible !important;
  }

  body.tour-open {
    overflow-x: hidden;
    overflow-y: hidden !important;
  }

  code {
    font-family: "Source Code Pro", monospace;
    font-size: 0.9rem;
  }

  .page-header--untabbed.page-header__upsell-banner--hidden + .no-data-guide {
    ${tw`-mt-1 border-t`}
  }

  .paid-feature-wrapper {
    * {
      ${tw`pointer-events-none`}
    }

    input, select, button, &.flow-check, .flow-button--action, [role=textbox] {
      ${tw`opacity-50`}
    }
  }

  .tippy-box[data-theme~="annotation"] {
    min-width: 120px;
    color: ${theme`colors.gray.900`};
    background-color: ${theme`colors.gray.100`};
    border: 1px solid ${theme`colors.gray.50`};
    border-bottom: 2px solid ${theme`colors.red.800`};
    border-radius: 5px 5px 0 0;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1));

    .annotation-wrapper {
      border-bottom:1px solid ${theme`colors.gray.200`};
      padding-top:10px;

      .annotation-tag {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 1px 3px 1px 4px;
        height: 12px;
        width: fit-content;
        background: ${theme`colors.blue.500`};
        border-radius: 30px;
        margin-bottom: 3px;
        margin-top: -5px;

        span {
          font-weight: 600;
          font-size: 8px;
          letter-spacing: 0.02em;
          color: white;
        }
      }

      .annotation-time {
        padding-bottom:10px;
        color: ${theme`colors.gray.600`};
      }

      &:last-child {
        border-bottom: none;
      }
    }

  }
`;

const GlobalStyles = () => (
  <>
    <BaseStyles />
    <CustomStyles />
  </>
);

export default GlobalStyles;
