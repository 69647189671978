import { ReactNode } from "react";

import { language_enum } from "../../../../__generated__/graphql";
import createIntl from "../../../../common/i18n";
import { PauseSubscriptionOfferDetails } from "../../../offers/lib/offerDetails";

export default function getPauseSubscriptionDescription(
  options: PauseSubscriptionOfferDetails["options"],
  forApp: boolean = false,
  language: language_enum = language_enum.en_us,
  renderPlaceholderFn: (children: ReactNode) => ReactNode = (children) =>
    children?.toString(),
  renderHighlightFn: (children: ReactNode) => ReactNode = (children) =>
    children?.toString(),
  prefix: string = ""
) {
  const cleanPrefix = prefix ? `${prefix.trimEnd()} ` : prefix;

  const intl = createIntl(language);

  const placeholder = renderPlaceholderFn;
  const highlight = renderHighlightFn;

  if (options.length < 1) {
    return null;
  }

  if (
    options.length > 1 ||
    (options.length === 1 && options[0].type === "date")
  ) {
    if (forApp) {
      return intl.formatMessage(
        {
          defaultMessage:
            "{cleanPrefix}<highlight>for a chosen duration</highlight>",
          id: "jLObml",
        },
        { cleanPrefix, highlight }
      );
    }

    return intl.formatMessage(
      {
        defaultMessage:
          "{cleanPrefix}<highlight>for your chosen duration</highlight>",
        id: "F27pwX",
      },
      { cleanPrefix, highlight }
    );
  }

  const rawInterval = options[0].interval;
  const rawIntervalCount = options[0].intervalCount;

  if (rawInterval === "indefinitely") {
    return intl.formatMessage(
      {
        defaultMessage: "{cleanPrefix}<highlight>indefinitely</highlight>",
        id: "fsAxu+",
      },
      { cleanPrefix, highlight }
    );
  }

  const intervalCount = Number(rawIntervalCount);
  const interval = `${
    rawInterval === "billing_cycle" ? "billing cycle" : rawInterval
  }${intervalCount > 1 || !intervalCount ? "s" : ""}`;

  if (Number(intervalCount) > 0) {
    return intl.formatMessage(
      {
        defaultMessage:
          "{cleanPrefix}for <highlight>{intervalCount, number} {interval}</highlight>",
        id: "BC3Kkl",
      },
      { cleanPrefix, highlight, intervalCount, interval }
    );
  }

  return intl.formatMessage(
    {
      defaultMessage:
        "{cleanPrefix}for <highlight><placeholder>'<number>'</placeholder> {interval}</highlight>",
      id: "65f3fE",
    },
    { cleanPrefix, highlight, placeholder, interval }
  );
}
