import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import tw from "twin.macro";

import {
  ChildSegmentConditionGroupFragment,
  SegmentConditionFragment,
  SegmentConditionGroupEntryFragment,
} from "../../__generated__/graphql";
import {
  getFriendlyValue,
  getOperatorDisplay,
  propertyConfigs,
} from "../../common/segments/lib";

interface SegmentConditionDescriptionProps {
  condition: SegmentConditionFragment;
  active?: boolean;
  className?: string;
  onRemove?: () => void;
}

const BooleanOperator = tw.div`uppercase font-bold last:hidden my-2`;

const ChildGroup: React.FunctionComponent<{
  group: ChildSegmentConditionGroupFragment;
}> = ({ group }) => (
  <div tw="flex">
    <div tw="w-3 border-l border-t border-b border-divider"></div>
    <div tw="py-4 ml-1">
      {group.segment_condition_group_entries.map((e) => (
        <React.Fragment key={e.entry_segment_condition?.id}>
          <Entry entry={e} />
          <BooleanOperator>{group.boolean_operator}</BooleanOperator>
        </React.Fragment>
      ))}
    </div>
  </div>
);

const LightText = tw.span`text-type-light italic`;
const Value = tw.span`ml-[2px]`;

const Condition: React.FunctionComponent<{
  condition: SegmentConditionFragment;
  active: boolean;
  className?: string;
  onRemove?: () => void;
}> = ({ condition, active, className, onRemove }) => {
  const property =
    condition.property !== "custom_property"
      ? propertyConfigs[condition.property]
      : undefined;

  const group =
    property?.entity === "subscriber" || condition.custom_property?.entity
      ? "Subscriber"
      : property?.entity === "subscription" || condition.custom_property?.entity
      ? "Subscription"
      : null;

  const name = property?.name ? property.name : condition.custom_property?.name;

  const description = (() => {
    if (condition.operator === "within_last_days") {
      return (
        <>
          <LightText>is within the last</LightText>{" "}
          <Value>{condition.value}</Value> <LightText>days</LightText>
        </>
      );
    }

    if (condition.operator === "more_than_days_ago") {
      return (
        <>
          <LightText>is more than</LightText> <Value>{condition.value}</Value>{" "}
          <LightText>days ago</LightText>
        </>
      );
    }

    if (condition.operator === "within_next_days") {
      return (
        <>
          <LightText>is within the next</LightText>{" "}
          <Value>{condition.value}</Value> <LightText>days</LightText>
        </>
      );
    }

    if (condition.operator === "more_than_days_from_now") {
      return (
        <>
          <LightText>is more than</LightText> <Value>{condition.value}</Value>{" "}
          <LightText>days from now</LightText>
        </>
      );
    }

    return (
      <>
        <span css={active ? tw`italic text-white` : tw`italic text-type-light`}>
          {getOperatorDisplay(condition.property, condition.operator)}
        </span>{" "}
        <Value>
          {getFriendlyValue(
            condition.property,
            condition.value,
            condition.operator === "in" || condition.operator === "nin",
            condition.custom_property
          )}
        </Value>
      </>
    );
  })();

  return (
    <div
      tw="flex items-center"
      css={
        active
          ? tw`bg-purple-600 text-white px-2 rounded`
          : tw`bg-gray-200 px-2 rounded`
      }
      className={className}
    >
      <div tw="leading-[1.2rem] py-1">
        <span>
          <strong>{group}</strong> - {name || ""} {description}{" "}
        </span>
      </div>
      {onRemove && (
        <div tw="ml-auto">
          <button
            css={[
              tw`ml-2 text-gray-500 hover:text-gray-800`,
              active && tw`text-white hover:text-white`,
            ]}
            type="button"
            onClick={onRemove}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
      )}
    </div>
  );
};

const Entry: React.FunctionComponent<{
  entry: SegmentConditionGroupEntryFragment;
  active?: boolean;
}> = ({ entry, active = false }) => {
  if (entry.entry_segment_condition_group) {
    return <ChildGroup group={entry.entry_segment_condition_group} />;
  }

  if (entry.entry_segment_condition) {
    return (
      <Condition condition={entry.entry_segment_condition} active={active} />
    );
  }

  return null;
};

const SegmentConditionDescription: React.FunctionComponent<
  SegmentConditionDescriptionProps
> = ({ condition, active = false, className, onRemove }) => (
  <Condition
    condition={condition}
    active={active}
    className={className}
    onRemove={onRemove}
  />
);

export default SegmentConditionDescription;
