import { CampaignTextFragment } from "../../../../__generated__/graphql";
import getCampaignObjectVersion from "../../../../common/campaign/getCampaignObjectVersion";
import { CampaignVersion } from "../../../../common/campaign/types";
import { defaultTranslationMap } from "../../flow/lib/mapFlowText";
import { CampaignText } from "./types";

export default function mapCampaignText(
  texts: CampaignTextFragment[],
  version: CampaignVersion
) {
  return texts.reduce<CampaignText>((prev, text) => {
    const textVersion = getCampaignObjectVersion(text, version);

    prev[text.key] = textVersion.translation;
    return prev;
  }, defaultTranslationMap);
}
