import { ELEMENT_PARAGRAPH } from "@udecode/slate-plugins";
import { Editor, Node, Transforms } from "slate";

export default function withTrailingParagraphs<T extends Editor>(editor: T) {
  const { normalizeNode } = editor;

  editor.normalizeNode = (entry) => {
    const [node, path] = entry;

    if (!path.length && Node.isNode(node)) {
      const children: any = node.children;
      const lastChild = children[children.length - 1];

      if (!lastChild || lastChild.type !== ELEMENT_PARAGRAPH) {
        Transforms.insertNodes(
          editor,
          {
            type: ELEMENT_PARAGRAPH,
            children: [{ text: "" }],
          },
          { at: [children.length] }
        );
        return;
      }
    }

    normalizeNode(entry);
  };

  return editor;
}
