import {
  FlowOfferRulesQuery,
  FlowQuestionFragment,
} from "../../../../__generated__/graphql";
import Modal, { ModalProps } from "../../../../common/modal/Modal";
import ModalClose from "../../../../common/modal/ModalClose";
import ModalHeader from "../../../../common/modal/ModalHeader";
import Spinner from "../../../../common/Spinner";
import { RuleManager } from "../../../flow/edit/ruleActions";
import { FlowText } from "../lib/types";
import OfferRules from "./OfferRules";

type OfferRulesModalProps = ModalProps & {
  onClose: () => void;
  offerRuleGroupId: number;
  offerRules?: FlowOfferRulesQuery | null;
  flowId: number;
  questions: FlowQuestionFragment[];
  manager: RuleManager;
  flowText: FlowText;
  excludeOfferGroups?: boolean;
};

const OfferRulesModal: React.FunctionComponent<OfferRulesModalProps> = ({
  onClose,
  offerRuleGroupId,
  offerRules,
  flowId,
  questions,
  manager,
  flowText,
  excludeOfferGroups = false,
  ...props
}) => (
  <Modal {...props} size="2xl">
    <ModalHeader>
      Configure offer rules
      <ModalClose onClose={onClose} />
    </ModalHeader>

    {!offerRules ? (
      <Spinner />
    ) : (
      <OfferRules
        tw="bg-white"
        offerRuleGroupId={offerRuleGroupId}
        offerRules={offerRules}
        flowId={flowId}
        questions={questions}
        manager={manager}
        showQuestions={false}
        flowText={flowText}
        excludeOfferGroups={excludeOfferGroups}
      />
    )}
  </Modal>
);

export default OfferRulesModal;
