import { language_enum, TranslationFragment } from "../__generated__/graphql";
import isSlateContentEmpty from "./editor/lib/isContentEmpty";
import { isContentEmpty as isLexicalContentEmpty } from "./editor2/lib";
import translationValue from "./translationValue";

export default function isTranslationContentEmpty(
  translation: TranslationFragment,
  language: language_enum,
  default_language: language_enum
) {
  const textTranslationValue = translationValue(
    translation,
    language,
    default_language
  );

  const value =
    textTranslationValue.format &&
    ["lexical", "slate"].includes(textTranslationValue.format) &&
    typeof textTranslationValue.value === "string"
      ? JSON.parse(textTranslationValue.value)
      : textTranslationValue.value;

  switch (textTranslationValue.format) {
    case "lexical":
      return isLexicalContentEmpty(value);

    case "slate":
      return isSlateContentEmpty(value);

    case "text":
    default:
      return !value || value === "";
  }
}
