import { gql, useQuery } from "@apollo/client";

import { MultipleProductIdsDropdownQuery } from "../../__generated__/graphql";
import MultipleIdsDropdown from "./MultipleIdsDropdown";

interface MultipleProductIdsDropdownProps {
  value: string[];
  width?: string;
  placeholder?: string;
  onChange: (value: string[]) => void;
}

const MultipleProductIdsDropdown: React.FunctionComponent<
  MultipleProductIdsDropdownProps
> = ({ value, width, placeholder = "Select a product…", onChange }) => {
  const { data, loading } = useQuery<MultipleProductIdsDropdownQuery>(gql`
    query MultipleProductIdsDropdownQuery {
      platform_product {
        platform_id
        name
      }
    }
  `);

  const ids = (data?.platform_product || []).map((product) => ({
    id: product.platform_id,
    label: product.name,
  }));

  return (
    <MultipleIdsDropdown
      ids={ids}
      value={value}
      onChange={onChange}
      isLoading={loading}
      width={width}
      placeholder={placeholder}
    />
  );
};

export default MultipleProductIdsDropdown;
