import { gql, useQuery } from "@apollo/client";
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import {
  InsertPropertyTagModalQuery,
  segment_condition_property_enum,
} from "../../__generated__/graphql";
import groupSegmentProperties from "../../features/segments/lib/groupSegmentProperties";
import SegmentPropertyDropdown from "../../features/segments/SegmentPropertyDropdown";
import Button from "../form/Button";
import FieldHint from "../form/FieldHint";
import FieldInput from "../form/FieldInput";
import FieldLabel from "../form/FieldLabel";
import FieldRow from "../form/FieldRow";
import SelectInput from "../form/input/SelectInput";
import TextInput from "../form/input/TextInput";
import TheAppPropertyFragment from "../fragments/AppPropertyFragment";
import Modal, { ModalProps } from "../modal/Modal";
import ModalFooter from "../modal/ModalFooter";
import ModalFormBody from "../modal/ModalFormBody";
import ModalHeader from "../modal/ModalHeader";
import PillRadio from "../PillRadio";
import { getPropertiesFilteredForPlatform } from "../segments/lib";
import Spinner from "../Spinner";
import useFocusFirstEmptyInput from "../useFocusFirstEmptyInput";
import useLoading from "../useLoading";
import usePrevious from "../usePrevious";
import useViewer from "../useViewer";
import {
  PROPERTY_TAG_CUSTOM_PROPERTY,
  PROPERTY_TAG_SEGMENT_PROPERTY,
  PropertyTag,
} from "./lib/PropertyTagPlugin/types";

export interface InsertPropertTagFormValues {
  tag: PropertyTag;
  propertyId?: string;
  dateFormat?: string;
  fallback: string;
  numberFormat?: string;
  isArray?: boolean;
}

interface InterfacePropertyTagModalProps extends ModalProps {
  mode: "insert" | "edit";
  tag?: PropertyTag;
  initialValues?: InsertPropertTagFormValues;
  onClose: (
    values: InsertPropertTagFormValues | undefined,
    tag?: PropertyTag
  ) => void;
  propertyTags?: Array<PropertyTag>;
}

const InsertPropertyTagModal: React.FunctionComponent<
  InterfacePropertyTagModalProps
> = ({ mode, tag, onClose, initialValues, propertyTags = [], ...props }) => {
  const { viewer } = useViewer();

  const { register, handleSubmit, control, setValue, watch } =
    useForm<InsertPropertTagFormValues>({
      defaultValues: initialValues,
    });

  const numberFormat = watch("numberFormat");

  const [formRef, setFormRef] = useState<HTMLFormElement | null>(null);
  useFocusFirstEmptyInput(formRef);

  const [selectedPropertyId, setSelectedPropertyId] = useState<
    segment_condition_property_enum | number | undefined
  >();

  const { data } = useQuery<InsertPropertyTagModalQuery>(
    gql`
      query InsertPropertyTagModalQuery {
        property(where: { deleted_at: { _is_null: true } }) {
          ...AppPropertyFragment
        }
      }
      ${TheAppPropertyFragment}
    `,
    { fetchPolicy: "cache-and-network" }
  );

  const customProperties = useMemo(
    () => data?.property || [],
    [data?.property]
  );

  const platform = viewer?.account?.platform_connection?.platform;

  const platformProperties = useMemo(
    () => (platform ? getPropertiesFilteredForPlatform(platform) : []),
    [platform]
  );

  const segmentProperties = useMemo(
    () => groupSegmentProperties(platformProperties),
    [platformProperties]
  );

  const onSubmit = handleSubmit((values) => {
    const tag = selectedPropertyId
      ? customProperties.find((p) => p.id === selectedPropertyId)
        ? PROPERTY_TAG_CUSTOM_PROPERTY
        : platformProperties.find((p) => p.id === selectedPropertyId)
        ? PROPERTY_TAG_SEGMENT_PROPERTY
        : undefined
      : undefined;

    onClose(values, tag);
  });

  useEffect(() => {
    if (selectedPropertyId || !platformProperties.length) {
      return;
    }

    setSelectedPropertyId(platformProperties[0].id);
    setValue("propertyId", platformProperties[0].id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customProperties, platformProperties, selectedPropertyId]);

  const previouslySelectedPropertyId = usePrevious(selectedPropertyId);

  useEffect(() => {
    if (
      !selectedPropertyId ||
      !setValue ||
      selectedPropertyId === previouslySelectedPropertyId
    ) {
      return;
    }

    const platformProperty = platformProperties.find(
      (p) => p.id === selectedPropertyId
    );

    if (platformProperty) {
      setSelectedPropertyId(platformProperty.id);
      setValue("isArray", platformProperty.array || undefined);
      return;
    }

    const customProperty = customProperties.find(
      (p) => p.id === selectedPropertyId
    );

    if (customProperty) {
      setSelectedPropertyId(customProperty.id);
      setValue("isArray", undefined);
      return;
    }
  }, [
    selectedPropertyId,
    platformProperties,
    customProperties,
    setValue,
    previouslySelectedPropertyId,
  ]);

  const selectedProperty = selectedPropertyId
    ? platformProperties.find((p) => p.id === selectedPropertyId) ||
      customProperties.find((p) => p.id === selectedPropertyId)
    : null;

  const loading = useLoading(!selectedPropertyId);

  return (
    <Modal {...props}>
      <ModalHeader>
        {mode === "insert" ? "Insert property tag" : "Edit property tag"}
      </ModalHeader>
      {loading || !selectedPropertyId ? (
        <Spinner />
      ) : (
        <ModalFormBody>
          <form
            id="insert-property-tag"
            onSubmit={onSubmit}
            ref={(ref) => setFormRef(ref)}
          >
            <FieldRow>
              <FieldLabel>Tag</FieldLabel>
              <FieldInput>
                <Controller
                  name="propertyId"
                  control={control}
                  render={({ field }) => (
                    <SegmentPropertyDropdown
                      groups={segmentProperties}
                      properties={customProperties}
                      value={selectedPropertyId}
                      onChange={(
                        id: number | segment_condition_property_enum
                      ) => {
                        field.onChange(id.toString());
                        setSelectedPropertyId(id);
                      }}
                    />
                  )}
                />
              </FieldInput>
            </FieldRow>
            {selectedProperty?.type === "date" && (
              <FieldRow>
                <FieldLabel>
                  <label>Date format</label>
                </FieldLabel>
                <FieldInput>
                  <SelectInput {...register("dateFormat")} width="lg">
                    <option value="MMMM d, yyyy">
                      Full (e.g. February 4, 2007)
                    </option>
                    <option value="M/d/yy">M/D/Y</option>
                    <option value="d/M/yy">D/M/Y</option>
                  </SelectInput>
                </FieldInput>
              </FieldRow>
            )}
            {selectedProperty?.type === "number" && (
              <FieldRow>
                <FieldLabel>
                  <label>Number format</label>
                </FieldLabel>
                <FieldInput>
                  <Controller
                    control={control}
                    name="numberFormat"
                    render={({ field }) => (
                      <PillRadio
                        tw="leading-none mt-2"
                        value={field.value || "unformatted"}
                        onChange={field.onChange}
                        options={[
                          {
                            label: "Unformatted",
                            value: "unformatted",
                          },
                          {
                            label: "Formatted",
                            value: "number",
                          },
                          {
                            label: "Currency",
                            value: "currency",
                          },
                        ]}
                      />
                    )}
                  />
                  <FieldHint tw="mt-2">
                    {numberFormat === "unformatted"
                      ? "Displayed without any formatting, e.g. 100000."
                      : numberFormat === "number"
                      ? "Displayed as a formatted number, e.g. 100,000."
                      : numberFormat === "currency"
                      ? "Displayed as a currency, e.g. $100,000."
                      : null}
                  </FieldHint>
                </FieldInput>
              </FieldRow>
            )}
            <FieldRow>
              <FieldLabel>
                <label>Fallback</label>
              </FieldLabel>
              <FieldInput>
                <TextInput {...register("fallback")} />
                <FieldHint>
                  Fallback value used if the property is not set.
                </FieldHint>
              </FieldInput>
            </FieldRow>
          </form>
        </ModalFormBody>
      )}
      <ModalFooter>
        <Button
          type="button"
          buttonType="primary"
          form="insert-property-tag"
          onClick={onSubmit}
          disabled={loading}
        >
          {mode === "insert" ? "Insert tag" : "Save"}
        </Button>
        <Button
          buttonType="default"
          onMouseDown={(e) => {
            e.preventDefault();
            onClose(undefined);
          }}
          disabled={loading}
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default InsertPropertyTagModal;
