import tw from "twin.macro";

const Wrapper = tw.div`flex-shrink-0 flex items-center`;

const PanelTitle: React.FunctionComponent = ({ children }) => (
  <Wrapper>
    <h3>{children}</h3>
  </Wrapper>
);

export default PanelTitle;
