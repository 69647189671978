import tw from "twin.macro";

const Placeholder = tw.span`text-type-light`;

export default function getTrialExtensionDescription(
  days: number | null | undefined
) {
  return (
    <span className="highlight">
      {Number(days) > 0 ? days : <Placeholder>&lt;days&gt;</Placeholder>}{" "}
      {Number(days) === 1 ? "day" : "days"}
    </span>
  );
}
