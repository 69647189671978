import gql from "graphql-tag";

export default gql`
  fragment FlowHasUnsavedChangesOfferRuleGroupFragment on offer_rule_group {
    id
    published_version {
      id
      offer_rule_group_version_offer_rules {
        offer_rule {
          id
          draft_version {
            id
          }
        }
      }
    }
    draft_version {
      id
    }
  }
`;
