import { useLayoutEffect } from "react";
import { useContext } from "react";

import { Context } from "./UpsellProvider";

export const useUpsellBanner = (
  featureText?: string,
  freeModeOverride: boolean = false
) => {
  const context = useContext(Context);
  if (!context) {
    throw new Error(
      "useUpsellBanner() not called within UpsellProvider context"
    );
  }

  useLayoutEffect(
    () => {
      if ((context.enabled || freeModeOverride) && !!featureText) {
        context.setBannerFeatureText(featureText);
        context.setBannerIsVisible(true);
      }

      return () => {
        context.setBannerFeatureText("");
        context.setBannerIsVisible(false);
      };
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [context.enabled, context.bannerFeatureText]
  );

  return context;
};
