import { EditorThemeClasses } from "lexical";

export const editorTheme: EditorThemeClasses = {
  heading: {
    h1: "editor__h1",
    h2: "editor__h2",
    h3: "editor__h3",
    h4: "editor__h4",
    h5: "editor__h5",
  },
  image: "editor__image",
  video: "editor__video",
  link: "editor__link",
  list: {
    listitem: "editor__listItem",
    nested: {
      listitem: "editor__nestedListItem",
    },
    olDepth: [
      "editor__ol1",
      "editor__ol2",
      "editor__ol3",
      "editor__ol4",
      "editor__ol5",
    ],
    ul: "editor__ul",
  } as any,
  ltr: "editor__ltr",
  paragraph: "editor__paragraph",
  quote: "editor__quote",
  rtl: "editor__rtl",
  table: "editor__table",
  tableCell: "editor__tableCell",
  tableCellHeader: "editor__tableCellHeader",
  text: {
    bold: "editor__textBold",
    code: "editor__textCode",
    italic: "editor__textItalic",
    strikethrough: "editor__textStrikethrough",
    underline: "editor__textUnderline",
    underlineStrikethrough: "editor__textUnderlineStrikethrough",
  },
};
