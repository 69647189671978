import {
  FlowQuestionFragment,
  question_type_enum,
} from "../../../../__generated__/graphql";
import getFlowObjectVersion from "../../../../common/flow/getFlowObjectVersion";
import { FlowVersion } from "../../../../common/flow/types";
import { QuestionAnswer } from "./types";

const sanitizeAndFormatQuestionAnswers = (
  questions: FlowQuestionFragment[],
  questionAnswers: QuestionAnswer[],
  flowVersion: FlowVersion
): QuestionAnswer[] =>
  questions.map((question) => {
    const id = question.id;

    const questionVersion = getFlowObjectVersion(question, flowVersion);

    const versionId = questionVersion.id;
    const answer = questionAnswers.find((answer) => answer.id === id);
    if (answer?.value) {
      return answer;
    }

    switch (question.type) {
      case question_type_enum.text:
        return {
          id,
          versionId,
          type: question_type_enum.text,
          value: "",
        };
      case question_type_enum.radio:
        return {
          id,
          versionId,
          type: question_type_enum.radio,
          value: [],
        };
      case question_type_enum.likelihood_to_return:
        return {
          id,
          versionId,
          type: question_type_enum.likelihood_to_return,
          value: "",
        };
      default:
        throw new Error("Unsupported question type");
    }
  });

export default sanitizeAndFormatQuestionAnswers;
