import { QueryResult, useQuery } from "@apollo/client";
import gql from "graphql-tag";

import {
  SubscriberCampaignQuery,
  SubscriberCampaignQueryVariables,
} from "../../__generated__/graphql";
import CampaignTextFragment from "../fragments/CampaignTextFragment";
import CampaignVersionFragment from "../fragments/CampaignVersionFragment";

export const TheSubscriberCampaignQuery = gql`
  query SubscriberCampaignQuery($token: String!) {
    subscriber_campaign(where: { token: { _eq: $token } }) {
      id
      token
      version
      campaign {
        id
        title
        account {
          id
          title
          properties {
            id
            name
            type
            format
          }
        }
        campaign_texts {
          ...CampaignTextFragment
        }
        default_language
        published_version {
          ...CampaignVersionFragment
        }
      }
      subscriber {
        name
        email
        subscriber_properties {
          subscriber_id
          property_id
          value
          property {
            id
            name
            type
            format
          }
        }
      }
      subscription {
        subscription_properties {
          subscription_id
          property_id
          value
          property {
            id
            name
            type
            format
          }
        }
      }
      subscriber_campaign_offers {
        offer {
          id
        }
        first_view: subscriber_campaign_views_aggregate {
          aggregate {
            min {
              created_at
            }
          }
        }
      }
    }
  }
  ${CampaignVersionFragment}
  ${CampaignTextFragment}
`;

export default function useSubscriberCampaignByToken(
  token: string
): QueryResult<SubscriberCampaignQuery, SubscriberCampaignQueryVariables> {
  return useQuery<SubscriberCampaignQuery, SubscriberCampaignQueryVariables>(
    TheSubscriberCampaignQuery,
    {
      variables: { token },
    }
  );
}
