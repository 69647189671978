export default function validateMetadata(value: string) {
  const records: Array<{ key: string; value: string }> = JSON.parse(value);

  const keys: string[] = [];

  for (const r of records) {
    if (keys.includes(r.key)) {
      return "Metadata cannot have duplicate keys.";
    }
    keys.push(r.key);
  }

  if (records.find((r) => !!r.value && !r.key)) {
    return "Metadata key cannot be blank.";
  }
  if (records.find((r) => !!r.key && !r.value)) {
    return "Metadata value cannot be blank.";
  }
}
