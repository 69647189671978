import { nanoid } from "nanoid";
import { useState } from "react";

import {
  language_enum,
  question_type_enum,
  TranslationFragment,
} from "../../../__generated__/graphql";
import { TranslatedForms } from "../../../common/form/useTranslatableForm";
import AddQuestionModal, { FormValues } from "./AddQuestionModal";
import EditableTextWrapper from "./EditableTextWrapper";

interface EditableQuestionTitleProps {
  isEditable: boolean;
  type: question_type_enum;
  language: language_enum;
  enabledLanguages: language_enum[];
  defaultLanguage: language_enum;
  titleTranslation: TranslationFragment;
  hintTranslation: TranslationFragment;
  placeholderTranslation: TranslationFragment | null | undefined;
  onSave: (forms: TranslatedForms<FormValues>) => Promise<void>;
}

const EditableQuestionTitle: React.FunctionComponent<
  EditableQuestionTitleProps
> = ({
  isEditable,
  type,
  language,
  enabledLanguages,
  defaultLanguage,
  titleTranslation,
  hintTranslation,
  placeholderTranslation,
  onSave,
  children,
}) => {
  const [editing, setEditing] = useState(false);
  const [modalKey, setModalKey] = useState(nanoid());

  return (
    <>
      <AddQuestionModal
        key={modalKey}
        mode="edit"
        language={language}
        enabledLanguages={enabledLanguages}
        defaultLanguage={defaultLanguage}
        type={type}
        isOpen={editing}
        onSave={onSave}
        onClose={() => {
          setEditing(false);
          setModalKey(nanoid());
        }}
        titleTranslation={titleTranslation}
        hintTranslation={hintTranslation}
        placeholderTranslation={placeholderTranslation}
      />
      <EditableTextWrapper
        isEditable={isEditable}
        onEdit={() => setEditing(true)}
      >
        <span>{children}</span>
      </EditableTextWrapper>
    </>
  );
};

export default EditableQuestionTitle;
