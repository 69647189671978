import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  $getSelection,
  $isRangeSelection,
  $isRootNode,
  COMMAND_PRIORITY_EDITOR,
  createCommand,
} from "lexical";
import { useEffect } from "react";

import {
  $createOfferDescriptionNode,
  OfferDescriptionNode,
} from "../nodes/OfferDescriptionNode";

export const INSERT_OFFER_DESCRIPTION_COMMAND = createCommand();

const OfferDescriptionPlugin: React.FunctionComponent = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([OfferDescriptionNode])) {
      throw new Error(
        "OfferDescriptionPlugin: OfferDescriptionNode not registered on editor"
      );
    }

    const removeListener = editor.registerCommand(
      INSERT_OFFER_DESCRIPTION_COMMAND,
      () => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          if ($isRootNode(selection.anchor.getNode())) {
            selection.insertParagraph();
          }
          const offerDescriptionNode = $createOfferDescriptionNode();
          selection.insertNodes([offerDescriptionNode]);
        }
        return true;
      },
      COMMAND_PRIORITY_EDITOR
    );

    return () => {
      removeListener();
    };
  }, [editor]);

  return null;
};

export default OfferDescriptionPlugin;
