import { useEffect, useState } from "react";
import { isPresent } from "ts-is-present";
import tw, { css, theme } from "twin.macro";

import {
  FlowDeflectionFragment,
  FlowDeflectionSnapshotItemFragment,
} from "../../../../__generated__/graphql";
import formatPropertyValue from "../../../../common/properties/formatPropertyValue";
import { useTranslations } from "../../../../common/translations/TranslationsProvider";
import { usePropertyValues } from "../../../properties/lib/propertyValues";
import FlowIcon from "../FlowIcon";
import renderDeflectionContent from "../lib/renderDeflectionContent";
import StepTitle from "../ui/StepTitle";
import DeflectionActions from "./deflections/DeflectionActions";

interface DeflectionProps {
  deflection: FlowDeflectionFragment;
  disableVideoAutoPlay: boolean;
  previewMode: boolean;
  onDeflect: (deflectionId: number, flowActionId: number) => void;
}

const Deflection: React.FunctionComponent<DeflectionProps> = ({
  deflection,
  disableVideoAutoPlay,
  previewMode,
  onDeflect,
}) => {
  const { language, defaultLanguage } = useTranslations();
  const { propertyValues, propertyConfig, showPlaceholders } =
    usePropertyValues();

  const items = deflection.deflection_snapshot_items
    .map((i) => i.deflection_snapshot_item)
    .filter(isPresent);

  const [itemIds, setItemIds] = useState(
    (items || []).map((i) => i.id.toString())
  );

  useEffect(() => {
    const newItemIds = items.map((i) => i.id.toString());

    if (newItemIds.length !== itemIds.length) {
      setItemIds(items.map((i) => i.id.toString()));
    }
  }, [itemIds.length, items]);

  const renderPropertyValue = (item: FlowDeflectionSnapshotItemFragment) => {
    if (!item.property) {
      return null;
    }

    const value = propertyValues[item.property.id];

    if (typeof value === "undefined") {
      return null;
    }

    if (
      item.property.type === "number" &&
      typeof value === "number" &&
      typeof item.property_condition_value === "number"
    ) {
      if (value <= item.property_condition_value) {
        return null;
      }
    }

    return formatPropertyValue(
      item.property.type,
      value,
      item.property.format,
      item.date_format || "MMM. d, yyyy"
    ).toString();
  };

  return (
    <div
      className="flow-step__deflection"
      css={[
        previewMode &&
          tw`relative py-8 px-1 mx-8 overscroll-none overflow-clip`,
      ]}
    >
      <StepTitle>
        {renderDeflectionContent({
          translation: deflection.heading_translation,
          placeholderText: "Heading",
          previewMode,
          baseFontSize: "1.5rem",
          language,
          defaultLanguage,
          propertyValues,
          propertyConfig,
          showPropertyPlaceholders: showPlaceholders,
          disableVideoAutoPlay,
        })}
      </StepTitle>
      <div className="flow-step__content">
        {renderDeflectionContent({
          translation: deflection.content_translation,
          placeholderText: "Content",
          previewMode,
          language,
          defaultLanguage,
          propertyValues,
          propertyConfig,
          showPropertyPlaceholders: showPlaceholders,
          disableVideoAutoPlay,
        })}
      </div>
      {!!items.length && (
        <div className="flow-step__deflection-grid">
          {itemIds.map((itemId) => {
            const item = items.find((i) => i.id.toString() === itemId);

            if (!item) {
              return null;
            }

            const renderedValue = renderPropertyValue(item);
            if (!previewMode && !!item.property_id && renderedValue === null) {
              return null;
            }

            return (
              <div key={itemId} className="flow-step__deflection-grid__item">
                {item.icon_prefix && item.icon_name && (
                  <div
                    tw="rounded-full flex items-center justify-center bg-blue-50"
                    css={css`
                      min-width: 70px;
                      height: 70px;
                    `}
                  >
                    <FlowIcon
                      prefix={item.icon_prefix}
                      name={item.icon_name}
                      color={theme`colors.blue.DEFAULT`}
                      css={css`
                        width: 40px;
                        height: 40px;
                      `}
                    />
                  </div>
                )}
                <div tw="ml-4">
                  {!!item.property_id && previewMode ? (
                    <div
                      tw="font-semibold text-blue-500 truncate"
                      css={css`
                        max-width: 165px;
                      `}
                    >
                      &lt;{item.property?.name}&gt;
                    </div>
                  ) : (
                    <div
                      css={[
                        css`
                          line-height: 2rem;
                        `,
                        tw`font-semibold text-blue-500`,
                        item.property?.type === "date"
                          ? tw`text-2xl`
                          : tw`text-3xl`,
                      ]}
                    >
                      {renderedValue}
                    </div>
                  )}

                  <div>
                    {renderDeflectionContent({
                      translation: item.text_translation,
                      placeholderText: "Snapshot text",
                      previewMode,
                      language,
                      defaultLanguage,
                      propertyValues,
                      propertyConfig,
                      showPropertyPlaceholders: showPlaceholders,
                      disableVideoAutoPlay,
                    })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
      <DeflectionActions
        deflection={deflection}
        onClick={onDeflect}
        previewMode={previewMode}
      />
    </div>
  );
};

export default Deflection;
