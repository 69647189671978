import gql from "graphql-tag";

import TranslationFragment from "./TranslationFragment";

export default gql`
  fragment FlowOfferPauseSubscriptionOptionFragment on offer_pause_subscription_option {
    type
    interval
    interval_count
    text_translation {
      ...TranslationFragment
    }
  }
  ${TranslationFragment}
`;
