import { useApolloClient, useMutation } from "@apollo/client";
import gql from "graphql-tag";

import {
  CreateCouponOfferOfferMutation,
  CreateCouponOfferOfferMutationVariables,
  CreateCouponOfferOfferQuery,
  CreateCouponOfferOfferQueryVariables,
  CreateCouponOfferProductGroupsQuery,
  offer_autopilot_insert_input,
  offer_coupon_insert_input,
  offer_insert_input,
  platform_enum,
} from "../../../__generated__/graphql";
import AppOfferFragment from "../../../common/fragments/AppOfferFragment";
import updateCache from "./updateCache";

export default function useCreateCouponOffer() {
  const apollo = useApolloClient();

  const [createOffer] = useMutation<
    CreateCouponOfferOfferMutation,
    CreateCouponOfferOfferMutationVariables
  >(gql`
    mutation CreateCouponOfferOfferMutation($object: offer_insert_input!) {
      insert_offer_one(object: $object) {
        ...AppOfferFragment
      }
    }
    ${AppOfferFragment}
  `);

  return async (
    platform: platform_enum,
    offer: offer_insert_input,
    coupon: offer_coupon_insert_input,
    autopilot?: offer_autopilot_insert_input
  ) => {
    const coupons: offer_coupon_insert_input[] = [];

    if (platform === "chargify") {
      const productGroupsResult =
        await apollo.query<CreateCouponOfferProductGroupsQuery>({
          query: gql`
            query CreateCouponOfferProductGroupsQuery {
              platform_product_group(where: { archived: { _eq: false } }) {
                platform_id
              }
            }
          `,
        });

      if (!productGroupsResult.data?.platform_product_group.length) {
        throw new Error("Error retrieving products");
      }

      for (const productGroup of productGroupsResult.data
        .platform_product_group) {
        coupons.push({
          ...coupon,
          platform_product_group_id: productGroup.platform_id,
        });
      }
    } else {
      coupons.push(coupon);
    }

    const createOfferResult = await createOffer({
      variables: {
        object: {
          ...offer,
          offer_coupons: { data: coupons },
          ...(!!autopilot && { offer_autopilot: { data: autopilot } }),
        },
      },
    });
    if (!createOfferResult.data?.insert_offer_one?.id) {
      throw new Error("Error creating offer");
    }

    const offerResult = await apollo.query<
      CreateCouponOfferOfferQuery,
      CreateCouponOfferOfferQueryVariables
    >({
      query: gql`
        query CreateCouponOfferOfferQuery($id: Int!) {
          offer_by_pk(id: $id) {
            ...AppOfferFragment
          }
        }
        ${AppOfferFragment}
      `,
      variables: {
        id: createOfferResult.data.insert_offer_one.id,
      },
    });

    if (!offerResult.data?.offer_by_pk) {
      throw new Error("Error retrieving new offer");
    }

    updateCache(apollo.cache, offerResult.data.offer_by_pk);

    return offerResult.data.offer_by_pk;
  };
}
