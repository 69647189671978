import classNames from "classnames";
import { range } from "lodash";
import { useEffect, useRef } from "react";
import tw from "twin.macro";

import Button from "../form/Button";

interface CalendarYearViewProps {
  selectedYear: number;
  minYear?: number;
  maxYear?: number;
  onSelectYear: (year: number) => void;
  onCancel: () => void;
}

const CalendarYearView: React.FunctionComponent<CalendarYearViewProps> = ({
  selectedYear,
  minYear,
  maxYear,
  onSelectYear,
  onCancel,
}) => {
  const scrollableRef = useRef<HTMLDivElement>(null);
  const currentYearRef = useRef<HTMLDivElement>(null);

  const start = selectedYear - 200;
  const end = selectedYear + 200;

  useEffect(() => {
    if (!scrollableRef.current || !currentYearRef.current) {
      return;
    }

    scrollableRef.current.scrollTo({
      top: currentYearRef.current.offsetTop - 200,
    });
  }, []);

  return (
    <div>
      <div
        tw="w-[374px] h-[283px] p-2 overflow-y-auto grid grid-cols-4 gap-1"
        ref={scrollableRef}
      >
        {range(start, end).map((year) => (
          <div
            key={year}
            role="button"
            css={[
              tw`p-2 rounded-full text-center hover:bg-gray-100 active:bg-blue active:text-white`,
              year === selectedYear && tw`bg-blue text-white hover:bg-blue`,
              ((!!minYear && year < minYear) ||
                (!!maxYear && year > maxYear)) &&
                tw`text-gray-300 pointer-events-none`,
            ]}
            ref={year === selectedYear ? currentYearRef : undefined}
            onClick={() => onSelectYear(year)}
            className={classNames({
              calendar__grid__month: true,
              active: year === selectedYear,
            })}
          >
            {year}
          </div>
        ))}
      </div>
      <div tw="flex border-t border-divider p-4">
        <div tw="ml-auto">
          <Button
            type="button"
            buttonType="transparent-secondary-no-border"
            onClick={onCancel}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CalendarYearView;
