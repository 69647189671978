import gql from "graphql-tag";

import FlowDeflectionRuleGroupVersionFragment from "./FlowDeflectionRuleGroupVersionFragment";

export default gql`
  fragment FlowDeflectionRuleGroupFragment on offer_rule_group {
    id
    title
    published_version {
      ...FlowDeflectionRuleGroupVersionFragment
    }
    draft_version @include(if: $includeDrafts) {
      ...FlowDeflectionRuleGroupVersionFragment
    }
  }
  ${FlowDeflectionRuleGroupVersionFragment}
`;
