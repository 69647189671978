import gql from "graphql-tag";

import FlowAcknowledgementVersionFragment from "./FlowAcknowledgementVersionFragment";

export default gql`
  fragment FlowAcknowledgementFragment on acknowledgement {
    id
    title
    published_version {
      ...FlowAcknowledgementVersionFragment
    }
    draft_version @include(if: $includeDrafts) {
      ...FlowAcknowledgementVersionFragment
    }
  }
  ${FlowAcknowledgementVersionFragment}
`;
