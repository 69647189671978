import { FieldError as FieldErrorType } from "react-hook-form";
import tw from "twin.macro";

interface FieldErrorProps {
  error: FieldErrorType | undefined;
  className?: string;
}

const ErrorMessage = tw.div`pt-1 text-red-500 leading-normal`;

const FieldError: React.FunctionComponent<FieldErrorProps> = ({
  error,
  className,
}) =>
  error && error.message ? (
    <ErrorMessage className={className}>{error.message}</ErrorMessage>
  ) : null;

export default FieldError;
