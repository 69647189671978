import { UnreachableCaseError } from "ts-essentials";

import { offer_type_enum } from "../../../../__generated__/graphql";

const getTranslatableFields = (offerType: `${offer_type_enum}`): string[] => {
  const defaultFields = ["headline", "content", "buttonText"];

  switch (offerType) {
    case "pause_subscription":
      return [...defaultFields, "option1Text", "option2Text", "option3Text"];

    case "change_plan":
    case "coupon":
    case "custom":
    case "downgrade":
    case "modify_subscription":
    case "product_swap":
    case "reschedule_order":
    case "trial_extension":
    case "upgrade":
      return defaultFields;

    default:
      throw new UnreachableCaseError(offerType);
  }
};

export default getTranslatableFields;
