import { DataProxy } from "@apollo/client";

import {
  AppOfferFragment,
  ExistingOffersQuery,
} from "../../../__generated__/graphql";
import TheExistingOffersQuery from "../../../common/queries/ExistingOffersQuery";

export default function updateCache(cache: DataProxy, offer: AppOfferFragment) {
  const result = cache.readQuery<ExistingOffersQuery>({
    query: TheExistingOffersQuery,
  });
  if (!result?.offer) {
    return;
  }

  cache.writeQuery<ExistingOffersQuery>({
    query: TheExistingOffersQuery,
    data: {
      offer: [...result.offer, offer],
    },
  });
}
