import {
  CustomerPortalSubscriptionDetailsInvoiceFragment,
  platform_enum,
} from "../../../__generated__/graphql";
import Pagination from "../../../common/pagination/Pagination";
import usePagination from "../../../common/usePagination";
import CardHeading from "./CardHeading";
import InvoiceRow from "./InvoiceRow";

interface InvoiceHistoryProps {
  sessionToken: string;
  invoices: CustomerPortalSubscriptionDetailsInvoiceFragment[];
  platform: platform_enum;
}

const InvoiceHistory: React.FunctionComponent<InvoiceHistoryProps> = ({
  sessionToken,
  invoices,
  platform,
}) => {
  const { page, setPage, pages, offset } = usePagination({
    perPage: 10,
    totalCount: invoices.length,
  });

  const thisPageInvoices = invoices.slice(offset, offset + 10);

  const linkType = platform === "paddle" ? "view" : "download";

  return (
    <>
      <CardHeading>Invoice history</CardHeading>
      <div tw="px-2 py-2 border-b border-divider">
        <Pagination current={page} pages={pages} setPage={setPage} />
      </div>
      <div tw="grid grid-cols-[1fr_8rem_6rem_6rem_1rem] border-b border-divider py-2 px-4 font-semibold">
        <div>Number</div>
        <div>Period end</div>
        <div>Status</div>
        <div>Total</div>
        <div></div>
      </div>
      {thisPageInvoices.map((i) => (
        <InvoiceRow
          key={i.platformId}
          sessionToken={sessionToken}
          invoice={i}
          linkType={linkType}
        />
      ))}
      {!invoices.length && <span tw="text-type-light">No invoices.</span>}
    </>
  );
};

export default InvoiceHistory;
