import { faArrowCircleUp } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { nanoid } from "nanoid";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import tw, { styled } from "twin.macro";

import { ReactComponent as UpgradeIcon } from "../../assets/icons/upgrade.svg";
import Button from "../../common/form/Button";

interface UpsellBannerProps {
  className?: string;
  isVisible: boolean;
  featureText?: string;
  onClick: () => void;
  mode?: BannerMode;
  fadeInOverlay?: boolean;
  isNavCollapsed?: boolean;
}

export type BannerMode = "page" | "panel" | "header" | "nav" | "overlay";

const FadeTransition = styled(CSSTransition)<{
  delay: number;
  duration: number;
}>`
  .overlay,
  .overlay-content {
    ${tw`opacity-0`}
  }

  &.fade-appear-active {
    .overlay {
      ${tw`transition-all z-50 opacity-75`}
      transition-delay: ${(props) => props.delay.toString()}ms;
      transition-duration: ${(props) => props.duration.toString()}ms;
    }

    .overlay-content {
      ${tw`transition-all z-50 opacity-100`}
      transition-delay: ${(props) => props.delay.toString()}ms;
      transition-duration: ${(props) => props.duration.toString()}ms;
    }
  }

  &.fade-appear-done,
  &.fade-enter-done {
    .overlay,
    .overlay-content {
      ${tw`!z-50`}
    }

    .overlay {
      ${tw`opacity-75`}
    }

    .overlay-content {
      ${tw`opacity-100`}
    }
  }
`;

type BannerStyleProps = {
  mode: BannerMode;
  collapseX: boolean;
  collapseY: boolean;
};

const Wrapper = styled.div<BannerStyleProps>`
  ${tw`h-full w-full bg-transparent`}
  ${(props) => props.mode === "header" && tw`max-h-[58px]`}
  ${(props) => props.mode === "overlay" && tw`absolute`}
`;

const Container = styled.div<BannerStyleProps>`
  ${tw`flex items-center rounded w-full !z-50`}
  ${(props) => props.mode === "overlay" && tw`absolute h-full justify-center`}
`;

const Overlay = tw.div`absolute w-full h-full bg-gray-50 flex items-center justify-center`;

const Content = styled.div<BannerStyleProps>`
  ${tw`z-10 relative p-5 flex gap-4 justify-center items-center w-full rounded-xl border border-[#fdf0c8]`}
  background: linear-gradient(314deg, #fad35d -284.57%, #fff 100%);
  box-shadow: 0px 4px 40px rgba(188, 156, 58, 0.1);

  ${(props) => props.mode === "panel" && tw`gap-2 p-2.5 rounded-lg`}
  ${(props) =>
    props.mode === "header" && tw`gap-2 p-3 max-h-[58px] rounded-none`}
  ${(props) => props.mode === "nav" && tw`flex-col gap-2 p-5 rounded-lg`}
  ${(props) => props.collapseX && tw`p-2`}
  ${(props) =>
    props.mode === "overlay" &&
    tw`flex-col gap-2 justify-center bg-none border-none shadow-none mb-16`}
      ${(props) => props.collapseY && tw`gap-2 p-3`}
`;

const TextContainer = styled.div<BannerStyleProps>`
  ${tw`flex gap-2 grow justify-between items-center`}

  ${(props) => props.mode === "page" && tw`gap-4`}
  ${(props) => props.mode === "nav" && tw`flex-col`}
  ${(props) => props.mode === "overlay" && tw`flex-col`}
  ${(props) => props.collapseY && tw`flex-row`}
`;

const TextInner = styled.div<BannerStyleProps>`
  ${tw`flex flex-col grow`}
  ${(props) => props.mode === "panel" && tw`text-sm grow`}
  ${(props) =>
    props.mode === "nav"
      ? props.collapseX
        ? tw`hidden font-body`
        : tw`text-sm text-center font-body`
      : undefined}

  ${(props) => props.mode === "header" && tw`text-sm`}
  ${(props) => props.mode === "overlay" && tw`text-center gap-1 mb-1`}
`;

const FeatureText = styled.span<BannerStyleProps>`
  ${tw`font-bold font-title`}
  ${(props) => ["panel", "nav"].includes(props.mode) && tw`text-sm font-body`}
  ${(props) => props.mode === "overlay" && tw`text-xl`}
  ${(props) => props.collapseY && tw`text-left`}
`;

const DetailText = styled.span<BannerStyleProps>`
  ${(props) => props.mode !== "overlay" && tw`text-sm`}
  ${(props) =>
    (["panel", "header"].includes(props.mode) || props.collapseY) && tw`hidden`}
`;

const ButtonGroup = styled.div<BannerStyleProps>`
  ${tw`flex items-center justify-end text-white`}
  ${(props) => props.mode === "nav" && tw`w-full justify-center`}
`;

const UpgradeButton = styled(Button)<{
  bannerStyle: BannerMode;
  maxWidth: string | undefined;
}>`
  ${tw`!bg-[#f2c94c] hover:!bg-[#f0c234] focus:!ring-[#f7df93]  transition-none`}
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "unset")};
  max-height: ${(props) => (props.maxWidth ? props.maxWidth : "unset")};
  ${(props) => props.bannerStyle === "nav" && tw`grow`}
`;

const UpsellBanner: React.FunctionComponent<UpsellBannerProps> = ({
  className,
  isVisible,
  featureText,
  mode = "page",
  fadeInOverlay = false,
  onClick,
  isNavCollapsed,
}) => {
  const [showOverlay, setShowOverlay] = useState<boolean>(false);
  const collapseX = isNavCollapsed;
  const [collapseY, setCollapseY] = useState<boolean>(false);

  const outerRef = useRef<HTMLDivElement>(null);
  const [id] = useState(nanoid());
  const nodeRef = useRef(null);

  const title = !!featureText
    ? featureText
    : mode === "nav" && collapseY
    ? "Unlock premium features."
    : "Unlock premium features";

  const subtitle =
    mode === "nav"
      ? "with Grow, Prosper, or Enterprise plans."
      : "Unlock premium features with Grow, Prosper or Enterprise plans.";

  useLayoutEffect(() => {
    if (mode === "overlay") {
      document.body.classList.toggle("modal-open", isVisible);
      setShowOverlay(isVisible);
    }
  }, [mode, isVisible]);

  useEffect(() => {
    if (mode !== "nav" || !outerRef.current || !isVisible) {
      return;
    }

    const observer = new ResizeObserver((entries) => {
      const entry = entries[0];
      const height = entry.target.getBoundingClientRect().height;
      setCollapseY(height <= 204);
    });

    observer.observe(outerRef.current);

    return () => {
      observer.disconnect();
    };
  }, [isVisible, mode]);

  const maxIconWidth =
    mode === "overlay"
      ? "34px"
      : mode === "nav" && (collapseX || collapseY)
      ? "32px"
      : mode === "header"
      ? "30px"
      : mode === "panel"
      ? "28px"
      : undefined;

  const maxButtonWidth = mode === "nav" && collapseX ? maxIconWidth : undefined;

  if (!isVisible) {
    return <></>;
  }

  const fadeDelay = fadeInOverlay ? 500 : 0;
  const fadeDuration = fadeInOverlay ? 1000 : 0;

  const styleProps = {
    mode,
    collapseX,
    collapseY,
  } as BannerStyleProps;

  return (
    <Wrapper className={className} {...styleProps} ref={outerRef}>
      <FadeTransition
        nodeRef={nodeRef}
        in={isVisible}
        timeout={fadeDelay + fadeDuration}
        classNames="fade"
        appear={true}
        unmountOnExit={true}
        css={[showOverlay && tw`z-50`]}
        delay={fadeDelay}
        duration={fadeDuration}
      >
        <Container {...styleProps} ref={nodeRef} key={id}>
          <Overlay
            className={classNames(showOverlay && "overlay")}
            css={[!showOverlay && tw`hidden`]}
          />
          <Content
            {...styleProps}
            className={classNames(showOverlay && "overlay-content")}
          >
            <TextContainer {...styleProps}>
              <div tw="relative">
                <UpgradeIcon
                  style={{
                    maxWidth: maxIconWidth,
                    maxHeight: maxIconWidth,
                    fill: mode === "overlay" ? "white" : "transparent",
                  }}
                />
              </div>
              <TextInner {...styleProps}>
                <FeatureText {...styleProps}>{title}</FeatureText>
                <DetailText {...styleProps}>{subtitle}</DetailText>
              </TextInner>
            </TextContainer>
            <ButtonGroup {...styleProps}>
              <UpgradeButton
                buttonType="secondary"
                onMouseDown={onClick}
                maxWidth={maxButtonWidth}
                bannerStyle={mode}
                size={mode === "overlay" ? "lg" : undefined}
              >
                <span>
                  <FontAwesomeIcon
                    icon={faArrowCircleUp}
                    transform={
                      mode === "nav" && collapseX ? "left-4.5" : "left-2"
                    }
                  />
                  {!collapseX && " Upgrade"}
                </span>
              </UpgradeButton>
            </ButtonGroup>
          </Content>
        </Container>
      </FadeTransition>
    </Wrapper>
  );
};

export default UpsellBanner;
