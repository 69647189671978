import { createContext, useContext, useEffect, useState } from "react";

import { Token, UserClaims } from "../@types";
import convertClaimsArrayString from "../common/convertClaimsArrayString";
import Spinner from "../common/Spinner";
import { useAuth0 } from "./auth0";

interface UserClaimsContext {
  userClaims: UserClaims | undefined;
  refreshUserClaims: (() => Promise<void>) | undefined;
  userLoggedIn: boolean;
  setUserLoggedIn: ((userLoggedIn: boolean) => void) | undefined;
}

const Context = createContext<UserClaimsContext>({
  userClaims: undefined,
  refreshUserClaims: undefined,
  userLoggedIn: false,
  setUserLoggedIn: undefined,
});

export const useUserClaims = () => useContext(Context);

/**
 * Provides a UserClaims object. Renders children only when authentication is
 * finished.
 *
 * Use useUserClaims() to get the claims object. If it's undefined, no user
 * is logged in.
 */
const UserClaimsProvider: React.FunctionComponent = ({ children }) => {
  const { loading, isAuthenticated, getTokenSilently } = useAuth0();
  const [userClaims, setUserClaims] = useState<UserClaims | undefined>();
  const [ready, setReady] = useState(false);
  const [userLoggedIn, setUserLoggedIn] = useState(false);

  useEffect(() => {
    let mounted = true;

    if (loading) {
      return;
    }

    if (!isAuthenticated) {
      setReady(true);
      return;
    }

    (async () => {
      const token = await getTokenSilently();
      if (!token) {
        throw new Error("Error getting token");
      }

      if (mounted) {
        setClaimsFromToken(token);
        setReady(true);
      }
    })();

    return () => {
      mounted = false;
    };
  }, [getTokenSilently, isAuthenticated, loading]);

  const setClaimsFromToken = (token: Token) => {
    const claims = token.decoded["https://hasura.io/jwt/claims"];

    const allowedAccountIds = claims["x-hasura-allowed-account-ids"]
      ? convertClaimsArrayString(claims["x-hasura-allowed-account-ids"])
      : [];

    setUserClaims({
      token,
      id: claims["x-hasura-user-id"],
      accountId: claims["x-hasura-account-id"]
        ? Number(claims["x-hasura-account-id"])
        : null,
      allowedAccountIds,
      disabledAccountId: claims["x-hasura-disabled-account-id"]
        ? Number(claims["x-hasura-disabled-account-id"])
        : null,
      defaultRole: claims["x-hasura-default-role"],
      allowedRoles: claims["x-hasura-allowed-roles"],
      transparentUserId: claims["x-hasura-transparent-user-id"] || null,
      linkedUserId: claims["x-hasura-linked-user-id"] || null,
    });
  };

  const refreshUserClaims = async () => {
    const token = await getTokenSilently({ ignoreCache: true });
    if (!token) {
      throw new Error("Error getting token");
    }

    setClaimsFromToken(token);
  };

  if (!ready) {
    return <Spinner height="100vh" />;
  }

  return (
    <Context.Provider
      value={{ userClaims, refreshUserClaims, userLoggedIn, setUserLoggedIn }}
    >
      {children}
    </Context.Provider>
  );
};

export default UserClaimsProvider;
