import { useMutation } from "@apollo/client";
import gql from "graphql-tag";

import {
  CreateProductSwapOfferMutation,
  CreateProductSwapOfferMutationVariables,
} from "../../../__generated__/graphql";
import AppOfferFragment from "../../../common/fragments/AppOfferFragment";
import updateCache from "./updateCache";

export default function useCreateProductSwapOffer() {
  const [createProductSwapOffer] = useMutation<
    CreateProductSwapOfferMutation,
    CreateProductSwapOfferMutationVariables
  >(
    gql`
      mutation CreateProductSwapOfferMutation(
        $object: offer_product_swap_insert_input!
      ) {
        insert_offer_product_swap_one(object: $object) {
          offer {
            ...AppOfferFragment
          }
        }
      }
      ${AppOfferFragment}
    `,
    {
      update: (cache, { data }) => {
        if (!data?.insert_offer_product_swap_one?.offer) {
          return;
        }

        updateCache(cache, data.insert_offer_product_swap_one.offer);
      },
    }
  );

  return createProductSwapOffer;
}
