import { nanoid } from "nanoid";
import React from "react";
import { Node, Text } from "slate";
import tw from "twin.macro";

import {
  language_enum,
  property_format_enum,
} from "../../../__generated__/graphql";
import { OfferDetails } from "../../../features/offers/lib/offerDetails";
import {
  PropertyConfig,
  PropertyValues,
} from "../../../features/properties/lib/types";
import { options } from "../TextEditor";
import isContentEmpty from "./isContentEmpty";
import { renderOfferTag } from "./OfferTagPlugin";
import { OfferTag } from "./OfferTagPlugin/types";
import { renderPropertyTag } from "./PropertyTagPlugin";
import { PropertyTag } from "./PropertyTagPlugin/types";

const Placeholder = tw.span`text-type-light`;

interface RenderContentWithOfferOptions {
  content: Node[];
  offerDetails: OfferDetails;
  language: language_enum;
  showContentPlaceholder?: boolean;
  propertyValues: PropertyValues;
  propertyConfig: PropertyConfig;
  showPropertyPlaceholders: boolean;
}

export default function renderContentWithOffer({
  content,
  offerDetails,
  language,
  showContentPlaceholder = false,
  propertyValues,
  propertyConfig,
  showPropertyPlaceholders,
}: RenderContentWithOfferOptions) {
  if (showContentPlaceholder && isContentEmpty(content)) {
    return (
      <p>
        <Placeholder>Content</Placeholder>
      </p>
    );
  }

  return renderNode(
    { children: content },
    offerDetails,
    language,
    propertyValues,
    propertyConfig,
    showPropertyPlaceholders
  );
}

const renderNode = (
  node: Node,
  offerDetails: OfferDetails,
  language: language_enum,
  propertyValues: PropertyValues,
  propertyConfig: PropertyConfig,
  showPropertyPlaceholders: boolean
): React.ReactNode => {
  if (Text.isText(node)) {
    const split = node.text.split("\n");

    let rendered: React.ReactNode = split.map((text, index) => (
      <React.Fragment key={nanoid()}>
        {text}
        {index < split.length - 1 && <br />}
      </React.Fragment>
    ));

    if (node[options.bold.type]) {
      rendered = <strong>{rendered}</strong>;
    }
    if (node[options.italic.type]) {
      rendered = <em>{rendered}</em>;
    }
    if (node[options.underline.type]) {
      rendered = <u>{rendered}</u>;
    }

    return <span key={nanoid()}>{rendered}</span>;
  }

  const children = node.children.map((n) =>
    renderNode(
      n,
      offerDetails,
      language,
      propertyValues,
      propertyConfig,
      showPropertyPlaceholders
    )
  );

  switch (node.type) {
    case options.h4.type:
      return <h4 key={nanoid()}>{children}</h4>;

    case options.h5.type:
      return <h5 key={nanoid()}>{children}</h5>;

    case options.p.type:
      return <p key={nanoid()}>{children}</p>;

    case options.offer_tag.type:
      return (
        <span key={nanoid()}>
          <span className="highlight">
            {renderOfferTag(offerDetails, node.tag as OfferTag, language)}
          </span>
          {children}
        </span>
      );

    case options.property_tag.type:
      return showPropertyPlaceholders ? (
        <span
          key={nanoid()}
          tw="bg-gray-100 px-1 rounded border border-gray-100 whitespace-nowrap"
        >
          {renderPropertyTag(
            propertyValues,
            propertyConfig,
            node.tag as PropertyTag,
            (node.propertyId as string) || "",
            (node.dateFormat as string) || "",
            node.numberFormat as property_format_enum,
            (node.fallback as string) || "",
            !!node.isArray,
            true
          )}
        </span>
      ) : (
        <span key={nanoid()}>
          {renderPropertyTag(
            propertyValues,
            propertyConfig,
            node.tag as PropertyTag,
            (node.propertyId as string) || "",
            (node.dateFormat as string) || "",
            node.numberFormat as property_format_enum,
            (node.fallback as string) || "",
            !!node.isArray
          )}
        </span>
      );

    default:
      return children;
  }
};
