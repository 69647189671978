import { gql, useQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { MultipleOfferIdsDropdownQuery } from "../../__generated__/graphql";
import offerIcon from "../offers/lib/offerIcon";
import MultipleIdsDropdown from "./MultipleIdsDropdown";

interface MultipleOfferIdsDropdownProps {
  value: string[];
  onChange: (value: string[]) => void;
  width?: string;
}

const MultipleOfferIdsDropdown: React.FunctionComponent<
  MultipleOfferIdsDropdownProps
> = ({ value, onChange, width = "210px" }) => {
  const { data, loading } = useQuery<MultipleOfferIdsDropdownQuery>(gql`
    query MultipleOfferIdsDropdownQuery {
      offer(where: { deleted_at: { _is_null: true } }) {
        token
        name
        type
      }
    }
  `);

  const ids = (data?.offer || []).map((offer) => ({
    id: offer.token,
    label: offer.name,
    displayLabel: (
      <>
        <FontAwesomeIcon
          icon={offerIcon(offer.type)}
          fixedWidth
          tw="text-type-light"
        />{" "}
        {offer.name}
      </>
    ),
  }));

  return (
    <MultipleIdsDropdown
      ids={ids}
      value={value}
      onChange={onChange}
      isLoading={loading}
      width={width}
      placeholder="Select an offer…"
    />
  );
};

export default MultipleOfferIdsDropdown;
