import gql from "graphql-tag";

import ViewerFragment from "../fragments/ViewerFragment";

export default gql`
  query ViewerQuery {
    viewer {
      ...ViewerFragment
    }
  }
  ${ViewerFragment}
`;
