import { useMutation } from "@apollo/client";
import { faUserSecret } from "@fortawesome/pro-duotone-svg-icons";
import { faArrowLeftLong } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import gql from "graphql-tag";
import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { theme } from "twin.macro";

import { TransparentLoginHeaderTransparentLogoutMutation } from "../../../__generated__/graphql";
import { useAuth0 } from "../../../app/auth0";
import { useUserClaims } from "../../../app/UserClaimsProvider";
import LoadingModal from "../../LoadingModal";
import StandardLinkButton from "../../StandardLinkButton";
import useViewer from "../../useViewer";

export const TRANSPARENT_LOGIN_HEADER_HEIGHT = "42px";

interface TransparentLoginHeaderProps {
  fullWidth?: boolean;
}

const TransparentLoginHeader: React.FunctionComponent<
  TransparentLoginHeaderProps
> = () => {
  const { userClaims } = useUserClaims();
  const { viewer, loading } = useViewer();
  const [loadingModalOpen, setLoadingModalOpen] = useState(false);
  const { getTokenSilently } = useAuth0();

  useEffect(() => {
    document.body.classList.add("has-transparent-login");

    return () => {
      document.body.classList.remove("has-transparent-login");
    };
  }, []);

  const [transparentLogout] =
    useMutation<TransparentLoginHeaderTransparentLogoutMutation>(gql`
      mutation TransparentLoginHeaderTransparentLogoutMutation {
        transparentLogout {
          success
        }
      }
    `);

  const handleClickLogout = async () => {
    setLoadingModalOpen(true);

    await transparentLogout();

    await getTokenSilently({ ignoreCache: true });

    if (userClaims?.accountId) {
      window.location.replace(`/a/accounts/${userClaims.accountId}`);
    } else {
      window.location.replace("/a/accounts");
    }
  };

  if (loading || !viewer || !viewer.account) {
    return null;
  }

  let userName = [viewer.firstName, viewer.lastName].join(" ").trim();
  if (!userName) {
    userName = viewer.email;
  }
  if (!userName) {
    userName = viewer.id;
  }

  const accountTitle = viewer.account.title || "untitled";

  return (
    <>
      <LoadingModal
        isOpen={loadingModalOpen}
        message="Returning to admin&hellip;"
      />
      {ReactDOM.createPortal(
        <div tw="fixed top-0 z-50 py-2 pl-4 pr-2 flex gap-2 items-center bg-orange-400 text-white w-full border-b-orange-600">
          <div>
            <FontAwesomeIcon
              icon={faUserSecret}
              color={theme`colors.orange.700`}
              tw="mr-1"
            />{" "}
            Transparently logged in as <strong>{userName}</strong> to{" "}
            <strong>{accountTitle}</strong> ({viewer.account.id}).
          </div>
          <div tw="ml-auto">
            <StandardLinkButton
              onClick={handleClickLogout}
              tw="text-white opacity-75 hover:opacity-100 hover:no-underline rounded px-2 border bg-orange-500 border-orange-600"
            >
              <FontAwesomeIcon icon={faArrowLeftLong} tw="mr-1" /> Return to
              admin
            </StandardLinkButton>
          </div>
        </div>,
        document.body
      )}
    </>
  );
};

export default TransparentLoginHeader;
