import gql from "graphql-tag";

import AppDeflectionSnapshotItemFragment from "./AppDeflectionSnapshotItemFragment";
import AppFlowActionFragment from "./AppFlowActionFragment";
import TranslationFragment from "./TranslationFragment";

export default gql`
  fragment AppDeflectionFragment on deflection {
    id
    token
    title
    tag_ids
    created_at
    archived_at
    tag_ids
    minimum_items
    deflection_tags {
      tag {
        id
        name
      }
    }
    heading_translation_id
    heading_translation {
      ...TranslationFragment
    }
    content_translation_id
    content_translation {
      ...TranslationFragment
    }
    deflection_snapshot_items: deflection_snapshot_item_positions(
      order_by: { position: asc }
    ) {
      position
      deflection_snapshot_item {
        ...AppDeflectionSnapshotItemFragment
      }
    }
    deflection_flow_actions(order_by: { position: asc }) {
      position
      flow_action {
        ...AppFlowActionFragment
      }
    }
  }
  ${AppFlowActionFragment}
  ${AppDeflectionSnapshotItemFragment}
  ${TranslationFragment}
`;
