import classNames from "classnames";
import tw, { styled } from "twin.macro";

const FieldLabel = styled.div.attrs(
  (props: { className: string; required: boolean }) => ({
    className: classNames(
      props.className,
      "field-label",
      props.required && "field-label--required"
    ),
  })
)<{
  className?: string;
  required?: boolean;
}>`
  width: 20%;
  min-width: 150px;
  ${tw`text-type font-semibold py-4 border-t border-transparent flex-shrink-0`}

  &.field-label.field-label--required::after {
    content: "*";
  }
`;

export default FieldLabel;
