import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import classNames from "classnames";
import { useState } from "react";
import { Node } from "slate";
import tw, { css, styled } from "twin.macro";

import {
  FlowConfirmationFragment,
  language_enum,
  subscriber_flow_status_enum,
} from "../../../../__generated__/graphql";
import BaseButton from "../../../../common/form/BaseButton";
import { ConfirmationManager } from "../../../flow/edit/confirmationManager";
import EditableFlowText from "../../../flow/edit/EditableFlowText";
import { useFlowVersion } from "../FlowVersionProvider";

interface ConfirmationProps {
  status: subscriber_flow_status_enum | undefined | null;
  confirmation: FlowConfirmationFragment;
  isEditMode: boolean;
  manager?: ConfirmationManager;
}

const Button = styled(BaseButton)`
  ${tw`
    bg-gray-600 text-sm text-white rounded px-2 py-1 mr-2 last:mr-0
  `}
`;

const ToggleButton = styled(Button)`
  ${tw`hover:bg-blue-500 disabled:opacity-25 disabled:bg-gray-600`}
  &.on {
    ${tw`bg-blue-500`}
  }
`;

const Confirmation: React.FunctionComponent<ConfirmationProps> = ({
  status,
  confirmation,
  isEditMode,
  manager,
}) => {
  const { getObjectVersion } = useFlowVersion();
  const [editStatus, setEditStatus] = useState<subscriber_flow_status_enum>(
    subscriber_flow_status_enum.saved
  );

  const showStatus = isEditMode ? editStatus : status;

  const version = getObjectVersion(confirmation);

  const handleUpdateCanceledHeadlineTranslation = (
    value: Partial<Record<language_enum, Node[]>>
  ) => {
    if (!manager) {
      return;
    }

    manager.updateConfirmationTranslations(confirmation.id, value, {}, {}, {});
  };

  const handleUpdateCanceledContentTranslation = (
    value: Partial<Record<language_enum, Node[]>>
  ) => {
    if (!manager) {
      return;
    }

    manager.updateConfirmationTranslations(confirmation.id, {}, value, {}, {});
  };

  const handleUpdateSavedHeadlineTranslation = (
    value: Partial<Record<language_enum, Node[]>>
  ) => {
    if (!manager) {
      return;
    }

    manager.updateConfirmationTranslations(confirmation.id, {}, {}, value, {});
  };

  const handleUpdateSavedContentTranslation = (
    value: Partial<Record<language_enum, Node[]>>
  ) => {
    if (!manager) {
      return;
    }

    manager.updateConfirmationTranslations(confirmation.id, {}, {}, {}, value);
  };

  return (
    <div
      className={classNames({
        "flow-confirmation": true,
        "inline-text-context": isEditMode,
      })}
    >
      {isEditMode && (
        <div
          css={css`
            position: absolute;
            margin-top: -70px;
            ${tw`bg-gray-200 p-2 rounded flex`}
          `}
        >
          <Tippy
            content="Shown after a subscriber accepts an offer."
            placement="bottom"
          >
            <ToggleButton
              disabled={!version.show_on_save}
              className={classNames({
                on: editStatus === subscriber_flow_status_enum.saved,
              })}
              onClick={() => setEditStatus(subscriber_flow_status_enum.saved)}
            >
              Saved
            </ToggleButton>
          </Tippy>
          <Tippy
            content="Shown after a subscriber cancels their subscription."
            placement="bottom"
          >
            <ToggleButton
              disabled={!version.show_on_cancel}
              className={classNames({
                on: editStatus === subscriber_flow_status_enum.canceled,
              })}
              onClick={() =>
                setEditStatus(subscriber_flow_status_enum.canceled)
              }
            >
              Canceled
            </ToggleButton>
          </Tippy>
        </div>
      )}
      <div
        className={classNames({
          "flow-confirmation__icon": true,
          "flow-confirmation__icon--saved":
            showStatus === subscriber_flow_status_enum.saved,
        })}
      >
        <FontAwesomeIcon icon={faCheckCircle} />
      </div>
      <div className="flow-confirmation__content">
        <div className="flow-confirmation__headline">
          {showStatus === subscriber_flow_status_enum.canceled ? (
            <EditableFlowText
              translation={version.canceled_headline_translation}
              isEditable={isEditMode}
              onSave={handleUpdateCanceledHeadlineTranslation}
            />
          ) : (
            <EditableFlowText
              translation={version.saved_headline_translation}
              isEditable={isEditMode}
              onSave={handleUpdateSavedHeadlineTranslation}
            />
          )}
        </div>
        <div className="flow-confirmation__body">
          {showStatus === subscriber_flow_status_enum.canceled ? (
            <EditableFlowText
              translation={version.canceled_content_translation}
              isEditable={isEditMode}
              isInline={false}
              onSave={handleUpdateCanceledContentTranslation}
              baseFontSize="20px"
            />
          ) : (
            <EditableFlowText
              translation={version.saved_content_translation}
              isEditable={isEditMode}
              isInline={false}
              onSave={handleUpdateSavedContentTranslation}
              baseFontSize="20px"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Confirmation;
