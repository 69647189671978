import { gql, useQuery } from "@apollo/client";
import { FieldError } from "react-hook-form";

import { FlowIdDropdownQuery } from "../../__generated__/graphql";
import IdDropdown from "../../common/IdDropdown";

interface FlowIdDropdownProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  excludeIds?: string[];
  includeIds?: string[];
  filterType?: string;
  resettable?: boolean;
  useId?: boolean;
  width?: string;
  disabled?: boolean;
  fieldError?: FieldError;
}

const FlowIdDropdown: React.FunctionComponent<FlowIdDropdownProps> = ({
  value,
  onChange,
  placeholder,
  excludeIds = [],
  includeIds = [],
  resettable = false,
  useId = false,
  width = "100%",
  disabled,
  fieldError,
}) => {
  const { data, loading } = useQuery<FlowIdDropdownQuery>(
    gql`
      query FlowIdDropdownQuery {
        flow(where: { deleted_at: { _is_null: true } }) {
          id
          token
          title
        }
      }
    `,
    { fetchPolicy: "cache-and-network" }
  );

  const ids = (data?.flow || [])
    .filter(
      (flow) => !excludeIds.includes(useId ? flow.id.toString() : flow.token)
    )
    .filter(
      (flow) => includeIds.length === 0 || includeIds.includes(flow.token)
    )
    .map((flow) => ({
      id: useId ? flow.id.toString() : flow.token,
      displayId: flow.token,
      label: flow.token,
      displayLabel: flow.title,
    }));

  return (
    <IdDropdown
      ids={ids}
      value={value}
      onChange={onChange}
      isLoading={loading}
      placeholder={placeholder}
      width={width}
      resettable={resettable}
      disabled={disabled}
      fieldError={fieldError}
    />
  );
};

export default FlowIdDropdown;
