import { gql, useMutation, useQuery } from "@apollo/client";
import { isAfter, subMinutes } from "date-fns";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import {
  CustomerPortalLoginQuery,
  LoginSendEmailVerificationMutation,
  LoginSendEmailVerificationMutationVariables,
  LoginVerifyEmailCodeMutation,
  LoginVerifyEmailCodeMutationVariables,
} from "../../../__generated__/graphql";
import Button from "../../../common/form/Button";
import TextInput from "../../../common/form/input/TextInput";
import CustomerPortalAccountFragment from "../../../common/fragments/CustomerPortalAccountFragment";
import {
  PlatformFeature,
  platformHasFeature,
} from "../../../common/platform/features";
import Spinner from "../../../common/Spinner";
import StandardLinkButton from "../../../common/StandardLinkButton";
import useFocusFirstEmptyInput from "../../../common/useFocusFirstEmptyInput";
import Card from "./Card";
import CodeInput from "./CodeInput";
import Header from "./Header";
import NotFound from "./NotFound";

interface LoginFormValues {
  email: string;
}

const VERIFYING_EMAIL_KEY = "verifying-email";

const Login: React.FunctionComponent = () => {
  const history = useHistory();
  const { clientId, sessionStatus } = useParams<{
    clientId: string;
    sessionStatus: string | undefined;
  }>();
  const { register, handleSubmit } = useForm<LoginFormValues>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isResending, setIsResending] = useState(false);

  const expiredSession = sessionStatus === "expired";

  const storedSession = localStorage.getItem(`session-${clientId}`);

  let defaultVerifyingEmail = "";
  const storedEmail = localStorage.getItem(VERIFYING_EMAIL_KEY);
  if (storedEmail && !storedSession) {
    const storedEmailData = JSON.parse(storedEmail);

    if (isAfter(new Date(storedEmailData.at), subMinutes(new Date(), 5))) {
      defaultVerifyingEmail = storedEmailData.email;
    } else {
      localStorage.removeItem(VERIFYING_EMAIL_KEY);
    }
  }

  const [verifyingEmail, setVerifyingEmail] = useState(defaultVerifyingEmail);
  const [formRef, setFormRef] = useState<HTMLFormElement | null>(null);
  const { addToast } = useToasts();
  useFocusFirstEmptyInput(formRef);
  const [code, setCode] = useState("");

  useEffect(() => {
    if (storedSession) {
      const storedSessionData = JSON.parse(storedSession);
      history.replace(
        `/p/portal/${clientId}/session/${storedSessionData.token}`
      );
    }
  }, [clientId, storedSession, history]);

  const { data, loading } = useQuery<CustomerPortalLoginQuery>(
    gql`
      query CustomerPortalLoginQuery {
        account {
          ...CustomerPortalAccountFragment
        }
      }
      ${CustomerPortalAccountFragment}
    `
  );

  const account = data?.account.length ? data.account[0] : undefined;
  const logoUrl = account?.logo_url || account?.default_flow?.logo_url;

  const [sendEmailVerification] = useMutation<
    LoginSendEmailVerificationMutation,
    LoginSendEmailVerificationMutationVariables
  >(gql`
    mutation LoginSendEmailVerificationMutation(
      $input: SendEmailVerificationInput!
    ) {
      sendEmailVerification(input: $input) {
        subscriberFound
        rateLimited
      }
    }
  `);

  const [verifyEmailCode] = useMutation<
    LoginVerifyEmailCodeMutation,
    LoginVerifyEmailCodeMutationVariables
  >(gql`
    mutation LoginVerifyEmailCodeMutation($input: VerifyEmailCodeInput!) {
      verifyEmailCode(input: $input) {
        portalSessionToken
      }
    }
  `);

  const onSubmit = handleSubmit(async (values) => {
    setIsSubmitting(true);

    const result = await sendEmailVerification({
      variables: {
        input: {
          clientId,
          email: values.email,
        },
      },
    });

    if (result.errors) {
      addToast(
        <div>
          Something went wrong with your request. Please try again later.
        </div>,
        { appearance: "error" }
      );
    }

    if (result.data?.sendEmailVerification.subscriberFound) {
      setVerifyingEmail(values.email);
      localStorage.setItem(
        VERIFYING_EMAIL_KEY,
        JSON.stringify({
          email: values.email,
          at: new Date(),
        })
      );
    } else {
      if (result.data?.sendEmailVerification.rateLimited) {
        addToast(
          <div>
            Too many attempts. Please wait a few minutes and try again.
          </div>,
          { appearance: "warning" }
        );
      } else {
        addToast(
          <div>
            We couldn't find an account associated with that email address.
          </div>,
          { appearance: "warning" }
        );
      }
    }

    setIsSubmitting(false);
  });

  const handleCodeChange = async (code: string) => {
    setCode(code);
    setIsSubmitting(true);

    const result = await verifyEmailCode({
      variables: {
        input: {
          clientId,
          email: verifyingEmail,
          code,
        },
      },
    });

    if (result.errors) {
      addToast(
        <div>
          Something went wrong with your request. Please try again later.
        </div>,
        { appearance: "error" }
      );
    }

    if (result.data?.verifyEmailCode.portalSessionToken) {
      const token = result.data.verifyEmailCode.portalSessionToken;

      localStorage.removeItem(VERIFYING_EMAIL_KEY);
      localStorage.setItem(
        `session-${clientId}`,
        JSON.stringify({
          token,
        })
      );

      history.push(`/p/portal/${clientId}/session/${token}`);
      return;
    } else {
      addToast(<div>Invalid code. Please check your code and try again.</div>, {
        appearance: "warning",
      });
    }

    setIsSubmitting(false);
  };

  const handleClickResendCode = async () => {
    setIsResending(true);

    const result = await sendEmailVerification({
      variables: {
        input: {
          clientId,
          email: verifyingEmail,
        },
      },
    });

    if (result.errors || !result.data?.sendEmailVerification.subscriberFound) {
      if (result.data?.sendEmailVerification.rateLimited) {
        addToast(
          <div>
            Please wait a few minutes before requesting a new verification code.
          </div>,
          { appearance: "warning" }
        );
      } else {
        addToast(
          <div>
            Something went wrong with your request. Please try again later.
          </div>,
          { appearance: "error" }
        );
      }
    }

    if (result.data?.sendEmailVerification.subscriberFound) {
      addToast(
        <div>
          A new verification code has been sent. Please check your email for the
          new code.
        </div>,
        {
          appearance: "info",
        }
      );

      localStorage.setItem(
        VERIFYING_EMAIL_KEY,
        JSON.stringify({
          email: verifyingEmail,
          at: new Date(),
        })
      );
    }

    setIsResending(false);
  };

  if (!loading && !account) {
    return <NotFound />;
  }

  if (
    !loading &&
    !expiredSession &&
    account &&
    !platformHasFeature(
      account?.platform_connection?.platform,
      PlatformFeature.CustomerPortalEmailLogin
    )
  ) {
    return <NotFound />;
  }

  return loading ? (
    <Spinner context="page" />
  ) : (
    <>
      <Helmet>
        <title>
          {account?.title && `Verify your account - ${account.title}`}
        </title>
        {account?.favicon_url && (
          <link rel="icon" href={account.favicon_url}></link>
        )}
      </Helmet>
      <Header logoUrl={logoUrl} companyName={account?.title} />
      {expiredSession ? (
        <Card tw="flex flex-col items-center md:w-[26rem] py-10 px-8">
          <h2 tw="font-title text-center text-lg">Your session has expired</h2>
          {platformHasFeature(
            account?.platform_connection?.platform,
            PlatformFeature.CustomerPortalEmailLogin
          ) && (
            <p tw="text-type text-center max-w-[20rem] mt-3">
              <Link to={`/p/portal/${clientId}`} tw="text-link hover:underline">
                Log in again
              </Link>
            </p>
          )}
        </Card>
      ) : (
        <Card tw="flex flex-col items-center md:w-[26rem] pt-10 px-8 pb-10">
          {!verifyingEmail ? (
            <>
              <h2 tw="font-title text-center mb-4 text-lg">
                Verify your {account?.title} account
              </h2>
              <p tw="text-type text-center mb-6 max-w-[20rem]">
                Enter the email address associated with your account and we'll
                send you a verification code.
              </p>
              <form onSubmit={onSubmit} tw="w-[17rem] mx-auto" ref={setFormRef}>
                <TextInput
                  {...register("email", {
                    required: true,
                    validate: (value) => {
                      if (!value.match(/(.+)@(.+)/)) {
                        return "Invalid email";
                      }
                      return undefined;
                    },
                  })}
                  tw="w-full mb-3"
                  placeholder="Email address"
                />
                <div>
                  <Button
                    buttonType="neutral"
                    isLoading={isSubmitting}
                    disabled={isSubmitting}
                    tw="w-full"
                  >
                    Send code
                  </Button>
                </div>
              </form>
            </>
          ) : (
            <>
              <h2 tw="font-title text-center mb-3 text-lg">
                Verification code
              </h2>
              <p tw="text-type text-center mb-8 max-w-[20rem]">
                Please enter the verification code sent to{" "}
                <strong>{verifyingEmail}</strong>.
              </p>

              <div>
                <CodeInput
                  onChange={handleCodeChange}
                  disabled={isSubmitting}
                />
                <Button
                  buttonType="neutral"
                  isLoading={isSubmitting}
                  disabled={isSubmitting || !code}
                  tw="w-full mt-3"
                  onClick={() => handleCodeChange(code)}
                >
                  Verify
                </Button>
                <div tw="mt-2 text-center">
                  <StandardLinkButton
                    tw="text-gray-500 hover:text-gray-700 disabled:text-gray-500 underline"
                    onClick={handleClickResendCode}
                    disabled={isSubmitting || isResending}
                  >
                    Resend code
                  </StandardLinkButton>
                </div>
              </div>
            </>
          )}
        </Card>
      )}
    </>
  );
};

export default Login;
