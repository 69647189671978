import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import tw from "twin.macro";

import LocationInput from "./LocationInput";

type LocationValue = { country: string; state: string };

interface MultipleLocationsInputProps {
  value: LocationValue[];
  onChange: (value: LocationValue[]) => void;
}

const DeleteButton = tw.button`flex justify-between pt-[0.6rem] text-gray-400 hover:text-gray-500 focus:outline-none disabled:opacity-0 disabled:cursor-default ml-2`;

const MultipleLocationsInput: React.FunctionComponent<
  MultipleLocationsInputProps
> = ({ value, onChange }) => {
  const handleChange = (index: number, v: LocationValue) => {
    const newValue = [...value];
    newValue[index] = v;
    onChange(newValue);
  };

  const handleClickDelete = (index: number) => {
    onChange([...value.slice(0, index), ...value.slice(index + 1)]);
  };

  const count = value.length + 1;

  const inputs = [...Array(count).keys()].map((i) => (
    <div key={i} tw="flex mt-2">
      <div tw="flex-1 border-l-2 border-gray-300 pl-3">
        <LocationInput
          value={value[i] || { country: "", state: "" }}
          onChange={(v) => handleChange(i, v)}
        />
      </div>
      <DeleteButton
        type="button"
        onClick={() => handleClickDelete(i)}
        disabled={i === value.length}
      >
        <FontAwesomeIcon icon={faXmark} />
      </DeleteButton>
    </div>
  ));

  return <div>{inputs}</div>;
};

export default MultipleLocationsInput;
