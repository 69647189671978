import { FlowQuestionFragment } from "../../__generated__/graphql";
import getFlowObjectVersion from "./getFlowObjectVersion";
import { FlowVersion } from "./types";

interface FormattedQuestionConditionSegmentGroup {
  id: number;
}

interface FormattedQuestionConditionSegment {
  id: number;
}

interface FormattedQuestionConditionOption {
  id: number;
  questionId: number;
}

export interface FormattedQuestionCondition {
  questionId: number;
  segments: FormattedQuestionConditionSegment[];
  segmentGroups: FormattedQuestionConditionSegmentGroup[];
  segmentMatch: boolean;
  segmentGroupMatch: boolean;
  questionOptions: FormattedQuestionConditionOption[];
  includeOtherInQuestionIds: number[];
}

const formatQuestionConditions = (
  questions: FlowQuestionFragment[],
  matchedSegmentIds: number[],
  matchedSegmentGroupIds: number[],
  flowVersion: FlowVersion
): FormattedQuestionCondition[] => {
  const questionConditions: FormattedQuestionCondition[] = [];

  for (const question of questions) {
    const questionVersion = getFlowObjectVersion(question, flowVersion);

    const segments: FormattedQuestionConditionSegment[] =
      questionVersion.question_version_condition_segments.map((s) => ({
        id: s.segment_id || -1,
      }));

    const segmentGroups: FormattedQuestionConditionSegmentGroup[] =
      questionVersion.question_version_condition_segment_groups.map((g) => ({
        id: g.segment_group_id || -1,
      }));

    const questionOptions: FormattedQuestionConditionOption[] =
      questionVersion.question_version_condition_question_options.map((q) => ({
        id: q.question_option_id || -1,
        questionId: q.question_option?.question_id || -1,
      }));

    if (
      segments.length ||
      questionOptions.length ||
      questionVersion.condition_include_other_in_question_ids.length
    ) {
      const segmentMatch = !!segments.find((segment) =>
        matchedSegmentIds.some(
          (matchedSegmentId) => segment.id === matchedSegmentId
        )
      );
      const segmentGroupMatch = !!segmentGroups.find((g) =>
        matchedSegmentGroupIds.some((id) => g.id === id)
      );

      const condition: FormattedQuestionCondition = {
        questionId: question.id,
        segments,
        segmentGroups,
        segmentMatch,
        segmentGroupMatch,
        questionOptions,
        includeOtherInQuestionIds:
          questionVersion.condition_include_other_in_question_ids,
      };

      questionConditions.push(condition);
    }
  }

  return questionConditions;
};

export default formatQuestionConditions;
