export interface MissingImagePlaceHolderProps {
  color: "gray" | "blue";
}

const MissingImagePlaceHolder: React.FunctionComponent<
  MissingImagePlaceHolderProps
> = ({ color = "gray" }) => (
  <>
    {color === "blue" ? (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="18"
        viewBox="0 0 20 18"
        fill="none"
      >
        <path
          d="M0.5 3.0625C0.5 1.76367 1.53906 0.6875 2.875 0.6875H17.125C18.4238 0.6875 19.5 1.76367 19.5 3.0625V14.9375C19.5 16.2734 18.4238 17.3125 17.125 17.3125H2.875C1.53906 17.3125 0.5 16.2734 0.5 14.9375V3.0625ZM12.4863 7.0332C12.3379 6.77344 12.041 6.625 11.7812 6.625C11.4844 6.625 11.1875 6.77344 11.0391 7.0332L7.81055 11.7832L6.80859 10.5215C6.66016 10.3359 6.40039 10.1875 6.14062 10.1875C5.84375 10.1875 5.58398 10.3359 5.43555 10.5215L3.06055 13.4902C2.83789 13.7871 2.80078 14.1582 2.94922 14.4551C3.09766 14.752 3.39453 14.9375 3.76562 14.9375H7.32812H8.51562H16.2344C16.5312 14.9375 16.8652 14.7891 17.0137 14.4922C17.1621 14.1953 17.125 13.8242 16.9395 13.5645L12.4863 7.0332ZM4.65625 6.625C5.28711 6.625 5.84375 6.29102 6.17773 5.73438C6.51172 5.21484 6.51172 4.50977 6.17773 3.95312C5.84375 3.43359 5.28711 3.0625 4.65625 3.0625C3.98828 3.0625 3.43164 3.43359 3.09766 3.95312C2.76367 4.50977 2.76367 5.21484 3.09766 5.73438C3.43164 6.29102 3.98828 6.625 4.65625 6.625Z"
          fill="#8891CA"
        />
      </svg>
    ) : (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="18"
        viewBox="0 0 20 18"
        fill="none"
      >
        <path
          d="M0.5 3.0625C0.5 1.76367 1.53906 0.6875 2.875 0.6875H17.125C18.4238 0.6875 19.5 1.76367 19.5 3.0625V14.9375C19.5 16.2734 18.4238 17.3125 17.125 17.3125H2.875C1.53906 17.3125 0.5 16.2734 0.5 14.9375V3.0625ZM12.4863 7.0332C12.3379 6.77344 12.041 6.625 11.7812 6.625C11.4844 6.625 11.1875 6.77344 11.0391 7.0332L7.81055 11.7832L6.80859 10.5215C6.66016 10.3359 6.40039 10.1875 6.14062 10.1875C5.84375 10.1875 5.58398 10.3359 5.43555 10.5215L3.06055 13.4902C2.83789 13.7871 2.80078 14.1582 2.94922 14.4551C3.09766 14.752 3.39453 14.9375 3.76562 14.9375H7.32812H8.51562H16.2344C16.5312 14.9375 16.8652 14.7891 17.0137 14.4922C17.1621 14.1953 17.125 13.8242 16.9395 13.5645L12.4863 7.0332ZM4.65625 6.625C5.28711 6.625 5.84375 6.29102 6.17773 5.73438C6.51172 5.21484 6.51172 4.50977 6.17773 3.95312C5.84375 3.43359 5.28711 3.0625 4.65625 3.0625C3.98828 3.0625 3.43164 3.43359 3.09766 3.95312C2.76367 4.50977 2.76367 5.21484 3.09766 5.73438C3.43164 6.29102 3.98828 6.625 4.65625 6.625Z"
          fill="#A1A1AA"
        />
      </svg>
    )}
  </>
);

export default MissingImagePlaceHolder;
