import tw, { styled } from "twin.macro";

export default styled.div`
  ${tw`px-4 py-2`}

  max-height: calc(100vh - 20rem);
  overflow-y: auto;

  & > p:not(:last-child),
  & > .callout:not(:last-child) {
    ${tw`mb-3`}
  }

  & .logo {
    ${tw`mt-3`}
  }
`;
