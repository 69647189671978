import tw, { styled } from "twin.macro";

export default styled.div`
  ${tw`flex border-b border-divider p-1`}

  .slate-ToolbarButton {
    ${tw`rounded mr-1 last:mr-0`}
  }

  .slate-ToolbarButton:hover {
    ${tw`bg-gray-100`}
  }

  .slate-ToolbarButton:active,
  .slate-ToolbarButton-active,
  .slate-ToolbarButton-active:hover {
    ${tw`bg-gray-200`}
  }

  fieldset:disabled & {
    ${tw`bg-gray-100 text-gray-400 pointer-events-none`}
  }
`;
