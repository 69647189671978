import { LexicalEditor as LexicalEditorType } from "lexical";
import { FieldError } from "react-hook-form";
import { ReactEditor } from "slate-react";

import { translation_value_format_enum } from "../../__generated__/graphql";
import { nodes } from "../../features/public/flow/lib/mapFlowText";
import { TAG_DESCRIPTION } from "../editor/lib/OfferTagPlugin/types";
import {
  PROPERTY_TAG_CUSTOM_PROPERTY,
  PROPERTY_TAG_EMAIL,
  PROPERTY_TAG_FIRST_NAME,
  PROPERTY_TAG_LAST_NAME,
  PROPERTY_TAG_SEGMENT_PROPERTY,
} from "../editor/lib/PropertyTagPlugin/types";
import SlateEditor from "../editor/TextEditor";
import LexicalEditor from "./Editor";
import { EditorType } from "./lib";

interface EditorSelectorProps {
  format: translation_value_format_enum;
  value: any;
  isInline?: boolean;
  baseFontSize?: string;
  initialFocus?: boolean;
  isOfferContent?: boolean;
  hasCustomProperties?: boolean;
  initialValueKey?: string;
  onChange: (value: string, editorType: EditorType) => void;
  lexicalEditorRef?: (editor: LexicalEditorType) => void;
  slateEditorRef?: (editor: ReactEditor) => void;
  height?: string;
  isReadOnly?: boolean;
  linksEnabled?: boolean;
  listsEnabled?: boolean;
  imagesEnabled?: boolean;
  videosEnabled?: boolean;
  fieldError?: FieldError;
  includeSegmentProperties?: boolean;
}

const EditorSelector: React.FunctionComponent<EditorSelectorProps> = ({
  format,
  value,
  isInline,
  baseFontSize,
  initialFocus,
  isOfferContent,
  hasCustomProperties,
  initialValueKey,
  onChange,
  lexicalEditorRef,
  slateEditorRef,
  height,
  isReadOnly,
  linksEnabled,
  listsEnabled,
  imagesEnabled,
  videosEnabled,
  fieldError,
}) => {
  switch (format) {
    case "lexical":
      return (
        <LexicalEditor
          initialValueKey={initialValueKey}
          initialValue={
            value
              ? typeof value === "string"
                ? value
                : JSON.stringify(value)
              : undefined
          }
          onChange={(v) => onChange(v, "lexical")}
          editorRef={lexicalEditorRef}
          baseFontSize={baseFontSize}
          initialFocus={initialFocus}
          isOfferContent={isOfferContent}
          height={height}
          isReadOnly={isReadOnly}
          listsEnabled={listsEnabled}
          linksEnabled={linksEnabled}
          imagesEnabled={imagesEnabled}
          videosEnabled={videosEnabled}
          isInline={isInline}
          fieldError={fieldError}
        />
      );

    default:
      return (
        <SlateEditor
          initialValueKey={initialValueKey}
          initialValue={
            value
              ? typeof value === "string"
                ? JSON.parse(value)
                : value
              : nodes("")
          }
          onChange={(v) => onChange(JSON.stringify(v), "slate")}
          isInline={isInline}
          editorRef={slateEditorRef}
          height={height}
          imagesEnabled={imagesEnabled}
          videosEnabled={videosEnabled}
          propertyTags={
            hasCustomProperties
              ? [
                  PROPERTY_TAG_FIRST_NAME,
                  PROPERTY_TAG_LAST_NAME,
                  PROPERTY_TAG_EMAIL,
                  PROPERTY_TAG_SEGMENT_PROPERTY,
                  PROPERTY_TAG_CUSTOM_PROPERTY,
                ]
              : [
                  PROPERTY_TAG_FIRST_NAME,
                  PROPERTY_TAG_LAST_NAME,
                  PROPERTY_TAG_EMAIL,
                  PROPERTY_TAG_SEGMENT_PROPERTY,
                ]
          }
          fieldError={fieldError}
          offerTags={isOfferContent ? [TAG_DESCRIPTION] : undefined}
        />
      );
  }
};

export default EditorSelector;
