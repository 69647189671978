import { language_enum, TranslationFragment } from "../__generated__/graphql";
import isSlateContentEmpty from "./editor/lib/isContentEmpty";
import { isContentEmpty as isLexicalContentEmpty } from "./editor2/lib";

export default function translationValue(
  translation: TranslationFragment | null | undefined,
  language: language_enum,
  defaultLanguage: language_enum
) {
  if (!translation) {
    return {
      value: null,
      format: null,
    };
  }

  let value = translation.translation_values?.find(
    (value) => value.language === language
  );

  if (
    !value ||
    (value.language !== defaultLanguage &&
      ((value.format === "text" && value.value === "") ||
        (value.format === "slate" && isSlateContentEmpty(value.value)) ||
        (value.format === "lexical" && isLexicalContentEmpty(value.value))))
  ) {
    value = translation.translation_values?.find(
      (value) => value.language === defaultLanguage
    );
  }

  return {
    value: value?.value || null,
    format: value?.format || null,
  };
}
