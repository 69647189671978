import React, { Suspense } from "react";
import { css } from "twin.macro";

import { IconPickerProps } from "./IconPicker";
import Spinner from "./Spinner";

const IconPicker = React.lazy(() => import("./IconPicker"));

const LoadableIconPicker: React.FunctionComponent<IconPickerProps> = (
  props
) => (
  <Suspense
    fallback={
      <div
        css={css`
          width: 108px;
        `}
      >
        <Spinner height="60px" />
      </div>
    }
  >
    <IconPicker {...props} />
  </Suspense>
);

export default LoadableIconPicker;
