import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import tw from "twin.macro";

import { language_enum, platform_enum } from "../../__generated__/graphql";
import EditorSelector from "../../common/editor2/EditorSelector";
import Button from "../../common/form/Button";
import FieldCheckboxInput from "../../common/form/FieldCheckboxInput";
import FieldError from "../../common/form/FieldError";
import FieldHint from "../../common/form/FieldHint";
import FieldInput from "../../common/form/FieldInput";
import FieldLabel from "../../common/form/FieldLabel";
import FieldRow from "../../common/form/FieldRow";
import FieldRowBlock from "../../common/form/FieldRowBlock";
import FieldSet from "../../common/form/FieldSet";
import FormMode from "../../common/form/FormMode";
import ImageInput from "../../common/form/input/ImageInput";
import SelectInput from "../../common/form/input/SelectInput";
import TagsInput from "../../common/form/input/TagsInput";
import TextInput from "../../common/form/input/TextInput";
import useTranslatableForm, {
  TranslatedForms,
} from "../../common/form/useTranslatableForm";
import HelpIcon from "../../common/HelpIcon";
import MetadataInput from "../../common/MetadataInput";
import PillRadio from "../../common/PillRadio";
import {
  PlatformFeature,
  platformHasFeature,
} from "../../common/platform/features";
import { useTranslations } from "../../common/translations/TranslationsProvider";
import useAccountFeatures from "../../common/useAccountFeatures";
import useFocusFirstEmptyInput from "../../common/useFocusFirstEmptyInput";
import LanguageRadio from "../flow/edit/LanguageRadio";
import getDefaultFormValues from "./forms/lib/getDefaultFormValues";
import getTranslatableFields from "./forms/lib/getTranslatableFields";
import { PauseSubscriptionOfferFormValues } from "./forms/types";
import defaultInterval from "./lib/defaultPauseInterval";
import useValidateHeadline from "./lib/useValidateHeadline";
import useValidateName from "./lib/useValidateName";
import validateMetadata from "./lib/validateMetadata";
import PauseSubscriptionOfferFormOption from "./PauseSubscriptionOfferFormOption";

interface PauseSubscriptionOfferFormProps {
  mode: FormMode;
  platform: platform_enum;
  onChange: (forms: TranslatedForms<PauseSubscriptionOfferFormValues>) => void;
  onSubmit: (forms: TranslatedForms<PauseSubscriptionOfferFormValues>) => void;
  initialValues?: TranslatedForms<PauseSubscriptionOfferFormValues>;
  onChangeEditingLanguage: (language: language_enum) => void;
  isSubmitting: boolean;
  tags: string[];
}

const PauseSubscriptionOfferForm: React.FunctionComponent<
  PauseSubscriptionOfferFormProps
> = ({
  mode,
  platform,
  onChange,
  onSubmit,
  initialValues,
  onChangeEditingLanguage,
  isSubmitting,
  tags,
}) => {
  const { defaultLanguage, enabledLanguages } = useTranslations();
  const defaultValues =
    initialValues ||
    getDefaultFormValues<PauseSubscriptionOfferFormValues>({
      type: "pause_subscription",
      enabledLanguages: enabledLanguages,
      platform,
    });

  const formMethods = useTranslatableForm<PauseSubscriptionOfferFormValues>({
    defaultValues,
    initialLanguage: defaultLanguage,
    languages: enabledLanguages,
    defaultLanguage,
    translatableFields: getTranslatableFields("pause_subscription"),
  });

  const {
    register,
    watch,
    formState,
    handleSubmit,
    control,
    editingLanguage,
    setEditingLanguage,
    forms,
    trigger,
    setValue,
  } = formMethods;

  const [formRef, setFormRef] = useState<HTMLFormElement | null>(null);
  useFocusFirstEmptyInput(formRef);
  const validateName = useValidateName(formState.dirtyFields.name || false);
  const values = watch();
  const validateHeadline = useValidateHeadline(values.image);
  const { features } = useAccountFeatures();

  let optionCount = 0;
  if (values.option1Present) {
    optionCount++;
  }
  if (values.option2Present) {
    optionCount++;
  }
  if (values.option3Present) {
    optionCount++;
  }

  useEffect(() => {
    onChange(forms);
  }, [forms, onChange]);

  let optionNumber = 0;
  const getNextOptionNumber = () => {
    optionNumber++;
    return optionNumber;
  };

  return (
    <form
      id="create-offer"
      onChange={() => onChange(forms)}
      ref={(ref) => setFormRef(ref)}
      onSubmit={handleSubmit(onSubmit)}
    >
      <fieldset disabled={isSubmitting}>
        <input {...register("type")} type="hidden" value="pause_subscription" />
        <FieldRow>
          <FieldLabel>
            <label htmlFor="name">Name</label>
          </FieldLabel>
          <FieldInput>
            <TextInput
              {...register("name", {
                required: true,
                validate: validateName,
              })}
              id="name"
              width="full"
              fieldError={formState.errors.name}
            />
            <FieldError error={formState.errors.name} />
          </FieldInput>
        </FieldRow>
        <FieldRow>
          <FieldLabel>Tags</FieldLabel>
          <FieldInput>
            <Controller
              control={control}
              name="tags"
              render={({ field }) => (
                <TagsInput
                  tags={tags}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </FieldInput>
        </FieldRow>
        <FieldRow>
          <FieldLabel>
            <label htmlFor="style">Style</label>
          </FieldLabel>
          <FieldInput>
            <SelectInput {...register("style")} id="style" defaultValue="modal">
              <option value="modal">Modal</option>
              <option value="step">Step</option>
            </SelectInput>
            <FieldHint>
              {values.style === "modal" ? (
                <>Offer will be shown in a pop-up modal dialog.</>
              ) : (
                <>Offer will be shown as a full flow step.</>
              )}
            </FieldHint>
          </FieldInput>
        </FieldRow>
        <FieldRow>
          <FieldLabel>
            <label>
              Allow override{" "}
              <HelpIcon content="By default, an offer will not be displayed to a subscriber if they have previously accepted another offer. This setting will allow this offer to override any previously accepted offers." />
            </label>
          </FieldLabel>
          <FieldCheckboxInput>
            <input
              {...register("allowOverride")}
              id="allowOverride"
              type="checkbox"
            />
            <label htmlFor="allowOverride">Override other offers</label>
          </FieldCheckboxInput>
        </FieldRow>

        {enabledLanguages.length > 1 && (
          <div tw="mt-10">
            <LanguageRadio
              value={editingLanguage}
              languages={enabledLanguages}
              defaultLanguage={defaultLanguage}
              onChange={(language) => {
                setEditingLanguage(language);
                onChangeEditingLanguage(language);
              }}
            />
          </div>
        )}

        {platformHasFeature(
          platform,
          PlatformFeature.PauseSubscriptionSpecifyLength,
          { defaultForCustom: true }
        ) && (
          <>
            <FieldSet title="Pause details" disabled={mode === "edit"}>
              <FieldRow
                css={
                  !platformHasFeature(
                    platform,
                    PlatformFeature.PauseSubscriptionPauseAtEndOfPeriod
                  )
                    ? tw`hidden`
                    : undefined
                }
              >
                <FieldLabel>
                  <label>Pause at</label>
                </FieldLabel>
                <FieldInput>
                  <Controller
                    control={control}
                    name="pauseAt"
                    render={({ field }) => (
                      <PillRadio
                        value={field.value}
                        options={[
                          {
                            label: "Immediately",
                            value: "immediately",
                            tooltip: "Pause the subscription immediately",
                          },
                          {
                            label: "End of period",
                            value: "period_end",
                            tooltip:
                              "Pause the subscription at the end of the billing period",
                          },
                        ]}
                        onChange={field.onChange}
                      />
                    )}
                  />
                </FieldInput>
              </FieldRow>
              {values.option1Present && (
                <PauseSubscriptionOfferFormOption
                  mode={mode}
                  optionIndex={1}
                  optionCount={optionCount}
                  optionNumber={getNextOptionNumber()}
                  platform={platform}
                  editingLanguage={editingLanguage}
                  initialTextValue={
                    initialValues?.[editingLanguage]?.option1Text || undefined
                  }
                  initialTextValueKey={`option-1-${editingLanguage}`}
                  onDelete={() => {
                    setValue("option1Present", false);
                    setValue("option1Interval", defaultInterval(platform));
                    setValue("option1IntervalCount", null);
                    setValue("option1Text", null);
                    setValue("option1Metadata", JSON.stringify([]));
                  }}
                  register={register}
                  control={control}
                  formState={formState}
                  watch={watch}
                />
              )}

              {values.option2Present && (
                <PauseSubscriptionOfferFormOption
                  mode={mode}
                  optionIndex={2}
                  optionCount={optionCount}
                  optionNumber={getNextOptionNumber()}
                  platform={platform}
                  editingLanguage={editingLanguage}
                  initialTextValue={
                    initialValues?.[editingLanguage]?.option2Text || undefined
                  }
                  onDelete={() => {
                    setValue("option2Present", false);
                    setValue("option2Interval", defaultInterval(platform));
                    setValue("option2IntervalCount", null);
                    setValue("option2Text", null);
                    setValue("option2Metadata", JSON.stringify([]));
                  }}
                  register={register}
                  control={control}
                  formState={formState}
                  watch={watch}
                />
              )}

              {values.option3Present && (
                <PauseSubscriptionOfferFormOption
                  mode={mode}
                  optionIndex={3}
                  optionCount={optionCount}
                  optionNumber={getNextOptionNumber()}
                  platform={platform}
                  editingLanguage={editingLanguage}
                  initialTextValue={
                    initialValues?.[editingLanguage]?.option3Text || undefined
                  }
                  onDelete={() => {
                    setValue("option3Present", false);
                    setValue("option3Interval", defaultInterval(platform));
                    setValue("option3IntervalCount", null);
                    setValue("option3Text", null);
                    setValue("option3Metadata", JSON.stringify([]));
                  }}
                  register={register}
                  control={control}
                  formState={formState}
                  watch={watch}
                />
              )}

              {mode !== "edit" && (
                <Button
                  tw="mt-3 ml-0"
                  type="button"
                  buttonType="alternate-secondary"
                  size="sm"
                  onClick={() => {
                    if (!values.option1Present) {
                      setValue("option1Present", true);
                      return;
                    }

                    if (!values.option2Present) {
                      setValue("option2Present", true);
                      return;
                    }

                    if (!values.option3Present) {
                      setValue("option3Present", true);
                      return;
                    }
                  }}
                  disabled={optionCount >= 3}
                >
                  <FontAwesomeIcon icon={faPlus} /> Add option
                </Button>
              )}
            </FieldSet>
          </>
        )}

        <FieldSet title={`Offer ${values.style}`}>
          {values.style === "modal" && (
            <FieldRow>
              <FieldLabel>
                <label>Banner</label>
              </FieldLabel>
              <FieldCheckboxInput>
                <input
                  {...register("showBanner")}
                  id="showBanner"
                  type="checkbox"
                />
                <label htmlFor="showBanner">Show "Special Offer" banner</label>
              </FieldCheckboxInput>
            </FieldRow>
          )}
          <FieldRow>
            <FieldLabel>
              <label htmlFor="headline">Headline</label>
            </FieldLabel>
            <FieldInput>
              <TextInput
                {...register("headline", { validate: validateHeadline })}
                id="headline"
                width="full"
                fieldError={formState.errors.headline}
              />
              <FieldError error={formState.errors.headline} />
            </FieldInput>
          </FieldRow>
          <FieldRow>
            <FieldLabel>
              <label>Image</label>
            </FieldLabel>
            <FieldInput>
              <Controller
                control={control}
                name="image"
                render={({ field }) => (
                  <ImageInput
                    value={field.value}
                    onChange={(logoUrl) => {
                      field.onChange(logoUrl);
                      if (formState.isSubmitted) {
                        trigger();
                      }
                    }}
                  />
                )}
              />
            </FieldInput>
          </FieldRow>
          <FieldRowBlock>
            <FieldLabel>
              <label>Content</label>
            </FieldLabel>
            <Controller
              control={control}
              name="content"
              render={({ field }) => {
                const form = forms[editingLanguage];
                if (!form) {
                  return <></>;
                }

                return (
                  <EditorSelector
                    initialValueKey={editingLanguage}
                    isOfferContent
                    hasCustomProperties={!!features.custom_properties}
                    format={form.contentFormat}
                    value={form.content}
                    onChange={(value) => {
                      field.onChange(value);
                      onChange({
                        ...forms,
                        [editingLanguage]: {
                          ...forms[editingLanguage],
                          content: value,
                        },
                      });
                    }}
                  />
                );
              }}
            />
          </FieldRowBlock>
          <FieldRow>
            <FieldLabel>
              <label>Button text</label>
            </FieldLabel>
            <FieldInput>
              <TextInput
                {...register("buttonText", { required: true })}
                id="buttonText"
                width="md"
                fieldError={formState.errors.buttonText}
              />
            </FieldInput>
          </FieldRow>
        </FieldSet>

        <FieldSet title="Metadata">
          <FieldRow>
            <FieldInput>
              <Controller
                control={control}
                name="metadata"
                render={({ field }) => (
                  <MetadataInput
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
                rules={{
                  validate: validateMetadata,
                }}
              />
              <FieldError error={formState.errors.metadata} />
            </FieldInput>
          </FieldRow>
        </FieldSet>
      </fieldset>
    </form>
  );
};

export default PauseSubscriptionOfferForm;
