import { isPresent } from "ts-is-present";

import {
  property_format_enum,
  property_type_enum,
} from "../../__generated__/graphql";
import { formatUTC } from "../dates";
import formatCurrency from "../formatCurrency";
import { isLocationValue, SegmentConditionPropertyType } from "../segments/lib";

export default function formatPropertyValue(
  type: property_type_enum | SegmentConditionPropertyType,
  value: string | number | boolean | object,
  numberFormat?: property_format_enum | null,
  dateFormat: string = "MMMM d, yyyy",
  isArray?: boolean
) {
  const formatCurrencyValue = () => {
    let currencyVal = value;
    if (typeof currencyVal === "string") {
      currencyVal = Number(currencyVal || undefined);
    }

    if (typeof currencyVal !== "number") {
      throw new Error(`Invalid currency value: ${value}`);
    }

    if (isArray) {
      return String(value)
        .split(",")
        .map((val) => formatCurrency(Number(val.trim())))
        .filter(isPresent)
        .join(", ");
    }

    return formatCurrency(currencyVal);
  };

  switch (type) {
    case "number":
      switch (numberFormat) {
        case "number":
          if (isArray) {
            return String(value)
              .split(",")
              .map((val) => String(Number(val.trim()).toLocaleString()))
              .filter(isPresent)
              .join(", ");
          }

          return Number(value).toLocaleString();

        case "currency":
          return formatCurrencyValue();

        default:
          return Number(value);
      }

    case "currency":
      return formatCurrencyValue();

    case "percentage":
      return `${Number(value)}%`;

    case "boolean":
      if (isArray) {
        return String(value)
          .split(",")
          .map((val) => (val.trim() ? "True" : "False"))
          .filter(isPresent)
          .join(", ");
      }

      return value ? "True" : "False";

    case "date":
      if (isArray) {
        return String(value)
          .split(",")
          .map((val) => {
            if (typeof val !== "string") {
              throw new Error(`Invalid date value: ${val}`);
            }

            return formatUTC(new Date(val), dateFormat).replace(
              /^May\./,
              "May"
            );
          })
          .filter(isPresent)
          .join(", ");
      }

      if (typeof value !== "string") {
        throw new Error(`Invalid date value: ${value}`);
      }

      return formatUTC(new Date(value), dateFormat).replace(/^May\./, "May");

    case "location":
      if (!isLocationValue(value)) {
        throw new Error(`Invalid location value: ${value}`);
      }

      const { state, country } = value;

      return [state, country]
        .filter(isPresent)
        .map((v) => v.trim())
        .join(", ");

    case "text":
    case "string":
    case "id":
    case "select":
    default:
      if (isArray) {
        return String(value)
          .split(",")
          .map((val) => val.trim())
          .filter(isPresent)
          .join(", ");
      }

      return value;
  }
}
