import { account_feature_key_enum } from "../__generated__/graphql";

export const defaultFeatures: Record<
  account_feature_key_enum,
  boolean | number
> = {
  [account_feature_key_enum.ab_test_weights]: false,
  [account_feature_key_enum.api]: true,
  [account_feature_key_enum.conversion]: false,
  [account_feature_key_enum.custom_css]: true,
  [account_feature_key_enum.custom_properties]: true,
  [account_feature_key_enum.data_connectors]: false,
  [account_feature_key_enum.eligibility_messages]: false,
  [account_feature_key_enum.feedback_trends_report]: false,
  [account_feature_key_enum.flow_ab_tests]: false,
  [account_feature_key_enum.hubspot]: true,
  [account_feature_key_enum.integrations]: true,
  [account_feature_key_enum.intercom]: true,
  [account_feature_key_enum.interventions]: false,
  [account_feature_key_enum.klaviyo]: false,
  [account_feature_key_enum.limit_users]: 0,
  [account_feature_key_enum.linked_accounts]: true,
  [account_feature_key_enum.multiple_flows]: false,
  [account_feature_key_enum.offer_ab_tests]: false,
  [account_feature_key_enum.offer_autopilot]: false,
  [account_feature_key_enum.offer_groups]: false,
  [account_feature_key_enum.offer_rule_groups]: false,
  [account_feature_key_enum.retention]: true,
  [account_feature_key_enum.salesforce]: true,
  [account_feature_key_enum.segment]: true,
  [account_feature_key_enum.segment_groups]: false,
  [account_feature_key_enum.sessions]: 0,
  [account_feature_key_enum.show_branding]: false,
  [account_feature_key_enum.slack]: true,
  [account_feature_key_enum.sso]: false,
  [account_feature_key_enum.test_mode]: true,
  [account_feature_key_enum.translations]: false,
  [account_feature_key_enum.webhooks]: true,
  [account_feature_key_enum.zapier]: true,
};

export const featureTypes: Record<
  account_feature_key_enum,
  "boolean" | "number"
> = {
  [account_feature_key_enum.ab_test_weights]: "boolean",
  [account_feature_key_enum.api]: "boolean",
  [account_feature_key_enum.conversion]: "boolean",
  [account_feature_key_enum.custom_css]: "boolean",
  [account_feature_key_enum.custom_properties]: "boolean",
  [account_feature_key_enum.data_connectors]: "boolean",
  [account_feature_key_enum.eligibility_messages]: "boolean",
  [account_feature_key_enum.feedback_trends_report]: "boolean",
  [account_feature_key_enum.flow_ab_tests]: "boolean",
  [account_feature_key_enum.hubspot]: "boolean",
  [account_feature_key_enum.integrations]: "boolean",
  [account_feature_key_enum.intercom]: "boolean",
  [account_feature_key_enum.interventions]: "boolean",
  [account_feature_key_enum.klaviyo]: "boolean",
  [account_feature_key_enum.limit_users]: "number",
  [account_feature_key_enum.linked_accounts]: "boolean",
  [account_feature_key_enum.multiple_flows]: "boolean",
  [account_feature_key_enum.offer_ab_tests]: "boolean",
  [account_feature_key_enum.offer_autopilot]: "boolean",
  [account_feature_key_enum.offer_groups]: "boolean",
  [account_feature_key_enum.offer_rule_groups]: "boolean",
  [account_feature_key_enum.retention]: "boolean",
  [account_feature_key_enum.salesforce]: "boolean",
  [account_feature_key_enum.segment]: "boolean",
  [account_feature_key_enum.segment_groups]: "boolean",
  [account_feature_key_enum.sessions]: "number",
  [account_feature_key_enum.show_branding]: "boolean",
  [account_feature_key_enum.slack]: "boolean",
  [account_feature_key_enum.sso]: "boolean",
  [account_feature_key_enum.test_mode]: "boolean",
  [account_feature_key_enum.translations]: "boolean",
  [account_feature_key_enum.webhooks]: "boolean",
  [account_feature_key_enum.zapier]: "boolean",
};
