import tw, { styled } from "twin.macro";

export default styled.div`
  ${tw`p-4 overflow-y-auto`}

  max-height: calc(100vh - 10rem);

  > p:not(:last-child) {
    ${tw`mb-4`}
  }

  & .logo {
    ${tw`mt-1`}
  }
`;
