import { gql, useQuery } from "@apollo/client";

import { CouponIdDropdownQuery } from "../../__generated__/graphql";
import IdDropdown from "../../common/IdDropdown";

interface CouponIdDropdownProps {
  value: string;
  placeholder?: string;
  width?: string;
  onChange: (value: string) => void;
}

const CouponIdDropdown: React.FunctionComponent<CouponIdDropdownProps> = ({
  value,
  placeholder = "Select a coupon…",
  width = "200px",
  onChange,
}) => {
  const { data, loading } = useQuery<CouponIdDropdownQuery>(gql`
    query CouponIdDropdownQuery {
      platform_coupon {
        platform_id
        name
      }
    }
  `);

  const ids = (data?.platform_coupon || []).map((coupon) => ({
    id: coupon.platform_id,
    label: coupon.name || "",
  }));

  return (
    <IdDropdown
      ids={ids}
      value={value}
      onChange={onChange}
      isLoading={loading}
      width={width}
      placeholder={placeholder}
    />
  );
};

export default CouponIdDropdown;
