import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

export interface CloseXButtonProps {
  onClick: () => void;
}

const CloseX = styled.div`
  position: absolute;
  z-index: 50;
  top: 8px;
  right: 8px;
`;

const CloseXButton: React.FunctionComponent<CloseXButtonProps> = ({
  onClick,
}) => (
  <CloseX>
    <button className="flow-close-button" onClick={onClick}>
      <FontAwesomeIcon icon={faTimes} />
    </button>
  </CloseX>
);

export default CloseXButton;
