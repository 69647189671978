import { css } from "twin.macro";

interface WistiaProps {
  videoId: string;
  autoPlay: boolean;
}

const Wistia: React.FunctionComponent<WistiaProps> = ({
  videoId,
  autoPlay,
}) => (
  <>
    <div
      className="wistia_responsive_padding"
      css={css`
        padding: 56.25% 0 0 0;
        position: relative;
      `}
    >
      <div
        className="wistia_responsive_wrapper"
        css={css`
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
        `}
      >
        <iframe
          src={`https://fast.wistia.net/embed/iframe/${videoId}?videoFoam=true&autoPlay=${
            autoPlay ? "true" : "false"
          }`}
          title="Video"
          allow="autoplay; fullscreen"
          allowTransparency={true}
          frameBorder="0"
          scrolling="no"
          className="wistia_embed"
          name="wistia_embed"
          allowFullScreen
          width="100%"
          height="100%"
        ></iframe>
      </div>
    </div>
    <script
      src="https://fast.wistia.net/assets/external/E-v1.js"
      async
    ></script>
  </>
);

export default Wistia;
