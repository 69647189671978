import { faGift } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Temporal } from "@js-temporal/polyfill";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import {
  FlowOfferFragment,
  FlowSubscriptionFragment,
  offer_goal_enum,
  offer_style_enum,
  offer_type_enum,
  PauseReason,
} from "../../../__generated__/graphql";
import { useTranslations } from "../../../common/translations/TranslationsProvider";
import offerIcon from "../../offers/lib/offerIcon";
import { FlowError, FlowText } from "./lib/types";
import OfferButtons from "./OfferButtons";
import OfferContent from "./OfferContent";
import PauseForm from "./PauseForm";
import ProductSwapForm from "./ProductSwapForm";
import RescheduleOrderForm, {
  RescheduleOrderFormOption,
} from "./RescheduleOrderForm";
import CloseXButton from "./ui/CloseXButton";
import CountdownTimer from "./ui/CountdownTimer";
import FlowErrorAlert from "./ui/FlowErrorAlert";

interface OfferContainerProps {
  offer: FlowOfferFragment | null;
  previewMode: boolean;
  baseClassName: string;
  disableVideoAutoPlay?: boolean;
  onImageLoad?: () => void;
  onDismiss?: () => void;
  pauseReasons?: PauseReason[];
  flowText: FlowText;
  onAccept: (
    selectedOptionIndex: number,
    groupOffer: null,
    selectedVariantId: string | null | undefined,
    rescheduleTo: Temporal.PlainDate | null | undefined,
    pauseReasonCode: string | null | undefined,
    pauseAt: Temporal.PlainDate | null | undefined,
    resumeAt: Temporal.PlainDate | null | undefined
  ) => void;
  onAcceptGroupOffer: (
    selectedOptionIndex: number,
    offerId: string,
    selectedVariantId: string | null | undefined,
    rescheduleTo: Temporal.PlainDate | null | undefined,
    pauseReasonCode: string | null | undefined,
    pauseAt: Temporal.PlainDate | null | undefined,
    resumeAt: Temporal.PlainDate | null | undefined
  ) => void;
  onDecline: () => void;
  isFinalStep: boolean;
  nextOrderDate?: Temporal.PlainDate;
  swappableProducts?: FlowSubscriptionFragment | null;
  startTimerFrom?: Date | null | undefined;
  showCloseX?: boolean;
  showDeclineButton?: boolean;
  error?: FlowError;
  offerButtonsKey?: string;
  onClickPauseOption?: (id: number) => void;
  isGrouped?: boolean;
  showImages?: boolean;
  isCanceling?: boolean;
}

const OfferContainer: React.FunctionComponent<OfferContainerProps> = ({
  offer,
  previewMode,
  baseClassName,
  disableVideoAutoPlay,
  onImageLoad,
  onDismiss = () => {},
  pauseReasons,
  flowText,
  onAccept,
  onAcceptGroupOffer,
  onDecline,
  isFinalStep,
  nextOrderDate,
  swappableProducts = null,
  startTimerFrom = null,
  showDeclineButton = true,
  showCloseX = false,
  error,
  offerButtonsKey,
  onClickPauseOption = () => {},
  isGrouped = false,
  showImages = true,
  isCanceling,
  ...props
}) => {
  const { language, defaultLanguage } = useTranslations();
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(0);
  const [selectedVariantId, setSelectedVariantId] = useState<string | null>(
    null
  );
  const [isDismissing, setIsDismissing] = useState(false);
  const [selectedRescheduleOption, setSelectedRescheduleOption] =
    useState<RescheduleOrderFormOption>("skip");

  const [rescheduleDate, setRescheduleDate] = useState(nextOrderDate);
  const [pauseReasonCode, setPauseReasonCode] = useState(
    pauseReasons?.length === 1 ? pauseReasons[0].code : undefined
  );

  const selectedPauseReason = pauseReasons?.find(
    (r) => r.code === pauseReasonCode
  );

  const [pauseDate, setPauseDate] = useState(
    selectedPauseReason?.minimumStartDate
      ? Temporal.PlainDate.from(selectedPauseReason.minimumStartDate)
      : Temporal.Now.plainDateISO()
  );
  const [resumeDate, setResumeDate] = useState<Temporal.PlainDate>();

  useEffect(() => {
    setSelectedOptionIndex(0);
  }, [
    offer?.offer_pause_subscription?.offer_pause_subscription_options.length,
  ]);

  useEffect(() => {
    if (!rescheduleDate) {
      setRescheduleDate(nextOrderDate);
    }
  }, [nextOrderDate, rescheduleDate]);

  const handleDismiss = () => {
    setIsDismissing(true);
    setTimeout(() => {
      onDismiss();
    }, 100);
  };

  const bodyClasses = classNames({
    [`${baseClassName}__body`]: true,
    [`${baseClassName}__body--banner`]:
      offer?.style === offer_style_enum.banner,
    [`${baseClassName}__body--conversion`]:
      offer?.goal === offer_goal_enum.conversion,
  });

  const getDelayedDateFromString = (
    delayString: string,
    startedAt?: Date | null | undefined
  ) => {
    const delayParts = delayString.split(":");
    let date = startedAt ? startedAt.getTime() : Date.now();
    if (delayParts.length >= 1) {
      date += parseInt(delayParts[0]) * 60 * 60 * 1000;
    }
    if (delayParts.length >= 2) {
      date += parseInt(delayParts[1]) * 60 * 1000;
    }
    if (delayParts.length >= 3) {
      date += parseInt(delayParts[2]) * 1000;
    }

    return date;
  };

  if (offer?.offer_reschedule_order) {
    if (selectedRescheduleOption === "skip") {
      offer = {
        ...offer,
        button_text_translation:
          offer.offer_reschedule_order.skip_button_text_translation!,
      };
    } else if (selectedRescheduleOption === "reschedule") {
      offer = {
        ...offer,
        button_text_translation:
          offer.offer_reschedule_order.reschedule_button_text_translation!,
      };
    }
  }

  const selectedPauseOption = offer?.offer_pause_subscription
    ?.offer_pause_subscription_options.length
    ? offer.offer_pause_subscription.offer_pause_subscription_options[
        selectedOptionIndex
      ]
    : undefined;

  const image = offer?.type ? offerIcon(offer?.type) : faGift;

  if (!offer) {
    return null;
  }

  return (
    <>
      {isGrouped && showImages ? (
        !!offer.image_url ? (
          <img
            className={`${baseClassName}__image`}
            src={offer.image_url}
            alt=""
            onLoad={() => onImageLoad && onImageLoad()}
          />
        ) : (
          <div className={`${baseClassName}__no-image`}>
            <div className={`${baseClassName}__circle-wrapper`}>
              <div className={`${baseClassName}__circle`}>
                <FontAwesomeIcon
                  icon={image}
                  fixedWidth
                  transform="down-8 right-6"
                  size="2x"
                />
              </div>
            </div>
          </div>
        )
      ) : null}

      {offer.show_banner && offer?.style === offer_style_enum.modal && (
        <div className={`${baseClassName}__ribbon`}>
          <FormattedMessage defaultMessage="Special Offer" id="+XCEzH" />
        </div>
      )}

      <div
        className={bodyClasses}
        data-vulcan="offer"
        data-vulcan-id={offer.token}
      >
        {showCloseX && offer?.style === "modal" && (
          <CloseXButton onClick={handleDismiss} data-vulcan="dismiss" />
        )}

        {!!error && (
          <FlowErrorAlert
            error={error}
            className={classNames({
              "flow-error--has-ribbon":
                !!offer?.show_banner && offer.style === offer_style_enum.modal,
            })}
          />
        )}

        <OfferContent
          offer={offer}
          previewMode={previewMode}
          onImageLoad={onImageLoad}
          language={language}
          defaultLanguage={defaultLanguage}
          baseClassName={baseClassName}
          disableVideoAutoPlay={disableVideoAutoPlay}
          showImage={!isGrouped}
        />

        <div className={`${baseClassName}__options`}>
          {offer.offer_pause_subscription && (
            <PauseForm
              offer={offer}
              baseClassName={baseClassName}
              pauseReasons={pauseReasons}
              onClickPauseOption={setSelectedOptionIndex}
              onClickPauseReason={setPauseReasonCode}
              pauseDate={pauseDate}
              resumeDate={resumeDate}
              onChangeStartDate={setPauseDate}
              onChangeEndDate={setResumeDate}
            />
          )}

          {offer.type === offer_type_enum.product_swap && (
            <div className={`${baseClassName}__product-swap`}>
              <ProductSwapForm
                offer={offer}
                setSelectedVariantId={setSelectedVariantId}
                swappableProducts={swappableProducts}
              />
            </div>
          )}

          {offer.type === offer_type_enum.reschedule_order &&
            offer.offer_reschedule_order?.reschedule && (
              <div className={`${baseClassName}__reschedule-order`}>
                <RescheduleOrderForm
                  selectedOption={selectedRescheduleOption}
                  nextOrderDate={nextOrderDate}
                  rescheduleDate={rescheduleDate}
                  onChangeSelectedOption={(option) =>
                    setSelectedRescheduleOption(option)
                  }
                  onChangeRescheduleDate={(date) => setRescheduleDate(date)}
                />
              </div>
            )}

          {offer.goal === offer_goal_enum.conversion &&
            offer.offer_timer?.show_timer &&
            (previewMode || offer.offer_timer.end_time ? (
              <CountdownTimer
                banner={offer?.style === "banner"}
                targetDate={offer.offer_timer.end_time || 0}
              />
            ) : (
              offer.offer_timer.duration && (
                <CountdownTimer
                  banner={offer?.style === "banner"}
                  targetDate={getDelayedDateFromString(
                    offer.offer_timer.duration,
                    startTimerFrom
                  )}
                />
              )
            ))}
        </div>
      </div>

      <div
        className={classNames(
          offer.style === "step"
            ? {
                [`${baseClassName}__buttons`]: true,
              }
            : {
                [`${baseClassName}__footer`]: true,
                [`${baseClassName}__footer--convert`]:
                  offer?.goal === offer_goal_enum.conversion,
                [`${baseClassName}__footer--banner`]:
                  offer?.style === offer_style_enum.banner,
              }
        )}
      >
        <OfferButtons
          key={offerButtonsKey}
          offer={offer}
          flowText={flowText}
          previewMode={previewMode}
          language={language}
          defaultLanguage={defaultLanguage}
          isFinalStep={isFinalStep}
          showDeclineButton={showDeclineButton}
          showCloseX={showCloseX && offer?.style === "banner"}
          onAccept={() => {
            isGrouped
              ? onAcceptGroupOffer(
                  selectedOptionIndex,
                  offer!.token.toString(),
                  selectedVariantId,
                  selectedRescheduleOption === "reschedule"
                    ? rescheduleDate
                    : undefined,
                  pauseReasonCode,
                  selectedPauseOption?.type === "date" ? pauseDate : undefined,
                  selectedPauseOption?.type === "date" ? resumeDate : undefined
                )
              : onAccept(
                  selectedOptionIndex,
                  null,
                  selectedVariantId,
                  selectedRescheduleOption === "reschedule"
                    ? rescheduleDate
                    : undefined,
                  pauseReasonCode,
                  selectedPauseOption?.type === "date" ? pauseDate : undefined,
                  selectedPauseOption?.type === "date" ? resumeDate : undefined
                );
          }}
          onDecline={onDecline}
          onDismiss={handleDismiss}
          isDismissing={isDismissing}
          isDisabled={
            isCanceling ||
            (offer?.type === offer_type_enum.product_swap &&
              !selectedVariantId) ||
            (offer?.type === "reschedule_order" &&
              selectedRescheduleOption === "reschedule" &&
              rescheduleDate &&
              nextOrderDate &&
              rescheduleDate.equals(nextOrderDate)) ||
            (offer?.type === "pause_subscription" &&
              ((!!pauseReasons && !pauseReasonCode) ||
                (selectedPauseOption?.type === "date" &&
                  (!pauseDate || !resumeDate))))
          }
          error={error}
        />
      </div>
    </>
  );
};

export default OfferContainer;
