import { useEffect, useState } from "react";

export default function useFocusFirstEmptyInput(
  ref: HTMLFormElement | null | undefined
) {
  const [input, setInput] = useState<HTMLInputElement>();

  useEffect(() => {
    if (!ref || input) {
      return;
    }

    const inputs = ref.querySelectorAll<HTMLInputElement>(
      'input:not([type="hidden"]):not([type="file"])'
    );

    let firstInput: HTMLInputElement | undefined = undefined;

    for (const input of inputs) {
      if (!firstInput) {
        firstInput = input;
      }

      if (input.value === "") {
        setInput(input);
        return;
      }
    }

    if (firstInput) {
      setInput(firstInput);
    }
  }, [input, ref]);

  useEffect(() => {
    if (input) {
      window.setTimeout(() => {
        input.focus();
      }, 1);
    }
  }, [input]);
}
