import { faPencilAlt, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { nanoid } from "nanoid";
import { useState } from "react";
import { Transforms } from "slate";
import {
  ReactEditor,
  RenderElementProps,
  useEditor,
  useFocused,
  useSelected,
} from "slate-react";
import tw, { css, styled } from "twin.macro";

import Video from "../../../../features/flow/video/Video";
import InsertVideoModal from "../../InsertVideoModal";
import { ELEMENT_VIDEO } from ".";

const EditButton = styled.button`
  ${tw`flex justify-between items-center bg-gray-200 p-1 rounded border-gray-400 opacity-75 hover:opacity-100 focus:outline-none
  disabled:text-gray-400 disabled:opacity-100 disabled:cursor-default absolute z-10`}
  margin-top: 9px;
  top: 0px;
  left: 10px;
`;

const DeleteButton = styled.button`
  ${tw`flex justify-between items-center bg-red-500 text-white p-1 rounded border-gray-400 opacity-75 hover:opacity-100 focus:outline-none
  disabled:text-gray-400 disabled:opacity-100 disabled:cursor-default absolute z-10`}
  margin-top: 9px;
  top: 0px;
  left: 40px;
`;

const VideoElement: React.FunctionComponent<RenderElementProps> = ({
  attributes,
  element,
  children,
}) => {
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [editModalKey, setEditModalKey] = useState(nanoid());

  const isFocused = useFocused();
  const isSelected = useSelected();

  const editor = useEditor();
  const path = ReactEditor.findPath(editor, element);

  return (
    <div {...attributes}>
      <div
        contentEditable={false}
        css={[
          tw`mb-3 relative`,
          css`
            width: 300px;
          `,
          isFocused && isSelected
            ? tw`border-blue-500 border-2`
            : tw`border-gray-400 border-2`,
        ]}
      >
        <div tw="absolute top-0 left-0 w-full h-full z-10" />
        <EditButton
          onMouseDown={(e) => {
            e.preventDefault();
            setEditModalIsOpen(true);
          }}
        >
          <FontAwesomeIcon icon={faPencilAlt} />
        </EditButton>
        <DeleteButton
          onMouseDown={(e) => {
            e.preventDefault();
            Transforms.removeNodes(editor, { at: path });
          }}
        >
          <FontAwesomeIcon icon={faTrash} />
        </DeleteButton>
        <Video url={element.url as string} autoPlay={false}></Video>
        <InsertVideoModal
          key={editModalKey}
          mode="edit"
          initialValues={{
            url: element.url as string,
            autoPlay: (element.options as any).autoPlay,
          }}
          isOpen={editModalIsOpen}
          onClose={(values) => {
            setEditModalIsOpen(false);
            setEditModalKey(nanoid());

            if (values) {
              Transforms.setNodes(
                editor,
                {
                  type: ELEMENT_VIDEO,
                  url: values.url,
                  options: {
                    autoPlay: values.autoPlay,
                  },
                  children: [{ text: "" }],
                },
                { at: path, mode: "all" }
              );
            }

            Transforms.select(editor, path);
            ReactEditor.focus(editor);
          }}
        />
      </div>
      {children}
    </div>
  );
};

export default VideoElement;
