import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import tw from "twin.macro";

import IdDropdown from "../../common/IdDropdown";

interface MultipleIdsDropdownProps {
  ids: Array<{ id: string; label: string }>;
  value: string[];
  onChange: (value: string[]) => void;
  isLoading: boolean;
  width?: string;
  placeholder?: string;
}

const DropdownWrapper = tw.div`flex mt-2 first:mt-0`;

const DeleteButton = tw.button`flex justify-between items-center text-gray-400 hover:text-gray-500 focus:outline-none disabled:opacity-0 disabled:cursor-default ml-2`;

const MultipleIdsDropdown: React.FunctionComponent<
  MultipleIdsDropdownProps
> = ({ ids, value, onChange, isLoading, width, placeholder }) => {
  const valueObject = value.reduce<Record<string, string>>(
    (prev, value, i) => ({
      ...prev,
      [i.toString()]: value,
    }),
    {}
  );

  const handleValueChange = (value: string, index: number) => {
    onChange(
      Object.values({
        ...valueObject,
        [index.toString()]: value,
      })
    );
  };

  const handleClickDelete = (index: number) => {
    const clonedValues = { ...valueObject };
    delete clonedValues[index.toString()];

    const newValues = Object.values(clonedValues).reduce(
      (prev, value, i) => ({
        ...prev,
        [i.toString()]: value,
      }),
      {}
    );

    onChange(Object.values(newValues));
  };

  const count = value.length + 1;

  const inputs = [...Array(count).keys()].map((i) => {
    const filteredIds = ids.filter(
      (id) => !value.includes(id.id) || valueObject[i.toString()] === id.id
    );

    return (
      <DropdownWrapper key={i}>
        <IdDropdown
          ids={filteredIds}
          value={valueObject[i.toString()]}
          onChange={(value) => handleValueChange(value, i)}
          width={width}
          placeholder={placeholder}
        />
        <DeleteButton
          type="button"
          onClick={() => handleClickDelete(i)}
          disabled={!valueObject[i.toString()]}
        >
          <FontAwesomeIcon icon={faXmark} />
        </DeleteButton>
      </DropdownWrapper>
    );
  });

  return <div>{inputs}</div>;
};

export default MultipleIdsDropdown;
