import gql from "graphql-tag";

export default gql`
  fragment OfferTimerFragment on offer_timer {
    offer_timer_mode
    start_time
    end_time
    duration
    show_timer
  }
`;
