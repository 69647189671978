import { useMutation } from "@apollo/client";
import gql from "graphql-tag";

import {
  DeleteOfferTimerMutation,
  DeleteOfferTimerMutationVariables,
} from "../../../__generated__/graphql";

export default function useDeleteOfferTimer() {
  const [deleteOfferTimer] = useMutation<
    DeleteOfferTimerMutation,
    DeleteOfferTimerMutationVariables
  >(
    gql`
      mutation DeleteOfferTimerMutation($offerId: Int!) {
        delete_offer_timer_by_pk(offer_id: $offerId) {
          offer_id
          offer_timer_mode
          start_time
          end_time
          duration
          show_timer
        }
      }
    `
  );

  return deleteOfferTimer;
}
