import { faPencilAlt, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useLexicalNodeSelection } from "@lexical/react/useLexicalNodeSelection";
import { mergeRegister } from "@lexical/utils";
import classNames from "classnames";
import {
  $getNodeByKey,
  $getSelection,
  $isNodeSelection,
  CLICK_COMMAND,
  COMMAND_PRIORITY_LOW,
  DecoratorNode,
  EditorConfig,
  KEY_BACKSPACE_COMMAND,
  KEY_DELETE_COMMAND,
  LexicalNode,
  NodeKey,
} from "lexical";
import { nanoid } from "nanoid";
import { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import tw, { css } from "twin.macro";

import InsertImageModal from "../../editor/InsertImageModal";

interface ImageComponentProps {
  src: string;
  nodeKey: NodeKey;
}

export interface SerializedImageNode {
  type: "image";
  version: number;
  src: string;
}

const EditButton = tw.button`flex justify-between items-center bg-gray-200 p-1 rounded border-gray-400 opacity-75 hover:opacity-100 focus:outline-none
  disabled:text-gray-400 disabled:opacity-100 disabled:cursor-default absolute z-10`;

const DeleteButton = tw.button`flex justify-between items-center bg-red-500 text-white p-1 rounded border-gray-400 opacity-75 hover:opacity-100 focus:outline-none
  disabled:text-gray-400 disabled:opacity-100 disabled:cursor-default absolute z-10`;

const ImageComponent: React.FunctionComponent<ImageComponentProps> = ({
  src,
  nodeKey,
}) => {
  const ref = useRef<HTMLImageElement>(null);
  const [editor] = useLexicalComposerContext();
  const [isSelected, setSelected, clearSelection] =
    useLexicalNodeSelection(nodeKey);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [editModalKey, setEditModalKey] = useState(nanoid());

  const onDelete = useCallback(
    (payload) => {
      if (isSelected && $isNodeSelection($getSelection())) {
        const event: KeyboardEvent = payload;
        event.preventDefault();
        editor.update(() => {
          const node = $getNodeByKey(nodeKey);
          if (node && $isImageNode(node)) {
            node.remove();
          }
          setSelected(false);
        });
      }
      return false;
    },
    [editor, isSelected, nodeKey, setSelected]
  );

  useEffect(
    () =>
      mergeRegister(
        editor.registerCommand<MouseEvent>(
          CLICK_COMMAND,
          (payload) => {
            const event = payload;

            if (event.target === ref.current) {
              if (!event.shiftKey) {
                clearSelection();
              }
              setSelected(!isSelected);
              return true;
            }

            return false;
          },
          COMMAND_PRIORITY_LOW
        ),
        editor.registerCommand(
          KEY_DELETE_COMMAND,
          onDelete,
          COMMAND_PRIORITY_LOW
        ),
        editor.registerCommand(
          KEY_BACKSPACE_COMMAND,
          onDelete,
          COMMAND_PRIORITY_LOW
        )
      ),
    [clearSelection, editor, isSelected, onDelete, setSelected]
  );

  return (
    <>
      <div
        className={classNames({
          editing: editor.isEditable(),
          focused: isSelected,
        })}
      >
        {editor.isEditable() && isSelected && (
          <>
            <EditButton
              onClick={() => setEditModalIsOpen(true)}
              css={css`
                top: 9px;
                left: 10px;
              `}
            >
              <FontAwesomeIcon icon={faPencilAlt} />
            </EditButton>
            <DeleteButton
              css={css`
                top: 9px;
                left: 40px;
              `}
              onClick={() => {
                editor.update(() => {
                  const node = $getNodeByKey(nodeKey);
                  if (node && $isImageNode(node)) {
                    node.remove();
                  }
                  setSelected(false);
                });
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
            </DeleteButton>
          </>
        )}

        <img ref={ref} src={src} alt="" />
      </div>
      <InsertImageModal
        key={editModalKey}
        mode="edit"
        initialValues={{ url: src }}
        isOpen={editModalIsOpen}
        onClose={(values) => {
          setEditModalIsOpen(false);
          setEditModalKey(nanoid());

          if (values) {
            editor.update(() => {
              const node = $getNodeByKey(nodeKey);
              if (node && $isImageNode(node)) {
                node.setSrc(values.url);
              }
            });
          }
        }}
      />
    </>
  );
};

export class ImageNode extends DecoratorNode<ReactNode> {
  __src: string;

  static getType(): string {
    return "image";
  }

  static clone(node: ImageNode): ImageNode {
    return new ImageNode(node.__src, node.__key);
  }

  constructor(src: string, key?: NodeKey) {
    super(key);
    this.__src = src;
  }

  createDOM(config: EditorConfig): HTMLElement {
    const el = document.createElement("div");
    const theme = config.theme;
    const className = theme.image;
    if (className !== undefined) {
      el.className = className;
    }
    return el;
  }

  updateDOM() {
    return false;
  }

  setSrc(src: string) {
    const writable = this.getWritable();
    writable.__src = src;
  }

  decorate() {
    return <ImageComponent src={this.__src} nodeKey={this.getKey()} />;
  }

  static importJSON(serializedNode: SerializedImageNode) {
    return $createImageNode(serializedNode.src);
  }

  exportJSON(): SerializedImageNode {
    return {
      type: "image",
      version: 1,
      src: this.__src,
    };
  }
}

const $createImageNode = (src: string) => new ImageNode(src);

const $isImageNode = (node?: LexicalNode): node is ImageNode =>
  node instanceof ImageNode;

export { $createImageNode, $isImageNode };
