import gql from "graphql-tag";

import FlowTextVersionFragment from "./FlowTextVersionFragment";

export default gql`
  fragment FlowTextFragment on flow_text {
    flow_id
    key
    published_version {
      ...FlowTextVersionFragment
    }
    draft_version @include(if: $includeDrafts) {
      ...FlowTextVersionFragment
    }
  }
  ${FlowTextVersionFragment}
`;
