import gql from "graphql-tag";

import FlowOfferRuleVersionFragment from "./FlowOfferRuleVersionFragment";

export default gql`
  fragment FlowOfferRuleFragment on offer_rule {
    id
    published_version {
      ...FlowOfferRuleVersionFragment
    }
    draft_version @include(if: $includeDrafts) {
      ...FlowOfferRuleVersionFragment
    }
  }
  ${FlowOfferRuleVersionFragment}
`;
