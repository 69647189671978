import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { LexicalEditor } from "lexical";
import { useEffect } from "react";

interface EditorRefPluginProps {
  editorRef?: (editor: LexicalEditor) => void;
}

const EditorRefPlugin: React.FunctionComponent<EditorRefPluginProps> = ({
  editorRef,
}) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (editor && editorRef) {
      editorRef(editor);
    }
  }, [editor, editorRef]);

  return null;
};

export default EditorRefPlugin;
