import { SlatePlugin } from "@udecode/slate-plugins";

export default function InlinePlugin(): SlatePlugin {
  return {
    onKeyDown(event, editor) {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    },
  };
}
