import { faPlus, faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { theme } from "twin.macro";

import Button from "./form/Button";
import TextInput from "./form/input/TextInput";

interface InputProps {
  name: string;
  value: string;
  canDelete: boolean;
  onChangeName: (key: string) => void;
  onChangeValue: (value: string) => void;
  onDelete: () => void;
  disabled?: boolean;
}

const Input: React.FunctionComponent<InputProps> = ({
  name,
  value,
  canDelete,
  onChangeName,
  onChangeValue,
  onDelete,
  disabled = false,
}) => (
  <div tw="flex items-center">
    <div tw="w-[30%]">
      <TextInput
        placeholder="Key"
        value={name}
        onChange={(e) => onChangeName(e.currentTarget.value)}
        width="full"
        disabled={disabled}
      />
    </div>
    <div tw="flex-grow ml-2">
      <TextInput
        placeholder="Value"
        value={value}
        onChange={(e) => onChangeValue(e.currentTarget.value)}
        width="full"
        disabled={disabled}
      />
    </div>
    <div tw="ml-2">
      {canDelete && !disabled && (
        <button
          type="button"
          tw="opacity-75 hover:opacity-100 focus:outline-none"
          onClick={onDelete}
        >
          <FontAwesomeIcon icon={faTimesCircle} color={theme`colors.red.500`} />
        </button>
      )}
    </div>
  </div>
);

interface MetadataInputProps {
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
}

const MetadataInput: React.FunctionComponent<MetadataInputProps> = ({
  value,
  onChange,
  disabled = false,
}) => {
  const records: Array<{ key: string; value: string }> = JSON.parse(value);

  if (records.length === 0) {
    records.push({ key: "", value: "" });
  }

  return (
    <div>
      <div>
        {records.map(({ key, value }, i) => (
          <div key={i} tw="mb-2 last:mb-0">
            <Input
              name={key}
              value={value}
              disabled={disabled}
              onChangeName={(name) => {
                const newRecords = [...records];
                newRecords[i].key = name;
                onChange(JSON.stringify(newRecords));
              }}
              onChangeValue={(value) => {
                const newRecords = [...records];
                newRecords[i].value = value;
                onChange(JSON.stringify(newRecords));
              }}
              canDelete={true}
              onDelete={() => {
                const newRecords = [...records];
                newRecords.splice(i, 1);
                onChange(JSON.stringify(newRecords));
              }}
            />
          </div>
        ))}
      </div>
      <div tw="mt-3">
        <Button
          tw="py-1"
          type="button"
          buttonType="alternate-secondary"
          size="sm"
          onClick={() => {
            const newRecords = [...records];
            newRecords.push({ key: "", value: "" });
            onChange(JSON.stringify(newRecords));
          }}
          disabled={disabled}
        >
          <FontAwesomeIcon icon={faPlus} /> Add item
        </Button>
      </div>
    </div>
  );
};

export default MetadataInput;
