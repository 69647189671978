import { ReactNode } from "react";

import {
  language_enum,
  offer_goal_enum,
} from "../../../../__generated__/graphql";
import createIntl from "../../../../common/i18n";

export default function getCouponDescription(
  type: string | null | undefined,
  amountOff: number | null | undefined,
  duration: string | null | undefined,
  durationInterval: string | null | undefined,
  durationCount: number | null | undefined,
  months: number | null | undefined,
  goal: offer_goal_enum = offer_goal_enum.retention,
  language: language_enum,
  subscriberFacing: boolean = true,
  renderPlaceholderFn: (children: ReactNode) => ReactNode = (children) =>
    children?.toString(),
  prefix: string = ""
) {
  const intl = createIntl(language);

  const placeholder = renderPlaceholderFn;

  const cleanPrefix = prefix ? `${prefix.trimEnd()} ` : prefix;

  switch (type) {
    case "percentage":
      switch (duration) {
        case "once":
          if (Number(amountOff) > 0) {
            return subscriberFacing
              ? goal === "retention"
                ? intl.formatMessage(
                    {
                      defaultMessage:
                        "{cleanPrefix}{amountOff, number, ::percent} off your next invoice",
                      id: "l/YdlJ",
                    },
                    { cleanPrefix, amountOff: Number(amountOff) / 100 }
                  )
                : intl.formatMessage(
                    {
                      defaultMessage:
                        "{cleanPrefix}{amountOff, number, ::percent} off your first invoice",
                      id: "xKyfHR",
                    },
                    { cleanPrefix, amountOff: Number(amountOff) / 100 }
                  )
              : goal === "retention"
              ? intl.formatMessage(
                  {
                    defaultMessage:
                      "{cleanPrefix}{amountOff, number, ::percent} off the next invoice",
                    id: "9SUlOo",
                  },
                  { cleanPrefix, amountOff: Number(amountOff) / 100 }
                )
              : intl.formatMessage(
                  {
                    defaultMessage:
                      "{cleanPrefix}{amountOff, number, ::percent} off the first invoice",
                    id: "09oJ4T",
                  },
                  { cleanPrefix, amountOff: Number(amountOff) / 100 }
                );
          }

          return subscriberFacing
            ? goal === "retention"
              ? intl.formatMessage(
                  {
                    defaultMessage:
                      "{cleanPrefix}<placeholder>'<amount>'</placeholder> off your next invoice",
                    id: "SKBdmn",
                  },
                  { cleanPrefix, placeholder }
                )
              : intl.formatMessage(
                  {
                    defaultMessage:
                      "{cleanPrefix}<placeholder>'<amount>'</placeholder> off your first invoice",
                    id: "ZMMBFY",
                  },
                  { cleanPrefix, placeholder }
                )
            : goal === "retention"
            ? intl.formatMessage(
                {
                  defaultMessage:
                    "{cleanPrefix}<placeholder>'<amount>'</placeholder> off the next invoice",
                  id: "cmHX+f",
                },
                { cleanPrefix, placeholder }
              )
            : intl.formatMessage(
                {
                  defaultMessage:
                    "{cleanPrefix}<placeholder>'<amount>'</placeholder> off the first invoice",
                  id: "SEMrHY",
                },
                { cleanPrefix, placeholder }
              );

        case "forever":
          if (Number(amountOff) > 0) {
            return intl.formatMessage(
              {
                defaultMessage:
                  "{cleanPrefix}{amountOff, number, ::percent} off all future invoices",
                id: "+rjmn5",
              },
              { cleanPrefix, amountOff: Number(amountOff) / 100 }
            );
          }

          return intl.formatMessage(
            {
              defaultMessage:
                "{cleanPrefix}<placeholder>'<amount>'</placeholder> off all future invoices",
              id: "IAcOHV",
            },
            { cleanPrefix, placeholder }
          );

        case "repeating":
          if (
            Number(amountOff) > 0 &&
            durationInterval === "month" &&
            Number(durationCount) > 0
          ) {
            return intl.formatMessage(
              {
                defaultMessage:
                  "{cleanPrefix}{amountOff, number, ::percent} off for {months, number} {months, plural, one {month} other {months}}",
                id: "RvO8gw",
              },
              {
                cleanPrefix,
                amountOff: Number(amountOff) / 100,
                months: durationCount,
                placeholder,
              }
            );
          }

          if (
            Number(amountOff) > 0 &&
            durationInterval === "day" &&
            Number(durationCount) > 0
          ) {
            return intl.formatMessage(
              {
                defaultMessage:
                  "{cleanPrefix}{amountOff, number, ::percent} off for {days, number} {days, plural, one {day} other {days}}",
                id: "tOEW5u",
              },
              {
                cleanPrefix,
                amountOff: Number(amountOff) / 100,
                days: durationCount,
              }
            );
          }

          if (
            Number(amountOff) > 0 &&
            durationInterval === "week" &&
            Number(durationCount) > 0
          ) {
            return intl.formatMessage(
              {
                defaultMessage:
                  "{cleanPrefix}{amountOff, number, ::percent} off for {weeks, number} {weeks, plural, one {week} other {weeks}}",
                id: "AaejTe",
              },
              {
                cleanPrefix,
                amountOff: Number(amountOff) / 100,
                weeks: durationCount,
              }
            );
          }

          if (
            Number(amountOff) > 0 &&
            durationInterval === "year" &&
            Number(durationCount) > 0
          ) {
            return intl.formatMessage(
              {
                defaultMessage:
                  "{cleanPrefix}{amountOff, number, ::percent} off for {years, number} {years, plural, one {year} other {years}}",
                id: "Ev7Pq2",
              },
              {
                cleanPrefix,
                amountOff: Number(amountOff) / 100,
                years: durationCount,
              }
            );
          }

          if (Number(amountOff) > 0 && Number(months) > 0) {
            return intl.formatMessage(
              {
                defaultMessage:
                  "{cleanPrefix}{amountOff, number, ::percent} off for {months, number} {months, plural, one {month} other {months}}",
                id: "RvO8gw",
              },
              { cleanPrefix, amountOff: Number(amountOff) / 100, months }
            );
          }

          if (Number(amountOff) > 0) {
            return intl.formatMessage(
              {
                defaultMessage:
                  "{cleanPrefix}{amountOff, number, ::percent} off for <placeholder>'<number>'</placeholder> months",
                id: "i2LyyU",
              },
              { cleanPrefix, amountOff: Number(amountOff) / 100, placeholder }
            );
          }

          if (Number(months) > 0) {
            return intl.formatMessage(
              {
                defaultMessage:
                  "{cleanPrefix}<placeholder>'<amount>'</placeholder> off for {months, number} {months, plural, one {month} other {months}}",
                id: "9dfb//",
              },
              { cleanPrefix, months, placeholder }
            );
          }

          return intl.formatMessage(
            {
              defaultMessage:
                "{cleanPrefix}<placeholder>'<amount>'</placeholder> off for <placeholder>'<number>'</placeholder> months",
              id: "dlOWv7",
            },
            { cleanPrefix, placeholder }
          );

        default:
          throw new Error(`Invalid duration ${duration}`);
      }

    case "fixed":
      switch (duration) {
        case "once":
          if (Number(amountOff) > 0) {
            return subscriberFacing
              ? intl.formatMessage(
                  {
                    defaultMessage:
                      "{cleanPrefix}{amountOff} off your next invoice",
                    id: "qOMFno",
                  },
                  {
                    cleanPrefix,
                    amountOff: intl.formatNumber(Number(amountOff), {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 2,
                    }),
                  }
                )
              : intl.formatMessage(
                  {
                    defaultMessage:
                      "{cleanPrefix}{amountOff} off the next invoice",
                    id: "omyONZ",
                  },
                  {
                    cleanPrefix,
                    amountOff: intl.formatNumber(Number(amountOff), {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 2,
                    }),
                  }
                );
          }

          return subscriberFacing
            ? intl.formatMessage(
                {
                  defaultMessage:
                    "{cleanPrefix}<placeholder>'<amount>'</placeholder> off your next invoice",
                  id: "SKBdmn",
                },
                { cleanPrefix, placeholder }
              )
            : intl.formatMessage(
                {
                  defaultMessage:
                    "{cleanPrefix}<placeholder>'<amount>'</placeholder> off your next invoice",
                  id: "SKBdmn",
                },
                {
                  cleanPrefix,
                  placeholder,
                }
              );

        case "forever":
          if (Number(amountOff) > 0) {
            return intl.formatMessage(
              {
                defaultMessage:
                  "{cleanPrefix}{amountOff} off all future invoices",
                id: "Al14dx",
              },
              {
                cleanPrefix,
                amountOff: intl.formatNumber(Number(amountOff), {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                }),
              }
            );
          }

          return intl.formatMessage(
            {
              defaultMessage:
                "{cleanPrefix}<placeholder>'<amount>'</placeholder> off all future invoices",
              id: "IAcOHV",
            },
            { cleanPrefix, placeholder }
          );

        case "repeating":
          if (
            Number(amountOff) > 0 &&
            durationInterval === "month" &&
            Number(durationCount) > 0
          ) {
            return intl.formatMessage(
              {
                defaultMessage:
                  "{cleanPrefix}{amountOff} off for {months, number} {months, plural, one {month} other {months}}",
                id: "EGamRJ",
              },
              {
                cleanPrefix,
                amountOff: intl.formatNumber(Number(amountOff), {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                }),
                months: durationCount,
              }
            );
          }

          if (
            Number(amountOff) > 0 &&
            durationInterval === "day" &&
            Number(durationCount) > 0
          ) {
            return intl.formatMessage(
              {
                defaultMessage:
                  "{cleanPrefix}{amountOff} off for {days, number} {days, plural, one {day} other {days}}",
                id: "XdD7yb",
              },
              {
                cleanPrefix,
                amountOff: intl.formatNumber(Number(amountOff), {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                }),
                days: durationCount,
              }
            );
          }

          if (
            Number(amountOff) > 0 &&
            durationInterval === "week" &&
            Number(durationCount) > 0
          ) {
            return intl.formatMessage(
              {
                defaultMessage:
                  "{cleanPrefix}{amountOff} off for {weeks, number} {weeks, plural, one {week} other {weeks}}",
                id: "UWJiQb",
              },
              {
                cleanPrefix,
                amountOff: intl.formatNumber(Number(amountOff), {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                }),
                weeks: durationCount,
              }
            );
          }

          if (
            Number(amountOff) > 0 &&
            durationInterval === "year" &&
            Number(durationCount) > 0
          ) {
            return intl.formatMessage(
              {
                defaultMessage:
                  "{cleanPrefix}{amountOff} off for {years, number} {years, plural, one {year} other {years}}",
                id: "HGjevA",
              },
              {
                cleanPrefix,
                amountOff: intl.formatNumber(Number(amountOff), {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                }),
                years: durationCount,
              }
            );
          }

          if (Number(amountOff) > 0 && Number(months) > 0) {
            return intl.formatMessage(
              {
                defaultMessage:
                  "{cleanPrefix}{amountOff} off for {months, number} {months, plural, one {month} other {months}}",
                id: "EGamRJ",
              },
              {
                cleanPrefix,
                amountOff: intl.formatNumber(Number(amountOff), {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                }),
                months,
              }
            );
          }

          if (Number(amountOff) > 0) {
            return intl.formatMessage(
              {
                defaultMessage:
                  "{cleanPrefix}{amountOff} off for <placeholder>'<number>'</placeholder> months",
                id: "+Fy1Iu",
              },
              {
                cleanPrefix,
                amountOff: intl.formatNumber(Number(amountOff), {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                }),
                placeholder,
              }
            );
          }

          if (Number(months) > 0) {
            return intl.formatMessage(
              {
                defaultMessage:
                  "{cleanPrefix}<placeholder>'<amount>'</placeholder> off for {months, number} {months, plural, one {month} other {months}}",
                id: "9dfb//",
              },
              { cleanPrefix, months, placeholder }
            );
          }

          return intl.formatMessage(
            {
              defaultMessage:
                "{cleanPrefix}<placeholder>'<amount>'</placeholder> off for <placeholder>'<number>'</placeholder> months",
              id: "dlOWv7",
            },
            { cleanPrefix, placeholder }
          );

        default:
          throw new Error(`Invalid duration ${duration}`);
      }

    default:
      return "";
  }
}
