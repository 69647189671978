import { useApolloClient } from "@apollo/client";

import { ExistingDeflectionsQuery } from "../../../__generated__/graphql";
import TheExistingDeflectionsQuery from "../../../common/queries/ExistingDeflectionsQuery";

export default function useValidateName(isDirty: boolean) {
  const apollo = useApolloClient();

  return async (value: string) => {
    if (!isDirty) {
      return undefined;
    }

    const result = await apollo.query<ExistingDeflectionsQuery>({
      query: TheExistingDeflectionsQuery,
    });

    if (
      result.data?.deflection.find((deflection) => deflection.title === value)
    ) {
      return "A deflection with that name already exists.";
    }
  };
}
