import { gql, useQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FieldError } from "react-hook-form";

import { OfferIdDropdownQuery } from "../../__generated__/graphql";
import IdDropdown from "../../common/IdDropdown";
import offerIcon from "../offers/lib/offerIcon";

interface OfferIdDropdownProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  excludeIds?: string[];
  includeIds?: string[];
  filterType?: string;
  filterGoal?: string;
  excludeType?: string;
  excludeAutopilot?: boolean;
  resettable?: boolean;
  useId?: boolean;
  width?: string;
  disabled?: boolean;
  fieldError?: FieldError;
}

const OfferIdDropdown: React.FunctionComponent<OfferIdDropdownProps> = ({
  value,
  onChange,
  placeholder = "Select an offer…",
  excludeIds = [],
  includeIds = [],
  filterType = null,
  filterGoal = null,
  excludeType = null,
  excludeAutopilot = false,
  resettable = false,
  useId = false,
  width = "230px",
  disabled,
  fieldError,
}) => {
  const { data, loading } = useQuery<OfferIdDropdownQuery>(
    gql`
      query OfferIdDropdownQuery {
        offer(
          where: {
            deleted_at: { _is_null: true }
            archived_at: { _is_null: true }
          }
        ) {
          id
          token
          name
          type
          goal
          created_at
          offer_autopilot {
            offer_id
          }
        }
      }
    `,
    { fetchPolicy: "cache-and-network" }
  );

  const ids = (data?.offer || [])
    .filter((offer) => !filterType || offer.type === filterType)
    .filter((offer) => !filterGoal || offer.goal === filterGoal)
    .filter((offer) => !excludeType || offer.type !== excludeType)
    .filter((offer) => !excludeIds.includes(offer.token))
    .filter(
      (offer) => includeIds.length === 0 || includeIds.includes(offer.token)
    )
    .filter((offer) => !excludeAutopilot || !offer.offer_autopilot?.offer_id)
    .map((offer) => ({
      id: useId ? offer.id.toString() : offer.token,
      created_at: offer.created_at,
      displayId: offer.token,
      label: offer.name,
      displayLabel: (
        <>
          <FontAwesomeIcon
            icon={offerIcon(offer.type)}
            fixedWidth
            tw="text-type-light"
          />{" "}
          {offer.name}
        </>
      ),
    }));

  return (
    <IdDropdown
      ids={ids}
      value={value}
      onChange={onChange}
      isLoading={loading}
      placeholder={placeholder}
      width={width}
      resettable={resettable}
      disabled={disabled}
      fieldError={fieldError}
    />
  );
};

export default OfferIdDropdown;
