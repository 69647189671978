import Decimal from "decimal.js";

import formatCurrency from "./formatCurrency";

interface CurrencyProps {
  value: string | number | Decimal;
  cents?: boolean;
}

const Currency: React.FunctionComponent<CurrencyProps> = ({
  value,
  cents = true,
}) => <>{formatCurrency(value, cents)}</>;

export default Currency;
