import { gql, useQuery } from "@apollo/client";
import { faLeftLong } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import flow from "@prosperstack/flow";
import { nanoid } from "nanoid";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { createGlobalStyle } from "styled-components";
import { css, theme } from "twin.macro";

import { DemoQuery, DemoQueryVariables } from "../../../__generated__/graphql";
import GlobalStyles from "../../../app/GlobalStyles";
import logo from "../../../assets/logo.svg";
import Card from "../../../common/card/Card";
import CardBody from "../../../common/card/CardBody";
import env from "../../../common/env";
import Button from "../../../common/form/Button";
import Spinner from "../../../common/Spinner";
import StandardExternalLink from "../../../common/StandardExternalLink";

const CustomStyles = createGlobalStyle<{ brandColor: string }>`
  body {
    background-color: ${(props) => props.brandColor};
  }
`;

const Demo: React.FunctionComponent = () => {
  const { token } = useParams<{ token?: string }>();
  const { addToast } = useToasts();
  const [closed, setClosed] = useState(false);
  const [status, setStatus] = useState<string>();

  const { data } = useQuery<DemoQuery, DemoQueryVariables>(
    gql`
      query DemoQuery($token: String!) {
        demo(where: { token: { _eq: $token } }) {
          id
          token
          company_name
          logo_url
          brand_color
          client_id
          flow_id
          properties
        }
      }
    `,
    {
      variables: {
        token: token || "",
      },
    }
  );

  const handleFlow = async () => {
    if (!demo) {
      throw new Error();
    }

    (window as any).__PROSPERSTACK_DEBUG_APP_HOST__ = env("REACT_APP_APP_URL");
    (window as any).__PROSPERSTACK_DEBUG_API_HOST__ = env(
      "REACT_APP_SERVER_URL"
    );

    const Chance = (await import("chance")).default;
    const chance = new Chance();

    const result = await flow(
      {
        clientId: demo.client_id,
        flowId: demo.flow_id,
        subscriber: {
          internalId: nanoid(),
          name: chance.name(),
          email: chance.email(),
          properties: demo.properties,
        },
        subscription: {
          properties: demo.properties,
        },
      },
      {
        onClosed() {
          setClosed(true);
        },
      }
    );

    setStatus(result.status);
  };

  useEffect(() => {
    if (!closed || !status) {
      return;
    }

    if (status !== "incomplete") {
      addToast(<div>Success! You completed the cancellation flow.</div>, {
        appearance: "success",
      });
    } else {
      addToast(
        <div>You closed the cancellation flow without completing it!</div>,
        {
          appearance: "info",
        }
      );
    }

    setStatus(undefined);
    setClosed(false);
  }, [addToast, closed, status]);

  if (!data?.demo.length) {
    return <Spinner height="100vh" />;
  }

  const demo = data.demo[0];

  return (
    <>
      <Helmet>
        <title>ProsperStack demo</title>
      </Helmet>
      <GlobalStyles />
      <CustomStyles brandColor={demo.brand_color || theme`colors.gray.100`} />
      <div
        tw="bg-white"
        css={css`
          box-shadow: 0px 0px 40px rgb(0 0 0 / 7%);
        `}
      >
        <div
          tw="mx-auto px-4 py-4"
          css={css`
            max-width: 540px;
          `}
        >
          <div tw="flex items-center">
            <img
              src={logo}
              alt="ProsperStack"
              css={css`
                max-width: 40%;
              `}
            />
            <div tw="text-2xl font-bold mx-4">+</div>

            {demo.logo_url && (
              <img
                src={demo.logo_url}
                alt=""
                css={css`
                  max-width: 40%;
                  max-height: 40px;
                `}
              />
            )}
          </div>
        </div>
      </div>

      <div
        tw="mx-auto px-4"
        css={css`
          max-width: 540px;
          padding-top: 40px;
        `}
      >
        <Card border title={`Cancellation flow demo for ${demo.company_name}`}>
          <CardBody>
            <h3 tw="text-lg font-title mb-2">Hello, {demo.company_name}!</h3>
            <p tw="text-type-light mb-4">
              This is a demo of the ProsperStack cancellation flow customized
              just for you. The ProsperStack cancellation flow is designed to
              automatically prevent churn for subscription businesses like
              yours.
            </p>
            <div tw="flex items-center">
              <Button buttonType="secondary" onClick={handleFlow}>
                Launch the flow
              </Button>
              <div tw="ml-4">
                <FontAwesomeIcon
                  icon={faLeftLong}
                  transform="grow-7 down-2"
                  tw="mr-2"
                  color={theme`colors.gray.600`}
                />{" "}
                click to see it in action!
              </div>
            </div>
          </CardBody>
        </Card>
        <Card border>
          <CardBody>
            <h3 tw="text-lg font-title mb-2">Want to learn more?</h3>
            <p tw="text-type-light mb-2">
              Learn more about ProsperStack at{" "}
              <StandardExternalLink
                href="https://prosperstack.com"
                target="_blank"
              >
                prosperstack.com
              </StandardExternalLink>{" "}
              or drop us a note at{" "}
              <StandardExternalLink href="mailto:hello@prosperstack.com">
                hello@prosperstack.com
              </StandardExternalLink>
              .
            </p>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default Demo;
