import "tippy.js/dist/tippy.css";

import WebFont from "webfontloader";

WebFont.load({
  google: {
    families: [
      "Noto Sans:500,700",
      "Source Sans Pro:400,600,700",
      "Source Code Pro:400",
    ],
  },
});
