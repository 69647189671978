import { nanoid } from "nanoid";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import tw from "twin.macro";

import {
  language_enum,
  TranslationFragment,
} from "../../../__generated__/graphql";
import isSlateContentEmpty from "../../../common/editor/lib/isContentEmpty";
import renderContent from "../../../common/editor/lib/renderContent";
import Editor from "../../../common/editor2/Editor";
import {
  EditorType,
  isContentEmpty as isLexicalContentEmpty,
} from "../../../common/editor2/lib";
import { useTranslations } from "../../../common/translations/TranslationsProvider";
import usePrevious from "../../../common/usePrevious";
import { usePropertyValues } from "../../properties/lib/propertyValues";
import EditableTextModal from "./EditableTextModal";
import EditableTextWrapper from "./EditableTextWrapper";

interface EditableFlowTextProps {
  className?: string;
  isEditable: boolean;
  translation: TranslationFragment;
  onSave: (
    value: Partial<Record<language_enum, any>>,
    editorType: EditorType
  ) => void;
  invert?: boolean;
  isInline?: boolean;
  placeholder?: string;
  tallEditor?: boolean;
  imagesEnabled?: boolean;
  videosEnabled?: boolean;
  disableVideoAutoPlay?: boolean;
  hoverContentClass?: string;
  isRequired?: boolean;
  baseFontSize?: string;
  disabled?: boolean;
}

const Placeholder = tw.span`text-type-light`;

const EditableFlowText: React.FunctionComponent<EditableFlowTextProps> = ({
  className,
  isEditable,
  translation,
  onSave,
  isInline = true,
  invert = false,
  placeholder,
  tallEditor = false,
  imagesEnabled = false,
  videosEnabled = false,
  disableVideoAutoPlay = false,
  hoverContentClass,
  isRequired = true,
  baseFontSize = "16px",
  disabled = false,
}) => {
  const { translationValue } = useTranslations();
  const inlineTextRef = useRef<HTMLDivElement>(null);
  const pencilWrapperRef = useRef<HTMLDivElement>(null);
  const [editing, setEditing] = useState(false);
  const [modalKey, setModalKey] = useState(nanoid());
  const [editorKey, setEditorKey] = useState(nanoid());

  const { propertyValues, propertyConfig, showPlaceholders } =
    usePropertyValues();

  const value = translationValue(translation);
  const previousValue = usePrevious(value);

  useEffect(() => {
    if (JSON.stringify(value) !== JSON.stringify(previousValue)) {
      setEditorKey(nanoid());
    }
  }, [previousValue, value]);

  const contentIsEmpty =
    value.format === "lexical"
      ? isLexicalContentEmpty(value.value)
      : isSlateContentEmpty(value.value);

  const content = contentIsEmpty ? (
    <Placeholder>{isEditable ? placeholder || "" : ""}</Placeholder>
  ) : value.format === "lexical" ? (
    <Editor
      initialValue={JSON.stringify(value.value)}
      initialValueKey={editorKey}
      isReadOnly={true}
      allowVideoAutoPlay={!isEditable && !disableVideoAutoPlay}
      baseFontSize={baseFontSize}
    />
  ) : (
    renderContent(
      value.value,
      isEditable || disableVideoAutoPlay,
      propertyValues,
      propertyConfig,
      showPlaceholders
    )
  );

  useLayoutEffect(() => {
    if (inlineTextRef.current && pencilWrapperRef.current) {
      const lineHeight = window.getComputedStyle(
        inlineTextRef.current
      ).lineHeight;
      pencilWrapperRef.current.style.height = lineHeight;
    }
  }, []);

  useEffect(() => {
    if (!editing) {
      setModalKey(nanoid());
    }
  }, [editing]);

  return (
    <>
      <EditableTextModal
        key={modalKey}
        isOpen={editing}
        translation={translation}
        isInline={isInline}
        onClose={() => setEditing(false)}
        onSave={(value, editorType) => {
          onSave(value, editorType);
          setEditing(false);
        }}
        size="xl"
        tallEditor={tallEditor}
        imagesEnabled={imagesEnabled}
        videosEnabled={videosEnabled}
        isRequired={isRequired}
        baseFontSize={baseFontSize}
      />
      <EditableTextWrapper
        className={className}
        isEditable={isEditable}
        onEdit={() => setEditing(!disabled)}
        invert={invert}
        isInline={isInline}
        hoverContentClass={hoverContentClass}
        disabled={disabled}
      >
        {content}
      </EditableTextWrapper>
    </>
  );
};

export default EditableFlowText;
