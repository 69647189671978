import { useLazyQuery } from "@apollo/client";
import { useEffect } from "react";

import { ViewerQuery } from "../__generated__/graphql";
import { useAuth0 } from "../app/auth0";
import { useUserClaims } from "../app/UserClaimsProvider";
import TheViewerQuery from "./queries/ViewerQuery";

export default function useViewer() {
  const { isAuthenticated, loading: auth0Loading } = useAuth0();
  const { userLoggedIn } = useUserClaims();

  const [
    fetchViewer,
    { data, loading: viewerLoading, error, called, refetch },
  ] = useLazyQuery<ViewerQuery>(TheViewerQuery);

  useEffect(() => {
    if (isAuthenticated && !called) {
      try {
        fetchViewer();
      } catch (e) {}
    }
  }, [isAuthenticated, fetchViewer, error, called]);

  useEffect(() => {
    if (!refetch) {
      return;
    }

    refetch();
  }, [refetch, userLoggedIn]);

  return {
    viewer: data?.viewer,
    loading: auth0Loading || viewerLoading || !called,
    refetch,
  };
}
