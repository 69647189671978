import { useMemo, useState } from "react";
import tw, { styled } from "twin.macro";

import Button, { ButtonType } from "./form/Button";
import Modal, { ModalProps } from "./modal/Modal";
import ModalBody from "./modal/ModalBody";
import ModalFooter from "./modal/ModalFooter";
import ModalHeader from "./modal/ModalHeader";

type ConfirmationModalProps = ModalProps & {
  onClose: (confirmed: boolean) => void;
  title: string;
  content: React.ReactNode;
  confirmText: string;
  confirmButtonType: ButtonType;
  confirmButtonDisabled?: boolean;
  hideCancelButton?: boolean;
};

const StyledModalBody = styled(ModalBody)`
  ${tw`pt-6 pb-6 px-6`}
`;

const ConfirmationModal: React.FunctionComponent<ConfirmationModalProps> = ({
  onClose,
  title,
  content,
  confirmText,
  confirmButtonType,
  confirmButtonDisabled = false,
  hideCancelButton = false,
  ...props
}) => {
  const [submitting, setSubmitting] = useState(false);

  useMemo(() => {
    if (props.isOpen && submitting) {
      setSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen]);

  return (
    <Modal {...props}>
      <ModalHeader>{title}</ModalHeader>
      <StyledModalBody>{content}</StyledModalBody>
      <ModalFooter>
        <Button
          buttonType={confirmButtonType}
          isLoading={submitting}
          onClick={() => {
            setSubmitting(true);
            onClose(true);
          }}
          disabled={submitting || confirmButtonDisabled}
        >
          {confirmText}
        </Button>
        {!hideCancelButton && (
          <Button
            buttonType="default"
            onClick={() => onClose(false)}
            disabled={submitting}
          >
            Cancel
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationModal;
