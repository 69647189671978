import tw, { css } from "twin.macro";

export const editorStyles = css`
  .editor__ltr {
    text-align: left;
  }
  .editor__rtl {
    text-align: right;
  }
  .editor__paragraph:not(:last-child),
  .editor__ul:not(:last-child),
  .editor__ol1:not(:last-child),
  .editor__image:not(:last-child),
  .editor__video:not(:last-child),
  .editor__h4:not(:last-child),
  .editor__h5:not(:last-child),
  .editor__h6:not(:last-child) {
    margin-bottom: 0.75em;
  }
  .editor__h4 {
    ${tw`font-semibold`}
    font-size: 1.5em;
    line-height: 1.25em;
  }
  .editor__h5 {
    ${tw`font-semibold`}
    font-size: 1.25em;
    line-height: 1.25em;
  }
  .editor__textBold {
    font-weight: bold;
  }
  .editor__textItalic {
    font-style: italic;
  }
  .editor__textUnderline {
    text-decoration: underline;
  }
  .editor__textStrikethrough {
    text-decoration: line-through;
  }
  .editor__textUnderlineStrikethrough {
    text-decoration: underline line-through;
  }
  .editor__textCode {
    background-color: rgb(240, 242, 245);
    padding: 1px 0.25rem;
    font-family: Menlo, Consolas, Monaco, monospace;
    font-size: 94%;
  }
  .editor__hashtag {
    background-color: rgba(88, 144, 255, 0.15);
    border-bottom: 1px solid rgba(88, 144, 255, 0.3);
  }
  .editor__link {
    color: rgb(33, 111, 219);
    text-decoration: none;
  }
  .editor__link:hover {
    text-decoration: underline;
  }
  .editor__code {
    background-color: rgb(240, 242, 245);
    font-family: Menlo, Consolas, Monaco, monospace;
    display: block;
    padding: 8px 8px 8px 52px;
    line-height: 1.53;
    font-size: 13px;
    margin: 0;
    margin-top: 8px;
    margin-bottom: 8px;
    tab-size: 2;
    /* white-space: pre; */
    overflow-x: auto;
    position: relative;
  }
  .editor__code:before {
    content: attr(data-gutter);
    position: absolute;
    background-color: #eee;
    left: 0;
    top: 0;
    border-right: 1px solid #ccc;
    padding: 8px;
    color: #777;
    white-space: pre-wrap;
    text-align: right;
    min-width: 25px;
  }
  .editor__code:after {
    content: attr(data-highlight-language);
    top: 0;
    right: 3px;
    padding: 3px;
    font-size: 10px;
    text-transform: uppercase;
    position: absolute;
    color: rgba(0, 0, 0, 0.5);
  }
  .editor__table {
    border-collapse: collapse;
    border-spacing: 0;
    max-width: 100%;
    overflow-y: scroll;
    table-layout: fixed;
    width: 100%;
  }
  .editor__tableCell {
    border: 1px solid black;
    padding: 6px 8px;
    min-width: 75px;
    vertical-align: top;
    text-align: start;
  }
  .editor__tableCellHeader {
    background-color: #f2f3f5;
    text-align: start;
  }
  .editor__characterLimit {
    display: inline;
    background-color: #ffbbbb !important;
  }
  .editor__ol1,
  .editor__ol2,
  .editor__ol3 {
    padding: 0;
    margin: 0;
  }
  .editor__ol1 {
    padding: 0;
    margin: 0;
    list-style-type: decimal;
  }
  .editor__ol2 {
    list-style-type: lower-alpha;
  }
  .editor__ol3 {
    list-style-type: lower-roman;
  }
  .editor__ul {
    list-style-type: disc;
    padding: 0;
    margin: 0;

    ul {
      list-style-type: circle;

      ul {
        list-style-type: square;
      }
    }
  }
  .editor__listItem {
    margin: 8px 24px 8px 24px;
  }
  .editor__nestedListItem {
    list-style-type: none;
  }
  .editor__tokenComment {
    color: slategray;
  }
  .editor__tokenPunctuation {
    color: #999;
  }
  .editor__tokenProperty {
    color: #905;
  }
  .editor__tokenSelector {
    color: #690;
  }
  .editor__tokenOperator {
    color: #9a6e3a;
  }
  .editor__tokenAttr {
    color: #07a;
  }
  .editor__tokenVariable {
    color: #e90;
  }
  .editor__tokenFunction {
    color: #dd4a68;
  }
  .editor__image {
    > div {
      max-width: 528px;
      position: relative;
    }

    > div.editing {
      ${tw`border-gray-400 border-2`}
    }

    > div.focused {
      ${tw`border-blue-500 border-2`}
    }
  }
`;
