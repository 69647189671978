import gql from "graphql-tag";

import OfferFragment from "../../server/common/fragments/OfferFragment";
import FlowOfferGroupFragment from "./FlowOfferGroupFragment";
import FlowOfferRuleOfferAutopilotFragment from "./FlowOfferRuleOfferAutopilotFragment";
import FlowOfferRuleOfferFragment from "./FlowOfferRuleOfferFragment";
import FlowOfferRuleRuleFragment from "./FlowOfferRuleRuleFragment";
import FlowOfferTestFragment from "./FlowOfferTestFragment";
import FlowSegmentFragment from "./FlowSegmentFragment";
import FlowSegmentGroupFragment from "./FlowSegmentGroupFragment";
import TranslationFragment from "./TranslationFragment";

export default gql`
  fragment FlowOfferRuleVersionFragment on offer_rule_version {
    id
    offer_rule_id
    segment_group_ids
    segment_ids
    question_option_ids
    offer_ids
    offer_test_ids
    offer_group_ids
    offer_autopilot_offer_ids
    include_present_no_offer
    include_other_in_question_ids
    offer_rule_rule_ids

    offer_rule_version_segment_groups(
      order_by: { segment_group: { name: asc } }
    ) {
      offer_rule_version_id
      segment_group_id
      segment_group {
        ...FlowSegmentGroupFragment
      }
    }

    offer_rule_version_segments(order_by: { segment: { name: asc } }) {
      offer_rule_version_id
      segment_id
      segment {
        ...FlowSegmentFragment
      }
    }

    offer_rule_version_question_options(order_by: { position: asc }) {
      offer_rule_version_id
      question_option_id
      question_option {
        id
        question_id
        published_version {
          id
          title_translation {
            ...TranslationFragment
          }
        }
        draft_version @include(if: $includeDrafts) {
          id
          title_translation {
            ...TranslationFragment
          }
        }
      }
    }

    offer_rule_version_offer_rule_rules {
      offer_rule_version_id
      offer_rule_rule_id
      offer_rule_rule {
        ...FlowOfferRuleRuleFragment
      }
    }

    offer_rule_version_offers(order_by: { offer: { name: asc } }) {
      offer_rule_version_id
      offer_id
      offer {
        ...FlowOfferRuleOfferFragment
      }
    }

    offer_rule_version_offer_tests(order_by: { offer_test: { name: asc } }) {
      offer_rule_version_id
      offer_test_id
      offer_test {
        ...FlowOfferTestFragment
      }
    }

    offer_rule_version_offer_groups(order_by: { offer_group: { name: asc } }) {
      offer_rule_version_id
      offer_group_id
      offer_group {
        ...FlowOfferGroupFragment
      }
    }

    offer_rule_version_offer_autopilot_offers(
      order_by: { offer_autopilot: { offer: { name: asc } } }
    ) {
      offer_rule_version_id
      offer_id
      offer_autopilot {
        ...FlowOfferRuleOfferAutopilotFragment
      }
    }
  }
  ${FlowSegmentGroupFragment}
  ${FlowSegmentFragment}
  ${TranslationFragment}
  ${OfferFragment}
  ${FlowOfferRuleRuleFragment}
  ${FlowOfferRuleOfferFragment}
  ${FlowOfferTestFragment}
  ${FlowOfferGroupFragment}
  ${FlowOfferRuleOfferAutopilotFragment}
`;
