import Tippy from "@tippyjs/react";
import { ReactNode } from "react";
import tw, { css } from "twin.macro";

interface ToolbarButtonProps extends React.ComponentPropsWithoutRef<"button"> {
  isActive?: boolean;
  tooltip?: ReactNode;
}

const ToolbarButton: React.FunctionComponent<ToolbarButtonProps> = ({
  isActive,
  tooltip,
  ...props
}) => (
  <Tippy content={tooltip} disabled={!tooltip} placement="top">
    <button
      type="button"
      tabIndex={-1}
      tw="rounded mr-1 last:mr-0 outline-none focus:outline-none disabled:opacity-50 disabled:pointer-events-none"
      css={[
        css`
          font-size: 0.75rem;
          width: 28px;
          height: 24px;
        `,
        isActive ? tw`bg-gray-200! hover:bg-gray-200!` : tw`hover:bg-gray-100!`,
      ]}
      {...props}
    />
  </Tippy>
);

export default ToolbarButton;
