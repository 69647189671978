import { UnreachableCaseError } from "ts-essentials";

import {
  FlowOfferFragment,
  language_enum,
  offer_type_enum,
} from "../../../../__generated__/graphql";
import isSlateContentEmpty from "../../../../common/editor/lib/isContentEmpty";
import renderContentWithOffer from "../../../../common/editor/lib/renderContentWithOffer";
import Editor from "../../../../common/editor2/Editor";
import { isContentEmpty as isLexicalContentEmpty } from "../../../../common/editor2/lib";
import translationValue from "../../../../common/translationValue";
import {
  OfferDetails,
  OfferDetailsProvider,
} from "../../../offers/lib/offerDetails";
import { PropertyConfig, PropertyValues } from "../../../properties/lib/types";

interface RenderOfferContentOptions {
  offer: FlowOfferFragment | null;
  previewMode: boolean;
  language: language_enum;
  defaultLanguage: language_enum;
  propertyValues: PropertyValues;
  propertyConfig: PropertyConfig;
  showPropertyPlaceholders: boolean;
  disableVideoAutoPlay?: boolean;
}

export default function renderOfferContent({
  offer,
  previewMode,
  language,
  defaultLanguage,
  propertyValues,
  propertyConfig,
  showPropertyPlaceholders,
  disableVideoAutoPlay,
}: RenderOfferContentOptions) {
  const showContentPlaceholder = previewMode && !offer?.image_url;

  if (!offer) {
    return null;
  }

  const contentTranslationValue = translationValue(
    offer.content_translation,
    language,
    defaultLanguage
  );

  if (
    contentTranslationValue.format === "lexical"
      ? isLexicalContentEmpty(
          typeof contentTranslationValue.value === "string"
            ? JSON.parse(contentTranslationValue.value)
            : contentTranslationValue.value
        )
      : isSlateContentEmpty(contentTranslationValue.value)
  ) {
    if (!showContentPlaceholder) {
      return null;
    }

    return (
      <p>
        <span tw="text-type-light">Content</span>
      </p>
    );
  }

  const offerDetails = ((): OfferDetails => {
    const offerType: `${offer_type_enum}` = offer.type;

    switch (offerType) {
      case "custom":
        return {
          type: "custom",
        };

      case "trial_extension":
        if (!offer.offer_trial_extension) {
          throw new Error(
            `Offer is missing trial extension configuration: ${offer.id}`
          );
        }

        return {
          type: "trial_extension",
          days: offer.offer_trial_extension.days,
        };

      case "pause_subscription":
        if (!offer.offer_pause_subscription) {
          throw new Error(
            `Offer is missing pause subscription configuration: ${offer.id}`
          );
        }

        return {
          type: "pause_subscription",
          options:
            offer.offer_pause_subscription.offer_pause_subscription_options.map(
              (o) => ({
                type: o.type,
                interval: o.interval,
                intervalCount: o.interval_count,
              })
            ),
        };

      case "coupon":
        if (!offer.offer_coupon) {
          throw new Error(`Offer is missing coupon configuration: ${offer.id}`);
        }

        return {
          type: "coupon",
          couponType: offer.offer_coupon.type,
          amountOff: Number(offer.offer_coupon.amount_off),
          duration: offer.offer_coupon.duration,
          durationInterval: offer.offer_coupon.duration_interval,
          durationCount: offer.offer_coupon.duration_count,
          months: offer.offer_coupon.months,
          goal: offer.goal,
        };

      case "change_plan":
        if (!offer.offer_change_plan) {
          throw new Error(
            `Offer is missing change plan configuration: ${offer.id}`
          );
        }

        return {
          type: "change_plan",
          planId: offer.offer_change_plan.platform_plan_id,
          planName: null,
        };

      case "upgrade":
        if (!offer.offer_upgrade) {
          throw new Error(
            `Offer is missing upgrade configuration: ${offer.id}`
          );
        }

        return {
          type: "upgrade",
          planId: offer.offer_upgrade.platform_plan_id,
          planName: null,
        };

      case "downgrade":
        if (!offer.offer_downgrade) {
          throw new Error(
            `Offer is missing downgrade configuration: ${offer.id}`
          );
        }

        return {
          type: "downgrade",
          planId: offer.offer_downgrade.platform_plan_id,
          planName: null,
        };

      case "modify_subscription":
        return {
          type: "modify_subscription",
        };

      case "product_swap":
        if (!offer.offer_product_swap) {
          throw new Error(
            `Offer is missing product swap configuration: ${offer.id}`
          );
        }

        return {
          type: "product_swap",
          specificProducts: offer.offer_product_swap.specific_products,
          products: [],
        };

      case "reschedule_order":
        if (!offer.offer_reschedule_order) {
          throw new Error(
            `Offer is missing skip order configuration: ${offer.id}`
          );
        }

        return {
          type: "reschedule_order",
          skipEnabled: offer.offer_reschedule_order.skip,
          rescheduleEnabled: offer.offer_reschedule_order.reschedule,
        };

      default:
        throw new UnreachableCaseError(offerType);
    }
  })();

  const initialValue =
    typeof contentTranslationValue.value === "string"
      ? contentTranslationValue.value
      : JSON.stringify(contentTranslationValue.value);

  if (contentTranslationValue.format === "lexical") {
    return (
      <OfferDetailsProvider offerDetails={offerDetails}>
        <Editor
          initialValue={initialValue}
          initialValueKey={initialValue}
          allowVideoAutoPlay={!disableVideoAutoPlay}
          isReadOnly
        />
      </OfferDetailsProvider>
    );
  }

  return renderContentWithOffer({
    content: translationValue(
      offer.content_translation,
      language,
      defaultLanguage
    ).value,
    offerDetails,
    language,
    showContentPlaceholder,
    propertyValues,
    propertyConfig,
    showPropertyPlaceholders,
  });
}
