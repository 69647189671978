import { sortBy } from "lodash";

import { SegmentConditionPropertyConfig } from "../../../common/segments/lib";

export default function groupSegmentProperties(
  properties: SegmentConditionPropertyConfig[]
) {
  const grouped: Record<string, SegmentConditionPropertyConfig[]> = {};

  for (const property of sortBy(properties, "name")) {
    const group =
      property.entity === "subscriber"
        ? "Subscriber"
        : property.entity === "subscription"
        ? "Subscription"
        : "Other";
    grouped[group] = grouped[group] || [];
    grouped[group].push(property);
  }

  return grouped;
}
