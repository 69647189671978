import {
  FlowQuestionFragment,
  question_type_enum,
} from "../../../../__generated__/graphql";
import getFlowObjectVersion from "../../../../common/flow/getFlowObjectVersion";
import { FlowVersion } from "../../../../common/flow/types";
import { QuestionAnswer } from "./types";

const questionIsSatisfied = (
  question: FlowQuestionFragment,
  questionAnswers: QuestionAnswer[],
  flowVersion: FlowVersion
): boolean => {
  const questionVersion = getFlowObjectVersion(question, flowVersion);
  if (!question.primary && !questionVersion.required) {
    return true;
  }

  const answer = questionAnswers.find((answer) => answer.id === question.id);

  if (!answer) {
    return false;
  }

  const currentValue = answer.value;

  switch (answer.type) {
    case question_type_enum.radio:
      return (!!currentValue && currentValue.length > 0) || !!answer?.specify;

    case question_type_enum.text:
      return !!currentValue || currentValue === "0";

    case question_type_enum.likelihood_to_return:
      return !!currentValue;

    default:
      return false;
  }
};

export default questionIsSatisfied;
