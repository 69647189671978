import { range } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import tw, { styled } from "twin.macro";

import sharedInputStyles from "../../../common/form/input/sharedInputStyles";
import useFocusFirstEmptyInput from "../../../common/useFocusFirstEmptyInput";
import usePrevious from "../../../common/usePrevious";

interface CodeInputProps {
  onChange: (value: string) => void;
  disabled?: boolean;
}

const Input = styled.input`
  ${sharedInputStyles}
  ${tw`text-center mr-1 md:mr-3 last:mr-0 w-auto max-w-[2.25rem]`}
`;

const CodeInput: React.FunctionComponent<CodeInputProps> = ({
  onChange,
  disabled,
}) => {
  const [values, setValues] = useState<string[]>(Array(6).fill(""));
  const refs = useMemo(
    () => range(6).map(() => React.createRef<HTMLInputElement>()),
    []
  );
  const [formRef, setFormRef] = useState<HTMLFormElement | null>(null);
  useFocusFirstEmptyInput(formRef);

  const value = values.join("");
  const prevValue = usePrevious(value);

  const handleKeyDown = (index: number, e: React.KeyboardEvent) => {
    if (/\d/.test(e.key)) {
      e.preventDefault();

      if (index >= values.length - 1 && values[index] !== "") {
        const ref = refs[index];
        if (
          ref.current &&
          typeof ref.current.selectionStart === "number" &&
          typeof ref.current.selectionEnd === "number" &&
          ref.current.selectionStart === ref.current.selectionEnd
        ) {
          return;
        }
      }

      const nextValues = [...values];
      nextValues[index] = e.key;
      setValues(nextValues);
      focusNext(index);
    }

    if (e.key === "Backspace" || e.key === "Delete") {
      e.preventDefault();

      const nextValues = [...values];
      nextValues[index] = "";
      setValues(nextValues);

      if (e.key === "Backspace") {
        focusPrevious(index);
      }
    }

    if (e.key === "ArrowLeft") {
      e.preventDefault();
      focusPrevious(index);
    }

    if (e.key === "ArrowRight") {
      e.preventDefault();
      focusNext(index);
    }
  };

  const handleFocus = (index: number, e: React.FocusEvent) => {
    const ref = refs[index];
    if (ref.current) {
      ref.current.select();
    }
  };

  const handlePaste = (e: React.ClipboardEvent) => {
    const text = e.clipboardData.getData("text");
    if (text.length === 6 && Number(text).toString() === text) {
      const nextValues = [];
      for (const char of text) {
        nextValues.push(char);
      }

      setValues(nextValues);
      const ref = refs[refs.length - 1];
      if (ref.current) {
        ref.current.focus();
      }
    }
  };

  const focusPrevious = (index: number) => {
    if (index === 0) {
      return;
    }

    const prevRef = refs[index - 1];
    if (prevRef.current) {
      prevRef.current.focus();
      prevRef.current.select();
    }
  };

  const focusNext = (index: number) => {
    if (index >= values.length - 1) {
      return;
    }

    const nextRef = refs[index + 1];
    if (nextRef.current) {
      nextRef.current.focus();
      nextRef.current.select();
    }
  };

  useEffect(() => {
    if (
      value.length === 6 &&
      (!prevValue || prevValue.length < 6 || value[5] !== prevValue[5])
    ) {
      onChange(value);
    }
  }, [onChange, prevValue, value]);

  return (
    <form ref={setFormRef}>
      {range(6).map((i) => (
        <Input
          key={i}
          value={values[i]}
          ref={refs[i]}
          onKeyDown={(e) => handleKeyDown(i, e)}
          onFocus={(e) => handleFocus(i, e)}
          onChange={() => {}}
          disabled={disabled}
          onPaste={handlePaste}
        />
      ))}
    </form>
  );
};

export default CodeInput;
