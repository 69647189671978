import { useMutation } from "@apollo/client";
import gql from "graphql-tag";

import {
  UpdateOfferTimerMutation,
  UpdateOfferTimerMutationVariables,
} from "../../../__generated__/graphql";

export default function useUpdateOfferTimer() {
  const [updateOfferTimer] = useMutation<
    UpdateOfferTimerMutation,
    UpdateOfferTimerMutationVariables
  >(
    gql`
      mutation UpdateOfferTimerMutation($object: offer_timer_insert_input!) {
        insert_offer_timer_one(
          object: $object
          on_conflict: {
            constraint: offer_timer_pkey
            update_columns: [
              offer_timer_mode
              start_time
              end_time
              duration
              show_timer
            ]
          }
        ) {
          offer_id
          offer_timer_mode
          start_time
          end_time
          duration
          show_timer
        }
      }
    `
  );

  return updateOfferTimer;
}
