import { faTrashCan } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Temporal } from "@js-temporal/polyfill";
import tw, { css } from "twin.macro";

import {
  AppPropertyFragment,
  property_entity_enum,
  segment_condition_property_enum,
  SegmentConditionFragment,
} from "../../__generated__/graphql";
import Button from "../../common/form/Button";
import CurrencyInput from "../../common/form/input/CurrencyInput";
import CustomSelectInput from "../../common/form/input/CustomSelectInput";
import DatePickerInput from "../../common/form/input/DatePickerInput";
import TextInput from "../../common/form/input/TextInput";
import {
  getOperatorDisplay,
  getSegmentConditionCustomPropertyOperators,
  getSegmentConditionPropertyOperators,
  propertyConfigs,
  SegmentConditionPropertyConfig,
  SelectOption,
} from "../../common/segments/lib";
import AddOnIdDropdown from "./AddOnIdDropdown";
import CouponIdDropdown from "./CouponIdDropdown";
import LocationInput from "./LocationInput";
import MultipleAddOnIdsDropdown from "./MultipleAddOnIdsDropdown";
import MultipleCouponIdsDropdown from "./MultipleCouponIdsDropdown";
import MultipleLocationsInput from "./MultipleLocationsInput";
import MultipleOfferIdsDropdown from "./MultipleOfferIdsDropdown";
import MultiplePlanIdsDropdown from "./MultiplePlanIdsDropdown";
import MultipleProductIdsDropdown from "./MultipleProductIdsDropdown";
import OfferIdDropdown from "./OfferIdDropdown";
import PlanIdDropdown from "./PlanIdDropdown";
import ProductIdDropdown from "./ProductIdDropdown";
import SegmentPropertyDropdown from "./SegmentPropertyDropdown";

interface SegmentConditionProps {
  index: number;
  condition: SegmentConditionFragment;
  properties: AppPropertyFragment[];
  groupedProperties: Record<string, SegmentConditionPropertyConfig[]>;
  canDelete: boolean;
  className?: string;
  onPropertyChange: (value: number | segment_condition_property_enum) => void;
  onOperatorChange: (value: string) => void;
  onValueChange: (value: string) => void;
  onDelete: () => void;
  onClickCreateProperty?: (entity: property_entity_enum) => void;
}

const parseMultipleInputValue = (value: any) => {
  if (typeof value === "object" && Array.isArray(value)) {
    return value;
  }

  if (!value) {
    return [];
  }

  try {
    const parsed = JSON.parse(value);
    if (typeof parsed === "object" && Array.isArray(parsed)) {
      return parsed;
    }
  } catch (e) {}

  try {
    return JSON.parse(`["${value}"]`);
  } catch (e) {}

  return [];
};

const getTextInput = (
  name: string,
  value: string,
  onChange: (value: string) => void
) => (
  <TextInput
    name={name}
    value={value}
    onChange={(e) => onChange(e.target.value)}
  />
);

const getNumberInput = (
  name: string,
  value: string,
  onChange: (value: string) => void
) => (
  <TextInput
    type="number"
    name={name}
    value={value}
    onChange={(e) => onChange(e.target.value)}
  />
);

const getCurrencyInput = (
  name: string,
  value: string,
  onChange: (value: string) => void
) => (
  <CurrencyInput tw="w-full">
    <TextInput
      name={name}
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
  </CurrencyInput>
);

const getSelectInput = (
  value: string,
  options: SelectOption[],
  onChange: (value: string) => void
) => (
  <CustomSelectInput
    items={options.map((option) => ({
      label: option.label,
      value: option.id,
    }))}
    value={value.toString()}
    onChange={(value) => onChange(value)}
    tw="w-full"
  />
);

const getDateInput = (value: string, onChange: (value: string) => void) => (
  <DatePickerInput
    value={!!value ? Temporal.PlainDate.from(value) : undefined}
    onChange={(value) => onChange(value.toString())}
    render={({ onClick }) => (
      <TextInput
        value={
          !!value
            ? Temporal.PlainDate.from(value).toLocaleString(undefined, {
                day: "numeric",
                month: "long",
                year: "numeric",
              })
            : ""
        }
        onChange={() => {}}
        onFocus={onClick}
      />
    )}
  />
);

const getLocationInput = (value: string, onChange: (value: string) => void) => (
  <LocationInput
    value={
      !value
        ? { country: "", state: "" }
        : typeof value === "string"
        ? JSON.parse(value)
        : value
    }
    onChange={(value) =>
      !value.country ? "" : onChange(JSON.stringify(value))
    }
  />
);

const getMultipleLocationsInput = (
  value: string,
  onChange: (value: string) => void
) => (
  <MultipleLocationsInput
    value={parseMultipleInputValue(value)}
    onChange={(value) => onChange(value.length ? JSON.stringify(value) : "")}
  />
);

const getPlanIdInput = (value: string, onChange: (value: string) => void) => (
  <PlanIdDropdown value={value} onChange={onChange} width="100%" />
);

const getAddOnIdInput = (value: string, onChange: (value: string) => void) => (
  <AddOnIdDropdown value={value} onChange={onChange} />
);

const getProductIdInput = (
  value: string,
  onChange: (value: string) => void
) => <ProductIdDropdown value={value} onChange={onChange} width="100%" />;

const getMultiplePlanIdsInput = (
  value: string,
  onChange: (value: string) => void
) => (
  <MultiplePlanIdsDropdown
    value={parseMultipleInputValue(value)}
    onChange={(value) => onChange(value.length ? JSON.stringify(value) : "")}
    width="100%"
  />
);

const getMultipleAddOnIdsInput = (
  value: string,
  onChange: (value: string) => void
) => (
  <MultipleAddOnIdsDropdown
    value={parseMultipleInputValue(value)}
    onChange={(value) => onChange(value.length ? JSON.stringify(value) : "")}
  />
);

const getMultipleProductIdsInput = (
  value: string,
  onChange: (value: string) => void
) => (
  <MultipleProductIdsDropdown
    value={parseMultipleInputValue(value)}
    onChange={(value) => onChange(value.length ? JSON.stringify(value) : "")}
    width="100%"
  />
);

const getOfferIdInput = (value: string, onChange: (value: string) => void) => (
  <OfferIdDropdown value={value} onChange={onChange} width="100%" />
);

const getMultipleOfferIdsInput = (
  value: string,
  onChange: (value: string) => void
) => (
  <MultipleOfferIdsDropdown
    value={parseMultipleInputValue(value)}
    onChange={(value) => onChange(value.length ? JSON.stringify(value) : "")}
    width="100%"
  />
);

const getCouponIdInput = (value: string, onChange: (value: string) => void) => (
  <CouponIdDropdown value={value} onChange={onChange} width="100%" />
);

const getMultipleCouponIdsInput = (
  value: string,
  onChange: (value: string) => void
) => (
  <MultipleCouponIdsDropdown
    value={parseMultipleInputValue(value)}
    onChange={(value) => onChange(value.length ? JSON.stringify(value) : "")}
    width="100%"
  />
);

const getValueInput = (
  index: number,
  condition: SegmentConditionFragment,
  property: SegmentConditionPropertyConfig | undefined,
  onChange: (value: string) => void
) => {
  const fieldName = `condition:${index}:value`;

  if (condition.property_id && !property) {
    if (!condition.custom_property) {
      throw new Error("Condition has no custom property");
    }

    if (condition.custom_property.type === "boolean") {
      return getSelectInput(
        condition.value,
        [
          { id: "true", label: "True" },
          { id: "false", label: "False" },
        ],
        onChange
      );
    }

    if (condition.custom_property.type === "number") {
      if (condition.custom_property.format === "currency") {
        return getCurrencyInput(fieldName, condition.value, onChange);
      }

      return getNumberInput(fieldName, condition.value, onChange);
    }

    if (condition.custom_property.type === "date") {
      if (
        condition.operator === "within_last_days" ||
        condition.operator === "more_than_days_ago" ||
        condition.operator === "within_next_days" ||
        condition.operator === "more_than_days_from_now"
      ) {
        return getNumberInput(fieldName, condition.value, onChange);
      }

      return getDateInput(condition.value, onChange);
    }

    return getTextInput(fieldName, condition.value, onChange);
  }

  if (!property) {
    throw new Error("No property and not a custom property");
  }

  switch (condition.property) {
    case "plan_id":
    case "subscriber_plan_id":
      if (condition.operator === "eq" || condition.operator === "neq") {
        return getPlanIdInput(condition.value, onChange);
      }
      if (condition.operator === "in" || condition.operator === "nin") {
        return getMultiplePlanIdsInput(condition.value, onChange);
      }
      break;

    case "addon_id":
      if (condition.operator === "eq" || condition.operator === "neq") {
        return getAddOnIdInput(condition.value, onChange);
      }
      if (condition.operator === "in" || condition.operator === "nin") {
        return getMultipleAddOnIdsInput(condition.value, onChange);
      }
      break;

    case "product_id":
      if (condition.operator === "eq" || condition.operator === "neq") {
        return getProductIdInput(condition.value, onChange);
      }
      if (condition.operator === "in" || condition.operator === "nin") {
        return getMultipleProductIdsInput(condition.value, onChange);
      }
      break;

    case "subscriber_status":
      return getSelectInput(
        condition.value,
        property.type === "select" ? property.options : [],
        onChange
      );

    case "subscriber_offer_id": {
      if (condition.operator === "eq" || condition.operator === "neq") {
        return getOfferIdInput(condition.value, onChange);
      }
      if (condition.operator === "in" || condition.operator === "nin") {
        return getMultipleOfferIdsInput(condition.value, onChange);
      }
      break;
    }

    case "subscription_coupon_id":
    case "subscriber_coupon_id":
      if (condition.operator === "eq" || condition.operator === "neq") {
        return getCouponIdInput(condition.value, onChange);
      }
      if (condition.operator === "in" || condition.operator === "nin") {
        return getMultipleCouponIdsInput(condition.value, onChange);
      }
      break;
  }

  switch (property.type) {
    case "currency":
      return getCurrencyInput(fieldName, condition.value, onChange);

    case "select":
      return getSelectInput(condition.value, property.options || [], onChange);

    case "boolean":
      return getSelectInput(
        condition.value.toString(),
        [
          { id: "true", label: "True" },
          { id: "false", label: "False" },
        ],
        onChange
      );

    case "date":
      if (
        condition.operator === "within_last_days" ||
        condition.operator === "more_than_days_ago" ||
        condition.operator === "within_next_days" ||
        condition.operator === "more_than_days_from_now"
      ) {
        return getNumberInput(fieldName, condition.value, onChange);
      }

      return getDateInput(condition.value, onChange);

    case "location":
      if (condition.operator === "eq" || condition.operator === "neq") {
        return getLocationInput(condition.value, onChange);
      }
      if (condition.operator === "in" || condition.operator === "nin") {
        return getMultipleLocationsInput(condition.value, onChange);
      }
      break;
  }

  return getTextInput(fieldName, condition.value, onChange);
};

const SegmentCondition: React.FunctionComponent<SegmentConditionProps> = ({
  index,
  condition,
  properties,
  groupedProperties,
  canDelete,
  className,
  onPropertyChange,
  onOperatorChange,
  onValueChange,
  onDelete,
  onClickCreateProperty,
}) => {
  const isCustom = !!condition.property_id;

  const property =
    condition.property !== "custom_property"
      ? propertyConfigs[condition.property]
      : undefined;
  if (!isCustom && !property) {
    return null;
  }

  const propertyValue =
    condition.property === "custom_property"
      ? condition.property_id
      : condition.property;

  if (!propertyValue) {
    throw new Error("Invalid property value");
  }

  const customProperty = condition.property_id
    ? properties.find((p) => p.id === condition.property_id)
    : undefined;

  const operators = !!property
    ? getSegmentConditionPropertyOperators(property.type, property.array)
    : !!customProperty
    ? getSegmentConditionCustomPropertyOperators(customProperty.type)
    : [];

  return (
    <div tw="py-4 items-start" className={className}>
      <div>
        <SegmentPropertyDropdown
          groups={groupedProperties}
          value={propertyValue}
          onChange={onPropertyChange}
          properties={properties}
          onClickCreateProperty={onClickCreateProperty}
          tw="w-full"
        />

        <CustomSelectInput
          items={operators.map((operator) => ({
            label: getOperatorDisplay(condition.property, operator),
            value: operator,
          }))}
          value={condition.operator}
          onChange={onOperatorChange}
          minWidth="100%"
          buttonWidth="100%"
          tw="w-full mt-2"
        />
      </div>
      {condition.operator !== "is_set" &&
        condition.operator !== "is_not_set" && (
          <div
            tw="mt-2"
            css={css`
              input {
                width: 100%;
              }
            `}
          >
            {getValueInput(index, condition, property, onValueChange)}
          </div>
        )}
      {canDelete && (
        <div tw="mt-2">
          <Button
            type="button"
            buttonType="alternate-danger"
            size="sm"
            onClick={onDelete}
            css={css`
              fieldset:disabled & {
                ${tw`opacity-50 pointer-events-none`}
              }
            `}
          >
            <FontAwesomeIcon icon={faTrashCan} transform="shrink-2" /> Remove
          </Button>
        </div>
      )}
    </div>
  );
};

export default SegmentCondition;
