import tw from "twin.macro";

const sharedInputStyles = tw`
  bg-white box-border border border-gray-200 shadow-sm rounded-md px-2 py-1 m-0 outline-none 
  text-type leading-normal
  disabled:bg-gray-100 disabled:text-gray-400 
  focus:outline-none focus:ring focus:ring-blue-200 focus:border-blue-500
  duration-150 ease-in-out
`;

export default sharedInputStyles;
