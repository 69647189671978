import React, { createContext, useContext } from "react";
import { FieldValues } from "react-hook-form";

import { UseTranslatableFormReturn } from "./useTranslatableForm";

const TranslatableFormContext = createContext<UseTranslatableFormReturn | null>(
  null
);

const useTranslatableFormContext = <
  TFieldValues extends FieldValues
>(): UseTranslatableFormReturn<TFieldValues> => {
  const context = useContext(
    TranslatableFormContext
  ) as UseTranslatableFormReturn<TFieldValues>;
  if (!context) {
    throw new Error(
      "useTranslatableFormContext() not called within TranslatableFormProvider context"
    );
  }

  return context;
};

export type TranslatableFormProviderProps<
  TFieldValues extends FieldValues = FieldValues,
  TContext = any
> = {
  children: React.ReactNode | React.ReactNode[];
} & UseTranslatableFormReturn<TFieldValues, TContext>;

export const TranslatableFormProvider = <
  TFieldValues extends FieldValues = FieldValues,
  TContext = any
>(
  props: TranslatableFormProviderProps<TFieldValues, TContext>
) => {
  const { children, ...data } = props;
  return (
    <TranslatableFormContext.Provider
      value={data as unknown as UseTranslatableFormReturn}
    >
      {children}
    </TranslatableFormContext.Provider>
  );
};

export default useTranslatableFormContext;
