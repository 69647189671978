import gql from "graphql-tag";

import FlowSegmentGroupFragment from "./FlowSegmentGroupFragment";
import TranslationFragment from "./TranslationFragment";

export default gql`
  fragment FlowStepVersionFragment on flow_step_version {
    id
    flow_step_id
    condition_segment_group_ids
    condition_segment_ids
    condition_question_option_ids
    condition_include_other_in_question_ids
    flow_step_version_condition_segment_groups {
      segment_group_id
      segment_group {
        ...FlowSegmentGroupFragment
      }
    }
    flow_step_version_condition_segments {
      segment_id
      segment {
        id
        name
      }
    }
    flow_step_version_condition_question_options {
      question_option_id
      question_option {
        id
        question_id
        published_version {
          id
          title_translation {
            ...TranslationFragment
          }
        }
        draft_version @include(if: $includeDrafts) {
          id
          title_translation {
            ...TranslationFragment
          }
        }
      }
    }
  }
  ${FlowSegmentGroupFragment}
  ${TranslationFragment}
`;
