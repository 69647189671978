import gql from "graphql-tag";

import TranslationFragment from "./TranslationFragment";

export default gql`
  fragment FlowQuestionOptionVersionFragment on question_option_version {
    id
    question_option_id
    title_translation_id
    title_translation {
      ...TranslationFragment
    }
  }
  ${TranslationFragment}
`;
