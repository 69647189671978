import classNames from "classnames";
import tw, { styled } from "twin.macro";

import FieldRow from "./FieldRow";

export default styled(FieldRow).attrs((props: { className?: string }) => ({
  className: classNames("field-row", props.className),
}))<{
  className: string;
}>`
  ${tw`block pb-4`}
`;
