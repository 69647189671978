import { faCopy } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import tw from "twin.macro";

import Button from "./form/Button";

interface CopyButtonProps {
  className?: string;
  value: string;
  customText?: string | JSX.Element;
}

const InlineBlock = tw.div`inline-block`;

const CopyButton: React.FunctionComponent<CopyButtonProps> = ({
  className,
  value,
  customText,
}) => {
  const [wasCopied, setWasCopied] = useState(false);
  const [tippyVisible, setTippyVisible] = useState(false);
  const handleCopy = () => {
    setWasCopied(true);
  };
  const handleMouseEnter = () => {
    setTippyVisible(true);
  };
  const handleMouseLeave = () => {
    setTippyVisible(false);
    setTimeout(() => {
      setWasCopied(false);
    }, 250);
  };
  const tooltip = wasCopied ? "Copied!" : "Copy to clipboard";

  return (
    <CopyToClipboard text={value} onCopy={handleCopy}>
      <InlineBlock className={className}>
        <Tippy content={tooltip} visible={tippyVisible}>
          <InlineBlock
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <Button type="button" size="sm" buttonType="transparent">
              {customText ? (
                <span>{customText}</span>
              ) : (
                <FontAwesomeIcon icon={faCopy} />
              )}
            </Button>
          </InlineBlock>
        </Tippy>
      </InlineBlock>
    </CopyToClipboard>
  );
};

export default CopyButton;
