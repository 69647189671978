import { gql, useMutation } from "@apollo/client";

import {
  UseUpdateOfferChangePlanMutation,
  UseUpdateOfferChangePlanMutationVariables,
} from "../../../__generated__/graphql";

export default function useUpdateOfferChangePlan() {
  const [updateOfferChangePlan] = useMutation<
    UseUpdateOfferChangePlanMutation,
    UseUpdateOfferChangePlanMutationVariables
  >(gql`
    mutation UseUpdateOfferChangePlanMutation(
      $offerId: Int!
      $prorate: Boolean!
      $changeAt: offer_change_plan_change_at_enum!
      $resetBillingDate: Boolean!
    ) {
      update_offer_change_plan_by_pk(
        pk_columns: { offer_id: $offerId }
        _set: {
          prorate: $prorate
          change_at: $changeAt
          reset_billing_date: $resetBillingDate
        }
      ) {
        offer_id
        prorate
        change_at
        offer {
          id
          offer_change_plan {
            offer_id
            prorate
            change_at
            reset_billing_date
          }
        }
      }
    }
  `);

  return updateOfferChangePlan;
}
