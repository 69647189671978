export default function randomByWeight<
  T extends { id: number; weight: number }
>(items: Array<T>): T | undefined {
  const totalWeight = items.reduce((acc, i) => acc + i.weight, 0);

  const threshold = Math.random() * totalWeight;

  let total = 0;
  for (const item of items) {
    total += item.weight;
    if (total >= threshold) {
      return item;
    }
  }
}
