import { LexicalEditor } from "lexical";
import { useEffect, useState } from "react";
import { Transforms } from "slate";
import { ReactEditor } from "slate-react";
import tw from "twin.macro";

import {
  language_enum,
  translation_value_format_enum,
  TranslationFragment,
} from "../../../__generated__/graphql";
import isSlateContentEmpty from "../../../common/editor/lib/isContentEmpty";
import serializeToString from "../../../common/editor/lib/serializeToString";
import {
  EditorType,
  isContentEmpty as isLexicalContentEmpty,
} from "../../../common/editor2/lib";
import TranslationValueEditorSelector from "../../../common/editor2/TranslationValueEditorSelector";
import Button from "../../../common/form/Button";
import FieldRowBlock from "../../../common/form/FieldRowBlock";
import Modal, { ModalProps } from "../../../common/modal/Modal";
import ModalFooter from "../../../common/modal/ModalFooter";
import ModalFormBody from "../../../common/modal/ModalFormBody";
import ModalHeader from "../../../common/modal/ModalHeader";
import { useTranslations } from "../../../common/translations/TranslationsProvider";
import LanguageRadio from "./LanguageRadio";

type EditableTextModalProps = ModalProps & {
  translation: TranslationFragment;
  onClose: () => void;
  onSave: (
    value: Partial<Record<language_enum, any>>,
    editorType: EditorType
  ) => void;
  isInline?: boolean;
  tallEditor?: boolean;
  imagesEnabled?: boolean;
  videosEnabled?: boolean;
  isRequired?: boolean;
  baseFontSize?: string;
};

const findTranslationValue = (
  translation: TranslationFragment,
  language: language_enum
) =>
  translation.translation_values.find(
    (translationValue) => translationValue.language === language
  );

const EditableTextModal: React.FunctionComponent<EditableTextModalProps> = ({
  translation,
  onClose,
  onSave,
  isInline,
  tallEditor = false,
  imagesEnabled = false,
  videosEnabled = false,
  isRequired = true,
  baseFontSize,
  ...props
}) => {
  const { language, defaultLanguage, enabledLanguages } = useTranslations();
  const [editorType, setEditorType] = useState<EditorType>();
  const [slateEditors, setSlateEditors] = useState<
    Partial<Record<language_enum, ReactEditor>>
  >({});
  const [lexicalEditors, setLexicalEditors] = useState<
    Partial<Record<language_enum, LexicalEditor>>
  >({});
  const [editingLanguage, setEditingLanguage] = useState(language);
  const [value, setValue] = useState<Partial<Record<language_enum, any>>>(
    enabledLanguages.reduce(
      (prev, language) => ({
        ...prev,
        [language]: findTranslationValue(translation, language)?.value,
      }),
      {}
    )
  );

  useEffect(() => {
    const editor = slateEditors[editingLanguage];
    if (editor) {
      window.setTimeout(() => {
        ReactEditor.focus(editor);
        window.setTimeout(() => {
          Transforms.move(editor, {
            distance: serializeToString(editor.children).length,
          });
        }, 50);
      });
    }
  }, [editingLanguage, slateEditors]);

  useEffect(() => {
    setEditingLanguage(language);
  }, [language]);

  const handleSave = (value: Partial<Record<language_enum, any>>) => {
    if (!editorType) {
      throw new Error();
    }

    const translationValue = findTranslationValue(translation, defaultLanguage);
    const defaultValue = value[defaultLanguage];
    if (!translationValue || !defaultValue) {
      throw new Error();
    }

    if (editorType === "lexical") {
      if (isLexicalContentEmpty(defaultValue)) {
        setEditingLanguage(defaultLanguage);
        const editor = lexicalEditors[defaultLanguage];
        if (editor) {
          editor.focus();
        }
        return;
      }
    }

    if (editorType === "slate") {
      if (isRequired && isSlateContentEmpty(defaultValue)) {
        setEditingLanguage(defaultLanguage);
        const editor = slateEditors[defaultLanguage];
        if (editor) {
          ReactEditor.focus(editor);
        }
        return;
      }
    }

    onSave(value, editorType);
  };

  return (
    <Modal {...props}>
      <ModalHeader>Edit text</ModalHeader>
      <ModalFormBody tw="pb-0">
        {enabledLanguages.length > 1 && (
          <LanguageRadio
            value={editingLanguage}
            defaultLanguage={defaultLanguage}
            languages={enabledLanguages}
            onChange={(language) => setEditingLanguage(language)}
          />
        )}
        <FieldRowBlock>
          {enabledLanguages.map((language) => {
            const translationValue = findTranslationValue(
              translation,
              language
            );

            const defaultValue = findTranslationValue(
              translation,
              defaultLanguage
            );
            if (!defaultValue) {
              throw new Error();
            }

            return (
              <div
                key={`text-editor-${language}`}
                css={[
                  tw`mt-4`,
                  language === editingLanguage ? tw`block` : tw`hidden`,
                ]}
              >
                <TranslationValueEditorSelector
                  translationValue={translationValue || null}
                  isInline={!!isInline}
                  onChange={(newValue, editorType) => {
                    setEditorType(editorType);
                    setValue({
                      ...value,
                      [language]: JSON.parse(newValue),
                    });
                  }}
                  lexicalEditorRef={(editor) => {
                    if (!lexicalEditors[language]) {
                      setLexicalEditors({
                        ...lexicalEditors,
                        [language]: editor,
                      });
                    }
                  }}
                  slateEditorRef={(editor) => {
                    if (!slateEditors[language]) {
                      setSlateEditors({
                        ...slateEditors,
                        [language]: editor,
                      });
                    }
                  }}
                  defaultFormat={
                    defaultValue.format || translation_value_format_enum.slate
                  }
                  baseFontSize={baseFontSize}
                  initialFocus={true}
                />
              </div>
            );
          })}
        </FieldRowBlock>
      </ModalFormBody>
      <ModalFooter>
        <Button
          buttonType="primary"
          form="add-question"
          isLoading={false}
          onClick={() => handleSave(value)}
        >
          Save
        </Button>
        <Button buttonType="default" onClick={onClose}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditableTextModal;
