import { RenderElementProps, useFocused, useSelected } from "slate-react";
import tw, { styled } from "twin.macro";

import { useOfferDetails } from "../../../../features/offers/lib/offerDetails";
import { useTranslations } from "../../../translations/TranslationsProvider";
import renderOfferTag from "./renderOfferTag";
import { OfferTag } from "./types";

const Tag = styled.span<{ selected: boolean }>`
  ${tw`bg-gray-100 px-1 rounded border border-gray-100`}

  ${(props) => props.selected && tw`border-blue-500`}
`;

const OfferTagElement: React.FunctionComponent<RenderElementProps> = ({
  attributes,
  element,
  children,
}) => {
  const { offerDetails } = useOfferDetails();
  const isFocused = useFocused();
  const isSelected = useSelected();
  const { language } = useTranslations();

  return (
    <Tag
      contentEditable={false}
      {...attributes}
      selected={isFocused && isSelected}
    >
      {renderOfferTag(offerDetails, element.tag as OfferTag, language)}
      {children}
    </Tag>
  );
};

export default OfferTagElement;
