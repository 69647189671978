import { RefObject, useEffect, useState } from "react";
import { styled, theme } from "twin.macro";

interface ScrollIndicatorProps {
  target: RefObject<HTMLDivElement>;
  count: number;
}

const ScrollIndicatorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding-bottom: 0.85rem;
`;
const Dot = styled.div<{
  active: boolean;
}>`
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: ${(props) =>
    props.active ? theme`colors.gray.800` : theme`colors.gray.200`};
  cursor: pointer;
`;

const ScrollIndicator: React.FunctionComponent<ScrollIndicatorProps> = ({
  target,
  count,
}) => {
  const [scrollProgress, setScrollProgress] = useState(0);

  const renderDots = () => {
    const selectedDotValue = (scrollProgress * count) / 100;
    return [...Array(count).keys()].map((index) => (
      <Dot
        key={index}
        active={selectedDotValue >= index && selectedDotValue <= index + 1}
        onClick={() => scrollToIndex(index)}
      />
    ));
  };

  useEffect(() => {
    const scrollListener = () => {
      if (!target.current) {
        return;
      }

      const element = target.current;
      const windowScroll = element.scrollLeft; // Distance of the scrollbar from the leftmost point
      const totalWidth = element.scrollWidth - element.clientWidth; // Total width the scrollbar can traverse
      if (windowScroll === 0) {
        return setScrollProgress(0);
      }

      if (windowScroll > totalWidth) {
        return setScrollProgress(100);
      }

      setScrollProgress((windowScroll / totalWidth) * 100);
    };

    if (target.current) {
      const current = target.current;
      current.addEventListener("scroll", scrollListener);
      return () => current.removeEventListener("scroll", scrollListener);
    }
  }, [target, scrollProgress, setScrollProgress]);

  const scrollToIndex = (i: number) => {
    if (!target.current) {
      return;
    }

    const element = target.current;
    const totalWidth = element.scrollWidth - element.clientWidth;
    element.scrollTo({
      left: (totalWidth / (count - 1)) * i,
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <ScrollIndicatorContainer>{renderDots()}</ScrollIndicatorContainer>
    </>
  );
};

export default ScrollIndicator;
