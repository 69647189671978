import { faGripVertical, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { nanoid } from "nanoid";
import { useEffect, useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
  ResponderProvided,
} from "react-beautiful-dnd";
import { Node } from "slate";
import { isPresent } from "ts-is-present";
import tw, { styled } from "twin.macro";

import {
  FlowAcknowledgementGroupFragment,
  language_enum,
} from "../../../../__generated__/graphql";
import ConfirmationModal from "../../../../common/ConfirmationModal";
import Editor from "../../../../common/editor2/Editor";
import { TranslatedForms } from "../../../../common/form/useTranslatableForm";
import { useTranslations } from "../../../../common/translations/TranslationsProvider";
import { AcknowledgementsManager } from "../../../flow/edit/acknowledgementActions";
import AddAcknowledgementModal, {
  FormValues,
} from "../../../flow/edit/AddAcknowledgementModal";
import EditableAcknowledgementLabel from "../../../flow/edit/EditableAcknowledgementLabel";
import EditableFlowText from "../../../flow/edit/EditableFlowText";
import { useFlowVersion } from "../FlowVersionProvider";
import StepTitle from "../ui/StepTitle";

const AcknowledgementsWrapper = styled.div`
  ${tw`-ml-2`}

  &.acknowledgements-are-draggable {
    margin-left: -30px;
  }
`;

const FormGroup = styled.div`
  ${tw`flex mb-1 border border-transparent px-2`}

  &.option-is-dragging {
    ${tw`bg-white rounded border-gray-200`}
    z-index: 10;

    button {
      display: none;
    }
  }

  button {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 19px;
    height: 19px;
    border-radius: 50%;
    font-size: 13px;
    transition: all 0.3s ease;
    transition-property: background-color, border-color;
    outline: none;
    margin: 4px 0 0 10px;
  }

  &:hover {
    button {
      ${tw`bg-red-500 border-red-500 cursor-pointer opacity-75 hover:opacity-100`}

      svg {
        position: relative;
        left: 0.035px;
      }
    }
  }
`;

const DragHandle = styled.div`
  ${tw`text-gray-400 mr-3 relative`}
  top: 2px;
`;

interface AcknowledgementsProps {
  flowId: number;
  flowToken: string;
  isEditMode: boolean;
  acknowledgementGroup: FlowAcknowledgementGroupFragment;
  onChange: (acknowledged: boolean) => void;
  manager?: AcknowledgementsManager;
}

const Acknowledgements: React.FunctionComponent<AcknowledgementsProps> = ({
  flowId,
  flowToken,
  isEditMode,
  acknowledgementGroup,
  onChange,
  manager,
}) => {
  const { language, enabledLanguages, defaultLanguage, translationValue } =
    useTranslations();
  const [addAcknowledgementModalIsOpen, setAddAcknowledgementModalIsOpen] =
    useState(false);
  const [addAcknowledgementModalKey, setAddAcknowledgementModalKey] = useState(
    nanoid()
  );
  const { getObjectVersion } = useFlowVersion();

  const groupVersion = getObjectVersion(acknowledgementGroup);

  const acknowledgements =
    groupVersion.acknowledgement_group_version_acknowledgements
      .map((v) => v.acknowledgement)
      .filter(isPresent);

  const [checked, setChecked] = useState(
    acknowledgements.reduce<{ [key: string]: boolean }>((prev, ack) => {
      prev[ack.id.toString()] = false;
      return prev;
    }, {})
  );
  const [confirmDeleteId, setConfirmDeleteId] = useState<number | null>(null);

  const handleAddAcknowledgement = async (
    forms: TranslatedForms<FormValues>
  ) => {
    const title = forms[defaultLanguage]?.title || "";

    if (manager) {
      await manager.addAcknowledgement(acknowledgementGroup.id, title, forms);
    }
  };

  const handleUpdateAcknowledgement = async (
    id: number,
    forms: TranslatedForms<FormValues>
  ) => {
    if (manager) {
      await manager.updateAcknowledgement(id, forms);
    }
  };

  const handleUpdateTitle = async (
    value: Partial<Record<language_enum, Node[]>>
  ) => {
    if (manager) {
      manager.updateAcknowledgementGroupTitle(acknowledgementGroup.id, value);
    }
  };

  const handleDelete = (id: number) => {
    if (manager) {
      manager.deleteAcknowledgement(acknowledgementGroup.id, id);
    }
  };

  useEffect(() => {
    onChange(Object.values(checked).every((value) => value));
  }, [checked, onChange]);

  const onDragEnd = (result: DropResult, provided: ResponderProvided) => {
    if (!result.destination) {
      return;
    }

    if (manager) {
      manager.moveAcknowledgement(
        acknowledgementGroup.id,
        acknowledgements,
        result.source.index,
        result.destination.index
      );
    }
  };

  return (
    <div className={isEditMode ? "inline-text-context" : undefined}>
      {isEditMode && (
        <ConfirmationModal
          isOpen={!!confirmDeleteId}
          onClose={(confirmed) => {
            if (confirmed && confirmDeleteId) {
              handleDelete(confirmDeleteId);
            }
            setConfirmDeleteId(null);
          }}
          title="Remove acknowledgement"
          content="Are you sure you want to remove this acknowlegement?"
          confirmButtonType="danger"
          confirmText="Remove"
        />
      )}
      <StepTitle>
        <EditableFlowText
          isEditable={isEditMode}
          translation={groupVersion.title_translation}
          onSave={(value) => handleUpdateTitle(value)}
        />
      </StepTitle>
      <AcknowledgementsWrapper
        className={isEditMode ? "acknowledgements-are-draggable" : undefined}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable
            droppableId="acknowledgements"
            isDropDisabled={!isEditMode}
          >
            {(provided, droppableSnapshot) => (
              <>
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {acknowledgements.map((ack, index) => {
                    const ackVersion = getObjectVersion(ack);

                    const id = `ack_${ack.id}`;
                    const thisChecked = checked[ack.id.toString()];

                    const labelTranslationValue = translationValue(
                      ackVersion.label_translation
                    );

                    return (
                      <Draggable
                        key={ack.id}
                        draggableId={String(ack.id)}
                        index={index}
                        isDragDisabled={!isEditMode}
                      >
                        {(provided, snapshot) => (
                          <FormGroup
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            className={
                              snapshot.isDragging
                                ? "option-is-dragging"
                                : isEditMode
                                ? "acknowledgement-inline-text-content"
                                : undefined
                            }
                          >
                            {isEditMode && (
                              <DragHandle {...provided.dragHandleProps}>
                                <FontAwesomeIcon icon={faGripVertical} />
                              </DragHandle>
                            )}
                            <div className="flow-check flow-check--checkbox">
                              <input
                                id={id}
                                type="checkbox"
                                checked={thisChecked}
                                onChange={() =>
                                  setChecked({
                                    ...checked,
                                    [ack.id]: !thisChecked,
                                  })
                                }
                              />

                              <label htmlFor={id}>
                                <EditableAcknowledgementLabel
                                  isEditable={isEditMode}
                                  flowId={flowId}
                                  flowToken={flowToken}
                                  language={language}
                                  enabledLanguages={enabledLanguages}
                                  defaultLanguage={defaultLanguage}
                                  labelTranslation={
                                    ackVersion.label_translation
                                  }
                                  onSave={async (forms) => {
                                    await handleUpdateAcknowledgement(
                                      ack.id,
                                      forms
                                    );
                                  }}
                                >
                                  {labelTranslationValue.format ===
                                  "lexical" ? (
                                    <Editor
                                      initialValue={JSON.stringify(
                                        labelTranslationValue.value
                                      )}
                                      initialValueKey={JSON.stringify(
                                        labelTranslationValue.value
                                      )}
                                      isReadOnly={true}
                                      isInline={true}
                                      baseFontSize="inherit"
                                    />
                                  ) : (
                                    labelTranslationValue.value
                                  )}
                                </EditableAcknowledgementLabel>
                              </label>
                            </div>
                            {isEditMode && (
                              <button
                                type="button"
                                style={{
                                  display: droppableSnapshot.isDraggingOver
                                    ? "none"
                                    : "block",
                                }}
                                onClick={() => setConfirmDeleteId(ack.id)}
                              >
                                <FontAwesomeIcon icon={faTimes} color="white" />
                              </button>
                            )}
                          </FormGroup>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
                {isEditMode && (
                  <FormGroup>
                    <DragHandle style={{ visibility: "hidden" }}>
                      <FontAwesomeIcon icon={faGripVertical} />
                    </DragHandle>
                    <div className="flow-check flow-check--checkbox flow-check--add">
                      <label
                        onClick={() => setAddAcknowledgementModalIsOpen(true)}
                      >
                        Add acknowledgement
                      </label>
                    </div>
                  </FormGroup>
                )}
              </>
            )}
          </Droppable>
        </DragDropContext>
        {isEditMode && (
          <AddAcknowledgementModal
            mode="create"
            key={addAcknowledgementModalKey}
            isOpen={addAcknowledgementModalIsOpen}
            flowId={flowId}
            flowToken={flowToken}
            language={language}
            enabledLanguages={enabledLanguages}
            defaultLanguage={defaultLanguage}
            onClose={() => {
              setAddAcknowledgementModalIsOpen(false);
              setAddAcknowledgementModalKey(nanoid());
            }}
            onSave={handleAddAcknowledgement}
          />
        )}
      </AcknowledgementsWrapper>
    </div>
  );
};

export default Acknowledgements;
