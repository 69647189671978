import gql from "graphql-tag";

import TranslationFragment from "./TranslationFragment";

export default gql`
  fragment FlowInterventionVersionFragment on intervention_version {
    id
    intervention_id
    content_translation_id
    continue_button_translation_id
    cancel_button_translation_id
    content_translation {
      ...TranslationFragment
    }
    continue_button_translation {
      ...TranslationFragment
    }
    cancel_button_translation {
      ...TranslationFragment
    }
  }
  ${TranslationFragment}
`;
