import { CardElement, useRecurly } from "@recurly/react-recurly";
import countryList from "country-list";
import { useState } from "react";
import { useForm } from "react-hook-form";
import states from "states-us";
import tw from "twin.macro";

import Button from "../../../common/form/Button";
import SelectInput from "../../../common/form/input/SelectInput";
import TextInput from "../../../common/form/input/TextInput";
import useFocusFirstEmptyInput from "../../../common/useFocusFirstEmptyInput";
import { paymentFormOnComplete } from "./onComplete";

interface FormValues {
  firstName: string;
  lastName: string;
  address1: string;
  address2: string;
  city: string;
  region: string;
  postalCode: string;
  country: string;
}

interface RecurlyPaymentFormProps {
  addressRequirements: string;
  onReady?: () => void;
  onComplete: paymentFormOnComplete;
  onCancel: () => void;
}

const countries = countryList.getData();
countries.sort((a, b) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
});

const RecurlyPaymentForm: React.FunctionComponent<RecurlyPaymentFormProps> = ({
  addressRequirements,
  onReady,
  onComplete,
  onCancel,
}) => {
  const recurly = useRecurly();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [formRef, setFormRef] = useState<HTMLFormElement | null>(null);
  useFocusFirstEmptyInput(formRef);

  const { register, watch, handleSubmit, formState } = useForm<FormValues>({
    defaultValues: {
      country: "US",
    },
  });

  const country = watch("country");

  const onSubmit = handleSubmit((values) => {
    if (!formRef) {
      return;
    }

    setIsSubmitting(true);
    setErrorMsg(null);

    recurly.token(formRef, async (err, token) => {
      if (err) {
        setErrorMsg(err.message);
        setIsSubmitting(false);
      } else {
        const { success, errorMessage } = await onComplete(token.id);
        if (!success) {
          setErrorMsg(
            errorMessage || "Something went wrong with your request."
          );
        }
        setIsSubmitting(false);
      }
    });
  });

  return (
    <form onSubmit={onSubmit} ref={(ref) => setFormRef(ref)}>
      {errorMsg && <div tw="mb-4 text-red">{errorMsg}</div>}
      <fieldset tw="mb-2">
        <label tw="block mb-1 font-semibold">Name</label>
        <div tw="flex">
          <TextInput
            {...register("firstName", { required: true })}
            data-recurly="first_name"
            placeholder="First name"
            tw="w-full mr-2"
            fieldError={formState.errors.firstName}
          />
          <TextInput
            {...register("lastName", { required: true })}
            data-recurly="last_name"
            placeholder="Last name"
            tw="w-full"
            fieldError={formState.errors.lastName}
          />
        </div>
      </fieldset>

      <fieldset
        css={[
          tw`mt-4 mb-2`,
          addressRequirements === "none" ? tw`hidden` : undefined,
        ]}
      >
        <label tw="block mb-1 font-semibold">
          {addressRequirements === "zip" ? "ZIP code" : "Address"}
        </label>
        <div
          css={[
            tw`mb-2`,
            addressRequirements !== "full" &&
            addressRequirements !== "streetzip"
              ? tw`hidden`
              : undefined,
          ]}
        >
          <TextInput
            {...register("address1", { required: true })}
            data-recurly="address1"
            placeholder="Line 1"
            tw="w-full"
            fieldError={formState.errors.address1}
          />
        </div>
        <div
          css={[
            tw`mb-2`,
            addressRequirements !== "full" &&
            addressRequirements !== "streetzip"
              ? tw`hidden`
              : undefined,
          ]}
        >
          <TextInput
            {...register("address2")}
            data-recurly="address2"
            placeholder="Line 2"
            tw="w-full"
          />
        </div>
        <div
          css={[
            tw`mb-2`,
            addressRequirements !== "full" ? tw`hidden` : undefined,
          ]}
        >
          <SelectInput
            {...register("country", { required: true })}
            data-recurly="country"
            tw="mr-2"
            fieldError={formState.errors.country}
          >
            {countries.map((c) => (
              <option key={c.code} value={c.code}>
                {c.name}
              </option>
            ))}
          </SelectInput>
        </div>
        <div
          css={[
            tw`flex mb-2`,
            addressRequirements !== "full" &&
            addressRequirements !== "streetzip" &&
            addressRequirements !== "zip"
              ? tw`hidden`
              : undefined,
          ]}
        >
          <TextInput
            {...register("city", { required: true })}
            data-recurly="city"
            placeholder="City"
            tw="w-full mr-2"
            fieldError={formState.errors.city}
            css={addressRequirements !== "full" ? tw`hidden` : undefined}
          />
          {country === "US" ? (
            <SelectInput
              {...register("region", { required: true })}
              data-recurly="state"
              tw="w-full mr-2 w-[12rem]"
              placeholder="State"
              defaultValue=""
              fieldError={formState.errors.region}
              css={addressRequirements !== "full" ? tw`hidden` : undefined}
            >
              <option value="" disabled>
                State
              </option>
              {states.map((s) => (
                <option key={s.abbreviation} value={s.abbreviation}>
                  {s.name}
                </option>
              ))}
            </SelectInput>
          ) : (
            <TextInput
              {...register("region", { required: true })}
              data-recurly="state"
              placeholder="Region"
              tw="w-full mr-2 w-[12rem]"
              fieldError={formState.errors.region}
              css={addressRequirements !== "full" ? tw`hidden` : undefined}
            />
          )}
          <TextInput
            {...register("postalCode", { required: true })}
            data-recurly="postal_code"
            placeholder={country === "US" ? "ZIP" : "Postal code"}
            tw="w-[6rem]"
            fieldError={formState.errors.postalCode}
          />
        </div>
      </fieldset>

      <div tw="mt-2 -mb-1">
        <label tw="font-semibold">Card</label>
      </div>

      <div>
        <CardElement onReady={onReady} />
      </div>
      <div tw="mt-6">
        <Button
          buttonType="secondary"
          isLoading={isSubmitting}
          disabled={isSubmitting}
        >
          Submit
        </Button>
        <Button type="button" disabled={isSubmitting} onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </form>
  );
};

export default RecurlyPaymentForm;
