import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { LineBreakNode, ParagraphNode, RootNode } from "lexical";
import { useEffect } from "react";

const InlinePlugin: React.FunctionComponent = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    // Only allow a single paragraph node.
    editor.registerNodeTransform(RootNode, (rootNode: RootNode) => {
      const children = rootNode.getChildren();
      if (children.length > 1) {
        const lastChild = rootNode.getLastChild();
        lastChild?.remove();
      }
    });

    // Remove any line break nodes.
    editor.registerNodeTransform(
      ParagraphNode,
      (paragraphNode: ParagraphNode) => {
        const children = paragraphNode.getChildren();
        for (const child of children) {
          if (child instanceof LineBreakNode) {
            child.remove();
          }
        }
      }
    );
  }, [editor]);

  return null;
};

export default InlinePlugin;
