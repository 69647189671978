import {
  faBold,
  faH1,
  faH2,
  faImage,
  faItalic,
  faTag,
  faUnderline,
  faVideo,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TippyProps } from "@tippyjs/react";
import {
  BoldPlugin,
  CodePlugin,
  DEFAULTS_BOLD,
  DEFAULTS_CODE,
  DEFAULTS_HEADING,
  DEFAULTS_ITALIC,
  DEFAULTS_PARAGRAPH,
  DEFAULTS_UNDERLINE,
  EditablePlugins,
  HeadingPlugin,
  ItalicPlugin,
  ParagraphPlugin,
  SoftBreakPlugin,
  ToolbarButton,
  ToolbarElement,
  ToolbarMark,
  UnderlinePlugin,
  withToggleType,
} from "@udecode/slate-plugins";
import deepEqual from "fast-deep-equal";
import { nanoid } from "nanoid";
import { useEffect, useMemo, useRef, useState } from "react";
import { FieldError } from "react-hook-form";
import { createEditor, Node, Range, Transforms } from "slate";
import { withHistory } from "slate-history";
import { ReactEditor, Slate, withReact } from "slate-react";
import tw, { styled } from "twin.macro";

import { property_format_enum } from "../../__generated__/graphql";
import DropdownMenu from "../DropdownMenu";
import sharedInputStyles from "../form/input/sharedInputStyles";
import hotkey from "../hotkey";
import useModal from "../modal/useModal";
import InsertImageModal from "./InsertImageModal";
import InsertPropertyTagModal, {
  InsertPropertTagFormValues,
} from "./InsertPropertyTagModal";
import InsertVideoModal from "./InsertVideoModal";
import { ImagePlugin } from "./lib/ImagePlugin";
import insertImage from "./lib/ImagePlugin/insertImage";
import withImage from "./lib/ImagePlugin/withImage";
import InlinePlugin from "./lib/InlinePlugin";
import { OfferTagPlugin, withOfferTag } from "./lib/OfferTagPlugin";
import insertOfferTag from "./lib/OfferTagPlugin/insertOfferTag";
import {
  OFFER_TAG,
  OfferTag,
  TAG_DESCRIPTION,
} from "./lib/OfferTagPlugin/types";
import { PropertyTagPlugin, withPropertyTag } from "./lib/PropertyTagPlugin";
import insertPropertyTag from "./lib/PropertyTagPlugin/insertPropertyTag";
import {
  PROPERTY_TAG,
  PROPERTY_TAG_CUSTOM_PROPERTY,
  PROPERTY_TAG_EMAIL,
  PROPERTY_TAG_FIRST_NAME,
  PROPERTY_TAG_LAST_NAME,
  PROPERTY_TAG_SEGMENT_PROPERTY,
  PropertyTag,
} from "./lib/PropertyTagPlugin/types";
import { VideoPlugin } from "./lib/VideoPlugin";
import insertVideo from "./lib/VideoPlugin/insertVideo";
import withVideo from "./lib/VideoPlugin/withVideo";
import withTrailingParagraphs from "./lib/withTrailingParagraph";
import Toolbar from "./Toolbar";

export interface TextEditorProps {
  className?: string;
  initialValue?: Node[];
  onChange?: (value: Node[]) => void;
  offerTags?: Array<OfferTag>;
  isInline?: boolean;
  editorRef?: (editor: ReactEditor) => void;
  initialValueKey?: string;
  height?: string;
  imagesEnabled?: boolean;
  videosEnabled?: boolean;
  propertyTags?: Array<PropertyTag>;
  fieldError?: FieldError;
}

const Wrapper = styled.div<{ isInline: boolean; height?: string }>`
  ${sharedInputStyles}

  ${tw`p-0`}

  div[data-slate-editor="true"] {
    ${tw`p-2`}
    height: ${(props) =>
      props.height ? props.height : props.isInline ? "auto" : "10rem"};
    overflow: hidden;
    overflow-y: auto;

    fieldset:disabled & {
      ${tw`bg-gray-100 text-gray-400 pointer-events-none`}
    }
  }

  .slate-p {
    ${tw`mb-3 last:mb-0`}
  }

  & + .field-warning {
    ${tw`mt-2`}
  }

  h4 {
    ${tw`text-2xl text-black mb-3 font-semibold`}
  }

  h5 {
    ${tw`text-xl text-black mb-3 font-semibold`}
  }
`;

export const options = {
  ...DEFAULTS_PARAGRAPH,
  ...DEFAULTS_BOLD,
  ...DEFAULTS_ITALIC,
  ...DEFAULTS_UNDERLINE,
  ...DEFAULTS_CODE,
  ...DEFAULTS_HEADING,
  offer_tag: {
    type: OFFER_TAG,
  },
  property_tag: {
    type: PROPERTY_TAG,
  },
  span: {
    type: "span",
  },
};

const defaultInitialValue: Node[] = [
  {
    type: options.p.type,
    children: [
      {
        text: "",
      },
    ],
  },
];

const tooltip: TippyProps = {
  arrow: true,
  delay: 0,
  duration: [200, 0],
  hideOnClick: false,
  offset: [0, 17],
  placement: "top",
};

const ToolbarIcon = styled(FontAwesomeIcon)`
  && {
    display: inline-block;
    width: unset;
    height: 1em;
  }
`;

const TextEditor: React.FunctionComponent<TextEditorProps> = ({
  className,
  initialValue = defaultInitialValue,
  onChange,
  offerTags = [],
  isInline = false,
  editorRef,
  initialValueKey,
  height,
  imagesEnabled,
  videosEnabled,
  propertyTags = [
    PROPERTY_TAG_FIRST_NAME,
    PROPERTY_TAG_LAST_NAME,
    PROPERTY_TAG_EMAIL,
    PROPERTY_TAG_CUSTOM_PROPERTY,
    PROPERTY_TAG_SEGMENT_PROPERTY,
  ],
  fieldError,
}) => {
  const initialValueKeyRef = useRef<string | undefined>();
  const [value, setValue] = useState<Node[]>(initialValue);
  const editor = useMemo(() => {
    const result = withVideo(
      withImage(
        withPropertyTag(
          withOfferTag(withHistory(withReact(withToggleType()(createEditor()))))
        )
      )
    );

    if (!isInline) {
      return withTrailingParagraphs(result);
    }

    return result;
  }, [isInline]);

  const [
    insertPropertyTagModalIsOpen,
    setInsertPropertyTagModalIsOpen,
    insertPropertyTagModalKey,
  ] = useModal();

  const [insertVideoModalIsOpen, setInsertVideoModalIsOpen] = useState(false);
  const [insertVideoModalKey, setInsertVideoModalKey] = useState(nanoid());
  const [insertImageModalIsOpen, setInsertImageModalIsOpen] = useState(false);
  const [insertImageModalKey, setInsertImageModalKey] = useState(nanoid());

  const [storedSelection, setStoredSelection] = useState<Range | null>(null);

  useEffect(() => {
    initialValueKeyRef.current = initialValueKey;
  });

  useEffect(() => {
    if (editor && editorRef) {
      editorRef(editor);
    }
  }, [editor, editorRef]);

  const prevInitialValueKey = initialValueKeyRef.current;

  useEffect(() => {
    if (initialValueKey !== prevInitialValueKey) {
      setValue(initialValue);
    }
  }, [initialValue, initialValueKey, prevInitialValueKey]);

  const plugins = useMemo(() => {
    const result = [
      ParagraphPlugin(options),
      BoldPlugin(options),
      ItalicPlugin(options),
      UnderlinePlugin(options),
      CodePlugin(options),
      PropertyTagPlugin(),
      OfferTagPlugin(),
      ImagePlugin(),
      VideoPlugin(),
    ];

    if (isInline) {
      result.push(InlinePlugin());
    } else {
      result.push(HeadingPlugin(options));
      result.push(SoftBreakPlugin());
    }

    return result;
  }, [isInline]);

  const handleInsertProperty = (
    result?: InsertPropertTagFormValues,
    tag?: PropertyTag
  ) => {
    if (storedSelection) {
      Transforms.select(editor, storedSelection);
    }

    ReactEditor.focus(editor);
    if (!result) {
      setInsertPropertyTagModalIsOpen(false);
      return;
    }

    if (!tag) {
      throw new Error();
    }

    insertPropertyTag(
      editor,
      tag,
      result.propertyId ? result.propertyId.toString() : "",
      result.dateFormat || "",
      result.numberFormat as property_format_enum,
      result.fallback,
      !!result.isArray
    );

    setInsertPropertyTagModalIsOpen(false);
  };

  return (
    <Wrapper
      isInline={isInline}
      height={height}
      className={className}
      css={[
        fieldError &&
          tw`border-red-500 focus-within:ring focus-within:ring-red-200 focus-within:border-red-500`,
      ]}
    >
      <Slate
        editor={editor}
        value={value}
        onChange={(newValue) => {
          if (!deepEqual(newValue, value)) {
            if (onChange) {
              onChange(newValue);
            }
            setValue(newValue);
          }
        }}
      >
        <Toolbar>
          {!isInline && (
            <>
              <ToolbarElement
                type={options.h4.type}
                icon={<ToolbarIcon icon={faH1} size="xs" />}
                tooltip={{ content: "Heading", ...tooltip }}
              />
              <ToolbarElement
                type={options.h5.type}
                icon={<ToolbarIcon icon={faH2} size="xs" />}
                tooltip={{ content: "Heading", ...tooltip }}
              />
            </>
          )}
          <ToolbarMark
            type={options.bold.type}
            icon={<ToolbarIcon icon={faBold} size="xs" />}
            tooltip={{ content: `Bold (${hotkey("mod+b")})`, ...tooltip }}
          />
          <ToolbarMark
            type={options.italic.type}
            icon={<ToolbarIcon icon={faItalic} size="xs" />}
            tooltip={{ content: `Italic (${hotkey("mod+i")})`, ...tooltip }}
          />
          <ToolbarMark
            type={options.underline.type}
            icon={<ToolbarIcon icon={faUnderline} size="xs" />}
            tooltip={{ content: `Underline (${hotkey("mod+u")})`, ...tooltip }}
          />
          {(offerTags.length || propertyTags.length) && (
            <>
              {offerTags.length ? (
                <DropdownMenu
                  zIndex={60}
                  items={[
                    ...offerTags.map((tag) => {
                      const label = (() => {
                        switch (tag) {
                          case TAG_DESCRIPTION:
                            return "Offer description";

                          default:
                            throw new Error("Invalid tag");
                        }
                      })();

                      return {
                        label,
                        onClick: (e: any) => {
                          e.preventDefault();
                        },
                        onMouseDown: (e: any) => {
                          e.preventDefault();
                          insertOfferTag(editor, tag);
                        },
                      };
                    }),
                    {
                      label: "Other properties...",
                      onClick: (e: any) => {
                        e.preventDefault();
                      },
                      onMouseDown: (e: any) => {
                        e.preventDefault();
                        setInsertPropertyTagModalIsOpen(true);
                      },
                    },
                  ]}
                  render={(props) => (
                    <div>
                      <ToolbarButton
                        icon={<ToolbarIcon icon={faTag} size="xs" />}
                        tooltip={{ content: "Property tag", ...tooltip }}
                        onMouseDown={(e) => {
                          e.preventDefault();
                          props.onClick();
                        }}
                      />
                    </div>
                  )}
                />
              ) : (
                <ToolbarButton
                  icon={<ToolbarIcon icon={faTag} size="xs" />}
                  tooltip={{ content: "Insert tag", ...tooltip }}
                  onMouseDown={(e) => {
                    e.preventDefault();
                    setInsertPropertyTagModalIsOpen(true);
                  }}
                />
              )}
            </>
          )}
          {imagesEnabled && (
            <ToolbarButton
              icon={<ToolbarIcon icon={faImage} size="xs" />}
              tooltip={{ content: "Insert image", ...tooltip }}
              onMouseDown={(e) => setInsertImageModalIsOpen(true)}
            />
          )}
          {videosEnabled && (
            <ToolbarButton
              icon={<ToolbarIcon icon={faVideo} size="xs" />}
              tooltip={{ content: "Insert video", ...tooltip }}
              onMouseDown={(e) => {
                setInsertVideoModalIsOpen(true);
              }}
            />
          )}
        </Toolbar>
        <EditablePlugins
          plugins={plugins}
          placeholder="Enter some text..."
          onBlur={(e) => {
            if (editor.selection) {
              setStoredSelection(editor.selection);
            }
          }}
        />
      </Slate>
      <InsertVideoModal
        key={insertVideoModalKey}
        mode="insert"
        isOpen={insertVideoModalIsOpen}
        onClose={(result) => {
          setInsertVideoModalIsOpen(false);
          setInsertVideoModalKey(nanoid());

          if (storedSelection) {
            Transforms.select(editor, storedSelection);
          }

          ReactEditor.focus(editor);

          if (result) {
            insertVideo(editor, result.url, {
              autoPlay: result.autoPlay,
            });
          }
        }}
      />
      <InsertImageModal
        key={insertImageModalKey}
        mode="insert"
        isOpen={insertImageModalIsOpen}
        onClose={(result) => {
          setInsertImageModalIsOpen(false);
          setInsertImageModalKey(nanoid());

          if (storedSelection) {
            Transforms.select(editor, storedSelection);
          }

          ReactEditor.focus(editor);

          if (result) {
            insertImage(editor, result.url);
          }
        }}
      />
      <InsertPropertyTagModal
        key={insertPropertyTagModalKey}
        mode="insert"
        isOpen={insertPropertyTagModalIsOpen}
        onClose={handleInsertProperty}
      />
    </Wrapper>
  );
};

export default TextEditor;
