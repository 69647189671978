import { IconDefinition, IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCrown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import classNames from "classnames";
import { CSSProp } from "styled-components";
import tw from "twin.macro";

import Badge, { BadgeColor } from "../badge/Badge";
import Button, { ButtonType } from "../form/Button";
import PillRadio, { PillRadioOption } from "../PillRadio";
import Spinner from "../Spinner";
import CardSmallTitle from "./CardSmallTitle";
import CardTitle from "./CardTitle";

export interface ButtonItem {
  label: string;
  icon?: IconProp;
  type?: ButtonType;
  disabled?: boolean;
  isLoading?: boolean;
  hidden?: boolean;
  form?: string;
  onClick?: () => void;
}

export interface BadgeItem {
  label: string;
  color: BadgeColor;
  icon?: IconDefinition;
  iconSpin?: boolean;
  prefix?: string;
  tooltip?: React.ReactNode;
  hidden?: boolean;
}

export interface FilterItem {
  options: PillRadioOption[];
  value: string;
  onChange: (value: string) => void;
}

export type TitleSize = "sm" | "md";

export interface CardProps {
  className?: string;
  title?: React.ReactNode;
  titleSize?: TitleSize;
  titleExtra?: React.ReactNode;
  headerBorder?: boolean;
  buttons?: ButtonItem[];
  badges?: BadgeItem[];
  filters?: FilterItem[];
  isLoading?: boolean;
  border?: boolean;
  help?: string;
  css?: CSSProp;
  upgrade?: boolean;
  buttonRef?: (ref: HTMLDivElement) => void;
}

const Card: React.FunctionComponent<CardProps> = ({
  className,
  title,
  titleSize = "md",
  titleExtra,
  headerBorder = true,
  buttons,
  badges,
  filters,
  isLoading,
  border = false,
  help,
  children,
  css,
  upgrade = false,
  buttonRef = null,
}) => {
  const visibleBadges = badges?.filter((b) => !b.hidden);
  const visibleButtons = buttons?.filter((b) => !b.hidden);

  return (
    <div
      className={classNames(className, "card")}
      css={[
        css,
        tw`relative bg-white border-b border-divider mb-[55px]`,
        border &&
          tw`border border-divider rounded-lg py-2 pt-4 px-5 shadow-sm mb-4`,
      ]}
    >
      {!!title && (
        <div
          className="card-header"
          css={[
            tw`flex flex-wrap items-center border-divider`,
            headerBorder && tw`border-b`,
          ]}
        >
          {titleSize === "md" ? (
            <CardTitle help={help}>{title}</CardTitle>
          ) : titleSize === "sm" ? (
            <CardSmallTitle help={help}>{title}</CardSmallTitle>
          ) : null}

          {titleExtra}

          {(!!filters?.length ||
            !!visibleBadges?.length ||
            !!visibleButtons?.length ||
            upgrade) && (
            <div tw="ml-auto flex gap-2">
              {upgrade && (
                <div tw="flex items-center mr-2">
                  <FontAwesomeIcon
                    icon={faCrown}
                    color="#f2c94c"
                    transform="up-2"
                  />
                </div>
              )}

              {filters?.length && (
                <div tw="flex items-center gap-2">
                  {filters.map((f, i) => (
                    <PillRadio
                      key={i}
                      options={f.options}
                      value={f.value}
                      onChange={f.onChange}
                      tw="mr-0"
                    />
                  ))}
                </div>
              )}

              {!!visibleBadges?.length && (
                <div tw="flex items-center last:mr-0 gap-2">
                  {visibleBadges.map((badge) => (
                    <Tippy
                      key={badge.label}
                      content={badge.tooltip}
                      disabled={!badge.tooltip}
                    >
                      <Badge
                        color={badge.color}
                        icon={badge.icon}
                        iconSpin={badge.iconSpin}
                        prefix={badge.prefix}
                      >
                        {badge.label}
                      </Badge>
                    </Tippy>
                  ))}
                </div>
              )}

              {!!visibleButtons?.length && (
                <div ref={buttonRef}>
                  {visibleButtons.map((button) => (
                    <Button
                      key={button.label}
                      buttonType={button.type || "secondary"}
                      disabled={button.disabled}
                      isLoading={button.isLoading}
                      onClick={button.onClick}
                      form={button.form}
                    >
                      {button.icon && (
                        <FontAwesomeIcon icon={button.icon} tw="mr-1" />
                      )}{" "}
                      {button.label}
                    </Button>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      )}

      {isLoading ? <Spinner /> : children}

      {!title && upgrade && (
        <div tw="absolute bottom-1 right-3 pointer-events-none">
          <FontAwesomeIcon icon={faCrown} color="#f2c94c" transform="up-2" />
        </div>
      )}
    </div>
  );
};

export default Card;
