import { gql, useMutation } from "@apollo/client";
import {
  faArrowUpRightFromSquare,
  faDownload,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Temporal } from "@js-temporal/polyfill";

import {
  CustomerPortalInvoiceRowDownloadInvoiceMutation,
  CustomerPortalInvoiceRowDownloadInvoiceMutationVariables,
  CustomerPortalSubscriptionDetailsInvoiceFragment,
} from "../../../__generated__/graphql";
import LoadingModal from "../../../common/LoadingModal";

interface InvoiceRowProps {
  sessionToken: string;
  invoice: CustomerPortalSubscriptionDetailsInvoiceFragment;
  linkType: "download" | "view";
}

const InvoiceRow: React.FunctionComponent<InvoiceRowProps> = ({
  sessionToken,
  invoice,
  linkType,
}) => {
  const [downloadInvoice, { loading }] = useMutation<
    CustomerPortalInvoiceRowDownloadInvoiceMutation,
    CustomerPortalInvoiceRowDownloadInvoiceMutationVariables
  >(gql`
    mutation CustomerPortalInvoiceRowDownloadInvoiceMutation(
      $input: PortalDownloadInvoiceInput!
    ) {
      portalDownloadInvoice(input: $input) {
        url
      }
    }
  `);

  const handleClickDownload = async () => {
    const result = await downloadInvoice({
      variables: {
        input: {
          portalSessionToken: sessionToken,
          platformInvoiceId: invoice.platformId,
        },
      },
    });

    if (!result.data?.portalDownloadInvoice) {
      throw new Error();
    }

    window.open(result.data.portalDownloadInvoice.url, "_blank");
  };

  return (
    <>
      <div tw="border-b border-divider last:border-b-0 py-3 px-4">
        <div tw="grid grid-cols-[1fr_8rem_6rem_6rem_1rem]">
          <div>{invoice.number}</div>
          <div>
            {Temporal.Instant.from(invoice.periodEnd).toLocaleString(
              undefined,
              { dateStyle: "medium" }
            )}
          </div>
          <div tw="capitalize">{invoice.status}</div>
          <div>
            {new Intl.NumberFormat(undefined, {
              style: "currency",
              currency: "usd",
            }).format(Number(invoice.total))}
          </div>
          <div>
            <button
              type="button"
              tw="text-blue hover:text-blue-600"
              onClick={handleClickDownload}
              disabled={loading}
            >
              <FontAwesomeIcon
                icon={
                  linkType === "view" ? faArrowUpRightFromSquare : faDownload
                }
              />
            </button>
          </div>
        </div>
      </div>
      <LoadingModal
        isOpen={loading}
        message="Loading&hellip;"
        spinnerStyle="generic"
      />
    </>
  );
};

export default InvoiceRow;
