import {
  language_enum,
  offer_autopilot_strategy_enum,
  offer_autopilot_success_metric_enum,
  offer_type_enum,
} from "../../../__generated__/graphql";

export const AUTOPILOT_SUPPORTED_STRATEGIES = [
  offer_autopilot_strategy_enum.copy,
];
export const AUTOPILOT_SUPPORTED_SUCCESS_METRICS = [
  offer_autopilot_success_metric_enum.revenue_potential,
];
export const AUTOPILOT_SUPPORTED_OFFER_TYPES = [offer_type_enum.coupon];

export const AUTOPILOT_DEFAULT_LANGUAGE = language_enum.en_us;
export const AUTOPILOT_ENABLED_LANGUAGES = [language_enum.en_us];
