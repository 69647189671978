import { defaultContentValues } from "../../../../__generated__/editor";
import {
  language_enum,
  OfferDetailsFragment,
} from "../../../../__generated__/graphql";
import { TranslatedForms } from "../../../../common/form/useTranslatableForm";
import translationText from "../../../../common/translationText";
import translationValue from "../../../../common/translationValue";
import { OfferFormValues } from "../types";

const mapFormValues = (
  formValues: Partial<OfferFormValues>,
  offer: OfferDetailsFragment,
  enabledLanguages: language_enum[]
): TranslatedForms<OfferFormValues> =>
  enabledLanguages.reduce<TranslatedForms<OfferFormValues>>(
    (prev, language) => {
      const content = translationValue(
        offer.content_translation,
        language,
        language
      ).value;
      const contentFormat = translationValue(
        offer.content_translation,
        language,
        language
      ).format;
      const confirmationContent = translationValue(
        offer.confirmation_content_translation,
        language,
        language
      ).value;

      return {
        ...prev,
        [language]: {
          ...formValues,
          headline: translationText(
            offer.headline_translation,
            language,
            language
          ),
          content: content ? JSON.stringify(content) : undefined,
          contentFormat,
          buttonText: translationText(
            offer.button_text_translation,
            language,
            language
          ),
          confirmationContent: JSON.stringify(
            confirmationContent ||
              defaultContentValues["campaign.confirmation"][language]
          ),
          confirmationConfirmButtonText: translationText(
            offer.confirmation_confirm_button_text_translation,
            language,
            language
          ),
          confirmationCancelButtonText: translationText(
            offer.confirmation_cancel_button_text_translation,
            language,
            language
          ),
        },
      };
    },
    {}
  );

export default mapFormValues;
