import { language_enum, TranslationFragment } from "../__generated__/graphql";
import translationValue from "./translationValue";

export default function translationText(
  translation: TranslationFragment | null | undefined,
  language: language_enum,
  defaultLanguage: language_enum
): string {
  return translationValue(translation, language, defaultLanguage).value || "";
}
