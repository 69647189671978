import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { nanoid } from "nanoid";
import { useEffect, useMemo, useState } from "react";
import { Controller } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import { isPresent } from "ts-is-present";
import tw, { styled } from "twin.macro";

import { defaultContentValues } from "../../__generated__/editor";
import {
  AppPropertyFragment,
  flow_action_appearance_enum,
  flow_action_type_enum,
  language_enum,
  translation_value_format_enum,
} from "../../__generated__/graphql";
import isSlateContentEmpty from "../../common/editor/lib/isContentEmpty";
import EditorSelector from "../../common/editor2/EditorSelector";
import { isContentEmpty as isLexicalContentEmpty } from "../../common/editor2/lib";
import Button from "../../common/form/Button";
import FieldError from "../../common/form/FieldError";
import FieldHint from "../../common/form/FieldHint";
import FieldInput from "../../common/form/FieldInput";
import FieldLabel from "../../common/form/FieldLabel";
import FieldRow from "../../common/form/FieldRow";
import FieldRowBlock from "../../common/form/FieldRowBlock";
import FieldSetTitle from "../../common/form/FieldSetTitle";
import SelectInput from "../../common/form/input/SelectInput";
import TagsInput from "../../common/form/input/TagsInput";
import TextInput from "../../common/form/input/TextInput";
import useTranslatableForm, {
  TranslatedForms,
} from "../../common/form/useTranslatableForm";
import IdDropdown from "../../common/IdDropdown";
import LoadableIconPicker from "../../common/LoadableIconPicker";
import PillRadio, { PillRadioOption } from "../../common/PillRadio";
import StandardLinkButton from "../../common/StandardLinkButton";
import { useTranslations } from "../../common/translations/TranslationsProvider";
import useAccountFeatures from "../../common/useAccountFeatures";
import useHasMultipleFlows from "../../common/useHasMultipleFlows";
import usePrevious from "../../common/usePrevious";
import LanguageRadio from "../flow/edit/LanguageRadio";
import { usePropertyValues } from "../properties/lib/propertyValues";
import { DeleteButton } from "../public/flow/steps/SharedComponents";
import FlowIdDropdown from "../segments/FlowIdDropdown";
import { generatePaidFeatureClassName } from "../upgrade-account/usePaidFeature";
import useValidateName from "./lib/useValidateName";

export interface SnapshotItemFormValues {
  key: string;
  token: string | null;
  icon: string | null;
  text: string;
  textFormat: translation_value_format_enum;
  propertyId: number | null;
  minimumValue: number | null;
  dateFormat: string | null;
}

export interface FlowActionFormValues {
  key: string;
  token: string | null;
  code: string | null;
  text: string;
  textFormat: translation_value_format_enum;
  type: flow_action_type_enum;
  appearance: flow_action_appearance_enum;
  url: string | null;
  rerouteToFlowId: number | null;
}

export interface DeflectionFormValues {
  name: string;
  tags: string[];
  heading: string;
  headingFormat: translation_value_format_enum;
  content: string;
  contentFormat: translation_value_format_enum;
  snapshotItems: SnapshotItemFormValues[];
  flowActions: FlowActionFormValues[];
  minimumItems: number;
}

const StyledFieldInput = styled(FieldInput)`
  ${tw`flex`}

  button, select {
    ${tw`grow`}
  }
`;

const Item = styled.div`
  ${tw`mt-3 first:mt-4 px-3 py-2 pb-2 border border-divider bg-gray-50 rounded shadow-sm`}

  button:not(.button--x) {
    ${tw`bg-white`}
  }

  > div:last-child {
    ${tw`border-b-0 pb-1`}
  }
`;

const NoDataContainer = tw.div`rounded border min-h-[98px] mt-4 p-4 text-center flex items-center justify-center`;

const ItemFieldLabel = tw.span`font-bold`;
const ItemFieldRow = styled(FieldRow)`
  ${tw`border-gray-200`}
`;
const ItemFieldRowBlock = styled(FieldRowBlock)`
  ${tw`border-gray-200`}
`;

const getDefaultValues = (languages: language_enum[]) =>
  languages.reduce<TranslatedForms<DeflectionFormValues>>(
    (prev, language) => ({
      ...prev,
      [language]: {
        name: "",
        tags: [] as string[],
        heading: JSON.stringify(
          defaultContentValues["deflection.heading.default"][language]
        ),
        headingFormat: translation_value_format_enum.lexical,
        content: JSON.stringify(
          defaultContentValues["deflection.content.default"][language]
        ),
        contentFormat: translation_value_format_enum.lexical,
        snapshotItems: [] as SnapshotItemFormValues[],
        flowActions: [] as FlowActionFormValues[],
      } as DeflectionFormValues,
    }),
    {}
  );

const getSnapshotItemDefaultValues = (language: language_enum) =>
  ({
    key: nanoid(),
    text: JSON.stringify(
      defaultContentValues["deflection_snapshot_item.text.default"][language]
    ),
    textFormat: translation_value_format_enum.lexical,
    propertyId: null,
    minimumValue: null,
    dateFormat: null,
  } as SnapshotItemFormValues);

const getFlowActionDefaultValues = (language: language_enum) =>
  ({
    key: nanoid(),
    code: null,
    text: JSON.stringify(
      defaultContentValues["flow_action.text.default"][language]
    ),
    textFormat: translation_value_format_enum.lexical,
    type: flow_action_type_enum.close,
    appearance: flow_action_appearance_enum.button,
    url: null,
    rerouteToFlowId: null,
    token: "",
  } as FlowActionFormValues);

interface DeflectionFormProps {
  initialValues?: TranslatedForms<DeflectionFormValues>;
  isSubmitting: boolean;
  tags: string[];
  onChange: (values: TranslatedForms<DeflectionFormValues>) => void;
  onSubmit: (values: TranslatedForms<DeflectionFormValues>) => void;
  onChangeEditingLanguage: (language: language_enum) => void;
  onClickCreateProperty: () => void;
  isFreeMode: boolean;
  newProperty?: AppPropertyFragment;
}

const DeflectionForm: React.FunctionComponent<DeflectionFormProps> = ({
  initialValues,
  isSubmitting,
  tags,
  onChange,
  onSubmit,
  onChangeEditingLanguage,
  onClickCreateProperty,
  isFreeMode = false,
  newProperty,
}) => {
  const { addToast } = useToasts();

  const { defaultLanguage, enabledLanguages } = useTranslations();
  const defaultValues = initialValues || getDefaultValues(enabledLanguages);

  const handleClickCreateProperty = (index: number) => {
    setNewPropertyForSnapshotIndex(index);
    onClickCreateProperty();
  };

  const {
    register,
    watch,
    formState,
    handleSubmit,
    control,
    editingLanguage,
    setEditingLanguage,
    forms,
    setValue,
  } = useTranslatableForm<DeflectionFormValues>({
    defaultValues,
    initialLanguage: defaultLanguage,
    languages: enabledLanguages,
    defaultLanguage,
    translatableFields: ["heading", "content"],
  });

  const [newPropertyForSnapshotIndex, setNewPropertyForSnapshotIndex] =
    useState<number | null>(null);
  const validateName = useValidateName(formState.dirtyFields.name || false);
  const values = watch();

  const { features } = useAccountFeatures();
  const { hasMultipleFlowsFeature, hasMoreThanOneFlow } = useHasMultipleFlows();
  const { propertyConfig } = usePropertyValues();

  const propertyIds = useMemo(
    () =>
      Object.entries(propertyConfig).map(([id, property]) => ({
        id,
        label: property.name,
      })),
    [propertyConfig]
  );

  const previousNewProperty = usePrevious(newProperty);

  useEffect(() => {
    if (
      !newProperty?.id ||
      previousNewProperty?.id === newProperty.id ||
      newPropertyForSnapshotIndex === null
    ) {
      return;
    }

    setValue(
      "snapshotItems",
      [
        ...values.snapshotItems.slice(0, newPropertyForSnapshotIndex),
        {
          ...values.snapshotItems[newPropertyForSnapshotIndex],
          propertyId: newProperty.id,
        },
        ...values.snapshotItems.slice(newPropertyForSnapshotIndex + 1),
      ].filter(isPresent)
    );

    setNewPropertyForSnapshotIndex(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newProperty?.id, previousNewProperty?.id, newPropertyForSnapshotIndex]);

  useEffect(() => {
    onChange(forms);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forms, values]);

  const addSnapshotItem = () => {
    if (values.snapshotItems.length >= 4) {
      addToast("You cannot have more than four snapshot items.", {
        appearance: "error",
      });
      return;
    }

    setValue("snapshotItems", [
      ...values.snapshotItems,
      getSnapshotItemDefaultValues(editingLanguage),
    ]);
  };

  const deleteSnapshotItem = (key: string) => {
    setValue(
      "snapshotItems",
      values.snapshotItems.filter((item) => item.key !== key)
    );
  };

  const addFlowAction = () => {
    setValue("flowActions", [
      ...values.flowActions,
      getFlowActionDefaultValues(editingLanguage),
    ]);
  };

  const deleteFlowAction = (key: string) => {
    setValue(
      "flowActions",
      values.flowActions.filter((item) => item.key !== key)
    );
  };

  const validateEditorValue = (
    value: string,
    format: translation_value_format_enum
  ) =>
    format === "slate"
      ? !isSlateContentEmpty(JSON.parse(value))
      : !isLexicalContentEmpty(JSON.parse(value));

  return (
    <form id="create-deflection" onSubmit={handleSubmit(onSubmit)}>
      <fieldset disabled={isSubmitting}>
        <FieldRow>
          <FieldLabel>
            <label htmlFor="name">Name</label>
          </FieldLabel>
          <FieldInput>
            <TextInput
              {...register("name", {
                required: true,
                validate: validateName,
              })}
              id="name"
              width="full"
              fieldError={formState.errors.name}
            />
            <FieldError error={formState.errors.name} />
          </FieldInput>
        </FieldRow>
        <FieldRow
          className={generatePaidFeatureClassName(
            "Upgrade your plan to add custom tags.",
            isFreeMode,
            "form"
          )}
        >
          <FieldLabel>Tags</FieldLabel>
          <FieldInput>
            <Controller
              control={control}
              name="tags"
              render={({ field }) => (
                <TagsInput
                  tags={tags}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </FieldInput>
        </FieldRow>
      </fieldset>
      <fieldset disabled={isSubmitting}>
        <FieldSetTitle>Deflection details</FieldSetTitle>
        {(enabledLanguages.length > 1 || isFreeMode) && (
          <FieldRow>
            <div
              className={generatePaidFeatureClassName(
                "Upgrade your plan to add support for multiple languages.",
                isFreeMode,
                "form"
              )}
            >
              <LanguageRadio
                value={editingLanguage}
                languages={enabledLanguages}
                defaultLanguage={defaultLanguage}
                onChange={(language) => {
                  setEditingLanguage(language);
                  onChangeEditingLanguage(language);
                }}
              />
            </div>
          </FieldRow>
        )}
        <FieldRowBlock>
          <FieldLabel>Heading</FieldLabel>
          <Controller
            control={control}
            name="heading"
            rules={{
              validate: (value) =>
                validateEditorValue(value, values.headingFormat),
            }}
            render={({ field, fieldState }) => {
              const form = forms[editingLanguage];
              if (!form) {
                return <></>;
              }

              return (
                <EditorSelector
                  initialValueKey={editingLanguage}
                  isOfferContent={false}
                  hasCustomProperties={!!features.custom_properties}
                  format={form.headingFormat}
                  value={form.heading}
                  height="64px"
                  isInline={true}
                  linksEnabled={false}
                  listsEnabled={false}
                  imagesEnabled={false}
                  videosEnabled={false}
                  fieldError={fieldState.error}
                  onChange={(value) => {
                    field.onChange(value);
                  }}
                />
              );
            }}
          />
        </FieldRowBlock>
        <FieldRowBlock>
          <FieldLabel>Content</FieldLabel>
          <Controller
            control={control}
            name="content"
            rules={{
              validate: (value) =>
                validateEditorValue(value, values.contentFormat),
            }}
            render={({ field, fieldState }) => {
              const form = forms[editingLanguage];
              if (!form) {
                return <></>;
              }

              return (
                <EditorSelector
                  initialValueKey={editingLanguage}
                  isOfferContent={false}
                  hasCustomProperties={!!features.custom_properties}
                  format={form.contentFormat}
                  value={form.content}
                  fieldError={fieldState.error}
                  onChange={(value) => {
                    field.onChange(value);
                  }}
                />
              );
            }}
          />
        </FieldRowBlock>
      </fieldset>
      <fieldset disabled={isSubmitting}>
        <FieldSetTitle>Snapshot items</FieldSetTitle>
        {!values.snapshotItems.length && (
          <FieldRow tw="!border-none">
            <NoDataContainer>
              <span>
                Present users with data points mapped from integrations or
                custom properties.{" "}
                <StandardLinkButton onClick={addSnapshotItem}>
                  Create a snapshot item.
                </StandardLinkButton>
              </span>
            </NoDataContainer>
          </FieldRow>
        )}
        {values.snapshotItems.map((item, index) => (
          <Item key={item.key}>
            <ItemFieldLabel tw="flex grow">
              <span>Item {index + 1}</span>
              <div tw="ml-auto">
                <DeleteButton
                  className="button--x"
                  tw="-mr-1"
                  type="button"
                  onClick={() => deleteSnapshotItem(item.key)}
                >
                  <FontAwesomeIcon icon={faTimesCircle} />
                </DeleteButton>
              </div>
            </ItemFieldLabel>

            <ItemFieldRow>
              <FieldLabel>Icon</FieldLabel>
              <FieldInput>
                <Controller
                  control={control}
                  name={`snapshotItems.${index}.icon`}
                  render={({ field }) => (
                    <LoadableIconPicker
                      value={item.icon && JSON.parse(item.icon)}
                      onChange={(value) => {
                        field.onChange(JSON.stringify(value));
                      }}
                    />
                  )}
                />
              </FieldInput>
            </ItemFieldRow>
            <ItemFieldRow>
              <FieldLabel>Property</FieldLabel>
              <StyledFieldInput>
                <Controller
                  control={control}
                  name={`snapshotItems.${index}.propertyId`}
                  render={({ field }) => (
                    <IdDropdown
                      key={`propertyId-${index}`}
                      ids={propertyIds}
                      value={item.propertyId ? item.propertyId.toString() : ""}
                      onChange={(value) => {
                        field.onChange(Number(value));
                        if (propertyConfig[Number(value)]?.type !== "number") {
                          setValue(`snapshotItems.${index}.minimumValue`, null);
                        }
                        if (propertyConfig[Number(value)]?.type !== "date") {
                          setValue(`snapshotItems.${index}.dateFormat`, null);
                        }
                      }}
                      showId={false}
                      placeholder="Select a property..."
                      onClickCreate={
                        features.custom_properties
                          ? () => handleClickCreateProperty(index)
                          : undefined
                      }
                      createText="Create new property"
                      width="160px"
                    />
                  )}
                />
              </StyledFieldInput>
            </ItemFieldRow>
            {item.propertyId &&
              propertyConfig[item.propertyId]?.type === "number" && (
                <ItemFieldRow>
                  <FieldLabel>
                    <label>Minimum value</label>
                  </FieldLabel>
                  <StyledFieldInput>
                    <div tw="flex-col">
                      <TextInput
                        {...register(`snapshotItems.${index}.minimumValue`)}
                        type="number"
                        width="full"
                      />
                      <FieldHint>
                        Item will be shown if the property value is greater than
                        this value.
                      </FieldHint>
                    </div>
                  </StyledFieldInput>
                </ItemFieldRow>
              )}
            {item.propertyId &&
              propertyConfig[item.propertyId]?.type === "date" && (
                <ItemFieldRow>
                  <FieldLabel>
                    <label>Date format</label>
                  </FieldLabel>
                  <StyledFieldInput>
                    <Controller
                      control={control}
                      name={`snapshotItems.${index}.dateFormat`}
                      render={({ field }) => (
                        <SelectInput
                          value={item.dateFormat || "MMM. d, yyyy"}
                          width="md"
                          onChange={(value) => {
                            field.onChange(value);
                          }}
                        >
                          <option value="MMM. d, yyyy">
                            Default (Feb. 4, 2007)
                          </option>
                          <option value="MMMM d, yyyy">
                            Full (e.g. February 4, 2007)
                          </option>
                          <option value="M/d/yy">M/D/Y</option>
                          <option value="d/M/yy">D/M/Y</option>
                        </SelectInput>
                      )}
                    />
                  </StyledFieldInput>
                </ItemFieldRow>
              )}
            <ItemFieldRowBlock>
              <FieldLabel>Text</FieldLabel>
              <Controller
                control={control}
                name={`snapshotItems.${index}.text`}
                rules={{
                  validate: (value) =>
                    validateEditorValue(value, item.textFormat),
                }}
                render={({ field, fieldState }) => {
                  const form = forms[editingLanguage];
                  if (!form) {
                    return <></>;
                  }

                  return (
                    <EditorSelector
                      initialValueKey={editingLanguage}
                      isOfferContent={false}
                      hasCustomProperties={!!features.custom_properties}
                      format={item.textFormat}
                      value={item.text}
                      height="64px"
                      isInline={true}
                      listsEnabled={false}
                      imagesEnabled={false}
                      videosEnabled={false}
                      fieldError={fieldState.error}
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                    />
                  );
                }}
              />
            </ItemFieldRowBlock>
          </Item>
        ))}
        {values.snapshotItems.length < 4 && (
          <FieldRow>
            <FieldLabel>
              <Button
                type="button"
                size="sm"
                onClick={addSnapshotItem}
                buttonType="alternate-secondary"
              >
                <FontAwesomeIcon icon={faPlus} transform="left-1" /> Add
                snapshot item
              </Button>
            </FieldLabel>
          </FieldRow>
        )}
        {!!values.snapshotItems.length && (
          <FieldRow>
            <FieldLabel>
              <label htmlFor="minimumItems">Minimum items</label>
            </FieldLabel>
            <FieldInput>
              <TextInput
                id="minimumItems"
                type="number"
                disabled={!values.snapshotItems.length}
                {...register("minimumItems", {
                  validate: (value) =>
                    Number(value) > values.snapshotItems.length
                      ? "Minimum cannot be greater than total number of items"
                      : Number(value) < 0
                      ? "Invalid minimum"
                      : undefined,
                })}
                width="sm"
              />
              <FieldHint>
                Skip this deflection in flows if fewer than this number of items
                are shown. Leave blank for no minimum.
              </FieldHint>
              <FieldError error={formState.errors.minimumItems}></FieldError>
            </FieldInput>
          </FieldRow>
        )}
      </fieldset>
      <fieldset disabled={isSubmitting}>
        <FieldSetTitle>Actions</FieldSetTitle>
        {!values.flowActions.length && (
          <FieldRow tw="!border-none">
            <NoDataContainer>
              <span>
                Present users with other helpful actions they can take instead
                of canceling.{" "}
                <StandardLinkButton onClick={addFlowAction}>
                  Create an action.
                </StandardLinkButton>
              </span>
            </NoDataContainer>
          </FieldRow>
        )}
        {values.flowActions.map((flowAction, index) => (
          <Item key={flowAction.key}>
            <ItemFieldLabel tw="flex grow">
              <span>Action {index + 1}</span>
              <div tw="ml-auto">
                <DeleteButton
                  className="button--x"
                  tw="-mr-1"
                  type="button"
                  onClick={() => deleteFlowAction(flowAction.key)}
                >
                  <FontAwesomeIcon icon={faTimesCircle} />
                </DeleteButton>
              </div>
            </ItemFieldLabel>
            <ItemFieldRow>
              <FieldLabel>Code</FieldLabel>
              <FieldInput>
                <TextInput
                  {...register(`flowActions.${index}.code`)}
                  id={`code-${index}`}
                  width="full"
                />
                <FieldHint>
                  Optional code for use in reporting and third-party
                  integrations.
                </FieldHint>
              </FieldInput>
            </ItemFieldRow>
            <ItemFieldRowBlock>
              <FieldLabel>Text</FieldLabel>
              <Controller
                control={control}
                name={`flowActions.${index}.text`}
                rules={{
                  validate: (value) =>
                    validateEditorValue(value, flowAction.textFormat),
                }}
                render={({ field, fieldState }) => {
                  const form = forms[editingLanguage];
                  if (!form) {
                    return <></>;
                  }

                  return (
                    <EditorSelector
                      initialValueKey={editingLanguage}
                      isOfferContent={false}
                      hasCustomProperties={!!features.custom_properties}
                      format={flowAction.textFormat}
                      value={flowAction.text}
                      height="64px"
                      linksEnabled={false}
                      listsEnabled={false}
                      imagesEnabled={false}
                      videosEnabled={false}
                      isInline={true}
                      fieldError={fieldState.error}
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                    />
                  );
                }}
              />
            </ItemFieldRowBlock>
            <ItemFieldRow>
              <FieldLabel>Type</FieldLabel>
              <FieldInput tw="flex">
                <Controller
                  control={control}
                  name={`flowActions.${index}.type`}
                  render={({ field }) => (
                    <PillRadio
                      tw="flex flex-wrap"
                      value={field.value || ""}
                      onChange={(value) => {
                        field.onChange(value);
                        if (value !== "url") {
                          setValue(`flowActions.${index}.url`, null);
                        }
                        if (value !== "rerouted") {
                          setValue(
                            `flowActions.${index}.rerouteToFlowId`,
                            null
                          );
                        }
                      }}
                      options={(() => {
                        const options: PillRadioOption[] = [
                          {
                            label: "URL",
                            value: "url",
                          },
                          {
                            label: "Close flow",
                            value: "close",
                          },
                        ];

                        if (hasMultipleFlowsFeature) {
                          options.push({
                            label: "Route to another flow",
                            value: "reroute",
                            disabled: !hasMoreThanOneFlow,
                            tooltip: !hasMoreThanOneFlow
                              ? "You need at least two flows to route to another flow."
                              : undefined,
                          });
                        }

                        return options;
                      })()}
                    />
                  )}
                />
              </FieldInput>
            </ItemFieldRow>
            {flowAction.type === "url" && (
              <ItemFieldRow>
                <FieldLabel>
                  <label>URL</label>
                </FieldLabel>
                <FieldInput>
                  <Controller
                    control={control}
                    name={`flowActions.${index}.url`}
                    rules={{
                      validate: (value) => {
                        if (flowAction.type !== "url") {
                          return;
                        }

                        try {
                          new URL(value || "");
                        } catch (e) {
                          return "Must be a valid URL.";
                        }
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <>
                        <TextInput
                          value={field.value || ""}
                          onChange={field.onChange}
                          width="full"
                          fieldError={fieldState.error}
                        />
                        <FieldHint>
                          Redirect the subscriber to this URL when they click
                          the action.
                        </FieldHint>
                      </>
                    )}
                  />
                </FieldInput>
              </ItemFieldRow>
            )}
            {flowAction.type === "reroute" && (
              <ItemFieldRow>
                <FieldLabel>
                  <label>Flow</label>
                </FieldLabel>
                <FieldInput>
                  <Controller
                    control={control}
                    name={`flowActions.${index}.rerouteToFlowId`}
                    render={({ field, fieldState }) => (
                      <FlowIdDropdown
                        value={field.value?.toString() || ""}
                        onChange={(value) =>
                          field.onChange(value ? Number(value) : undefined)
                        }
                        useId
                        fieldError={fieldState.error}
                      />
                    )}
                    rules={{
                      validate: (value) =>
                        flowAction.type === "reroute" && !value
                          ? "Flow is required."
                          : undefined,
                    }}
                  />
                  <FieldHint>
                    Redirect the subscriber to this cancellation flow when they
                    click the action.
                  </FieldHint>
                </FieldInput>
              </ItemFieldRow>
            )}
          </Item>
        ))}
        <FieldRow>
          <FieldLabel>
            <Button
              type="button"
              size="sm"
              onClick={addFlowAction}
              buttonType="alternate-secondary"
            >
              <FontAwesomeIcon icon={faPlus} transform="left-1" /> Add action
            </Button>
          </FieldLabel>
        </FieldRow>
      </fieldset>
    </form>
  );
};

export default DeflectionForm;
