import tw, { styled } from "twin.macro";

const FieldSetTitle = styled.div`
  ${tw`pt-10 pb-2 text-base font-title font-semibold border-b border-gray-100`}

  fieldset:first-child > & {
    ${tw`pt-2`}
  }
`;

export default FieldSetTitle;
