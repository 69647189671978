import { isPresent } from "ts-is-present";

import {
  FlowDeflectionFragment,
  FlowOfferRuleFragment,
  FlowStepFragment,
  language_enum,
} from "../../../../__generated__/graphql";
import answersMatch from "../../../../common/flow/answersMatch";
import getFlowObjectVersion from "../../../../common/flow/getFlowObjectVersion";
import mapDeflectionRules from "../../../../common/flow/mapDeflectionRules";
import { FlowVersion } from "../../../../common/flow/types";
import isTranslationContentEmpty from "../../../../common/isTranslationContentEmpty";
import randomByWeight from "../../../../common/randomByWeight";
import { PropertyValues } from "../../../properties/lib/types";
import coreDebugLog from "./debugLog";
import { QuestionAnswer } from "./types";

const evaluateDeflectionRules = (
  deflectionRules: FlowOfferRuleFragment[],
  questionAnswers: QuestionAnswer[],
  matchedSegmentIds: number[],
  matchedSegmentGroupIds: number[],
  flowVersion: FlowVersion,
  debug: boolean = true
) => {
  for (const rule of deflectionRules) {
    const ruleVersion = getFlowObjectVersion(rule, flowVersion);

    const debugLog = (message?: any, ...optionalParams: any[]) =>
      // eslint-disable-next-line no-console
      coreDebugLog(
        message,
        {
          ruleId: rule.id,
          ruleVersionId: ruleVersion.id,
        },
        ...(!!optionalParams && optionalParams)
      );

    if (debug) {
      debugLog("Evaluating offer rule");
    }

    const options: Array<{
      questionId: number;
      questionOptionId: number | null;
    }> = ruleVersion.offer_rule_version_question_options
      .map((o) => o.question_option)
      .filter(isPresent)
      .map((o) => ({
        questionId: o.question_id,
        questionOptionId: o.id,
      }));

    for (const questionId of ruleVersion.include_other_in_question_ids || []) {
      options.push({ questionId, questionOptionId: null });
    }

    const answers = questionAnswers
      .map((answer) => ({
        questionId: answer.id,
        questionOptionIds: Array.isArray(answer.value)
          ? answer.value.map((v) => v.id)
          : [],
        isOther: answer.type === "radio" && !!answer.specify,
      }))
      .filter(isPresent);

    debugLog("Checking answer match", options, answers);

    if (!answersMatch(options, answers)) {
      debugLog("Answers do not match");
      continue;
    }

    debugLog("Answers match, evaluating segments");

    // No segments or segment groups in the rule = "any".
    if (
      !ruleVersion.offer_rule_version_segments.length &&
      !ruleVersion.offer_rule_version_segment_groups.length
    ) {
      return { matchingRuleVersion: ruleVersion };
    }

    const segmentIds = ruleVersion.offer_rule_version_segments
      .map(({ segment_id }) => segment_id)
      .filter(isPresent);

    const matchingSegments = segmentIds.filter((id) =>
      matchedSegmentIds.includes(id)
    );

    if (matchingSegments.length) {
      debugLog("Segment IDs matched", matchingSegments);

      return {
        matchingRuleVersion: ruleVersion,
      };
    } else {
      debugLog("Segment IDs did not match", segmentIds);
    }

    debugLog("Evaluating segment groups");

    const segmentGroupIds = ruleVersion.offer_rule_version_segment_groups
      .map(({ segment_group_id }) => segment_group_id)
      .filter(isPresent);

    const matchingSegmentGroups = segmentGroupIds.filter((id) =>
      matchedSegmentGroupIds.includes(id)
    );

    if (matchingSegmentGroups.length) {
      debugLog("Segment group IDs matched", matchingSegmentGroups);

      return {
        matchingRuleVersion: ruleVersion,
      };
    } else {
      debugLog("Segment group IDs did not match", segmentGroupIds);
    }
  }

  return {
    matchingRuleVersion: null,
  };
};

const deflectionMeetsSnapshotItemRequirements = (
  deflection: FlowDeflectionFragment,
  propertyValues: PropertyValues = {}
) => {
  if (!deflection.minimum_items || deflection.minimum_items < 0) {
    return true;
  }

  let validItemCount = 0;

  for (const {
    deflection_snapshot_item: deflectionSnapshotItem,
  } of deflection.deflection_snapshot_items) {
    if (validItemCount >= deflection.minimum_items) {
      return true;
    }

    if (!deflectionSnapshotItem) {
      throw new Error("Flow step deflection snapshot item is missing");
    }

    const property = deflectionSnapshotItem.property;

    if (!property) {
      validItemCount++;
      continue;
    }

    const propertyValue = propertyValues[property.id];

    if (typeof propertyValue === "undefined") {
      continue;
    }

    if (
      property.type === "text" &&
      typeof propertyValue === "string" &&
      !propertyValue.length
    ) {
      continue;
    }

    if (
      property.type === "number" &&
      typeof deflectionSnapshotItem.property_condition_value === "number"
    ) {
      if (
        (propertyValue as number) <=
        deflectionSnapshotItem.property_condition_value
      ) {
        continue;
      }
    }

    validItemCount++;
  }

  return validItemCount >= deflection.minimum_items;
};

const deflectionIsPresentable = (
  deflection: FlowDeflectionFragment,
  propertyValues: PropertyValues = {},
  language: language_enum,
  defaultLanguage: language_enum
) => {
  if (deflection.deflection_flow_actions.length > 0) {
    let hasAtLeastOneValidAction = false;

    for (const {
      flow_action: flowAction,
    } of deflection.deflection_flow_actions) {
      if (!flowAction) {
        throw new Error("Flow action is missing");
      }

      if (
        !isTranslationContentEmpty(
          flowAction.text_translation,
          language,
          defaultLanguage
        ) &&
        ((flowAction.type === "reroute" && !!flowAction.reroute_to_flow_id) ||
          flowAction.type === "close" ||
          (flowAction.type === "url" && !!flowAction.url))
      ) {
        hasAtLeastOneValidAction = true;
        break;
      }
    }

    if (!hasAtLeastOneValidAction) {
      return false;
    }
  }

  if (
    isTranslationContentEmpty(
      deflection.heading_translation,
      language,
      defaultLanguage
    ) ||
    !deflection.content_translation ||
    isTranslationContentEmpty(
      deflection.content_translation,
      language,
      defaultLanguage
    )
  ) {
    return false;
  }

  return deflectionMeetsSnapshotItemRequirements(deflection, propertyValues);
};

interface DiscoverDeflectionOptions {
  flowStep: FlowStepFragment;
  flowVersion: FlowVersion;
  questionAnswers: QuestionAnswer[];
  propertyValues: PropertyValues;
  matchedSegmentIds: number[];
  matchedSegmentGroupIds: number[];
  presentedDeflectionIds: number[];
  language: language_enum;
  defaultLanguage: language_enum;
}

export const discoverDeflection = ({
  flowStep,
  flowVersion,
  questionAnswers,
  propertyValues = {},
  matchedSegmentIds,
  matchedSegmentGroupIds,
  presentedDeflectionIds,
  language,
  defaultLanguage,
}: DiscoverDeflectionOptions): FlowDeflectionFragment | null => {
  if (flowStep.type !== "deflection_rule_group") {
    return null;
  }

  const ruleGroup = flowStep.flow_step_deflection_rule_group?.offer_rule_group;
  if (!ruleGroup) {
    throw new Error(`No deflection rule group for flow step ${flowStep.token}`);
  }

  const ruleGroupVersion = getFlowObjectVersion(ruleGroup, flowVersion);
  const unmappedRules = ruleGroupVersion.offer_rule_group_version_offer_rules
    .map(({ offer_rule }) => offer_rule)
    .filter(isPresent);
  const rules = mapDeflectionRules(unmappedRules);

  const { matchingRuleVersion } = evaluateDeflectionRules(
    rules,
    questionAnswers,
    matchedSegmentIds,
    matchedSegmentGroupIds,
    flowVersion
  );

  // Select the matching rule group.
  const ruleRule = randomByWeight(
    matchingRuleVersion?.offer_rule_version_offer_rule_rules
      .map((r) => r.offer_rule_rule)
      .filter(isPresent) || []
  );

  const deflections = (ruleRule?.offer_rule_rule_deflections || [])
    .map(({ deflection }) => deflection)
    .filter(isPresent)
    .map((deflection) => ({
      deflection,
      weight: 100,
    }));

  // Remove any deflections that have already been presented in this flow
  // and any that do not meet the minimum snapshot item requirement.
  const validDeflections: Array<{
    deflection: FlowDeflectionFragment;
    weight: number;
  }> = deflections
    .filter(({ deflection }) => !presentedDeflectionIds.includes(deflection.id))
    .filter(({ deflection }) =>
      deflectionIsPresentable(
        deflection,
        propertyValues,
        language,
        defaultLanguage
      )
    );

  if (validDeflections.length) {
    const deflectionIds = validDeflections.map((d) => ({
      id: d.deflection.id,
      weight: d.weight,
    }));

    if (ruleRule?.include_present_no_offer) {
      deflectionIds.push({
        id: 0,
        weight: 100,
      });
    }

    const deflectionId = randomByWeight(deflectionIds)?.id;

    return (
      deflections.find(({ deflection }) => deflection.id === deflectionId)
        ?.deflection || null
    );
  }

  return null;
};

export default discoverDeflection;
