import gql from "graphql-tag";

import TranslationValueFragment from "./TranslationValueFragment";

export default gql`
  fragment TranslationFragment on translation {
    id
    translation_values {
      ...TranslationValueFragment
    }
  }
  ${TranslationValueFragment}
`;
