import Tippy, { useSingleton } from "@tippyjs/react";
import { useLayoutEffect } from "react";
import { createContext, useContext, useState } from "react";

import { useSettings } from "../../app/SettingsProvider";
import useViewer from "../../common/useViewer";
import {
  getPaidFeatureTooltip,
  getPaidFeatureWrapperElements,
  PaidFeatureTooltip,
} from "./usePaidFeature";

export type UpsellTarget = {
  refId: string;
  element: Element;
  tooltip: PaidFeatureTooltip;
};

interface UpsellContext {
  enabled: boolean;
  bannerIsVisible: boolean;
  bannerFeatureText: string;
  setBannerIsVisible: (visible: boolean) => void;
  setBannerFeatureText: (text: string) => void;
  setUpgradeModalIsOpen: (isOpen: boolean) => void;
  setUpsellTargets: (els: UpsellTarget[]) => void;
  addFeatureRef: (id: string, ref: HTMLElement) => void;
  loading: boolean;
}

export const Context = createContext<UpsellContext>({
  enabled: false,
  bannerIsVisible: false,
  bannerFeatureText: "",
  setBannerIsVisible: () => {},
  setBannerFeatureText: () => {},
  setUpgradeModalIsOpen: () => {},
  setUpsellTargets: () => {},
  addFeatureRef: () => {},
  loading: false,
});

export const useUpsell = () => {
  const context = useContext(Context);
  if (!context) {
    throw new Error("useUpsell() not called within UpsellProvider context");
  }

  return context;
};

interface UpsellProviderProps {
  setUpgradeModalIsOpen?: ((isOpen: boolean) => void) | undefined;
}

const UpsellProvider: React.FunctionComponent<UpsellProviderProps> = ({
  children,
  setUpgradeModalIsOpen = undefined,
}) => {
  const { viewer, loading: viewerLoading } = useViewer();

  const { toggleFreeMode } = useSettings();

  const enabled =
    (viewer?.account?.mode === "free" && !toggleFreeMode) ||
    (viewer?.account?.mode !== "free" && toggleFreeMode);

  const { setUpgradeModalIsOpen: onClick } = useUpsell();

  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [upsellTargets, setUpsellTargets] = useState<UpsellTarget[]>([]);
  const [featureText, setFeatureText] = useState<string>(
    "Access more features"
  );

  const [source, target] = useSingleton({
    disabled: !enabled,
    overrides: ["placement", "offset"],
  });

  useLayoutEffect(() => {
    if (!featureText) {
      setIsVisible(false);
    }
  }, [featureText]);

  const addFeatureRef = (refId: string, ref: HTMLElement) => {
    if (!enabled) {
      return;
    }

    const targets: UpsellTarget[] = [];

    const elements = getPaidFeatureWrapperElements(ref);
    for (const element of elements) {
      const tooltip = getPaidFeatureTooltip(element.className);
      if (tooltip?.text) {
        targets.push({
          refId,
          element,
          tooltip,
        });
      }
    }

    setUpsellTargets(targets);
  };

  return (
    <Context.Provider
      value={{
        enabled: enabled,
        bannerIsVisible: isVisible,
        bannerFeatureText: featureText,
        setBannerIsVisible: setIsVisible,
        setBannerFeatureText: setFeatureText,
        setUpgradeModalIsOpen: setUpgradeModalIsOpen || onClick || (() => {}),
        setUpsellTargets,
        addFeatureRef,
        loading: viewerLoading,
      }}
    >
      <Tippy
        singleton={source}
        interactive={true}
        hideOnClick={false}
        delay={600}
        appendTo={document.body}
      />
      {upsellTargets.map((upsellTarget, index) => (
        <Tippy
          key={index}
          singleton={target}
          reference={upsellTarget.element}
          content={upsellTarget.tooltip.text}
          placement={upsellTarget.tooltip.placement}
          offset={upsellTarget.tooltip.offset}
        />
      ))}
      {children}
    </Context.Provider>
  );
};

export default UpsellProvider;
