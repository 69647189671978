import { faSparkles } from "@fortawesome/pro-solid-svg-icons";

import {
  offer_autopilot_strategy_enum,
  offer_autopilot_success_metric_enum,
  offer_type_enum,
} from "../../../../__generated__/graphql";
import Badge from "../../../../common/badge/Badge";
import FieldInput from "../../../../common/form/FieldInput";
import FieldLabel from "../../../../common/form/FieldLabel";
import FieldRow from "../../../../common/form/FieldRow";
import FormBanner from "../../../../common/form/FormBanner";
import FormMode from "../../../../common/form/FormMode";
import HelpIcon from "../../../../common/HelpIcon";
import StandardLinkButton from "../../../../common/StandardLinkButton";
import { AUTOPILOT_SUPPORTED_OFFER_TYPES } from "../../autopilot/config";
import autopilotFriendlyStrategy from "../../autopilot/lib/autopilotFriendlyStrategy";
import autopilotFriendlySuccessMetric from "../../autopilot/lib/autopilotFriendlySuccessMetric";
import offerFriendlyType from "../../lib/offerFriendlyType";
import offerIcon from "../../lib/offerIcon";

interface AutopilotBaseFormPartialProps {
  mode: FormMode;
  offerType: offer_type_enum;
  strategy: offer_autopilot_strategy_enum;
  successMetric: offer_autopilot_success_metric_enum;
  onClickChangeType?: () => void;
}

const AutopilotBaseFormPartial: React.FunctionComponent<
  AutopilotBaseFormPartialProps
> = ({ mode, offerType, strategy, successMetric, onClickChangeType }) => (
  <>
    <FormBanner color="light-blue" icon={faSparkles}>
      Autopilot tests three variants of this offer against one another. The
      winner moves to the next round, competing against two new variants. This
      process repeats to find the best performing offer.
    </FormBanner>

    <fieldset>
      <FieldRow>
        <FieldLabel>
          <label>Type</label>
        </FieldLabel>
        <FieldInput tw="flex items-center leading-normal">
          <Badge
            color="transparent"
            icon={offerIcon(offerType)}
            tw="inline mr-2 pl-[1px]"
          >
            {offerFriendlyType(offerType)}
          </Badge>{" "}
          {mode === "create" && AUTOPILOT_SUPPORTED_OFFER_TYPES.length > 1 && (
            <StandardLinkButton onClick={onClickChangeType}>
              Change
            </StandardLinkButton>
          )}
        </FieldInput>
      </FieldRow>

      <FieldRow>
        <FieldLabel>
          <label>Autopilot strategy</label>
        </FieldLabel>
        <FieldInput tw="flex items-center leading-normal">
          {autopilotFriendlyStrategy(strategy)}
        </FieldInput>
      </FieldRow>

      {strategy !== offer_autopilot_strategy_enum.copy && (
        <FieldRow>
          <FieldLabel>
            <label>
              Success metric{" "}
              <HelpIcon content="The success metric determines whether autopilot will optimize for higher acceptance rates or higher revenue potential." />
            </label>
          </FieldLabel>
          <FieldInput tw="flex items-center leading-normal">
            {autopilotFriendlySuccessMetric(successMetric)}
          </FieldInput>
        </FieldRow>
      )}
    </fieldset>
  </>
);

export default AutopilotBaseFormPartial;
