import { Temporal } from "@js-temporal/polyfill";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { css } from "twin.macro";

import { FlowOfferFragment, PauseReason } from "../../../__generated__/graphql";
import Editor from "../../../common/editor2/Editor";
import { isContentEmpty } from "../../../common/editor2/lib";
import { useTranslations } from "../../../common/translations/TranslationsProvider";
import getPauseSubscriptionDescription from "./lib/getPauseSubscriptionDescription";
import renderHighlight from "./lib/renderHighlight";
import renderPlaceholder from "./lib/renderPlaceholder";
import PauseDatesForm from "./PauseDatesForm";
import PauseReasonForm from "./PauseReasonForm";

interface PauseFormProps {
  offer: FlowOfferFragment | null;
  baseClassName: string;
  pauseReasons?: PauseReason[];
  onClickPauseOption: (index: number) => void;
  onClickPauseReason: (value: string) => void;
  pauseDate: Temporal.PlainDate;
  resumeDate: Temporal.PlainDate | undefined;
  onChangeStartDate: (value: Temporal.PlainDate) => void;
  onChangeEndDate: (value: Temporal.PlainDate | undefined) => void;
}

const PauseForm: React.FunctionComponent<PauseFormProps> = ({
  offer,
  baseClassName,
  pauseReasons,
  onClickPauseOption,
  onClickPauseReason,
  pauseDate,
  resumeDate,
  onChangeStartDate,
  onChangeEndDate,
}) => {
  const { language, translationValue } = useTranslations();

  const [selectedOptionIndex, setSelectedOptionIndex] = useState(0);

  const [pauseReasonCode, setPauseReasonCode] = useState(
    pauseReasons?.length === 1 ? pauseReasons[0].code : undefined
  );

  useEffect(() => {
    setSelectedOptionIndex(0);
  }, [
    offer?.offer_pause_subscription?.offer_pause_subscription_options.length,
  ]);

  const handleClickPauseOption = (value: number) => {
    setSelectedOptionIndex(value);
    onClickPauseOption(value);
  };

  const handleClickPauseReason = (value: string) => {
    setPauseReasonCode(value);
    onClickPauseReason(value);
  };

  const selectedPauseReason = pauseReasons?.find(
    (r) => r.code === pauseReasonCode
  );

  const selectedPauseOption = offer?.offer_pause_subscription
    ?.offer_pause_subscription_options.length
    ? offer.offer_pause_subscription.offer_pause_subscription_options[
        selectedOptionIndex
      ]
    : undefined;

  if (!offer?.offer_pause_subscription) {
    return null;
  }

  return (
    <div className={`${baseClassName}__pause`}>
      {!!pauseReasons && (
        <div className={`${baseClassName}__pause-reasons`}>
          <PauseReasonForm
            pauseReasons={pauseReasons}
            value={pauseReasonCode}
            hasMultipleOptions={
              offer?.offer_pause_subscription
                ? offer.offer_pause_subscription
                    .offer_pause_subscription_options.length > 1
                : false
            }
            onChange={handleClickPauseReason}
          />
        </div>
      )}

      {(!pauseReasons || !!selectedPauseReason) &&
        offer?.offer_pause_subscription &&
        offer.offer_pause_subscription.offer_pause_subscription_options.length >
          1 && (
          <div className={`${baseClassName}__pause-options-container`}>
            {!!selectedPauseReason && <label>Pause duration</label>}
            <div className={`${baseClassName}__pause-options`}>
              {offer.offer_pause_subscription.offer_pause_subscription_options.map(
                (o, i) => {
                  const textTranslation = translationValue(
                    o.text_translation
                  ).value;

                  return (
                    <button
                      type="button"
                      key={`pause-option-${i}`}
                      className={classNames({
                        [`${baseClassName}__pause-options__item`]: true,
                        active: i === selectedOptionIndex,
                      })}
                      onClick={() => handleClickPauseOption(i)}
                    >
                      {textTranslation && !isContentEmpty(textTranslation) ? (
                        <div
                          css={css`
                            && p {
                              text-align: center;
                              cursor: pointer;
                            }
                          `}
                        >
                          <Editor
                            initialValue={JSON.stringify(textTranslation)}
                            initialValueKey={JSON.stringify(textTranslation)}
                            isReadOnly={true}
                            isInline={true}
                          />
                        </div>
                      ) : o.type === "date" ? (
                        <>Choose date</>
                      ) : (
                        <>
                          Pause{" "}
                          {getPauseSubscriptionDescription(
                            [
                              {
                                type: o.type,
                                interval: o.interval,
                                intervalCount: o.interval_count,
                              },
                            ],
                            false,
                            language,
                            renderPlaceholder,
                            renderHighlight
                          )}
                        </>
                      )}
                    </button>
                  );
                }
              )}
            </div>
          </div>
        )}

      {!!selectedPauseReason && selectedPauseOption?.type === "date" && (
        <PauseDatesForm
          startDate={pauseDate}
          endDate={resumeDate}
          onChangeStartDate={onChangeStartDate}
          onChangeEndDate={onChangeEndDate}
          minimumStartDate={
            selectedPauseReason.minimumStartDate
              ? Temporal.PlainDate.from(selectedPauseReason.minimumStartDate)
              : Temporal.Now.plainDateISO()
          }
          maximumStartDate={
            selectedPauseReason.maximumStartDate
              ? Temporal.PlainDate.from(selectedPauseReason.maximumStartDate)
              : pauseDate.add({ days: 180 })
          }
          maximumEndDate={
            selectedPauseReason.maximumDays
              ? pauseDate.add({
                  days: selectedPauseReason.maximumDays,
                })
              : pauseDate.add({ days: 180 })
          }
        />
      )}
    </div>
  );
};

export default PauseForm;
