import gql from "graphql-tag";

export default gql`
  fragment SegmentConditionFragment on segment_condition {
    id
    property
    operator
    value
    property_id
    custom_property {
      id
      name
      entity
      type
      format
    }
  }
`;
