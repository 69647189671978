import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import Fuse from "fuse.js";
import { useMemo, useRef, useState } from "react";
import tw, { styled } from "twin.macro";

import sharedInputStyles from "./sharedInputStyles";

interface TagsInputProps {
  tags: string[];
  value: string[];
  onChange: (value: string[]) => void;
}

const MenuItem = styled.li<{ selected: boolean; disabled?: boolean }>`
  ${tw`flex hover:bg-gray-100 px-2 py-1 mb-1 last:mb-0 cursor-pointer`}
  ${(props) => props.selected && tw`bg-gray-100`}
  ${(props) => props.disabled && tw`pointer-events-none opacity-50`}
`;

const TagsInput: React.FunctionComponent<TagsInputProps> = ({
  tags,
  value,
  onChange,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [searchResults, setSearchResults] = useState<string[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const inputRef = useRef<HTMLSpanElement>(null);

  const fuse = useMemo(() => new Fuse(tags, { threshold: 0.4 }), [tags]);

  const handleInputChange = (newValue: string) => {
    setInputValue(newValue);

    if (newValue.length) {
      const result = fuse.search(newValue, { limit: 10 });
      setSearchResults(
        result.map((r) => r.item).filter((t) => !value.includes(t))
      );

      setIsOpen(true);
      return;
    }

    if (!isOpen) {
      setSelectedIndex(0);
    }

    setIsOpen(false);
  };

  const handleAddTag = (name: string) => {
    onChange([...value, name.trim()]);
    setIsOpen(false);
    if (inputRef.current) {
      inputRef.current.textContent = "";
      inputRef.current.focus();
    }

    window.setTimeout(() => {
      setInputValue("");
    }, 300);
  };

  const handleRemoveTag = (name: string) => {
    onChange(value.filter((t) => t !== name));
  };

  const canAddNewTag =
    !value.map((t) => t.toLowerCase()).includes(inputValue.toLowerCase()) &&
    !tags.map((t) => t.toLowerCase()).includes(inputValue.toLowerCase());

  return (
    <Tippy
      theme="light"
      content={
        <ul tw="text-base py-2 mt-[-10px] w-[336px]">
          {searchResults.map((r, i) => (
            <MenuItem
              key={r}
              selected={i === selectedIndex}
              onClick={() => handleAddTag(r)}
            >
              {r}
            </MenuItem>
          ))}
          <MenuItem
            selected={selectedIndex === searchResults.length}
            disabled={!canAddNewTag}
            onClick={() => handleAddTag(inputValue)}
            className="tag-input-menu-item"
          >
            <span css={!canAddNewTag ? tw`text-gray-400` : tw`text-link`}>
              + Add <span tw="font-semibold">{inputValue}</span>
            </span>
          </MenuItem>
        </ul>
      }
      visible={isOpen}
      placement="bottom-start"
      arrow={false}
      interactive={true}
      className="dropdown-menu"
      offset={[0, 3]}
    >
      <div
        css={[
          sharedInputStyles,
          isFocused ? tw`ring ring-blue-200 border-blue-500` : undefined,
        ]}
        tw="flex flex-wrap pb-0 cursor-text"
        onClick={() => {
          if (inputRef.current) {
            inputRef.current.focus();
          }
        }}
      >
        {value.map((tag) => (
          <span
            key={tag}
            tw="border border-gray-200 rounded-xl px-2 mr-1 mb-1 last:mr-0 last:mb-0"
          >
            {tag}{" "}
            <button
              type="button"
              tw="hover:text-gray-900"
              onClick={() => handleRemoveTag(tag)}
            >
              <FontAwesomeIcon icon={faTimes} transform="shrink-3" tw="ml-1" />
            </button>
          </span>
        ))}
        <span
          ref={inputRef}
          role="textbox"
          contentEditable
          suppressContentEditableWarning
          onInput={(e) => handleInputChange(e.currentTarget.textContent || "")}
          onFocus={() => setIsFocused(true)}
          onBlur={(e) => {
            const shouldBlur =
              !(e.relatedTarget instanceof HTMLElement) ||
              !e.relatedTarget.classList.contains("dropdown-menu");

            if (!shouldBlur) {
              return;
            }

            setIsFocused(false);
            if (inputRef.current) {
              inputRef.current.textContent = "";
            }
            handleInputChange("");
          }}
          tw="focus:outline-none px-1 mr-1 mb-1 border border-transparent"
          onKeyDown={(e) => {
            if (e.key === "ArrowDown") {
              e.preventDefault();
              let newSelectedIndex = selectedIndex + 1;
              if (newSelectedIndex > searchResults.length) {
                newSelectedIndex = searchResults.length;
              }
              setSelectedIndex(newSelectedIndex);
            }

            if (e.key === "ArrowUp") {
              e.preventDefault();
              let newSelectedIndex = selectedIndex - 1;
              if (newSelectedIndex < 0) {
                newSelectedIndex = 0;
              }
              setSelectedIndex(newSelectedIndex);
            }

            if (e.key === "Enter") {
              e.preventDefault();

              if (
                value
                  .map((t) => t.toLowerCase())
                  .includes(inputValue.toLowerCase())
              ) {
                return;
              }

              const selectedTag = searchResults[selectedIndex];
              if (selectedTag) {
                handleAddTag(selectedTag);
              } else {
                if (canAddNewTag) {
                  handleAddTag(inputValue);
                }
              }
            }
          }}
        >
          {!isFocused && !inputValue.length && !value.length && (
            <span tw="text-type-light pointer-events-none">
              Find or add tags
            </span>
          )}
        </span>
      </div>
    </Tippy>
  );
};

export default TagsInput;
