import { createContext, useContext } from "react";

export interface EditorSettings {
  allowVideoAutoPlay: boolean;
}

interface EditorSettingsProviderProps {
  editorSettings: EditorSettings;
}

interface EditorSettingsContext {
  editorSettings: EditorSettings;
}

const Context = createContext<EditorSettingsContext | undefined>(undefined);

export const useEditorSettings = () => {
  const context = useContext(Context);
  if (!context) {
    throw new Error(
      "useEditorSettings() not called within EditorSettingsProvider context"
    );
  }

  return context;
};

const EditorSettingsProvider: React.FunctionComponent<
  EditorSettingsProviderProps
> = ({ editorSettings, children }) => (
  <Context.Provider value={{ editorSettings }}>{children}</Context.Provider>
);

export { EditorSettingsProvider };
