import { gql, useMutation, useQuery } from "@apollo/client";
import { faArrowLeftLong } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Temporal } from "@js-temporal/polyfill";
import flow from "@prosperstack/flow";
import { useState } from "react";
import { Link } from "react-router-dom";

import {
  CustomerPortalSubscriptionDetailsQuery,
  CustomerPortalSubscriptionDetailsQueryVariables,
  CustomerPortalSubscriptionSignFlowPayloadMutation,
  CustomerPortalSubscriptionSignFlowPayloadMutationVariables,
  platform_enum,
} from "../../../__generated__/graphql";
import env from "../../../common/env";
import Button from "../../../common/form/Button";
import CustomerPortalSubscriptionDetailsFragment from "../../../common/fragments/CustomerPortalSubscriptionDetailsFragment";
import CustomerPortalSubscriptionDetailsInvoiceFragment from "../../../common/fragments/CustomerPortalSubscriptionDetailsInvoiceFragment";
import {
  PlatformFeature,
  platformHasFeature,
} from "../../../common/platform/features";
import Spinner from "../../../common/Spinner";
import Card from "./Card";
import CardContent from "./CardContent";
import CardHeading from "./CardHeading";
import InvoiceHistory from "./InvoiceHistory";
import SubscriptionAmount from "./SubscriptionAmount";

interface SubscriptionProps {
  clientId: string;
  sessionToken: string;
  platformSubscriptionId: string;
  platform: platform_enum;
}

const Subscription: React.FunctionComponent<SubscriptionProps> = ({
  clientId,
  sessionToken,
  platformSubscriptionId,
  platform,
}) => {
  const [isFlowOpen, setIsFlowOpen] = useState(false);

  const { data, refetch } = useQuery<
    CustomerPortalSubscriptionDetailsQuery,
    CustomerPortalSubscriptionDetailsQueryVariables
  >(
    gql`
      query CustomerPortalSubscriptionDetailsQuery(
        $input: PortalSubscriptionDetailsInput!
      ) {
        portalSubscriptionDetails(input: $input) {
          ...CustomerPortalSubscriptionDetailsFragment
          invoices {
            ...CustomerPortalSubscriptionDetailsInvoiceFragment
          }
        }
      }
      ${CustomerPortalSubscriptionDetailsFragment}
      ${CustomerPortalSubscriptionDetailsInvoiceFragment}
    `,
    {
      variables: {
        input: {
          portalSessionToken: sessionToken,
          platformSubscriptionId,
        },
      },
    }
  );

  const [signFlowPayload] = useMutation<
    CustomerPortalSubscriptionSignFlowPayloadMutation,
    CustomerPortalSubscriptionSignFlowPayloadMutationVariables
  >(gql`
    mutation CustomerPortalSubscriptionSignFlowPayloadMutation(
      $input: SignCustomerPortalFlowPayloadInput!
    ) {
      signCustomerPortalFlowPayload(input: $input) {
        signature
      }
    }
  `);

  const handleClickCancel = async () => {
    setIsFlowOpen(true);

    (window as any).__PROSPERSTACK_DEBUG_APP_HOST__ = env("REACT_APP_APP_URL");
    (window as any).__PROSPERSTACK_DEBUG_API_HOST__ = env(
      "REACT_APP_SERVER_URL"
    );

    await flow(
      {
        clientId,
        subscription: {
          platformId: platformSubscriptionId,
        },
      },
      {
        sign: async (payload) => {
          const signResult = await signFlowPayload({
            variables: {
              input: {
                portalSessionToken: sessionToken,
                payload,
              },
            },
          });

          if (!signResult.data?.signCustomerPortalFlowPayload.signature) {
            // TODO
            throw new Error();
          }

          return signResult.data.signCustomerPortalFlowPayload.signature;
        },
      }
    );

    refetch();

    setIsFlowOpen(false);
  };

  const details = data?.portalSubscriptionDetails;

  const nextBillingAt = details?.nextBillingAt
    ? Temporal.Instant.from(details.nextBillingAt).toLocaleString(undefined, {
        dateStyle: "medium",
      })
    : undefined;

  return (
    <>
      <div tw="mb-4">
        <Link to={`/p/portal/${clientId}/session/${sessionToken}`}>
          <FontAwesomeIcon icon={faArrowLeftLong} />{" "}
          <span tw="ml-1 font-semibold">Home</span>
        </Link>
      </div>
      <Card tw="w-full md:w-[39rem]">
        {!!details ? (
          <>
            <CardHeading>Subscription details</CardHeading>
            <CardContent>
              <div tw="flex">
                <div>
                  <h4 tw="font-title font-semibold mb-1">{details.name}</h4>
                  {details.status === "canceled" ? (
                    <div tw="text-type-light">Canceled</div>
                  ) : (
                    <>
                      <div tw="mb-1">
                        <SubscriptionAmount subscriptionDetails={details} big />
                      </div>
                      {nextBillingAt && (
                        <div tw="text-type-light text-sm">
                          {details.status === "trialing"
                            ? "Your free trial ends on"
                            : "Your plan renews on"}{" "}
                          {nextBillingAt}
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div tw="flex ml-auto items-end">
                  {details.status !== "canceled" && (
                    <Button
                      buttonType="danger-border"
                      onClick={handleClickCancel}
                      isLoading={isFlowOpen}
                      disabled={isFlowOpen}
                    >
                      Cancel plan
                    </Button>
                  )}
                </div>
              </div>
            </CardContent>
            {platformHasFeature(
              platform,
              PlatformFeature.CustomerPortalInvoices
            ) && (
              <InvoiceHistory
                sessionToken={sessionToken}
                invoices={details.invoices}
                platform={platform}
              />
            )}
          </>
        ) : (
          <Spinner />
        )}
      </Card>
    </>
  );
};

export default Subscription;
