import { LinkNode } from "@lexical/link";
import { ListItemNode, ListNode } from "@lexical/list";
import { HeadingNode } from "@lexical/rich-text";
import { ParagraphNode } from "lexical";

import { ImageNode } from "../nodes/ImageNode";
import { OfferDescriptionNode } from "../nodes/OfferDescriptionNode";
import { PropertyNode } from "../nodes/PropertyNode";
import { VideoNode } from "../nodes/VideoNode";
import { editorTheme } from "../ui/theme";

export const editorConfig = {
  namespace: "ProsperStack",
  nodes: [
    ParagraphNode,
    HeadingNode,
    ListNode,
    ListItemNode,
    LinkNode,
    PropertyNode,
    ImageNode,
    VideoNode,
    OfferDescriptionNode,
  ],
  theme: editorTheme,
  onError: console.error,
};
