import { ELEMENT_IMAGE } from "@udecode/slate-plugins";
import { Editor } from "slate";

export default function withImage<T extends Editor>(editor: T) {
  const { isVoid } = editor;

  editor.isVoid = (element) =>
    element.type === ELEMENT_IMAGE ? true : isVoid(element);

  return editor;
}
