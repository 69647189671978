import { Temporal } from "@js-temporal/polyfill";

import { CustomerPortalSubscriptionDetailsFragment } from "../../../__generated__/graphql";
import SubscriptionAmount from "./SubscriptionAmount";

interface SubscriptionSimpleDescriptionProps {
  subscriptionDetails: CustomerPortalSubscriptionDetailsFragment;
}

const SubscriptionSimpleDescription: React.FunctionComponent<
  SubscriptionSimpleDescriptionProps
> = ({ subscriptionDetails }) => {
  if (subscriptionDetails.status === "canceled") {
    return <>Canceled</>;
  }

  if (subscriptionDetails.status === "paused") {
    return <>Paused</>;
  }

  if (
    subscriptionDetails.status === "trialing" &&
    subscriptionDetails.trialEndsAt
  ) {
    const trialEndsAt = Temporal.Instant.from(subscriptionDetails.trialEndsAt);
    return (
      <>
        Free trial ends{" "}
        {trialEndsAt.toLocaleString(undefined, { dateStyle: "medium" })}
      </>
    );
  }

  return <SubscriptionAmount subscriptionDetails={subscriptionDetails} />;
};

export default SubscriptionSimpleDescription;
