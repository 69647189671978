import env from "../env";
import { FlowVersion, FlowVersionedObject } from "./types";

export default function getFlowObjectVersion<T>(
  object: FlowVersionedObject<T>,
  version: FlowVersion
): T {
  let v: T | null | undefined = null;

  if (version === "draft") {
    v = object.draft_version || object.published_version;
  }

  if (version === "published") {
    v = object.published_version;
  }

  if (!v) {
    if (env("NODE_ENV") === "development") {
      console.error(object);
    }

    throw new Error("Object has no published version");
  }

  return v;
}
