import { SlatePlugin } from "@udecode/slate-plugins";

import PropertyTagElement from "./PropertyTagElement";
import { PROPERTY_TAG } from "./types";

export default function PropertyTagPlugin(): SlatePlugin {
  return {
    renderElement: (props) => {
      if (props.element.type === PROPERTY_TAG) {
        return <PropertyTagElement {...props} />;
      }
    },
  };
}
