import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { LexicalEditor } from "lexical";
import { useEffect } from "react";

const isLinkDomNode = (domNode: Element) =>
  domNode.nodeName.toLowerCase() === "a";

const getLinkDomNode = (event: MouseEvent, editor: LexicalEditor) =>
  editor.getEditorState().read(() => {
    const domNode = event.target;

    if (!(domNode instanceof Element)) {
      return null;
    }

    if (isLinkDomNode(domNode)) {
      return domNode;
    }

    if (
      domNode.parentNode instanceof Element &&
      isLinkDomNode(domNode.parentNode)
    ) {
      return domNode.parentNode;
    }

    return null;
  });

const TargetBlankLinkPlugin: React.FunctionComponent = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    const onClick = (e: MouseEvent) => {
      if (editor.isEditable()) {
        return;
      }

      const linkDomNode = getLinkDomNode(e, editor);
      if (linkDomNode === null) {
        return;
      }

      const href = linkDomNode.getAttribute("href");
      if (href) {
        e.preventDefault();
        window.open(href, "_blank");
      }
    };

    editor.registerRootListener((rootElement, prevRootElement) => {
      if (prevRootElement !== null) {
        prevRootElement.removeEventListener("click", onClick);
      }
      if (rootElement !== null) {
        rootElement.addEventListener("click", onClick);
      }
    });
  }, [editor]);

  return null;
};

export default TargetBlankLinkPlugin;
