import { ForwardedRef, forwardRef } from "react";
import tw, { styled } from "twin.macro";

import UpsellBanner, {
  BannerMode,
} from "../../features/upgrade-account/UpsellBanner";
import { useUpsell } from "../../features/upgrade-account/UpsellProvider";

const StyledPanelBody = styled.div`
  ${tw`p-4 pt-0 overflow-y-auto`}

  > p:not(:last-child) {
    ${tw`mb-4`}
  }
`;

interface PanelBodyProps {
  children?: React.ReactNode;
  forwardedRef?: ForwardedRef<HTMLDivElement>;
}

const PanelBody: React.FunctionComponent<PanelBodyProps> = ({
  children,
  forwardedRef,
  ...props
}) => {
  const {
    bannerIsVisible: isVisible,
    bannerFeatureText: featureText,
    setUpgradeModalIsOpen,
  } = useUpsell();

  return (
    <StyledPanelBody {...props} ref={forwardedRef}>
      <div tw="my-3">
        <UpsellBanner
          isVisible={isVisible}
          featureText={featureText}
          onClick={() => setUpgradeModalIsOpen(true)}
          mode={"panel" as BannerMode}
        />
      </div>
      {children}
    </StyledPanelBody>
  );
};

export default forwardRef<HTMLDivElement, PanelBodyProps>((props, ref) => (
  <PanelBody {...props} forwardedRef={ref} />
));
