import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";

import { language_enum, platform_enum } from "../../__generated__/graphql";
import EditorSelector from "../../common/editor2/EditorSelector";
import FieldCheckboxInput from "../../common/form/FieldCheckboxInput";
import FieldError from "../../common/form/FieldError";
import FieldHint from "../../common/form/FieldHint";
import FieldInput from "../../common/form/FieldInput";
import FieldLabel from "../../common/form/FieldLabel";
import FieldRow from "../../common/form/FieldRow";
import FieldSetTitle from "../../common/form/FieldSetTitle";
import ImageInput from "../../common/form/input/ImageInput";
import SelectInput from "../../common/form/input/SelectInput";
import TagsInput from "../../common/form/input/TagsInput";
import TextInput from "../../common/form/input/TextInput";
import useTranslatableForm, {
  TranslatedForms,
} from "../../common/form/useTranslatableForm";
import HelpIcon from "../../common/HelpIcon";
import MetadataInput from "../../common/MetadataInput";
import { useTranslations } from "../../common/translations/TranslationsProvider";
import useAccountFeatures from "../../common/useAccountFeatures";
import useFocusFirstEmptyInput from "../../common/useFocusFirstEmptyInput";
import LanguageRadio from "../flow/edit/LanguageRadio";
import { generatePaidFeatureClassName } from "../upgrade-account/usePaidFeature";
import getDefaultFormValues from "./forms/lib/getDefaultFormValues";
import getTranslatableFields from "./forms/lib/getTranslatableFields";
import { RescheduleOrderOfferFormValues } from "./forms/types";
import useValidateHeadline from "./lib/useValidateHeadline";
import useValidateName from "./lib/useValidateName";
import validateMetadata from "./lib/validateMetadata";

interface RescheduleOrderOfferFormProps {
  platform: platform_enum;
  isSubmitting: boolean;
  tags: string[];
  initialValues?: TranslatedForms<RescheduleOrderOfferFormValues>;
  isFreeMode: boolean;
  onChange: (forms: TranslatedForms<RescheduleOrderOfferFormValues>) => void;
  onSubmit: (forms: TranslatedForms<RescheduleOrderOfferFormValues>) => void;
  onChangeEditingLanguage: (language: language_enum) => void;
}

const RescheduleOrderOfferForm: React.FunctionComponent<
  RescheduleOrderOfferFormProps
> = ({
  platform,
  isSubmitting,
  tags,
  initialValues,
  isFreeMode,
  onChange,
  onSubmit,
  onChangeEditingLanguage,
}) => {
  const { defaultLanguage, enabledLanguages } = useTranslations();
  const defaultValues =
    initialValues ||
    getDefaultFormValues<RescheduleOrderOfferFormValues>({
      type: "reschedule_order",
      enabledLanguages,
      platform,
    });

  const {
    register,
    formState,
    handleSubmit,
    control,
    editingLanguage,
    setEditingLanguage,
    forms,
    trigger,
    watch,
  } = useTranslatableForm<RescheduleOrderOfferFormValues>({
    defaultValues,
    initialLanguage: defaultLanguage,
    languages: enabledLanguages,
    defaultLanguage,
    translatableFields: getTranslatableFields("reschedule_order"),
  });
  const values = watch();
  const [formRef, setFormRef] = useState<HTMLFormElement | null>(null);
  useFocusFirstEmptyInput(formRef);
  const validateName = useValidateName(formState.dirtyFields.name || false);
  const image = watch("image");
  const validateHeadline = useValidateHeadline(image);
  const { features } = useAccountFeatures();

  useEffect(() => {
    onChange(forms);
  }, [forms, onChange]);

  return (
    <form
      id="create-offer"
      onChange={() => onChange(forms)}
      ref={(ref) => setFormRef(ref)}
      onSubmit={handleSubmit(onSubmit)}
    >
      <fieldset disabled={isSubmitting}>
        <input {...register("type")} type="hidden" value="custom" />
        <FieldRow>
          <FieldLabel>
            <label htmlFor="name">Name</label>
          </FieldLabel>
          <FieldInput>
            <TextInput
              {...register("name", {
                required: true,
                validate: validateName,
              })}
              id="name"
              width="full"
              fieldError={formState.errors.name}
            />
            <FieldError error={formState.errors.name} />
          </FieldInput>
        </FieldRow>
        <FieldRow>
          <FieldLabel>Tags</FieldLabel>
          <FieldInput>
            <Controller
              control={control}
              name="tags"
              render={({ field }) => (
                <TagsInput
                  tags={tags}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </FieldInput>
        </FieldRow>
        <FieldRow
          className={generatePaidFeatureClassName(
            "Upgrade your plan to modify offer style.",
            isFreeMode,
            "form"
          )}
        >
          <FieldLabel>
            <label htmlFor="style">Style</label>
          </FieldLabel>
          <FieldInput>
            <SelectInput {...register("style")} id="style" defaultValue="modal">
              <option value="modal">Modal</option>
              <option value="step">Step</option>
            </SelectInput>
            <FieldHint>
              {values.style === "modal" ? (
                <>Offer will be shown in a pop-up modal dialog.</>
              ) : (
                <>Offer will be shown as a full flow step.</>
              )}
            </FieldHint>
          </FieldInput>
        </FieldRow>
        <FieldRow>
          <FieldLabel>
            <label>
              Allow override{" "}
              <HelpIcon content="By default, an offer will not be displayed to a subscriber if they have previously accepted another offer. This setting will allow this offer to override any previously accepted offers." />
            </label>
          </FieldLabel>
          <FieldCheckboxInput>
            <input
              {...register("allowOverride")}
              id="allowOverride"
              type="checkbox"
            />
            <label htmlFor="allowOverride">Override other offers</label>
          </FieldCheckboxInput>
        </FieldRow>
        <FieldSetTitle>Reschedule details</FieldSetTitle>
        <FieldRow tw="hidden">
          <FieldLabel>
            <label>Skip</label>
          </FieldLabel>
          <FieldCheckboxInput>
            <input
              {...register("skipEnabled")}
              id="skipEnabled"
              type="checkbox"
            />
            <label htmlFor="rescheduleEnabled">Allow skip next order</label>
          </FieldCheckboxInput>
        </FieldRow>
        <FieldRow>
          <FieldLabel>
            <label>Reschedule</label>
          </FieldLabel>
          <FieldCheckboxInput>
            <input
              {...register("rescheduleEnabled")}
              id="rescheduleEnabled"
              type="checkbox"
            />
            <label htmlFor="rescheduleEnabled">Allow order rescheduling</label>
          </FieldCheckboxInput>
        </FieldRow>
        <FieldSetTitle>Offer {values.style}</FieldSetTitle>
        {values.style === "modal" && (
          <FieldRow>
            <FieldLabel>
              <label>Banner</label>
            </FieldLabel>
            <FieldCheckboxInput>
              <input
                {...register("showBanner")}
                id="showBanner"
                type="checkbox"
              />
              <label htmlFor="showBanner">Show "Special Offer" banner</label>
            </FieldCheckboxInput>
          </FieldRow>
        )}
        {enabledLanguages.length > 1 && (
          <LanguageRadio
            value={editingLanguage}
            languages={enabledLanguages}
            defaultLanguage={defaultLanguage}
            onChange={(language) => {
              setEditingLanguage(language);
              onChangeEditingLanguage(language);
            }}
          />
        )}
        <FieldRow>
          <FieldLabel>
            <label>Headline</label>
          </FieldLabel>
          <FieldInput>
            <TextInput
              {...register("headline", { validate: validateHeadline })}
              id="headline"
              width="full"
              fieldError={formState.errors.headline}
            />
            <FieldError error={formState.errors.headline} />
          </FieldInput>
        </FieldRow>
        <FieldRow>
          <FieldLabel>
            <label>Image</label>
          </FieldLabel>
          <FieldInput>
            <Controller
              control={control}
              name="image"
              render={({ field }) => (
                <ImageInput
                  value={field.value}
                  onChange={(logoUrl) => {
                    field.onChange(logoUrl);
                    if (formState.isSubmitted) {
                      trigger();
                    }
                  }}
                />
              )}
            />
          </FieldInput>
        </FieldRow>
        <FieldRow style={{ display: "block" }}>
          <FieldLabel>
            <label>Content</label>
          </FieldLabel>
          <Controller
            control={control}
            name="content"
            render={({ field }) => {
              const form = forms[editingLanguage];
              if (!form) {
                return <></>;
              }

              return (
                <EditorSelector
                  initialValueKey={editingLanguage}
                  isOfferContent
                  hasCustomProperties={!!features.custom_properties}
                  format={form.contentFormat}
                  value={form.content}
                  onChange={(value) => {
                    field.onChange(value);
                    onChange({
                      ...forms,
                      [editingLanguage]: {
                        ...forms[editingLanguage],
                        content: value,
                      },
                    });
                  }}
                />
              );
            }}
          />
        </FieldRow>
        <FieldRow>
          <FieldLabel>
            <label>Skip button text</label>
          </FieldLabel>
          <FieldInput>
            <TextInput
              {...register("skipButtonText", { required: true })}
              id="buttonText"
              width="md"
              fieldError={formState.errors.skipButtonText}
            />
          </FieldInput>
        </FieldRow>
        <FieldRow>
          <FieldLabel>
            <label>Reschedule button text</label>
          </FieldLabel>
          <FieldInput>
            <TextInput
              {...register("rescheduleButtonText", { required: true })}
              id="buttonText"
              width="md"
              fieldError={formState.errors.rescheduleButtonText}
            />
          </FieldInput>
        </FieldRow>
        <FieldSetTitle>Metadata</FieldSetTitle>
        <FieldRow>
          <FieldInput>
            <Controller
              control={control}
              name="metadata"
              render={({ field }) => (
                <MetadataInput value={field.value} onChange={field.onChange} />
              )}
              rules={{
                validate: validateMetadata,
              }}
            />
            <FieldError error={formState.errors.metadata} />
          </FieldInput>
        </FieldRow>
      </fieldset>
    </form>
  );
};

export default RescheduleOrderOfferForm;
