import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";

import {
  language_enum,
  question_type_enum,
  TranslationFragment,
} from "../../../__generated__/graphql";
import Button from "../../../common/form/Button";
import FieldInput from "../../../common/form/FieldInput";
import FieldLabel from "../../../common/form/FieldLabel";
import FieldRow from "../../../common/form/FieldRow";
import FormMode from "../../../common/form/FormMode";
import TextInput from "../../../common/form/input/TextInput";
import useTranslatableForm, {
  TranslatedForms,
} from "../../../common/form/useTranslatableForm";
import Modal, { ModalProps } from "../../../common/modal/Modal";
import ModalFooter from "../../../common/modal/ModalFooter";
import ModalFormBody from "../../../common/modal/ModalFormBody";
import ModalHeader from "../../../common/modal/ModalHeader";
import PillRadio from "../../../common/PillRadio";
import useFocusFirstEmptyInput from "../../../common/useFocusFirstEmptyInput";
import LanguageRadio from "./LanguageRadio";

type AddQuestionModalProps = ModalProps & {
  mode: FormMode;
  language: language_enum;
  enabledLanguages: language_enum[];
  defaultLanguage: language_enum;
  type?: question_type_enum;
  onClose: () => void;
  onSave: (
    forms: TranslatedForms<FormValues>,
    type: question_type_enum
  ) => Promise<void>;
  titleTranslation?: TranslationFragment;
  hintTranslation?: TranslationFragment;
  placeholderTranslation?: TranslationFragment | null;
  defaultType?: string;
};

export interface FormValues {
  title: string;
  hint: string;
  placeholder?: string;
  type: question_type_enum;
}

const AddQuestionModal: React.FunctionComponent<AddQuestionModalProps> = ({
  mode,
  language,
  enabledLanguages,
  defaultLanguage,
  type,
  onClose,
  onSave,
  titleTranslation,
  hintTranslation,
  placeholderTranslation,
  ...props
}) => {
  const [formRef, setFormRef] = useState<HTMLFormElement | null>(null);
  useFocusFirstEmptyInput(formRef);

  const [submitting, setSubmitting] = useState(false);

  const defaultValues = enabledLanguages.reduce((prev, enabledLanguage) => {
    const title = titleTranslation?.translation_values.find(
      (value) => value.language === enabledLanguage
    );
    const hint = hintTranslation?.translation_values.find(
      (value) => value.language === enabledLanguage
    );
    const placeholder = placeholderTranslation?.translation_values.find(
      (value) => value.language === enabledLanguage
    );

    return {
      ...prev,
      [enabledLanguage]: {
        title: title?.value || "",
        hint: hint?.value || "",
        placeholder: placeholder?.value || "",
        type: type || question_type_enum.radio,
      },
    };
  }, {});

  const {
    register,
    handleSubmit,
    editingLanguage,
    setEditingLanguage,
    control,
    watch,
  } = useTranslatableForm<FormValues>({
    languages: enabledLanguages,
    initialLanguage: language,
    defaultValues,
    defaultLanguage,
    translatableFields: ["title", "hint", "placeholder"],
  });

  const { type: selectedType } = watch();

  useEffect(() => {
    setEditingLanguage(language);
  }, [language, setEditingLanguage]);

  const onSubmit = handleSubmit(async (values) => {
    setSubmitting(true);
    await onSave(values, type || selectedType);
    onClose();
  });

  return (
    <Modal {...props}>
      <ModalHeader>
        {mode === "create" ? (
          <>
            {!type
              ? "Add question"
              : type === question_type_enum.radio
              ? "Add multiple choice question"
              : type === question_type_enum.likelihood_to_return
              ? "Add return likelihood question"
              : "Add text question"}
          </>
        ) : (
          <>Edit question</>
        )}
      </ModalHeader>
      <ModalFormBody>
        {enabledLanguages.length > 1 && (
          <LanguageRadio
            value={editingLanguage}
            languages={enabledLanguages}
            defaultLanguage={defaultLanguage}
            onChange={(newLanguage) => setEditingLanguage(newLanguage)}
          />
        )}
        <form
          id="add-question"
          onSubmit={onSubmit}
          ref={(ref) => setFormRef(ref)}
        >
          <fieldset disabled={submitting}>
            {!type && (
              <FieldRow>
                <FieldLabel>
                  <label>Type</label>
                </FieldLabel>
                <FieldInput tw="flex items-center">
                  <Controller
                    control={control}
                    name="type"
                    render={({ field }) => (
                      <PillRadio
                        value={field.value}
                        onChange={field.onChange}
                        options={[
                          {
                            label: "Multiple choice",
                            value: question_type_enum.radio,
                          },
                          {
                            label: "Text",
                            value: question_type_enum.text,
                          },
                          {
                            label: "Return likelihood",
                            value: question_type_enum.likelihood_to_return,
                          },
                        ]}
                      />
                    )}
                  />
                </FieldInput>
              </FieldRow>
            )}
            <FieldRow>
              <FieldLabel>
                <label htmlFor="title">Title</label>
              </FieldLabel>
              <FieldInput>
                <TextInput
                  {...register("title", { required: true })}
                  id="title"
                  width="lg"
                />
              </FieldInput>
            </FieldRow>
            <FieldRow>
              <FieldLabel>
                <label htmlFor="label">Hint</label>
              </FieldLabel>
              <FieldInput>
                <TextInput {...register("hint")} id="hint" width="lg" />
              </FieldInput>
            </FieldRow>
            {(type === question_type_enum.text ||
              selectedType === question_type_enum.text) && (
              <FieldRow>
                <FieldLabel>
                  <label htmlFor="placeholder">Placeholder</label>
                </FieldLabel>
                <FieldInput>
                  <TextInput
                    {...register("placeholder")}
                    id="placeholder"
                    width="lg"
                  />
                </FieldInput>
              </FieldRow>
            )}
          </fieldset>
        </form>
      </ModalFormBody>
      <ModalFooter>
        <Button buttonType="primary" form="add-question" isLoading={submitting}>
          {mode === "create" ? "Add question" : "Save"}
        </Button>
        <Button buttonType="default" onClick={onClose}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddQuestionModal;
