import "tippy.js/themes/light.css";

import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faCrown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Placement } from "@popperjs/core";
import Tippy from "@tippyjs/react";
import Fuse from "fuse.js";
import { DOMAttributes, useEffect, useMemo, useState } from "react";
import { createGlobalStyle } from "styled-components";
import { isPresent } from "ts-is-present";
import tw, { css, styled } from "twin.macro";

import SearchInput from "./search/SearchInput";
import StandardLinkButton from "./StandardLinkButton";

export interface DropdownRenderProps {
  onClick: () => void;
  isOpen: boolean;
}

export type DropdownRender = (props: DropdownRenderProps) => React.ReactElement;

interface DividerMenuItem {
  type: "divider";
}

interface HeadingMenuItem {
  type: "heading";
  label: string;
}

export type StandardMenuItem = DOMAttributes<HTMLElement> & {
  type?: "item";
  label: React.ReactNode;
  icon?: IconDefinition;
  isActive?: boolean;
  value?: string;
  searchValue?: string;
  tooltip?: string;
  isDisabled?: boolean;
  hidden?: boolean;
  color?: string;
  checkbox?: boolean;
  upgrade?: boolean;
};

export type MenuItem = DividerMenuItem | HeadingMenuItem | StandardMenuItem;

interface DropdownMenuProps {
  render: DropdownRender;
  header?: string;
  items: Array<MenuItem | undefined>;
  onToggle?: (isOpen: boolean) => void;
  stickyOpen?: boolean;
  arrow?: boolean;
  search?: boolean;
  minWidth?: string;
  selectAll?: boolean;
  onSelectAll?: (values: string[]) => void;
  onDeselectAll?: (values: string[]) => void;
  zIndex?: number;
  placement?: Placement;
  reference?: HTMLElement | null;
  offset?: [number, number];
}

const GlobalStyle = createGlobalStyle`
  .tippy-box {
    ${tw`rounded-md`}
  }

  .tippy-box.dropdown-menu .tippy-content {
    padding: 0;
  }
`;

const Menu = styled.ul<{ minWidth: string }>`
  ${tw`text-base`}
  min-width: ${(props) => props.minWidth};
`;

const Header = styled.li`
  ${tw`font-semibold px-2 pb-1 mb-1 last:mb-0 truncate`}

  .item + & {
    ${tw`py-1`}
  }
`;

const Icon = tw.div`text-gray-600 mr-1`;

const Item = styled.li<{
  isActive: boolean;
  isDisabled: boolean;
  checkbox: boolean;
}>`
  ${tw`flex hover:bg-gray-100 px-2 py-1 mb-1 last:mb-0 cursor-pointer relative`}
  ${(props) =>
    !props.isDisabled &&
    !props.checkbox &&
    css`
      &:active {
        ${tw`bg-blue-500`}
        div {
          ${tw`text-white`}
        }
      }
    `}

  ${(props) =>
    props.isActive &&
    !props.checkbox &&
    tw`bg-blue-500 text-white hover:bg-blue-500`}


  ${(props) =>
    props.isDisabled &&
    css`
      ${tw`cursor-default hover:bg-white`}
       
      > :not(.upgrade-crown) {
        ${tw`opacity-50`}
      }
    x`}

  ${(props) =>
    props.isActive &&
    props.isDisabled &&
    !props.checkbox &&
    tw`bg-blue-500/90 hover:bg-blue-500/90`}
`;

const Divider = tw.li`border-t border-divider mt-2 mb-2 first:mt-0`;

const DropdownMenu: React.FunctionComponent<DropdownMenuProps> = ({
  render,
  header,
  items,
  onToggle,
  stickyOpen = false,
  arrow = false,
  search = false,
  minWidth = "12rem",
  zIndex = 15,
  placement = "bottom-start",
  reference,
  offset = [0, 3],
  onSelectAll,
  onDeselectAll,
}) => {
  const [visible, setVisible] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState<StandardMenuItem[]>([]);

  const fuse = useMemo(
    () =>
      new Fuse(
        items.filter(isPresent).filter((i) => i.type === "item" || !i.type),
        {
          keys: ["value", "searchValue"],
          threshold: 0.4,
        }
      ),
    [items]
  );

  const onClick = () => {
    setVisible(!visible);
  };

  useEffect(() => {
    if (onToggle) {
      onToggle(visible);
    }
  }, [onToggle, visible]);

  const handleSearchChange = (value: string) => {
    setSearchValue(value);

    const results = fuse.search(value);
    setSearchResults(
      results
        .map((r) => r.item)
        .filter((i) => i.type === "item" || !i.type) as StandardMenuItem[]
    );
  };

  const filteredItems = items.filter((item) => {
    if (!item) {
      return false;
    }

    if (!item.type || item.type === "item") {
      if (!!searchValue && !searchResults.find((i) => i.value === item.value)) {
        return false;
      }
    }

    return true;
  });

  const visibleStandardItems = filteredItems
    .map((i) => (i && (!i.type || i.type === "item") ? i : undefined))
    .filter(isPresent);

  const handleSelectAll = () => {
    onSelectAll?.(visibleStandardItems.map((i) => i.value || ""));
  };

  const handleDeselectAll = () => {
    onDeselectAll?.(visibleStandardItems.map((i) => i.value || ""));
  };

  const canSelectAll = visibleStandardItems.find((i) => !i.isActive);
  const canDeselectAll = !!visibleStandardItems.find(
    (i) => i.isActive && !i.isDisabled
  );

  return (
    <>
      <GlobalStyle />
      <Tippy
        theme="light"
        className="dropdown-menu"
        zIndex={zIndex}
        offset={offset}
        content={
          <Menu minWidth={minWidth}>
            <div>
              {search && (
                <div tw="w-full border-b border-divider p-2">
                  <SearchInput
                    value={searchValue}
                    onChange={(e) => handleSearchChange(e.currentTarget.value)}
                    onClear={() => handleSearchChange("")}
                    tw="w-full"
                    width="100%"
                  />
                  {!!onSelectAll && !!onDeselectAll && (
                    <div tw="mt-2 flex gap-3">
                      <StandardLinkButton
                        onClick={handleSelectAll}
                        disabled={!canSelectAll}
                      >
                        Select all
                      </StandardLinkButton>
                      <StandardLinkButton
                        onClick={handleDeselectAll}
                        disabled={!canDeselectAll}
                      >
                        Deselect all
                      </StandardLinkButton>
                    </div>
                  )}
                </div>
              )}
            </div>

            <div tw="max-h-[45vh] overflow-y-auto py-2">
              {header ? <Header>{header}</Header> : null}

              {!filteredItems.length && (
                <li tw="px-2 py-1 mb-1 last:mb-0 text-type-light">
                  No results.
                </li>
              )}

              {filteredItems.map((item, index) => {
                if (!item) {
                  return null;
                }

                if (!item.type || item.type === "item") {
                  const {
                    type,
                    label,
                    icon,
                    onClick,
                    isActive = false,
                    isDisabled = false,
                    tooltip,
                    checkbox = false,
                    ...props
                  } = item;

                  return (
                    <Tippy
                      key={index}
                      content={tooltip}
                      disabled={!tooltip}
                      placement="right"
                    >
                      <Item
                        className="item"
                        onClick={(e) => {
                          if (isDisabled) {
                            e.preventDefault();
                            return;
                          }
                          !stickyOpen && setVisible(false);
                          if (onClick) {
                            onClick(e);
                          }
                        }}
                        isActive={isActive}
                        isDisabled={isDisabled}
                        checkbox={checkbox}
                        {...props}
                      >
                        {checkbox && (
                          <input
                            type="checkbox"
                            checked={item.isActive}
                            readOnly
                            tw="mr-2"
                          />
                        )}
                        {!!icon && (
                          <Icon
                            css={
                              item.color &&
                              css`
                                color: ${item.color};
                              `
                            }
                          >
                            <FontAwesomeIcon
                              icon={icon}
                              fixedWidth
                              transform="shrink-2"
                            />
                          </Icon>
                        )}
                        <div
                          css={[
                            tw`w-full`,
                            item.color &&
                              css`
                                color: ${item.color};
                              `,
                          ]}
                        >
                          {label}
                        </div>
                        {item.upgrade && (
                          <div
                            className="upgrade-crown"
                            tw="ml-auto float-right"
                          >
                            <FontAwesomeIcon icon={faCrown} color="#f2c94c" />
                          </div>
                        )}
                      </Item>
                    </Tippy>
                  );
                } else if (item.type === "heading") {
                  return <Header key={item.label}>{item.label}</Header>;
                } else {
                  return <Divider key={index} />;
                }
              })}
            </div>
          </Menu>
        }
        visible={visible}
        onClickOutside={() => setVisible(false)}
        interactive={true}
        reference={reference}
        appendTo={document.body}
        placement={placement}
        arrow={arrow}
      >
        {render({ onClick, isOpen: visible })}
      </Tippy>
    </>
  );
};

export default DropdownMenu;
