import { Temporal } from "@js-temporal/polyfill";
import { format } from "date-fns";

import { DateRange } from "./reports/types";

export const formatUTCDay = (date: Date) => {
  const now = new Date();
  date.setHours(now.getHours());
  date.setMinutes(now.getMinutes());
  date.setSeconds(now.getSeconds());

  const year = date.getUTCFullYear();
  const month = date.getUTCMonth() + 1;
  const day = date.getUTCDate();

  return `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}`;
};

export const formatUTCMonth = (date: Date) => {
  const now = new Date();
  date.setHours(now.getHours());
  date.setMinutes(now.getMinutes());
  date.setSeconds(now.getSeconds());

  const year = date.getUTCFullYear();
  const month = date.getUTCMonth() + 1;

  return `${year}-${month.toString().padStart(2, "0")}`;
};

export const formatUTC = (date: Date, fmt: string) => {
  const [year, month, day] = date.toISOString().substr(0, 10).split("-");
  return format(new Date(Number(year), Number(month) - 1, Number(day)), fmt);
};

export const parseUTC = (date: string) => {
  const [year, month, day] = date.substr(0, 10).split("-");
  return new Date(Number(year), Number(month) - 1, Number(day));
};

export const parsePlainDateOrPlainYearMonth = (
  date: string,
  startOrEnd: "start" | "end"
) => {
  try {
    return Temporal.PlainDate.from(date);
  } catch (e) {
    const result = Temporal.PlainYearMonth.from(date);
    return result.toPlainDate({
      day: startOrEnd === "start" ? 1 : result.daysInMonth,
    });
  }
};

export const plainDateToDate = (date: Temporal.PlainDate) => {
  const d = new Date(date.year, date.month - 1, date.day);
  d.setHours(0, 0, 0, 0);
  return d;
};

export const formatFriendly = (date: Temporal.Instant) => {
  if (
    date
      .toZonedDateTimeISO(Temporal.Now.timeZone())
      .toPlainDate()
      .equals(Temporal.Now.plainDateISO())
  ) {
    return date.toLocaleString(undefined, { timeStyle: "short" });
  }

  return date.toLocaleString(undefined, { dateStyle: "medium" });
};

export const eachDateOfInterval = (
  unit: "day" | "week" | "month",
  range: Required<DateRange>
) => {
  const result: Temporal.PlainDate[] = [];

  let nextDate = Temporal.PlainDate.from(range.start);
  while (Temporal.PlainDate.compare(nextDate, range.end) < 1) {
    result.push(Temporal.PlainDate.from(nextDate));

    switch (unit) {
      case "day":
        nextDate = nextDate.add({ days: 1 });
        break;

      case "week":
        nextDate = nextDate.add({ weeks: 1 });
        break;

      case "month":
        nextDate = nextDate.add({ months: 1 });
        break;
    }
  }

  return result;
};
