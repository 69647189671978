import { faCalendarDay } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Temporal } from "@js-temporal/polyfill";
import Tippy from "@tippyjs/react";
import { useState } from "react";

import Calendar from "../../../common/calendar/Calendar";

interface PauseDatesFormProps {
  startDate: Temporal.PlainDate;
  endDate: Temporal.PlainDate | undefined;
  minimumStartDate: Temporal.PlainDate;
  maximumStartDate: Temporal.PlainDate;
  maximumEndDate: Temporal.PlainDate;
  onChangeStartDate: (value: Temporal.PlainDate) => void;
  onChangeEndDate: (value: Temporal.PlainDate | undefined) => void;
}

const PauseDatesForm: React.FunctionComponent<PauseDatesFormProps> = ({
  startDate,
  endDate,
  onChangeStartDate,
  minimumStartDate,
  maximumStartDate,
  maximumEndDate,
  onChangeEndDate,
}) => {
  const [startIsOpen, setStartIsOpen] = useState(false);
  const [endIsOpen, setEndIsOpen] = useState(false);

  const handleChangeStartDate = (value: Temporal.PlainDate) => {
    onChangeStartDate(value);

    if (endDate && Temporal.PlainDate.compare(value, endDate) >= 0) {
      onChangeEndDate(undefined);
    }
  };

  return (
    <div className="flow-pause__dates">
      <div className="flow-pause__dates__date flow-pause__dates__date-start">
        <label>Start pause on</label>
        <Tippy
          theme="light"
          visible={startIsOpen}
          onClickOutside={() => setStartIsOpen(false)}
          interactive={true}
          placement="bottom-start"
          offset={[0, 3]}
          maxWidth="none"
          content={
            <Calendar
              value={startDate}
              onChange={(value) => {
                handleChangeStartDate(value);
                setStartIsOpen(false);
              }}
              minValue={minimumStartDate}
              maxValue={maximumStartDate}
              className="flow-pause__calendar"
            />
          }
          arrow={false}
        >
          <button
            className="flow-pause__calendar-toggle"
            onClick={() => setStartIsOpen(!startIsOpen)}
          >
            <div>
              {startDate.toLocaleString(undefined, {
                dateStyle: "full",
              })}
            </div>
            <div tw="ml-auto">
              <FontAwesomeIcon icon={faCalendarDay} />
            </div>
          </button>
        </Tippy>
      </div>

      <div className="flow-pause__dates__date flow-pause__dates__date-end">
        <label>Resume on</label>
        <Tippy
          theme="light"
          visible={endIsOpen}
          onClickOutside={() => setEndIsOpen(false)}
          interactive={true}
          placement="bottom-start"
          offset={[0, 3]}
          maxWidth="none"
          content={
            <Calendar
              value={endDate}
              onChange={(value) => {
                onChangeEndDate(value);
                setEndIsOpen(false);
              }}
              minValue={startDate.add({ days: 1 })}
              maxValue={maximumEndDate}
              className="flow-pause__calendar"
            />
          }
          arrow={false}
        >
          <button
            className="flow-pause__calendar-toggle"
            onClick={() => setEndIsOpen(!endIsOpen)}
          >
            <div>
              {!!endDate
                ? endDate.toLocaleString(undefined, {
                    dateStyle: "full",
                  })
                : "Select a date"}
            </div>
            <div tw="ml-auto">
              <FontAwesomeIcon icon={faCalendarDay} />
            </div>
          </button>
        </Tippy>
      </div>
    </div>
  );
};

export default PauseDatesForm;
