import gql from "graphql-tag";

import FlowStepFragment from "./FlowStepFragment";

export default gql`
  fragment FlowVersionFragment on flow_version {
    id
    flow_id
    css
    flow_step_ids
    flow_version_flow_steps(order_by: { position: asc }) {
      flow_step {
        ...FlowStepFragment
      }
    }
  }
  ${FlowStepFragment}
`;
