import { gql, useMutation } from "@apollo/client";

import {
  UseUpdateOfferRescheduleOrderMutation,
  UseUpdateOfferRescheduleOrderMutationVariables,
} from "../../../__generated__/graphql";

export default function useUpdateOfferRescheduleOrder() {
  const [updateOfferChangePlan] = useMutation<
    UseUpdateOfferRescheduleOrderMutation,
    UseUpdateOfferRescheduleOrderMutationVariables
  >(gql`
    mutation UseUpdateOfferRescheduleOrderMutation(
      $offerId: Int!
      $skip: Boolean!
      $reschedule: Boolean!
    ) {
      update_offer_reschedule_order_by_pk(
        pk_columns: { offer_id: $offerId }
        _set: { skip: $skip, reschedule: $reschedule }
      ) {
        offer_id
        skip
        reschedule
        offer {
          id
          offer_reschedule_order {
            offer_id
            skip
            reschedule
          }
        }
      }
    }
  `);

  return updateOfferChangePlan;
}
