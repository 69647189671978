import { css } from "twin.macro";

interface LoomProps {
  videoId: string;
  autoPlay: boolean;
}

const Loom: React.FunctionComponent<LoomProps> = ({ videoId, autoPlay }) => (
  <div
    css={css`
      position: relative;
      padding-bottom: 56.25%;
      height: 0;
    `}
  >
    <iframe
      title="Video"
      css={css`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      `}
      src={`https://www.useloom.com/embed/${videoId}?autoplay=${
        autoPlay ? "1" : "0"
      }`}
      frameBorder="0"
      allowFullScreen
      allow="autoplay; fullscreen; picture-in-picture"
    ></iframe>
  </div>
);

export default Loom;
