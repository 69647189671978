import gql from "graphql-tag";

export default gql`
  fragment TranslationValueFragment on translation_value {
    translation_id
    language
    value
    format
  }
`;
