import gql from "graphql-tag";

import TranslationFragment from "./TranslationFragment";

export default gql`
  fragment FlowConfirmationVersionFragment on confirmation_version {
    id
    confirmation_id
    show_on_cancel
    show_on_save
    saved_headline_translation {
      ...TranslationFragment
    }
    saved_content_translation {
      ...TranslationFragment
    }
    canceled_headline_translation {
      ...TranslationFragment
    }
    canceled_content_translation {
      ...TranslationFragment
    }
  }
  ${TranslationFragment}
`;
