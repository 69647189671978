import gql from "graphql-tag";

import FlowOfferRuleOfferFragment from "./FlowOfferRuleOfferFragment";

export default gql`
  fragment FlowOfferTestFragment on offer_test {
    id
    name
    token
    goal
    control_weight
    control_offer {
      ...FlowOfferRuleOfferFragment
    }
    offer_test_offers {
      offer_test_id
      offer_id
      weight
      offer {
        ...FlowOfferRuleOfferFragment
      }
    }
    winning_offer {
      ...FlowOfferRuleOfferFragment
    }
  }
  ${FlowOfferRuleOfferFragment}
`;
