import { forwardRef, useEffect, useState } from "react";
import tw, { styled } from "twin.macro";

import {
  FlowDeflectionFragment,
  language_enum,
} from "../../__generated__/graphql";
import { usePropertyValues } from "../properties/lib/propertyValues";
import { flowButtonCss } from "../public/flow/DefaultStyles";
import Deflection from "../public/flow/steps/Deflection";
import { DeflectionFormValues } from "./DeflectionForm";

const Container = styled.div`
  ${tw`flex justify-center max-h-full`}
  ${flowButtonCss()}
`;

const DeflectionWrapper = styled.div<{ mode: "panel" | "card" }>`
  ${tw`bg-white rounded`}

  ${(props) =>
    props.mode === "panel" &&
    tw`max-h-[40rem] border w-full pr-2 overscroll-y-auto overflow-y-auto`}
  ${(props) => props.mode === "card" && tw`w-[40rem] overscroll-y-none border`}
`;

interface DeflectionPreviewProps {
  formValues: Partial<Record<language_enum, DeflectionFormValues>> | undefined;
  language: language_enum;
  mode: "panel" | "card";
}

const DeflectionPreview: React.FunctionComponent<DeflectionPreviewProps> = ({
  formValues,
  language,
  mode,
}) => {
  const [deflection, setDeflection] = useState<FlowDeflectionFragment>();

  const { propertyConfig, propertyValues } = usePropertyValues();

  useEffect(() => {
    const values =
      formValues && formValues[language] ? formValues[language] : null;
    if (!values) {
      return;
    }

    setDeflection({
      __typename: "deflection",
      id: 1,
      token: "",
      title: values.name,
      minimum_items: values?.minimumItems,
      heading_translation_id: 1,
      heading_translation: {
        __typename: "translation",
        id: 1,
        translation_values: [
          {
            __typename: "translation_value",
            translation_id: 1,
            value: values.heading ? JSON.parse(values.heading) : "",
            language,
            format: values.headingFormat,
          },
        ],
      },
      content_translation_id: 2,
      content_translation: {
        __typename: "translation",
        id: 2,
        translation_values: [
          {
            __typename: "translation_value",
            translation_id: 2,
            value: values.content ? JSON.parse(values.content) : "",
            language,
            format: values.contentFormat,
          },
        ],
      },
      tag_ids: [],
      snapshot_item_ids: [],
      deflection_snapshot_items: values.snapshotItems.map((item, index) => {
        const hasProperty = !!item.propertyId && item.propertyId > 0;
        const propertyValue = hasProperty
          ? propertyValues[item.propertyId!.toString()]
          : null;
        const config = hasProperty
          ? propertyConfig[item.propertyId!.toString()]
          : null;

        return {
          __typename: "deflection_snapshot_item_position",
          position: index,
          deflection_snapshot_item: {
            __typename: "deflection_snapshot_item",
            id: index,
            deflection_id: 1,
            token: item.token,
            property_condition_value: item.minimumValue,
            property_id: item.propertyId,
            property:
              hasProperty && config
                ? {
                    __typename: "property",
                    id: item.propertyId,
                    name: config.name,
                    type: config.type,
                    format: config.numberFormat,
                    entity: "subscriber",
                    value: propertyValue,
                  }
                : {},
            icon_prefix: item.icon ? JSON.parse(item.icon).prefix : undefined,
            icon_name: item.icon ? JSON.parse(item.icon).name : undefined,
            date_format: item.dateFormat,
            text_translation_id: index,
            text_translation: {
              __typename: "translation",
              id: index,
              translation_values: [
                {
                  __typename: "translation_value",
                  translation_id: index,
                  value: item.text ? JSON.parse(item.text) : "",
                  language,
                  format: item.textFormat,
                },
              ],
            },
          },
        };
      }),
      flow_action_ids: [],
      deflection_flow_actions: values.flowActions.map((item, index) => ({
        __typename: "deflection_flow_action",
        position: index,
        flow_action: {
          __typename: "flow_action",
          id: index,
          token: "",
          code: item.code,
          type: item.type,
          account_id: -1,
          appearance: item.appearance,
          url: item.url,
          reroute_to_flow_id: item.rerouteToFlowId,
          text_translation_id: 1,
          text_translation: {
            __typename: "translation",
            id: index,
            translation_values: [
              {
                __typename: "translation_value",
                translation_id: index,
                value: item.text ? JSON.parse(item.text) : "",
                language,
                format: item.textFormat,
              },
            ],
          },
        },
      })),
    } as FlowDeflectionFragment);
  }, [formValues, language, propertyConfig, propertyValues]);

  if (!deflection) {
    return null;
  }
  return (
    <DeflectionWrapper mode={mode}>
      <Deflection
        deflection={deflection}
        disableVideoAutoPlay={true}
        previewMode={true}
        onDeflect={() => {}}
      />
    </DeflectionWrapper>
  );
};

export default forwardRef<HTMLDivElement, DeflectionPreviewProps>(
  (props, ref) => (
    <Container ref={ref}>
      <DeflectionPreview {...props} />
    </Container>
  )
);
