import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import { useState } from "react";
import tw, { styled, theme } from "twin.macro";

import sharedInputStyles from "./sharedInputStyles";

interface CustomSelectInputProps {
  className?: string;
  items: Array<{ value: string; label: string }>;
  value: string;
  onChange: (value: string) => void;
  minWidth?: string;
  buttonWidth?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  placeholder?: string;
}

const Menu = styled.div<{ minWidth?: string }>`
  ${tw`text-base`}
  min-width: ${(props) => (props.minWidth ? props.minWidth : "auto")};
  max-height: 20rem;
  overflow-y: auto;
`;
const DropdownButton = styled.button`
  ${sharedInputStyles}
  ${tw`flex items-center`}
`;
const DropdownButtonInner = styled.div`
  ${tw`whitespace-nowrap truncate text-left`}
  max-width: 16rem;
`;
const ItemRow = styled.div`
  ${tw`p-2 hover:bg-gray-100 cursor-pointer active:bg-blue-500 active:text-white first:rounded-t last:rounded-b`}

  &.active {
    ${tw`bg-blue-500 text-white`}
  }
`;

const CustomSelectInput: React.FunctionComponent<CustomSelectInputProps> = ({
  className,
  items,
  value,
  onChange,
  minWidth,
  buttonWidth,
  style,
  disabled = false,
  placeholder = "Select an item…",
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const selectedItem = items.find((item) => item.value === value);

  return (
    <Tippy
      theme="light"
      className="dropdown-menu"
      offset={[0, 3]}
      maxWidth="none"
      content={
        <Menu minWidth={minWidth}>
          {items.map((item) => (
            <ItemRow
              className={item.value === value ? "active" : undefined}
              key={item.value}
              onClick={() => {
                onChange(item.value);
                setIsOpen(false);
              }}
            >
              {item.label}
            </ItemRow>
          ))}
        </Menu>
      }
      visible={isOpen}
      onClickOutside={() => setIsOpen(false)}
      interactive={true}
      appendTo={document.body}
      placement="bottom-start"
      arrow={false}
    >
      <DropdownButton
        type="button"
        onClick={handleClick}
        className={className}
        style={style}
        disabled={disabled}
      >
        <DropdownButtonInner
          style={{
            width: buttonWidth ? `calc(${buttonWidth} - 36px)` : "auto",
          }}
        >
          {selectedItem ? selectedItem.label : placeholder}
        </DropdownButtonInner>
        <FontAwesomeIcon
          icon={faChevronDown}
          transform="down-1 shrink-6"
          tw="ml-auto"
          color={theme`colors.gray.600`}
        />
      </DropdownButton>
    </Tippy>
  );
};

export default CustomSelectInput;
