import gql from "graphql-tag";

import FlowConfirmationVersionFragment from "./FlowConfirmationVersionFragment";

export default gql`
  fragment FlowConfirmationFragment on confirmation {
    id
    published_version {
      ...FlowConfirmationVersionFragment
    }
    draft_version {
      ...FlowConfirmationVersionFragment
    }
  }
  ${FlowConfirmationVersionFragment}
`;
