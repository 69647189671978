import gql from "graphql-tag";

export default gql`
  fragment FlowPropertyFragment on property {
    id
    name
    entity
    type
    format
  }
`;
