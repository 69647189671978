import gql from "graphql-tag";

export default gql`
  fragment FlowOfferModifySubscriptionOptionFragment on offer_modify_subscription_option {
    type
    platform_connection_id
    platform_plan_id
    platform_addon_id
  }
`;
