import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import tw, { theme } from "twin.macro";

import LogoSpinner from "./LogoSpinner";
import Modal, { ModalProps } from "./modal/Modal";
import Spinner from "./Spinner";

type LoadingModalProps = ModalProps & {
  message: React.ReactNode;
  spinnerStyle?: "logo" | "generic" | "complete";
};

const Message = tw.div`text-center pb-10 text-lg text-gray-900`;

const LoadingModal: React.FunctionComponent<LoadingModalProps> = ({
  message,
  spinnerStyle = "logo",
  ...props
}) => (
  <Modal {...props} size="sm">
    {spinnerStyle === "generic" ? (
      <Spinner tw="pt-16" />
    ) : spinnerStyle === "complete" ? (
      <div tw="flex w-full items-center justify-center pt-14 pb-8">
        <FontAwesomeIcon
          icon={faCheckCircle}
          color={theme`colors.green.DEFAULT`}
          transform="grow-24"
        />
      </div>
    ) : (
      <LogoSpinner />
    )}
    <Message>{message}</Message>
  </Modal>
);

export default LoadingModal;
