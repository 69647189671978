import { gql, useApolloClient } from "@apollo/client";

import {
  CouponDropdownSearchQuery,
  CouponDropdownSearchQueryVariables,
  OfferFormContainerCouponsQuery,
} from "../../__generated__/graphql";
import IdDropdown from "../../common/IdDropdown";

interface CouponDropdownProps {
  value: string | null;
  onChange: (value: string) => void;
  coupons: OfferFormContainerCouponsQuery["platform_coupon"] | undefined;
}

const CouponDropdown: React.FunctionComponent<CouponDropdownProps> = ({
  value,
  onChange,
  coupons,
}) => {
  const apollo = useApolloClient();

  const handleSearch = async (search: string) => {
    const result = await apollo.query<
      CouponDropdownSearchQuery,
      CouponDropdownSearchQueryVariables
    >({
      query: gql`
        query CouponDropdownSearchQuery($search: String!) {
          search_platform_coupons(args: { search: $search }, limit: 1000) {
            platform_id
            name
            code
          }
        }
      `,
      variables: {
        search,
      },
    });

    return result.data.search_platform_coupons.map((c) => ({
      id: c.platform_id,
      label: c.name || "",
      displayId: c.code || c.platform_id,
    }));
  };

  const ids = (coupons || []).map((coupon) => ({
    id: coupon.platform_id,
    label: coupon.name || "",
    displayId: coupon.code || coupon.platform_id,
  }));

  return (
    <IdDropdown
      ids={ids}
      value={value || ""}
      onChange={onChange}
      isLoading={!coupons}
      width="100%"
      onSearch={handleSearch}
    />
  );
};

export default CouponDropdown;
