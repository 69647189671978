import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import { Placement } from "tippy.js";
import { theme } from "twin.macro";

interface HelpContentProps {
  content: string;
  placement?: Placement;
}

const HelpIcon: React.FunctionComponent<HelpContentProps> = ({
  content,
  placement = "top",
}) => (
  <Tippy content={content} delay={100} placement={placement}>
    <span tw="text-base">
      <FontAwesomeIcon icon={faQuestionCircle} color={theme`colors.gray.400`} />
    </span>
  </Tippy>
);

export default HelpIcon;
