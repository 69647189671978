import { Editor, Transforms } from "slate";

import { PROPERTY_TAG, PropertyTag } from "./types";

export default function insertPropertyTag(
  editor: Editor,
  tag: PropertyTag,
  propertyId: string,
  dateFormat: string,
  numberFormat: string,
  fallback: string,
  isArray: boolean
) {
  Transforms.insertNodes(editor, {
    type: PROPERTY_TAG,
    tag,
    fallback,
    propertyId,
    dateFormat,
    numberFormat,
    isArray,
    children: [{ text: "" }],
  });
  Transforms.move(editor);
}
