import { createContext, useContext } from "react";

import { PropertyConfig, PropertyValues } from "./types";

interface PropertyValuesContext {
  propertyValues: PropertyValues;
  propertyConfig: PropertyConfig;
  showPlaceholders: boolean;
}

const Context = createContext<PropertyValuesContext | undefined>(undefined);

export const usePropertyValues = () => {
  const context = useContext(Context);
  if (!context) {
    throw new Error(
      "usePropertyValues() not called within PropertyValuesProvider context"
    );
  }

  return context;
};

interface PropertyValuesProviderProps {
  propertyValues: PropertyValues;
  propertyConfig: PropertyConfig;
  showPlaceholders?: boolean;
}

export const PropertyValuesProvider: React.FunctionComponent<
  PropertyValuesProviderProps
> = ({
  propertyValues,
  propertyConfig,
  showPlaceholders = false,
  children,
}) => (
  <Context.Provider
    value={{ propertyValues, propertyConfig, showPlaceholders }}
  >
    {children}
  </Context.Provider>
);
