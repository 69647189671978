import { createGlobalStyle, keyframes } from "styled-components";
import tw, { css, theme } from "twin.macro";

import { FlowDisplayMode } from "./lib/types";
import { logoHeight, screenMobile } from "./lib/variables";

interface DefaultStylesProps {
  isEditMode: boolean;
  fullScreen: boolean;
  modal: boolean;
  showOfferTimer?: boolean;
  flowDisplayMode?: FlowDisplayMode;
}

const ribbonHeight = 28;
const groupRibbonHeight = 21;

export const flowModalCss = (
  showOfferTimer: boolean = true,
  displayMode?: FlowDisplayMode
) => css`
  .flow-modal {
    ${tw`fixed top-0 bottom-0 left-0 right-0 flex items-center justify-center`}
  }

  .flow-modal--convert {
    ${tw`relative w-full h-full`}
  }

  .flow-modal--convert--banner {
    ${tw`border-b border-divider`}
  }

  .flow-modal__overlay {
    opacity: 0.5;
  }

  .flow-modal--enter {
    .flow-modal__overlay {
      opacity: 0;
    }

    .flow-modal__container {
      opacity: 0;
      margin-top: -2rem;
    }
  }

  .flow-modal--enter-active {
    .flow-modal__overlay {
      opacity: 0.5;
      transition: opacity 200ms;
    }

    .flow-modal__container {
      opacity: 1;
      margin-top: 0;
      transition: opacity 200ms, margin-top 200ms;
    }
  }

  .flow-modal--enter-done {
    .flow-modal__overlay {
      opacity: 0.5;
    }

    .flow-modal__container {
      opacity: 1;
      margin-top: 0;
    }
  }

  .flow-modal--exit {
    .flow-modal__overlay {
      opacity: 0.5;
    }

    .flow-modal__container {
      opacity: 1;
      margin-top: 0;
    }
  }

  .flow-modal--exit-active {
    .flow-modal__overlay {
      opacity: 0;
      transition: opacity 200ms;
    }

    .flow-modal__container {
      opacity: 0;
      transition: opacity 200ms;
    }
  }

  .flow-modal__overlay {
    ${tw`absolute top-0 bottom-0 left-0 right-0 bg-gray-100`}
  }

  .flow-modal__container {
    ${tw`bg-white rounded`}
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
    ${tw`w-11/12 z-50 relative`}
    ${tw`flex flex-col`}
    max-height: calc(100% - 2rem);

    @media (max-width: 500px) {
      ${tw`w-full h-full max-h-full rounded-none flex flex-col`}
    }

    @media (min-width: 700px) {
      ${tw`max-w-3xl`}
    }

    @media (max-width: 700px) and (min-width: 501px) {
      ${displayMode !== "modal" && tw`max-w-sm`}
    }

    ${displayMode === "modal" &&
    css`
      && {
        ${tw`w-full h-full rounded-none flex flex-col max-w-[unset]`}
      }
    `}

    &.flow-modal__container--group {
      @media (max-width: 700px) {
        ${tw`max-w-3xl`}
      }

      @media (max-width: 640px) {
        ${tw`w-full h-full rounded-none flex flex-col flex-grow`}
      }
    }

    &.flow-modal__container--banner {
      ${tw`flex flex-row`}

      button,
      span {
        ${tw`h-1/3`}
      }

      .flow-button--x {
        ${tw`top-2 right-4`}
      }

      .flow-button--decline {
        ${tw`self-stretch mx-0`}
      }

      ${showOfferTimer
        ? css`
            @media (max-width: 550px) {
              ${tw`flex-col`}

              .flow-modal__footer--banner {
                ${tw`w-full items-center my-1`}

                .flow-button--x {
                  ${tw`absolute`}
                }

                .flow-button--primary {
                  ${tw`w-full h-auto`}
                  line-height: unset;
                }

                .flow-button--decline {
                  ${tw`self-center text-center`}

                  &.flow-button--decline--thin {
                    ${tw`hidden`}
                  }

                  &.flow-button--decline--wide {
                    ${tw`block`}
                  }
                }
              }
            }
          `
        : css`
            @media (max-width: 430px) {
              ${tw`flex-col`}

              .flow-modal__footer--banner {
                ${tw`w-full items-center my-1`}

                .flow-button--x {
                  ${tw`absolute`}
                }

                .flow-button--primary {
                  ${tw`w-full h-auto`}
                  line-height: unset;
                }

                .flow-button--decline {
                  ${tw`self-center text-center`}

                  &.flow-button--decline--thin {
                    ${tw`hidden`}
                  }

                  &.flow-button--decline--wide {
                    ${tw`block`}
                  }
                }
              }
            }
          `}
    }

    &.flow-modal__container--convert {
      ${tw`w-full h-full`}
      max-width: unset;
    }

    &.flow-modal__container--product-swap {
      ${tw`max-w-[37.5rem]`}

      .flow-modal__body {
        flex: 1;
        overflow: unset;
      }
    }
  }

  .flow-modal__body {
    ${tw`pt-6 pb-4 px-6 overflow-y-auto flex-shrink`}

    @media (max-width: 500px) {
      ${tw`flex-grow`}
    }

    ${displayMode === "modal" && tw`flex-grow`}

    > p:not(:last-child) {
      ${tw`mb-4`}
    }

    p,
    ul {
      ${tw`text-2xl`}
    }

    li {
      ${tw`text-2xl`}
    }

    .highlight {
      text-decoration: underline;
    }

    max-height: calc(100vh - 6rem);
    ${displayMode === "modal" && tw`max-h-[unset]`}

    &.flow-modal__body--banner {
      ${tw`p-5 h-full flex flex-col justify-center`}
      max-height: unset;
      flex-grow: 1;
    }
  }

  .flow-modal__body--conversion {
    max-height: unset;
  }

  .flow-group-modal__scroll-indicators {
    @media (min-width: 701px) {
      ${tw`hidden`}
    }
  }

  .flow-group-modal__header {
    ${tw`flex pt-2 flex-col text-center items-center`}

    h2 {
      ${tw`font-semibold text-xl leading-6 pt-2`}
    }

    h4 {
      ${tw`font-normal text-base leading-5 pt-2`}
    }
  }

  .flow-group-modal__body {
    ${tw`flex p-5 gap-[0.8rem] overflow-auto`}

    @media (max-width: 700px) {
      ${tw`snap-x min-w-full overflow-x-auto`}
      padding-left: 8%;
      padding-right: 8%;
    }

    &::-webkit-scrollbar {
      display: none;
      scrollbar-width: none;
      -ms-overflow-style: none;
    }
  }

  .flow-group-modal__footer {
    ${tw`text-gray-600 font-normal text-base leading-5 flex items-center text-center flex-col underline pb-5`}

    button {
      ${tw`disabled:opacity-50`}
    }
  }

  .flow-group-offer {
    @media (max-width: 700px) {
      ${tw`flex-grow snap-center`}
      min-width: 90%;
    }

    .highlight {
      ${tw`underline`}
    }

    label {
      ${tw`font-semibold`}
    }

    ${tw`flex flex-col items-stretch basis-full  py-0 text-sm box-border justify-start rounded-lg border border-divider min-h-[16rem]`}

    max-height: calc(100vh - 13rem);
  }

  .flow-group-offer__circle-wrapper {
    ${tw`flex flex-row items-start p-0 gap-3 relative w-14 h-14 top-5`}
    left: calc(50% - 56px / 2);
  }

  .flow-group-offer__circle {
    width: 56px;
    height: 56px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 56px;
    ${tw`bg-gray-200`}
  }

  .flow-group-offer__image {
    ${tw`w-full h-24 rounded-t-lg object-cover`}
  }

  .flow-group-offer__no-image {
    ${tw`w-full h-24 bg-gray-800 rounded-t-lg shrink-0`}
  }

  .flow-group-offer__ribbon {
    ${tw`mb-4 relative self-start shrink-0`}

    top: 16px;
    height: ${groupRibbonHeight}px;
    background: #27ae60;
    padding: 1px 8px;
    font-size: 12px;
    color: white;
    font-weight: 600;

    &:after {
      content: "";
      height: 0;
      width: 0;
      display: block;
      position: absolute;
      top: 0;
      right: -7px;
      border-top: ${groupRibbonHeight / 2}px solid #27ae60;
      border-bottom: ${groupRibbonHeight / 2}px solid #27ae60;
      border-right: 7px solid transparent;
    }
  }

  .flow-group-offer__headline {
    ${tw`font-semibold text-base px-4 pt-4`}
  }

  .flow-group-offer__body {
    ${tw`flex flex-col gap-3 grow overflow-auto`}
  }

  .flow-group-offer__content {
    ${tw`px-4`}
  }

  .flow-group-offer__options {
    ${tw`px-4 overflow-y-auto overflow-x-hidden`}
  }

  .flow-group-offer__options:not(:empty) {
    ${tw`my-2 py-1`}
  }

  .flow-group-offer__footer {
    ${tw`w-full p-4`}

    button {
      ${tw`flex flex-row items-center justify-center w-full rounded bg-green-500 text-sm`}
    }
  }

  .flow-modal__content {
    ${tw`pt-4`}

    h4, .editor__h4 {
      font-size: 28px;
      line-height: 34px;
      ${tw`text-3xl font-semibold mb-4`}
    }

    h5,
    .editor__h5 {
      font-size: 22px;
      line-height: 28px;
      ${tw`text-2xl font-semibold mb-[0.8rem]`}
    }

    &.flow-modal__content--banner {
      margin-top: 0;

      p {
        font-size: 16px;
        line-height: 21px;
      }
    }
  }

  .flow-modal__headline {
    font-size: 32px;
    line-height: 40px;
    ${tw`font-semibold`}

    &.flow-modal__headline--banner {
      font-size: 24px;
      line-height: 25px;
    }
  }

  .flow-modal__headline--has-ribbon {
    padding-top: 32px;

    @media (max-width: 500px) {
      padding-top: 0;
    }
  }

  .flow-modal__ribbon,
  .flow-instant-cancel__offer__ribbon {
    position: absolute;
    top: 16px;
    ${tw`mb-4`}
    left: -19px;
    height: ${ribbonHeight}px;
    background: #27ae60;
    padding: 1px 12px;
    font-size: 16px;
    color: white;
    font-weight: 600;
    line-height: 25px;

    &:after {
      content: "";
      height: 0;
      width: 0;
      display: block;
      position: absolute;
      top: 0;
      right: -7px;
      border-top: ${ribbonHeight / 2}px solid #27ae60;
      border-bottom: ${ribbonHeight / 2}px solid #27ae60;
      border-right: 7px solid transparent;
    }

    &:before {
      content: "";
      height: 0;
      width: 0;
      display: block;
      position: absolute;
      top: 28px;
      left: -1px;
      border-left: 20px solid transparent;
      border-top: 6px solid #0e6c36;
    }

    @media (max-width: 500px) {
      display: none;
    }

    ${displayMode === "modal" && tw`hidden`}
  }

  .flow-instant-cancel__offer__ribbon {
    position: relative;
    top: -8px;
    left: -50px;
    max-width: 130px;
    margin-bottom: 8px;
  }

  .flow-instant-cancel__offer__options:not(:empty) {
    ${tw`my-8`}
  }

  .flow-modal__pause-options,
  .flow-reschedule-order__options {
    ${tw`flex mt-8`}

    @media (max-width: ${screenMobile}) {
      ${tw`flex-col px-0`}
    }
  }

  .flow-instant-cancel__pause-options,
  .flow-instant-cancel__offer__pause-options {
    ${tw`flex`}

    @media (max-width: ${screenMobile}) {
      ${tw`flex-col px-0`}
    }
  }

  .flow-modal__pause-options-container,
  .flow-step__offer__pause-options-container,
  .flow-instant-cancel__offer__pause-options-container {
    ${tw`mt-8`}

    label {
      ${tw`block font-semibold mb-2 -mt-3`}
    }
  }

  .flow-group-offer__pause {
    ${tw`flex flex-col gap-2`}
  }

  .flow-group-offer__pause-options-container {
    ${tw`flex flex-col gap-2`}
  }

  .flow-group-offer__pause-options {
    ${tw`flex flex-col gap-4 w-full`}
  }

  .flow-modal__pause-options {
    ${tw`mt-0`}
  }

  .flow-reschedule-order__options {
    @media (max-width: 800px) {
      ${tw`flex-col px-0`}
    }
  }

  .flow-group-offer .flow-reschedule-order__options {
    ${tw`flex-col px-0`}
  }

  .flow-step__offer__pause-options {
    ${tw`flex w-full`}

    @media (max-width: ${screenMobile}) {
      ${tw`flex-col px-0`}
    }
  }

  .flow-step__offer__buttons {
    ${tw`mt-4`}
  }

  .flow-instant-cancel__pause-options,
  .flow-instant-cancel__offer__pause-options {
    ${tw`mb-8`}

    @media (max-width: 800px) {
      ${tw`flex-col px-0`}
    }
  }

  .flow-pause__dates {
    ${tw`mt-4 flex flex-col gap-3`}
  }

  .flow-pause__dates__date {
    ${tw`flex-1`}

    label {
      ${tw`font-semibold`}
    }
  }

  .flow-step__offer__pause {
    ${tw`mb-4`}
  }

  .flow-instant-cancel__offer__pause {
    ${tw`mb-8`}
  }

  .flow-group-offer__pause-reasons {
    ${tw`text-sm`}

    label {
      ${tw`font-normal`}
    }
  }

  .flow-pause__reason {
    ${tw`mt-5`}
  }
  label.flow-pause__reason__label {
    ${tw`font-semibold`}
  }
  .flow-pause__reason__reasons {
    ${tw`mt-2 flex flex-col gap-2`}
  }

  .flow-pause__reason__reason {
    ${tw`flex items-start`}

    input {
      ${tw`mt-[5.5px]`}
    }

    label {
      ${tw`ml-2`}
    }
  }

  .flow-group-offer .flow-pause__reason__reason {
    input {
      ${tw`mt-1`}
    }
  }

  .flow-group-offer__pause-reasons {
    ${tw`mt-0 flex flex-col gap-2`}
  }

  .flow-group-offer__pause-reasons + .flow-group-offer__pause-options {
    ${tw`mt-0`}
  }

  .flow-pause__calendar-toggle {
    ${tw`mt-2 flex w-full text-left px-4 py-2 border border-gray-300 rounded focus:border-blue-500 focus:ring focus:ring-blue-200 outline-none focus:outline-none transition-colors duration-150`}
  }

  .flow-group-offer__reschedule-order {
    ${tw`flex flex-col gap-2`}
  }

  .flow-step__offer .flow-reschedule-order__options {
    @media (max-width: 800px) {
      ${tw`flex-col px-0`}
    }
  }

  .flow-instant-cancel__offer .flow-reschedule-order__options {
    ${tw`mb-8`}

    @media (max-width: 800px) {
      ${tw`flex-col px-0`}
    }
  }

  .flow-instant-cancel__offer .flow-reschedule-order__options__reschedule {
    ${tw`mb-8 -mt-5`}
  }

  .flow-step__offer__reschedule-order
    .flow-reschedule-order__options__reschedule {
    ${tw``}
  }

  .flow-modal__pause-options__item,
  .flow-step__offer__pause-options__item,
  .flow-reschedule-order__options__item,
  .flow-instant-cancel__pause-options__item,
  .flow-instant-cancel__offer__pause-options__item,
  .flow-group-offer__pause-options__item {
    ${tw`flex-1 ml-4 first:ml-0 px-4 py-2 border-2 border-gray-300 rounded focus:border-blue-500 focus:ring focus:ring-blue-200 outline-none focus:outline-none transition-colors duration-150`}

    line-height: 1.25rem;

    &.active {
      ${tw`border-blue-500 bg-blue-100 text-blue-900`}

      .placeholder {
        ${tw`text-blue-900/60`}
      }
    }

    @media (max-width: ${screenMobile}) {
      ${tw`ml-0 mt-4 first:mt-0`}
    }

    span.highlight {
      text-decoration: none;
    }

    p {
      ${tw`m-0`}
      font-size: 1rem;
    }
  }

  .flow-reschedule-order__options__reschedule {
    ${tw`mt-3`}

    label {
      ${tw`block font-semibold mb-1`}
    }

    .tippy-content {
      ${tw`p-0`}
    }
  }

  .flow-group-offer__pause-options__item {
    ${tw`mx-0 my-0`}
  }

  .flow-reschedule-order__options__reschedule__calendar-toggle {
    ${tw`flex w-full text-left px-4 py-2 border border-gray-300 rounded focus:border-blue-500 focus:ring focus:ring-blue-200 outline-none focus:outline-none transition-colors duration-150`}
  }

  .flow-reschedule-order__options__reschedule__calendar {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 1rem;

    .calendar__grid__cell div[role="button"].active div,
    .calendar__grid__month.active {
      ${tw`bg-gray-600`}
    }
  }

  .flow-instant-cancel__pause-options__item,
  .flow-instant-cancel__offer__pause-options__item,
  .flow-reschedule-order__options__item {
    @media (max-width: 800px) {
      ${tw`ml-0 mt-4 first:mt-0`}
    }
  }

  .flow-group-offer .flow-reschedule-order__options__item {
    ${tw`ml-0 mt-4 first:mt-0`}
  }

  .flow-step__offer {
    ${tw`flex flex-col gap-6`}
  }

  .flow-instant-cancel__offer__content,
  .flow-step__offer__content {
    ${tw`text-lg`}

    p:not(:last-child), h4:not(:last-child), h5:not(:last-child) {
      ${tw`mb-3`}
    }

    h4 {
      ${tw`text-2xl font-semibold`}
      line-height: 1.25em;
    }

    h5 {
      ${tw`text-xl font-semibold`}
      line-height: 1.25em;
    }
  }

  .flow-group-offer {
    .flow-pause__reason {
      ${tw`mt-0`}
    }

    .flow-pause__reason__reasons {
      ${tw`flex flex-col gap-2`}
    }

    .flow-pause__reason--active {
      ${tw`border-b border-divider pb-6`}
    }

    .flow-modal__pause-options {
      ${tw`mt-3`}
    }

    .flow-pause__reason.flow-pause__reason--active {
      ${tw`pb-3 mb-1`}
    }

    .flow-pause__dates {
      ${tw`mt-0 gap-2`}
    }

    .flow-reschedule-order__options {
      ${tw`mt-0`}
    }
  }

  .flow-step__offer__options {
    ${tw`mb-4`}
  }

  .flow-step__offer__content {
    ${tw`mb-6`}

    .highlight {
      text-decoration: underline;
    }
  }

  .flow-step__offer__image {
    ${tw`mt-4`}

    & + .flow-step__offer__content {
      ${tw`mt-8`}
    }
  }

  .flow-modal__image {
    ${tw`mt-4`}
  }

  .flow-modal__footer {
    ${tw`py-4 pb-6 px-6 flex gap-4`}

    ${displayMode === "modal" && tw`py-6`}

    button {
      ${tw`w-full`}
    }

    &.flow-modal__footer--convert {
      ${tw`flex-col gap-2 pb-3`}
    }

    .flow-button--decline {
      ${tw`max-w-[150px] self-center`}
    }

    &.flow-modal__footer--banner {
      ${tw`flex-col w-36 min-w-fit items-end justify-between px-5 py-1`}

      button {
        ${tw`m-auto`}
      }

      .flow-button--primary {
        ${tw`w-full text-[16px] font-semibold`}
      }

      .flow-button--danger {
        display: none;
      }

      .flow-button--x {
        ${tw`cursor-pointer text-gray-300 hover:text-gray-400 transition-colors duration-150`}
        font-weight: 900;
        font-size: 16px;
      }

      .flow-button--decline {
        ${tw`text-right`}

        &.flow-button--decline--thin {
          ${tw`block`}
        }

        &.flow-button--decline--wide {
          ${tw`hidden`}
        }
      }
    }
  }

  .flow-timer {
    ${tw`flex flex-row justify-center gap-8 pb-2`}

    &.flow-timer--banner {
      ${tw`gap-4 pt-2 pb-0`}

      @media (max-width: 750px) and (min-width: 601px) {
        ${tw`gap-3 pt-1`}
      }

      @media (max-width: 550px) {
        ${tw`gap-6 pb-3`}
      }
    }

    .flow-timer__wrapper {
      ${tw`flex flex-col justify-center items-center`}

      .flow-timer__count {
        ${tw`border-[1px] border-divider rounded-md`}
        padding: 8px;
        font-weight: 700;
        font-size: 32px;
        line-height: 24px;
        color: #52525b;

        &.flow-timer__count--banner {
          @media (max-width: 750px) and (min-width: 551px) {
            font-size: 21px;
            line-height: 16px;
          }
        }
      }

      .flow-timer__label {
        font-weight: 400;
        font-size: 18px;
        color: #52525b;

        &.flow-timer__label--banner {
          @media (max-width: 750px) and (min-width: 551px) {
            font-size: 14px;
            line-height: 18px;
          }
        }
      }
    }
  }
`;

export const flowButtonCss = (isEditMode: boolean = false) => css`
  .flow-button {
    ${tw`
      text-xl border rounded relative 
      disabled:cursor-default disabled:shadow-none
      focus:outline-none focus:ring focus:ring-blue-200 transition-colors duration-150 ease-in-out
    `}

    &:active:enabled > div {
      top: 1px;
    }

    &.flow-button--edit-mode:active {
      ${tw`focus:shadow-none`}
      & > div {
        top: 0;
      }
    }
  }

  .flow-button--default,
  .flow-button--primary,
  .flow-button--secondary,
  .flow-button--danger {
    ${tw`text-white border-transparent disabled:text-white disabled:opacity-50 disabled:pointer-events-none`}
  }

  .flow-button--default {
    ${tw`bg-gray-800 hover:bg-gray-900 disabled:bg-gray-500`}
  }

  .flow-button--primary {
    ${tw`bg-green-500 hover:bg-green-600`}
  }

  .flow-button--secondary {
    ${tw`bg-blue-500 hover:bg-blue-600`}
  }

  .flow-button--danger {
    ${tw`bg-red-500 hover:bg-red-600`}

    &.flow-button--danger-in-editor:disabled {
      ${tw`bg-red-500`}
    }

    &.flow-abort--danger-in-editor:disabled {
      ${tw`bg-red-500`}
    }
  }

  .flow-button--next {
    width: 13rem;

    ${!isEditMode &&
    css`
      @media (max-width: 700px) {
        ${tw`mr-0`}
        order: 1;
        width: calc(100vw - 2.5rem);
        max-width: 20rem;
      }
    `}
  }

  .flow-button--action {
    ${tw`bg-transparent border-2 border-blue-500 text-blue-500`}

    &:disabled {
      ${tw`opacity-50`}
    }

    &:hover:not(:disabled) {
      ${tw`text-blue-600 border-blue-600`}
    }
  }

  &.flow-button--loading {
    .flow-button__content {
      ${tw`invisible`}
    }
  }

  .flow-button__content {
    ${tw`px-3 relative`}
    line-height: 1.5rem;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
  }

  .flow-button__spinner {
    ${tw`absolute top-0 left-0 w-full h-full box-border`}
  }

  .flow-button--decline {
    ${tw`text-link text-[12px] leading-8 font-semibold underline text-gray-300 hover:text-gray-400`}
  }
`;

const radioCheckedColor = theme`colors.blue.400`;
const radioBorderColor = theme`colors.gray.200`;
const radioSize = 19;
const radioCheckedSize = 11;
const radioRippleSize = 10;
const buffer = 4;

const ripple = keyframes`
  0% {
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.0);
  }

  50% { 
    box-shadow: 0px 0px 0px ${radioRippleSize}px rgba(0, 0, 0, 0.1);
  }

  100% {
    box-shadow: 0px 0px 0px ${radioRippleSize}px rgba(0, 0, 0, 0);
  }
`;

const GlobalStyles = createGlobalStyle<DefaultStylesProps>`
  body {
    --flow-divider-color: ${theme`colors.gray.200`};
  }

  ${(props) =>
    !props.isEditMode &&
    css`
      body {
        margin: 0;
        font-family: "Source Sans Pro", sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        overscroll-behavior: none;
        ${tw`text-gray-900`}
      }

      html,
      body,
      #root {
        overflow: hidden;
      }

      #root {
        justify-content: center;
      }
    `}

  ${(props) =>
    !props.isEditMode &&
    props.fullScreen &&
    !props.modal &&
    css`
      body {
        background: white;
      }

      html,
      body,
      #root {
        height: 100%;
      }
    `}

  ${(props) =>
    !props.fullScreen &&
    css`
      #root {
        padding: 30px;
      }
    `}

  .flow-sidebar {}

  .flow-sidebar__logo {
    max-height: ${logoHeight};
    margin-bottom: 2.5rem;
  
    ${(props) =>
      !props.isEditMode &&
      css`
        @media (max-width: ${screenMobile}) {
          ${tw`my-3`}
        }
      `}

    ${(props) =>
      !props.isEditMode && props.flowDisplayMode === "modal" && tw`my-3`}
  }

  .flow-sidebar__company-name {
    ${tw`text-2xl font-semibold mb-6 leading-8`}

    ${(props) =>
      !props.isEditMode &&
      css`
        @media (max-width: ${screenMobile}) {
          ${tw`my-3`}
        }
      `}

    ${(props) =>
      !props.isEditMode && props.flowDisplayMode === "modal" && tw`my-3`}
  }

  .flow-sidebar__headline {
    ${tw`font-semibold mb-6 leading-10`}
    font-size: 32px;
  
    ${(props) =>
      !props.isEditMode &&
      css`
        @media (max-width: ${screenMobile}) {
          ${tw`mb-0 leading-8`}
          font-size: 24px;
        }
      `}

    ${(props) =>
      !props.isEditMode &&
      props.flowDisplayMode === "modal" &&
      css`
        ${tw`mb-0 leading-8`}
        font-size: 24px;
      `}
  }

  .flow-sidebar__subheadline {
    ${tw`text-gray-400`}
    font-size: 22px;

    ${(props) =>
      !props.isEditMode &&
      css`
        @media (max-width: ${screenMobile}) {
          display: none;
        }

        @media (max-height: 520px) {
          display: none;
        }
      `}


    ${(props) =>
      !props.isEditMode && props.flowDisplayMode === "modal" && tw`hidden`}
  }

  .flow-footer {
    ${tw`py-4 flex`}
  }

  .flow-footer__content {
    ${tw`flex items-center mx-auto`}

    ${(props) =>
      !props.isEditMode &&
      css`
        @media (max-width: 700px) {
          ${tw`flex-col pb-2`}

          & > * {
            ${tw`mt-4`}
          }
        }
      `}
  }

  .flow-footer__pagination {
    ${tw`mx-4`}

    ${(props) =>
      !props.isEditMode &&
      css`
        @media (max-width: 700px) {
          width: auto !important;
          order: -1;
          ${tw`mt-0`}

          &:first-child {
            display: none;
          }
        }
      `}
  }

  .flow-pagination {
    ${tw`flex mx-auto items-center`}
  }

  .flow-pagination__page {
    ${tw`
      w-3 h-3 rounded-full mr-2 last:mr-0 bg-gray-200 focus:outline-none 
      transition-transform duration-150
    `}
    transform: scale(.8);
  
    &:disabled {
      ${tw`cursor-default`}
    }
  }

  .flow-pagination__page--active {
    ${tw`bg-gray-800 cursor-default`}
    transform: scale(1);
  }

  .flow-close-button {
    ${tw`py-1 px-2 cursor-pointer outline-none focus:outline-none text-xl text-gray-300 hover:text-gray-400 transition-colors duration-150`}
  }

  .flow-abort-button {
    ${tw`text-xl mr-6 text-link focus:outline-none disabled:text-gray-200 disabled:cursor-default`}

    &.flow-abort-button--in-editor:disabled {
      ${tw`text-link`}
    }

    ${(props) =>
      !props.isEditMode &&
      css`
        @media (max-width: 700px) {
          ${tw`mr-0 leading-4`}
          order: 2;
        }
      `}
  }

  .flow-link-button {
    ${tw`text-link hover:underline text-left outline-none focus:outline-none`}
  }

  ${(props) => flowButtonCss(props.isEditMode)}

  .flow-step {}

  .flow-step__title {
    ${tw`text-2xl leading-8 mb-6`}

    .flow-container--modal & {
      ${tw`mb-4`}
    }
  }

  .flow-step__hint {
    ${tw`text-lg text-gray-400 mb-8`}

    .flow-container--modal & {
      ${tw`mb-6`}
    }
  }

  .flow-step__content {
    ${tw`mb-6 text-lg`}

    .flow-container--modal & {
      ${tw`mb-4`}
    }

    p:not(:last-child), h4:not(:last-child), h5:not(:last-child) {
      ${tw`mb-3`}
    }

    h4 {
      ${tw`text-2xl font-semibold`}
    }

    h5 {
      ${tw`text-xl font-semibold`}
    }
  }

  .flow-step__deflection-grid {
    ${tw`grid grid-cols-2 my-8`}

    .flow-container--modal & {
      ${tw`mt-6 mb-6`}
    }

    ${(props) =>
      !props.isEditMode &&
      css`
        ${tw`-mx-12`}

        @media (max-width: 980px) {
          ${tw`-mx-10`}
        }

        @media (max-width: 900px) {
          ${tw`grid-cols-1 -ml-2`}

          .flow-step__deflection-grid__item {
            ${tw`px-0 py-2`}
          }
        }
      `}
  }

  .flow-step__deflection-grid__item {
    ${tw`flex items-center m-2 px-8 py-4`}

    ${(props) => props.isEditMode && tw`ml-0 pl-0`}

    .flow-container--modal & {
      ${tw`my-0`}
    }
  }

  .flow-step__deflection-actions {
    ${tw`flex flex-wrap items-center`}

    row-gap: 0.5rem;
    column-gap: 1rem;
  }

  .flow-check {
    ${tw`relative`}

    input {
      display: none;

      &:checked + label:before {
        ${tw`border-blue-500`}
        animation: ${ripple} 0.2s linear forwards;
      }

      &:checked + label:after {
        transform: scale(1);
      }
    }

    &.flow-check--disabled {
      label {
        ${tw`cursor-default opacity-50`}
      }
    }

    &.flow-check--add {
      ${tw`text-blue-500`}
      label:before {
        ${tw`border-blue-500`}
      }
    }

    label {
      margin: 0;
      display: inline-block;
      min-height: 23px;
      position: relative;
      padding: 0 0 0 ${radioSize + 10}px;
      margin-bottom: 0;
      vertical-align: bottom;
      cursor: pointer;
      ${tw`text-lg cursor-pointer`}

      &:before,
      &:after {
        position: absolute;
        content: '';
        transition: all 0.3s ease;
        transition-property: transform, border-color;
      }

      &:before {
        left: 0;
        top: ${buffer}px;
        width: ${radioSize}px;
        height: ${radioSize}px;
        border: 2px solid ${radioBorderColor};
      }

      &:after {
        top: ${radioSize / 2 - radioCheckedSize / 2 + buffer}px;
        left: ${radioSize / 2 - radioCheckedSize / 2}px;
        width: ${radioCheckedSize}px;
        height: ${radioCheckedSize}px;
        transform: scale(0);
        background: ${radioCheckedColor};
      }
    }
  }

  .flow-check--radio {
    label {
      &:before,
      &:after {
        border-radius: 50%;
      }
    }
  }

  .flow-check__please-specify {
    margin-left: 6px;
    margin-bottom: -1px;
  }

  .flow-form-group {
    ${tw`mb-8`}

    label {
      ${tw`block text-lg mb-2`}
    }

    .flow-input-text {
      ${tw`w-full`}
    }
  }

  .flow-form-question--title--required .flow-form-question--title__content > span::after {
    content: ' *';
    white-space: nowrap;
  }

  .flow-input-label--required::after {
    ${tw`text-red-500`}
    content: ' *';
  }

  .flow-input-text {
    ${tw`text-lg rounded-none bg-transparent border-0 border-b border-gray-200 focus:border-blue-500 focus:outline-none`}

    &:placeholder {
      ${tw`text-gray-200`}
    }
  }

  .flow-input-hint {
    ${tw`text-type-light mt-1`}
  }

  .flow-textarea {
    resize: none;
    ${tw`text-lg rounded-none bg-transparent border-0 border-b border-gray-200 w-full py-2 focus:border-blue-500 focus:outline-none`}

    &:placeholder {
      ${tw`text-gray-200`}
    }
  }

  ${(props) => flowModalCss(props.showOfferTimer, props.flowDisplayMode)}

  .flow-confirmation {
    ${tw`flex`}
  }

  .flow-confirmation__icon {
    ${tw`text-4xl pt-1 text-gray-600`}
  }

  .flow-confirmation__icon--saved {
    ${tw`text-green-600`}
  }

  .flow-confirmation__content {
    ${tw`ml-8 flex-1`}
  }

  .flow-confirmation__headline {
    ${tw`text-2xl font-semibold mt-2`}
  }

  .flow-confirmation__body {
    ${tw`text-xl mt-4`}
  }

  .flow-spinner {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  .flow-spinner__dot {
    background-color: white;
    width: 10px;
    height: 10px;
    margin-right: 6px;
    border-radius: 100%;
    display: inline-block;
    animation: flow-spinner-bounce 1.4s infinite ease-in-out both;
  }

  .flow-spinner__dot--1 {
    animation-delay: -0.32s;
  }

  .flow-spinner__dot--2 {
    animation-delay: -0.16s;
  }

  @keyframes flow-spinner-bounce {
    0%,
    80%,
    100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }

  .flow-instant-cancel {
    ${(props) =>
      !props.isEditMode &&
      css`
        @media (max-width: 700px) {
          ${tw`flex flex-col-reverse`}

          .flow-instant-cancel__section {
            ${tw`w-full mb-8 first:mt-0`}
          }
        }
      `}  
  }

  .flow-instant-cancel__brand {
    @media (max-width: ${screenMobile}) {
      ${tw`w-full mb-4`}
    } 
  }

  .flow-instant-cancel__offer {
    ${tw`border border-divider p-8 rounded`}
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  }

  .flow-instant-cancel__offer__headline, .flow-step__offer__headline {
    font-size: 32px;
    line-height: 40px;
    ${tw`font-semibold mb-2`}  
  }

  .flow-instant-cancel__offer__footer {
    ${tw`mt-6`}
  }

  .flow-step__offer__headline {
    ${tw`mb-4`}
  }

  .flow-rating-selector {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.8rem;
    max-width: 502px;
    @media (max-width: 982px) {
      width: 282px;
    }
    @media (max-width: 602px) {
      width: 100%;
    }
  }

  .flow-rating-selector__ratings {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    @media (max-width: 982px) {
      flex-direction: column;
      gap: 0.75rem;
    }
    @media (max-width: 602px) {
      width: inherit;
    }
  }

  .flow-rating-selector__ratings-inner-wrapper {
    display: flex;
    gap: 0.75rem;
    @media (max-width: 982px) {
      align-items: flex-start;
      gap: 1.125rem;
      align-self: stretch;
    }
    @media (max-width: 602px) {
      flex-direction: column;
      gap: 0.625rem;
    }

    &.flow-rating-selector__ratings-inner-wrapper--compact {
      align-items: flex-start;
      gap: 0.45em;
      align-self: stretch;

      @media (max-width: 982px) {
        gap: 1.125rem;
      }

      @media (max-width: 602px) {
        gap: 0.625rem;
      }
    }
  }

  .flow-rating-selector__rating {
    display: flex;
    padding: 0.5625rem 0.9rem;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.6875rem;
    margin-bottom: 8px;
    max-width: 40px;
    align-items: center;
    border-radius: 0.375rem;
    border: 1px solid var(--gray-5, ${theme`colors.divider`});
    cursor: pointer;

    @media (max-width: 982px) {
      padding: 0.5625rem 1rem;
      max-width: 42px;
    }

    @media (max-width: 602px) {
      display: flex;
      padding: 0.5625rem 1rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0.6875rem;
      align-self: stretch;
      max-width: initial;
    }

    &:hover {
      ${tw`bg-blue-100`}
      border: 1px solid var(--gray-5, ${theme`colors.divider`});
    }

    &.flow-rating-selector__rating--active {
      border: 1px solid var(--blue-1, ${theme`colors.blue.DEFAULT`});
      background: var(--blue-1, ${theme`colors.blue.DEFAULT`});

      .flow-rating-selector__rating__label {
        color: var(--white, #FFF);
      }
    }
  }

  .flow-rating-selector__rating__label {
    color: var(--gray-1, #333);
    text-align: center;
    font-size: 1.125rem;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    user-select: none;
  }

  .flow-rating-selector__help-text {
    display: flex;
    align-items: flex-start;
    gap: 0.625rem;
    align-self: stretch;
  }

  .flow-rating-selector__help-text__left {
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    color: var(--gray-1, #333);
    font-size: 0.875rem;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .flow-rating-selector__help-text__right {
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    color: var(--gray-1, #333);
    text-align: right;
    font-size: 0.875rem;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .flow-modal__product-swap {
    ${tw`mt-4 px-2 overflow-y-auto overflow-x-hidden flex-shrink`}
  }

  .flow-product-swap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.625rem;
  }

  .flow-product-swap__product {
    padding: 0.75rem;
    border-radius: 0.375rem;
    border: 2px solid var(--Gray-4, #A1A1AA);
    background: var(--White, #FFF);
    color: var(--Gray-1, #333);
    font-size: 0.875rem;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    align-self: stretch;
    height: 6.75rem;
    width: 100%;
    transition: height 0.1s linear;

    &.flow-product-swap__product--selected {
      border: 2px solid var(--Blue-1, ${theme`colors.blue.DEFAULT`});
      background: #DFEEFD;
      color: #1D1E8B;
    }

    &.flow-product-swap__product--expanded {
      height: 19rem;
      transition: height 0.1s linear;

      &.flow-product-swap__product--none {
        height: 6.75rem;
      }

      &.flow-product-swap__product--md {
        height: 15.6rem;
      }

      &.flow-product-swap__product--sm {
        height: 12.2rem;
      }
    }
  }

  .flow-product-swap__product__header__img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5rem;
    height: 5rem;
    border-radius: 0.1875rem;
    background: #F2F2F2;

    &.flow-product-swap__product__header__img--selected {
      background: #CFDDF4;
    }

    img {
      border-radius: 0.1875rem;
    }
  }

  .flow-product-swap__product__header {
    display: flex;
    gap: 0.75rem;
  }

  .flow-group-offer .flow-product-swap__product__header {
    ${tw`text-start`}
  }

  .flow-product-swap__product__header__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.1rem;
    flex: 1 0 0;
  }

  .flow-product-swap__product__header__title {
    font-weight: 700;
    font-size: 1rem;

    @media (max-width: ${screenMobile}) {
      ${tw`text-start`}
    }
  }

  .flow-product-swap__product__header__subtitle {
    color: var(--Gray-3, #52525B);
    font-style: italic;

    &.flow-product-swap__product__header__subtitle--selected {
      color: #1D1E8B;
    }

    &.flow-product-swap__product__header__subtitle--valid {
      font-style: normal;
    }
  }

  .flow-product-swap__product__body {
    height: 11.25rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    gap: 0.375rem;
    flex: 1 0 0;
    transition: height 0.2s linear;

    &.flow-product-swap__product__body--none {
      height: 0;
      display: none;
    }
  }

  .flow-product-swap__product__body__variant {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.0625rem;
    align-self: stretch;
    justify-content: start;
  }

  .flow-product-swap__product__body__variant__title {
    font-weight: 600;
  }

  .flow-product-swap__product__body__variant__select {
    appearance: none;
    text-overflow: ellipsis;
    background-color: rgba(255, 255, 255, 0.40);

    background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.3) 0%,
        rgba(255, 255, 255, 0.3) 100%
      ),
      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path stroke='black' fill='black' d='M6 8l-1 1l5 5l5-5l-1-1l-4 4l-4-4z'/></svg>");
    background-repeat: repeat, no-repeat;
    background-position: 0 0, right 0.5em top 50%;
    background-size: 100%, 0.85em auto;

    display: flex;
    padding: 0.175rem 0.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.375rem;
    align-self: stretch;
    border-radius: 0.3125rem;
    border: 1px solid #7F9EE7;
    height: 1.75rem;
  }

  .flow-product-swap__product__body__price {
    font-weight: 600;
    display:flex;
    gap: 0.25rem;
    align-items: center;
  }

  .flow-product-swap__product__body__price__help {
    font-style: italic;
  }

  .flow-product-swap__placeholder {
    display: flex;
    height: 5rem;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 0.375rem;
    border: 0px dashed var(--Gray-5, ${theme`colors.divider`});
    background: rgba(242, 242, 242, 0.70);
    text-align: center;
    font-family: Source Sans Pro;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .flow-current-variant {
    ${tw`pb-4`}
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0rem;
    align-self: stretch;
  }

  .flow-current-variant__title {
    color: var(--Gray-1, #333);
  }

  .flow-current-variant__body {
    display: flex;
    width: 28.625rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.375rem;
    align-self: stretch;
  }

  .flow-group-offer .flow-current-variant__body {
    width: unset;
  }

  .flow-current-variant__body__name {
    color: var(--Gray-1, #333);
    font-weight: 700;
  }

  .flow-current-variant__body__variants {
    color: var(--Gray-2, ${theme`colors.gray.800`});
  }

  .flow-current-variant__body__price {
    color: var(--Gray-1, #333);
    font-size: 0.875rem;
    font-weight: 600;
    display:flex;
    gap: 0.25rem;
    align-items: center;
  }

  .flow-product-swap-instructions {
    ${tw`py-4`}
    color: var(--Gray-1, #333);
    font-size: 1rem;
    border-top: 1px solid var(--Gray-5, ${theme`colors.divider`});
  }

  .flow-error {
    ${tw`flex bg-red-50 rounded-lg border border-red-100 p-4 pr-5 mb-6`}
  }

  .flow-error--has-ribbon {
    ${tw`mt-10 -mb-2`}
  }

  .flow-instant-cancel__offer .flow-error {
    ${tw`mb-4`}
  }

  .flow-instant-cancel__offer .flow-error--has-ribbon {
    ${tw`my-4`}
  }

  .flow-error__icon {
    ${tw`text-red-500 pr-3`}
  }

  .flow-error__title {
    ${tw`font-bold text-red-800 text-lg`}
  }

  .flow-error__message {
    ${tw`text-red-800 text-base`}
  }

  .flow-error--cancel {
    ${tw`flex-col items-center text-center p-8`}

    .flow-error__icon {
      ${tw`text-[49px]`}
    }

    .flow-error__title {
      ${tw`mb-2`}
    }
  }
  .flow-group-modal__error {
    ${tw`p-6 pb-0`}

    .flow-error {
      ${tw`mb-0`}
    }
  }
`;

const DefaultStyles: React.FunctionComponent<DefaultStylesProps> = (props) => (
  <GlobalStyles {...props} />
);

export default DefaultStyles;
