import { ApolloClient, useApolloClient } from "@apollo/client";
import gql from "graphql-tag";
import { cloneDeep } from "lodash";
import { nanoid } from "nanoid";

import {
  AddOfferRuleInlineSegmentsMutation,
  AddOfferRuleInlineSegmentsMutationVariables,
  AddOfferRuleMutation,
  AddOfferRuleMutationVariables,
  AddOfferRuleSetVersionMutation,
  AddOfferRuleSetVersionMutationVariables,
  CreateOfferRuleDraftExistingVersionQuery,
  CreateOfferRuleDraftExistingVersionQueryVariables,
  CreateOfferRuleFraftNewVersionMutation,
  CreateOfferRuleFraftNewVersionMutationVariables,
  CreateOfferRuleGroupDraftExistingVersionQuery,
  CreateOfferRuleGroupDraftExistingVersionQueryVariables,
  CreateOfferRuleGroupDraftNewVersionMutation,
  CreateOfferRuleGroupDraftNewVersionMutationVariables,
  DeleteRuleMutation,
  DeleteRuleMutationVariables,
  FlowOfferRuleFragment,
  FlowOfferRulesQuery,
  FlowOfferRulesQueryVariables,
  MoveRuleMutation,
  MoveRuleMutationVariables,
  segment_condition_boolean_operator_enum,
  SegmentConditionFragment,
  UpdateOfferRuleGroupVersionMutation,
  UpdateOfferRuleGroupVersionMutationVariables,
  UpdateOfferRuleVersionMutation,
  UpdateOfferRuleVersionMutationVariables,
  UpdateRuleMutation,
  UpdateRuleMutationVariables,
  UpdateRuleRulesMutation,
  UpdateRuleRulesMutationVariables,
} from "../../../__generated__/graphql";
import TheFlowOfferRuleFragment from "../../../common/fragments/FlowOfferRuleFragment";
import TheFlowOfferRuleGroupFragment from "../../../common/fragments/FlowOfferRuleGroupFragment";
import TheFlowOfferRuleGroupVersionFragment from "../../../common/fragments/FlowOfferRuleGroupVersionFragment";
import TheFlowOfferRuleVersionFragment from "../../../common/fragments/FlowOfferRuleVersionFragment";
import TheFlowOfferRulesQuery from "../../../common/queries/FlowOfferRulesQuery";
import RuleInlineSegmentFragment from "../../../common/rules/fragments/RuleInlineSegmentFragment";
import { getConditionValueForInsert } from "../../../common/segments/lib";
import { RuleRule } from "../../public/flow/steps/types";

export const defaultRule: FlowOfferRuleFragment = {
  __typename: "offer_rule",
  id: -1,
  published_version: {
    __typename: "offer_rule_version",
    id: -1,
    offer_rule_id: -1,
    segment_group_ids: [],
    segment_ids: [],
    question_option_ids: [],
    offer_rule_rule_ids: [-1],
    offer_ids: [],
    offer_test_ids: [],
    offer_group_ids: [],
    offer_autopilot_offer_ids: [],
    include_present_no_offer: false,
    include_other_in_question_ids: [],
    offer_rule_version_segment_groups: [],
    offer_rule_version_segments: [],
    offer_rule_version_question_options: [],
    offer_rule_version_offer_rule_rules: [
      {
        __typename: "offer_rule_version_offer_rule_rule",
        offer_rule_rule_id: -1,
        offer_rule_version_id: -1,
        offer_rule_rule: {
          __typename: "offer_rule_rule",
          id: -1,
          offer_rule_id: -1,
          offer_ids: [],
          offer_test_ids: [],
          offer_group_ids: [],
          offer_autopilot_offer_ids: [],
          deflection_ids: [],
          include_present_no_offer: false,
          weight: 100,
          offer_rule_rule_offers: [],
          offer_rule_rule_offer_tests: [],
          offer_rule_rule_offer_groups: [],
          offer_rule_rule_offer_autopilot_offers: [],
          offer_rule_rule_deflections: [],
        },
      },
    ],
    offer_rule_version_offers: [],
    offer_rule_version_offer_tests: [],
    offer_rule_version_offer_groups: [],
    offer_rule_version_offer_autopilot_offers: [],
  },
  draft_version: null,
};

const updateOfferRuleGroupVersion = (
  apollo: ApolloClient<any>,
  offerRuleGroupId: number,
  publishedVersionId: number,
  draftVersionId?: number | null
) =>
  apollo.mutate<
    UpdateOfferRuleGroupVersionMutation,
    UpdateOfferRuleGroupVersionMutationVariables
  >({
    mutation: gql`
      mutation UpdateOfferRuleGroupVersionMutation(
        $offerRuleGroupId: Int!
        $publishedVersionId: Int!
        $draftVersionId: Int
        $includeDrafts: Boolean! = true
      ) {
        update_offer_rule_group_by_pk(
          pk_columns: { id: $offerRuleGroupId }
          _set: {
            published_version_id: $publishedVersionId
            draft_version_id: $draftVersionId
          }
        ) {
          ...FlowOfferRuleGroupFragment
        }
      }
      ${TheFlowOfferRuleGroupFragment}
    `,
    variables: {
      offerRuleGroupId,
      publishedVersionId,
      draftVersionId,
    },
    fetchPolicy: "no-cache",
  });

export const createOfferRuleGroupDraft = async (
  apollo: ApolloClient<any>,
  offerRuleGroupId: number
) => {
  const existingVersionResult = await apollo.query<
    CreateOfferRuleGroupDraftExistingVersionQuery,
    CreateOfferRuleGroupDraftExistingVersionQueryVariables
  >({
    query: gql`
      query CreateOfferRuleGroupDraftExistingVersionQuery(
        $offerRuleGroupId: Int!
        $includeDrafts: Boolean! = true
      ) {
        offer_rule_group_by_pk(id: $offerRuleGroupId) {
          id
          published_version {
            ...FlowOfferRuleGroupVersionFragment
          }
          draft_version {
            ...FlowOfferRuleGroupVersionFragment
          }
        }
      }
      ${TheFlowOfferRuleGroupVersionFragment}
    `,
    variables: { offerRuleGroupId },
    fetchPolicy: "no-cache",
  });

  if (existingVersionResult.data?.offer_rule_group_by_pk?.draft_version) {
    return existingVersionResult.data.offer_rule_group_by_pk.draft_version;
  }

  const existingVersion =
    existingVersionResult.data?.offer_rule_group_by_pk?.published_version;
  if (!existingVersion) {
    throw new Error("Offer rule group has no published version");
  }

  const newVersionResult = await apollo.mutate<
    CreateOfferRuleGroupDraftNewVersionMutation,
    CreateOfferRuleGroupDraftNewVersionMutationVariables
  >({
    mutation: gql`
      mutation CreateOfferRuleGroupDraftNewVersionMutation(
        $object: offer_rule_group_version_insert_input!
        $includeDrafts: Boolean! = true
      ) {
        insert_offer_rule_group_version_one(object: $object) {
          ...FlowOfferRuleGroupVersionFragment
        }
      }
      ${TheFlowOfferRuleGroupVersionFragment}
    `,
    variables: {
      object: {
        offer_rule_group_id: existingVersion.offer_rule_group_id,
        offer_rule_ids: existingVersion.offer_rule_ids,
      },
    },
    fetchPolicy: "no-cache",
  });

  if (!newVersionResult.data?.insert_offer_rule_group_version_one?.id) {
    throw new Error("Unable to insert new offer rule group version");
  }

  await updateOfferRuleGroupVersion(
    apollo,
    existingVersion.offer_rule_group_id,
    existingVersion.id,
    newVersionResult.data.insert_offer_rule_group_version_one.id
  );

  return newVersionResult.data.insert_offer_rule_group_version_one;
};

const updateOfferRuleVersion = (
  apollo: ApolloClient<any>,
  offerRuleId: number,
  publishedVersionId: number,
  draftVersionId?: number | null
) =>
  apollo.mutate<
    UpdateOfferRuleVersionMutation,
    UpdateOfferRuleVersionMutationVariables
  >({
    mutation: gql`
      mutation UpdateOfferRuleVersionMutation(
        $offerRuleId: Int!
        $publishedVersionId: Int!
        $draftVersionId: Int
        $includeDrafts: Boolean! = true
      ) {
        update_offer_rule_by_pk(
          pk_columns: { id: $offerRuleId }
          _set: {
            published_version_id: $publishedVersionId
            draft_version_id: $draftVersionId
          }
        ) {
          ...FlowOfferRuleFragment
        }
      }
      ${TheFlowOfferRuleFragment}
    `,
    variables: {
      offerRuleId,
      publishedVersionId,
      draftVersionId,
    },
  });

const createOfferRuleDraft = async (
  apollo: ApolloClient<any>,
  offerRuleId: number
) => {
  const existingVersionResult = await apollo.query<
    CreateOfferRuleDraftExistingVersionQuery,
    CreateOfferRuleDraftExistingVersionQueryVariables
  >({
    query: gql`
      query CreateOfferRuleDraftExistingVersionQuery(
        $offerRuleId: Int!
        $includeDrafts: Boolean! = true
      ) {
        offer_rule_by_pk(id: $offerRuleId) {
          id
          published_version {
            ...FlowOfferRuleVersionFragment
          }
          draft_version {
            ...FlowOfferRuleVersionFragment
          }
        }
      }
      ${TheFlowOfferRuleVersionFragment}
    `,
    variables: { offerRuleId },
  });

  if (existingVersionResult.data?.offer_rule_by_pk?.draft_version) {
    return existingVersionResult.data.offer_rule_by_pk.draft_version;
  }

  const existingVersion =
    existingVersionResult.data?.offer_rule_by_pk?.published_version;
  if (!existingVersion) {
    throw new Error("Offer rule has no published version");
  }

  const newVersionResult = await apollo.mutate<
    CreateOfferRuleFraftNewVersionMutation,
    CreateOfferRuleFraftNewVersionMutationVariables
  >({
    mutation: gql`
      mutation CreateOfferRuleFraftNewVersionMutation(
        $object: offer_rule_version_insert_input!
        $includeDrafts: Boolean! = true
      ) {
        insert_offer_rule_version_one(object: $object) {
          ...FlowOfferRuleVersionFragment
        }
      }
      ${TheFlowOfferRuleVersionFragment}
    `,
    variables: {
      object: {
        offer_rule_id: existingVersion.offer_rule_id,
        offer_ids: existingVersion.offer_ids,
        segment_ids: existingVersion.segment_ids,
        question_option_ids: existingVersion.question_option_ids,
      },
    },
  });

  if (!newVersionResult.data?.insert_offer_rule_version_one) {
    throw new Error("Unable to insert new offer rule version");
  }

  await updateOfferRuleVersion(
    apollo,
    newVersionResult.data.insert_offer_rule_version_one.offer_rule_id,
    existingVersion.id,
    newVersionResult.data.insert_offer_rule_version_one.id
  );

  return newVersionResult.data.insert_offer_rule_version_one;
};

export interface AddRuleOptions {
  offerIds?: number[];
  segmentGroupIds?: number[];
  segmentIds?: number[];
  newConditions?: SegmentConditionFragment[];
  deflectionIds?: number[];
  offerAutopilotOfferIds?: number[];
}

export const addRule = async (
  apollo: ApolloClient<any>,
  offerRuleGroupId: number,
  options: AddRuleOptions | undefined = {
    offerIds: [],
    segmentGroupIds: [],
    segmentIds: [],
    newConditions: [],
    deflectionIds: [],
    offerAutopilotOfferIds: [],
  }
) => {
  const groupDraftVersion = await createOfferRuleGroupDraft(
    apollo,
    offerRuleGroupId
  );

  const ruleResult = await apollo.mutate<
    AddOfferRuleMutation,
    AddOfferRuleMutationVariables
  >({
    mutation: gql`
      mutation AddOfferRuleMutation($object: offer_rule_version_insert_input!) {
        insert_offer_rule_version_one(object: $object) {
          id
          offer_rule_id
        }
      }
      ${TheFlowOfferRuleFragment}
    `,
    variables: {
      object: {
        offer_rule: {
          data: {},
        },
        segment_group_ids: options.segmentGroupIds || [],
        segment_ids: options.segmentIds || [],
        question_option_ids: [],
        offer_ids: options.offerIds || [],
        offer_autopilot_offer_ids: options.offerAutopilotOfferIds || [],
      },
    },
  });

  if (!ruleResult.data?.insert_offer_rule_version_one?.id) {
    throw new Error("Unable to insert offer rule");
  }

  const setVersionResult = await apollo.mutate<
    AddOfferRuleSetVersionMutation,
    AddOfferRuleSetVersionMutationVariables
  >({
    mutation: gql`
      mutation AddOfferRuleSetVersionMutation(
        $id: Int!
        $versionId: Int!
        $offerRuleGroupVersionId: Int!
        $appendId: jsonb!
        $includeDrafts: Boolean! = true
      ) {
        update_offer_rule_by_pk(
          pk_columns: { id: $id }
          _set: { published_version_id: $versionId }
        ) {
          ...FlowOfferRuleFragment
        }
        update_offer_rule_group_version_by_pk(
          pk_columns: { id: $offerRuleGroupVersionId }
          _append: { offer_rule_ids: $appendId }
        ) {
          id
          offer_rule_group {
            ...FlowOfferRuleGroupFragment
          }
        }
      }
      ${TheFlowOfferRuleFragment}
      ${TheFlowOfferRuleGroupFragment}
    `,
    variables: {
      id: ruleResult.data.insert_offer_rule_version_one.offer_rule_id,
      versionId: ruleResult.data.insert_offer_rule_version_one.id,
      offerRuleGroupVersionId: groupDraftVersion.id,
      appendId: ruleResult.data.insert_offer_rule_version_one.offer_rule_id,
    },
  });

  if (!setVersionResult.data?.update_offer_rule_by_pk) {
    throw new Error("Unable to set offer rule version");
  }

  return setVersionResult.data.update_offer_rule_by_pk;
};

interface UpdateRulesOptions {
  flowId?: number;
  segmentGroupIds: number[];
  segmentIds: number[];
  newConditions: SegmentConditionFragment[];
  questionOptionIds: number[];
  includeOtherInQuestionIds: number[];
  rules: RuleRule[];
}

export const updateRule = async (
  apollo: ApolloClient<any>,
  id: number,
  options: UpdateRulesOptions
) => {
  const draftVersion = await createOfferRuleDraft(apollo, id);

  const rulesResult = await apollo.mutate<
    UpdateRuleRulesMutation,
    UpdateRuleRulesMutationVariables
  >({
    mutation: gql`
      mutation UpdateRuleRulesMutation(
        $objects: [offer_rule_rule_insert_input!]!
      ) {
        insert_offer_rule_rule(objects: $objects) {
          returning {
            id
          }
        }
      }
    `,
    variables: {
      objects: options.rules.map((rule) => ({
        offer_rule_id: id,
        offer_ids: rule.offerIds,
        offer_test_ids: rule.offerTestIds,
        offer_group_ids: rule.offerGroupIds,
        offer_autopilot_offer_ids: rule.offerAutopilotOfferIds,
        deflection_ids: rule.deflectionIds,
        include_present_no_offer: rule.includePresentNoOffer,
        weight: rule.weight,
      })),
    },
  });

  const inlineSegmentIds = await (async () => {
    if (!options.newConditions?.length) {
      return [];
    }

    const result = await apollo.mutate<
      AddOfferRuleInlineSegmentsMutation,
      AddOfferRuleInlineSegmentsMutationVariables
    >({
      mutation: gql`
        mutation AddOfferRuleInlineSegmentsMutation(
          $objects: [segment_insert_input!]!
        ) {
          insert_segment(objects: $objects) {
            returning {
              ...RuleInlineSegmentFragment
            }
          }
        }
        ${RuleInlineSegmentFragment}
      `,
      variables: {
        objects: options.newConditions.map((c) => ({
          name: nanoid(),
          inline: true,
          primary_segment_condition_group: {
            data: {
              boolean_operator: segment_condition_boolean_operator_enum.and,
              segment_condition_group_entries: {
                data: [
                  {
                    position: 0,
                    entry_segment_condition: {
                      data: {
                        property: c.property,
                        operator: c.operator,
                        value: getConditionValueForInsert(c),
                        property_id: c.property_id,
                      },
                    },
                  },
                ],
              },
            },
          },
        })),
      },
    });

    if (!result.data?.insert_segment) {
      throw new Error("Unable to create inline segments");
    }

    if (options.flowId) {
      const offerRules = apollo.cache.readQuery<
        FlowOfferRulesQuery,
        FlowOfferRulesQueryVariables
      >({
        query: TheFlowOfferRulesQuery,
        variables: { flowId: options.flowId },
      });

      if (offerRules) {
        apollo.cache.writeQuery<
          FlowOfferRulesQuery,
          FlowOfferRulesQueryVariables
        >({
          query: TheFlowOfferRulesQuery,
          variables: { flowId: options.flowId },
          data: {
            ...offerRules,
            inlineSegments: [
              ...offerRules.inlineSegments,
              ...result.data.insert_segment.returning,
            ],
          },
        });
      }
    }

    return result.data.insert_segment.returning.map((s) => s.id);
  })();

  const updatedRuleResult = await apollo.mutate<
    UpdateRuleMutation,
    UpdateRuleMutationVariables
  >({
    mutation: gql`
      mutation UpdateRuleMutation(
        $id: Int!
        $update: offer_rule_version_set_input!
        $includeDrafts: Boolean! = true
      ) {
        update_offer_rule_version_by_pk(
          pk_columns: { id: $id }
          _set: $update
        ) {
          id
          offer_rule {
            ...FlowOfferRuleFragment
          }
        }
      }
      ${TheFlowOfferRuleFragment}
    `,
    variables: {
      id: draftVersion.id,
      update: {
        segment_group_ids: options.segmentGroupIds,
        segment_ids: [...options.segmentIds, ...inlineSegmentIds],
        question_option_ids: options.questionOptionIds,
        include_other_in_question_ids: options.includeOtherInQuestionIds,
        offer_rule_rule_ids:
          rulesResult.data?.insert_offer_rule_rule?.returning.map(
            (r) => r.id
          ) || [],
      },
    },
  });

  return updatedRuleResult.data?.update_offer_rule_version_by_pk;
};

export const deleteRule = async (
  apollo: ApolloClient<object>,
  offerRuleGroupId: number,
  offerRuleId: number
) => {
  const groupDraftVersion = await createOfferRuleGroupDraft(
    apollo,
    offerRuleGroupId
  );

  await apollo.mutate<DeleteRuleMutation, DeleteRuleMutationVariables>({
    mutation: gql`
      mutation DeleteRuleMutation(
        $versionId: Int!
        $offerRuleIds: jsonb!
        $includeDrafts: Boolean! = true
      ) {
        update_offer_rule_group_version_by_pk(
          pk_columns: { id: $versionId }
          _set: { offer_rule_ids: $offerRuleIds }
        ) {
          id
          offer_rule_group {
            ...FlowOfferRuleGroupFragment
          }
        }
      }
      ${TheFlowOfferRuleGroupFragment}
    `,
    variables: {
      versionId: groupDraftVersion.id,
      offerRuleIds: groupDraftVersion.offer_rule_ids.filter(
        (id: number) => id !== offerRuleId
      ),
    },
  });
};

export const reorderRules = (
  list: FlowOfferRuleFragment[],
  startIndex: number,
  endIndex: number
) => {
  const result = Array.from(cloneDeep(list));
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const moveRule = async (
  apollo: ApolloClient<any>,
  offerRuleGroupId: number,
  rules: FlowOfferRuleFragment[],
  sourceIndex: number,
  destinationIndex: number
) => {
  const reordered = reorderRules(rules, sourceIndex, destinationIndex);

  const groupDraftVersion = await createOfferRuleGroupDraft(
    apollo,
    offerRuleGroupId
  );

  await apollo.mutate<MoveRuleMutation, MoveRuleMutationVariables>({
    mutation: gql`
      mutation MoveRuleMutation(
        $versionId: Int!
        $offerRuleIds: jsonb!
        $includeDrafts: Boolean! = true
      ) {
        update_offer_rule_group_version_by_pk(
          pk_columns: { id: $versionId }
          _set: { offer_rule_ids: $offerRuleIds }
        ) {
          id
          offer_rule_group {
            ...FlowOfferRuleGroupFragment
          }
        }
      }
      ${TheFlowOfferRuleGroupFragment}
    `,
    variables: {
      versionId: groupDraftVersion.id,
      offerRuleIds: reordered.map((r) => r.id),
    },
  });
};

export const useRuleManager = (
  trackUpdate: <T>(promise: Promise<T>) => Promise<T>,
  flowId: number
) => {
  const apollo = useApolloClient();

  return {
    addRule: (offerRuleGroupId: number, options?: AddRuleOptions) =>
      trackUpdate(addRule(apollo, offerRuleGroupId, options)),
    moveRule: (
      offerRuleGroupId: number,
      rules: FlowOfferRuleFragment[],
      sourceIndex: number,
      destinationIndex: number
    ) =>
      trackUpdate(
        moveRule(apollo, offerRuleGroupId, rules, sourceIndex, destinationIndex)
      ),
    updateRule: (id: number, options: UpdateRulesOptions) =>
      trackUpdate(updateRule(apollo, id, options)),
    deleteRule: (offerRuleGroupId: number, ruleId: number) =>
      trackUpdate(deleteRule(apollo, offerRuleGroupId, ruleId)),
  };
};

export type RuleManager = ReturnType<typeof useRuleManager>;
