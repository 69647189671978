import { gql, useMutation } from "@apollo/client";
import { nanoid } from "nanoid";

import {
  segment_condition_boolean_operator_enum,
  SegmentConditionFragment,
  UseInsertInlineSegmentsMutation,
  UseInsertInlineSegmentsMutationVariables,
} from "../../../__generated__/graphql";
import { getConditionValueForInsert } from "../../segments/lib";
import RuleInlineSegmentFragment from "../fragments/RuleInlineSegmentFragment";

export default function useInsertInlineSegments() {
  const [insertInlineSegments] = useMutation<
    UseInsertInlineSegmentsMutation,
    UseInsertInlineSegmentsMutationVariables
  >(gql`
    mutation UseInsertInlineSegmentsMutation(
      $objects: [segment_insert_input!]!
    ) {
      insert_segment(objects: $objects) {
        returning {
          ...RuleInlineSegmentFragment
        }
      }
    }
    ${RuleInlineSegmentFragment}
  `);

  return async (conditions: SegmentConditionFragment[]) => {
    const result = await insertInlineSegments({
      variables: {
        objects: conditions.map((c) => ({
          name: nanoid(),
          inline: true,
          primary_segment_condition_group: {
            data: {
              boolean_operator: segment_condition_boolean_operator_enum.and,
              segment_condition_group_entries: {
                data: [
                  {
                    position: 0,
                    entry_segment_condition: {
                      data: {
                        property: c.property,
                        operator: c.operator,
                        value: getConditionValueForInsert(c),
                        property_id: c.property_id,
                      },
                    },
                  },
                ],
              },
            },
          },
        })),
      },
    });

    if (!result.data?.insert_segment) {
      throw new Error("Unable to insert inline segments");
    }

    return result.data.insert_segment.returning;
  };
}
