import parseVideoURL from "./lib/parseVideoURL";
import Loom from "./Loom";
import Vimeo from "./Vimeo";
import Wistia from "./Wistia";
import YouTube from "./YouTube";

interface VideoProps {
  url: string;
  autoPlay: boolean;
}

const Video: React.FunctionComponent<VideoProps> = ({ url, autoPlay }) => {
  const result = parseVideoURL(url);
  if (!result) {
    return null;
  }

  const { provider, videoId } = result;
  if (!videoId) {
    return null;
  }

  switch (provider) {
    case "wistia":
      return <Wistia videoId={videoId} autoPlay={autoPlay} />;

    case "vimeo":
      return <Vimeo videoId={videoId} autoPlay={autoPlay} />;

    case "loom":
      return <Loom videoId={videoId} autoPlay={autoPlay} />;

    case "youtube":
      return <YouTube videoId={videoId} autoPlay={autoPlay} />;

    default:
      return null;
  }
};

export default Video;
