import gql from "graphql-tag";

import FlowDeflectionFragment from "./FlowDeflectionFragment";

export default gql`
  fragment FlowDeflectionRuleRuleFragment on offer_rule_rule {
    id
    offer_rule_id
    deflection_ids
    include_present_no_offer
    weight

    offer_rule_rule_deflections(order_by: { deflection: { title: asc } }) {
      offer_rule_rule_id
      deflection_id
      deflection {
        ...FlowDeflectionFragment
      }
    }
  }
  ${FlowDeflectionFragment}
`;
