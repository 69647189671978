import gql from "graphql-tag";

import SegmentConditionFragment from "./SegmentConditionFragment";

export default gql`
  fragment ChildSegmentConditionGroupFragment on segment_condition_group {
    id
    boolean_operator
    segment_condition_group_entries(order_by: { position: asc }) {
      entry_segment_condition {
        ...SegmentConditionFragment
      }
    }
  }
  ${SegmentConditionFragment}
`;
