import {
  language_enum,
  offer_autopilot_strategy_enum,
  offer_autopilot_success_metric_enum,
  offer_goal_enum,
  offer_pause_subscription_option_type_enum,
  offer_type_enum,
  OfferFormContainerCouponsQuery,
  OfferPlatformEcommerceProductsQuery,
  platform_enum,
} from "../../../__generated__/graphql";
import FormMode from "../../../common/form/FormMode";
import { TranslatedForms } from "../../../common/form/useTranslatableForm";
import ChangePlanOfferForm from "../ChangePlanOfferForm";
import CustomOfferForm from "../CustomOfferForm";
import DowngradeOfferForm from "../DowngradeOfferForm";
import {
  OfferDetailsProvider,
  PauseSubscriptionOfferDetails,
} from "../lib/offerDetails";
import ModifySubscriptionOfferForm from "../ModifySubscriptionOfferForm";
import PauseSubscriptionOfferForm from "../PauseSubscriptionOfferForm";
import ProductSwapOfferForm from "../ProductSwapOfferForm";
import RescheduleOrderOfferForm from "../RescheduleOrderOfferForm";
import TrialExtensionOfferForm from "../TrialExtensionOfferForm";
import UpgradeOfferForm from "../UpgradeOfferForm";
import CouponOfferForm from "./CouponOfferForm";
import {
  ChangePlanOfferFormValues,
  CouponOfferFormValues,
  CustomOfferFormValues,
  DowngradeOfferFormValues,
  ModifySubscriptionOfferFormValues,
  OfferFormValues,
  PauseSubscriptionOfferFormValues,
  ProductSwapOfferFormValues,
  RescheduleOrderOfferFormValues,
  TrialExtensionOfferFormValues,
  UpgradeOfferFormValues,
} from "./types";

export interface RenderOfferFormProps {
  mode: FormMode;
  offerType?: offer_type_enum;
  goal: offer_goal_enum;
  platform: platform_enum;
  tags: string[];
  formValues?: TranslatedForms<OfferFormValues>;
  editingLanguage: language_enum;
  onChange: (values: TranslatedForms<OfferFormValues>) => void;
  onSubmit: (values: TranslatedForms<OfferFormValues>) => void;
  onChangeEditingLanguage: (value: language_enum) => void;
  isSubmitting: boolean;
  isFreeMode: boolean;
  coupons?: OfferFormContainerCouponsQuery["platform_coupon"];
  ecommerceProducts?:
    | OfferPlatformEcommerceProductsQuery["platform_ecommerce_product"];
  strategy?: offer_autopilot_strategy_enum;
  successMetric?: offer_autopilot_success_metric_enum;
}

const renderOfferForm = ({
  mode,
  offerType,
  goal,
  platform,
  tags,
  coupons,
  ecommerceProducts,
  formValues,
  editingLanguage,
  onChange,
  onSubmit,
  onChangeEditingLanguage,
  isSubmitting,
  isFreeMode,
  strategy,
  successMetric,
}: RenderOfferFormProps) => {
  const values = formValues?.[editingLanguage];

  switch (offerType) {
    case "coupon":
      if (values && values.type !== "coupon") {
        throw new Error("formValues is of incorrect type");
      }

      return (
        <OfferDetailsProvider
          offerDetails={{
            type: "coupon",
            couponType: values?.couponType,
            amountOff: values?.amountOff,
            duration: values?.duration,
            durationInterval: values?.durationInterval,
            durationCount: values?.durationCount,
            months: values?.months,
            goal,
          }}
          showPlaceholders
        >
          <CouponOfferForm
            mode={mode}
            onChange={onChange}
            onSubmit={onSubmit}
            initialValues={formValues as TranslatedForms<CouponOfferFormValues>}
            isSubmitting={isSubmitting}
            coupons={coupons}
            tags={tags}
            platform={platform}
            goal={goal}
            isFreeMode={isFreeMode}
            strategy={strategy}
            successMetric={successMetric}
          />
        </OfferDetailsProvider>
      );

    case "change_plan":
      if (values && values.type !== "change_plan") {
        throw new Error("formValues is of incorrect type");
      }

      return (
        <OfferDetailsProvider
          offerDetails={{
            type: "change_plan",
            planId: values?.planId,
            planName: values?.planName,
          }}
          showPlaceholders
        >
          <ChangePlanOfferForm
            mode={mode}
            platform={platform}
            onChange={onChange}
            onSubmit={onSubmit}
            initialValues={
              formValues as TranslatedForms<ChangePlanOfferFormValues>
            }
            onChangeEditingLanguage={onChangeEditingLanguage}
            isSubmitting={isSubmitting}
            tags={tags}
            goal={goal}
          />
        </OfferDetailsProvider>
      );

    case "modify_subscription":
      if (values && values.type !== "modify_subscription") {
        throw new Error("formValues is of incorrect type");
      }

      return (
        <OfferDetailsProvider
          offerDetails={{
            type: "modify_subscription",
          }}
          showPlaceholders
        >
          <ModifySubscriptionOfferForm
            mode={mode}
            platform={platform}
            onChange={onChange}
            onSubmit={onSubmit}
            initialValues={
              formValues as TranslatedForms<ModifySubscriptionOfferFormValues>
            }
            onChangeEditingLanguage={onChangeEditingLanguage}
            isSubmitting={isSubmitting}
            tags={tags}
          />
        </OfferDetailsProvider>
      );

    case "trial_extension":
      if (values && values.type !== "trial_extension") {
        throw new Error("formValues is of incorrect type");
      }

      return (
        <OfferDetailsProvider
          offerDetails={{ type: "trial_extension", days: values?.days }}
          showPlaceholders
        >
          <TrialExtensionOfferForm
            mode={mode}
            platform={platform}
            onChange={onChange}
            onSubmit={onSubmit}
            initialValues={
              formValues as TranslatedForms<TrialExtensionOfferFormValues>
            }
            onChangeEditingLanguage={onChangeEditingLanguage}
            isSubmitting={isSubmitting}
            goal={goal}
            tags={tags}
          />
        </OfferDetailsProvider>
      );

    case "pause_subscription":
      if (values && values.type !== "pause_subscription") {
        throw new Error("formValues is of incorrect type");
      }

      const options: PauseSubscriptionOfferDetails["options"][0][] = [];
      if (values?.option1Present) {
        options.push({
          type:
            values.option1Interval === "date"
              ? offer_pause_subscription_option_type_enum.date
              : offer_pause_subscription_option_type_enum.interval,
          interval:
            values.option1Interval === "date" ? null : values.option1Interval,
          intervalCount: values.option1IntervalCount,
        });
      }
      if (values?.option2Present) {
        options.push({
          type:
            values.option2Interval === "date"
              ? offer_pause_subscription_option_type_enum.date
              : offer_pause_subscription_option_type_enum.interval,
          interval:
            values.option2Interval === "date" ? null : values.option2Interval,
          intervalCount: values.option2IntervalCount,
        });
      }
      if (values?.option3Present) {
        options.push({
          type:
            values.option3Interval === "date"
              ? offer_pause_subscription_option_type_enum.date
              : offer_pause_subscription_option_type_enum.interval,
          interval:
            values.option3Interval === "date" ? null : values.option3Interval,
          intervalCount: values.option3IntervalCount,
        });
      }

      return (
        <OfferDetailsProvider
          offerDetails={{
            type: "pause_subscription",
            options,
          }}
          showPlaceholders
        >
          <PauseSubscriptionOfferForm
            mode={mode}
            platform={platform}
            onChange={onChange}
            onSubmit={onSubmit}
            initialValues={
              formValues as TranslatedForms<PauseSubscriptionOfferFormValues>
            }
            onChangeEditingLanguage={onChangeEditingLanguage}
            isSubmitting={isSubmitting}
            tags={tags}
          />
        </OfferDetailsProvider>
      );

    case "upgrade":
      if (values && values.type !== "upgrade") {
        throw new Error("formValues is of incorrect type");
      }

      return (
        <OfferDetailsProvider
          offerDetails={{
            type: "downgrade",
            planId: values?.planId,
            planName: values?.planName,
          }}
          showPlaceholders
        >
          <UpgradeOfferForm
            mode={mode}
            platform={platform}
            onChange={onChange}
            onSubmit={onSubmit}
            initialValues={
              formValues as TranslatedForms<UpgradeOfferFormValues>
            }
            onChangeEditingLanguage={onChangeEditingLanguage}
            isSubmitting={isSubmitting}
            tags={tags}
          />
        </OfferDetailsProvider>
      );

    case "downgrade":
      if (values && values.type !== "downgrade") {
        throw new Error("formValues is of incorrect type");
      }

      return (
        <OfferDetailsProvider
          offerDetails={{
            type: "downgrade",
            planId: values?.planId,
            planName: values?.planName,
          }}
          showPlaceholders
        >
          <DowngradeOfferForm
            mode={mode}
            platform={platform}
            onChange={onChange}
            onSubmit={onSubmit}
            initialValues={
              formValues as TranslatedForms<DowngradeOfferFormValues>
            }
            onChangeEditingLanguage={onChangeEditingLanguage}
            isSubmitting={isSubmitting}
            tags={tags}
          />
        </OfferDetailsProvider>
      );

    case "product_swap":
      if (values && values.type !== "product_swap") {
        throw new Error("formValues is of incorrect type");
      }

      return (
        <OfferDetailsProvider
          offerDetails={{
            type: "product_swap",
            specificProducts: values?.specificProducts || false,
            products: [],
          }}
        >
          <ProductSwapOfferForm
            mode={mode}
            onChange={onChange}
            onSubmit={onSubmit}
            initialValues={
              formValues as TranslatedForms<ProductSwapOfferFormValues>
            }
            onChangeEditingLanguage={onChangeEditingLanguage}
            isSubmitting={isSubmitting}
            products={ecommerceProducts}
            tags={tags}
            platform={platform}
          />
        </OfferDetailsProvider>
      );

    case "reschedule_order":
      if (values && values.type !== "reschedule_order") {
        throw new Error("formValues is of incorrect type");
      }

      return (
        <OfferDetailsProvider
          offerDetails={{
            type: "reschedule_order",
            skipEnabled: values?.skipEnabled || false,
            rescheduleEnabled: values?.rescheduleEnabled || false,
          }}
        >
          <RescheduleOrderOfferForm
            onChange={onChange}
            onSubmit={onSubmit}
            initialValues={
              formValues as TranslatedForms<RescheduleOrderOfferFormValues>
            }
            onChangeEditingLanguage={onChangeEditingLanguage}
            isSubmitting={isSubmitting}
            tags={tags}
            platform={platform}
            isFreeMode={isFreeMode}
          />
        </OfferDetailsProvider>
      );

    case "custom":
      return (
        <OfferDetailsProvider
          offerDetails={{ type: "custom" }}
          showPlaceholders
        >
          <CustomOfferForm
            onChange={onChange}
            onSubmit={onSubmit}
            initialValues={formValues as TranslatedForms<CustomOfferFormValues>}
            onChangeEditingLanguage={onChangeEditingLanguage}
            isSubmitting={isSubmitting}
            goal={goal}
            tags={tags}
            platform={platform}
          />
        </OfferDetailsProvider>
      );
  }
};

export default renderOfferForm;
