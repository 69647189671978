import { faPencilAlt } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useLayoutEffect, useRef } from "react";
import { createGlobalStyle } from "styled-components";
import tw, { styled } from "twin.macro";

const InlineText = styled.span`
  ${tw`inline-block relative w-full`}

  &:hover {
    .pencil {
      transform: scale(1);
      ${tw`transition-all duration-200`}
      svg {
        opacity: 1;
      }
    }
  }
`;

const PencilWrapper = styled.span<{ shiftLeft: number }>`
  ${tw`absolute flex items-center opacity-0 transition-all duration-200`}
  top: 0;
  left: ${(props) => -26 + props.shiftLeft}px;
  height: 1.5em;
  width: 26px;
`;

const Pencil = styled.span`
  ${tw`
    cursor-pointer transition-all duration-200 ease-in-out
    w-5 h-5 text-white bg-gray-800 rounded-full
    flex items-center justify-center
    shadow
  `}
  font-size: .6rem;
  &.invert {
    ${tw`text-gray-800 bg-white hover:text-white`}
  }
  ${tw`hover:bg-blue-500`}
  &.disabled {
    ${tw`pointer-events-none cursor-default`}
  }
`;

const GlobalStyles = createGlobalStyle<{ hoverContentClass: string }>`
  ${(props) => `
  .${props.hoverContentClass}:hover {
    .${props.hoverContentClass}-pencil {
      opacity: 1;
    }
  }
  `}
`;

interface EditableTextWrapperProps {
  className?: string;
  isEditable: boolean;
  onEdit: React.MouseEventHandler;
  invert?: boolean;
  shiftLeft?: number;
  hoverContentClass?: string;
  isInline?: boolean;
  disabled?: boolean;
}

const EditableTextWrapper: React.FunctionComponent<
  EditableTextWrapperProps
> = ({
  className,
  isEditable,
  invert = false,
  onEdit,
  shiftLeft = 0,
  children,
  hoverContentClass = "inline-text-context",
  isInline = true,
  disabled = false,
}) => {
  const inlineTextRef = useRef<HTMLDivElement>(null);
  const pencilWrapperRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (inlineTextRef.current && pencilWrapperRef.current) {
      const lineHeight = window.getComputedStyle(
        inlineTextRef.current
      ).lineHeight;

      pencilWrapperRef.current.style.height = lineHeight;
    }
  }, []);

  return (
    <>
      <GlobalStyles hoverContentClass={hoverContentClass} />
      {isEditable ? (
        <InlineText ref={inlineTextRef} className={className}>
          {isInline ? children : <div>{children}</div>}
          <PencilWrapper
            className={classNames(hoverContentClass + "-pencil")}
            shiftLeft={shiftLeft}
            ref={pencilWrapperRef}
          >
            <Pencil
              className={classNames(invert && "invert", disabled && "disabled")}
              onClick={onEdit}
            >
              <FontAwesomeIcon icon={faPencilAlt} />
            </Pencil>
          </PencilWrapper>
        </InlineText>
      ) : (
        <span>{children}</span>
      )}
    </>
  );
};

export default EditableTextWrapper;
