import gql from "graphql-tag";

import FlowDeflectionFragment from "./FlowDeflectionFragment";
import FlowOfferGroupFragment from "./FlowOfferGroupFragment";
import FlowOfferRuleOfferAutopilotFragment from "./FlowOfferRuleOfferAutopilotFragment";
import FlowOfferRuleOfferFragment from "./FlowOfferRuleOfferFragment";
import FlowOfferTestFragment from "./FlowOfferTestFragment";

export default gql`
  fragment FlowOfferRuleRuleFragment on offer_rule_rule {
    id
    offer_rule_id
    offer_ids
    offer_test_ids
    offer_group_ids
    offer_autopilot_offer_ids
    deflection_ids
    include_present_no_offer
    weight

    offer_rule_rule_offers(order_by: { offer: { name: asc } }) {
      offer_rule_rule_id
      offer_id
      offer {
        ...FlowOfferRuleOfferFragment
      }
    }

    offer_rule_rule_offer_tests(order_by: { offer_test: { name: asc } }) {
      offer_rule_rule_id
      offer_test_id
      offer_test {
        ...FlowOfferTestFragment
      }
    }

    offer_rule_rule_offer_groups(order_by: { offer_group: { name: asc } }) {
      offer_rule_rule_id
      offer_group_id
      offer_group {
        ...FlowOfferGroupFragment
      }
    }

    offer_rule_rule_offer_autopilot_offers(
      order_by: { offer_autopilot: { offer: { name: asc } } }
    ) {
      offer_rule_rule_id
      offer_id
      offer_autopilot {
        ...FlowOfferRuleOfferAutopilotFragment
      }
    }

    offer_rule_rule_deflections(order_by: { deflection: { title: asc } }) {
      offer_rule_rule_id
      deflection_id
      deflection {
        ...FlowDeflectionFragment
      }
    }
  }
  ${FlowOfferRuleOfferFragment}
  ${FlowOfferTestFragment}
  ${FlowOfferGroupFragment}
  ${FlowOfferRuleOfferAutopilotFragment}
  ${FlowDeflectionFragment}
`;
