export const TRACK_EVENT_ACCOUNT_SETTING_UPDATED = "Account Setting Updated";
export const TRACK_EVENT_FLOW_CREATED = "Flow Created";
export const TRACK_EVENT_FLOW_CUSTOMIZED = "Flow Customized";
export const TRACK_EVENT_FLOW_EMBEDDED = "Flow Embedded";
export const TRACK_EVENT_FLOW_TEST_CREATED = "Flow Test Created";
export const TRACK_EVENT_HUBSPOT_DISABLED = "HubSpot Disabled";
export const TRACK_EVENT_HUBSPOT_INTEGRATED = "HubSpot Integrated";
export const TRACK_EVENT_HUBSPOT_MAPPINGS_SET = "HubSpot Mappings Set";
export const TRACK_EVENT_INSIGHTS_DATA_REQUESTED = "Insights Data Requested";
export const TRACK_EVENT_INTEGRATION_DISABLED = "Integration Disabled";
export const TRACK_EVENT_INTEGRATION_ENABLED = "Integration Enabled";
export const TRACK_EVENT_INTEGRATION_MODIFIED = "Integration Modified";
export const TRACK_EVENT_INTERCOM_DISABLED = "Intercom Disabled";
export const TRACK_EVENT_INTERCOM_INTEGRATED = "Intercom Integrated";
export const TRACK_EVENT_KLAVIYO_DISABLED = "Klaviyo Disabled";
export const TRACK_EVENT_KLAVIYO_INTEGRATED = "Klaviyo Integrated";
export const TRACK_EVENT_OFFER_CREATED = "Offer Created";
export const TRACK_EVENT_OFFER_TEST_CREATED = "Offer Test Created";
export const TRACK_EVENT_PAYMENT_METHOD_ATTACHED = "Payment Method Attached";
export const TRACK_EVENT_PLATFORM_CONNECTED = "Payment Provider Connected";
export const TRACK_EVENT_PLATFORM_CONNECTION_SYNCED =
  "Payment Provider Connection Synced";
export const TRACK_EVENT_SALESFORCE_DISABLED = "Salesforce Disabled";
export const TRACK_EVENT_SALESFORCE_INTEGRATED = "Salesforce Integrated";
export const TRACK_EVENT_SEGMENT_CREATED = "Segment Created";
export const TRACK_EVENT_SEGMENT_INTEGRATED = "Segment Integrated";
export const TRACK_EVENT_SESSION_STARTED = "Session Started";
export const TRACK_EVENT_SESSION_COMPLETED = "Session Completed";
export const TRACK_EVENT_SESSIONS_EXPORTED = "Sessions Exported";
export const TRACK_EVENT_SIGNED_UP = "Signed Up";
export const TRACK_EVENT_SLACK_DISABLED = "Slack Disabled";
export const TRACK_EVENT_SLACK_INTEGRATED = "Slack Integrated";
export const TRACK_EVENT_SUBSCRIBER_CANCELED = "Subscriber Canceled";
export const TRACK_EVENT_SUBSCRIBER_DEFLECTED = "Subscriber Deflected";
export const TRACK_EVENT_SUBSCRIBER_SAVED = "Subscriber Saved";
export const TRACK_EVENT_USER_INITIATED_SESSION_STARTED =
  "User Initiated Session Started";
export const TRACK_EVENT_USER_INVITED = "User Invited";
export const TRACK_EVENT_WEBHOOKS_CONFIGURED = "Webhooks Configured";
export const TRACK_EVENT_ZAPIER_INTEGRATED = "Zapier Integrated";

export type TrackEvent =
  | typeof TRACK_EVENT_ACCOUNT_SETTING_UPDATED
  | typeof TRACK_EVENT_FLOW_CREATED
  | typeof TRACK_EVENT_FLOW_CUSTOMIZED
  | typeof TRACK_EVENT_FLOW_EMBEDDED
  | typeof TRACK_EVENT_FLOW_TEST_CREATED
  | typeof TRACK_EVENT_HUBSPOT_DISABLED
  | typeof TRACK_EVENT_HUBSPOT_INTEGRATED
  | typeof TRACK_EVENT_HUBSPOT_MAPPINGS_SET
  | typeof TRACK_EVENT_INSIGHTS_DATA_REQUESTED
  | typeof TRACK_EVENT_INTEGRATION_DISABLED
  | typeof TRACK_EVENT_INTEGRATION_ENABLED
  | typeof TRACK_EVENT_INTEGRATION_MODIFIED
  | typeof TRACK_EVENT_INTERCOM_DISABLED
  | typeof TRACK_EVENT_INTERCOM_INTEGRATED
  | typeof TRACK_EVENT_KLAVIYO_DISABLED
  | typeof TRACK_EVENT_KLAVIYO_INTEGRATED
  | typeof TRACK_EVENT_OFFER_CREATED
  | typeof TRACK_EVENT_OFFER_TEST_CREATED
  | typeof TRACK_EVENT_PAYMENT_METHOD_ATTACHED
  | typeof TRACK_EVENT_PLATFORM_CONNECTED
  | typeof TRACK_EVENT_PLATFORM_CONNECTION_SYNCED
  | typeof TRACK_EVENT_SALESFORCE_DISABLED
  | typeof TRACK_EVENT_SALESFORCE_INTEGRATED
  | typeof TRACK_EVENT_SEGMENT_CREATED
  | typeof TRACK_EVENT_SEGMENT_INTEGRATED
  | typeof TRACK_EVENT_SESSION_STARTED
  | typeof TRACK_EVENT_SESSION_COMPLETED
  | typeof TRACK_EVENT_SESSIONS_EXPORTED
  | typeof TRACK_EVENT_SIGNED_UP
  | typeof TRACK_EVENT_SLACK_DISABLED
  | typeof TRACK_EVENT_SLACK_INTEGRATED
  | typeof TRACK_EVENT_SUBSCRIBER_CANCELED
  | typeof TRACK_EVENT_SUBSCRIBER_DEFLECTED
  | typeof TRACK_EVENT_SUBSCRIBER_SAVED
  | typeof TRACK_EVENT_USER_INITIATED_SESSION_STARTED
  | typeof TRACK_EVENT_USER_INVITED
  | typeof TRACK_EVENT_WEBHOOKS_CONFIGURED
  | typeof TRACK_EVENT_ZAPIER_INTEGRATED;
