import { gql, useQuery } from "@apollo/client";

import { UseTagsQuery } from "../../__generated__/graphql";

const TheUseTagsQuery = gql`
  query UseTagsQuery {
    tag {
      id
      name
    }
  }
`;

export default function useTags() {
  const { data, loading } = useQuery<UseTagsQuery>(TheUseTagsQuery);

  return { tags: (data?.tag || []).map((t) => t.name), data, loading };
}

export { TheUseTagsQuery as UseTagsQuery };
