import gql from "graphql-tag";

export default gql`
  fragment FlowSubscriberFormQuestionFragment on flow_subscriber_form_question {
    id
    flow_id
    title
    hint
    position
    required
  }
`;
