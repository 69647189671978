import { useState } from "react";

import {
  language_enum,
  TranslationFragment,
} from "../../../__generated__/graphql";
import Button from "../../../common/form/Button";
import FieldHint from "../../../common/form/FieldHint";
import FieldInput from "../../../common/form/FieldInput";
import FieldLabel from "../../../common/form/FieldLabel";
import FieldRow from "../../../common/form/FieldRow";
import FormMode from "../../../common/form/FormMode";
import TextInput from "../../../common/form/input/TextInput";
import useTranslatableForm, {
  TranslatedForms,
} from "../../../common/form/useTranslatableForm";
import Modal, { ModalProps } from "../../../common/modal/Modal";
import ModalFooter from "../../../common/modal/ModalFooter";
import ModalFormBody from "../../../common/modal/ModalFormBody";
import ModalHeader from "../../../common/modal/ModalHeader";
import useFocusFirstEmptyInput from "../../../common/useFocusFirstEmptyInput";
import LanguageRadio from "./LanguageRadio";

type AddRadioOptionModalProps = ModalProps & {
  mode: FormMode;
  language: language_enum;
  enabledLanguages: language_enum[];
  defaultLanguage: language_enum;
  questionId: number;
  titleTranslation?: TranslationFragment;
  reasonCode?: string | null;
  position?: number;
  onClose: () => void;
  onSave: (forms: TranslatedForms<FormValues>) => Promise<void>;
};

export interface FormValues {
  title: string;
  reasonCode: string;
}

const AddRadioOptionModal: React.FunctionComponent<
  AddRadioOptionModalProps
> = ({
  mode,
  language,
  enabledLanguages,
  defaultLanguage,
  questionId,
  titleTranslation,
  reasonCode,
  position,
  onClose,
  onSave,
  ...props
}) => {
  const defaultValues = enabledLanguages.reduce(
    (prev, enabledLanguage) => ({
      ...prev,
      [enabledLanguage]: {
        title:
          titleTranslation?.translation_values.find(
            (value) => value.language === enabledLanguage
          )?.value || "",
        reasonCode,
      },
    }),
    {}
  );

  const { register, handleSubmit, editingLanguage, setEditingLanguage } =
    useTranslatableForm<FormValues>({
      languages: enabledLanguages,
      initialLanguage: language,
      defaultLanguage,
      defaultValues,
      translatableFields: ["title"],
    });
  const [formRef, setFormRef] = useState<HTMLFormElement | null>(null);
  useFocusFirstEmptyInput(formRef);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = handleSubmit(async (forms) => {
    setIsSubmitting(true);
    await onSave(forms);
    onClose();
  });

  return (
    <Modal {...props}>
      <ModalHeader>
        {mode === "create" ? "Add option" : "Edit option"}
      </ModalHeader>
      <ModalFormBody>
        <form
          id="add-option"
          onSubmit={onSubmit}
          ref={(ref) => setFormRef(ref)}
        >
          <fieldset disabled={isSubmitting}>
            {enabledLanguages.length > 1 && (
              <LanguageRadio
                value={editingLanguage}
                languages={enabledLanguages}
                defaultLanguage={defaultLanguage}
                onChange={(language) => setEditingLanguage(language)}
              />
            )}
            <FieldRow>
              <FieldLabel>
                <label htmlFor="title">Title</label>
              </FieldLabel>
              <FieldInput>
                <TextInput
                  {...register("title", { required: true })}
                  id="title"
                  width="lg"
                />
              </FieldInput>
            </FieldRow>
            <FieldRow>
              <FieldLabel>
                <label htmlFor="reasonCode">Reason code</label>
              </FieldLabel>
              <FieldInput>
                <TextInput
                  {...register("reasonCode")}
                  id="reasonCode"
                  width="lg"
                />
                <FieldHint>
                  Optional reason code for use in reporting and third-party
                  integrations.
                </FieldHint>
              </FieldInput>
            </FieldRow>
          </fieldset>
        </form>
      </ModalFormBody>
      <ModalFooter>
        <Button buttonType="primary" form="add-option" isLoading={isSubmitting}>
          {mode === "create" ? "Add" : "Save"}
        </Button>
        <Button buttonType="default" onClick={onClose}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddRadioOptionModal;
