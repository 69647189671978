import gql from "graphql-tag";

import AppPlatformConnectionFragment from "./AppPlatformConnectionFragment";

export default gql`
  fragment ViewerFragment on ViewerOutput {
    id
    defaultRole
    allowedRoles
    firstName
    lastName
    email
    account {
      id
      token
      title
      mode
      test_mode_for_account_id
      default_flow_id
      default_campaign_id
      internal_testing
      customer_portal_enabled
      logo_url
      favicon_url
      created_at
      currency
      revenue_mode
      sso_required
      require_payment_method
      platform_connection {
        ...AppPlatformConnectionFragment
      }
    }
  }
  ${AppPlatformConnectionFragment}
`;
