import { createContext, useContext } from "react";

export interface Settings {
  showSampleData: boolean;
  pollInterval: number;
  enableFeedbackAnalysisReport: boolean;
  toggleFreeMode: boolean;
}

interface SettingsProviderProps {
  settings: Settings;
}

interface SettingsContext {
  settings: Settings;
}

const Context = createContext<SettingsContext | undefined>(undefined);

export const useSettings = () => {
  const context = useContext(Context);
  if (!context) {
    throw new Error("useSettings() not called within SettingsProvider context");
  }

  return context.settings;
};

const SettingsProvider: React.FunctionComponent<SettingsProviderProps> = ({
  settings,
  children,
}) => <Context.Provider value={{ settings }}>{children}</Context.Provider>;

export default SettingsProvider;
