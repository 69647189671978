import gql from "graphql-tag";

export default gql`
  fragment FlowOfferRuleOfferFragment on offer {
    id
    token
    name
    type
  }
`;
