import { nanoid } from "nanoid";
import { useEffect, useState } from "react";

export default function useModal() {
  const [isOpen, setIsOpen] = useState(false);
  const [key, setKey] = useState(nanoid());

  const resetKey = () => {
    window.setTimeout(() => {
      setKey(nanoid());
    }, 200);
  };

  useEffect(() => {
    if (!isOpen) {
      resetKey();
    }
  }, [isOpen]);

  return [isOpen, setIsOpen, key] as const;
}
