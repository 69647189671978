import tw from "twin.macro";

import { CustomerPortalSubscriptionDetailsFragment } from "../../../__generated__/graphql";

interface SubscriptionAmountProps {
  subscriptionDetails: CustomerPortalSubscriptionDetailsFragment;
  big?: boolean;
}

const SubscriptionAmount: React.FunctionComponent<SubscriptionAmountProps> = ({
  subscriptionDetails,
  big,
}) => {
  const amount = subscriptionDetails.amount;
  const currency = subscriptionDetails.currency;
  const interval = subscriptionDetails.interval;
  const intervalCount = subscriptionDetails.intervalCount;

  if (
    !amount ||
    currency !== "usd" ||
    !interval ||
    typeof intervalCount !== "number"
  ) {
    return null;
  }

  if (intervalCount === 1) {
    return (
      <>
        <span css={big ? tw`text-xl font-bold` : undefined}>
          ${Number(amount).toLocaleString()}
        </span>{" "}
        per {interval}
      </>
    );
  }

  return (
    <>
      <span css={big ? tw`text-xl font-bold` : undefined}>${amount}</span> every{" "}
      {intervalCount} {interval}s
    </>
  );
};

export default SubscriptionAmount;
