import gql from "graphql-tag";

import ChildSegmentConditionGroupFragment from "./ChildSegmentConditionGroupFragment";
import SegmentConditionFragment from "./SegmentConditionFragment";

export default gql`
  fragment SegmentConditionGroupEntryFragment on segment_condition_group_entry {
    entry_segment_condition_group {
      ...ChildSegmentConditionGroupFragment
    }
    entry_segment_condition {
      ...SegmentConditionFragment
    }
  }
  ${ChildSegmentConditionGroupFragment}
  ${SegmentConditionFragment}
`;
