import HelpIcon from "../HelpIcon";

interface CardTitleProps {
  help?: string;
}

const CardSmallTitle: React.FunctionComponent<CardTitleProps> = ({
  children,
  help = "",
}) => (
  <div tw="flex-shrink-0 flex items-center">
    <h3 tw="font-title font-bold text-xl">{children}</h3>
    {help && (
      <div tw="ml-2">
        <HelpIcon content={help} />
      </div>
    )}
  </div>
);

export default CardSmallTitle;
