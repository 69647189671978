import { CardComponent } from "@chargebee/chargebee-js-react-wrapper";
import ChargebeeComponents from "@chargebee/chargebee-js-react-wrapper/dist/components/ComponentGroup";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import tw from "twin.macro";

import Button from "../../../common/form/Button";
import TextInput from "../../../common/form/input/TextInput";
import useFocusFirstEmptyInput from "../../../common/useFocusFirstEmptyInput";
import { paymentFormOnComplete } from "./onComplete";

interface FormValues {
  firstName: string;
  lastName: string;
  zip: string;
}

interface ChargebeePaymentFormProps {
  onReady?: () => void;
  onComplete: paymentFormOnComplete;
  onCancel: () => void;
}

const ChargbeePaymentForm: React.FunctionComponent<
  ChargebeePaymentFormProps
> = ({ onReady, onComplete, onCancel }) => {
  const cardRef = useRef<ChargebeeComponents>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [formRef, setFormRef] = useState<HTMLFormElement | null>(null);
  useFocusFirstEmptyInput(formRef);
  const [isFocused, setIsFocused] = useState(false);
  const { register, handleSubmit, formState } = useForm<FormValues>();

  const onSubmit = handleSubmit(async (values) => {
    setIsSubmitting(true);
    setErrorMsg(null);

    if (!cardRef.current) {
      return;
    }

    let result;

    try {
      result = await cardRef.current.tokenize(values);
    } catch (err) {
      const message = (err as any)?.message;
      switch (message) {
        case "Invalid card":
        case "Expired card":
        case "Invalid card verification code (CVC).":
          setErrorMsg(message);
          break;
        default:
          setErrorMsg("Something went wrong with your request.");
      }
    }

    if (result) {
      const { success, errorMessage } = await onComplete(result.token);

      if (!success) {
        setErrorMsg(errorMessage || "Something went wrong with your request.");
      }
    }

    setIsSubmitting(false);
  });

  return (
    <form onSubmit={onSubmit} ref={(ref) => setFormRef(ref)}>
      {errorMsg && <div tw="mb-4 text-red">{errorMsg}</div>}
      <div tw="flex mb-2">
        <TextInput
          {...register("firstName", { required: true })}
          placeholder="First name"
          fieldError={formState.errors.firstName}
          tw="w-full mr-2"
        />
        <TextInput
          {...register("lastName", { required: true })}
          placeholder="Last name"
          fieldError={formState.errors.lastName}
          tw="w-full mr-2"
        />
        <TextInput
          {...register("zip", { required: true })}
          placeholder="ZIP code"
          fieldError={formState.errors.zip}
          tw="w-[8rem]"
        />
      </div>
      <div
        css={[
          tw`rounded border border-gray-200 p-2 pt-[0.65rem] transition-all duration-200`,
          isFocused ? tw`border-blue ring ring-blue-200` : undefined,
        ]}
      >
        <CardComponent
          ref={cardRef}
          onReady={onReady}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
      </div>
      <div tw="mt-6">
        <Button
          buttonType="secondary"
          isLoading={isSubmitting}
          disabled={isSubmitting}
        >
          Submit
        </Button>
        <Button type="button" disabled={isSubmitting} onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </form>
  );
};

export default ChargbeePaymentForm;
