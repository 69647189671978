import classNames from "classnames";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import tw from "twin.macro";

import { FlowSubscriberFormQuestionFragment } from "../../../../__generated__/graphql";
import { CustomSubscriberDetails } from "../lib/types";

interface SubscriberDetailsFormProps {
  onChanged: (
    values: (CustomSubscriberDetails & Record<string, string>) | undefined
  ) => void;
  questions: FlowSubscriberFormQuestionFragment[];
  includeNameAndEmail?: boolean;
  displayErrorMessage?: string;
}

const SubscriberDetailsForm: React.FunctionComponent<
  SubscriberDetailsFormProps
> = ({
  onChanged,
  questions,
  includeNameAndEmail = true,
  displayErrorMessage,
}) => {
  const { register, watch, formState } = useForm<
    CustomSubscriberDetails & Record<string, string>
  >({
    mode: "all",
  });
  const [previousValues, setPreviousValues] = useState<
    CustomSubscriberDetails & Record<string, string>
  >();

  const values = watch();

  useEffect(() => {
    if (!formState.isValid) {
      onChanged(undefined);
      return;
    }

    if (JSON.stringify(previousValues) !== JSON.stringify(values)) {
      setPreviousValues(values);
      onChanged(values);
    }
  }, [formState.isValid, onChanged, previousValues, values]);

  return (
    <>
      <form>
        <h3 className="flow-step__title">
          We just need some basic information about your account.
        </h3>
        <div
          className="flow-form-group"
          css={!includeNameAndEmail ? tw`hidden` : undefined}
        >
          <label className="flow-input-label flow-input-label--required">
            Your First and Last Name
          </label>
          <input
            {...register("name", { required: includeNameAndEmail })}
            className="flow-input-text"
          />
        </div>
        <div
          className="flow-form-group"
          css={!includeNameAndEmail ? tw`hidden` : undefined}
        >
          <label className="flow-input-label flow-input-label--required">
            Your Email Address
          </label>
          <input
            {...register("email", {
              required: includeNameAndEmail,
              pattern: /\S+@\S+\.\S+/,
            })}
            className="flow-input-text"
          />
        </div>
        {questions.map((question) => (
          <div key={question.id} className="flow-form-group">
            <label
              className={classNames({
                "flow-input-label": true,
                "flow-input-label--required": question.required,
                "flow-input-label--optional": !question.required,
              })}
            >
              {question.title}
            </label>
            <input
              {...register(question.id.toString(), {
                required: question.required,
              })}
              className="flow-input-text"
            />
            {question.hint && (
              <div className="flow-input-hint">{question.hint}</div>
            )}
          </div>
        ))}
      </form>
      {!!displayErrorMessage && (
        <div
          className="flow-validation-warning"
          tw="text-lg text-orange-900 border border-orange-200 bg-orange-100 rounded py-4 px-6"
        >
          {displayErrorMessage}
        </div>
      )}
    </>
  );
};

export default SubscriberDetailsForm;
