import gql from "graphql-tag";

import FlowSubscriberFormQuestionFragment from "./FlowSubscriberFormQuestionFragment";
import FlowTextFragment from "./FlowTextFragment";
import FlowVersionFragment from "./FlowVersionFragment";
import TranslationFragment from "./TranslationFragment";

export default gql`
  fragment FlowFragment on flow {
    id
    token
    title
    logo_url
    redirect_uri
    preview_property_values
    account {
      id
      title
      prompt_for_subscriber_details
      intake_form_name_email
      flow_css
      show_branding_feature: account_features(
        where: { key: { _eq: show_branding } }
      ) {
        key
        value
      }
      properties {
        id
        name
        type
        format
      }
    }
    css
    flow_texts {
      ...FlowTextFragment
    }
    default_language
    flow_languages {
      flow_id
      language
    }
    flow_subscriber_form_questions(order_by: { position: asc }) {
      ...FlowSubscriberFormQuestionFragment
    }
    published_version {
      ...FlowVersionFragment
    }
    draft_version @include(if: $includeDrafts) {
      ...FlowVersionFragment
    }
    prevent_if_canceled
    prevent_if_canceled_translation {
      ...TranslationFragment
    }
  }
  ${FlowSubscriberFormQuestionFragment}
  ${FlowTextFragment}
  ${FlowVersionFragment}
  ${TranslationFragment}
`;
