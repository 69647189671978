export default function parseVideoURL(urlString: string) {
  try {
    const url = new URL(urlString);

    if (url.hostname.match(/wistia\.com$/) || url.hostname.match(/wi.st$/)) {
      return {
        provider: "wistia",
        videoId: url.pathname.match(/([^/]+$)/)?.[0],
      };
    }

    if (url.hostname.match(/vimeo\.com$/)) {
      return {
        provider: "vimeo",
        videoId: url.pathname.match(/([^/]+$)/)?.[0],
      };
    }

    if (url.hostname.match(/loom\.com$/)) {
      return {
        provider: "loom",
        videoId: url.pathname.match(/([^/]+$)/)?.[0],
      };
    }

    if (url.hostname.match(/youtube\.com$/)) {
      return {
        provider: "youtube",
        videoId: url.searchParams.get("v"),
      };
    }
  } catch (e) {
    return undefined;
  }
}
