import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_EDITOR,
  createCommand,
} from "lexical";
import { useEffect } from "react";

import { $createVideoNode, VideoNode } from "../nodes/VideoNode";

export interface InsertVideoPayload {
  src: string;
  autoPlay: boolean;
}

export const INSERT_VIDEO_COMMAND = createCommand<InsertVideoPayload>();

const VideoPlugin: React.FunctionComponent = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([VideoNode])) {
      throw new Error("VideoPlugin: VideoNode not registered on editor");
    }

    const removeLister = editor.registerCommand<InsertVideoPayload>(
      INSERT_VIDEO_COMMAND,
      (payload) => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          const focusNode = selection.focus.getNode();
          if (focusNode !== null) {
            const videoNode = $createVideoNode(payload.src, payload.autoPlay);
            selection.focus
              .getNode()
              .getTopLevelElementOrThrow()
              .insertAfter(videoNode);
            const paragraphNode = $createParagraphNode();
            videoNode.insertAfter(paragraphNode);
            paragraphNode.select();
          }
        }
        return true;
      },
      COMMAND_PRIORITY_EDITOR
    );

    return () => {
      removeLister();
    };
  }, [editor]);

  return null;
};

export default VideoPlugin;
