import { gql, useMutation } from "@apollo/client";

import {
  UseTrackEventMutation,
  UseTrackEventMutationVariables,
} from "../__generated__/graphql";
import { TrackEvent } from "../common/track-events/events";

const useTrackEvent = () => {
  const [trackEvent] = useMutation<
    UseTrackEventMutation,
    UseTrackEventMutationVariables
  >(gql`
    mutation UseTrackEventMutation($input: TrackEventInput!) {
      trackEvent(input: $input) {
        success
      }
    }
  `);

  return async (event: TrackEvent, properties: Record<string, any> = {}) => {
    const result = await trackEvent({
      variables: {
        input: {
          event,
          properties: JSON.stringify(properties),
        },
      },
    });

    return !!result.data?.trackEvent.success;
  };
};

export default useTrackEvent;
