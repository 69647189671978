import {
  faAsterisk,
  faCheckSquare,
  faDice,
  faDirections,
  faFont,
  faTrashAlt,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSingleton } from "@tippyjs/react";
import Tippy from "@tippyjs/react";
import classNames from "classnames";
import { useState } from "react";
import { useToasts } from "react-toast-notifications";
import { createGlobalStyle } from "styled-components";
import tw, { styled } from "twin.macro";

import {
  FlowFormFragment,
  question_setting_key_enum,
  question_type_enum,
} from "../../../__generated__/graphql";
import ConfirmationModal from "../../../common/ConfirmationModal";
import BaseButton from "../../../common/form/BaseButton";
import { useTranslations } from "../../../common/translations/TranslationsProvider";
import { useFlowVersion } from "../../public/flow/FlowVersionProvider";
import { FormStepQuestion } from "../../public/flow/lib/types";
import { FormManager } from "./formManager";
import FormQuestionConditionsPanel from "./FormQuestionConditionsPanel";
import { QuestionManager } from "./questionManager";
import StepActionQuestionInfo from "./StepActionQuestionInfo";

const ActionMenu = styled.div`
  ${tw`absolute flex items-center z-10 right-2 opacity-0 transition-opacity duration-200`}
`;

const ButtonBar = tw.div`bg-white shadow-md rounded flex items-center`;

const Button = styled(BaseButton)`
  ${tw`text-sm h-8 w-8 border border-r-0 first:rounded-l last:rounded-r last:border-r !ring-0`}
  &.disabled {
    ${tw`cursor-default`}
  }
`;

const ToggleButton = styled(Button)`
  ${tw`hover:bg-blue-100`}
  &.on {
    ${tw`text-white bg-blue-500/90`}
  }
  &.disabled:not(.on) {
    ${tw`bg-gray-50 text-gray-800/30`}
  }
`;

const ConditionsButton = styled(ToggleButton)`
  ${tw`text-orange-300`}
  &.on {
    ${tw`text-orange-300`}
  }
  &.disabled {
    ${tw`bg-gray-50 text-orange-300/30`}
  }
`;

const DangerButton = styled(Button)`
  ${tw`text-red-500 hover:bg-red-100`}
  &.disabled {
    ${tw`bg-gray-50 text-red-500/30`}
  }
`;

const GlobalStyles = createGlobalStyle<{ hoverContentClass: string }>`
  ${(props) => `
  .${props.hoverContentClass}:hover {
    .${props.hoverContentClass}-action-menu {
      opacity: 1;
    }
  }
  `}
`;

interface FormQuestionActionsProps {
  form: FlowFormFragment;
  formManager: FormManager;
  formStepQuestion: FormStepQuestion;
  questionManager: QuestionManager;
  onDelete: (questionId: number) => void;
  hoverContentClass?: string;
  isFreeMode?: boolean;
}

const FormQuestionActions: React.FunctionComponent<
  FormQuestionActionsProps
> = ({
  form,
  formManager,
  formStepQuestion,
  questionManager,
  onDelete,
  hoverContentClass = "flow-form-question-context",
  isFreeMode = false,
}) => {
  const [source, target] = useSingleton();
  const [confirmDeleteQuestion, setConfirmDeleteQuestion] = useState(false);
  const [questionConditionsPanelIsOpen, setQuestionConditionsPanelIsOpen] =
    useState(false);

  const { translationText } = useTranslations();
  const { getObjectVersion } = useFlowVersion();
  const { addToast } = useToasts();

  const question = formStepQuestion.question;
  const questionVersion = getObjectVersion(question);

  const isRequired = questionVersion?.required;
  const isDeletable = !question?.primary;
  const isConditional = !!formStepQuestion.condition;
  const isEnabled = formStepQuestion.isEnabled;

  const isMultipleChoice = question?.type === question_type_enum.radio;
  const isSelectMultiple = (question?.question_settings || []).some(
    (setting) => {
      if (setting.key === question_setting_key_enum.select_multiple) {
        const settingVersion = getObjectVersion(setting);
        return settingVersion.value;
      }

      return false;
    }
  );
  const isRandomized = (question?.question_settings || []).some((setting) => {
    if (setting.key === question_setting_key_enum.randomize) {
      const settingVersion = getObjectVersion(setting);
      return settingVersion.value;
    }

    return false;
  });
  const isOtherSpecify = (question?.question_settings || []).some((setting) => {
    if (setting.key === question_setting_key_enum.other_specify) {
      const settingVersion = getObjectVersion(setting);
      return settingVersion.value;
    }

    return false;
  });

  return (
    <>
      <GlobalStyles hoverContentClass={hoverContentClass} />
      <Tippy singleton={source} delay={600} placement="bottom" />
      <ActionMenu className={hoverContentClass + "-action-menu"}>
        <StepActionQuestionInfo question={question} tw="bg-white opacity-100" />
        <ButtonBar>
          {(!question?.primary || question?.type === question_type_enum.text) &&
            isEnabled && (
              <Tippy content="Require an answer" singleton={target}>
                <ToggleButton
                  className={!!isRequired ? "on" : undefined}
                  onClick={() => {
                    if (question) {
                      questionManager.setQuestionRequired(
                        question.id,
                        !isRequired
                      );
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faAsterisk} />
                </ToggleButton>
              </Tippy>
            )}
          {isMultipleChoice && isEnabled && (
            <Tippy content="Randomize display of options" singleton={target}>
              <ToggleButton
                className={isRandomized ? "on" : undefined}
                onClick={() => {
                  if (question) {
                    questionManager.setQuestionSetting(
                      question.id,
                      question_setting_key_enum.randomize,
                      !isRandomized
                    );
                  }
                }}
              >
                <FontAwesomeIcon icon={faDice} />
              </ToggleButton>
            </Tippy>
          )}
          {isMultipleChoice &&
            isEnabled &&
            (() => {
              const inUseByRule =
                formStepQuestion.isOtherSpecifyReferencedByOfferRule;

              const inUseByStepCondition =
                !!formStepQuestion.stepConditionsReferencedBy?.some((c) =>
                  c.includeOtherInQuestionIds.includes(question.id)
                );

              const inUseByQuestionCondition =
                !!formStepQuestion.questionConditionsReferencedBy?.some((c) =>
                  c.includeOtherInQuestionIds.includes(question.id)
                );

              const tippyContent = isOtherSpecify
                ? inUseByRule
                  ? 'Cannot remove "Other, please specify" option because it is in use by flow offer rules.'
                  : inUseByStepCondition
                  ? 'Cannot remove "Other, please specify" option because it is referenced by a conditional step.'
                  : inUseByQuestionCondition
                  ? 'Cannot remove "Other, please specify" option it is referenced by another question in this form.'
                  : 'Remove "Other, please specify" option'
                : 'Include "Other, please specify" option';

              const preventToggle =
                isOtherSpecify &&
                (inUseByRule ||
                  inUseByStepCondition ||
                  inUseByQuestionCondition);

              return (
                <Tippy content={tippyContent} singleton={target}>
                  <ToggleButton
                    className={classNames([
                      isOtherSpecify && "on",
                      preventToggle && "disabled",
                    ])}
                    onClick={() => {
                      if (preventToggle) {
                        return;
                      }

                      if (question) {
                        questionManager.setQuestionSetting(
                          question.id,
                          question_setting_key_enum.other_specify,
                          !isOtherSpecify
                        );
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={faFont} />
                  </ToggleButton>
                </Tippy>
              );
            })()}
          {isMultipleChoice && (!question?.primary || isFreeMode) && (
            <Tippy
              content={
                isFreeMode
                  ? "Upgrade to enable selection of multiple options."
                  : "Allow selecting multiple options"
              }
              singleton={target}
            >
              <ToggleButton
                className={classNames(
                  isSelectMultiple && "on",
                  isFreeMode && "disabled"
                )}
                onClick={() => {
                  if (isFreeMode) {
                    return;
                  }
                  if (question) {
                    questionManager.setQuestionSetting(
                      question.id,
                      question_setting_key_enum.select_multiple,
                      !isSelectMultiple
                    );
                  }
                }}
              >
                <FontAwesomeIcon icon={faCheckSquare} />
              </ToggleButton>
            </Tippy>
          )}
          {isEnabled && (!question?.primary || isFreeMode) && (
            <Tippy
              content={
                isFreeMode
                  ? "Upgrade to create question conditions."
                  : "Set question conditions"
              }
              singleton={target}
            >
              <ConditionsButton
                className={classNames(
                  isConditional && "on",
                  isFreeMode && "disabled"
                )}
                onClick={() => {
                  if (isFreeMode) {
                    return;
                  }
                  setQuestionConditionsPanelIsOpen(true);
                }}
              >
                <FontAwesomeIcon icon={faDirections} />
              </ConditionsButton>
            </Tippy>
          )}
          {(() => {
            if (!isDeletable) {
              return null;
            }

            const inUseByRule = formStepQuestion.isReferencedByOfferRule;

            const inUseByStepCondition =
              !!formStepQuestion.stepConditionsReferencedBy?.length;

            const inUseByQuestionCondition =
              !!formStepQuestion.questionConditionsReferencedBy?.length;

            const tippyContent = isFreeMode
              ? "Upgrade to add and delete questions."
              : inUseByRule
              ? "Cannot be removed because it is in use by flow rules."
              : inUseByStepCondition
              ? "Cannot be removed because it is referenced by a conditional step."
              : inUseByQuestionCondition
              ? "Cannot be removed because it is referenced by another question in this form."
              : "Remove question";

            return (
              <Tippy
                content={tippyContent}
                hideOnClick={false}
                singleton={target}
              >
                <DangerButton
                  className={classNames(
                    (inUseByRule ||
                      inUseByStepCondition ||
                      inUseByQuestionCondition ||
                      isFreeMode) &&
                      "disabled"
                  )}
                  onClick={(e) => {
                    if (
                      inUseByRule ||
                      inUseByStepCondition ||
                      inUseByQuestionCondition ||
                      isFreeMode
                    ) {
                      return;
                    }
                    setConfirmDeleteQuestion(true);
                  }}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </DangerButton>
              </Tippy>
            );
          })()}
        </ButtonBar>
      </ActionMenu>
      <FormQuestionConditionsPanel
        isOpen={questionConditionsPanelIsOpen}
        form={form}
        formManager={formManager}
        formStepQuestion={formStepQuestion}
        questionManager={questionManager}
        onClose={() => {
          setQuestionConditionsPanelIsOpen(false);
        }}
      />
      <ConfirmationModal
        isOpen={confirmDeleteQuestion}
        onClose={async (confirmed) => {
          if (confirmed) {
            onDelete(question.id);
            addToast(<div>Question has been removed.</div>, {
              appearance: "info",
            });
          }
          setConfirmDeleteQuestion(false);
        }}
        title="Remove"
        content={
          <>
            Are you sure you want to remove "
            {questionVersion?.title_translation
              ? translationText(questionVersion.title_translation)
              : "this question"}
            "?
          </>
        }
        confirmButtonType="danger"
        confirmText="Remove"
      />
    </>
  );
};

export default FormQuestionActions;
