import { gql, useQuery } from "@apollo/client";

import { MultiplePlanIdsDropdownQuery } from "../../__generated__/graphql";
import MultipleIdsDropdown from "./MultipleIdsDropdown";

interface MultiplePlanIdsDropdownProps {
  value: string[];
  excludeIds?: string[];
  width?: string;
  onChange: (value: string[]) => void;
}

const MultiplePlanIdsDropdown: React.FunctionComponent<
  MultiplePlanIdsDropdownProps
> = ({ value, excludeIds = [], width, onChange }) => {
  const { data, loading } = useQuery<MultiplePlanIdsDropdownQuery>(gql`
    query MultiplePlanIdsDropdownQuery {
      platform_plan {
        platform_id
        name
        code
        platform_product {
          name
        }
      }
    }
  `);

  const ids = (data?.platform_plan || []).map((plan) => ({
    id: plan.platform_id,
    label: plan.name,
    displayId: plan.code || undefined,
    displayLabel: plan.code
      ? `${plan.name} (${plan.platform_id})`
      : !plan.name && !!plan.platform_product?.name
      ? plan.platform_product.name
      : undefined,
  }));

  return (
    <MultipleIdsDropdown
      ids={ids}
      value={value}
      onChange={onChange}
      isLoading={loading}
      placeholder="Select a plan…"
      width={width}
    />
  );
};

export default MultiplePlanIdsDropdown;
