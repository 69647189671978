import { Elements, RecurlyProvider } from "@recurly/react-recurly";
import { useState } from "react";
import { Helmet } from "react-helmet-async";

import Spinner from "../../../common/Spinner";
import { paymentFormOnComplete } from "./onComplete";
import RecurlyPaymentForm from "./RecurlyPaymentForm";

interface RecurlyPaymentProps {
  publicKey: string;
  addressRequirements: string;
  onReady?: () => void;
  onComplete: paymentFormOnComplete;
  onCancel: () => void;
}

const RecurlyPayment: React.FunctionComponent<RecurlyPaymentProps> = ({
  publicKey,
  addressRequirements,
  onReady,
  onComplete,
  onCancel,
}) => {
  const [ready, setReady] = useState(false);

  return (
    <>
      <Helmet
        onChangeClientState={(newState, addedTags) => {
          if (!addedTags.scriptTags) {
            return;
          }

          for (const scriptTag of addedTags.scriptTags) {
            if (scriptTag.src === "https://js.recurly.com/v4/recurly.js") {
              scriptTag.addEventListener("load", () => {
                setReady(true);
              });
            }
          }
        }}
      >
        <script src="https://js.recurly.com/v4/recurly.js" />
        <link rel="stylesheet" href="https://js.recurly.com/v4/recurly.css" />
      </Helmet>
      {ready ? (
        <RecurlyProvider publicKey={publicKey}>
          <Elements>
            <RecurlyPaymentForm
              addressRequirements={addressRequirements}
              onReady={onReady}
              onComplete={onComplete}
              onCancel={onCancel}
            />
          </Elements>
        </RecurlyProvider>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default RecurlyPayment;
