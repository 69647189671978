import { gql, useQuery } from "@apollo/client";

import {
  FlowVersion as FlowVersionEnum,
  SubscriberFlowSegmentMatchesQuery,
  SubscriberFlowSegmentMatchesQueryVariables,
} from "../../__generated__/graphql";
import { PropertyValues } from "../../features/properties/lib/types";
import { FlowVersion } from "./types";

export default function useSubscriberFlowSegmentMatches(
  token: string,
  flowVersion: FlowVersion,
  previewPropertyValues: PropertyValues,
  previewSegmentIds: number[]
) {
  return useQuery<
    SubscriberFlowSegmentMatchesQuery,
    SubscriberFlowSegmentMatchesQueryVariables
  >(
    gql`
      query SubscriberFlowSegmentMatchesQuery(
        $input: SubscriberFlowSegmentMatchesInput!
      ) {
        subscriberFlowSegmentMatches(input: $input) {
          segmentIds
          segmentGroupIds
        }
      }
    `,
    {
      variables: {
        input: {
          token,
          flowVersion:
            flowVersion === "draft"
              ? FlowVersionEnum.draft
              : FlowVersionEnum.published,
          previewPropertyValues,
          previewSegmentIds,
        },
      },
    }
  );
}
