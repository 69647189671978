import { gql, useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import { css } from "twin.macro";

import { defaultContentValues } from "../../../__generated__/editor";
import {
  EligibilityMessageFragment,
  EligibilityMessagePanelCreateEligibilityMessageMutation,
  EligibilityMessagePanelCreateEligibilityMessageMutationVariables,
  EligibilityMessagePanelInitEligibilityMessageContentMutation,
  EligibilityMessagePanelInitEligibilityMessageContentMutationVariables,
  EligibilityMessagePanelQuery,
  EligibilityMessagePanelQueryVariables,
  language_enum,
  translation_value_format_enum,
} from "../../../__generated__/graphql";
import Editor from "../../../common/editor2/Editor";
import { isContentEmpty } from "../../../common/editor2/lib";
import Button from "../../../common/form/Button";
import FieldInput from "../../../common/form/FieldInput";
import FieldLabel from "../../../common/form/FieldLabel";
import FieldRow from "../../../common/form/FieldRow";
import FieldRowBlock from "../../../common/form/FieldRowBlock";
import TextInput from "../../../common/form/input/TextInput";
import useTranslatableForm, {
  TranslatedForms,
} from "../../../common/form/useTranslatableForm";
import FlowPropertyFragment from "../../../common/fragments/FlowPropertyFragment";
import TranslationFragment from "../../../common/fragments/TranslationFragment";
import Panel, { PanelProps } from "../../../common/panel/Panel";
import PanelButtons from "../../../common/panel/PanelButtons";
import PanelFormBody from "../../../common/panel/PanelFormBody";
import PanelTitle from "../../../common/panel/PanelTitle";
import translationValue from "../../../common/translationValue";
import usePrevious from "../../../common/usePrevious";
import useViewer from "../../../common/useViewer";
import { PropertyValuesProvider } from "../../properties/lib/propertyValues";

interface FormValues {
  eligibilityMessageName: string;
  eligibilityMessageContent: string;
}

type EligibilityMessagePanelProps = PanelProps & {
  eligibilityMessageId?: number;
  mode: "create" | "edit";
  onClose: (eligibilityMessageFragment?: EligibilityMessageFragment) => void;
};

const getDefaultValues = (languages: language_enum[]) =>
  languages.reduce<TranslatedForms<FormValues>>(
    (prev, language) => ({
      ...prev,
      [language]: {
        eligibilityMessageName: "",
        eligibilityMessageContent: JSON.stringify(
          defaultContentValues["flow_routes.eligibility_message.default"][
            language
          ]
        ),
      } as FormValues,
    }),
    {}
  );

const EligibilityMessagePanel: React.FunctionComponent<
  EligibilityMessagePanelProps
> = ({
  eligibilityMessageId = undefined,
  mode = "create",
  onClose,
  ...props
}) => {
  const { addToast } = useToasts();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { viewer } = useViewer();

  const prevIsOpen = usePrevious(props.isOpen);

  const { data, loading, refetch } = useQuery<
    EligibilityMessagePanelQuery,
    EligibilityMessagePanelQueryVariables
  >(
    gql`
      query EligibilityMessagePanelQuery($id: Int!) {
        eligibility_message_by_pk(id: $id) {
          id
          name
          eligibility_message_translation {
            ...TranslationFragment
          }
          account {
            properties {
              ...FlowPropertyFragment
            }
          }
        }
      }
      ${TranslationFragment}
      ${FlowPropertyFragment}
    `,
    {
      variables: { id: eligibilityMessageId || 0 },
      fetchPolicy: "cache-and-network",
      skip: !eligibilityMessageId || mode === "create",
    }
  );

  const eligibilityMessage = data?.eligibility_message_by_pk;

  useEffect(() => {
    if (!prevIsOpen && props.isOpen) {
      refetch();
    }
  }, [prevIsOpen, props, refetch]);

  const defaultValues = getDefaultValues([language_enum.en_us]);

  const {
    register,
    forms,
    formState,
    handleSubmit,
    control,
    editingLanguage,
    setValue,
  } = useTranslatableForm<FormValues>({
    defaultValues,
    initialLanguage: language_enum.en_us,
    languages: [language_enum.en_us],
    defaultLanguage: language_enum.en_us,
    translatableFields: ["eligibilityMessageContent"],
  });

  useEffect(() => {
    const eligibilityMessage = data?.eligibility_message_by_pk;
    if (!eligibilityMessage) {
      return;
    }

    setValue("eligibilityMessageName", eligibilityMessage.name);

    const eligibilityMessageValue = translationValue(
      eligibilityMessage.eligibility_message_translation,
      language_enum.en_us,
      language_enum.en_us
    ).value;
    if (!!eligibilityMessageValue) {
      setValue(
        "eligibilityMessageContent",
        JSON.stringify(eligibilityMessageValue),
        { shouldTouch: true }
      );
    }
  }, [data?.eligibility_message_by_pk, setValue]);

  //const values = watch();

  const [initEligibilityMessageTranslation] = useMutation<
    EligibilityMessagePanelInitEligibilityMessageContentMutation,
    EligibilityMessagePanelInitEligibilityMessageContentMutationVariables
  >(gql`
    mutation EligibilityMessagePanelInitEligibilityMessageContentMutation(
      $objects: [translation_insert_input!]!
    ) {
      insert_translation(objects: $objects) {
        returning {
          id
        }
      }
    }
  `);

  const [saveEligibilityMessage] = useMutation<
    EligibilityMessagePanelCreateEligibilityMessageMutation,
    EligibilityMessagePanelCreateEligibilityMessageMutationVariables
  >(gql`
    mutation EligibilityMessagePanelCreateEligibilityMessageMutation(
      $object: eligibility_message_insert_input!
      $translationValues: [translation_value_insert_input!]!
    ) {
      insert_eligibility_message_one(
        object: $object
        on_conflict: {
          constraint: eligibility_message_pkey
          update_columns: [name, eligibility_message_translation_id]
        }
      ) {
        id
        name
        eligibility_message_translation_id
      }

      insert_translation_value(
        objects: $translationValues
        on_conflict: {
          constraint: translation_value_pkey
          update_columns: [value]
        }
      ) {
        returning {
          translation_id
          language
          value
          translation {
            ...TranslationFragment
          }
        }
      }
    }
    ${TranslationFragment}
  `);

  const onSubmit = () =>
    handleSubmit(async (values) => {
      const translatedValues = values[editingLanguage];
      if (!translatedValues) {
        throw new Error("Failed to read form values");
      }

      setIsSubmitting(true);

      const newTranslation =
        translatedValues.eligibilityMessageContent &&
        !eligibilityMessage?.eligibility_message_translation?.id
          ? await initEligibilityMessageTranslation({
              variables: {
                objects: [
                  {
                    translation_values: {
                      data: Object.entries(forms)
                        .map(([language, values]) => ({
                          language: language as language_enum,
                          value: values?.eligibilityMessageContent,
                          format: translation_value_format_enum.text,
                        }))
                        .filter((v) => !!v.value),
                    },
                  },
                ],
              },
            })
          : null;

      const translationId =
        newTranslation?.data?.insert_translation?.returning[0].id ||
        eligibilityMessage?.eligibility_message_translation?.id;

      const savedMessage = await saveEligibilityMessage({
        variables: {
          object: {
            id:
              mode === "edit" && eligibilityMessageId
                ? eligibilityMessageId
                : undefined,
            account_id: viewer?.account?.id,
            name: translatedValues.eligibilityMessageName,
            eligibility_message_translation_id: translationId,
          },
          translationValues: !!translationId
            ? Object.entries(forms)
                .map(([language, values]) => ({
                  translation_id: translationId,
                  language: language as language_enum,
                  value: JSON.parse(values?.eligibilityMessageContent),
                  format: translation_value_format_enum.text,
                }))
                .filter((v) => !!v.value)
            : [],
        },
      });

      addToast(<div>Eligibility Message saved successfully.</div>, {
        appearance: "success",
      });

      if (mode === "edit") {
        onClose();
      } else {
        onClose(savedMessage.data?.insert_eligibility_message_one || undefined);
      }
    });

  const propertyConfig = (eligibilityMessage?.account.properties || []).reduce(
    (prev, current) => ({
      ...prev,
      [current.id]: {
        name: current.name,
        type: current.type,
        numberFormat: current.format,
      },
    }),
    {}
  );

  return (
    <>
      <Panel
        {...props}
        isLoading={loading && !eligibilityMessage}
        width={750}
        header={
          <>
            <PanelTitle>Eligibility message</PanelTitle>
            <PanelButtons>
              <Button
                buttonType="primary"
                form="flow-settings"
                isLoading={isSubmitting}
                onClick={onSubmit()}
              >
                Save
              </Button>
              <Button
                type="button"
                buttonType="default"
                onClick={() => onClose()}
              >
                Cancel
              </Button>
            </PanelButtons>
          </>
        }
      >
        <PanelFormBody>
          <form id="eligibility-message">
            <FieldRow>
              <FieldLabel
                css={css`
                  width: 20%;
                `}
              >
                <label>Name</label>
              </FieldLabel>
              <FieldInput>
                <TextInput
                  {...register("eligibilityMessageName", { required: true })}
                  width="full"
                />
              </FieldInput>
            </FieldRow>
            <FieldRowBlock>
              <FieldLabel
                css={css`
                  width: 20%;
                  padding-top: 0px;
                `}
              >
                <label>Message</label>
              </FieldLabel>

              <PropertyValuesProvider
                propertyValues={{}}
                propertyConfig={propertyConfig}
                showPlaceholders={true}
              >
                <Controller
                  key={editingLanguage}
                  control={control}
                  name="eligibilityMessageContent"
                  render={({ field }) => (
                    <Editor
                      height="10rem"
                      initialValue={
                        forms[editingLanguage]?.eligibilityMessageContent
                      }
                      initialValueKey={editingLanguage}
                      videosEnabled={false}
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                      fieldError={formState.errors.eligibilityMessageContent}
                    />
                  )}
                  rules={{
                    validate: (value) =>
                      !!value && !isContentEmpty(JSON.parse(value))
                        ? true
                        : "Content is required.",
                  }}
                />
              </PropertyValuesProvider>
            </FieldRowBlock>
          </form>
        </PanelFormBody>
      </Panel>
    </>
  );
};

export default EligibilityMessagePanel;
