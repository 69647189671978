import { useMutation } from "@apollo/client";
import gql from "graphql-tag";

import {
  CreateOfferTimerMutation,
  CreateOfferTimerMutationVariables,
} from "../../../__generated__/graphql";

export default function useCreateOfferTimer() {
  const [createOfferTimer] = useMutation<
    CreateOfferTimerMutation,
    CreateOfferTimerMutationVariables
  >(
    gql`
      mutation CreateOfferTimerMutation($object: offer_timer_insert_input!) {
        insert_offer_timer_one(object: $object) {
          offer_id
          offer_timer_mode
          start_time
          end_time
          duration
          show_timer
        }
      }
    `
  );

  return createOfferTimer;
}
