import { Temporal } from "@js-temporal/polyfill";
import { nanoid } from "nanoid";
import { useEffect, useState } from "react";

import {
  FlowOfferGroupFragment,
  FlowSubscriptionFragment,
  PauseReason,
} from "../../../__generated__/graphql";
import { FlowError, FlowText } from "./lib/types";
import OfferGroupContainer from "./OfferGroupContainer";
import FlowModal, { FlowModalProps } from "./ui/FlowModal";

type OfferGroupModalProps = FlowModalProps & {
  offerGroup: FlowOfferGroupFragment | null;
  flowText: FlowText;
  nextOrderDate?: Temporal.PlainDate;
  pauseReasons?: PauseReason[];
  swappableProducts?: FlowSubscriptionFragment | null;
  onAccept: (
    selectedOptionIndex: number,
    offerId: string,
    selectedVariantId: string | null | undefined,
    rescheduleTo: Temporal.PlainDate | null | undefined,
    pauseReasonCode: string | null | undefined,
    pauseAt: Temporal.PlainDate | null | undefined,
    resumeAt: Temporal.PlainDate | null | undefined
  ) => void;
  onDecline: () => void;
  isFinalStep: boolean;
  previewMode?: boolean;
  onImageLoad?: () => void;
  onClose?: () => void;
  showCloseX?: boolean;
  error?: FlowError;
};

const OfferGroupModal: React.FunctionComponent<OfferGroupModalProps> = ({
  offerGroup,
  flowText,
  pauseReasons,
  swappableProducts,
  nextOrderDate,
  onAccept,
  onDecline,
  previewMode = false,
  isFinalStep,
  onImageLoad,
  onClose = () => {},
  showCloseX = false,
  error,
  ...props
}) => {
  const [offerButtonsKey, setOfferButtonsKey] = useState<string>();

  useEffect(() => {
    if (props.isOpen) {
      setOfferButtonsKey(nanoid());
    }
  }, [props.isOpen]);

  return (
    <FlowModal addContainerClass="flow-modal__container--group" {...props}>
      {!!offerGroup ? (
        <OfferGroupContainer
          offerGroup={offerGroup}
          previewMode={previewMode}
          onImageLoad={onImageLoad}
          baseClassName="flow-modal"
          offerButtonsKey={offerButtonsKey}
          flowText={flowText}
          onAccept={onAccept}
          onDecline={onDecline}
          isFinalStep={isFinalStep}
          showCloseX={showCloseX}
          showDeclineButton={false}
          pauseReasons={pauseReasons}
          swappableProducts={swappableProducts}
          nextOrderDate={nextOrderDate}
          error={error}
        />
      ) : null}
    </FlowModal>
  );
};

export default OfferGroupModal;
