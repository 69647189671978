import { ContentEditable as LexicalContentEditable } from "@lexical/react/LexicalContentEditable";
import { css } from "twin.macro";

interface ContentEditableProps {
  isInline: boolean;
  isEditable?: boolean;
  fontSize?: string;
  height?: string;
}

const ContentEditable: React.FunctionComponent<ContentEditableProps> = ({
  isInline,
  isEditable,
  fontSize,
  height,
}) => (
  <LexicalContentEditable
    css={css`
      background-color: ${!isEditable ? "transparent" : "white"};
      height: ${height ? height : !isEditable || isInline ? "auto" : "24rem"};
      overflow-x: hidden;
      overflow-y: auto;
      border: 0;
      resize: none;
      cursor: ${isEditable ? "text" : "inherit"};
      font-size: ${fontSize || "16px"};
      caret-color: rgb(5, 5, 5);
      display: ${isInline && !isEditable ? "inline" : "block"};
      position: relative;
      tab-size: 1;
      outline: 0;
      padding: ${!isEditable ? "0" : "10px"};
    `}
  />
);

export default ContentEditable;
