import gql from "graphql-tag";

export default gql`
  fragment AppPlatformConnectionFragment on platform_connection {
    id
    platform
    platform_id
    import_started_at
    import_completed_at
    meta
    publishable_key
    platform_connection_shopify_store {
      store
      client_id
      install_link
    }
  }
`;
