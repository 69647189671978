import gql from "graphql-tag";

import TranslationFragment from "./TranslationFragment";

export default gql`
  fragment FlowTextVersionFragment on flow_text_version {
    id
    flow_id
    key
    translation {
      ...TranslationFragment
    }
  }
  ${TranslationFragment}
`;
