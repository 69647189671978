import { faCircle, faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import { useState } from "react";
import tw, { styled, theme } from "twin.macro";

import { FlowQuestionFragment } from "../../../__generated__/graphql";
import CopyButton from "../../../common/CopyButton";

interface StepActionQuestionInfoProps {
  question: FlowQuestionFragment;
}

const Info = tw.div`mr-3`;

const Menu = styled.div`
  ${tw`text-base py-3 px-4`}
  width: 20rem;
`;

const StepActionQuestionInfo: React.FunctionComponent<
  StepActionQuestionInfoProps
> = ({ question }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Info>
      <Tippy
        content={
          <Menu>
            <span tw="text-gray-600">Question ID</span>
            <br />
            {question.token} <CopyButton value={question.token} />
            {question.primary && (
              <div tw="mt-2">
                <span tw="text-type-light">
                  This is a primary question and cannot be deleted.
                </span>
              </div>
            )}
          </Menu>
        }
        placement="bottom"
        visible={isOpen}
        interactive={true}
        theme="light"
        className="dropdown-menu"
        offset={[0, 3]}
        onClickOutside={() => setIsOpen(false)}
      >
        <span className="fa-layers fa-fw">
          <FontAwesomeIcon icon={faCircle} color="white" size="2x" />
          <FontAwesomeIcon
            icon={faInfoCircle}
            color={theme`colors.type.light`}
            onClick={() => setIsOpen(!isOpen)}
            style={{ cursor: "pointer" }}
          />
        </span>
      </Tippy>
    </Info>
  );
};

export default StepActionQuestionInfo;
