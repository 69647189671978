import gql from "graphql-tag";

import FlowAcknowledgementGroupFragment from "./FlowAcknowledgementGroupFragment";
import FlowConfirmationFragment from "./FlowConfirmationFragment";
import FlowDeflectionFragment from "./FlowDeflectionFragment";
import FlowDeflectionRuleGroupFragment from "./FlowDeflectionRuleGroupFragment";
import FlowFormFragment from "./FlowFormFragment";
import FlowInterventionFragment from "./FlowInterventionFragment";
import FlowQuestionFragment from "./FlowQuestionFragment";
import FlowStepVersionFragment from "./FlowStepVersionFragment";

export default gql`
  fragment FlowStepFragment on flow_step {
    id
    token
    type
    published_version {
      ...FlowStepVersionFragment
    }
    draft_version @include(if: $includeDrafts) {
      ...FlowStepVersionFragment
    }
    flow_step_acknowledgement_group {
      flow_step_id
      acknowledgement_group_id
      acknowledgement_group {
        ...FlowAcknowledgementGroupFragment
      }
    }
    flow_step_form {
      flow_step_id
      form_id
      form {
        ...FlowFormFragment
      }
    }
    flow_step_question {
      flow_step_id
      question_id
      question {
        ...FlowQuestionFragment
      }
    }
    flow_step_deflection_rule_group {
      flow_step_id
      offer_rule_group_id
      offer_rule_group {
        ...FlowDeflectionRuleGroupFragment
      }
    }
    flow_step_offer_rule_group {
      flow_step_id
      offer_rule_group_id
      offer_rule_group {
        id
        title
      }
    }
    flow_step_rule_group {
      flow_step_id
      offer_rule_group_id
      offer_rule_group {
        id
        title
      }
    }
    flow_step_confirmation {
      flow_step_id
      confirmation_id
      confirmation {
        ...FlowConfirmationFragment
      }
    }
    flow_step_intervention {
      flow_step_id
      intervention_id
      intervention {
        ...FlowInterventionFragment
      }
    }
  }
  ${FlowStepVersionFragment}
  ${FlowAcknowledgementGroupFragment}
  ${FlowFormFragment}
  ${FlowQuestionFragment}
  ${FlowDeflectionFragment}
  ${FlowConfirmationFragment}
  ${FlowInterventionFragment}
  ${FlowDeflectionRuleGroupFragment}
`;
