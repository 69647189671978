import { createContext, useContext } from "react";

import getCampaignObjectVersion from "../../../common/campaign/getCampaignObjectVersion";
import {
  CampaignVersion,
  CampaignVersionedObject,
} from "../../../common/campaign/types";

interface CampaignVersionProviderProps {
  version: CampaignVersion;
}

interface CampaignVersionContext {
  version: CampaignVersion;
  getObjectVersion: <T>(object: CampaignVersionedObject<T>) => T;
}

const Context = createContext<CampaignVersionContext | undefined>(undefined);

export const useCampaignVersion = () => {
  const context = useContext(Context);
  if (!context) {
    throw new Error(
      "useVersion() not called within CampaignVersionProvider context"
    );
  }

  return context;
};

const CampaignVersionProvider: React.FunctionComponent<
  CampaignVersionProviderProps
> = ({ version, children }) => {
  const getObjectVersion = <T,>(object: CampaignVersionedObject<T>): T =>
    getCampaignObjectVersion(object, version);

  return (
    <Context.Provider value={{ version, getObjectVersion }}>
      {children}
    </Context.Provider>
  );
};

export default CampaignVersionProvider;
