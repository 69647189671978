import gql from "graphql-tag";

import FlowOfferAutopilotRoundFragment from "./FlowOfferAutopilotRoundFragment";
import FlowOfferRuleOfferFragment from "./FlowOfferRuleOfferFragment";
import FlowOfferVariantFragment from "./FlowOfferVariantFragment";

export default gql`
  fragment FlowOfferRuleOfferAutopilotFragment on offer_autopilot {
    offer_id
    offer {
      ...FlowOfferRuleOfferFragment
    }
    current_round {
      ...FlowOfferAutopilotRoundFragment
    }
    winning_variant {
      ...FlowOfferVariantFragment
    }
    offer_autopilot_offer_autopilot_rounds(order_by: { round_index: desc }) {
      offer_autopilot_round_id
      round_index
      offer_autopilot_round {
        ...FlowOfferAutopilotRoundFragment
      }
    }
  }
  ${FlowOfferRuleOfferFragment}
  ${FlowOfferAutopilotRoundFragment}
  ${FlowOfferVariantFragment}
`;
