import {
  faCheckCircle,
  faExclamationTriangle,
  faInfoCircle,
  faTimesCircle,
  faXmark,
  IconDefinition,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { ToastProps } from "react-toast-notifications";
import { AppearanceTypes, Placement } from "react-toast-notifications";
import tw, { styled, theme } from "twin.macro";

interface Appearance {
  bg: string;
  icon: IconDefinition;
  iconColor: string;
  textColor: string;
  borderColor: string;
}

interface Appearances {
  success: Appearance;
  error: Appearance;
  warning: Appearance;
  info: Appearance;
}

const appearances: Appearances = {
  success: {
    bg: theme`colors.green.50`,
    icon: faCheckCircle,
    iconColor: theme`colors.green.500`,
    textColor: theme`colors.green.800`,
    borderColor: theme`colors.green.200`,
  },
  error: {
    bg: theme`colors.red.50`,
    icon: faTimesCircle,
    iconColor: theme`colors.red.500`,
    textColor: theme`colors.red.800`,
    borderColor: theme`colors.red.100`,
  },
  warning: {
    bg: theme`colors.yellow.50`,
    icon: faExclamationTriangle,
    iconColor: theme`colors.yellow.500`,
    textColor: theme`colors.yellow.800`,
    borderColor: theme`colors.yellow.200`,
  },
  info: {
    bg: theme`colors.gray.50`,
    icon: faInfoCircle,
    iconColor: theme`colors.gray.400`,
    textColor: theme`colors.gray.700`,
    borderColor: theme`colors.gray.200`,
  },
};

const getAppearance = (name: AppearanceTypes): Appearance => appearances[name];

const ToastWrapper = styled.div`
  transition: height 200ms;
  overflow: hidden;
`;

const StyledToast = styled.div<{
  appearance: AppearanceTypes;
  placement: Placement;
}>`
  ${tw`flex items-stretch rounded-md shadow relative overflow-hidden border py-1 min-w-[400px]`}
  ${(props) => props.placement === "top-right" && tw`mr-4`}

  color: ${(props) => getAppearance(props.appearance).textColor};
  background-color: ${(props) => getAppearance(props.appearance).bg};
  border-color: ${(props) => getAppearance(props.appearance).borderColor};

  &.entering {
    opacity: 0;
    transform: ${(props) =>
      props.placement === "top-center"
        ? "translate3d(0, -120%, 0)"
        : props.placement === "bottom-center"
        ? "translate3d(0, 120%, 0)"
        : props.placement === "top-left"
        ? "translate3d(-120%, 0, 0)"
        : props.placement === "top-right"
        ? "translate3d(120%, 0, 0)"
        : "translate3d(120%, 0, 0)"};
  }
  &.entered {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: opacity 200ms, transform 200ms;
  }
  &.exiting {
    opacity: 0;
    transition: opacity 200ms;
  }
  &.exited {
    opacity: 0;
  }
`;

const Icon = styled.div<{ appearance: AppearanceTypes }>`
  ${tw`pl-4 text-center text-white flex items-center justify-center`}
  color: ${(props) => getAppearance(props.appearance).iconColor};
`;

const Message = tw.div`p-2 pl-3 pr-0 relative font-semibold`;

const CloseButton = styled.button<{ appearance: AppearanceTypes }>`
  ${tw`ml-auto px-4 active:outline-none focus:outline-none opacity-50 hover:opacity-100 transition-opacity duration-150`}
  color: ${(props) => getAppearance(props.appearance).iconColor};
`;

const gutter = 6;

const DefaultToast: React.FunctionComponent<ToastProps> = ({
  appearance,
  placement,
  onDismiss,
  transitionState,
  children,
}) => {
  const [height, setHeight] = useState("auto");
  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (transitionState === "entered") {
      const el = elementRef.current;
      if (el) {
        setHeight(String(el.offsetHeight + gutter) + "px");
      }
    }
    if (transitionState === "exiting") {
      setHeight("0");
    }
  }, [transitionState]);

  return (
    <ToastWrapper ref={elementRef} style={{ height }}>
      <StyledToast
        appearance={appearance}
        placement={placement}
        className={transitionState}
      >
        <Icon appearance={appearance}>
          <FontAwesomeIcon icon={getAppearance(appearance).icon} />
        </Icon>
        <Message>{children}</Message>
        <CloseButton appearance={appearance} onClick={() => onDismiss()}>
          <FontAwesomeIcon icon={faXmark} />
        </CloseButton>
      </StyledToast>
    </ToastWrapper>
  );
};

export default DefaultToast;
