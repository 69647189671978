import gql from "graphql-tag";

import FlowQuestionFragment from "./FlowQuestionFragment";
import TranslationFragment from "./TranslationFragment";

export default gql`
  fragment FlowFormVersionFragment on form_version {
    id
    form_id
    question_ids
    form_version_questions(order_by: { position: asc }) {
      question_id
      form_version_id
      question {
        ...FlowQuestionFragment
      }
    }
  }
  ${TranslationFragment}
  ${FlowQuestionFragment}
`;
