import { DecoratorNode, EditorConfig, LexicalNode } from "lexical";
import { ReactNode } from "react";
import tw from "twin.macro";

import { language_enum } from "../../../__generated__/graphql";
import { useOfferDetails } from "../../../features/offers/lib/offerDetails";
import { renderOfferTag } from "../../editor/lib/OfferTagPlugin";

export interface SerializedOfferDescriptionNode {
  type: "offer_description";
  version: number;
}

const OfferDescriptionComponent: React.FunctionComponent = () => {
  const { offerDetails, showPlaceholders } = useOfferDetails();

  return (
    <span
      css={
        showPlaceholders
          ? tw`bg-gray-100 px-1 rounded border border-gray-100 whitespace-nowrap`
          : undefined
      }
      className="highlight"
    >
      {renderOfferTag(offerDetails, "description", language_enum.en_us)}
    </span>
  );
};

export class OfferDescriptionNode extends DecoratorNode<ReactNode> {
  static getType() {
    return "offer_description";
  }

  static clone(node: OfferDescriptionNode) {
    return new OfferDescriptionNode(node.__key);
  }

  createDOM(config: EditorConfig) {
    const el = document.createElement("span");
    const theme = config.theme;
    const className = theme.property;
    if (typeof className === "string") {
      el.className = className;
    }
    return el;
  }

  updateDOM() {
    return false;
  }

  decorate() {
    return <OfferDescriptionComponent />;
  }

  static importJSON(serializedNode: SerializedOfferDescriptionNode) {
    return $createOfferDescriptionNode();
  }

  exportJSON(): SerializedOfferDescriptionNode {
    return {
      type: "offer_description",
      version: 1,
    };
  }

  isInline(): boolean {
    return true;
  }
}

const $createOfferDescriptionNode = () => new OfferDescriptionNode();

const $isOfferDescriptionNode = (
  node?: LexicalNode
): node is OfferDescriptionNode => node instanceof OfferDescriptionNode;

export { $createOfferDescriptionNode, $isOfferDescriptionNode };
