import gql from "graphql-tag";

import FlowDeflectionRuleVersionFragment from "./FlowDeflectionRuleVersionFragment";

export default gql`
  fragment FlowDeflectionRuleFragment on offer_rule {
    id
    published_version {
      ...FlowDeflectionRuleVersionFragment
    }
    draft_version @include(if: $includeDrafts) {
      ...FlowDeflectionRuleVersionFragment
    }
  }
  ${FlowDeflectionRuleVersionFragment}
`;
