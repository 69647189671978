import gql from "graphql-tag";

import FlowOfferVariantFragment from "./FlowOfferVariantFragment";

export default gql`
  fragment FlowOfferAutopilotRoundFragment on offer_autopilot_round {
    id
    offer_id
    control_weight
    offer_autopilot_round_offer_variants {
      offer_autopilot_round_id
      offer_variant_id
      offer_variant {
        ...FlowOfferVariantFragment
      }
    }
    control_variant_id
    winning_variant_id
  }
  ${FlowOfferVariantFragment}
`;
