import { gql, useQuery } from "@apollo/client";

import { ProductIdDropdownQuery } from "../../__generated__/graphql";
import IdDropdown from "../../common/IdDropdown";

interface ProductIdDropdownProps {
  value: string;
  placeholder?: string;
  width?: string;
  onChange: (value: string) => void;
}

const ProductIdDropdown: React.FunctionComponent<ProductIdDropdownProps> = ({
  value,
  placeholder = "Select a product…",
  width,
  onChange,
}) => {
  const { data, loading } = useQuery<ProductIdDropdownQuery>(gql`
    query ProductIdDropdownQuery {
      platform_product {
        platform_id
        name
      }
    }
  `);

  const ids = (data?.platform_product || []).map((product) => ({
    id: product.platform_id,
    label: product.name,
  }));

  return (
    <IdDropdown
      ids={ids}
      value={value}
      onChange={onChange}
      isLoading={loading}
      width={width}
      placeholder={placeholder}
    />
  );
};

export default ProductIdDropdown;
