import gql from "graphql-tag";

export default gql`
  fragment FlowSegmentGroupFragment on segment_group {
    id
    name
    segment_group_segments {
      operator
      segment {
        id
        name
      }
    }
  }
`;
