import { useLayoutEffect } from "react";

import { CampaignOfferFragment } from "../../../__generated__/graphql";
import DefaultStyles from "../flow/DefaultStyles";
import { FlowText } from "../flow/lib/types";
import OfferModal from "../flow/OfferModal";
import { CampaignText } from "./lib/types";

interface CampaignContentProps {
  campaignText: CampaignText;
  offer: CampaignOfferFragment | null;
  onAcceptOffer: () => void;
  onDeclineOffer: () => void;
  onDismissOffer: () => void;
  cssValue: string;
  startTimerFrom: Date | null;
}

const CampaignComponent: React.FunctionComponent<CampaignContentProps> = ({
  campaignText,
  offer,
  onAcceptOffer,
  onDeclineOffer,
  onDismissOffer,
  cssValue,
  startTimerFrom = null,
}) => {
  useLayoutEffect(() => {
    const els = document.querySelectorAll("style[data-flow-css]");
    for (const el of els) {
      document.head.removeChild(el);
    }
    const style = document.createElement("style");
    style.type = "text/css";
    style.textContent = cssValue;
    style.setAttribute("data-flow-css", "1");
    document.head.appendChild(style);
  }, [cssValue]);

  return (
    <>
      <DefaultStyles
        isEditMode={false}
        modal={true}
        fullScreen={offer?.style === "banner"}
        showOfferTimer={!!offer?.offer_timer?.show_timer}
      />
      <OfferModal
        isOpen={true}
        flowText={campaignText as FlowText}
        offer={offer}
        onAccept={onAcceptOffer}
        onDecline={onDeclineOffer}
        isFinalStep={true}
        showCloseX={true}
        portalContainer={document.getElementById("root")!}
        showOverlay={false}
        onDismiss={onDismissOffer}
        startTimerFrom={startTimerFrom}
      />
    </>
  );
};

export default CampaignComponent;
