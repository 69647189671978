import classNames from "classnames";
import { nanoid } from "nanoid";
import { useMemo } from "react";

import { PauseReason } from "../../../__generated__/graphql";

interface PauseReasonFormProps {
  pauseReasons: PauseReason[];
  value: string | undefined;
  hasMultipleOptions: boolean;
  onChange: (value: string) => void;
}

const PauseReasonForm: React.FunctionComponent<PauseReasonFormProps> = ({
  pauseReasons,
  value,
  hasMultipleOptions,
  onChange,
}) => {
  const uniqueKey = useMemo(() => nanoid(), []);

  return (
    <div
      className={classNames({
        "flow-pause__reason": true,
        "flow-pause__reason--active": !!value && hasMultipleOptions,
      })}
    >
      <label className="flow-pause__reason__label">Pause reason</label>
      <div className="flow-pause__reason__reasons">
        {pauseReasons.map((r) => (
          <div key={r.code} className="flow-pause__reason__reason">
            <input
              className="flow-pause__reason__reason-radio"
              type="radio"
              id={`pause-reason-${uniqueKey}-${r.code}`}
              value={r.code}
              checked={value === r.code}
              onChange={(e) => onChange(e.currentTarget.value)}
            />
            <label
              className="flow-pause__reason__reason-label"
              htmlFor={`pause-reason-${uniqueKey}-${r.code}`}
            >
              {r.reason}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PauseReasonForm;
