import { gql, useMutation, useQuery } from "@apollo/client";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cloneDeep, isEqual } from "lodash";
import { nanoid } from "nanoid";
import { useEffect, useMemo, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { Node } from "slate";
import { isPresent } from "ts-is-present";
import tw, { styled } from "twin.macro";

import { defaultContentValues } from "../../__generated__/editor";
import {
  AppDeflectionFragment,
  AppPropertyFragment,
  DeflectionPanelCreateDeflectionMutation,
  DeflectionPanelCreateDeflectionMutationVariables,
  DeflectionPanelPropertiesQuery,
  DeflectionPanelQuery,
  DeflectionPanelQueryVariables,
  DeflectionPanelUpdateDeflectionMutation,
  DeflectionPanelUpdateDeflectionMutationVariables,
  language_enum,
} from "../../__generated__/graphql";
import Button from "../../common/form/Button";
import FormMode from "../../common/form/FormMode";
import { TranslatedForms } from "../../common/form/useTranslatableForm";
import TheAppDeflectionFragment from "../../common/fragments/AppDeflectionFragment";
import TheAppPropertyFragment from "../../common/fragments/AppPropertyFragment";
import ModalClose from "../../common/modal/ModalClose";
import useUpdateTags from "../../common/mutations/useUpdateTags";
import Panel from "../../common/panel/Panel";
import PanelButtons from "../../common/panel/PanelButtons";
import PanelFormBody from "../../common/panel/PanelFormBody";
import PanelHeader from "../../common/panel/PanelHeader";
import PanelTitle from "../../common/panel/PanelTitle";
import useTags from "../../common/tags/useTags";
import TranslationsProvider from "../../common/translations/TranslationsProvider";
import translationValue from "../../common/translationValue";
import useAccountFeatures from "../../common/useAccountFeatures";
import useLoading from "../../common/useLoading";
import useViewer from "../../common/useViewer";
import { PropertyValuesProvider } from "../properties/lib/propertyValues";
import { PropertyConfig } from "../properties/lib/types";
import PropertyPanel from "../properties/PropertyPanel";
import DefaultStyles from "../public/flow/DefaultStyles";
import { useUpsell } from "../upgrade-account/UpsellProvider";
import DeflectionForm, { DeflectionFormValues } from "./DeflectionForm";
import DeflectionPreview from "./DeflectionPreview";

const previewWidth = 560;
const previewTransition = 150;

const Container = tw.div`flex h-full`;
const FormContainer = styled.div`
  ${tw`flex flex-col`}
  width: 450px;
`;
const Preview = styled.div`
  ${tw`bg-gray-100 h-full z-50 grow`}
  width: ${previewWidth}px;
`;
const PreviewHeader = styled(PanelHeader)`
  height: 59px;
  ${tw`bg-gray-100 text-sm flex items-center`}
`;
const PreviewBody = styled.div`
  ${tw`px-6 py-8`}
`;

const PreviewTransitionWrapper = styled.div`
  &.preview-enter {
    opacity: 0;
  }
  &.preview-exit {
    opacity: 1;
  }
  &.preview-enter-active {
    opacity: 1;
  }
  &.preview-exit-active {
    opacity: 0;
  }
  &.preview-enter-active,
  &.preview-exit-active {
    transition: opacity ${previewTransition}ms;
  }
`;
const PreviewHint = tw.div`mt-8`;

const HelpContainer = tw.div`text-gray-800 pb-5 flex`;
const HelpIcon = tw.div`mr-2`;
const HelpText = tw.div``;

const mapFormValues = (
  formValues: Partial<DeflectionFormValues>,
  deflection: AppDeflectionFragment,
  enabledLanguages: language_enum[]
): TranslatedForms<DeflectionFormValues> =>
  enabledLanguages.reduce<TranslatedForms<DeflectionFormValues>>(
    (prev, language) => {
      const heading = translationValue(
        deflection.heading_translation,
        language,
        language
      );
      const content = translationValue(
        deflection.content_translation,
        language,
        language
      );

      const getStringifiedValue = (value: any, defaultValue: any) =>
        value
          ? JSON.stringify(value)
          : defaultValue
          ? JSON.stringify(defaultValue)
          : "";

      return {
        ...prev,
        [language]: {
          ...formValues,
          heading: getStringifiedValue(
            heading.value,
            defaultContentValues["deflection.heading.default"][language]
          ),
          headingFormat: heading.format,
          content: getStringifiedValue(
            content.value,
            defaultContentValues["deflection.content.default"][language]
          ),
          contentFormat: content.format,
          snapshotItems: deflection.deflection_snapshot_items
            .filter((dsi) => !!dsi.deflection_snapshot_item)
            .map((dsi) => {
              const item = dsi.deflection_snapshot_item!;

              const itemText = translationValue(
                item.text_translation,
                language,
                language
              );
              return {
                key: nanoid(),
                icon:
                  item.icon_name && item.icon_prefix
                    ? JSON.stringify({
                        name: item.icon_name,
                        prefix: item.icon_prefix,
                      })
                    : undefined,
                text: getStringifiedValue(
                  itemText.value,
                  defaultContentValues["deflection_snapshot_item.text.default"][
                    language
                  ]
                ),
                textFormat: itemText.format,
                dateFormat: item.date_format,
                propertyId: item.property_id,
                minimumValue: JSON.stringify(item.property_condition_value),
              };
            }),
          flowActions: deflection.deflection_flow_actions
            .filter((dfa) => !!dfa.flow_action)
            .map((dfa) => {
              const flowAction = dfa.flow_action!;
              const actionText = translationValue(
                flowAction.text_translation,
                language,
                language
              );
              return {
                key: nanoid(),
                token: flowAction.token,
                code: flowAction.code,
                type: flowAction.type,
                appearance: flowAction.appearance,
                text: getStringifiedValue(
                  actionText.value,
                  defaultContentValues["flow_action.text.default"][language]
                ),
                textFormat: actionText.format,
                url: flowAction.url,
                rerouteToFlowId: flowAction.reroute_to_flow_id,
              };
            }),
        },
      };
    },
    {}
  );

const getInitialFormValues = (
  deflection: AppDeflectionFragment,
  enabledLanguages: language_enum[]
): TranslatedForms<DeflectionFormValues> =>
  mapFormValues(
    {
      name: deflection.title,
      tags: deflection.deflection_tags
        .map((t) => t.tag)
        .filter(isPresent)
        .map((t) => t.name),
      minimumItems: deflection.minimum_items || undefined,
    },
    deflection,
    enabledLanguages
  );

type DeflectionPanelProps = {
  mode: FormMode;
  isOpen: boolean;
  deflection?: AppDeflectionFragment;
  onClose: (offer: AppDeflectionFragment | null) => void;
  defaultPropertyEntity?: string;
};

const DeflectionPanel: React.FunctionComponent<DeflectionPanelProps> = ({
  mode,
  deflection,
  isOpen,
  onClose,
  defaultPropertyEntity = "subscriber",
}) => {
  const { viewer } = useViewer();
  const { addToast } = useToasts();

  const { enabled: isFreeMode } = useUpsell();

  const [formValues, setFormValues] =
    useState<TranslatedForms<DeflectionFormValues>>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [enabledLanguages, setEnabledLanguages] = useState<language_enum[]>([
    language_enum.en_us,
  ]);

  const [createPropertyPanelIsOpen, setCreatePropertyPanelIsOpen] =
    useState(false);
  const [createPropertyPanelKey, setCreatePropertyPanelKey] = useState(
    nanoid()
  );

  const [newProperty, setNewProperty] = useState<AppPropertyFragment>();

  const [createDeflection] = useMutation<
    DeflectionPanelCreateDeflectionMutation,
    DeflectionPanelCreateDeflectionMutationVariables
  >(gql`
    mutation DeflectionPanelCreateDeflectionMutation(
      $input: CreateDeflectionInput!
    ) {
      createDeflection(input: $input) {
        deflection {
          ...AppDeflectionFragment
        }
      }
    }
    ${TheAppDeflectionFragment}
  `);

  const [updateDeflection] = useMutation<
    DeflectionPanelUpdateDeflectionMutation,
    DeflectionPanelUpdateDeflectionMutationVariables
  >(gql`
    mutation DeflectionPanelUpdateDeflectionMutation(
      $input: UpdateDeflectionInput!
    ) {
      updateDeflection(input: $input) {
        deflection {
          ...AppDeflectionFragment
        }
      }
    }
    ${TheAppDeflectionFragment}
  `);

  const updateTags = useUpdateTags();

  const { tags } = useTags();

  const { data } = useQuery<
    DeflectionPanelQuery,
    DeflectionPanelQueryVariables
  >(
    gql`
      query DeflectionPanelQuery {
        flow {
          id
          default_language
          flow_languages {
            flow_id
            language
          }
          account {
            id
          }
        }
      }
    `
  );

  const flow = (data?.flow.length && data.flow[0]) || undefined;
  const defaultLanguage = flow?.default_language || language_enum.en_us;

  const [editingLanguage, setEditingLanguage] = useState(defaultLanguage);

  const { features } = useAccountFeatures();

  const { data: propertiesData, refetch: refetchProperties } =
    useQuery<DeflectionPanelPropertiesQuery>(gql`
      query DeflectionPanelPropertiesQuery {
        property {
          ...AppPropertyFragment
        }
      }
      ${TheAppPropertyFragment}
    `);

  const propertyConfig: PropertyConfig = useMemo(
    () =>
      (propertiesData?.property || []).reduce(
        (prev, current) => ({
          ...prev,
          [current.id]: {
            name: current.name,
            type: current.type,
            numberFormat: current.format,
          },
        }),
        {}
      ),
    [propertiesData?.property]
  );

  const initialFormValues = useMemo(() => {
    if (!deflection || !enabledLanguages.length) {
      return null;
    }
    return getInitialFormValues(deflection, enabledLanguages);
  }, [deflection, enabledLanguages]);

  useEffect(() => {
    if (deflection && enabledLanguages.length) {
      setFormValues(getInitialFormValues(deflection, enabledLanguages));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deflection, enabledLanguages]);

  useEffect(() => {
    if (data?.flow.length && !!features.translations) {
      let languages: language_enum[] = [];
      for (const flow of data.flow) {
        for (const language of flow.flow_languages) {
          if (languages.includes(language.language)) {
            continue;
          }
          languages.push(language.language);
        }
      }

      setEnabledLanguages(languages);
    }
  }, [data, features.translations]);

  useEffect(() => {
    if (isOpen) {
      setIsSubmitting(false);
      setFormValues(undefined);
    }
  }, [isOpen]);

  const handleFormChange = (
    newFormValues: TranslatedForms<DeflectionFormValues>
  ) => {
    if (JSON.stringify(newFormValues) !== JSON.stringify(formValues)) {
      setFormValues(cloneDeep(newFormValues));
    }
  };

  const handleSubmit = async (forms: TranslatedForms<DeflectionFormValues>) => {
    if (!viewer) {
      throw new Error("No viewer");
    }

    setIsSubmitting(true);

    const values = forms[defaultLanguage];
    if (!values) {
      throw new Error();
    }

    const tagIds = await updateTags(values.tags);

    switch (mode) {
      case "create":
        const newDeflectionResult = await createDeflection({
          variables: {
            input: {
              title: values.name,
              tagIds: tagIds,
              minimumItems: !!values.minimumItems
                ? Number(values.minimumItems)
                : null,
              headingTranslations: Object.entries(forms).reduce<
                Partial<Record<language_enum, Node[]>>
              >(
                (prev, [language, form]) =>
                  !form
                    ? prev
                    : {
                        ...prev,
                        [language]: form.heading
                          ? JSON.parse(form.heading)
                          : "",
                      },
                {}
              ),
              headingFormat: values.headingFormat,
              contentTranslations: Object.entries(forms).reduce<
                Partial<Record<language_enum, Node[]>>
              >(
                (prev, [language, form]) =>
                  !form
                    ? prev
                    : {
                        ...prev,
                        [language]: form.content
                          ? JSON.parse(form.content)
                          : "",
                      },
                {}
              ),
              contentFormat: values.contentFormat,
              snapshotItems: values.snapshotItems.map((item, index) => ({
                iconPrefix: item.icon ? JSON.parse(item.icon).prefix : null,
                iconName: item.icon ? JSON.parse(item.icon).name : null,
                propertyId: !!item.propertyId ? Number(item.propertyId) : null,
                propertyConditionValue: item.minimumValue
                  ? item.minimumValue
                  : null,
                dateFormat: item.dateFormat,
                textTranslations: Object.entries(forms).reduce<
                  Partial<Record<language_enum, Node[]>>
                >(
                  (prev, [language, form]) =>
                    !form
                      ? prev
                      : {
                          ...prev,
                          [language]: form.snapshotItems[index].text
                            ? JSON.parse(form.snapshotItems[index].text)
                            : "",
                        },
                  {}
                ),
                textFormat: values.snapshotItems[index].textFormat,
              })),
              flowActions: values.flowActions.map((action, index) => ({
                token: action.token,
                code: action.code,
                type: action.type,
                appearance: action.appearance,
                url: action.url,
                rerouteToFlowId: action.rerouteToFlowId,
                textTranslations: Object.entries(forms).reduce<
                  Partial<Record<language_enum, Node[]>>
                >(
                  (prev, [language, form]) =>
                    !form
                      ? prev
                      : {
                          ...prev,
                          [language]: form.flowActions[index].text
                            ? JSON.parse(form.flowActions[index].text)
                            : "",
                        },
                  {}
                ),
                textFormat: values.flowActions[index].textFormat,
              })),
            },
          },
        });

        const newDeflection =
          newDeflectionResult?.data?.createDeflection?.deflection;

        if (!newDeflection) {
          setIsSubmitting(false);
          addToast(<div>Oops! The deflection couldn't be created.</div>, {
            appearance: "error",
          });
          return;
        }

        onClose(newDeflection);
        break;

      case "edit":
        if (!deflection) {
          throw new Error("Deflection is not set");
        }

        const result = await updateDeflection({
          variables: {
            input: {
              id: deflection.id,
              title: values.name,
              tagIds: tagIds,
              minimumItems: !!values.minimumItems
                ? Number(values.minimumItems)
                : null,
              headingTranslations: Object.entries(forms).reduce<
                Partial<Record<language_enum, Node[]>>
              >(
                (prev, [language, form]) =>
                  !form
                    ? prev
                    : {
                        ...prev,
                        [language]: form.heading
                          ? JSON.parse(form.heading)
                          : "",
                      },
                {}
              ),
              headingFormat: values.headingFormat,
              contentTranslations: Object.entries(forms).reduce<
                Partial<Record<language_enum, Node[]>>
              >(
                (prev, [language, form]) =>
                  !form
                    ? prev
                    : {
                        ...prev,
                        [language]: form.content
                          ? JSON.parse(form.content)
                          : "",
                      },
                {}
              ),
              contentFormat: values.contentFormat,
              snapshotItems: values.snapshotItems.map((item, index) => ({
                iconPrefix: item.icon ? JSON.parse(item.icon).prefix : null,
                iconName: item.icon ? JSON.parse(item.icon).name : null,
                propertyId: !!item.propertyId ? Number(item.propertyId) : null,
                propertyConditionValue: item.minimumValue
                  ? item.minimumValue
                  : null,
                dateFormat: item.dateFormat,
                textTranslations: Object.entries(forms).reduce<
                  Partial<Record<language_enum, Node[]>>
                >(
                  (prev, [language, form]) =>
                    !form
                      ? prev
                      : {
                          ...prev,
                          [language]: form.snapshotItems[index].text
                            ? JSON.parse(form.snapshotItems[index].text)
                            : "",
                        },
                  {}
                ),
                textFormat: values.snapshotItems[index].textFormat,
              })),
              flowActions: values.flowActions.map((action, index) => ({
                token: action.token,
                code: action.code,
                type: action.type,
                appearance: action.appearance,
                url: action.url,
                rerouteToFlowId: action.rerouteToFlowId,
                textTranslations: Object.entries(forms).reduce<
                  Partial<Record<language_enum, Node[]>>
                >(
                  (prev, [language, form]) =>
                    !form
                      ? prev
                      : {
                          ...prev,
                          [language]: form.flowActions[index].text
                            ? JSON.parse(form.flowActions[index].text)
                            : "",
                        },
                  {}
                ),
                textFormat: values.flowActions[index].textFormat,
              })),
            },
          },
        });

        if (!result.data?.updateDeflection.deflection) {
          setIsSubmitting(false);
          addToast(<div>Oops! The deflection couldn't be updated.</div>, {
            appearance: "error",
          });
          return;
        }

        onClose(result.data.updateDeflection.deflection);

        break;
    }
  };

  const hasUnsavedChanges =
    (mode === "edit" &&
      initialFormValues &&
      !isEqual(initialFormValues, formValues)) ||
    (mode === "create" && !!formValues);

  const loading = useLoading(
    (mode === "edit" && !initialFormValues) ||
      (mode === "create" && !formValues) ||
      isSubmitting
  );

  return (
    <>
      <DefaultStyles
        isEditMode={true}
        modal={false}
        fullScreen={true}
        showOfferTimer={false}
      />
      <PropertyPanel
        key={createPropertyPanelKey}
        mode="create"
        isOpen={createPropertyPanelIsOpen}
        onClose={async (property) => {
          setCreatePropertyPanelIsOpen(false);
          if (property) {
            await refetchProperties();
            setNewProperty(property);
          }

          setTimeout(() => {
            setCreatePropertyPanelKey(nanoid());
          }, 500);
        }}
        defaultEntity={defaultPropertyEntity}
        zIndex={60}
      />
      <Panel isOpen={isOpen} width={1060}>
        <Container>
          <FormContainer>
            <PanelHeader>
              <PanelTitle>
                {mode === "create" ? (
                  <>Create deflection</>
                ) : (
                  <>Edit deflection</>
                )}
              </PanelTitle>
              <PanelButtons>
                <Button
                  buttonType="primary"
                  form="create-deflection"
                  isLoading={loading}
                  disabled={!hasUnsavedChanges}
                >
                  Save
                </Button>
                <Button
                  buttonType="default"
                  onClick={() => onClose(null)}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
              </PanelButtons>
            </PanelHeader>
            <PanelFormBody>
              <TranslationsProvider
                language={editingLanguage}
                defaultLanguage={defaultLanguage}
                enabledLanguages={enabledLanguages || [language_enum.en_us]}
              >
                <PropertyValuesProvider
                  propertyValues={{}}
                  propertyConfig={propertyConfig}
                  showPlaceholders={true}
                >
                  <DeflectionForm
                    onChange={handleFormChange}
                    onSubmit={handleSubmit}
                    initialValues={
                      initialFormValues as TranslatedForms<DeflectionFormValues>
                    }
                    onChangeEditingLanguage={(language) =>
                      setEditingLanguage(language)
                    }
                    isSubmitting={isSubmitting}
                    tags={tags}
                    isFreeMode={isFreeMode}
                    onClickCreateProperty={() => {
                      setCreatePropertyPanelIsOpen(true);
                    }}
                    newProperty={newProperty}
                  />
                </PropertyValuesProvider>
              </TranslationsProvider>
            </PanelFormBody>
          </FormContainer>
          <Preview>
            <PreviewHeader>
              Preview <ModalClose onClose={() => onClose(null)} />
            </PreviewHeader>
            <PreviewBody>
              <TranslationsProvider
                language={editingLanguage}
                defaultLanguage={defaultLanguage}
                enabledLanguages={enabledLanguages || [language_enum.en_us]}
              >
                <PropertyValuesProvider
                  propertyValues={{}}
                  propertyConfig={propertyConfig}
                  showPlaceholders={true}
                >
                  <SwitchTransition>
                    <CSSTransition
                      key="deflection-preview"
                      timeout={previewTransition}
                      classNames="preview"
                    >
                      <PreviewTransitionWrapper>
                        {!!formValues && formValues[editingLanguage] && (
                          <>
                            <DeflectionPreview
                              formValues={formValues}
                              mode="panel"
                              language={editingLanguage}
                            />
                            <PreviewHint>
                              <HelpContainer>
                                <HelpIcon>
                                  <FontAwesomeIcon icon={faInfoCircle} />
                                </HelpIcon>
                                <HelpText>
                                  Deflect users from canceling by reinforcing
                                  value and/or presenting alternative options.
                                </HelpText>
                              </HelpContainer>
                            </PreviewHint>
                          </>
                        )}
                      </PreviewTransitionWrapper>
                    </CSSTransition>
                  </SwitchTransition>
                </PropertyValuesProvider>
              </TranslationsProvider>
            </PreviewBody>
          </Preview>
        </Container>
      </Panel>
    </>
  );
};

export default DeflectionPanel;
