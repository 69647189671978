import tw from "twin.macro";

import Editor from "../../../common/editor2/Editor";
import Spinner from "../../../common/Spinner";
import StandardLinkButton from "../../../common/StandardLinkButton";
import { useTranslations } from "../../../common/translations/TranslationsProvider";
import translationValue from "../../../common/translationValue";
import DefaultStyles from "./DefaultStyles";

const Layout = tw.div`fixed top-0 bottom-0 left-0 right-0 flex items-center justify-center h-full`;
const Container = tw.div`flex flex-col items-center gap-4 mx-8`;
const Content = tw.div`flex flex-col border rounded-2xl px-12 py-8 max-w-[26rem]`;

interface AlreadyCanceledProps {
  content: string;
  isTranslatable?: boolean;
  onClose: () => void;
  isLoading: boolean;
}

const AlreadyCanceled: React.FunctionComponent<AlreadyCanceledProps> = ({
  content,
  isTranslatable = true,
  onClose,
  isLoading,
}) => {
  const { language, defaultLanguage } = useTranslations();

  const translatedContent = isTranslatable
    ? translationValue(JSON.parse(content), language, defaultLanguage).value
    : content;

  return (
    <>
      <DefaultStyles isEditMode={false} modal={false} fullScreen={true} />
      {isLoading ? (
        <Spinner />
      ) : (
        <Layout>
          <Container>
            <Content>
              {isTranslatable ? (
                <Editor
                  initialValue={JSON.stringify(translatedContent)}
                  isReadOnly={true}
                />
              ) : (
                <div>{content}</div>
              )}
            </Content>
            <div>
              <StandardLinkButton onClick={onClose}>Close</StandardLinkButton>
            </div>
          </Container>
        </Layout>
      )}
    </>
  );
};

export default AlreadyCanceled;
