import { isPresent } from "ts-is-present";

import {
  FlowDeflectionRuleFragment,
  FlowOfferRuleFragment,
} from "../../__generated__/graphql";

export default function mapDeflectionRules(
  rules: FlowDeflectionRuleFragment[]
): FlowOfferRuleFragment[] {
  return rules
    .map((rule) => {
      if (!rule?.id) {
        return null;
      }

      const publishedVersion = rule.published_version;
      const draftVersion = rule.draft_version;

      return {
        ...rule,
        published_version: !!publishedVersion
          ? {
              ...publishedVersion,
              offer_ids: [],
              offer_test_ids: [],
              offer_group_ids: [],
              offer_autopilot_offer_ids: [],
              offer_rule_version_offers: [],
              offer_rule_version_offer_tests: [],
              offer_rule_version_offer_groups: [],
              offer_rule_version_offer_autopilot_offers: [],
              offer_rule_version_offer_rule_rules: (
                publishedVersion.offer_rule_version_offer_rule_rules || []
              ).map((r) => ({
                ...r,
                offer_rule_rule: !!r.offer_rule_rule
                  ? {
                      ...r.offer_rule_rule,
                      offer_ids: [],
                      offer_test_ids: [],
                      offer_group_ids: [],
                      offer_autopilot_offer_ids: [],
                      offer_rule_rule_offers: [],
                      offer_rule_rule_offer_tests: [],
                      offer_rule_rule_offer_groups: [],
                      offer_rule_rule_offer_autopilot_offers: [],
                    }
                  : null,
              })),
            }
          : null,
        draft_version: !!draftVersion
          ? {
              ...draftVersion,
              offer_ids: [],
              offer_test_ids: [],
              offer_group_ids: [],
              offer_autopilot_offer_ids: [],
              offer_rule_version_offers: [],
              offer_rule_version_offer_tests: [],
              offer_rule_version_offer_groups: [],
              offer_rule_version_offer_autopilot_offers: [],
              offer_rule_version_offer_rule_rules: (
                draftVersion.offer_rule_version_offer_rule_rules || []
              ).map((r) => ({
                ...r,
                offer_rule_rule: !!r.offer_rule_rule
                  ? {
                      ...r.offer_rule_rule,
                      offer_ids: [],
                      offer_test_ids: [],
                      offer_group_ids: [],
                      offer_autopilot_offer_ids: [],
                      offer_rule_rule_offers: [],
                      offer_rule_rule_offer_tests: [],
                      offer_rule_rule_offer_groups: [],
                      offer_rule_rule_offer_autopilot_offers: [],
                    }
                  : null,
              })),
            }
          : null,
      };
    })
    .filter(isPresent);
}
