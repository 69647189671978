import { nanoid } from "nanoid";
import { useState } from "react";

import {
  language_enum,
  TranslationFragment,
} from "../../../__generated__/graphql";
import { TranslatedForms } from "../../../common/form/useTranslatableForm";
import AddAcknowledgementModal, { FormValues } from "./AddAcknowledgementModal";
import EditableTextWrapper from "./EditableTextWrapper";

interface EditableAcknowledgementLabelProps {
  isEditable: boolean;
  flowId: number;
  flowToken: string;
  language: language_enum;
  enabledLanguages: language_enum[];
  defaultLanguage: language_enum;
  labelTranslation: TranslationFragment;
  onSave: (forms: TranslatedForms<FormValues>) => Promise<void>;
}

const EditableAcknowledgementLabel: React.FunctionComponent<
  EditableAcknowledgementLabelProps
> = ({
  isEditable,
  flowId,
  flowToken,
  language,
  enabledLanguages,
  defaultLanguage,
  labelTranslation,
  onSave,
  children,
}) => {
  const [editing, setEditing] = useState(false);
  const [modalKey, setModalKey] = useState(nanoid());

  return (
    <>
      <AddAcknowledgementModal
        key={modalKey}
        mode="edit"
        language={language}
        enabledLanguages={enabledLanguages}
        defaultLanguage={defaultLanguage}
        isOpen={editing}
        flowId={flowId}
        flowToken={flowToken}
        labelTranslation={labelTranslation}
        onClose={() => {
          setEditing(false);
          setModalKey(nanoid());
        }}
        onSave={onSave}
      />
      <EditableTextWrapper
        isEditable={isEditable}
        onEdit={(e) => {
          e.preventDefault();
          setEditing(true);
        }}
        shiftLeft={-56}
        hoverContentClass="acknowledgement-inline-text-content"
      >
        <span>{children}</span>
      </EditableTextWrapper>
    </>
  );
};

export default EditableAcknowledgementLabel;
