import React from "react";
import { isPresent } from "ts-is-present";
import { theme } from "twin.macro";

import { RuleQuestionFragment } from "../../__generated__/graphql";
import { FlowText } from "../../features/public/flow/lib/types";
import BooleanDivider from "../BooleanDivider";
import getFlowObjectVersion from "../flow/getFlowObjectVersion";
import { useTranslations } from "../translations/TranslationsProvider";
import RuleExpander from "./RuleExpander";

export interface RuleQuestionAnswerPickerValue {
  questionOptionIds: number[];
  includeOtherInQuestionIds: number[];
}

interface RuleQuestionAnswerPickerProps {
  questions: RuleQuestionFragment[];
  value: RuleQuestionAnswerPickerValue;
  flowText?: FlowText;
  showBooleanDividers?: boolean;
  onChange: (value: RuleQuestionAnswerPickerValue) => void;
}

const RuleQuestionAnswerPicker: React.FunctionComponent<
  RuleQuestionAnswerPickerProps
> = ({ questions, value, flowText, showBooleanDividers, onChange }) => {
  const { translationText } = useTranslations();

  const questionValues = questions.reduce<Record<number, number[]>>(
    (prev, curr) => ({
      ...prev,
      [curr.id]: (() => {
        const questionVersion = getFlowObjectVersion(curr, "draft");
        const optionIds = questionVersion.question_version_question_options
          .map((o) => o.question_option)
          .filter(isPresent)
          .map((o) => o.id);

        const filtered = optionIds.filter((id) =>
          value.questionOptionIds.includes(id)
        );
        if (value.includeOtherInQuestionIds.includes(curr.id)) {
          filtered.push(-1);
        }

        return filtered;
      })(),
    }),
    {}
  );

  const handleChange = (questionId: number, ids: number[]) => {
    const newQuestionValues = {
      ...questionValues,
      [questionId]: ids,
    };

    onChange({
      ...value,
      questionOptionIds: Object.values(newQuestionValues)
        .reduce((prev, curr) => [...prev, ...curr], [])
        .filter((id) => id > 0),
      includeOtherInQuestionIds: ids.includes(-1)
        ? [...new Set([...value.includeOtherInQuestionIds, questionId])]
        : value.includeOtherInQuestionIds.filter((id) => id !== questionId),
    });
  };

  return (
    <div>
      {questions.map((question, i) => {
        const questionVersion = getFlowObjectVersion(question, "draft");
        let hasOtherSpecify = false;
        const otherSpecifySetting = question.question_settings.find(
          (s) => s.key === "other_specify"
        );
        if (otherSpecifySetting) {
          const settingVersion = getFlowObjectVersion(
            otherSpecifySetting,
            "draft"
          );
          hasOtherSpecify = settingVersion.value;
        }

        const items = questionVersion.question_version_question_options
          .map((o) => o.question_option)
          .filter(isPresent)
          .map((questionOption) => {
            const questionOptionVersion = getFlowObjectVersion(
              questionOption,
              "draft"
            );

            return {
              id: questionOption.id,
              label: translationText(questionOptionVersion.title_translation),
            };
          });

        if (hasOtherSpecify) {
          items.push({
            id: -1,
            label: flowText ? translationText(flowText.other_option) : "Other",
          });
        }

        return (
          <React.Fragment key={question.id}>
            <RuleExpander
              title={translationText(questionVersion.title_translation)}
              items={items}
              itemColor={theme`colors.blue.DEFAULT`}
              itemName="options"
              includeAny
              value={questionValues[question.id]}
              onChange={(v) => handleChange(question.id, v)}
              tw="mb-4"
            />
            {showBooleanDividers && i < questions.length - 1 && (
              <div tw="mt-6 mb-4">
                <BooleanDivider operator="and" />
              </div>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default RuleQuestionAnswerPicker;
