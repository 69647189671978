import { useState } from "react";

import Button from "../../common/form/Button";
import TextInput from "../../common/form/input/TextInput";
import Modal, { ModalProps } from "../../common/modal/Modal";
import ModalBody from "../../common/modal/ModalBody";
import ModalFooter from "../../common/modal/ModalFooter";
import ModalHeader from "../../common/modal/ModalHeader";

interface ConfirmConvertPropertyTypeModalProps extends ModalProps {
  onClose: (confirmed: boolean) => void;
}

const ConfirmConvertPropertyTypeModal: React.FunctionComponent<
  ConfirmConvertPropertyTypeModalProps
> = ({ onClose, ...props }) => {
  const [inputValue, setInputValue] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);

  return (
    <Modal {...props}>
      <ModalHeader>Convert property</ModalHeader>
      <ModalBody tw="pb-0">
        <p>Are you sure you want to change this property's type?</p>
        <p>
          Changing a property's type is a potentially destructive action. We'll
          do our best to convert your existing values, but if a value cannot be
          converted, it will be <strong>permanently deleted</strong>. This
          cannot be undone.
        </p>
        <p tw="pb-4 mb-0 border-b border-gray-100">
          <TextInput
            placeholder="Type CONTINUE to confirm"
            value={inputValue}
            onChange={(e) => setInputValue(e.currentTarget.value)}
            disabled={isDeleting}
          />
        </p>
      </ModalBody>
      <ModalFooter>
        <Button
          buttonType="danger"
          onClick={() => {
            setIsDeleting(true);
            onClose(true);
          }}
          disabled={inputValue !== "CONTINUE"}
          isLoading={isDeleting}
        >
          Convert property
        </Button>
        <Button
          buttonType="default"
          onClick={() => onClose(false)}
          disabled={isDeleting}
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmConvertPropertyTypeModal;
