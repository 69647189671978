import gql from "graphql-tag";

import FlowOfferFragment from "./FlowOfferFragment";
import FlowOfferRuleOfferAutopilotFragment from "./FlowOfferRuleOfferAutopilotFragment";

export default gql`
  fragment FlowOfferGroupFragment on offer_group {
    id
    name
    token
    show_images
    offer_group_offers(order_by: { position: asc }) {
      position
      offer {
        ...FlowOfferFragment
        offer_autopilot {
          ...FlowOfferRuleOfferAutopilotFragment
        }
      }
    }
    headline_translation {
      ...TranslationFragment
    }
    content_translation {
      ...TranslationFragment
    }
    cancel_text_translation {
      ...TranslationFragment
    }
    randomize
  }
  ${FlowOfferFragment}
  ${FlowOfferRuleOfferAutopilotFragment}
`;
