import { nanoid } from "nanoid";
import { ForwardedRef, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";
import tw, { css, styled } from "twin.macro";

import { TRANSPARENT_LOGIN_HEADER_HEIGHT } from "../layout/header/TransparentLoginHeader";
import Spinner from "../Spinner";
import PanelBody from "./PanelBody";
import PanelHeader from "./PanelHeader";

export interface PanelProps {
  className?: string;
  isOpen: boolean;
  header?: React.ReactNode;
  isLoading?: boolean;
  width?: number;
  marginRight?: number;
  classNames?: string;
  staticTransitionMs?: number;
  showOverlay?: boolean;
  forwardedRef?: ForwardedRef<HTMLDivElement>;
  zIndex?: number;
}

const defaultWidth = 500;
const overlayTranstion = 400;
const transition = (width: number, staticTransitionMs?: number) =>
  !!staticTransitionMs ? staticTransitionMs : Math.floor(width / 1.5);

const StyledCSSTranstion = styled(CSSTransition)<{
  classNames: string;
  width: number;
  marginRight: number;
  staticTransitionMs?: number;
}>`
  .overlay {
    opacity: 0.5;
  }

  &.${(props) => props.classNames}-enter {
    .overlay {
      opacity: 0;
    }

    > .content {
      margin-right: -${(props) => props.width}px;
    }
  }

  &.${(props) => props.classNames}-enter-active {
    .overlay {
      opacity: 0.5;
      transition: opacity ${overlayTranstion}ms;
    }

    > .content {
      margin-right: ${(props) => props.marginRight}px;
      transition: margin-right
        ${(props) => transition(props.width, props.staticTransitionMs)}ms;
    }
  }

  &.${(props) => props.classNames}-enter-done {
    .overlay {
      opacity: 0.5;
    }

    > .content {
      margin-right: ${(props) => props.marginRight}px;
    }
  }

  &.${(props) => props.classNames}-exit {
    .overlay {
      opacity: 0.5;
    }

    > .content {
      margin-right: ${(props) => props.marginRight}px;
    }
  }

  &.${(props) => props.classNames}-exit-active {
    .overlay {
      opacity: 0;
      transition: opacity ${overlayTranstion}ms;
    }

    > .content {
      margin-right: -${(props) => props.width}px;
      transition: margin-right
        ${(props) => transition(props.width, props.staticTransitionMs)}ms;
    }
  }
`;

const Container = styled.div<{ zIndex: number }>`
  ${tw`fixed w-full h-full top-0 left-0 flex`}

  z-index: ${(props) => props.zIndex};

  .content,
  .overlay {
    .has-transparent-login & {
      ${tw`bottom-0`}
      height: calc(100vh - ${TRANSPARENT_LOGIN_HEADER_HEIGHT});
    }
  }
`;
const Overlay = tw.div`absolute w-full h-full bg-gray-300 flex`;
const Content = styled.div<{
  width: number;
  marginRight: number;
  entered: boolean;
  staticTransitionMs?: number;
}>`
  ${tw`fixed h-full bg-white z-50 shadow-lg flex flex-col`}

  width: ${(props) => props.width}px;
  right: 0;
  margin-right: ${(props) => props.marginRight}px;
  ${(props) =>
    props.entered &&
    css`
      transition: margin-right
        ${transition(props.width, props.staticTransitionMs)}ms;
    `}
`;

const Panel: React.FunctionComponent<PanelProps> = ({
  className,
  isOpen,
  header,
  isLoading,
  width = defaultWidth,
  marginRight = 0,
  classNames = "panel",
  staticTransitionMs,
  showOverlay = true,
  zIndex = 50,
  children,
}) => {
  const [id] = useState(nanoid());
  const nodeRef = useRef(null);
  const [entered, setEntered] = useState(false);

  return ReactDOM.createPortal(
    <div id="panel-container" className={className}>
      <StyledCSSTranstion
        nodeRef={nodeRef}
        in={isOpen}
        timeout={transition(width, staticTransitionMs)}
        classNames={classNames}
        unmountOnExit={true}
        width={width}
        marginRight={marginRight}
        onEntered={() => setEntered(true)}
      >
        <Container ref={nodeRef} key={id} zIndex={zIndex}>
          {showOverlay && <Overlay className="overlay" />}
          <Content
            className="content"
            width={width}
            marginRight={marginRight}
            entered={entered}
            staticTransitionMs={staticTransitionMs}
          >
            {!!header && <PanelHeader>{header} </PanelHeader>}

            {isLoading ? (
              <PanelBody>
                <Spinner />
              </PanelBody>
            ) : (
              children
            )}
          </Content>
        </Container>
      </StyledCSSTranstion>
    </div>,
    document.body
  );
};

export default Panel;
