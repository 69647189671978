import { Temporal } from "@js-temporal/polyfill";
import { forwardRef, useEffect, useState } from "react";
import tw, { css, styled } from "twin.macro";

import {
  FlowOfferFragment,
  FlowSubscriptionFragment,
  offer_goal_enum,
  PauseReason,
} from "../../__generated__/graphql";
import placeholder from "../../assets/placeholder.svg";
import { flowButtonCss, flowModalCss } from "../public/flow/DefaultStyles";
import mapFlowText from "../public/flow/lib/mapFlowText";
import { FlowError } from "../public/flow/lib/types";
import OfferModal from "../public/flow/OfferModal";
import OfferStep from "../public/flow/OfferStep";
import { OfferFormValues } from "./forms/types";
import mapPreviewOffer from "./lib/mapOffer";

const Container = styled.div<{ showTimer: boolean }>`
  ${tw`flex justify-center max-h-full`}
  ${(props) => flowModalCss(props.showTimer)}
  ${flowButtonCss()}
`;

interface OfferPreviewProps {
  formValues: OfferFormValues | undefined;
  onImageLoad?: () => void;
  previewMode?: boolean;
  goal?: offer_goal_enum;
  showTimer?: boolean;
  swappableProducts?: FlowSubscriptionFragment;
  pauseReasons?: PauseReason[];
  autopilot?: boolean;
  showDeclineButton?: boolean;
  displayMode?: "panel" | "modal" | "card";
  nextOrderDate?: Temporal.PlainDate;
  error?: FlowError;
}

const OfferPreview: React.FunctionComponent<OfferPreviewProps> = ({
  formValues,
  onImageLoad,
  previewMode = true,
  goal = offer_goal_enum.retention,
  swappableProducts = null,
  pauseReasons,
  showDeclineButton = true,
  displayMode = "panel",
  error,
}) => {
  const [offer, setOffer] = useState<FlowOfferFragment>();
  const flowText = mapFlowText([], "published");

  useEffect(() => {
    if (!formValues) {
      return;
    }

    setOffer(mapPreviewOffer(formValues, goal));
  }, [swappableProducts, formValues, goal]);

  if (!offer) {
    return null;
  }

  const nextOrderDate = Temporal.Now.plainDateISO().add({ days: 3 });

  return displayMode === "card" ? (
    <div tw="w-full">
      <OfferStep
        offer={offer}
        nextOrderDate={nextOrderDate}
        flowText={flowText}
        onAccept={() => {}}
        swappableProducts={swappableProducts}
        disableVideoAutoPlay={true}
        error={error}
      />
    </div>
  ) : offer.style === "step" ? (
    <OfferStep
      offer={offer}
      nextOrderDate={nextOrderDate}
      pauseReasons={pauseReasons}
      flowText={flowText}
      onAccept={() => {}}
      swappableProducts={swappableProducts}
      disableVideoAutoPlay={true}
      onImageLoad={onImageLoad}
      error={error}
    />
  ) : (
    <OfferModal
      offer={offer}
      nextOrderDate={nextOrderDate}
      pauseReasons={pauseReasons}
      flowText={flowText}
      isOpen={true}
      inline={true}
      previewMode={previewMode}
      onAccept={() => {}}
      onDecline={() => {}}
      isFinalStep={true}
      onImageLoad={onImageLoad}
      showCloseX={goal === offer_goal_enum.conversion}
      swappableProducts={swappableProducts}
      disableVideoAutoPlay={true}
      showDeclineButton={showDeclineButton}
      error={error}
    />
  );
};

const BANNER_SCALE = 0.5;
const MODAL_SCALE = 0.7;
const CONVERSION_MODAL_SCALE = 0.5;

export default forwardRef<HTMLDivElement, OfferPreviewProps>((props, ref) => (
  <Container ref={ref} showTimer={!!props.showTimer}>
    {props.goal === "conversion" ? (
      <div
        tw="rounded border border-divider w-[570px] h-[500px] overflow-hidden"
        css={css`
          background-image: url(${placeholder});
          background-size: 570px;
        `}
      >
        <div
          css={
            props.formValues?.style === "banner"
              ? css`
                  transform-origin: top center;
                  transform: scale(${BANNER_SCALE});
                  width: calc(100% * 1 / ${BANNER_SCALE});
                  margin-left: calc(100% * ${BANNER_SCALE} * -1);
                `
              : css`
                  position: relative;
                  top: 145px;
                  transform-origin: top center;
                  transform: scale(
                    ${props.goal === "conversion"
                      ? CONVERSION_MODAL_SCALE
                      : MODAL_SCALE}
                  );
                `
          }
        >
          <OfferPreview {...props} />
        </div>
      </div>
    ) : (
      <OfferPreview {...props} />
    )}
  </Container>
));
