import { useApolloClient } from "@apollo/client";
import { faWeightHanging } from "@fortawesome/pro-regular-svg-icons";
import {
  faGripVertical,
  faInfoCircle,
  faPlus,
  faTimesCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { nanoid } from "nanoid";
import { useMemo, useRef, useState } from "react";
import React from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
  ResponderProvided,
} from "react-beautiful-dnd";
import { FormattedMessage } from "react-intl";
import { useToasts } from "react-toast-notifications";
import { isPresent } from "ts-is-present";
import tw from "twin.macro";

import {
  AppDeflectionFragment,
  AppPropertyFragment,
  AppSegmentGroupFragment,
  FlowOfferRuleFragment,
  FlowOfferRulesQuery,
  FlowOfferRulesQueryVariables,
  FlowQuestionFragment,
  SegmentFragment,
} from "../../../../__generated__/graphql";
import mapDeflectionRules from "../../../../common/flow/mapDeflectionRules";
import Button from "../../../../common/form/Button";
import TheFlowOfferRulesQuery from "../../../../common/queries/FlowOfferRulesQuery";
import {
  AnyBox,
  BoxContainer,
  QuestionOptionBox,
} from "../../../../common/rules/RuleBoxes";
import RuleEditor, {
  RuleEditorFlowDeflectionRuleValue,
} from "../../../../common/rules/RuleEditor";
import RuleEditorOverlay from "../../../../common/rules/RuleEditorOverlay";
import RuleSegments from "../../../../common/rules/RuleSegments";
import {
  DragHandle,
  HelpContainer,
  HelpIcon,
  HelpText,
  RuleBody,
  RuleColumn,
  RuleColumns,
  RuleDelete,
  RuleDrag,
  RuleHeader,
  RuleHeaderColumn,
  RuleHeaderDeleteColumn,
  RuleHeaderDragColumn,
  RuleRow,
  Rules,
  RulesContainer,
  RulesZeroState,
} from "../../../../common/rules/RuleStyles";
import Spinner from "../../../../common/Spinner";
import StandardExternalLink from "../../../../common/StandardExternalLink";
import StandardLinkButton from "../../../../common/StandardLinkButton";
import { useTranslations } from "../../../../common/translations/TranslationsProvider";
import useAccountFeatures from "../../../../common/useAccountFeatures";
import useViewer from "../../../../common/useViewer";
import DeflectionPanel from "../../../deflections/DeflectionPanel";
import {
  AddRuleOptions,
  defaultRule,
  reorderRules,
  RuleManager,
} from "../../../flow/edit/ruleActions";
import PropertyPanel from "../../../properties/PropertyPanel";
import CreateSegmentPanel from "../../../segments/CreateSegmentPanel";
import SegmentGroupPanel from "../../../segments/SegmentGroupPanel";
import UpsellBanner, {
  BannerMode,
} from "../../../upgrade-account/UpsellBanner";
import { useUpsellBanner } from "../../../upgrade-account/useUpsellBanner";
import { useFlowVersion } from "../FlowVersionProvider";
import { FlowText } from "../lib/types";
import OfferRuleDeflections from "./OfferRuleDeflections";

interface DeflectionRulesProps {
  ruleGroupId: number;
  deflectionRules: FlowOfferRulesQuery;
  deflections: AppDeflectionFragment[];
  flowId: number;
  questions: FlowQuestionFragment[];
  manager: RuleManager;
  flowText: FlowText;
  showQuestions?: boolean;
  isFreeMode?: boolean;
  onClickUpgrade?: () => void;
  onEditDeflection: () => void;
  onCreateDeflection: () => void;
}

const DeflectionRules: React.FunctionComponent<DeflectionRulesProps> = ({
  ruleGroupId,
  deflectionRules,
  deflections,
  flowId,
  questions,
  manager,
  flowText,
  showQuestions = true,
  isFreeMode = false,
  onClickUpgrade = () => {},
  onEditDeflection = () => {},
  onCreateDeflection = () => {},
}) => {
  const { translationText } = useTranslations();
  const [editingId, setEditingId] = useState<number | null>(null);
  const [deletingId, setDeletingId] = useState<number | null>(null);
  const [editingNewRule, setEditingNewRule] = useState(false);
  const ruleRefs = useRef<Record<number, HTMLDivElement | null>>({});

  const { getObjectVersion } = useFlowVersion();

  const [createDeflectionPanelIsOpen, setCreateDeflectionPanelIsOpen] =
    useState(false);
  const [creatingDeflectionInGroupIndex, setCreatingDeflectionInGroupIndex] =
    useState<number>();
  const [createDeflectionPanelKey, setCreateDeflectionPanelKey] = useState(
    nanoid()
  );

  const [editDeflectionPanelIsOpen, setEditDeflectionPanelIsOpen] =
    useState(false);
  const [editingDeflection, setEditingDeflection] =
    useState<AppDeflectionFragment>();
  const [editDeflectionPanelKey, setEditDeflectionPanelKey] = useState(
    nanoid()
  );

  const [createSegmentGroupPanelIsOpen, setCreateSegmentGroupPanelIsOpen] =
    useState(false);
  const [createSegmentGroupPanelKey, setCreateSegmentGroupPanelKey] = useState(
    nanoid()
  );

  const [createSegmentPanelIsOpen, setCreateSegmentPanelIsOpen] =
    useState(false);
  const [createSegmentPanelKey, setCreateSegmentPanelKey] = useState(nanoid());

  const [createPropertyPanelIsOpen, setCreatePropertyPanelIsOpen] =
    useState(false);
  const [createPropertyPanelKey, setCreatePropertyPanelKey] = useState(
    nanoid()
  );
  const [newProperty, setNewProperty] = useState<AppPropertyFragment>();
  const [defaultPropertyEntity, setDefaultPropertyEntity] = useState<string>();

  const [isSaving, setIsSaving] = useState(false);
  const { viewer } = useViewer();
  const { features } = useAccountFeatures();
  const apollo = useApolloClient();

  const { addToast } = useToasts();

  useUpsellBanner("Access deflection rules");

  const platform = viewer?.account?.platform_connection?.platform || undefined;
  const deflectionRuleGroup = deflectionRules.deflection_rule_group.find(
    (group) => group.id === ruleGroupId
  );
  if (!deflectionRuleGroup) {
    throw new Error("Missing deflection rule group");
  }
  const groupVersion = getObjectVersion(deflectionRuleGroup);

  const unmappedActualRules = groupVersion.offer_rule_group_version_offer_rules
    .map(({ offer_rule }) => offer_rule)
    .filter(isPresent);
  const actualRules = mapDeflectionRules(unmappedActualRules);

  const options = questions.flatMap((q) => {
    const questionVersion = getObjectVersion(q);
    return questionVersion.question_version_question_options
      .map((o) => o.question_option)
      .filter(isPresent);
  });

  const [ruleEditorValue, setRuleEditorValue] =
    useState<RuleEditorFlowDeflectionRuleValue>();

  const rules: FlowOfferRuleFragment[] = useMemo(
    () =>
      ruleEditorValue && editingId === -1
        ? [
            ...actualRules,
            {
              __typename: "offer_rule",
              id: -1,
              published_version: {
                __typename: "offer_rule_version",
                id: -1,
                offer_rule_id: -1,
                offer_rule_rule_ids: [],
                offer_ids: [],
                offer_test_ids: [],
                offer_group_ids: [],
                offer_autopilot_offer_ids: [],
                segment_group_ids: ruleEditorValue.segmentGroupIds,
                segment_ids: ruleEditorValue.segmentIds,
                offer_rule_version_segment_groups:
                  ruleEditorValue.segmentGroupIds
                    .map((id) =>
                      deflectionRules.segment_group.find((s) => s.id === id)
                    )
                    .filter(isPresent)
                    .map((segmentGroup) => ({
                      __typename: "offer_rule_version_segment_group",
                      offer_rule_version_id: -1,
                      segment_group_id: segmentGroup.id,
                      segmentGroup,
                    })),
                offer_rule_version_segments: ruleEditorValue.segmentIds
                  .map((id) => deflectionRules.segment.find((s) => s.id === id))
                  .filter(isPresent)
                  .map((segment) => ({
                    __typename: "offer_rule_version_segment",
                    offer_rule_version_id: -1,
                    segment_id: segment.id,
                    segment,
                  })),
                question_option_ids: ruleEditorValue.questionOptionIds,
                offer_rule_version_question_options:
                  ruleEditorValue.questionOptionIds
                    .map((id) => options.find((o) => o.id === id))
                    .filter(isPresent)
                    .map((option) => ({
                      __typename: "offer_rule_version_question_option",
                      offer_rule_version_id: -1,
                      question_option_id: option.id,
                      question_option: option,
                    })),
                offer_rule_version_offer_rule_rules: [],
                offer_rule_version_offers: [],
                offer_rule_version_offer_tests: [],
                offer_rule_version_offer_groups: [],
                offer_rule_version_offer_autopilot_offers: [],
                include_present_no_offer: false,
                include_other_in_question_ids:
                  ruleEditorValue.includeOtherInQuestionIds,
              },
              draft_version: null,
            },
          ]
        : actualRules,
    [actualRules, editingId, deflectionRules, options, ruleEditorValue]
  );

  const handleCreateDeflection = async (
    newDeflection: AppDeflectionFragment
  ) => {
    if (!ruleEditorValue || creatingDeflectionInGroupIndex === undefined) {
      throw new Error();
    }
    if (newDeflection) {
      apollo.cache.writeQuery<
        FlowOfferRulesQuery,
        FlowOfferRulesQueryVariables
      >({
        query: TheFlowOfferRulesQuery,
        variables: { flowId },
        data: {
          ...deflectionRules,
          deflection: [...deflectionRules.deflection, newDeflection],
        },
      });
      const group = ruleEditorValue.groups[creatingDeflectionInGroupIndex];
      const newGroups = [...ruleEditorValue.groups];
      newGroups[creatingDeflectionInGroupIndex] = {
        ...group,
        offerIds: [],
        offerGroupIds: [],
        offerTestIds: [],
        deflectionIds: [...group.deflectionIds, newDeflection.id],
      };
      setRuleEditorValue({
        ...ruleEditorValue,
        groups: newGroups,
      });

      onCreateDeflection();

      setCreatingDeflectionInGroupIndex(undefined);
    }
  };

  const handleUpdateDeflection = async (deflection: AppDeflectionFragment) => {
    if (!editingDeflection || deflection.id !== editingDeflection.id) {
      throw new Error();
    }

    const deflectionBoxIndex = deflectionRules.deflection.findIndex(
      (d) => d.id === deflection.id
    );

    apollo.cache.writeQuery<FlowOfferRulesQuery, FlowOfferRulesQueryVariables>({
      query: TheFlowOfferRulesQuery,
      variables: { flowId },
      data: {
        ...deflectionRules,
        deflection: [
          ...deflectionRules.deflection.slice(0, deflectionBoxIndex),
          deflection,
          ...deflectionRules.deflection.slice(deflectionBoxIndex + 1),
        ],
      },
    });

    onEditDeflection();

    setEditingDeflection(undefined);
  };

  const handleCreateSegmentGroup = (
    newSegmentGroup: AppSegmentGroupFragment
  ) => {
    if (!ruleEditorValue) {
      throw new Error();
    }

    apollo.cache.writeQuery<FlowOfferRulesQuery, FlowOfferRulesQueryVariables>({
      query: TheFlowOfferRulesQuery,
      variables: { flowId },
      data: {
        ...deflectionRules,
        segment_group: [...deflectionRules.segment_group, newSegmentGroup],
      },
    });

    setRuleEditorValue({
      ...ruleEditorValue,
      segmentGroupIds: [...ruleEditorValue.segmentGroupIds, newSegmentGroup.id],
    });
  };

  const handleCreateSegment = (newSegment: SegmentFragment) => {
    if (!ruleEditorValue) {
      throw new Error();
    }

    apollo.cache.writeQuery<FlowOfferRulesQuery, FlowOfferRulesQueryVariables>({
      query: TheFlowOfferRulesQuery,
      variables: { flowId },
      data: {
        ...deflectionRules,
        segment: [...deflectionRules.segment, newSegment],
      },
    });

    setRuleEditorValue({
      ...ruleEditorValue,
      segmentIds: [...ruleEditorValue.segmentIds, newSegment.id],
    });
  };

  const setEditingRule = (rule: FlowOfferRuleFragment) => {
    setEditingId(rule.id);

    const ruleVersion = getObjectVersion(rule);

    setRuleEditorValue({
      type: "flow_deflection_rule",
      segmentGroupIds: ruleVersion.segment_group_ids,
      segmentIds: ruleVersion.segment_ids,
      newConditions: [],
      questionOptionIds: ruleVersion.question_option_ids,
      includeOtherInQuestionIds: ruleVersion.include_other_in_question_ids,
      groups: ruleVersion.offer_rule_version_offer_rule_rules
        .map((r) => r.offer_rule_rule)
        .filter(isPresent)
        .map((r) => ({
          weight: r.weight,
          offerIds: [],
          offerTestIds: [],
          offerGroupIds: [],
          offerAutopilotOfferIds: [],
          deflectionIds: r.deflection_ids,
          includePresentNoOffer: r.include_present_no_offer,
        })),
    });
  };

  const handleAddRule = async (options?: AddRuleOptions) => {
    if (editingId) {
      return;
    }
    const rule = { ...defaultRule };
    setEditingRule(rule);
    setEditingNewRule(true);
  };

  const handleFlowOfferDragEnd = async (
    result: DropResult,
    provided: ResponderProvided
  ) => {
    if (!result.destination) {
      return;
    }

    const reordered = reorderRules(
      rules,
      result.source.index,
      result.destination.index
    );

    apollo.writeQuery<FlowOfferRulesQuery, FlowOfferRulesQueryVariables>({
      query: TheFlowOfferRulesQuery,
      variables: { flowId },
      data: {
        ...deflectionRules,
        deflection_rule_group: [
          {
            ...deflectionRuleGroup,
            draft_version: {
              ...groupVersion,
              offer_rule_group_version_offer_rules: reordered.map((r) => ({
                __typename: "offer_rule_group_version_offer_rule",
                offer_rule: r,
              })),
            },
          },
        ],
      },
    });

    manager.moveRule(
      ruleGroupId,
      rules,
      result.source.index,
      result.destination.index
    );
  };

  const handleClickRule = (ruleId: number) => {
    if (!editingId) {
      const rule = rules.find((rule) => rule.id === ruleId);

      if (rule) {
        setEditingNewRule(false);
        setEditingRule(rule);
      }
    }
  };

  const handleSave = async () => {
    if (!ruleEditorValue) {
      return;
    }

    setIsSaving(true);

    const ruleValues = {
      flowId,
      segmentGroupIds: ruleEditorValue.segmentGroupIds,
      segmentIds: ruleEditorValue.segmentIds,
      newConditions: ruleEditorValue.newConditions,
      questionOptionIds: ruleEditorValue.questionOptionIds,
      includeOtherInQuestionIds: ruleEditorValue.includeOtherInQuestionIds,
      rules: ruleEditorValue.groups,
    };

    if (editingNewRule) {
      const newRule = await manager.addRule(ruleGroupId);
      await manager.updateRule(newRule.id, ruleValues);
    } else if (editingRule) {
      await manager.updateRule(editingRule.id, ruleValues);
    }

    setEditingId(null);
    setRuleEditorValue(undefined);

    setIsSaving(false);
  };

  const handleCancel = () => {
    setEditingId(null);
    setRuleEditorValue(undefined);
  };

  const handleDeleteRule = async (ruleId: number) => {
    setDeletingId(ruleId);

    try {
      await manager.deleteRule(ruleGroupId, ruleId);
    } catch (e) {
      addToast(<div>Failed to offer delete deflection rule.</div>, {
        appearance: "error",
      });
    } finally {
      setDeletingId(null);
    }
  };

  const editingRule = editingId
    ? rules.find((rule) => rule.id === editingId)
    : null;

  const radioQuestions = questions.filter(
    (question) => question.type === "radio"
  );

  if (!deflectionRules) {
    return <Spinner />;
  }

  return (
    <>
      <DeflectionPanel
        key={createDeflectionPanelKey}
        mode="create"
        isOpen={createDeflectionPanelIsOpen}
        onClose={(newDeflection) => {
          setCreateDeflectionPanelIsOpen(false);
          setTimeout(() => {
            setCreateDeflectionPanelKey(nanoid());
          }, 733);
          if (newDeflection) {
            handleCreateDeflection(newDeflection);
          }
        }}
      />
      <DeflectionPanel
        key={editDeflectionPanelKey}
        mode="edit"
        isOpen={editDeflectionPanelIsOpen}
        deflection={editingDeflection}
        onClose={(editedDeflection) => {
          setEditDeflectionPanelIsOpen(false);
          setTimeout(() => {
            setEditDeflectionPanelKey(nanoid());
          }, 733);
          if (editedDeflection) {
            handleUpdateDeflection(editedDeflection);
          }
        }}
      />
      {!!platform && (
        <>
          <SegmentGroupPanel
            mode="create"
            key={createSegmentGroupPanelKey}
            isOpen={createSegmentGroupPanelIsOpen}
            onClose={(newSegmentGroup) => {
              setCreateSegmentGroupPanelIsOpen(false);
              window.setTimeout(() => {
                setCreateSegmentGroupPanelKey(nanoid());
              }, 733);

              if (newSegmentGroup) {
                handleCreateSegmentGroup(newSegmentGroup);
              }
            }}
          />
          <CreateSegmentPanel
            mode="create"
            key={createSegmentPanelKey}
            isOpen={createSegmentPanelIsOpen && !createPropertyPanelIsOpen}
            platform={platform}
            onClose={(newSegment) => {
              setCreateSegmentPanelIsOpen(false);
              window.setTimeout(() => {
                setCreateSegmentPanelKey(nanoid());
              }, 733);

              if (newSegment) {
                handleCreateSegment(newSegment);
              }
            }}
            onClickCreateProperty={(entity) => {
              setDefaultPropertyEntity(entity);
              setCreatePropertyPanelIsOpen(true);
            }}
            newProperty={newProperty}
          />
          <PropertyPanel
            key={createPropertyPanelKey}
            mode="create"
            isOpen={createPropertyPanelIsOpen}
            onClose={async (property) => {
              setCreatePropertyPanelIsOpen(false);
              if (property) {
                setNewProperty(property);
              }

              setTimeout(() => {
                setCreatePropertyPanelKey(nanoid());
              }, 500);
            }}
            defaultEntity={defaultPropertyEntity}
          />
        </>
      )}
      <UpsellBanner
        isVisible={isFreeMode}
        featureText="Access offer rules"
        mode={"overlay" as BannerMode}
        onClick={onClickUpgrade}
      />
      <RulesContainer>
        <DragDropContext onDragEnd={handleFlowOfferDragEnd}>
          <HelpContainer>
            <HelpIcon>
              <FontAwesomeIcon icon={faInfoCircle} />
            </HelpIcon>
            <HelpText>
              Present deflections based on segments
              {showQuestions && " and question answers"}. Rules are evaluated
              top down; a deflection from the first matching rule will be
              presented.{" "}
              <StandardExternalLink
                href="https://docs.prosperstack.com/cancellation-flows/creating-a-deflection#deflection-rules"
                target="_blank"
                rel="noopener noreferrer"
              >
                See the documentation.
              </StandardExternalLink>
            </HelpText>
          </HelpContainer>
          <div tw="bg-white border border-divider rounded shadow-sm p-0 mb-4">
            <RuleHeader className={editingId ? "rule-is-editing" : undefined}>
              <RuleHeaderDragColumn></RuleHeaderDragColumn>
              <RuleHeaderColumn css={showQuestions ? tw`w-1/3` : tw`w-1/2`}>
                Condition
              </RuleHeaderColumn>
              <RuleHeaderColumn css={!showQuestions ? tw`hidden` : undefined}>
                Answer
              </RuleHeaderColumn>
              <RuleHeaderColumn css={showQuestions ? tw`w-1/3` : tw`w-1/2`}>
                Deflection
              </RuleHeaderColumn>
              <RuleHeaderDeleteColumn></RuleHeaderDeleteColumn>
            </RuleHeader>
            {rules.length === 0 && (
              <RulesZeroState>
                Save customers with targeted deflections. Rules define which
                customers see which deflections.{" "}
                <StandardLinkButton onClick={() => handleAddRule()}>
                  Create a rule.
                </StandardLinkButton>
              </RulesZeroState>
            )}

            <Droppable droppableId="flowOffers">
              {(provided, droppableSnapshot) => (
                <Rules
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className={editingId ? "rule-is-editing" : undefined}
                >
                  <RuleEditorOverlay visible={!!deletingId} />

                  {rules.map((rule, index) => {
                    const ruleVersion = getObjectVersion(rule);

                    return (
                      <Draggable
                        key={rule.id}
                        draggableId={String(rule.id)}
                        index={index}
                        isDragDisabled={!!editingId || rules.length === 1}
                      >
                        {(provided, snapshot) => {
                          const isEditing = rule.id === editingId;

                          const ruleRules =
                            ruleVersion.offer_rule_version_offer_rule_rules
                              .map((r) => r.offer_rule_rule)
                              .filter(isPresent)
                              .map((r) => ({
                                weight: r.weight,
                                offerIds: [],
                                offerTestIds: [],
                                offerGroupIds: [],
                                deflectionIds: r.deflection_ids,
                                includePresentNoOffer:
                                  r.include_present_no_offer,
                              }));

                          return (
                            <RuleRow
                              ref={(el) => {
                                ruleRefs.current[rule.id] = el;
                                provided.innerRef(el);
                              }}
                              {...provided.draggableProps}
                              className={classNames({
                                "rule-is-dragging": snapshot.isDragging,
                                "rule-is-editing": isEditing,
                                "rule-editing-is-disabled":
                                  editingId !== null && !isEditing,
                                "rule-is-deleting": deletingId === rule.id,
                              })}
                            >
                              {isEditing ? (
                                <div tw="w-full h-full flex flex-col">
                                  <div tw="flex items-center px-4 py-2 border-b border-divider">
                                    <div tw="text-lg font-title">
                                      {editingNewRule ? "Add" : "Edit"} rule{" "}
                                      {!editingNewRule && (
                                        <span tw="text-type-light font-body">
                                          {index + 1} of {rules.length}
                                        </span>
                                      )}
                                    </div>
                                    <div tw="ml-auto">
                                      <Button
                                        type="button"
                                        onClick={handleCancel}
                                        disabled={isSaving}
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        type="button"
                                        buttonType="primary"
                                        onClick={handleSave}
                                        disabled={isSaving}
                                        isLoading={isSaving}
                                      >
                                        Save
                                      </Button>
                                    </div>
                                  </div>
                                  <div tw="w-full h-full overflow-hidden">
                                    {ruleEditorValue && (
                                      <RuleEditor
                                        segmentGroupsEnabled={
                                          !!features.segment_groups
                                        }
                                        offerRuleGroupsEnabled={
                                          !!features.offer_rule_groups
                                        }
                                        flowId={flowId}
                                        platform={platform}
                                        segmentGroups={
                                          deflectionRules.segment_group
                                        }
                                        segments={deflectionRules.segment}
                                        inlineSegments={
                                          deflectionRules.inlineSegments
                                        }
                                        properties={deflectionRules.property}
                                        questions={radioQuestions}
                                        offers={undefined}
                                        offerGroups={undefined}
                                        offerTests={undefined}
                                        deflections={deflectionRules.deflection}
                                        flowText={flowText}
                                        value={ruleEditorValue}
                                        onChange={(value) => {
                                          if (
                                            value.type !==
                                            "flow_deflection_rule"
                                          ) {
                                            throw new Error();
                                          }
                                          setRuleEditorValue(value);
                                        }}
                                        onClickCreateSegmentGroup={() =>
                                          setCreateSegmentGroupPanelIsOpen(true)
                                        }
                                        onClickCreateSegment={() =>
                                          setCreateSegmentPanelIsOpen(true)
                                        }
                                        onClickCreateDeflection={(
                                          groupIndex
                                        ) => {
                                          setCreatingDeflectionInGroupIndex(
                                            groupIndex
                                          );
                                          setCreateDeflectionPanelIsOpen(true);
                                        }}
                                        onClickEditDeflection={(id) => {
                                          const deflection = deflections.find(
                                            (d) => d.id === id
                                          );
                                          if (!!deflection) {
                                            setEditingDeflection(deflection);
                                            setEditDeflectionPanelIsOpen(true);
                                          }
                                        }}
                                      />
                                    )}
                                  </div>
                                </div>
                              ) : (
                                <RuleBody>
                                  <RuleDrag>
                                    <DragHandle
                                      {...provided.dragHandleProps}
                                      className={
                                        editingId || rules.length === 1
                                          ? "is-disabled"
                                          : undefined
                                      }
                                    >
                                      <FontAwesomeIcon icon={faGripVertical} />
                                    </DragHandle>
                                  </RuleDrag>
                                  <RuleColumns
                                    className="rule-columns"
                                    onClick={() => handleClickRule(rule.id)}
                                  >
                                    <RuleColumn
                                      css={
                                        showQuestions ? tw`w-1/3` : tw`w-1/2`
                                      }
                                    >
                                      <RuleSegments
                                        segmentGroups={ruleVersion.offer_rule_version_segment_groups
                                          .map((g) => g.segment_group)
                                          .filter(isPresent)}
                                        segments={ruleVersion.offer_rule_version_segments
                                          .map((s) =>
                                            deflectionRules.segment.find(
                                              (test) => test.id === s.segment_id
                                            )
                                          )
                                          .filter(isPresent)}
                                        inlineSegments={ruleVersion.offer_rule_version_segments
                                          .map((s) =>
                                            deflectionRules.inlineSegments.find(
                                              (test) => test.id === s.segment_id
                                            )
                                          )
                                          .filter(isPresent)}
                                        newConditions={[]}
                                      />
                                    </RuleColumn>
                                    <RuleColumn
                                      css={
                                        !showQuestions ? tw`hidden` : undefined
                                      }
                                    >
                                      <BoxContainer>
                                        {!ruleVersion
                                          .offer_rule_version_question_options
                                          .length &&
                                        !ruleVersion
                                          .include_other_in_question_ids
                                          .length ? (
                                          !!questions.length ? (
                                            <AnyBox readOnly={true} isOn={true}>
                                              Any
                                            </AnyBox>
                                          ) : (
                                            <span style={{ marginTop: "3px" }}>
                                              No available questions.
                                            </span>
                                          )
                                        ) : (
                                          <>
                                            {ruleVersion.offer_rule_version_question_options.map(
                                              ({
                                                question_option: questionOption,
                                              }) => {
                                                if (!questionOption) {
                                                  throw new Error(
                                                    "Question option is missing"
                                                  );
                                                }

                                                const questionOptionVersion =
                                                  getObjectVersion(
                                                    questionOption
                                                  );

                                                return (
                                                  <QuestionOptionBox
                                                    key={questionOption.id}
                                                    isOn={true}
                                                  >
                                                    {translationText(
                                                      questionOptionVersion.title_translation
                                                    )}
                                                  </QuestionOptionBox>
                                                );
                                              }
                                            )}
                                          </>
                                        )}
                                        {ruleVersion.include_other_in_question_ids.map(
                                          () => (
                                            <QuestionOptionBox
                                              readOnly
                                              isOn={true}
                                            >
                                              <FormattedMessage
                                                defaultMessage="Other"
                                                id="/VnDMl"
                                              />
                                            </QuestionOptionBox>
                                          )
                                        )}
                                      </BoxContainer>
                                    </RuleColumn>
                                    <RuleColumn
                                      css={
                                        showQuestions ? tw`w-1/3` : tw`w-1/2`
                                      }
                                    >
                                      {ruleRules.map((r, ruleIndex) => (
                                        <div
                                          key={`rule-${ruleIndex}`}
                                          tw="relative mb-2 pb-2 border-b last:border-none last:pb-0 last:mb-0"
                                        >
                                          {ruleRules.length > 1 && (
                                            <div
                                              css={[
                                                tw`mb-2`,
                                                isEditing
                                                  ? undefined
                                                  : tw`-mt-1 bg-gray-100 text-gray-800 rounded-md inline-block px-2`,
                                              ]}
                                            >
                                              <FontAwesomeIcon
                                                icon={faWeightHanging}
                                              />{" "}
                                              Weight: {r.weight}
                                            </div>
                                          )}
                                          <OfferRuleDeflections
                                            rule={r}
                                            deflections={
                                              deflectionRules.deflection
                                            }
                                          />
                                        </div>
                                      ))}
                                    </RuleColumn>
                                  </RuleColumns>
                                  <RuleDelete>
                                    {!editingId && (
                                      <button
                                        onClick={async (event) => {
                                          event.stopPropagation();
                                          await handleDeleteRule(rule.id);
                                        }}
                                        disabled={deletingId === rule.id}
                                      >
                                        <FontAwesomeIcon icon={faTimesCircle} />
                                      </button>
                                    )}
                                  </RuleDelete>
                                </RuleBody>
                              )}
                            </RuleRow>
                          );
                        }}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </Rules>
              )}
            </Droppable>
          </div>
        </DragDropContext>
        <div tw="-mt-1">
          <Button
            type="button"
            buttonType="alternate-secondary"
            onClick={() => handleAddRule()}
            disabled={!!editingId || !!deletingId}
          >
            <FontAwesomeIcon icon={faPlus} transform="left-1" /> Add rule
          </Button>
        </div>
      </RulesContainer>
    </>
  );
};

export default DeflectionRules;
