import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  $getSelection,
  $isRangeSelection,
  $isRootNode,
  COMMAND_PRIORITY_EDITOR,
  createCommand,
} from "lexical";
import { useEffect } from "react";

import { property_format_enum } from "../../../__generated__/graphql";
import { PropertyTag } from "../../editor/lib/PropertyTagPlugin/types";
import { $createPropertyNode, PropertyNode } from "../nodes/PropertyNode";

export interface InsertPropertyPayload {
  tag: PropertyTag;
  propertyId?: string;
  dateFormat?: string;
  numberFormat?: property_format_enum | null;
  fallback?: string;
  isArray?: boolean;
}

export const INSERT_PROPERTY_COMMAND = createCommand<InsertPropertyPayload>();

const PropertyPlugin: React.FunctionComponent = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([PropertyNode])) {
      throw new Error("PropertyPlugin: PropertyNode not registered on editor");
    }

    const removeListener = editor.registerCommand<InsertPropertyPayload>(
      INSERT_PROPERTY_COMMAND,
      (payload) => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          if ($isRootNode(selection.anchor.getNode())) {
            selection.insertParagraph();
          }
          const propertyNode = $createPropertyNode(
            payload.tag,
            payload.propertyId,
            payload.dateFormat,
            payload.numberFormat,
            payload.fallback,
            payload.isArray
          );
          selection.insertNodes([propertyNode]);
        }
        return true;
      },
      COMMAND_PRIORITY_EDITOR
    );

    return () => {
      removeListener();
    };
  }, [editor]);

  return null;
};

export default PropertyPlugin;
