import { DecoratorNode, EditorConfig, LexicalNode, NodeKey } from "lexical";
import { ReactNode } from "react";
import tw from "twin.macro";

import { property_format_enum } from "../../../__generated__/graphql";
import { usePropertyValues } from "../../../features/properties/lib/propertyValues";
import renderPropertyTag from "../../editor/lib/PropertyTagPlugin/renderPropertyTag";
import { PropertyTag } from "../../editor/lib/PropertyTagPlugin/types";

interface PropertyComponentProps {
  tag: PropertyTag;
  propertyId?: string;
  dateFormat?: string;
  fallback?: string;
  numberFormat?: property_format_enum | null;
  isArray?: boolean;
}

export interface SerializedPropertyNode {
  type: "property";
  version: number;
  tag: string;
  propertyId?: string;
  dateFormat?: string;
  numberFormat?: property_format_enum | null;
  fallback?: string;
  isArray?: boolean;
}

const PropertyComponent: React.FunctionComponent<PropertyComponentProps> = ({
  tag,
  propertyId,
  dateFormat,
  numberFormat,
  fallback,
  isArray,
}) => {
  const { propertyValues, propertyConfig, showPlaceholders } =
    usePropertyValues();

  return (
    <span
      css={
        showPlaceholders
          ? tw`bg-gray-100 px-1 rounded border border-gray-100 whitespace-nowrap`
          : undefined
      }
    >
      {renderPropertyTag(
        propertyValues,
        propertyConfig,
        tag,
        propertyId || "",
        dateFormat || "",
        numberFormat || null,
        fallback,
        isArray,
        showPlaceholders
      )}
    </span>
  );
};

export class PropertyNode extends DecoratorNode<ReactNode> {
  __tag: PropertyTag;
  __propertyId?: string;
  __dateFormat?: string;
  __numberFormat?: property_format_enum | null;
  __fallback?: string;
  __isArray?: boolean;

  static getType() {
    return "property";
  }

  static clone(node: PropertyNode) {
    return new PropertyNode(
      node.__tag,
      node.__propertyId,
      node.__dateFormat,
      node.__numberFormat,
      node.__fallback,
      node.__isArray,
      node.__key
    );
  }

  constructor(
    tag: PropertyTag,
    propertyId?: string,
    dateFormat?: string,
    numberFormat?: property_format_enum | null,
    fallback?: string,
    isArray?: boolean,
    key?: NodeKey
  ) {
    super(key);
    this.__tag = tag;
    this.__propertyId = propertyId;
    this.__dateFormat = dateFormat;
    this.__numberFormat = numberFormat;
    this.__fallback = fallback;
    this.__isArray = isArray;
  }

  createDOM(config: EditorConfig) {
    const el = document.createElement("span");
    const theme = config.theme;
    const className = theme.property;
    if (typeof className === "string") {
      el.className = className;
    }
    return el;
  }

  updateDOM() {
    return false;
  }

  decorate() {
    return (
      <PropertyComponent
        tag={this.__tag}
        propertyId={this.__propertyId}
        dateFormat={this.__dateFormat}
        numberFormat={this.__numberFormat || null}
        fallback={this.__fallback}
        isArray={this.__isArray}
      />
    );
  }

  static importJSON(serializedNode: SerializedPropertyNode) {
    return $createPropertyNode(
      serializedNode.tag as PropertyTag,
      serializedNode.propertyId,
      serializedNode.dateFormat,
      serializedNode.numberFormat,
      serializedNode.fallback,
      serializedNode.isArray
    );
  }

  exportJSON(): SerializedPropertyNode {
    return {
      type: "property",
      version: 1,
      tag: this.__tag,
      propertyId: this.__propertyId,
      dateFormat: this.__dateFormat,
      numberFormat: this.__numberFormat,
      fallback: this.__fallback,
      isArray: this.__isArray,
    };
  }
}

const $createPropertyNode = (
  tag: PropertyTag,
  propertyId?: string,
  dateFormat?: string,
  numberFormat?: property_format_enum | null,
  fallback?: string,
  isArray?: boolean
) =>
  new PropertyNode(
    tag,
    propertyId,
    dateFormat,
    numberFormat,
    fallback,
    isArray
  );

const $isPropertyNode = (node?: LexicalNode): node is PropertyNode =>
  node instanceof PropertyNode;

export { $createPropertyNode, $isPropertyNode };
