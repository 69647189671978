import {
  faArrowLeftLong,
  faArrowRightLong,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import tw, { styled } from "twin.macro";

const Wrapper = tw.div`flex w-full`;
const PrevNext = tw.button`disabled:text-gray-400 disabled:cursor-default focus:outline-none py-1 px-2 rounded hover:bg-gray-100 hover:disabled:bg-white`;
const Pages = tw.div`border-transparent border-l-4 border-r-4 flex-1 flex justify-center`;
const Page = styled.button<{ active: boolean }>`
  ${tw`flex items-center py-1 px-2 rounded text-center mr-1 last:mr-0 focus:outline-none hover:bg-gray-100`}
  ${(props) => props.active && tw`bg-gray-100 font-semibold cursor-default`}
`;
const Ellipsis = tw.div`flex items-center py-1 px-2 text-center mr-1 last:mr-0`;

interface PaginationProps {
  current: number;
  pages: number;
  isLoading?: boolean;
  setPage: (page: number) => void;
}

const getRange = (start: number, end: number) => {
  const rangeLength = end - start + 1;
  return Array(rangeLength)
    .fill(0)
    .map((_, i) => i + start);
};

const clamp = (value: number, lower: number, upper: number) =>
  Math.min(Math.max(value, lower), upper);

const calculatePages = (
  currentPage: number,
  pageCount: number,
  size: number
) => {
  if (pageCount < 1) {
    return [];
  }

  if (currentPage < 1) {
    currentPage = 1;
  }

  if (currentPage > pageCount) {
    currentPage = pageCount;
  }

  if (size % 2 === 0) {
    size += 1;
  }

  if (size < 5) {
    size = 5;
  }

  const offset = (size - 1) / 2;
  const shouldAddDots = pageCount > size;

  const rangeConfig = {
    start: clamp(
      currentPage - offset,
      1,
      shouldAddDots ? pageCount - size + 1 : 1
    ),
    end: clamp(currentPage + offset, size, pageCount),
  };

  const pages = getRange(rangeConfig.start, rangeConfig.end);

  if (shouldAddDots && pages[0] !== 1) {
    pages[0] = 1;
    pages[1] = -1;
  }
  if (shouldAddDots && pages[pages.length - 1] !== pageCount) {
    pages[pages.length - 1] = pageCount;
    pages[pages.length - 2] = -1;
  }
  return pages;
};

const Pagination: React.FunctionComponent<PaginationProps> = ({
  current,
  pages,
  isLoading,
  setPage,
}) => {
  if (pages < 1) {
    pages = 1;
  }

  if (current > pages) {
    current = pages;
  }

  const pageNav = calculatePages(current, pages, 9);

  return (
    <Wrapper>
      <PrevNext
        disabled={current === 1 || isLoading}
        onClick={() => setPage(current - 1)}
      >
        <FontAwesomeIcon icon={faArrowLeftLong} tw="mr-1" transform="down-1" />{" "}
        Prev
      </PrevNext>
      <Pages>
        {pageNav.map((p, i) =>
          p < 0 ? (
            <Ellipsis key={`ellipsis-${i}`}>…</Ellipsis>
          ) : (
            <Page
              key={p}
              active={current === p}
              onClick={() => setPage(p)}
              disabled={isLoading}
            >
              {p.toLocaleString()}
            </Page>
          )
        )}
      </Pages>
      <PrevNext
        disabled={current === pages || isLoading}
        onClick={() => setPage(current + 1)}
      >
        Next{" "}
        <FontAwesomeIcon icon={faArrowRightLong} tw="ml-1" transform="down-1" />
      </PrevNext>
    </Wrapper>
  );
};

export default Pagination;
