import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import tw, { css } from "twin.macro";

import loomLogo from "../../assets/loom-logo.svg";
import vimeoLogo from "../../assets/vimeo-logo.png";
import wistiaLogo from "../../assets/wistia-logo.png";
import youtubeLogo from "../../assets/youtube-logo.svg";
import parseVideoURL from "../../features/flow/video/lib/parseVideoURL";
import Video from "../../features/flow/video/Video";
import Button from "../form/Button";
import FieldCheckboxInput from "../form/FieldCheckboxInput";
import FieldHint from "../form/FieldHint";
import FieldInput from "../form/FieldInput";
import FieldLabel from "../form/FieldLabel";
import FieldRow from "../form/FieldRow";
import TextInput from "../form/input/TextInput";
import Modal, { ModalProps } from "../modal/Modal";
import ModalFooter from "../modal/ModalFooter";
import ModalFormBody from "../modal/ModalFormBody";
import ModalHeader from "../modal/ModalHeader";
import PillRadio from "../PillRadio";
import useFocusFirstEmptyInput from "../useFocusFirstEmptyInput";

interface FormValues {
  url: string;
  autoPlay: boolean;
}

type InsertVideoModalProps = ModalProps & {
  mode: "insert" | "edit";
  initialValues?: FormValues;
  onClose: (values: FormValues | undefined) => void;
};

const InsertVideoModal: React.FunctionComponent<InsertVideoModalProps> = ({
  mode,
  initialValues,
  onClose,
  ...props
}) => {
  const { register, handleSubmit, watch, setValue, reset } =
    useForm<FormValues>({
      defaultValues: initialValues,
    });

  const [formRef, setFormRef] = useState<HTMLFormElement | null>(null);
  useFocusFirstEmptyInput(formRef);

  let initialProvider: string | undefined = undefined;
  let initialVideoId: string | undefined | null = undefined;
  if (initialValues?.url) {
    const parsed = parseVideoURL(initialValues.url);
    initialProvider = parsed?.provider;
    initialVideoId = parsed?.videoId;
  }

  const [provider, setProvider] = useState<string | undefined>(initialProvider);
  const [videoId, setVideoId] = useState<string | undefined | null>(
    initialVideoId
  );

  const url = watch("url");

  useEffect(() => {
    let initialProvider: string | undefined = undefined;
    let initialVideoId: string | undefined | null = undefined;
    if (initialValues?.url) {
      const parsed = parseVideoURL(initialValues.url);
      initialProvider = parsed?.provider;
      initialVideoId = parsed?.videoId;
    }

    reset(initialValues);
    setProvider(initialProvider);
    setVideoId(initialVideoId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen]);

  useEffect(() => {
    if (mode !== "insert") {
      return;
    }
    setValue("url", "");
    setVideoId(undefined);
  }, [mode, provider, setValue]);

  useEffect(() => {
    const result = parseVideoURL(url);

    if (result && result.provider === provider && result.videoId) {
      setVideoId(result.videoId);
    } else {
      setVideoId(undefined);
    }
  }, [mode, provider, setValue, url]);

  useEffect(() => {
    const parsed = parseVideoURL(url);
    if (provider !== parsed?.provider) {
      setValue("url", "");
      setValue("autoPlay", false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider]);

  const onSubmit = handleSubmit((values) => {
    if (!videoId) {
      throw new Error();
    }

    onClose(values);
  });

  return (
    <Modal {...props}>
      <ModalHeader>
        {mode === "insert" ? "Insert video" : "Edit video"}
      </ModalHeader>
      <ModalFormBody>
        <form
          id="insert-video"
          onSubmit={onSubmit}
          ref={(ref) => setFormRef(ref)}
        >
          <FieldRow>
            <FieldLabel>Video provider</FieldLabel>
            <FieldInput>
              <PillRadio
                borderHighlight={true}
                value={provider || ""}
                onChange={(newValue) => setProvider(newValue)}
                options={[
                  {
                    label: (
                      <span>
                        <img
                          src={vimeoLogo}
                          css={css`
                            width: 50px;
                            display: inline;
                            margin: -5px 4px 0 4px;
                          `}
                          alt="Vimeo"
                        />
                      </span>
                    ),
                    value: "vimeo",
                  },
                  {
                    label: (
                      <span>
                        <img
                          src={wistiaLogo}
                          css={css`
                            width: 80px;
                            display: inline;
                            margin-top: -2px;
                          `}
                          alt="Wistia"
                        />
                      </span>
                    ),
                    value: "wistia",
                  },
                  {
                    label: (
                      <span>
                        <img
                          src={youtubeLogo}
                          css={css`
                            width: 60px;
                            display: inline;
                            margin: -2px 4px 0 4px;
                          `}
                          alt="YouTube"
                        />
                      </span>
                    ),
                    value: "youtube",
                  },
                  {
                    label: (
                      <span>
                        <img
                          src={loomLogo}
                          css={css`
                            width: 54px;
                            display: inline;
                            margin: -2px 4px 0 4px;
                          `}
                          alt="Loom"
                        />
                      </span>
                    ),
                    value: "loom",
                  },
                ]}
              />
            </FieldInput>
          </FieldRow>
          <FieldRow css={!provider ? tw`hidden` : undefined}>
            <FieldLabel>
              <label>Video URL</label>
            </FieldLabel>
            <FieldInput>
              <TextInput
                {...register("url", {
                  required: true,
                  validate: (value) => !!videoId,
                })}
                width="full"
              />
              <FieldHint>
                Paste the URL to your video, e.g.{" "}
                {provider === "wistia"
                  ? "https://home.wistia.com/medias/…"
                  : provider === "vimeo"
                  ? "https://vimeo.com/…"
                  : provider === "loom"
                  ? "https://www.loom.com/share/…"
                  : provider === "youtube"
                  ? "https://www.youtube.com/watch?v=…"
                  : null}
              </FieldHint>
            </FieldInput>
          </FieldRow>
          {provider && videoId && (
            <FieldRow>
              <FieldLabel>
                <label>Preview</label>
              </FieldLabel>
              <FieldInput>
                {provider && videoId ? (
                  <div
                    css={[
                      css`
                        width: 300px;
                      `,
                      tw`border-gray-400 border-2`,
                    ]}
                  >
                    <Video url={url} autoPlay={false} />
                  </div>
                ) : (
                  <div
                    css={[
                      css`
                        width: 300px;
                        height: 168.75px;
                      `,
                      tw`border-divider border-dashed border-2 rounded flex items-center justify-center text-gray-400`,
                    ]}
                  >
                    Preview
                  </div>
                )}
              </FieldInput>
            </FieldRow>
          )}
          <FieldRow css={!provider || !videoId ? tw`hidden` : undefined}>
            <FieldLabel>
              <label>Options</label>
            </FieldLabel>
            <FieldCheckboxInput tw="pt-0">
              <input {...register("autoPlay")} id="autoPlay" type="checkbox" />
              <label htmlFor="autoPlay">Auto-play</label>
            </FieldCheckboxInput>
          </FieldRow>
        </form>
      </ModalFormBody>
      <ModalFooter>
        <Button
          type="button"
          buttonType="primary"
          form="insert-video"
          onClick={onSubmit}
        >
          {mode === "insert" ? "Insert video" : "Save"}
        </Button>
        <Button
          type="button"
          buttonType="default"
          onMouseDown={(e) => {
            e.preventDefault();
            onClose(undefined);
          }}
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default InsertVideoModal;
