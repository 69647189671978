import { language_enum } from "../../../../__generated__/graphql";
import { OfferDetails } from "../../../../features/offers/lib/offerDetails";
import getCouponDescription from "../../../../features/public/flow/lib/getCouponDescription";
import getPauseSubscriptionDescription from "../../../../features/public/flow/lib/getPauseSubscriptionDescription";
import getTrialExtensionDescription from "../../../../features/public/flow/lib/getTrialExtensionDescription";
import renderPlaceholder from "../../../../features/public/flow/lib/renderPlaceholder";
import { OfferTag, TAG_DESCRIPTION } from "./types";

export default function renderOfferTag(
  offerDetails: OfferDetails,
  tag: OfferTag,
  language: language_enum
) {
  switch (tag) {
    case TAG_DESCRIPTION:
      switch (offerDetails.type) {
        case "coupon":
          return getCouponDescription(
            offerDetails.couponType,
            offerDetails.amountOff,
            offerDetails.duration,
            offerDetails.durationInterval,
            offerDetails.durationCount,
            offerDetails.months,
            offerDetails.goal,
            language,
            true,
            renderPlaceholder
          );

        case "trial_extension":
          return getTrialExtensionDescription(offerDetails.days);

        case "pause_subscription":
          return getPauseSubscriptionDescription(offerDetails.options);
      }
      break;
  }

  return null;
}
