import {
  ELEMENT_IMAGE,
  ELEMENT_PARAGRAPH,
  SlatePlugin,
} from "@udecode/slate-plugins";
import { Editor, Range, Transforms } from "slate";

import serializeToString from "../serializeToString";
import ImageElement from "./ImageElement";

export default function VideoPlugin(): SlatePlugin {
  return {
    renderElement: (props) => {
      if (props.element.type === ELEMENT_IMAGE) {
        return <ImageElement {...props} />;
      }
    },
    onKeyDown(event, editor) {
      if (!editor.selection) {
        return;
      }

      if (Range.isExpanded(editor.selection)) {
        return;
      }

      if (event.key !== "Backspace" && event.key !== "Delete") {
        return;
      }

      const above = Editor.above(editor);
      if (!above) {
        return;
      }

      const [entry, path] = above;

      if (
        entry.type === ELEMENT_PARAGRAPH &&
        serializeToString([entry]) === ""
      ) {
        if (editor.children.length === 1) {
          event.preventDefault();
          return;
        }

        Transforms.removeNodes(editor, { at: path });
        event.preventDefault();
      }
    },
  };
}
