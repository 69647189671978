import { useApolloClient, useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { cloneDeep } from "lodash";
import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { isPresent } from "ts-is-present";
import tw, { styled } from "twin.macro";

import {
  FlowFormFragment,
  FlowQuestionFragment,
  FormQuestionConditionsPanelQuery,
  question_setting_key_enum,
} from "../../../__generated__/graphql";
import { FormattedQuestionCondition } from "../../../common/flow/formatQuestionConditions";
import Button from "../../../common/form/Button";
import FieldInput from "../../../common/form/FieldInput";
import FieldLabel from "../../../common/form/FieldLabel";
import FieldRow from "../../../common/form/FieldRow";
import FormInstructions from "../../../common/form/FormInstructions";
import TheFlowQuestionFragment from "../../../common/fragments/FlowQuestionFragment";
import Panel, { PanelProps } from "../../../common/panel/Panel";
import PanelButtons from "../../../common/panel/PanelButtons";
import PanelFormBody from "../../../common/panel/PanelFormBody";
import PanelHeader from "../../../common/panel/PanelHeader";
import PanelTitle from "../../../common/panel/PanelTitle";
import {
  AnyBox,
  BoxContainer,
  QuestionOptionBox,
} from "../../../common/rules/RuleBoxes";
import { useTranslations } from "../../../common/translations/TranslationsProvider";
import { useFlowVersion } from "../../public/flow/FlowVersionProvider";
import { FormStepQuestion } from "../../public/flow/lib/types";
import SegmentRuleBox from "../../segments/SegmentRuleBox";
import { FormManager } from "./formManager";
import { QuestionManager } from "./questionManager";

const StyledBoxContainer = styled(BoxContainer)`
  ${tw`py-1 leading-normal`}
`;

const Question = tw.div`py-1 leading-normal`;

const QuestionTitle = tw.div`font-semibold text-gray-800 mb-1`;

type FormQuestionConditionsPanelProps = PanelProps & {
  form: FlowFormFragment;
  formManager: FormManager;
  formStepQuestion: FormStepQuestion;
  questionManager: QuestionManager;
  onClose: () => void;
};

const defaultCondition: FormattedQuestionCondition = {
  questionId: -1,
  segmentMatch: true,
  segmentGroupMatch: true,
  segments: [],
  segmentGroups: [],
  questionOptions: [],
  includeOtherInQuestionIds: [],
};

const FormQuestionConditionsPanel: React.FunctionComponent<
  FormQuestionConditionsPanelProps
> = ({
  form,
  formManager,
  formStepQuestion,
  questionManager,
  onClose,
  isLoading,
  ...props
}) => {
  const { translationText } = useTranslations();
  const [submitting, setSubmitting] = useState(false);
  const [unsavedChanges, setUnsavedChanges] =
    useState<FormattedQuestionCondition>(defaultCondition);
  const { getObjectVersion } = useFlowVersion();

  const question = formStepQuestion.question;
  const formVersion = getObjectVersion(form);

  const { data, loading, refetch, called } =
    useQuery<FormQuestionConditionsPanelQuery>(
      gql`
        query FormQuestionConditionsPanelQuery(
          $formVersionId: Int
          $includeDrafts: Boolean! = true
        ) {
          segment(order_by: { name: asc }, limit: 50) {
            id
            name
          }

          form_version_question(
            where: { form_version_id: { _eq: $formVersionId } }
          ) {
            position
            question {
              ...FlowQuestionFragment
            }
          }
        }
        ${TheFlowQuestionFragment}
      `,
      {
        variables: {
          formVersionId: formVersion.id,
        },
        skip: !formVersion,
      }
    );

  useEffect(() => {
    if (props.isOpen && called) {
      refetch();
    }
  }, [called, props.isOpen, refetch]);

  const apollo = useApolloClient();

  const position =
    data?.form_version_question.find((q) => q.question?.id === question.id)
      ?.position || -1;

  const previousRadioQuestions: FlowQuestionFragment[] = useMemo(
    () =>
      data?.form_version_question
        .filter(
          (q) =>
            q.question &&
            q.question.type === "radio" &&
            q.position &&
            q.position < position
        )
        .map((q) => q.question)
        .filter(isPresent) || [],
    [data?.form_version_question, position]
  );

  const questions =
    data?.form_version_question.filter(isPresent).map((q) => q.question) || [];
  const segments = data?.segment || [];

  useLayoutEffect(() => {
    setUnsavedChanges(
      cloneDeep(formStepQuestion.condition) || defaultCondition
    );
  }, [formStepQuestion.condition]);

  const save = async () => {
    const condition: FormattedQuestionCondition =
      unsavedChanges.segments.length ||
      unsavedChanges.questionOptions.length ||
      unsavedChanges.includeOtherInQuestionIds.length
        ? { ...unsavedChanges, questionId: question.id }
        : {
            questionId: question.id,
            segments: [],
            segmentGroups: [],
            questionOptions: [],
            segmentMatch: true,
            segmentGroupMatch: true,
            includeOtherInQuestionIds: [],
          };

    const version = getObjectVersion(question);

    apollo.writeFragment<FlowQuestionFragment>({
      id: apollo.cache.identify({ ...question }),
      fragment: TheFlowQuestionFragment,
      fragmentName: "FlowQuestionFragment",
      data: {
        ...question,
        draft_version: {
          ...version,
          question_version_condition_question_options:
            condition.questionOptions.map((o) => {
              const question = questions.find(
                (q) => q && q.id === o.questionId
              );
              if (!question) {
                throw new Error("Question not found");
              }

              const questionVersion = getObjectVersion(question);
              const option = questionVersion.question_version_question_options
                .map((test) => test.question_option)
                .find((test) => test?.id === o.id);
              if (!option) {
                throw new Error("Option not found");
              }

              return {
                __typename: "question_version_condition_question_option",
                question_option_id: o.id,
                question_option: option,
              };
            }),
          question_version_condition_segments: condition.segments.map((s) => {
            const segment = segments.find((test) => test.id === s.id);
            if (!segment) {
              throw new Error("Segment not found");
            }

            return {
              __typename: "question_version_condition_segment",
              segment_id: s.id,
              segment,
            };
          }),
          condition_include_other_in_question_ids:
            condition.includeOtherInQuestionIds,
        },
      },
      variables: {
        includeDrafts: true,
      },
    });

    setSubmitting(true);

    await questionManager.updateQuestionCondition(condition);

    setSubmitting(false);

    onClose();
  };

  const close = () => {
    setUnsavedChanges(formStepQuestion.condition || defaultCondition);
    onClose();
  };

  const toggleSegment = (segmentId: number) => {
    const condition = cloneDeep(unsavedChanges);
    const index = condition.segments.findIndex(
      (segment) => segment.id === segmentId
    );

    if (index === -1) {
      condition.segments.push({
        id: segmentId,
      });
    } else {
      condition.segments.splice(index, 1);
    }

    setUnsavedChanges(condition);
  };

  const clearSegments = () => {
    const condition = cloneDeep(unsavedChanges);
    condition.segments = [];
    setUnsavedChanges(condition);
  };

  const toggleOption = (questionId: number, optionId: number) => {
    const condition = cloneDeep(unsavedChanges);
    const index = condition.questionOptions.findIndex(
      (option) => option.id === optionId && option.questionId === questionId
    );

    if (index === -1) {
      condition.questionOptions.push({
        id: optionId,
        questionId,
      });
    } else {
      condition.questionOptions.splice(index, 1);
    }

    setUnsavedChanges(condition);
  };

  const toggleIncludeOther = (questionId: number) => {
    const condition = cloneDeep(unsavedChanges);
    if (condition.includeOtherInQuestionIds.includes(questionId)) {
      condition.includeOtherInQuestionIds =
        condition.includeOtherInQuestionIds.filter((id) => id !== questionId);
    } else {
      condition.includeOtherInQuestionIds = [
        ...condition.includeOtherInQuestionIds,
        questionId,
      ];
    }

    setUnsavedChanges(condition);
  };

  const clearOptions = (questionId: number) => {
    const condition = cloneDeep(unsavedChanges);
    condition.questionOptions = condition.questionOptions.filter(
      (option) => option.questionId !== questionId
    );
    condition.includeOtherInQuestionIds =
      condition.includeOtherInQuestionIds.filter((id) => id !== questionId);
    setUnsavedChanges(condition);
  };

  const panelProps = {
    ...props,
    isLoading: loading,
  };

  return (
    <Panel {...panelProps}>
      <PanelHeader>
        <PanelTitle>Conditions</PanelTitle>
        <PanelButtons>
          <Button
            buttonType="primary"
            onClick={save}
            isLoading={submitting}
            disabled={submitting}
          >
            Save
          </Button>
          <Button
            type="button"
            buttonType="default"
            onClick={close}
            disabled={submitting}
          >
            Cancel
          </Button>
        </PanelButtons>
      </PanelHeader>
      <PanelFormBody>
        <FormInstructions>
          Display this question if <em>any</em> segment matches and <em>any</em>{" "}
          answer matches for <em>each</em> question.
        </FormInstructions>
        <FieldRow>
          <FieldLabel>
            <label>Segments</label>
          </FieldLabel>
          <FieldInput>
            <StyledBoxContainer>
              <AnyBox
                isOn={!!unsavedChanges && !unsavedChanges.segments.length}
                onClick={() => {
                  clearSegments();
                }}
              >
                Any
              </AnyBox>
              {segments.map((segment) => {
                const isOn =
                  !!unsavedChanges &&
                  unsavedChanges.segments.findIndex(
                    (unsavedSegment) => unsavedSegment.id === segment.id
                  ) > -1;

                return (
                  <SegmentRuleBox
                    key={segment.id}
                    isEditable={true}
                    isOn={isOn}
                    onClick={() => toggleSegment(segment.id)}
                    segment={segment}
                  />
                );
              })}
            </StyledBoxContainer>
          </FieldInput>
        </FieldRow>
        {!!previousRadioQuestions.length && (
          <FieldRow>
            <FieldLabel>
              <label>Answers</label>
            </FieldLabel>
            <FieldInput>
              {previousRadioQuestions.map((otherQuestion) => {
                const otherQuestionVersion = getObjectVersion(otherQuestion);

                let hasOtherSpecify = false;
                const otherSpecifySetting =
                  otherQuestion.question_settings.find(
                    (s) => s.key === question_setting_key_enum.other_specify
                  );
                if (otherSpecifySetting) {
                  const settingVersion = getObjectVersion(otherSpecifySetting);
                  hasOtherSpecify = settingVersion.value;
                }

                const options =
                  otherQuestionVersion.question_version_question_options
                    .map((o) => o.question_option)
                    .filter(isPresent);

                return (
                  <Question key={otherQuestion.id}>
                    <QuestionTitle>
                      {translationText(otherQuestionVersion.title_translation)}
                    </QuestionTitle>
                    <StyledBoxContainer>
                      <AnyBox
                        isOn={
                          !!unsavedChanges &&
                          !unsavedChanges.questionOptions.filter(
                            (test) => test.questionId === otherQuestion.id
                          ).length &&
                          !unsavedChanges.includeOtherInQuestionIds.includes(
                            otherQuestion.id
                          )
                        }
                        onClick={() => {
                          clearOptions(otherQuestion.id);
                        }}
                      >
                        Any
                      </AnyBox>
                      {options.map((option) => {
                        const optionVersion = getObjectVersion(option);
                        const isOn =
                          !!unsavedChanges &&
                          unsavedChanges.questionOptions.findIndex(
                            (unsavedOption) =>
                              unsavedOption.id === option.id &&
                              unsavedOption.questionId === otherQuestion.id
                          ) > -1;
                        return (
                          <QuestionOptionBox
                            key={option.id}
                            isOn={isOn}
                            onClick={() => {
                              toggleOption(otherQuestion.id, option.id);
                            }}
                          >
                            {translationText(optionVersion.title_translation)}
                          </QuestionOptionBox>
                        );
                      })}
                      {hasOtherSpecify && (
                        <QuestionOptionBox
                          isOn={
                            !!unsavedChanges &&
                            unsavedChanges.includeOtherInQuestionIds.includes(
                              otherQuestion.id
                            )
                          }
                          onClick={() => toggleIncludeOther(otherQuestion.id)}
                        >
                          <FormattedMessage
                            defaultMessage="Other"
                            id="/VnDMl"
                          />
                        </QuestionOptionBox>
                      )}
                    </StyledBoxContainer>
                  </Question>
                );
              })}
            </FieldInput>
          </FieldRow>
        )}
      </PanelFormBody>
    </Panel>
  );
};

export default FormQuestionConditionsPanel;
