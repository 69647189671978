import { gql, useMutation } from "@apollo/client";

import {
  UseUpdateOfferDowngradeMutation,
  UseUpdateOfferDowngradeMutationVariables,
} from "../../../__generated__/graphql";

export default function useUpdateOfferDowngrade() {
  const [updateOfferDowngrade] = useMutation<
    UseUpdateOfferDowngradeMutation,
    UseUpdateOfferDowngradeMutationVariables
  >(gql`
    mutation UseUpdateOfferDowngradeMutation(
      $offerId: Int!
      $changeAt: offer_change_plan_change_at_enum!
    ) {
      update_offer_downgrade_by_pk(
        pk_columns: { offer_id: $offerId }
        _set: { change_at: $changeAt }
      ) {
        offer_id
        change_at
        offer {
          id
          offer_downgrade {
            offer_id
            change_at
          }
        }
      }
    }
  `);

  return updateOfferDowngrade;
}
