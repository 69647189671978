import { useState } from "react";
import { useForm } from "react-hook-form";

import Button from "../../form/Button";
import FieldInput from "../../form/FieldInput";
import FieldLabel from "../../form/FieldLabel";
import FieldRow from "../../form/FieldRow";
import TextInput from "../../form/input/TextInput";
import Modal, { ModalProps } from "../../modal/Modal";
import ModalFooter from "../../modal/ModalFooter";
import ModalFormBody from "../../modal/ModalFormBody";
import ModalHeader from "../../modal/ModalHeader";
import useFocusFirstEmptyInput from "../../useFocusFirstEmptyInput";

interface FormValues {
  url: string;
}

interface LinkModalProps extends ModalProps {
  initialValues?: FormValues;
  onClose: (values: FormValues | undefined) => void;
}

const LinkModal: React.FunctionComponent<LinkModalProps> = ({
  initialValues,
  onClose,
  ...props
}) => {
  const { register, handleSubmit } = useForm<FormValues>({
    defaultValues: initialValues,
  });
  const [formRef, setFormRef] = useState<HTMLFormElement | null>(null);
  useFocusFirstEmptyInput(formRef);

  const onSubmit = handleSubmit((values) => onClose(values));

  return (
    <Modal {...props}>
      <ModalHeader>Edit link</ModalHeader>
      <ModalFormBody>
        <form id="edit-link" onSubmit={onSubmit} ref={(ref) => setFormRef(ref)}>
          <FieldRow>
            <FieldLabel>
              <label>URL</label>
            </FieldLabel>
            <FieldInput>
              <TextInput {...register("url")} width="full" />
            </FieldInput>
          </FieldRow>
        </form>
      </ModalFormBody>
      <ModalFooter>
        <Button type="button" buttonType="primary" onClick={onSubmit}>
          Save
        </Button>
        <Button
          type="button"
          buttonType="default"
          onClick={() => onClose(undefined)}
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default LinkModal;
