import gql from "graphql-tag";

import FlowInterventionVersionFragment from "./FlowInterventionVersionFragment";

export default gql`
  fragment FlowInterventionFragment on intervention {
    id
    token
    title
    offer_rule_group_id
    offer_rule_group {
      id
      title
    }
    published_version {
      ...FlowInterventionVersionFragment
    }
    draft_version {
      ...FlowInterventionVersionFragment
    }
  }
  ${FlowInterventionVersionFragment}
`;
