import gql from "graphql-tag";

import FlowActionFragment from "./FlowActionFragment";
import FlowDeflectionSnapshotItemFragment from "./FlowDeflectionSnapshotItemFragment";
import TranslationFragment from "./TranslationFragment";

export default gql`
  fragment FlowDeflectionFragment on deflection {
    id
    title
    token
    heading_translation {
      ...TranslationFragment
    }
    content_translation {
      ...TranslationFragment
    }
    minimum_items
    deflection_snapshot_items: deflection_snapshot_item_positions(
      order_by: { position: asc }
    ) {
      position
      deflection_snapshot_item {
        ...FlowDeflectionSnapshotItemFragment
      }
    }
    deflection_flow_actions(order_by: { position: asc }) {
      position
      flow_action {
        ...FlowActionFragment
      }
    }
  }
  ${TranslationFragment}
  ${FlowDeflectionSnapshotItemFragment}
  ${FlowActionFragment}
`;
