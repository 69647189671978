import classNames from "classnames";
import { nanoid } from "nanoid";
import { useLayoutEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";

import {
  offer_goal_enum,
  offer_style_enum,
} from "../../../../__generated__/graphql";

export interface FlowModalProps {
  isOpen: boolean;
  inline?: boolean;
  addContainerClass?: string;
  style?: offer_style_enum;
  portalContainer?: Element;
  goal?: offer_goal_enum;
  showOverlay?: boolean;
}

const FlowModal: React.FunctionComponent<FlowModalProps> = ({
  isOpen,
  inline = false,
  addContainerClass = null,
  children,
  style = offer_style_enum.modal,
  portalContainer = document.body,
  goal = offer_goal_enum.retention,
  showOverlay = true,
}) => {
  const [id] = useState(nanoid());
  const nodeRef = useRef(null);

  const containerBaseClass =
    style === offer_style_enum.banner
      ? "flow-modal__container flow-modal__container--banner"
      : "flow-modal__container";

  const containerClass = `${containerBaseClass} ${addContainerClass ?? ""}`;

  useLayoutEffect(() => {
    document.body.classList.toggle("modal-open", isOpen);
  }, [isOpen]);

  const renderContent = () => <div className={containerClass}>{children}</div>;

  if (inline) {
    return renderContent();
  }

  return ReactDOM.createPortal(
    <CSSTransition
      nodeRef={nodeRef}
      in={isOpen}
      timeout={200}
      classNames={
        style === "modal"
          ? {
              enter: "flow-modal--enter",
              enterActive: "flow-modal--enter-active",
              enterDone: "flow-modal--enter-done",
              exit: "flow-modal--exit",
              exitActive: "flow-modal--exit-active",
              exitDone: "flow-modal--exit-done",
            }
          : {}
      }
      unmountOnExit={true}
    >
      <div
        ref={nodeRef}
        key={id}
        className={classNames({
          "flow-modal": true,
          "flow-modal--retain": goal === "retention",
          "flow-modal--convert": goal === "conversion",
          "flow-modal--convert--banner":
            goal === "conversion" && style === "banner",
        })}
      >
        {showOverlay && <div className="flow-modal__overlay" />}
        {renderContent()}
      </div>
    </CSSTransition>,
    portalContainer
  );
};

export default FlowModal;
