import tw, { styled } from "twin.macro";

const dragHandleWidth = 40;
const deleteColumnWidth = 40;
const DeEpmphasizeColor = "#f9f9f9";

export const RulesContainer = tw.div`bg-gray-50 p-5 h-full overflow-auto`;
export const HelpContainer = tw.div`text-gray-800 pb-5 flex`;

export const HelpIcon = tw.div`mr-2`;
export const HelpText = styled.div`
  @media (max-width: 1255px) {
    max-width: 56rem;
  }
`;
export const RuleHeader = styled.div`
  ${tw`flex border-b border-gray-200 rounded-t`}

  &.rule-is-editing {
    background: ${DeEpmphasizeColor};
  }
`;
export const RuleHeaderDragColumn = styled.div`
  width: ${dragHandleWidth}px;
`;
export const RuleHeaderColumn = tw.div`text-gray-600 font-semibold p-2 w-1/3`;
export const RuleHeaderDeleteColumn = styled.div`
  width: ${deleteColumnWidth}px;
`;
export const RulesZeroState = styled.div`
  ${tw`p-10 text-center text-gray-600`}
`;
export const Rules = styled.div`
  ${tw`relative`}
  &.rule-is-editing {
    background: ${DeEpmphasizeColor};
    ${tw`static`}
  }
`;
export const RuleRow = styled.div`
  @keyframes expand {
    95% {
      transform: scale(1.008);
    }
  }

  ${tw`bg-white border-b border-gray-200 border-dashed transition-colors duration-300`}

  &:last-child {
    ${tw`border-b-0 rounded-b`}
  }

  &.rule-is-dragging {
    ${tw`border border-solid rounded`}
  }

  &.rule-is-editing {
    ${tw`bg-white rounded-none absolute top-0 left-0 w-full h-full z-10`}
    animation-duration: 200ms;
    animation-name: expand;
    animation-timing-function: ease-in-out;

    .rule-columns {
      ${tw`cursor-default`}
    }

    & > div {
      ${tw`opacity-100 transition-all duration-[5s]`}
    }
  }

  &.rule-editing-is-disabled {
    ${tw`opacity-25 cursor-not-allowed`}
  }

  &.edit-start {
    ${tw`transition-all duration-300`}
  }

  &.rule-is-deleting {
    ${tw`bg-gray-100`}
  }
`;
export const RuleBody = tw.div`flex`;
export const RuleColumns = tw.div`w-full flex cursor-pointer`;
export const RuleColumn = tw.div`p-2 w-1/3 text-gray-400`;
export const RuleDrag = styled.div`
  ${tw`flex justify-center`}
  width: ${dragHandleWidth}px;
`;
export const RuleDelete = styled.div`
  ${tw`flex justify-center text-red-500 pt-3`}
  width: ${deleteColumnWidth}px;

  button {
    ${tw`flex justify-center text-red-500 opacity-75 hover:opacity-100 focus:outline-none`}
    &:disabled {
      ${tw`opacity-50 hover:opacity-50 cursor-default`}
    }
  }
`;
export const DragHandle = styled.div`
  ${tw`text-gray-400 p-2 focus:outline-none`}

  &.is-disabled {
    ${tw`opacity-50`}
  }
`;
