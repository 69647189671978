import { faCalendarDay } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Temporal } from "@js-temporal/polyfill";
import Tippy from "@tippyjs/react";
import classNames from "classnames";
import { useState } from "react";

import Calendar from "../../../common/calendar/Calendar";

export type RescheduleOrderFormOption = "skip" | "reschedule";

interface RescheduleOrderFormProps {
  selectedOption: RescheduleOrderFormOption;
  nextOrderDate?: Temporal.PlainDate;
  rescheduleDate?: Temporal.PlainDate;
  onChangeSelectedOption: (option: RescheduleOrderFormOption) => void;
  onChangeRescheduleDate: (date: Temporal.PlainDate) => void;
}

const RescheduleOrderForm: React.FunctionComponent<
  RescheduleOrderFormProps
> = ({
  selectedOption,
  nextOrderDate,
  rescheduleDate,
  onChangeSelectedOption,
  onChangeRescheduleDate,
}) => {
  const [calendarIsOpen, setCalendarIsOpen] = useState(false);

  return (
    <>
      <div className="flow-reschedule-order__options">
        <button
          className={classNames({
            "flow-reschedule-order__options__item": true,
            active: selectedOption === "skip",
          })}
          onClick={() => onChangeSelectedOption("skip")}
        >
          Skip next order
        </button>
        <button
          className={classNames({
            "flow-reschedule-order__options__item": true,
            active: selectedOption === "reschedule",
          })}
          onClick={() => onChangeSelectedOption("reschedule")}
        >
          Reschedule next order
        </button>
      </div>

      {selectedOption === "reschedule" && (
        <div className="flow-reschedule-order__options__reschedule">
          <label>Order date</label>
          <Tippy
            theme="light"
            visible={calendarIsOpen}
            onClickOutside={() => setCalendarIsOpen(false)}
            interactive={true}
            placement="bottom-start"
            offset={[0, 3]}
            maxWidth="none"
            content={
              <Calendar
                value={rescheduleDate}
                onChange={(value) => {
                  onChangeRescheduleDate(value);
                  setCalendarIsOpen(false);
                }}
                minValue={nextOrderDate}
                maxValue={nextOrderDate?.add({ months: 6 })}
                className="flow-reschedule-order__options__reschedule__calendar"
              />
            }
            arrow={false}
            zIndex={9999}
          >
            <button
              className="flow-reschedule-order__options__reschedule__calendar-toggle"
              onClick={() => setCalendarIsOpen(!calendarIsOpen)}
            >
              <div>
                {rescheduleDate
                  ? rescheduleDate.toLocaleString(undefined, {
                      dateStyle: "full",
                    })
                  : null}
              </div>
              <div tw="ml-auto">
                <FontAwesomeIcon icon={faCalendarDay} />
              </div>
            </button>
          </Tippy>
        </div>
      )}
    </>
  );
};

export default RescheduleOrderForm;
