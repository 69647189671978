import gql from "graphql-tag";

import FlowDeflectionFragment from "../fragments/FlowDeflectionFragment";
import FlowDeflectionRuleGroupFragment from "../fragments/FlowDeflectionRuleGroupFragment";
import FlowOfferGroupFragment from "../fragments/FlowOfferGroupFragment";
import FlowOfferRuleGroupFragment from "../fragments/FlowOfferRuleGroupFragment";
import FlowOfferRuleOfferAutopilotFragment from "../fragments/FlowOfferRuleOfferAutopilotFragment";
import FlowOfferRuleOfferFragment from "../fragments/FlowOfferRuleOfferFragment";
import FlowOfferTestFragment from "../fragments/FlowOfferTestFragment";
import RuleInlineSegmentFragment from "../rules/fragments/RuleInlineSegmentFragment";
import RulePropertyFragment from "../rules/fragments/RulePropertyFragment";

const FlowOfferRulesQuery = gql`
  query FlowOfferRulesQuery($flowId: Int!, $includeDrafts: Boolean! = true) {
    account_feature(where: { key: { _eq: segment_groups } }) {
      key
      value
    }

    flow(where: { id: { _eq: $flowId } }) {
      id
      published_version {
        id
        flow_version_flow_steps {
          flow_step {
            flow_step_offer_rule_group {
              flow_step_id
              offer_rule_group_id
            }
            flow_step_intervention {
              flow_step_id
              intervention_id
              intervention {
                offer_rule_group_id
              }
            }
            flow_step_deflection_rule_group {
              flow_step_id
              offer_rule_group_id
            }
          }
        }
      }
      draft_version @include(if: $includeDrafts) {
        id
        flow_version_flow_steps {
          flow_step {
            flow_step_offer_rule_group {
              flow_step_id
              offer_rule_group_id
            }
            flow_step_intervention {
              flow_step_id
              intervention_id
              intervention {
                offer_rule_group_id
              }
            }
            flow_step_deflection_rule_group {
              flow_step_id
              offer_rule_group_id
            }
          }
        }
      }
    }

    offer_rule_group(
      where: {
        _or: [
          {
            flow_step_offer_rule_groups: {
              flow_step: { flow_id: { _eq: $flowId } }
            }
          }
          {
            interventions: {
              flow_step_interventions: {
                flow_step: { flow_id: { _eq: $flowId } }
              }
            }
          }
        ]
      }
    ) {
      ...FlowOfferRuleGroupFragment
    }

    deflection_rule_group: offer_rule_group(
      where: {
        flow_step_deflection_rule_groups: {
          flow_step: { flow_id: { _eq: $flowId } }
        }
      }
    ) {
      ...FlowDeflectionRuleGroupFragment
    }

    segment_group(order_by: { name: asc }) {
      id
      name
      segment_group_segments {
        operator
        segment {
          id
          name
        }
      }
    }

    segment(where: { inline: { _eq: false } }, order_by: { name: asc }) {
      id
      name
      integration {
        id
        type
      }
    }

    inlineSegments: segment(where: { inline: { _eq: true } }) {
      ...RuleInlineSegmentFragment
    }

    property(where: { deleted_at: { _is_null: true } }) {
      ...RulePropertyFragment
    }

    offer(
      where: {
        goal: { _eq: retention }
        deleted_at: { _is_null: true }
        archived_at: { _is_null: true }
        _not: { offer_autopilot: {} }
      }
      order_by: { name: asc }
    ) {
      ...FlowOfferRuleOfferFragment
    }

    offer_test(
      where: { goal: { _eq: retention }, deleted_at: { _is_null: true } }
      order_by: { name: asc }
    ) {
      ...FlowOfferTestFragment
    }

    offer_group(
      where: { goal: { _eq: retention }, deleted_at: { _is_null: true } }
      order_by: { name: asc }
    ) {
      ...FlowOfferGroupFragment
    }

    offer_autopilot(
      where: {
        offer: {
          goal: { _eq: retention }
          archived_at: { _is_null: true }
          deleted_at: { _is_null: true }
        }
      }
      order_by: { offer: { name: asc } }
    ) {
      ...FlowOfferRuleOfferAutopilotFragment
    }

    deflection(
      where: { deleted_at: { _is_null: true }, archived_at: { _is_null: true } }
      order_by: { title: asc }
    ) {
      ...FlowDeflectionFragment
    }
  }
  ${FlowOfferRuleGroupFragment}
  ${RuleInlineSegmentFragment}
  ${RulePropertyFragment}
  ${FlowOfferRuleOfferFragment}
  ${FlowOfferTestFragment}
  ${FlowOfferGroupFragment}
  ${FlowDeflectionFragment}
  ${FlowDeflectionRuleGroupFragment}
  ${FlowOfferRuleOfferAutopilotFragment}
`;

export default FlowOfferRulesQuery;
