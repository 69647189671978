import { useMemo } from "react";
import { isPresent } from "ts-is-present";

import {
  FlowDeflectionFragment,
  FlowFragment,
  FlowOfferRulesQuery,
  language_enum,
  question_type_enum,
  subscriber_flow_status_enum,
} from "../../__generated__/graphql";
import { PropertyValues } from "../../features/properties/lib/types";
import discoverDeflection from "../../features/public/flow/lib/discoverDeflection";
import getFormQuestions from "../../features/public/flow/lib/getFormQuestions";
import getOfferRuleConditionedQuestionIds from "../../features/public/flow/lib/getOfferRuleConditionedQuestionIds";
import {
  AcknowledgementGroupStep,
  ConfirmationStep,
  DeflectionRuleGroupStep,
  FlowStep,
  FormStep,
  InterventionStep,
  OfferRuleGroupStep,
  QuestionStep,
  SubscriberDetailsFormStep,
} from "../../features/public/flow/lib/types";
import { QuestionAnswer } from "../../features/public/flow/lib/types";
import serializeToString from "../editor/lib/serializeToString";
import translationText from "../translationText";
import translationValue from "../translationValue";
import { FormattedStepCondition } from "./formatStepConditions";
import getFlowObjectVersion from "./getFlowObjectVersion";
import showConditionalStep from "./showConditionalStep";
import { FlowVersion } from "./types";

interface UseFlowStepsOptions {
  isLoading?: boolean;
  isEditing: boolean;
  isStepsCard?: boolean;
  forceIncludeConditional: boolean;
  flow: FlowFragment | undefined | null;
  stepConditions: FormattedStepCondition[];
  language: language_enum;
  defaultLanguage?: language_enum;
  questionAnswers?: QuestionAnswer[];
  flowVersion: FlowVersion;
  offerRules: FlowOfferRulesQuery | undefined | null;
  status: subscriber_flow_status_enum | undefined | null;
  clientMajorVersion: number;
  promptForSubscriberDetails: boolean;
  segmentMatches: number[];
  segmentGroupMatches: number[];
  presentedDeflections: Record<string, FlowDeflectionFragment>;
  propertyValues: PropertyValues;
}

export default function useFlowSteps({
  isLoading,
  isEditing,
  isStepsCard = false,
  forceIncludeConditional,
  flow,
  stepConditions,
  language,
  defaultLanguage = language_enum.en_us,
  questionAnswers = [],
  flowVersion,
  offerRules,
  status,
  clientMajorVersion,
  promptForSubscriberDetails,
  segmentMatches,
  segmentGroupMatches,
  presentedDeflections,
  propertyValues,
}: UseFlowStepsOptions): FlowStep[] {
  return useMemo(() => {
    const result = [];

    const version = flow && getFlowObjectVersion(flow, flowVersion);

    if (isLoading) {
      return [];
    }

    if (!flow || !version || (!isStepsCard && isEditing && !offerRules)) {
      return [];
    }

    if (!version.flow_version_flow_steps) {
      return [];
    }

    if (promptForSubscriberDetails) {
      const step: SubscriberDetailsFormStep = {
        id: "subscriberDetailsForm",
        type: "subscriberDetailsForm",
        step: undefined,
        title: "",
        helpText: "",
        questions: flow.flow_subscriber_form_questions,
      };

      result.push(step);
    }

    const steps = version.flow_version_flow_steps
      .map((v) => v.flow_step)
      .filter(isPresent);

    const discoveredDeflectionIds: number[] = [];

    for (const [i, flowStep] of steps.entries()) {
      const condition = stepConditions.find(
        (condition) => condition.stepId === flowStep.id
      );
      const previousStepRadioQuestions = steps
        .slice(0, i)
        .filter(
          (test) =>
            (test.type === "question" &&
              test.flow_step_question?.question.type === "radio") ||
            (test.type === "form" &&
              test.flow_step_form?.form &&
              getFlowObjectVersion(
                test.flow_step_form.form,
                flowVersion
              ).form_version_questions.some(
                (q) => q.question?.type === "radio"
              ))
        )
        .flatMap((item) =>
          item.type === "question"
            ? item.flow_step_question!.question
            : item.type === "form" && item.flow_step_form?.form
            ? getFlowObjectVersion(item.flow_step_form.form, flowVersion)
                .form_version_questions!.map((q) => q.question)
                .filter((q) => q?.type === "radio")
                .filter(isPresent)
            : []
        );

      switch (flowStep.type) {
        case "acknowledgement_group": {
          const acknowledgementGroup =
            flowStep.flow_step_acknowledgement_group?.acknowledgement_group;
          if (!acknowledgementGroup) {
            throw new Error("Flow step acknowledgement group is missing");
          }

          const acknowledgemenGroupVersion = getFlowObjectVersion(
            acknowledgementGroup,
            flowVersion
          );

          if (
            !isEditing &&
            !acknowledgemenGroupVersion
              .acknowledgement_group_version_acknowledgements.length
          ) {
            continue;
          }

          if (
            !forceIncludeConditional &&
            condition &&
            !showConditionalStep(
              condition,
              previousStepRadioQuestions,
              questionAnswers
            )
          ) {
            continue;
          }

          const step: AcknowledgementGroupStep = {
            id: flowStep.token,
            type: "acknowledgementGroup",
            step: flowStep,
            title: serializeToString(
              translationValue(
                acknowledgemenGroupVersion.title_translation,
                language,
                flow?.default_language || language_enum.en_us
              ).value
            ),
            helpText:
              "Require subscriber acceptance of cancellation consequences.",
            acknowledgementGroup,
            condition,
            previousRadioQuestions: previousStepRadioQuestions,
          };
          result.push(step);
          break;
        }

        case "form": {
          const form = flowStep.flow_step_form?.form;
          if (!form) {
            throw new Error("Flow step form is missing");
          }

          const formVersion = getFlowObjectVersion(form, flowVersion);
          if (
            !isEditing &&
            !formVersion.form_version_questions.some((q) => {
              if (!q.question) {
                return false;
              }
              if (
                [
                  question_type_enum.text,
                  question_type_enum.likelihood_to_return,
                ].includes(q.question.type)
              ) {
                return true;
              }
              const qv = getFlowObjectVersion(q.question, flowVersion);
              return !!qv.question_version_question_options.length;
            })
          ) {
            continue;
          }

          const formQuestions = getFormQuestions({
            isLoading,
            isEditing,
            form,
            segmentMatches,
            segmentGroupMatches,
            questionAnswers,
            flowVersion,
            stepConditions,
            offerRules,
          });

          const formVersionQuestionIds = formVersion.form_version_questions
            .map((q) => q.question?.id)
            .filter(isPresent);

          const conditionsReferencedBy = stepConditions.filter((condition) =>
            condition.questionOptions.some((option) =>
              formVersionQuestionIds.includes(option.questionId)
            )
          );

          const step: FormStep = {
            id: flowStep.token,
            type: "form",
            step: flowStep,
            title: form.title,
            helpText: "Collect open-ended feedback with one or more questions.",
            form: form,
            questions: formQuestions,
            condition,
            previousRadioQuestions: previousStepRadioQuestions,
            conditionsReferencedBy,
          };

          if (
            !forceIncludeConditional &&
            condition &&
            !showConditionalStep(
              condition,
              previousStepRadioQuestions,
              questionAnswers
            )
          ) {
            continue;
          }

          result.push(step);

          break;
        }

        case "question": {
          const question = flowStep.flow_step_question?.question;
          if (!question) {
            throw new Error("Flow step question is missing");
          }

          const questionVersion = getFlowObjectVersion(question, flowVersion);

          const helpText =
            question.type === "text"
              ? "Collect open-ended feedback."
              : question.type === "likelihood_to_return"
              ? "Collect user return likelihood feedback."
              : "Collect multiple-choice feedback.";

          const conditionsReferencedBy = stepConditions.filter(
            (condition) =>
              condition.questionOptions.some(
                (option) => option.questionId === question.id
              ) ||
              condition.includeOtherInQuestionIds.some(
                (id) => id === question.id
              )
          );

          const {
            questionIds: conditionedQuestionIds,
            questionOptionIds: conditionedQuestionOptionIds,
            otherSpecifyQuestionIds: conditionedOtherSpecifyQuestionIds,
          } = getOfferRuleConditionedQuestionIds(offerRules);

          const step: QuestionStep = {
            id: flowStep.token,
            type: "question",
            step: flowStep,
            title: translationText(
              questionVersion.title_translation,
              language,
              flow?.default_language || language_enum.en_us
            ),
            helpText,
            question: question,
            condition,
            previousRadioQuestions: previousStepRadioQuestions,
            conditionsReferencedBy,
            isReferencedByOfferRule: conditionedQuestionIds.includes(
              question.id
            ),
            isOtherSpecifyReferencedByOfferRule:
              conditionedOtherSpecifyQuestionIds.includes(question.id),
            optionIdsReferencedByOfferRules: (
              (questionVersion.question_option_ids || []) as number[]
            ).filter((id) => conditionedQuestionOptionIds.includes(id)),
          };

          if (
            !isEditing &&
            question.type === question_type_enum.radio &&
            !questionVersion.question_version_question_options.length
          ) {
            continue;
          }

          if (
            !forceIncludeConditional &&
            condition &&
            !showConditionalStep(
              condition,
              previousStepRadioQuestions,
              questionAnswers
            )
          ) {
            continue;
          }

          result.push(step);

          break;
        }

        case "deflection_rule_group": {
          const deflectionRuleGroupId =
            flowStep.flow_step_deflection_rule_group?.offer_rule_group_id;
          if (!deflectionRuleGroupId) {
            throw new Error("Flow step deflection rule group is missing");
          }

          // See if there are any available deflections
          const deflection = !!isEditing
            ? null
            : presentedDeflections[flowStep.token] ||
              discoverDeflection({
                flowStep,
                flowVersion,
                questionAnswers,
                propertyValues,
                matchedSegmentIds: segmentMatches,
                matchedSegmentGroupIds: segmentGroupMatches,
                presentedDeflectionIds: [
                  ...Object.values(presentedDeflections).map((d) => d.id),
                  ...discoveredDeflectionIds,
                ],
                language,
                defaultLanguage,
              });

          if (!isEditing && !deflection) {
            continue;
          }

          if (deflection) {
            discoveredDeflectionIds.push(deflection.id);
          }

          const step: DeflectionRuleGroupStep = {
            id: flowStep.token,
            type: "deflectionRuleGroup",
            step: flowStep,
            title:
              flowStep.flow_step_deflection_rule_group?.offer_rule_group
                ?.title || "Deflection",
            deflection,
            helpText: "Present a deflection based on targeted rules.",
            questions: previousStepRadioQuestions,
            deflectionRuleGroupId,
          };

          result.push(step);
          break;
        }

        case "offer_rule_group": {
          const offerRuleGroupId =
            flowStep.flow_step_offer_rule_group?.offer_rule_group_id;
          if (!offerRuleGroupId) {
            throw new Error("Flow step offer rule group is missing");
          }

          const step: OfferRuleGroupStep = {
            id: flowStep.token,
            type: "offerRuleGroup",
            step: flowStep,
            title:
              flowStep.flow_step_offer_rule_group?.offer_rule_group?.title ||
              "Offer",
            helpText: "Present an offer based on targeted rules.",
            questions: previousStepRadioQuestions,
            offerRuleGroupId,
          };

          result.push(step);
          break;
        }

        case "confirmation": {
          if (clientMajorVersion < 2) {
            continue;
          }

          const confirmation = flowStep.flow_step_confirmation?.confirmation;
          if (!confirmation) {
            throw new Error("Flow step confirmation is missing");
          }

          const step: ConfirmationStep = {
            id: flowStep.token,
            type: "confirmation",
            step: flowStep,
            title: "Confirmation",
            helpText: "Show a confirmation message when the flow is complete.",
            confirmation,
            status,
          };

          result.push(step);
          break;
        }

        case "intervention":
          const intervention = flowStep.flow_step_intervention?.intervention;
          if (!intervention) {
            throw new Error("Flow step intervention is missing");
          }

          if (
            !forceIncludeConditional &&
            condition &&
            !showConditionalStep(
              condition,
              previousStepRadioQuestions,
              questionAnswers
            )
          ) {
            continue;
          }

          const step: InterventionStep = {
            id: flowStep.token,
            type: "intervention",
            step: flowStep,
            title: intervention.title,
            helpText:
              "Allow immediate cancellation for compliance in certain jurisdictions.",
            intervention,
            condition,
            previousRadioQuestions: previousStepRadioQuestions,
          };

          result.push(step);
          break;
      }
    }

    return result;
  }, [
    isLoading,
    isStepsCard,
    flow,
    flowVersion,
    isEditing,
    offerRules,
    promptForSubscriberDetails,
    stepConditions,
    forceIncludeConditional,
    questionAnswers,
    language,
    clientMajorVersion,
    status,
    segmentMatches,
    segmentGroupMatches,
    presentedDeflections,
    propertyValues,
    defaultLanguage,
  ]);
}
