import { createIntl as createReactIntl } from "react-intl";

import { language_enum } from "../__generated__/graphql";
import { enumToIso } from "./languages";

export const messages = (language: language_enum) => {
  const locale = enumToIso(language);

  const messages = (() => {
    switch (locale) {
      default:
        return {};
    }
  })();

  return messages;
};

export default function createIntl(language: language_enum) {
  const locale = enumToIso(language);

  const obj = createReactIntl({
    locale,
    defaultLocale: "en-US",
    messages: messages(language),
    onError: (err) => {},
  });

  return obj;
}
