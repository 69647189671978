import { UnreachableCaseError } from "ts-essentials";

import { offer_type_enum } from "../../../__generated__/graphql";

export default function offerFriendlyType(
  type: `${offer_type_enum}`,
  autopilot: boolean = false,
  title: boolean = true
) {
  let result = (() => {
    switch (type) {
      case "coupon":
        return "coupon";

      case "change_plan":
        return "change plan";

      case "modify_subscription":
        return "modify subscription";

      case "trial_extension":
        return "trial extension";

      case "pause_subscription":
        return "pause subscription";

      case "upgrade":
        return "upgrade";

      case "downgrade":
        return "downgrade";

      case "product_swap":
        return "product swap";

      case "reschedule_order":
        return "reschedule order";

      case "custom":
        return "custom";

      default:
        throw new UnreachableCaseError(type);
    }
  })();

  result = autopilot ? `autopilot ${result}` : result;

  result = title
    ? result.charAt(0).toUpperCase() + result.substring(1)
    : result;

  return result;
}
