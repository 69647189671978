import tw, { styled } from "twin.macro";

import HelpIcon from "../HelpIcon";

interface CardTitleProps {
  help?: string;
  className?: string;
}

const Wrapper = tw.div`flex-shrink-0 py-2 flex items-center min-h-[46px] max-h-[46px]`;
const Title = styled.h3`
  ${tw`font-title font-bold text-xl truncate`}
  max-width: 30rem;
`;

const CardTitle: React.FunctionComponent<CardTitleProps> = ({
  children,
  help = "",
  className,
}) => (
  <Wrapper className={className}>
    <Title>{children}</Title>
    {help && (
      <div tw="ml-2 mt-[2px]">
        <HelpIcon content={help} />
      </div>
    )}
  </Wrapper>
);

export default CardTitle;
