import { ELEMENT_IMAGE } from "@udecode/slate-plugins";
import { nanoid } from "nanoid";
import React from "react";
import { Node, Text } from "slate";

import { property_format_enum } from "../../../__generated__/graphql";
import Video from "../../../features/flow/video/Video";
import {
  PropertyConfig,
  PropertyValues,
} from "../../../features/properties/lib/types";
import { options } from "../TextEditor";
import { renderPropertyTag } from "./PropertyTagPlugin";
import { PropertyTag } from "./PropertyTagPlugin/types";
import { ELEMENT_VIDEO } from "./VideoPlugin";

export default function renderContent(
  content: Node[],
  disableVideoAutoPlay: boolean = false,
  propertyValues: PropertyValues = {},
  propertyConfig: PropertyConfig,
  showPropertyPlaceholders: boolean = false
) {
  return renderNode(
    { children: content },
    disableVideoAutoPlay,
    propertyValues,
    propertyConfig,
    showPropertyPlaceholders
  );
}

const renderNode = (
  node: Node,
  disableVideoAutoPlay: boolean = false,
  propertyValues: PropertyValues = {},
  propertyConfig: PropertyConfig,
  showPropertyPlaceholders: boolean = false
): React.ReactNode => {
  if (Text.isText(node)) {
    const split = node.text.split("\n");

    let rendered: React.ReactNode = split.map((text, index) => (
      <React.Fragment key={nanoid()}>
        {text}
        {index < split.length - 1 && <br />}
      </React.Fragment>
    ));

    if (node[options.bold.type]) {
      rendered = <strong>{rendered}</strong>;
    }
    if (node[options.italic.type]) {
      rendered = <em>{rendered}</em>;
    }
    if (node[options.underline.type]) {
      rendered = <u>{rendered}</u>;
    }

    return <span key={nanoid()}>{rendered}</span>;
  }

  const children = node.children.map((n) =>
    renderNode(
      n,
      disableVideoAutoPlay,
      propertyValues,
      propertyConfig,
      showPropertyPlaceholders
    )
  );

  switch (node.type) {
    case options.h4.type:
      return <h4 key={nanoid()}>{children}</h4>;

    case options.h5.type:
      return <h5 key={nanoid()}>{children}</h5>;

    case options.p.type:
      return <p key={nanoid()}>{children}</p>;

    case options.span.type:
      return <span key={nanoid()}>{children}</span>;

    case ELEMENT_IMAGE:
      return (
        <div key={nanoid()} tw="mb-3">
          <img src={node.url as string} alt="" />
          {children}
        </div>
      );

    case ELEMENT_VIDEO:
      return (
        <div key={nanoid()} tw="mb-3">
          <Video
            url={node.url as string}
            autoPlay={
              disableVideoAutoPlay ? false : (node.options as any).autoPlay
            }
          >
            {children}
          </Video>
        </div>
      );

    case options.property_tag.type:
      return showPropertyPlaceholders ? (
        <span
          key={nanoid()}
          tw="bg-gray-100 px-1 rounded border border-gray-100 whitespace-nowrap"
        >
          {renderPropertyTag(
            propertyValues,
            propertyConfig,
            node.tag as PropertyTag,
            (node.propertyId as string) || "",
            (node.dateFormat as string) || "",
            node.numberFormat as property_format_enum,
            (node.fallback as string) || "",
            !!node.isArray,
            true
          )}
        </span>
      ) : (
        <span key={nanoid()}>
          {renderPropertyTag(
            propertyValues,
            propertyConfig,
            node.tag as PropertyTag,
            (node.propertyId as string) || "",
            (node.dateFormat as string) || "",
            node.numberFormat as property_format_enum,
            (node.fallback as string) || "",
            !!node.isArray
          )}
        </span>
      );

    default:
      return children;
  }
};
