import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import usePrevious from "./usePrevious";

export default function useQueryState<T>(
  initialState: T,
  paramName: string,
  serialize: (state: T) => string,
  deserialize: (state: string) => T,
  clearOnInitial: boolean = true,
  resetOnClear: boolean = false
): [T, (state: T) => void] {
  const history = useHistory();
  const search = new URLSearchParams(history.location.search);

  const existingValue = search.get(paramName) || "";

  const prevExistingValue = usePrevious(existingValue);

  const [state, setState] = useState<T>(
    existingValue ? deserialize(existingValue) : initialState
  );

  useEffect(() => {
    if (
      existingValue &&
      existingValue !== prevExistingValue &&
      deserialize(existingValue) !== state
    ) {
      setState(deserialize(existingValue));
    }

    if (resetOnClear && !existingValue && !!prevExistingValue) {
      setState(initialState);
    }
  }, [
    deserialize,
    existingValue,
    initialState,
    prevExistingValue,
    resetOnClear,
    state,
  ]);

  const onChange = (s: T) => {
    setState(s);

    const searchParams = new URLSearchParams(history.location.search);

    const serialized = serialize(s);
    if ((s === initialState && clearOnInitial) || !serialized.length) {
      searchParams.delete(paramName);
    } else {
      searchParams.set(paramName, serialized);
    }

    const pathname = history.location.pathname;
    history.replace({ pathname, search: searchParams.toString() });
  };

  return [state, onChange];
}
