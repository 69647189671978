import { createContext, useContext, useLayoutEffect, useState } from "react";
import { Route, RouteProps, useHistory, useLocation } from "react-router-dom";

import { UserClaims } from "../@types";
import { useAuth0 } from "../app/auth0";
import { useUserClaims } from "../app/UserClaimsProvider";
import env from "./env";
import useQueryParams from "./useQueryParams";
import useSwitchAccount from "./useSwitchAccount";

type Props = RouteProps & {
  requireRole?: "admin" | "user";
};

type RequiredUserClaims = UserClaims & {
  accountId: number;
};

interface PrivateRouteContext {
  userClaims: RequiredUserClaims;
}

const Context = createContext<PrivateRouteContext | undefined>(undefined);

export const useRequiredUserClaims = () => {
  const context = useContext(Context);
  if (!context) {
    throw new Error(
      "useRequiredUserClaims() not called within PrivateRoute context"
    );
  }

  return context.userClaims;
};

/**
 * Renders children only if a user is logged in.
 *
 * This also provides a UserClaims object to its children that is
 * always present. Use useRequiredUserClaims() to retrieve it.
 */
const PrivateRoute: React.FunctionComponent<Props> = ({
  requireRole = "user",
  ...props
}) => {
  const { userClaims, userLoggedIn } = useUserClaims();
  const { loginWithRedirect } = useAuth0();
  const [authorized, setAuthorized] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const { switchToAdmin } = useSwitchAccount(false);

  const queryParams = useQueryParams();

  useLayoutEffect(() => {
    if (!userClaims) {
      loginWithRedirect({
        redirect_uri: env("REACT_APP_AUTH0_CALLBACK_URL"),
        appState: {
          targetUrl: `${window.location.pathname}${window.location.search}`,
        },
        login_hint: queryParams.get("email") || "",
      });
      return;
    }

    if (requireRole !== "admin" && !location.pathname.startsWith("/a/")) {
      if (userClaims.disabledAccountId && !userClaims.transparentUserId) {
        history.replace("/upgrade-account");
        return;
      }
      if (!userClaims.accountId) {
        if (userClaims.allowedRoles.includes("admin")) {
          switchToAdmin();
          return;
        }

        if (["complete-sign-up"].includes(location.pathname.split("/")[1])) {
          setAuthorized(true);
          return;
        }

        if (userLoggedIn) {
          history.replace("/no-account");
        }

        return;
      }
    }

    if (userClaims.allowedRoles.includes(requireRole)) {
      setAuthorized(true);
      return;
    }

    history.replace("/not-found");
  }, [
    history,
    location.pathname,
    location.state,
    loginWithRedirect,
    queryParams,
    requireRole,
    switchToAdmin,
    userClaims,
    userLoggedIn,
  ]);

  return (
    <>
      {authorized && userClaims ? (
        <Context.Provider
          value={{ userClaims: userClaims as RequiredUserClaims }}
        >
          <Route {...props} />
        </Context.Provider>
      ) : null}
    </>
  );
};

export default PrivateRoute;
