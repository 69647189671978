import gql from "graphql-tag";

import AppSegmentFragment from "./AppSegmentFragment";

export default gql`
  fragment AppSegmentGroupFragment on segment_group {
    id
    token
    name
    created_at
    segment_group_segments {
      segment_group_id
      segment_id
      operator
      segment {
        ...AppSegmentFragment
      }
    }
  }
  ${AppSegmentFragment}
`;
