import Decimal from "decimal.js";

const formatterCents = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export default function formatCurrency(
  value: string | number | Decimal,
  cents: boolean = true
) {
  return (cents ? formatterCents : formatter).format(Number(value));
}
