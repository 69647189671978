import gql from "graphql-tag";

export default gql`
  fragment AppPropertyFragment on property {
    id
    entity
    type
    format
    name
    key
    token
    created_at
  }
`;
