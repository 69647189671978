import { faPlus, faWeightHanging } from "@fortawesome/pro-regular-svg-icons";
import {
  faGifts,
  faPlaneUp,
  faTimes,
  faVial,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import Fuse from "fuse.js";
import { useMemo, useState } from "react";
import tw from "twin.macro";

import {
  offer_type_enum,
  platform_enum,
  RuleOfferFragment,
  RuleOfferGroupFragment,
  RuleOfferTestFragment,
} from "../../__generated__/graphql";
import offerFriendlyType from "../../features/offers/lib/offerFriendlyType";
import offerIcon from "../../features/offers/lib/offerIcon";
import Button from "../form/Button";
import TextInput from "../form/input/TextInput";
import SearchInput from "../search/SearchInput";
import {
  BoxContainer,
  NoOfferBox,
  OfferAutopilotBox,
  OfferBox,
  OfferGroupBox,
  OfferTestBox,
} from "./RuleBoxes";

export interface RuleOfferPickerValue {
  groups: Array<{
    weight: number;
    offerIds: number[];
    offerTestIds: number[];
    offerGroupIds: number[];
    offerAutopilotOfferIds: number[];
    includePresentNoOffer: boolean;
  }>;
}

interface RuleOfferPickerProps {
  ruleGroupsEnabled: boolean;
  value: RuleOfferPickerValue;
  platform: platform_enum | undefined;
  offers: RuleOfferFragment[];
  offerGroups: RuleOfferGroupFragment[];
  offerTests: RuleOfferTestFragment[];
  offerAutopilotOffers: RuleOfferFragment[];
  onChange: (value: RuleOfferPickerValue) => void;
  onClickCreateOffer: (groupIndex: number) => void;
  onClickCreateOfferTest?: (groupIndex: number) => void;
}

const TypeHeading = tw.div`text-gray-700 font-semibold uppercase text-sm mb-[0.1rem]`;

const RuleOfferPicker: React.FunctionComponent<RuleOfferPickerProps> = ({
  ruleGroupsEnabled,
  value,
  platform,
  offers,
  offerGroups,
  offerTests,
  offerAutopilotOffers,
  onChange,
  onClickCreateOffer,
  onClickCreateOfferTest,
}) => {
  const [searchValues, setSearchValues] = useState<Record<number, string>>({});
  const [searchResults, setSearchResults] = useState<
    Record<
      number,
      Array<RuleOfferFragment | RuleOfferGroupFragment | RuleOfferTestFragment>
    >
  >({});

  const fuse = useMemo(
    () =>
      new Fuse(
        [...offers, ...offerGroups, ...offerTests, ...offerAutopilotOffers],
        {
          keys: ["name"],
          threshold: 0.4,
        }
      ),
    [offerGroups, offerTests, offers, offerAutopilotOffers]
  );

  const handleChangeWeight = (groupIndex: number, weight: number) => {
    const group = value.groups[groupIndex];
    if (weight < 1) {
      weight = 1;
    }
    if (weight > 100) {
      weight = 100;
    }

    const newGroup = {
      ...group,
      weight,
    };

    const newGroups = [...value.groups];
    newGroups[groupIndex] = newGroup;
    onChange({
      ...value,
      groups: newGroups,
    });
  };

  const handleToggleOffer = (groupIndex: number, offerId: number) => {
    const group = value.groups[groupIndex];

    const newGroup = {
      ...group,
      offerIds: group.offerIds.includes(offerId)
        ? group.offerIds.filter((id) => id !== offerId)
        : [...group.offerIds, offerId],
    };

    if (!group.offerIds.length) {
      newGroup.offerGroupIds = [];
      newGroup.offerTestIds = [];
      newGroup.offerAutopilotOfferIds = [];
    }

    if (group.includePresentNoOffer && newGroup.offerIds.length) {
      newGroup.includePresentNoOffer = false;
    }

    const newGroups = [...value.groups];
    newGroups[groupIndex] = newGroup;
    onChange({
      ...value,
      groups: newGroups,
    });
  };

  const handleToggleOfferGroup = (groupIndex: number, offerGroupId: number) => {
    const group = value.groups[groupIndex];

    const newGroup = {
      ...group,
      offerGroupIds: group.offerGroupIds.includes(offerGroupId)
        ? group.offerGroupIds.filter((id) => id !== offerGroupId)
        : [...group.offerGroupIds, offerGroupId],
    };

    if (!group.offerGroupIds.length) {
      newGroup.offerIds = [];
      newGroup.offerTestIds = [];
      newGroup.offerAutopilotOfferIds = [];
    }

    newGroup.includePresentNoOffer = false;

    const newGroups = [...value.groups];
    newGroups[groupIndex] = newGroup;
    onChange({
      ...value,
      groups: newGroups,
    });
  };

  const handleToggleOfferTest = (groupIndex: number, offerTestId: number) => {
    const group = value.groups[groupIndex];

    const newGroup = {
      ...group,
      offerTestIds: group.offerTestIds.includes(offerTestId)
        ? []
        : [offerTestId],
    };

    if (!group.offerTestIds.length) {
      newGroup.offerIds = [];
      newGroup.offerGroupIds = [];
      newGroup.offerAutopilotOfferIds = [];
    }

    newGroup.includePresentNoOffer = false;

    const newGroups = [...value.groups];
    newGroups[groupIndex] = newGroup;
    onChange({
      ...value,
      groups: newGroups,
    });
  };

  const handleToggleOfferAutopilot = (groupIndex: number, offerId: number) => {
    const group = value.groups[groupIndex];

    const newGroup = {
      ...group,
      offerAutopilotOfferIds: group.offerAutopilotOfferIds.includes(offerId)
        ? []
        : [offerId],
    };

    if (!group.offerAutopilotOfferIds.length) {
      newGroup.offerIds = [];
      newGroup.offerGroupIds = [];
      newGroup.offerTestIds = [];
    }

    newGroup.includePresentNoOffer = false;

    const newGroups = [...value.groups];
    newGroups[groupIndex] = newGroup;
    onChange({
      ...value,
      groups: newGroups,
    });
  };

  const handleTogglePresentNoOffer = (groupIndex: number) => {
    const group = value.groups[groupIndex];
    const newGroup = { ...group };

    if (!group.includePresentNoOffer && group.offerIds.length) {
      newGroup.includePresentNoOffer = true;
    }

    if (group.includePresentNoOffer && group.offerIds.length) {
      newGroup.includePresentNoOffer = false;
      newGroup.offerIds = [];
    }

    if (
      group.offerGroupIds.length ||
      group.offerTestIds.length ||
      group.offerAutopilotOfferIds.length
    ) {
      newGroup.includePresentNoOffer = false;
      newGroup.offerIds = [];
      newGroup.offerGroupIds = [];
      newGroup.offerTestIds = [];
      newGroup.offerAutopilotOfferIds = [];
    }

    const newGroups = [...value.groups];
    newGroups[groupIndex] = newGroup;
    onChange({
      ...value,
      groups: newGroups,
    });
  };

  const handleAddGroup = () => {
    const newGroup = {
      weight: 100,
      offerIds: [],
      offerTestIds: [],
      offerGroupIds: [],
      offerAutopilotOfferIds: [],
      deflectionIds: [],
      includePresentNoOffer: false,
    };

    onChange({
      ...value,
      groups: [...value.groups, newGroup],
    });
  };

  const handleRemoveGroup = (groupIndex: number) => {
    onChange({
      ...value,
      groups: [
        ...value.groups.slice(0, groupIndex),
        ...value.groups.slice(groupIndex + 1),
      ],
    });
  };

  const handleSearchChange = (groupIndex: number, newSearchValue: string) => {
    const result = fuse.search(newSearchValue);

    setSearchValues({
      ...searchValues,
      [groupIndex]: newSearchValue,
    });
    setSearchResults({
      ...searchResults,
      [groupIndex]: result.map((r) => r.item),
    });
  };

  return (
    <div>
      {value.groups.map((group, i) => {
        const filteredOffers = offers.filter(
          (o) =>
            !searchValues[i] ||
            searchResults[i].find(
              (r) => r.__typename === "offer" && r.id === o.id
            )
        );
        const filteredOfferGroups = offerGroups.filter(
          (og) =>
            !searchValues[i] ||
            searchResults[i].find(
              (r) => r.__typename === "offer_group" && r.id === og.id
            )
        );
        const filteredOfferTests = offerTests.filter(
          (t) =>
            !searchValues[i] ||
            searchResults[i].find(
              (r) => r.__typename === "offer_test" && r.id === t.id
            )
        );
        const filteredOfferAutopilotOffers = offerAutopilotOffers.filter(
          (t) =>
            !searchValues[i] ||
            searchResults[i].find(
              (r) => r.__typename === "offer" && r.id === t.id
            )
        );

        return (
          <div
            key={i}
            tw="relative pb-1 mb-3"
            css={
              value.groups.length > 1
                ? tw`border border-divider rounded-lg p-3`
                : undefined
            }
          >
            {value.groups.length > 1 && (
              <button
                tw="w-[26px] h-[26px] text-gray-600 hover:text-gray-800 hover:bg-gray-50 cursor-pointer rounded-full border border-divider absolute top-[-8px] left-[-8px] bg-white flex items-center justify-center transition-all duration-200"
                onClick={() => handleRemoveGroup(i)}
              >
                <FontAwesomeIcon icon={faTimes} transform="shrink-2" />
              </button>
            )}

            {value.groups.length > 1 && (
              <div tw="mb-3 border-b border-divider pb-2">
                <FontAwesomeIcon icon={faWeightHanging} /> Weight:{" "}
                <TextInput
                  type="number"
                  min={1}
                  max={100}
                  value={group.weight}
                  onChange={(e) =>
                    handleChangeWeight(i, Number(e.currentTarget.value))
                  }
                  tw="py-0 ml-2 pr-0 w-[60px]"
                />
              </div>
            )}

            {[...offers, ...offerGroups, ...offerTests].length >= 20 && (
              <div tw="mb-4">
                <SearchInput
                  tw="w-full"
                  value={searchValues[i]}
                  onChange={(e) => handleSearchChange(i, e.currentTarget.value)}
                  onClear={() => handleSearchChange(i, "")}
                />
              </div>
            )}

            <BoxContainer tw="mb-2">
              <NoOfferBox
                isOn={group.includePresentNoOffer}
                isReallyOn={
                  !group.offerIds.length &&
                  !group.offerTestIds.length &&
                  !group.offerGroupIds.length &&
                  !group.offerAutopilotOfferIds.length
                }
                onClick={() => handleTogglePresentNoOffer(i)}
              >
                Present no offer
              </NoOfferBox>
            </BoxContainer>

            {Object.values(offer_type_enum).map((type) =>
              !!filteredOffers.filter((o) => o.type === type).length ? (
                <div key={type} tw="mb-2">
                  <TypeHeading>{offerFriendlyType(type)}</TypeHeading>
                  <BoxContainer>
                    {filteredOffers
                      .filter((o) => o.type === type)
                      .map((offer) => (
                        <OfferBox
                          key={offer.id}
                          isOn={group.offerIds.includes(offer.id)}
                          onClick={() => handleToggleOffer(i, offer.id)}
                        >
                          <FontAwesomeIcon
                            icon={offerIcon(offer.type)}
                            transform="shrink-3"
                            tw="mr-1"
                          />{" "}
                          {offer.name}
                        </OfferBox>
                      ))}
                  </BoxContainer>
                </div>
              ) : null
            )}

            {!!filteredOfferGroups.length && (
              <div tw="mb-2">
                <TypeHeading>Offer group</TypeHeading>
                <BoxContainer>
                  {filteredOfferGroups.map((offerGroup) => (
                    <OfferGroupBox
                      key={offerGroup.id}
                      isOn={group.offerGroupIds.includes(offerGroup.id)}
                      onClick={() => handleToggleOfferGroup(i, offerGroup.id)}
                    >
                      <FontAwesomeIcon icon={faGifts} transform="shrink-3" />{" "}
                      {offerGroup.name}
                    </OfferGroupBox>
                  ))}
                </BoxContainer>
              </div>
            )}

            {!!filteredOfferTests.length && (
              <div tw="mb-2">
                <TypeHeading>A/B test</TypeHeading>
                <BoxContainer>
                  {filteredOfferTests.map((offerTest) => (
                    <OfferTestBox
                      key={offerTest.id}
                      isOn={group.offerTestIds.includes(offerTest.id)}
                      onClick={() => handleToggleOfferTest(i, offerTest.id)}
                    >
                      <FontAwesomeIcon icon={faVial} transform="shrink-3" />{" "}
                      {offerTest.name}
                    </OfferTestBox>
                  ))}
                </BoxContainer>
              </div>
            )}

            {!!filteredOfferAutopilotOffers.length && (
              <div tw="mb-2">
                <TypeHeading>Autopilot</TypeHeading>
                <BoxContainer>
                  {filteredOfferAutopilotOffers.map((autopilotOffer) => (
                    <OfferAutopilotBox
                      key={autopilotOffer.id}
                      isOn={group.offerAutopilotOfferIds.includes(
                        autopilotOffer.id
                      )}
                      onClick={() =>
                        handleToggleOfferAutopilot(i, autopilotOffer.id)
                      }
                    >
                      <FontAwesomeIcon icon={faPlaneUp} transform="shrink-3" />{" "}
                      {autopilotOffer.name}
                    </OfferAutopilotBox>
                  ))}
                </BoxContainer>
              </div>
            )}

            {!!searchValues[i] && !searchResults[i].length && (
              <span tw="text-type-light">No results.</span>
            )}

            <div tw="border-t border-divider mt-3 pt-2 flex gap-2">
              <Tippy
                content="Connect a subscription platform to create offers."
                disabled={!!platform}
              >
                <span>
                  <Button
                    type="button"
                    buttonType="alternate-secondary"
                    size="sm"
                    onClick={() => onClickCreateOffer(i)}
                    disabled={!platform}
                  >
                    <FontAwesomeIcon icon={faPlus} /> Create offer
                  </Button>
                </span>
              </Tippy>
              {!!onClickCreateOfferTest && (
                <Tippy
                  content={
                    !platform
                      ? "Connect a subscription platform to create A/B tests."
                      : offers.length < 2
                      ? "You need at least two offers to create an A/B test."
                      : ""
                  }
                  disabled={!!platform && offers.length >= 2}
                >
                  <span>
                    <Button
                      type="button"
                      buttonType="alternate-secondary"
                      size="sm"
                      onClick={() => onClickCreateOfferTest(i)}
                      disabled={!platform || offers.length < 2}
                    >
                      <FontAwesomeIcon icon={faPlus} /> Create A/B test
                    </Button>
                  </span>
                </Tippy>
              )}
            </div>
          </div>
        );
      })}
      {ruleGroupsEnabled && (
        <div tw="-ml-4 -mr-4 px-4 pt-5 border-t border-divider">
          <Button
            type="button"
            buttonType="alternate-secondary"
            size="sm"
            onClick={handleAddGroup}
          >
            <FontAwesomeIcon icon={faPlus} /> Add rule group
          </Button>
        </div>
      )}
    </div>
  );
};

export default RuleOfferPicker;
