import { ApolloProvider } from "@apollo/client";
import { Route, Switch } from "react-router-dom";

import { createApolloClient } from "../../app/apollo";
import Campaign from "./campaign/Campaign";
import Confirmation from "./campaign/Confirmation";
import Payment from "./campaign/Payment";
import CustomerPortalRoutes from "./customer-portal/CustomerPortalRoutes";
import Demo from "./demo/Demo";
import EmailSignature from "./email-signature/EmailSignature";
import Flow from "./flow/Flow";
import NewFlowSession from "./flow/NewFlowSession";
import ShareFlowPreview from "./flow/ShareFlowPreview";

const PublicRoutes: React.FunctionComponent = () => (
  <Switch>
    <Route path="/p/portal" render={() => <CustomerPortalRoutes />} />
    <Route
      path="/p/flow/:clientId/session"
      render={(props) => (
        <ApolloProvider
          client={createApolloClient(undefined, {
            "X-Hasura-Campaign-Token": "",
            "X-Hasura-Flow-Token": props.match.params["clientId"],
            "X-Hasura-Portal-Token": "",
          })}
        >
          <NewFlowSession />
        </ApolloProvider>
      )}
    />

    <Route path="/p/flow/:token/preview" render={() => <ShareFlowPreview />} />

    <Route
      path="/p/flow/:token"
      render={(props) => (
        <ApolloProvider
          client={createApolloClient(undefined, {
            "X-Hasura-Campaign-Token": "",
            "X-Hasura-Flow-Token": props.match.params["token"],
            "X-Hasura-Portal-Token": "",
          })}
        >
          <Flow />
        </ApolloProvider>
      )}
    />

    <Route
      path="/p/campaign/:token/confirmation"
      render={(props) => (
        <ApolloProvider
          client={createApolloClient(undefined, {
            "X-Hasura-Campaign-Token": props.match.params["token"],
            "X-Hasura-Flow-Token": "",
            "X-Hasura-Portal-Token": "",
          })}
        >
          <Confirmation />
        </ApolloProvider>
      )}
    />

    <Route
      path="/p/campaign/:token/payment"
      render={(props) => (
        <ApolloProvider
          client={createApolloClient(undefined, {
            "X-Hasura-Campaign-Token": props.match.params["token"],
            "X-Hasura-Flow-Token": "",
            "X-Hasura-Portal-Token": "",
          })}
        >
          <Payment />
        </ApolloProvider>
      )}
    />

    <Route
      path="/p/campaign/:token"
      render={(props) => (
        <ApolloProvider
          client={createApolloClient(undefined, {
            "X-Hasura-Campaign-Token": props.match.params["token"],
            "X-Hasura-Flow-Token": "",
            "X-Hasura-Portal-Token": "",
          })}
        >
          <Campaign />
        </ApolloProvider>
      )}
    />

    <Route
      path="/p/demo/:token"
      render={(props) => (
        <ApolloProvider
          client={createApolloClient(undefined, {
            "X-Hasura-Demo-Token": props.match.params["token"],
          })}
        >
          <Demo />
        </ApolloProvider>
      )}
    />
    <Route path="/p/email-signature" render={() => <EmailSignature />} />
  </Switch>
);

export default PublicRoutes;
