import gql from "graphql-tag";

import FlowPropertyFragment from "./FlowPropertyFragment";
import TranslationFragment from "./TranslationFragment";

export default gql`
  fragment FlowDeflectionSnapshotItemFragment on deflection_snapshot_item {
    id
    token
    icon_prefix
    icon_name
    property_id
    property_condition_value
    date_format
    text_translation {
      ...TranslationFragment
    }
    property {
      ...FlowPropertyFragment
    }
  }
  ${TranslationFragment}
  ${FlowPropertyFragment}
`;
