import { css } from "twin.macro";

interface FlowIconProps extends React.ComponentPropsWithoutRef<"img"> {
  prefix: string;
  name: string;
  color: string;
}

const FlowIcon: React.FunctionComponent<FlowIconProps> = ({
  prefix,
  name,
  color,
  ...props
}) => {
  const folder = (() => {
    switch (prefix) {
      case "fas":
        return "solid";

      case "far":
        return "regular";

      case "fal":
        return "light";

      case "fat":
        return "thin";

      default:
        throw new Error(`Unknown prefix ${prefix}`);
    }
  })();

  return (
    <img
      {...props}
      css={css`
        background-color: ${color};
        mask: url(/icons/${folder}/${name}.svg) no-repeat center;
      `}
      alt=""
    />
  );
};

export default FlowIcon;
