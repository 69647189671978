import { gql, useQuery } from "@apollo/client";

import { UseHasMultipleFlowsQuery } from "../__generated__/graphql";
import useAccountFeatures from "./useAccountFeatures";
import useViewer from "./useViewer";

export default function useHasMultipleFlows() {
  const { features } = useAccountFeatures();
  const { viewer } = useViewer();

  const { data } = useQuery<UseHasMultipleFlowsQuery>(
    gql`
      query UseHasMultipleFlowsQuery {
        flow(
          where: { deleted_at: { _is_null: true } }
          order_by: { title: asc }
        ) {
          id
          title
        }
      }
    `,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const flows = data?.flow || [];

  return {
    hasMultipleFlows: !!features.multiple_flows && flows.length > 1,
    hasMultipleFlowsFeature: !!features.multiple_flows,
    hasMoreThanOneFlow: flows.length > 1,
    flows,
    defaultFlowId: viewer?.account?.default_flow_id,
  };
}
