import Card from "./Card";

const NotFound: React.FunctionComponent = () => (
  <Card tw="w-[26rem] py-10">
    <h2 tw="font-title text-center mb-3 text-lg">Not found</h2>
    <p tw="text-type text-center">There's nothing here!</p>
  </Card>
);

export default NotFound;
