import { useEffect } from "react";

import useQueryState from "./useQueryState";

interface UsePaginationOptions {
  totalCount: number;
  perPage?: number;
  defaultPage?: number;
}

export default function usePagination({
  perPage = 10,
  defaultPage = 1,
  totalCount,
}: UsePaginationOptions) {
  const [page, setPage] = useQueryState(
    defaultPage,
    "page",
    (s) => s.toString(),
    (s) => Number(s)
  );

  const pages = Math.ceil(totalCount / perPage);

  useEffect(() => {
    if (pages && page > pages) {
      setPage(1);
    }
  }, [page, pages, setPage]);

  return {
    page,
    pages,
    offset: (page - 1) * perPage,
    setPage,
  };
}
