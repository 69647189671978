import { useMutation } from "@apollo/client";
import gql from "graphql-tag";

import {
  CreatePauseSubscriptionOfferMutation,
  CreatePauseSubscriptionOfferMutationVariables,
} from "../../../__generated__/graphql";
import AppOfferFragment from "../../../common/fragments/AppOfferFragment";
import updateCache from "./updateCache";

export default function useCreatePauseSubscriptionOffer() {
  const [createPauseSubscriptionOffer] = useMutation<
    CreatePauseSubscriptionOfferMutation,
    CreatePauseSubscriptionOfferMutationVariables
  >(
    gql`
      mutation CreatePauseSubscriptionOfferMutation(
        $object: offer_pause_subscription_insert_input!
      ) {
        insert_offer_pause_subscription_one(object: $object) {
          offer {
            ...AppOfferFragment
          }
        }
      }
      ${AppOfferFragment}
    `,
    {
      update: (cache, { data }) => {
        if (!data?.insert_offer_pause_subscription_one?.offer) {
          return;
        }

        updateCache(cache, data.insert_offer_pause_subscription_one.offer);
      },
    }
  );

  return createPauseSubscriptionOffer;
}
