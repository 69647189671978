import gql from "graphql-tag";

export default gql`
  fragment CustomerPortalSubscriptionDetailsFragment on PortalSubscriptionDetailsOutput {
    name
    platformId
    status
    trialEndsAt
    amount
    currency
    interval
    intervalCount
    nextBillingAt
  }
`;
