import { gql, useMutation } from "@apollo/client";

import {
  UseUpdateOfferCouponMutation,
  UseUpdateOfferCouponMutationVariables,
} from "../../../__generated__/graphql";

export default function useUpdateOfferCoupon() {
  const [updateOfferCoupon] = useMutation<
    UseUpdateOfferCouponMutation,
    UseUpdateOfferCouponMutationVariables
  >(gql`
    mutation UseUpdateOfferCouponMutation(
      $id: Int!
      $set: offer_coupon_set_input!
    ) {
      update_offer_coupon_by_pk(pk_columns: { id: $id }, _set: $set) {
        offer_id
        override_active_coupon
        offer {
          id
          offer_coupon {
            id
            override_active_coupon
            apply_to
          }
        }
      }
    }
  `);

  return updateOfferCoupon;
}
