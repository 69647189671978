import { Controller } from "react-hook-form";

import FieldError from "../../../../common/form/FieldError";
import FieldInput from "../../../../common/form/FieldInput";
import FieldRow from "../../../../common/form/FieldRow";
import FieldSetTitle from "../../../../common/form/FieldSetTitle";
import useTranslatableFormContext from "../../../../common/form/useTranslatableFormContext";
import MetadataInput from "../../../../common/MetadataInput";
import usePaidFeature, {
  generatePaidFeatureClassName,
} from "../../../upgrade-account/usePaidFeature";
import validateMetadata from "../../lib/validateMetadata";
import { OfferBaseFormValues } from "../types";

interface OfferMetadataFormPartialProps {
  isSubmitting: boolean;
}

const OfferMetadataFormPartial: React.FunctionComponent<
  OfferMetadataFormPartialProps
> = ({ isSubmitting }) => {
  const { formState, control } =
    useTranslatableFormContext<OfferBaseFormValues>();

  const { isFreeMode, setPaidFeatureRef } = usePaidFeature();

  return (
    <fieldset disabled={isSubmitting} ref={(ref) => setPaidFeatureRef(ref)}>
      <FieldSetTitle>Metadata</FieldSetTitle>
      <FieldRow
        className={generatePaidFeatureClassName(
          "Upgrade your plan to configure offer metadata.",
          isFreeMode,
          "form"
        )}
      >
        <FieldInput>
          <Controller
            control={control}
            name="metadata"
            render={({ field }) => (
              <MetadataInput
                value={field.value}
                onChange={field.onChange}
                disabled={isFreeMode}
              />
            )}
            rules={{
              validate: validateMetadata,
            }}
          />
          <FieldError error={formState.errors.metadata} />
        </FieldInput>
      </FieldRow>
    </fieldset>
  );
};

export default OfferMetadataFormPartial;
