import { ForwardedRef, forwardRef } from "react";
import tw, { styled } from "twin.macro";

import UpsellBanner, {
  BannerMode,
} from "../../features/upgrade-account/UpsellBanner";
import { useUpsell } from "../../features/upgrade-account/UpsellProvider";

const StyledPanelFormBody = styled.div`
  ${tw`p-4 pt-2 overflow-y-auto`}

  > p:not(:last-child) {
    ${tw`mb-4`}
  }
`;

interface PanelFormBodyProps {
  forwardedRef?: ForwardedRef<HTMLDivElement>;
  children?: React.ReactNode;
}

const PanelFormBody: React.FunctionComponent<PanelFormBodyProps> = ({
  children,
  forwardedRef,
  ...props
}) => {
  const { bannerIsVisible, bannerFeatureText, setUpgradeModalIsOpen } =
    useUpsell();

  return (
    <StyledPanelFormBody
      {...props}
      ref={forwardedRef}
      className="panel-form-body"
    >
      {bannerIsVisible && (
        <div tw="mt-3">
          <UpsellBanner
            isVisible={bannerIsVisible}
            featureText={bannerFeatureText}
            onClick={() => setUpgradeModalIsOpen(true)}
            mode={"panel" as BannerMode}
          />
        </div>
      )}
      {children}
    </StyledPanelFormBody>
  );
};

export default forwardRef<HTMLDivElement, PanelFormBodyProps>((props, ref) => (
  <PanelFormBody {...props} forwardedRef={ref} />
));
