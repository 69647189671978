import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ForwardedRef, forwardRef } from "react";
import { UnreachableCaseError } from "ts-essentials";
import tw, { theme } from "twin.macro";

export type FormBannerColor = "light-blue" | "light-gray";

interface FormBannerProps {
  className?: string;
  color: FormBannerColor;
  icon?: IconDefinition;
  forwardedRef?: ForwardedRef<HTMLDivElement>;
  children?: React.ReactNode;
}

const FormBanner: React.FunctionComponent<FormBannerProps> = ({
  className,
  color,
  icon,
  forwardedRef,
  children,
}) => {
  const bannerCss = (() => {
    switch (color) {
      case "light-blue":
        return tw`bg-blue-50 border-blue-200 text-blue-900`;

      case "light-gray":
        return tw`bg-gray-50 border-gray-200 text-zinc-600`;

      default:
        throw new UnreachableCaseError(color);
    }
  })();

  const iconColor = (() => {
    switch (color) {
      case "light-blue":
        return theme`colors.blue.500`;

      case "light-gray":
        return theme`colors.gray.400`;

      default:
        throw new UnreachableCaseError(color);
    }
  })();
  return (
    <div
      ref={forwardedRef}
      className={className}
      css={[
        tw`mt-4 p-4 flex gap-3 border rounded-lg shadow text-base font-normal`,
        bannerCss,
      ]}
    >
      {icon && (
        <span tw="w-5">
          <FontAwesomeIcon icon={icon} color={iconColor} fixedWidth />
        </span>
      )}
      <span>{children}</span>
    </div>
  );
};

export default forwardRef<HTMLDivElement, FormBannerProps>((props, ref) => (
  <FormBanner {...props} forwardedRef={ref} />
));
