import tw, { styled } from "twin.macro";

const CurrencyInput = styled.div`
  ${tw`relative inline-block`};

  &:after {
    ${tw`absolute text-type leading-normal text-center py-1 rounded rounded-r-none font-semibold`}
    content: '$';
    top: 1px;
    bottom: 1px;
    left: 1px;
    width: 22px;
  }

  input {
    padding-left: 22px;
  }
`;

export default CurrencyInput;
