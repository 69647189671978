import { RenderElementProps, useFocused, useSelected } from "slate-react";
import tw, { styled } from "twin.macro";

import { property_format_enum } from "../../../../__generated__/graphql";
import { usePropertyValues } from "../../../../features/properties/lib/propertyValues";
import { renderPropertyTag } from ".";
import { PropertyTag } from "./types";

const Tag = styled.span<{ selected: boolean }>`
  ${tw`bg-gray-100 px-1 rounded border border-gray-100 whitespace-nowrap`}

  ${(props) => props.selected && tw`border-blue-500`}
`;

const PropertyTagElement: React.FunctionComponent<RenderElementProps> = ({
  attributes,
  element,
  children,
}) => {
  const isFocused = useFocused();
  const isSelected = useSelected();
  const { propertyConfig } = usePropertyValues();

  return (
    <Tag
      contentEditable={false}
      {...attributes}
      selected={isFocused && isSelected}
    >
      {renderPropertyTag(
        {},
        propertyConfig,
        element.tag as PropertyTag,
        (element.propertyId as string) || "",
        (element.dateFormat as string) || "",
        element.numberFormat as property_format_enum,
        (element.fallback as string) || "",
        !!element.isArray,
        true
      )}
      {children}
    </Tag>
  );
};

export default PropertyTagElement;
