import { FlowQuestionFragment } from "../../__generated__/graphql";
import { QuestionAnswer } from "../../features/public/flow/lib/types";
import answersMatch from "./answersMatch";
import { FormattedStepCondition } from "./formatStepConditions";

const showConditionalStep = (
  condition: FormattedStepCondition,
  previousRadioQuestions: FlowQuestionFragment[],
  answers: QuestionAnswer[]
): boolean => {
  if (
    (condition.segmentGroups.length || condition.segments.length) &&
    !condition.segmentMatch &&
    !condition.segmentGroupMatch
  ) {
    return false;
  }

  if (
    condition.segments.length &&
    !condition.segmentGroups.length &&
    !condition.segmentMatch
  ) {
    return false;
  }

  if (
    condition.segmentGroups.length &&
    !condition.segments.length &&
    !condition.segmentGroupMatch
  ) {
    return false;
  }

  if (
    !condition.questionOptions.length &&
    !condition.includeOtherInQuestionIds.length
  ) {
    return true;
  }

  const options: Array<{
    questionId: number;
    questionOptionId: number | null;
  }> = condition.questionOptions
    .map((option) => ({
      questionId: option.questionId,
      questionOptionId: option.id,
    }))
    .filter((option) =>
      previousRadioQuestions.some(
        (previousQuestion) => previousQuestion.id === option.questionId
      )
    );

  for (const questionId of condition.includeOtherInQuestionIds) {
    options.push({
      questionId,
      questionOptionId: null,
    });
  }

  return answersMatch(
    options,
    answers.map((answer) => ({
      questionId: answer.id,
      questionOptionIds: Array.isArray(answer.value)
        ? answer.value.map((v) => v.id)
        : [],
      isOther: answer.type === "radio" && !!answer.specify,
    }))
  );
};

export default showConditionalStep;
