import { gql, useMutation } from "@apollo/client";

import {
  language_enum,
  translation_value_insert_input,
  UseUpdateOfferPauseSubscriptionCreateTranslationMutation,
  UseUpdateOfferPauseSubscriptionCreateTranslationMutationVariables,
  UseUpdateOfferPauseSubscriptionMutation,
  UseUpdateOfferPauseSubscriptionMutationVariables,
  UseUpdateOfferPauseSubscriptionOptionMetadataMutation,
  UseUpdateOfferPauseSubscriptionOptionMetadataMutationVariables,
  UseUpdateOfferPauseSubscriptionSetTranslationMutation,
  UseUpdateOfferPauseSubscriptionSetTranslationMutationVariables,
  UseUpdateOfferPauseSubscriptionTranslationsMutation,
  UseUpdateOfferPauseSubscriptionTranslationsMutationVariables,
} from "../../../__generated__/graphql";
import OfferDetailsFragment from "../../../common/fragments/OfferDetailsFragment";
import TranslationFragment from "../../../common/fragments/TranslationFragment";

export default function useUpdateOfferPauseSubscription() {
  const [updateOfferPauseSubscription] = useMutation<
    UseUpdateOfferPauseSubscriptionMutation,
    UseUpdateOfferPauseSubscriptionMutationVariables
  >(gql`
    mutation UseUpdateOfferPauseSubscriptionMutation(
      $offerId: Int!
      $pauseAt: offer_pause_subscription_pause_at_enum!
    ) {
      update_offer_pause_subscription_by_pk(
        pk_columns: { offer_id: $offerId }
        _set: { pause_at: $pauseAt }
      ) {
        offer_id
        pause_at
        offer {
          id
          offer_pause_subscription {
            offer_id
            pause_at
            offer_pause_subscription_options(order_by: { position: asc }) {
              id
              position
              text_translation_id
            }
          }
        }
      }
    }
  `);

  const [createTranslation] = useMutation<
    UseUpdateOfferPauseSubscriptionCreateTranslationMutation,
    UseUpdateOfferPauseSubscriptionCreateTranslationMutationVariables
  >(gql`
    mutation UseUpdateOfferPauseSubscriptionCreateTranslationMutation {
      insert_translation_one(object: {}) {
        id
      }
    }
  `);

  const [setTranslation] = useMutation<
    UseUpdateOfferPauseSubscriptionSetTranslationMutation,
    UseUpdateOfferPauseSubscriptionSetTranslationMutationVariables
  >(gql`
    mutation UseUpdateOfferPauseSubscriptionSetTranslationMutation(
      $offerPauseSubscriptionOptionId: Int!
      $textTranslationId: Int!
    ) {
      update_offer_pause_subscription_option_by_pk(
        pk_columns: { id: $offerPauseSubscriptionOptionId }
        _set: { text_translation_id: $textTranslationId }
      ) {
        id
        text_translation {
          ...TranslationFragment
        }
      }
    }
    ${TranslationFragment}
  `);

  const [updateTranslation] = useMutation<
    UseUpdateOfferPauseSubscriptionTranslationsMutation,
    UseUpdateOfferPauseSubscriptionTranslationsMutationVariables
  >(gql`
    mutation UseUpdateOfferPauseSubscriptionTranslationsMutation(
      $translationValues: [translation_value_insert_input!]!
    ) {
      insert_translation_value(
        objects: $translationValues
        on_conflict: {
          constraint: translation_value_pkey
          update_columns: [value]
        }
      ) {
        affected_rows
        returning {
          translation_id
          language
          value
        }
      }
    }
  `);

  const [updateOptionMetadata] = useMutation<
    UseUpdateOfferPauseSubscriptionOptionMetadataMutation,
    UseUpdateOfferPauseSubscriptionOptionMetadataMutationVariables
  >(gql`
    mutation UseUpdateOfferPauseSubscriptionOptionMetadataMutation(
      $optionId: Int!
      $metadata: jsonb!
    ) {
      update_offer_pause_subscription_option_by_pk(
        pk_columns: { id: $optionId }
        _set: { metadata: $metadata }
      ) {
        id
        metadata
        offer_pause_subscription {
          offer {
            ...OfferDetailsFragment
          }
        }
      }
    }
    ${OfferDetailsFragment}
  `);

  return async (
    variables: UseUpdateOfferPauseSubscriptionMutationVariables,
    optionTextTranslations: Record<number, Record<language_enum, any>>,
    optionMetadata: any[]
  ) => {
    let translationValues: translation_value_insert_input[] = [];

    const result = await updateOfferPauseSubscription({ variables });

    for (const [position, values] of Object.entries(optionTextTranslations)) {
      const option =
        result.data?.update_offer_pause_subscription_by_pk?.offer
          .offer_pause_subscription?.offer_pause_subscription_options[
          Number(position)
        ];

      if (!option) {
        throw new Error();
      }

      let translationId = option.text_translation_id;

      if (!translationId) {
        const newTranslation = await createTranslation();
        if (!newTranslation.data?.insert_translation_one?.id) {
          throw new Error();
        }
        await setTranslation({
          variables: {
            offerPauseSubscriptionOptionId: option.id,
            textTranslationId: newTranslation.data.insert_translation_one.id,
          },
        });
        translationId = newTranslation.data?.insert_translation_one?.id;
      }

      for (const [language, text] of Object.entries(values)) {
        translationValues.push({
          translation_id: translationId,
          language: language as language_enum,
          value: text,
        });
      }
    }

    await updateTranslation({
      variables: {
        translationValues,
      },
    });

    for (const [position, metadata] of optionMetadata.entries()) {
      const option =
        result.data?.update_offer_pause_subscription_by_pk?.offer
          .offer_pause_subscription?.offer_pause_subscription_options[
          Number(position)
        ];

      if (!option) {
        continue;
      }

      await updateOptionMetadata({
        variables: {
          optionId: option.id,
          metadata,
        },
      });
    }
  };
}
