import { useEffect, useState } from "react";
import { FieldValues, useForm, UseFormReturn } from "react-hook-form";

import { language_enum } from "../../__generated__/graphql";
import usePrevious from "../usePrevious";

export type TranslatedForms<TFieldValues> = Partial<
  Record<language_enum, TFieldValues>
>;

interface UseTranslatableFormOptions<FieldValues> {
  defaultValues?: TranslatedForms<FieldValues>;
  initialLanguage: language_enum;
  languages: language_enum[];
  defaultLanguage: language_enum;
  translatableFields: string[];
}

export type UseTranslatableFormReturn<
  TFieldValues extends FieldValues = FieldValues,
  TContext = any
> = Omit<UseFormReturn<TFieldValues, TContext>, "handleSubmit"> & {
  forms: TranslatedForms<TFieldValues>;
  editingLanguage: language_enum;
  defaultLanguage: language_enum;
  enabledLanguages: language_enum[];
  handleSubmit: (
    handler: (values: TranslatedForms<TFieldValues>) => any
  ) => (e?: React.BaseSyntheticEvent) => void;
  setEditingLanguage: (value: language_enum) => void;
};

export default function useTranslatableForm<TFieldValues extends FieldValues>({
  defaultValues = {},
  initialLanguage,
  languages,
  defaultLanguage,
  translatableFields,
}: UseTranslatableFormOptions<TFieldValues>): UseTranslatableFormReturn<TFieldValues> {
  const [values, setValues] =
    useState<TranslatedForms<TFieldValues>>(defaultValues);

  const [editingLanguage, setEditingLanguage] = useState(initialLanguage);

  const {
    setValue: setFormValue,
    watch,
    handleSubmit,
    trigger,
    ...form
  } = useForm<TFieldValues>({
    defaultValues: values[initialLanguage] as any,
    shouldUnregister: false,
  });

  const formValues = watch();

  const prevEditingLanguage = usePrevious(editingLanguage);
  const prevFormValues = usePrevious(formValues);

  useEffect(() => {
    if (editingLanguage === prevEditingLanguage) {
      return;
    }

    for (const field of translatableFields) {
      const languageForm = values[editingLanguage];
      const value = languageForm && (languageForm as any)[field];

      setFormValue(field as any, value);
    }
  }, [
    editingLanguage,
    prevEditingLanguage,
    setFormValue,
    translatableFields,
    values,
  ]);

  useEffect(() => {
    const stringifiedFormValues = JSON.stringify(formValues);

    if (JSON.stringify(prevFormValues) === stringifiedFormValues) {
      if (stringifiedFormValues !== JSON.stringify(values[editingLanguage])) {
        setValues({ ...values, [editingLanguage]: formValues });
      }
      return;
    }

    let newValues = { ...values };

    for (const [key, value] of Object.entries(formValues)) {
      if (!translatableFields.includes(key)) {
        for (const language of Object.keys(newValues)) {
          newValues = {
            ...newValues,
            [language]: {
              ...newValues[language as language_enum],
              [key]: value,
            },
          };
        }
      }
    }

    setValues({
      ...newValues,
      [editingLanguage]: formValues,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues]);

  const onSubmit =
    (handler: (values: Partial<Record<language_enum, TFieldValues>>) => any) =>
    (e?: React.BaseSyntheticEvent) => {
      e?.persist();

      if (editingLanguage !== defaultLanguage) {
        e?.preventDefault();

        setEditingLanguage(defaultLanguage);

        window.setTimeout(() => {
          handleSubmit(() => {
            handler(values);
          })(e);
        }, 0);

        return;
      }

      handleSubmit(() => {
        handler(values);
      })(e);
    };

  return {
    ...form,
    setValue: setFormValue,
    watch,
    handleSubmit: onSubmit,
    editingLanguage,
    defaultLanguage,
    enabledLanguages: languages,
    setEditingLanguage,
    forms: values,
    trigger,
  } as UseTranslatableFormReturn<TFieldValues>;
}
