import gql from "graphql-tag";

import TranslationFragment from "./TranslationFragment";
export default gql`
  fragment FlowOfferVariantFragment on offer_variant {
    id
    type
    offer_id
    headline_translation {
      ...TranslationFragment
    }
    content_translation {
      ...TranslationFragment
    }
    button_text_translation {
      ...TranslationFragment
    }
    offer_variant_coupon {
      offer_variant_id
      offer_id
      type
      amount_off
      duration
      months
      duration_interval
      duration_count
    }
    approved_at
  }
  ${TranslationFragment}
`;
