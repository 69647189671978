import { Temporal } from "@js-temporal/polyfill";
import Tippy from "@tippyjs/react";
import { useState } from "react";
import { styled } from "twin.macro";

import Calendar from "../../calendar/Calendar";
import TimePicker from "../../TimePicker";
import FieldInput from "../FieldInput";
import FieldLabel from "../FieldLabel";
import FieldRow from "../FieldRow";

export interface DatePickerInputRenderProps {
  onClick: () => void;
}

export type DateTimePickerInputRender = (
  props: DatePickerInputRenderProps
) => React.ReactElement;

interface DateTimePickerInputProps {
  value: Temporal.PlainDateTime | null | undefined;
  minDate?: Temporal.PlainDate;
  maxDate?: Temporal.PlainDate;
  onChange: (value: Temporal.PlainDateTime) => void;
  render: DateTimePickerInputRender;
}

const TimeFieldLabel = styled(FieldLabel)`
  padding-left: 25px;
  min-width: 120px;
`;

const DateTimePickerInput: React.FunctionComponent<
  DateTimePickerInputProps
> = ({ value, onChange, minDate, maxDate, render }) => {
  const [isOpen, setIsOpen] = useState(false);

  const date = value || Temporal.Now.plainDateTimeISO();

  return (
    <Tippy
      theme="light"
      className="dropdown-menu"
      offset={[0, 3]}
      visible={isOpen}
      onClickOutside={() => setIsOpen(false)}
      interactive={true}
      appendTo={document.body}
      placement="bottom"
      maxWidth="none"
      arrow={false}
      content={
        <div tw="text-base">
          <Calendar
            value={date.toPlainDate()}
            onChange={(d) => onChange(date.withPlainDate(d))}
            minValue={minDate}
            maxValue={maxDate}
          />
          <FieldRow>
            <TimeFieldLabel>
              <label>Time</label>
            </TimeFieldLabel>
            <FieldInput>
              <TimePicker
                value={date.toPlainTime().toString({ smallestUnit: "minute" })}
                onChange={(t) =>
                  t && onChange(date.withPlainTime(Temporal.PlainTime.from(t)))
                }
                disableClock={true}
                clockIcon="Clock"
                clearIcon={null}
              />
            </FieldInput>
          </FieldRow>
        </div>
      }
    >
      {render({
        onClick: () => setIsOpen(!isOpen),
      })}
    </Tippy>
  );
};

export default DateTimePickerInput;
