import { css } from "twin.macro";

import logoSpinner from "../assets/logo-spinner.svg";

const LogoSpinner: React.FunctionComponent = () => (
  <div
    tw="flex w-full items-center justify-center"
    css={css`
      padding-top: 30px;
    `}
  >
    <img
      src={logoSpinner}
      alt=""
      css={css`
        width: 40px;
      `}
    />
  </div>
);

export default LogoSpinner;
