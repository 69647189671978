import tw from "twin.macro";

interface BooleanOperatorProps {
  operator: string;
  align?: "left" | "center";
}

const BooleanDivider: React.FunctionComponent<BooleanOperatorProps> = ({
  operator,
  align = "center",
}) => (
  <div
    css={[
      tw`flex border-t border-divider border-dashed relative mt-1 mb-1`,
      align === "center" ? tw`justify-center` : undefined,
    ]}
  >
    <div
      css={[
        tw`font-semibold text-type-light bg-white absolute uppercase top-[-13px]`,
        align === "center" ? tw`px-2` : tw`pr-2`,
      ]}
    >
      {operator}
    </div>
  </div>
);

export default BooleanDivider;
