import { faGift } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Temporal } from "@js-temporal/polyfill";
import { ELEMENT_PARAGRAPH } from "@udecode/slate-plugins";
import classNames from "classnames";
import { useState } from "react";
import { Node } from "slate";
import tw, { theme } from "twin.macro";

import {
  coupon_duration_enum,
  FlowInterventionFragment,
  FlowOfferFragment,
  FlowOfferGroupFragment,
  FlowOfferRulesQuery,
  FlowSubscriptionFragment,
  language_enum,
  offer_coupon_type_enum,
  offer_goal_enum,
  offer_style_enum,
  offer_type_enum,
  PauseReason,
  translation_value_format_enum,
} from "../../../../__generated__/graphql";
import StandardLinkButton from "../../../../common/StandardLinkButton";
import EditableFlowText from "../../../flow/edit/EditableFlowText";
import { InterventionManager } from "../../../flow/edit/interventionManager";
import { RuleManager } from "../../../flow/edit/ruleActions";
import { useFlowVersion } from "../FlowVersionProvider";
import { FlowError, FlowText } from "../lib/types";
import OfferGroupContainer from "../OfferGroupContainer";
import FlowButton from "../ui/FlowButton";
import InterventionOffer from "./InterventionOffer";
import OfferRulesModal from "./OfferRulesModal";
import ReadOnlyOfferRules from "./ReadOnlyOfferRules";

interface InterventionProps {
  isEditMode: boolean;
  intervention: FlowInterventionFragment;
  flowId: number;
  interventionManager?: InterventionManager;
  ruleManager?: RuleManager;
  logoUrl: string | null | undefined;
  companyName: string;
  flowText: FlowText;
  offerRules?: FlowOfferRulesQuery | null;
  offer: FlowOfferFragment | null;
  offerGroup: FlowOfferGroupFragment | null;
  pauseReasons?: PauseReason[];
  nextOrderDate?: Temporal.PlainDate;
  onClickContinue: () => void;
  onClickCancel: () => void;
  onAcceptOffer: (
    selectedOptionIndex: number,
    groupOffer: null,
    selectedVariantId: string | null | undefined,
    rescheduleTo: Temporal.PlainDate | null | undefined,
    pauseReasonCode: string | null | undefined,
    pauseAt: Temporal.PlainDate | null | undefined,
    resumeAt: Temporal.PlainDate | null | undefined
  ) => void;
  onAcceptGroupOffer: (
    selectedOptionIndex: number,
    offerId: string,
    selectedVariantId: string | null | undefined,
    rescheduleTo: Temporal.PlainDate | null | undefined,
    pauseReasonCode: string | null | undefined,
    pauseAt: Temporal.PlainDate | null | undefined,
    resumeAt: Temporal.PlainDate | null | undefined
  ) => void;
  swappableProducts?: FlowSubscriptionFragment | null;
  disableVideoAutoPlay?: boolean;
  error?: FlowError;
}

const previewOffer: FlowOfferFragment = {
  __typename: "offer",
  id: 1,
  token: "offer",
  type: offer_type_enum.coupon,
  name: "Preview",
  show_banner: true,
  goal: offer_goal_enum.retention,
  style: offer_style_enum.modal,
  image_url: null,
  created_at: Temporal.Now.instant().toString(),
  updated_at: Temporal.Now.instant().toString(),
  headline_translation: {
    __typename: "translation",
    id: 1,
    translation_values: [
      {
        __typename: "translation_value",
        translation_id: 1,
        language: language_enum.en_us,
        value: "Save customers with an offer",
        format: translation_value_format_enum.text,
      },
    ],
  },
  content_translation: {
    __typename: "translation",
    id: 2,
    translation_values: [
      {
        __typename: "translation_value",
        translation_id: 2,
        language: language_enum.en_us,
        value: [
          {
            type: ELEMENT_PARAGRAPH,
            children: [
              {
                text: "Incentivize your customers to stay with an offer.",
              },
            ],
          },
        ],
        format: translation_value_format_enum.slate,
      },
    ],
  },
  button_text_translation: {
    __typename: "translation",
    id: 3,
    translation_values: [
      {
        __typename: "translation_value",
        translation_id: 3,
        language: language_enum.en_us,
        value: "Accept offer",
        format: translation_value_format_enum.text,
      },
    ],
  },
  offer_coupon: {
    __typename: "offer_coupon",
    type: offer_coupon_type_enum.percentage,
    amount_off: "40",
    duration: coupon_duration_enum.forever,
  },
  offer_change_plan: null,
  offer_trial_extension: null,
  offer_pause_subscription: null,
  offer_custom: null,
};

const Intervention: React.FunctionComponent<InterventionProps> = ({
  isEditMode,
  intervention,
  flowId,
  interventionManager,
  ruleManager,
  logoUrl,
  companyName,
  flowText,
  offerRules,
  offer,
  offerGroup,
  pauseReasons,
  onClickContinue,
  onClickCancel,
  onAcceptOffer,
  onAcceptGroupOffer,
  nextOrderDate,
  swappableProducts = null,
  disableVideoAutoPlay,
  error,
}) => {
  const { getObjectVersion } = useFlowVersion();
  const [offerRulesModalIsOpen, setOfferRulesModalIsOpen] = useState(false);

  const interventionVersion = getObjectVersion(intervention);

  const handleUpdateContentTranslation = (
    value: Partial<Record<language_enum, Node[]>>
  ) => {
    if (!interventionManager) {
      return;
    }

    interventionManager.updateInterventionTranslations(intervention.id, {
      contentTranslation: value,
    });
  };

  const handleUpdateContinueButtonTranslation = (
    value: Partial<Record<language_enum, Node[]>>
  ) => {
    if (!interventionManager) {
      return;
    }

    interventionManager.updateInterventionTranslations(intervention.id, {
      continueButtonTranslation: value,
    });
  };

  const handleUpdateCancelButtonTranslation = (
    value: Partial<Record<language_enum, Node[]>>
  ) => {
    if (!interventionManager) {
      return;
    }

    interventionManager.updateInterventionTranslations(intervention.id, {
      cancelButtonTranslation: value,
    });
  };

  const showOffer = isEditMode ? previewOffer : offer;

  return (
    <div>
      <div className="flow-instant-cancel__heading" tw="flex">
        <div
          className="flow-instant-cancel__brand"
          css={
            isEditMode || !!offer || !!offerGroup
              ? tw`w-1/2`
              : tw`w-2/3 mx-auto`
          }
        >
          {logoUrl ? (
            <img
              src={logoUrl}
              alt={companyName}
              className="flow-sidebar__logo"
            />
          ) : (
            <h1 className="flow-sidebar__company-name" tw="mx-auto">
              {companyName}
            </h1>
          )}
        </div>
      </div>

      <div className={classNames("flow-instant-cancel")} tw="flex w-full">
        <div
          className={classNames("flow-instant-cancel__section", {
            "intervention-text-content": isEditMode,
          })}
          css={[
            isEditMode || !!offer ? tw`w-1/2` : tw`w-2/3 mx-auto`,
            tw`pr-8`,
          ]}
        >
          <div className="flow-step__content">
            <EditableFlowText
              translation={interventionVersion.content_translation}
              isEditable={isEditMode}
              isInline={false}
              onSave={handleUpdateContentTranslation}
              placeholder="Enter content"
              tallEditor={true}
              imagesEnabled={true}
              videosEnabled={false}
              hoverContentClass="intervention-text-content"
            />
          </div>
          <FlowButton
            buttonType="secondary"
            onClick={onClickContinue}
            isEditMode={isEditMode}
          >
            <EditableFlowText
              isEditable={isEditMode}
              translation={interventionVersion.continue_button_translation}
              onSave={handleUpdateContinueButtonTranslation}
              hoverContentClass="intervention-text-content"
            />
          </FlowButton>
          <div tw="mt-4">
            <button className="flow-link-button" onClick={onClickCancel}>
              <EditableFlowText
                isEditable={isEditMode}
                translation={interventionVersion.cancel_button_translation}
                onSave={handleUpdateCancelButtonTranslation}
                hoverContentClass="intervention-text-content"
              />
            </button>
          </div>
        </div>

        {(isEditMode || !!offer || !!offerGroup) && (
          <div className="flow-instant-cancel__section" tw="w-1/2">
            {!isEditMode && showOffer && (
              <InterventionOffer
                offer={showOffer}
                isEditMode={isEditMode}
                pauseReasons={pauseReasons}
                flowText={flowText}
                onAccept={onAcceptOffer}
                nextOrderDate={nextOrderDate}
                swappableProducts={swappableProducts}
                disableVideoAutoPlay={disableVideoAutoPlay}
                error={error}
              />
            )}
            {!isEditMode && offerGroup && (
              <OfferGroupContainer
                offerGroup={offerGroup}
                previewMode={isEditMode}
                baseClassName="flow-instant-cancel__offer"
                pauseReasons={pauseReasons}
                flowText={flowText}
                onAccept={onAcceptGroupOffer}
                onDecline={() => {}}
                showDeclineButton={false}
                isFinalStep={false}
                nextOrderDate={nextOrderDate}
                swappableProducts={swappableProducts}
                disableVideoAutoPlay={disableVideoAutoPlay}
                error={error}
              />
            )}
            {isEditMode && (
              <div tw="border-dashed border-divider border-2 rounded p-4 flex flex-col">
                <div tw="text-type-light">
                  <FontAwesomeIcon
                    icon={faGift}
                    color={theme`colors.gray.400`}
                    tw="mr-1"
                  />{" "}
                  Present an offer based on customer segments.
                </div>

                <div tw="bg-white mt-2">
                  {offerRules && (
                    <ReadOnlyOfferRules
                      offerRuleGroupId={intervention.offer_rule_group_id}
                      offerRules={offerRules}
                    />
                  )}
                </div>

                <div tw="mt-2 pl-1">
                  <StandardLinkButton
                    onClick={() => setOfferRulesModalIsOpen(true)}
                  >
                    Configure offer rules
                  </StandardLinkButton>
                </div>
              </div>
            )}
          </div>
        )}

        {offerRules && ruleManager && (
          <OfferRulesModal
            isOpen={offerRulesModalIsOpen}
            onClose={() => setOfferRulesModalIsOpen(false)}
            offerRuleGroupId={intervention.offer_rule_group_id}
            flowId={flowId}
            offerRules={offerRules}
            questions={[]}
            manager={ruleManager}
            flowText={flowText}
            excludeOfferGroups={true}
          />
        )}
      </div>
    </div>
  );
};

export default Intervention;
