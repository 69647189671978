import { useMutation } from "@apollo/client";
import gql from "graphql-tag";

import {
  CreateCustomOfferMutation,
  CreateCustomOfferMutationVariables,
} from "../../../__generated__/graphql";
import AppOfferFragment from "../../../common/fragments/AppOfferFragment";
import updateCache from "./updateCache";

export default function useCreateCustomOffer() {
  const [createCustomOffer] = useMutation<
    CreateCustomOfferMutation,
    CreateCustomOfferMutationVariables
  >(
    gql`
      mutation CreateCustomOfferMutation($object: offer_custom_insert_input!) {
        insert_offer_custom_one(object: $object) {
          offer {
            ...AppOfferFragment
          }
        }
      }
      ${AppOfferFragment}
    `,
    {
      update: (cache, { data }) => {
        if (!data?.insert_offer_custom_one?.offer) {
          return;
        }

        updateCache(cache, data.insert_offer_custom_one.offer);
      },
    }
  );

  return createCustomOffer;
}
