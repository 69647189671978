import { createContext, useContext } from "react";

import getFlowObjectVersion from "../../../common/flow/getFlowObjectVersion";
import { FlowVersion, FlowVersionedObject } from "../../../common/flow/types";

interface FlowVersionProviderProps {
  version: FlowVersion;
}

interface FlowVersionContext {
  version: FlowVersion;
  getObjectVersion: <T>(object: FlowVersionedObject<T>) => T;
}

const Context = createContext<FlowVersionContext | undefined>(undefined);

export const useFlowVersion = () => {
  const context = useContext(Context);
  if (!context) {
    throw new Error(
      "useVersion() not called within FlowVersionProvider context"
    );
  }

  return context;
};

const FlowVersionProvider: React.FunctionComponent<
  FlowVersionProviderProps
> = ({ version, children }) => {
  const getObjectVersion = <T,>(object: FlowVersionedObject<T>): T =>
    getFlowObjectVersion(object, version);

  return (
    <Context.Provider value={{ version, getObjectVersion }}>
      {children}
    </Context.Provider>
  );
};

export default FlowVersionProvider;
