import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_EDITOR,
  createCommand,
} from "lexical";
import { useEffect } from "react";

import { $createImageNode, ImageNode } from "../nodes/ImageNode";

export interface InsertImagePayload {
  src: string;
}

export const INSERT_IMAGE_COMMAND = createCommand<InsertImagePayload>();

const ImagePlugin: React.FunctionComponent = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([ImageNode])) {
      throw new Error("ImagePlugin: ImageNode not registered on editor");
    }

    const removeLister = editor.registerCommand<InsertImagePayload>(
      INSERT_IMAGE_COMMAND,
      (payload) => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          const focusNode = selection.focus.getNode();
          if (focusNode !== null) {
            const imageNode = $createImageNode(payload.src);
            selection.focus
              .getNode()
              .getTopLevelElementOrThrow()
              .insertAfter(imageNode);
            const paragraphNode = $createParagraphNode();
            imageNode.insertAfter(paragraphNode);
            paragraphNode.select();
          }
        }
        return true;
      },
      COMMAND_PRIORITY_EDITOR
    );

    return () => {
      removeLister();
    };
  }, [editor]);

  return null;
};

export default ImagePlugin;
