import { FlowStepFragment } from "../../__generated__/graphql";
import getFlowObjectVersion from "./getFlowObjectVersion";
import { FlowVersion } from "./types";

interface FormattedStepConditionSegmentGroup {
  id: number;
}

interface FormattedStepConditionSegment {
  id: number;
}

interface FormattedStepConditionOption {
  id: number;
  questionId: number;
}

export interface FormattedStepCondition {
  stepId: number;
  segments: FormattedStepConditionSegment[];
  segmentGroups: FormattedStepConditionSegmentGroup[];
  segmentMatch: boolean;
  segmentGroupMatch: boolean;
  questionOptions: FormattedStepConditionOption[];
  includeOtherInQuestionIds: number[];
}

interface FormatStepConditionsOptions {
  steps: FlowStepFragment[];
  matchedSegmentIds: number[];
  matchedSegmentGroupIds: number[];
  flowVersion: FlowVersion;
}

const formatStepConditions = ({
  steps,
  matchedSegmentIds,
  matchedSegmentGroupIds,
  flowVersion,
}: FormatStepConditionsOptions): FormattedStepCondition[] => {
  const stepConditions: FormattedStepCondition[] = [];

  for (const step of steps) {
    const stepVersion = getFlowObjectVersion(step, flowVersion);

    const segmentGroups: FormattedStepConditionSegmentGroup[] =
      stepVersion.flow_step_version_condition_segment_groups.map((a) => ({
        id: a.segment_group_id || -1,
      }));

    const segments: FormattedStepConditionSegment[] =
      stepVersion.flow_step_version_condition_segments.map((s) => ({
        id: s.segment_id || -1,
      }));

    const questionOptions: FormattedStepConditionOption[] =
      stepVersion.flow_step_version_condition_question_options.map((q) => ({
        id: q.question_option_id || -1,
        questionId: q.question_option?.question_id || -1,
      }));

    if (
      segmentGroups.length ||
      segments.length ||
      questionOptions.length ||
      stepVersion.condition_include_other_in_question_ids.length
    ) {
      const segmentMatch = !!segments.find((segment) =>
        matchedSegmentIds.find(
          (matchedSegmentId) => segment.id === matchedSegmentId
        )
      );
      const segmentGroupMatch = !!segmentGroups.find((g) =>
        matchedSegmentGroupIds.find((id) => g.id === id)
      );

      const condition: FormattedStepCondition = {
        stepId: step.id,
        segmentGroups,
        segments,
        segmentMatch,
        segmentGroupMatch,
        questionOptions,
        includeOtherInQuestionIds:
          stepVersion.condition_include_other_in_question_ids,
      };

      stepConditions.push(condition);
    }
  }

  return stepConditions;
};

export default formatStepConditions;
