import tw, { styled } from "twin.macro";

import FieldInput from "./FieldInput";

export default styled(FieldInput)`
  ${tw`flex items-center pt-0`}

  min-height: 0;
  padding-bottom: 2px;

  label {
    ${tw`ml-2`}
  }

  input {
    position: relative;
    top: 1px;
  }
`;
