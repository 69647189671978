import { faAngleLeft, faAngleRight } from "@fortawesome/pro-regular-svg-icons";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CalendarDate,
  createCalendar,
  parseDate,
} from "@internationalized/date";
import { Temporal } from "@js-temporal/polyfill";
import { useState } from "react";
import { useCalendar, useLocale } from "react-aria";
import { useCalendarState } from "react-stately";

import Button from "../form/Button";
import CalendarGrid from "./CalendarGrid";
import CalendarMonthView from "./CalendarMonthView";
import CalendarYearView from "./CalendarYearView";

interface CalenderProps {
  value: Temporal.PlainDate | null | undefined;
  minValue?: Temporal.PlainDate;
  maxValue?: Temporal.PlainDate;
  className?: string;
  onChange: (value: Temporal.PlainDate) => void;
}

const Calendar: React.FunctionComponent<CalenderProps> = ({
  value,
  minValue,
  maxValue,
  className,
  onChange,
}) => {
  const { locale } = useLocale();
  const [view, setView] = useState<"day" | "month" | "year">("day");
  const [month, setMonth] = useState<number>();

  const state = useCalendarState({
    value: value && parseDate(value.toString()),
    onChange: (newValue) =>
      onChange(Temporal.PlainDate.from(newValue.toString())),
    locale,
    createCalendar,
    minValue: minValue && parseDate(minValue.toString()),
    maxValue: maxValue && parseDate(maxValue.toString()),
  });

  const { calendarProps, prevButtonProps, nextButtonProps, title } =
    useCalendar({}, state);

  return (
    <div {...calendarProps} tw="inline-block text-base" className={className}>
      <div tw="flex items-center p-4 border-b border-divider">
        <div tw="font-semibold text-lg">
          <Button
            type="button"
            buttonType="transparent-no-border"
            onClick={() => setView("month")}
            disabled={view !== "day"}
          >
            {title} <FontAwesomeIcon icon={faCaretDown} tw="ml-2" />
          </Button>
        </div>
        <div tw="ml-auto flex">
          <Button
            type="button"
            buttonType="transparent-no-border"
            onClick={prevButtonProps.onPress as any}
            disabled={prevButtonProps.isDisabled || view !== "day"}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </Button>
          <Button
            type="button"
            buttonType="transparent-no-border"
            onClick={nextButtonProps.onPress as any}
            disabled={nextButtonProps.isDisabled || view !== "day"}
          >
            <FontAwesomeIcon icon={faAngleRight} />
          </Button>
        </div>
      </div>
      {view === "year" ? (
        <CalendarYearView
          selectedYear={state.value?.year || Temporal.Now.plainDateISO().year}
          minYear={minValue?.year}
          maxYear={maxValue?.year}
          onSelectYear={(year) => {
            if (!month) {
              return;
            }

            state.setFocusedDate(new CalendarDate(year, month, 1));
            setView("day");
          }}
          onCancel={() => setView("day")}
        />
      ) : view === "month" ? (
        <CalendarMonthView
          selectedMonth={
            state.value?.month || Temporal.Now.plainDateISO().month
          }
          onSelectMonth={(month) => {
            setMonth(month);
            setView("year");
          }}
          onCancel={() => setView("day")}
        />
      ) : (
        <CalendarGrid state={state} />
      )}
    </div>
  );
};

export default Calendar;
