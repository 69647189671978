import { gql, useLazyQuery, useMutation } from "@apollo/client";

import {
  UseUpdateTagsMutation,
  UseUpdateTagsMutationVariables,
  UseUpdateTagsQuery,
  UseUpdateTagsQueryVariables,
} from "../../__generated__/graphql";
import { UseTagsQuery } from "../tags/useTags";

export default function useUpdateTags() {
  const [updateTags] = useMutation<
    UseUpdateTagsMutation,
    UseUpdateTagsMutationVariables
  >(
    gql`
      mutation UseUpdateTagsMutation($objects: [tag_insert_input!]!) {
        insert_tag(
          objects: $objects
          on_conflict: {
            constraint: tag_account_id_name_key
            update_columns: []
          }
        ) {
          affected_rows
        }
      }
    `,
    {
      refetchQueries: () => [
        {
          query: UseTagsQuery,
        },
      ],
    }
  );

  const [fetchTags] = useLazyQuery<
    UseUpdateTagsQuery,
    UseUpdateTagsQueryVariables
  >(gql`
    query UseUpdateTagsQuery($tags: [citext!]!) {
      tag(where: { name: { _in: $tags } }) {
        id
        name
      }
    }
  `);

  return async (tags: string[]) => {
    await updateTags({
      variables: {
        objects: tags.map((t) => ({
          name: t,
        })),
      },
    });

    const result = await fetchTags({
      variables: {
        tags,
      },
    });

    if (!result.data?.tag) {
      throw new Error("Unable to update tags");
    }

    return result.data.tag.map((t) => t.id);
  };
}
