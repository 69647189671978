import { gql, useQuery } from "@apollo/client";

import { MultipleAddOnIdsDropdownQuery } from "../../__generated__/graphql";
import MultipleIdsDropdown from "./MultipleIdsDropdown";

interface MultipleAddOnIdsDropdownProps {
  value: string[];
  excludeIds?: string[];
  width?: string;
  onChange: (value: string[]) => void;
}

const MultipleAddOnIdsDropdown: React.FunctionComponent<
  MultipleAddOnIdsDropdownProps
> = ({ value, excludeIds = [], width, onChange }) => {
  const { data, loading } = useQuery<MultipleAddOnIdsDropdownQuery>(
    gql`
      query MultipleAddOnIdsDropdownQuery {
        platform_addon {
          platform_id
          name
        }
      }
    `,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const ids = (data?.platform_addon || [])
    .filter((addon) => !excludeIds.includes(addon.platform_id))
    .map((addon) => ({
      id: addon.platform_id,
      label: addon.name,
    }));

  return (
    <MultipleIdsDropdown
      ids={ids}
      value={value}
      onChange={onChange}
      isLoading={loading}
      width={width}
      placeholder="Select an add-on…"
    />
  );
};

export default MultipleAddOnIdsDropdown;
