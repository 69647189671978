import gql from "graphql-tag";

export default gql`
  fragment FlowHasUnsavedChangesQuestionFragment on question {
    id
    type
    published_version {
      id
      question_version_question_options(order_by: { position: asc }) {
        question_option {
          id
          draft_version {
            id
          }
        }
      }
    }
    draft_version {
      id
    }
    question_settings {
      draft_version {
        id
      }
    }
  }
`;
