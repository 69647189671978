import gql from "graphql-tag";

import CustomerPortalAccountFragment from "./CustomerPortalAccountFragment";
import CustomerPortalSubscriptionFragment from "./CustomerPortalSubscriptionFragment";

export default gql`
  fragment CustomerPortalPortalSessionFragment on portal_session {
    return_url

    account {
      ...CustomerPortalAccountFragment
    }

    portal_session_subscribers {
      subscriber {
        platform_id
        subscriptions(where: { status: { _in: [active, trialing, paused] } }) {
          ...CustomerPortalSubscriptionFragment
        }
      }
    }
  }
  ${CustomerPortalAccountFragment}
  ${CustomerPortalSubscriptionFragment}
`;
