import { Placement } from "@popperjs/core";
import classNames from "classnames";
import { nanoid } from "nanoid";
import { ReactNode, useEffect, useState } from "react";

import { useUpsell } from "./UpsellProvider";

const BASE_CLASS_NAME = "paid-feature";
const WRAPPER_CLASS_NAME = `${BASE_CLASS_NAME}-wrapper`;

type DisplayMode = "panel" | "form";

export type PaidFeatureTooltip = {
  text: string;
  placement: Placement;
  offset: [number, number];
};

export const getPaidFeatureElements = (ref: HTMLElement) =>
  (ref
    ? Array.from(ref.querySelectorAll(`.${BASE_CLASS_NAME}`))
    : []) as HTMLElement[];

export const getPaidFeatureWrapperElements = (ref: HTMLElement) =>
  (ref
    ? Array.from(ref.querySelectorAll(`.${WRAPPER_CLASS_NAME}`))
    : []) as HTMLElement[];

export const isPaidFeature = (className?: string) =>
  !!className?.split(" ").find((c) => c === BASE_CLASS_NAME);

export const generatePaidFeatureClassName = (
  upsellText: string,
  isFreeMode: boolean,
  displayMode?: DisplayMode
) =>
  isFreeMode
    ? classNames([
        BASE_CLASS_NAME,
        `${BASE_CLASS_NAME}--text-${upsellText.split(" ").join("_")}`,
        displayMode && `${BASE_CLASS_NAME}--display-${displayMode}`,
      ])
    : undefined;

export const getPaidFeatureSubClassNames = (className?: string) =>
  className
    ?.split(" ")
    .filter(
      (c) =>
        ![BASE_CLASS_NAME, WRAPPER_CLASS_NAME].includes(c) &&
        c.startsWith(BASE_CLASS_NAME)
    );

export const getPaidFeatureTooltip = (className?: string) => {
  const targetClasses = getPaidFeatureSubClassNames(className);

  if (!targetClasses?.length) {
    return null;
  }

  const text = targetClasses
    .find((c) => c.startsWith(`${BASE_CLASS_NAME}--text-`))
    ?.split("--text-")[1]
    ?.split("_")
    .join(" ");

  if (!text) {
    return null;
  }

  const displayMode = targetClasses
    .find((c) => c.startsWith(`${BASE_CLASS_NAME}--display-`))
    ?.split("--display-")[1] as DisplayMode;

  let placement = undefined;
  let offset = undefined;

  switch (displayMode) {
    case "form":
    case "panel":
      placement = "left";
      offset = [0, 20];
      break;

    default:
      placement = "auto";
      offset = [0, 0];
  }

  return {
    text,
    placement,
    offset,
  } as PaidFeatureTooltip;
};

export const addPaidFeatureWrapperClass = (
  el: Element,
  subClassNames: string[] = []
) => {
  el.classList.add(WRAPPER_CLASS_NAME);
  if (!subClassNames.length) {
    el.classList.add(subClassNames.join(" "));
  }
};

export const isPaidFeatureWrapper = (className: string) =>
  !!className.split(" ").find((c) => c === WRAPPER_CLASS_NAME);

const usePaidFeature = () => {
  const [id] = useState<string>(nanoid());
  const { enabled: isFreeMode, addFeatureRef } = useUpsell();

  const [ref, setRef] = useState<HTMLElement | null>(null);

  const generateClassName = (upsellText: string, displayMode?: DisplayMode) => {
    const result = generatePaidFeatureClassName(
      upsellText,
      isFreeMode,
      displayMode
    );

    return result;
  };

  useEffect(() => {
    if (!ref || !id) {
      return;
    }

    const elements = getPaidFeatureElements(ref);

    for (const element of elements) {
      element
        .querySelectorAll("input, select, button, [role=textbox]")
        .forEach((e) => {
          e.setAttribute("disabled", "true");
          e.setAttribute("contenteditable", "false");
        });

      const subClassNames = getPaidFeatureSubClassNames(element.className);
      const tooltip = getPaidFeatureTooltip(element.className);

      if (
        !tooltip?.text ||
        isPaidFeatureWrapper(element.className) ||
        !element.parentElement ||
        isPaidFeatureWrapper(element.parentElement.className)
      ) {
        continue;
      }

      if (["DIV", "SPAN"].includes(element.tagName)) {
        addPaidFeatureWrapperClass(element, subClassNames);
        continue;
      }

      const parentDiv = element.closest("div");

      if (parentDiv) {
        addPaidFeatureWrapperClass(parentDiv, subClassNames);
        continue;
      }
    }

    addFeatureRef(id, ref);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, id]);

  const setPaidFeatureRef = (ref: ReactNode) => {
    setRef(ref as HTMLElement);
  };

  return { isFreeMode, setPaidFeatureRef, generateClassName };
};

export default usePaidFeature;
