import { faChevronDown, faXmark } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDebounce } from "@react-hook/debounce";
import Tippy from "@tippyjs/react";
import classNames from "classnames";
import Fuse from "fuse.js";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FieldError } from "react-hook-form";
import { useHotkeys } from "react-hotkeys-hook";
import tw, { css, styled, theme } from "twin.macro";

import sharedInputStyles from "./form/input/sharedInputStyles";
import TextInput from "./form/input/TextInput";
import Spinner from "./Spinner";
import StandardLinkButton from "./StandardLinkButton";

interface Id {
  id: string;
  label: string;
  img?: string;
  displayLabel?: React.ReactNode;
  displayId?: string;
}

interface IdDropdownProps {
  ids: Id[];
  value: string;
  isLoading?: boolean;
  width?: string;
  placeholder?: string;
  disabled?: boolean;
  createText?: string;
  showId?: boolean;
  resettable?: boolean;
  fieldError?: FieldError;
  displayImages?: boolean;
  onChange: (value: string) => void;
  onClickCreate?: () => void;
  onSearch?: (search: string) => Promise<Id[]>;
}

const Menu = styled.div`
  ${tw`text-base rounded`}
  width: 20rem;
  overflow: hidden;
`;

const DropdownButton = styled.button<{
  width: string;
  displayImages: boolean;
  fieldError?: FieldError;
}>`
  ${sharedInputStyles}
  ${tw`flex items-center`}
  width: ${(props) => props.width};

  fieldset:disabled & {
    ${tw`pointer-events-none`}
  }

  ${(props) => props.displayImages && tw`pl-[.44rem] py-[.44rem]`}

  ${(props) =>
    props.fieldError &&
    tw`border-red-500 focus:ring-red-200 focus:border-red-500`}
`;

const DropdownButtonInner = styled.div`
  ${tw`whitespace-nowrap truncate text-left w-full flex items-center gap-2`}
`;

const DropDownImage = styled.div`
  ${tw`flex h-10 w-10 flex-col justify-center items-center`}
  gap: 0.625rem;
  border-radius: 0.1875rem;
  background: var(--Gray-6, #f2f2f2);
`;

const IdRow = styled.div<{ border: boolean }>`
  ${tw`p-2 last:border-none hover:bg-gray-100 cursor-pointer active:bg-blue-500 active:text-white`}

  ${(props) => props.border && tw`border-b border-divider`}

  &.active {
    ${tw`bg-gray-100`}
  }

  &:active,
  &.highlighted {
    ${tw`bg-blue-500 text-white`}

    svg {
      ${tw`text-white`}
    }
  }
`;

const IdId = styled.div`
  ${tw`text-type-light truncate`}

  div:active > &, div.highlighted > & {
    ${tw`text-white`}
  }
`;

const fuseOptions = {
  keys: ["id", "label", "displayId", "displayLabel"],
  threshold: 0.4,
};

const IdDropdown: React.FunctionComponent<IdDropdownProps> = ({
  ids,
  value,
  isLoading = false,
  width = "280px",
  placeholder = "Select an ID…",
  disabled = false,
  createText = "Create",
  showId = true,
  resettable = false,
  fieldError,
  displayImages = false,
  onChange,
  onClickCreate,
  onSearch,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [actualSearch, setActualSearch, setActualSearchImmediate] = useDebounce(
    search,
    500
  );
  const [filteredIds, setFilteredIds] = useState<Id[]>(ids);
  const searchRef = useRef<HTMLInputElement>(null);
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const scrollContentRef = useRef<HTMLDivElement>(null);
  const selectedIdRef = useRef<HTMLDivElement>(null);
  const [isSearching, setIsSearching] = useState(false);

  const fuse = useMemo(() => new Fuse(ids, fuseOptions), [ids]);

  const handleSearch = useCallback(
    async (search: string) => {
      const doSearch = onSearch
        ? onSearch
        : async (search: string) => {
            const result = fuse.search(search);
            return result.map((r) => r.item);
          };

      setIsSearching(true);
      const result = await doSearch(search);
      setFilteredIds(result);
      setIsSearching(false);
    },
    [fuse, onSearch]
  );

  useEffect(() => {
    if (actualSearch.trim() === "") {
      setFilteredIds(ids);
      return;
    }

    handleSearch(actualSearch);
  }, [handleSearch, ids, actualSearch]);

  useEffect(() => {
    if (isOpen && searchRef.current) {
      searchRef.current.focus();
    }

    if (isOpen) {
      setSelectedIndex(undefined);
    }
  }, [isOpen]);

  useHotkeys(
    "down",
    () => {
      if (!isOpen) {
        return;
      }

      let newIndex =
        typeof selectedIndex === "undefined" ? 0 : selectedIndex + 1;

      if (newIndex > filteredIds.length - 1) {
        newIndex = filteredIds.length - 1;
      }

      setSelectedIndex(newIndex);
    },
    { enableOnTags: ["INPUT"] }
  );

  useHotkeys(
    "up",
    () => {
      if (!isOpen) {
        return;
      }

      if (typeof selectedIndex === "undefined") {
        return;
      }

      const newIndex = selectedIndex < 1 ? 0 : selectedIndex - 1;

      setSelectedIndex(newIndex);
    },
    { enableOnTags: ["INPUT"] }
  );

  useHotkeys(
    "enter",
    () => {
      if (!isOpen || typeof selectedIndex === "undefined") {
        return;
      }

      const id = filteredIds[selectedIndex];
      onChange(id.id);
      setIsOpen(false);
    },
    { enableOnTags: ["INPUT"] }
  );

  useHotkeys(
    "escape",
    () => {
      if (!isOpen) {
        return;
      }

      if (search.trim() !== "") {
        setSearch("");
      } else {
        setIsOpen(false);
      }
    },
    { enableOnTags: ["INPUT"] }
  );

  useEffect(() => {
    setSelectedIndex(undefined);
    if (scrollContentRef.current) {
      scrollContentRef.current.scrollTop = 0;
    }
  }, [search]);

  useEffect(() => {
    if (selectedIdRef.current && scrollContentRef.current) {
      const rect = selectedIdRef.current.getBoundingClientRect();
      const scrollRect = scrollContentRef.current.getBoundingClientRect();

      const top = rect.y - scrollRect.y;

      if (top + rect.height > scrollRect.height) {
        const diff = top + rect.height - scrollRect.height;
        scrollContentRef.current.scrollTop += diff;
      }

      if (top < 0) {
        scrollContentRef.current.scrollTop += top;
      }
    }
  }, [selectedIndex]);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const selectedId = ids.find((id) => id.id === value);

  return (
    <Tippy
      theme="light"
      className="dropdown-menu"
      offset={[0, 3]}
      maxWidth="none"
      content={
        <Menu>
          {isLoading ? (
            <Spinner padding="1rem 0" />
          ) : (
            <>
              <div tw="p-2 sticky top-0 bg-white border-b border-divider">
                <TextInput
                  ref={searchRef}
                  tw="w-full"
                  placeholder="Search"
                  value={search}
                  onChange={(e) => {
                    if (e.currentTarget.value.trim() === "") {
                      setActualSearchImmediate("");
                    }

                    setSearch(e.currentTarget.value);
                    setActualSearch(e.currentTarget.value);
                  }}
                />
              </div>
              <div
                ref={scrollContentRef}
                css={css`
                  overflow-y: scroll;
                  overflow-x: hidden;
                  max-height: 18rem;
                `}
              >
                {isSearching ? (
                  <Spinner padding="1rem 0" />
                ) : (
                  <>
                    {filteredIds.map((id, i) => (
                      <IdRow
                        className={classNames({
                          active: id.id === value,
                          highlighted: selectedIndex === i,
                        })}
                        key={id.id}
                        onClick={() => {
                          onChange(id.id);
                          setIsOpen(false);
                        }}
                        border={showId}
                        ref={i === selectedIndex ? selectedIdRef : undefined}
                      >
                        <div css={showId ? tw`font-semibold` : undefined}>
                          {id.displayLabel || id.label}
                        </div>
                        {showId && <IdId>{id.displayId || id.id}</IdId>}
                      </IdRow>
                    ))}
                    {filteredIds.length < 1 && (
                      <IdRow tw="pointer-events-none" border={false}>
                        <IdId>No results.</IdId>
                      </IdRow>
                    )}
                  </>
                )}
              </div>
            </>
          )}
          {onClickCreate && (
            <div tw="p-2 border-t border-divider">
              <StandardLinkButton
                onClick={() => {
                  setIsOpen(false);
                  onClickCreate();
                }}
              >
                {createText}
              </StandardLinkButton>
            </div>
          )}
        </Menu>
      }
      visible={isOpen}
      onClickOutside={() => setIsOpen(false)}
      interactive={true}
      appendTo={document.body}
      placement="bottom-start"
      arrow={false}
    >
      <div
        tw="relative"
        css={css`
          width: ${width};
        `}
      >
        <DropdownButton
          type="button"
          onClick={handleClick}
          width={width}
          disabled={disabled}
          fieldError={fieldError}
          displayImages={displayImages}
        >
          <DropdownButtonInner>
            {displayImages &&
              (selectedId?.img ? (
                <img
                  src={selectedId?.img}
                  alt="Product"
                  tw="w-10 h-10 rounded-[0.1875rem]"
                />
              ) : (
                <DropDownImage>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="18"
                    viewBox="0 0 20 18"
                    fill="none"
                  >
                    <path
                      d="M0.5 3.0625C0.5 1.76367 1.53906 0.6875 2.875 0.6875H17.125C18.4238 0.6875 19.5 1.76367 19.5 3.0625V14.9375C19.5 16.2734 18.4238 17.3125 17.125 17.3125H2.875C1.53906 17.3125 0.5 16.2734 0.5 14.9375V3.0625ZM12.4863 7.0332C12.3379 6.77344 12.041 6.625 11.7812 6.625C11.4844 6.625 11.1875 6.77344 11.0391 7.0332L7.81055 11.7832L6.80859 10.5215C6.66016 10.3359 6.40039 10.1875 6.14062 10.1875C5.84375 10.1875 5.58398 10.3359 5.43555 10.5215L3.06055 13.4902C2.83789 13.7871 2.80078 14.1582 2.94922 14.4551C3.09766 14.752 3.39453 14.9375 3.76562 14.9375H7.32812H8.51562H16.2344C16.5312 14.9375 16.8652 14.7891 17.0137 14.4922C17.1621 14.1953 17.125 13.8242 16.9395 13.5645L12.4863 7.0332ZM4.65625 6.625C5.28711 6.625 5.84375 6.29102 6.17773 5.73438C6.51172 5.21484 6.51172 4.50977 6.17773 3.95312C5.84375 3.43359 5.28711 3.0625 4.65625 3.0625C3.98828 3.0625 3.43164 3.43359 3.09766 3.95312C2.76367 4.50977 2.76367 5.21484 3.09766 5.73438C3.43164 6.29102 3.98828 6.625 4.65625 6.625Z"
                      fill="#A1A1AA"
                    />
                  </svg>
                </DropDownImage>
              ))}
            {selectedId ? (
              selectedId.displayLabel ? (
                selectedId.displayLabel
              ) : selectedId.label ? (
                selectedId.label
              ) : (
                selectedId.id
              )
            ) : (
              <span tw="text-type-light">{placeholder}</span>
            )}
          </DropdownButtonInner>
          <FontAwesomeIcon
            icon={faChevronDown}
            tw="ml-2"
            transform="shrink-6"
            color={theme`colors.gray.600`}
          />
        </DropdownButton>
        {resettable && !!value && (
          <div tw="absolute top-[8px] right-[32px] border-r border-gray-300 pr-[9px] h-[18px]">
            <FontAwesomeIcon
              role="button"
              icon={faXmark}
              tw="text-gray-400 hover:text-gray-500 relative top-[-2.5px]"
              transform="shrink-5"
              onClick={() => onChange("")}
            />
          </div>
        )}
      </div>
    </Tippy>
  );
};

export default IdDropdown;
