import classNames from "classnames";
import { useState } from "react";

interface LikelihoodToReturnProps {
  onChange: (value: string) => void;
  isFormQuestion?: boolean;
}

const LikelihoodToReturn: React.FunctionComponent<LikelihoodToReturnProps> = ({
  onChange,
  isFormQuestion = false,
}) => {
  const [selectedValue, setSelectedValue] = useState<string | null>(null);

  const onSelect = (value: string) => {
    setSelectedValue(value);
    onChange(value);
  };

  return (
    <div
      className={classNames({
        "flow-rating-selector": true,
        "flow-rating-selector--compact": isFormQuestion,
      })}
    >
      <div className="flow-rating-selector__ratings">
        {[1, 6].map((i) => (
          <div
            className={classNames({
              "flow-rating-selector__ratings-inner-wrapper": true,
              "flow-rating-selector__ratings-inner-wrapper--compact":
                isFormQuestion,
            })}
            key={i}
          >
            {Array.from(new Array(5), (x, j) => j + i).map((j) => (
              <div
                className={classNames({
                  "flow-rating-selector__rating": true,
                  "flow-rating-selector__rating--active":
                    selectedValue === j.toString(),
                })}
                onClick={() => onSelect(j.toString())}
                key={j}
              >
                <div className="flow-rating-selector__rating__label">{j}</div>
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className="flow-rating-selector__help-text">
        <div className="flow-rating-selector__help-text__left">1 - Least</div>
        <div className="flow-rating-selector__help-text__right">Most - 10</div>
      </div>
    </div>
  );
};

export default LikelihoodToReturn;
