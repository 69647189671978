import { Temporal } from "@js-temporal/polyfill";

import {
  FlowOfferFragment,
  FlowSubscriptionFragment,
  PauseReason,
} from "../../../../__generated__/graphql";
import { FlowError, FlowText } from "../lib/types";
import OfferContainer from "../OfferContainer";

interface InterventionOfferProps {
  offer: FlowOfferFragment;
  isEditMode: boolean;
  flowText: FlowText;
  pauseReasons?: PauseReason[];
  nextOrderDate?: Temporal.PlainDate;
  onAccept: (
    selectedOptionIndex: number,
    groupOffer: null,
    selectedVariantId: string | null | undefined,
    rescheduleTo: Temporal.PlainDate | null | undefined,
    pauseReasonCode: string | null | undefined,
    pauseAt: Temporal.PlainDate | null | undefined,
    resumeAt: Temporal.PlainDate | null | undefined
  ) => void;
  swappableProducts?: FlowSubscriptionFragment | null;
  disableVideoAutoPlay?: boolean;
  error?: FlowError;
  baseClassName?: string;
}

const InterventionOffer: React.FunctionComponent<InterventionOfferProps> = ({
  offer,
  isEditMode,
  flowText,
  pauseReasons,
  onAccept,
  nextOrderDate,
  swappableProducts = null,
  disableVideoAutoPlay,
  error,
  baseClassName = "flow-instant-cancel__offer",
}) => (
  <div className={baseClassName}>
    <OfferContainer
      offer={offer}
      previewMode={isEditMode}
      baseClassName={baseClassName}
      pauseReasons={pauseReasons}
      flowText={flowText}
      onAccept={onAccept}
      onAcceptGroupOffer={() => {}}
      onDecline={() => {}}
      showDeclineButton={false}
      isFinalStep={false}
      nextOrderDate={nextOrderDate}
      swappableProducts={swappableProducts}
      disableVideoAutoPlay={disableVideoAutoPlay}
      error={error}
    />
  </div>
);

export default InterventionOffer;
