interface HeaderProps {
  logoUrl?: string | null;
  companyName?: string | null;
}

const Header: React.FunctionComponent<HeaderProps> = ({
  logoUrl,
  companyName,
}) => (
  <div tw="flex justify-center pb-10">
    {logoUrl ? (
      <img src={logoUrl} alt="" tw="max-h-[60px]" />
    ) : (
      <div tw="font-title text-xl">{companyName}</div>
    )}
  </div>
);

export default Header;
