import Tippy from "@tippyjs/react";

import { integration_type_enum } from "../../__generated__/graphql";
import klaviyoIconDark from "../../assets/klaviyo-logo-mark.svg";
import klaviyoIconLight from "../../assets/klaviyo-logo-mark-white.svg";

interface SegmentIntegrationIconProps {
  integrationType: integration_type_enum;
  color?: "dark" | "light";
  tooltip?: boolean;
}

const SegmentIntegrationIcon: React.FunctionComponent<
  SegmentIntegrationIconProps
> = ({ integrationType, color = "dark", tooltip = false }) => {
  const icon = (() => {
    switch (integrationType) {
      case "klaviyo":
        return (
          <img
            src={color === "dark" ? klaviyoIconDark : klaviyoIconLight}
            alt=""
            tw="inline ml-2 w-[10px] mt-[-2px]"
          />
        );

      default:
        return null;
    }
  })();

  return (
    <Tippy
      content={
        <>
          <span tw="capitalize">{integrationType}</span> segment
        </>
      }
      disabled={!tooltip}
    >
      {icon ? icon : <></>}
    </Tippy>
  );
};

export default SegmentIntegrationIcon;
