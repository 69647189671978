import { Temporal } from "@js-temporal/polyfill";
import Tippy from "@tippyjs/react";
import { useState } from "react";

import Calendar from "../../calendar/Calendar";

export interface DatePickerInputRenderProps {
  onClick: () => void;
}

export type DatePickerInputRender = (
  props: DatePickerInputRenderProps
) => React.ReactElement;

interface DatePickerInputProps {
  value: Temporal.PlainDate | null | undefined;
  minDate?: Temporal.PlainDate;
  maxDate?: Temporal.PlainDate;
  onChange: (value: Temporal.PlainDate) => void;
  render: DatePickerInputRender;
}

const DatePickerInput: React.FunctionComponent<DatePickerInputProps> = ({
  value,
  onChange,
  minDate,
  maxDate,
  render,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Tippy
      theme="light"
      className="dropdown-menu"
      offset={[0, 3]}
      maxWidth="none"
      visible={isOpen}
      onClickOutside={() => setIsOpen(false)}
      interactive={true}
      appendTo={document.body}
      placement="bottom"
      arrow={false}
      content={
        <Calendar
          value={value}
          onChange={(value) => {
            onChange(value);
            setIsOpen(false);
          }}
          minValue={minDate}
          maxValue={maxDate}
        />
      }
    >
      {render({
        onClick: () => setIsOpen(!isOpen),
      })}
    </Tippy>
  );
};

export default DatePickerInput;
