import gql from "graphql-tag";

export default gql`
  fragment RulePropertyFragment on property {
    id
    key
    type
    entity
    token
    name
    created_at
  }
`;
