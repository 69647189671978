import gql from "graphql-tag";

import CampaignTextVersionFragment from "./CampaignTextVersionFragment";

export default gql`
  fragment CampaignTextFragment on campaign_text {
    campaign_id
    key
    published_version {
      ...CampaignTextVersionFragment
    }
  }
  ${CampaignTextVersionFragment}
`;
