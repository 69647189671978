import tw, { styled } from "twin.macro";

const FieldHint = styled.div`
  ${tw`pt-1 text-gray-400 leading-normal`}
  a {
    ${tw`text-link hover:underline`}
  }
`;

export default FieldHint;
