import { gql, useQuery } from "@apollo/client";
import { faPencil } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

import {
  CustomerPortalBillingDetailsQuery,
  CustomerPortalBillingDetailsQueryVariables,
} from "../../../__generated__/graphql";
import Spinner from "../../../common/Spinner";
import StandardLinkButton from "../../../common/StandardLinkButton";
import CardBrand from "./CardBrand";
import CardContent from "./CardContent";
import CardHeading from "./CardHeading";
import PaymentMethodModal from "./PaymentMethodModal";

interface BillingDetailsProps {
  sessionToken: string;
  platformCustomerId: string;
}

const BillingDetails: React.FunctionComponent<BillingDetailsProps> = ({
  sessionToken,
  platformCustomerId,
}) => {
  const [paymentMethodModalIsOpen, setPaymentMethodModalIsOpen] =
    useState(false);

  const { data, loading, refetch } = useQuery<
    CustomerPortalBillingDetailsQuery,
    CustomerPortalBillingDetailsQueryVariables
  >(
    gql`
      query CustomerPortalBillingDetailsQuery(
        $input: PortalCustomerBillingDetailsInput!
      ) {
        portalCustomerBillingDetails(input: $input) {
          name
          card {
            brand
            last4
            expirationMonth
            expirationYear
          }
        }
      }
    `,
    {
      variables: {
        input: {
          portalSessionToken: sessionToken,
          platformCustomerId,
        },
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  const details = data?.portalCustomerBillingDetails;

  return (
    <>
      <CardHeading>Billing information</CardHeading>
      <CardContent>
        {!details || loading ? (
          <Spinner />
        ) : (
          <div tw="flex">
            <div tw="flex-grow mr-4">
              <div tw="font-semibold border-b border-divider pb-3 mb-3">
                <div>{details.name}</div>
              </div>
              <div>
                {details.card ? (
                  <>
                    <div tw="font-semibold">
                      <span>
                        <CardBrand brand={details.card.brand} />
                      </span>{" "}
                      <span>····</span> <span>{details.card.last4}</span>
                    </div>
                    <div tw="text-type-light text-sm">
                      Expires{" "}
                      {details.card.expirationMonth.toString().padStart(2, "0")}
                      /{details.card.expirationYear}
                    </div>
                  </>
                ) : (
                  <StandardLinkButton
                    tw="font-semibold"
                    onClick={() => setPaymentMethodModalIsOpen(true)}
                  >
                    + Add payment method
                  </StandardLinkButton>
                )}
              </div>
            </div>
            {details.card && (
              <div tw="ml-auto flex items-center">
                <button
                  type="button"
                  tw="hover:bg-gray-100 active:bg-gray-200 rounded-lg w-[36px] h-[36px]"
                  onClick={() => setPaymentMethodModalIsOpen(true)}
                >
                  <FontAwesomeIcon icon={faPencil} fixedWidth />
                </button>
              </div>
            )}
          </div>
        )}
      </CardContent>
      <PaymentMethodModal
        isOpen={paymentMethodModalIsOpen}
        sessionToken={sessionToken}
        onClose={(updated) => {
          setPaymentMethodModalIsOpen(false);
          if (updated) {
            // Slight delay helps ensure we get the *new* CC details
            setTimeout(() => {
              refetch();
            }, 500);
          }
        }}
      />
    </>
  );
};

export default BillingDetails;
