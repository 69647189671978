import { gql, useQuery } from "@apollo/client";

import { AddOnIdDropdownQuery } from "../../__generated__/graphql";
import IdDropdown from "../../common/IdDropdown";

interface AddOnIdDropdownProps {
  value: string;
  onChange: (value: string) => void;
}

const AddOnIdDropdown: React.FunctionComponent<AddOnIdDropdownProps> = ({
  value,
  onChange,
}) => {
  const { data, loading } = useQuery<AddOnIdDropdownQuery>(gql`
    query AddOnIdDropdownQuery {
      platform_addon {
        platform_id
        name
      }
    }
  `);

  const ids = (data?.platform_addon || []).map((addon) => ({
    id: addon.platform_id,
    label: addon.name,
  }));

  return (
    <IdDropdown
      ids={ids}
      value={value}
      onChange={onChange}
      isLoading={loading}
    />
  );
};

export default AddOnIdDropdown;
