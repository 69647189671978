import { Node } from "slate";

import serializeToString from "./serializeToString";

export default function isContentEmpty(content: Node[] | undefined) {
  if (!content) {
    return true;
  }

  if (
    content.find((n) => n.type === "video") ||
    content.some((c) =>
      (c.children as Node[])?.find((n) => n.type === "property_tag")
    ) ||
    content.some((c) =>
      (c.children as Node[])?.find((n) => n.type === "offer_tag")
    )
  ) {
    return false;
  }

  return serializeToString(content).trim() === "";
}
