import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import tw from "twin.macro";

import {
  language_enum,
  offer_goal_enum,
  platform_enum,
} from "../../__generated__/graphql";
import Editor from "../../common/editor2/Editor";
import EditorSelector from "../../common/editor2/EditorSelector";
import { isContentEmpty } from "../../common/editor2/lib";
import FieldCheckboxInput from "../../common/form/FieldCheckboxInput";
import FieldError from "../../common/form/FieldError";
import FieldHint from "../../common/form/FieldHint";
import FieldInput from "../../common/form/FieldInput";
import FieldLabel from "../../common/form/FieldLabel";
import FieldRow from "../../common/form/FieldRow";
import FieldRowBlock from "../../common/form/FieldRowBlock";
import FieldSet from "../../common/form/FieldSet";
import FieldSetTitle from "../../common/form/FieldSetTitle";
import FormMode from "../../common/form/FormMode";
import ImageInput from "../../common/form/input/ImageInput";
import SelectInput from "../../common/form/input/SelectInput";
import TagsInput from "../../common/form/input/TagsInput";
import TextInput from "../../common/form/input/TextInput";
import useTranslatableForm, {
  TranslatedForms,
} from "../../common/form/useTranslatableForm";
import HelpIcon from "../../common/HelpIcon";
import MetadataInput from "../../common/MetadataInput";
import { useTranslations } from "../../common/translations/TranslationsProvider";
import useAccountFeatures from "../../common/useAccountFeatures";
import useFocusFirstEmptyInput from "../../common/useFocusFirstEmptyInput";
import LanguageRadio from "../flow/edit/LanguageRadio";
import getDefaultFormValues from "./forms/lib/getDefaultFormValues";
import getTranslatableFields from "./forms/lib/getTranslatableFields";
import ConversionOfferFormPartial from "./forms/partials/ConversionOfferFormPartial";
import { TrialExtensionOfferFormValues } from "./forms/types";
import useValidateHeadline from "./lib/useValidateHeadline";
import useValidateName from "./lib/useValidateName";
import validateMetadata from "./lib/validateMetadata";

interface TrialExtensionOfferFormProps {
  mode: FormMode;
  goal?: offer_goal_enum;
  platform: platform_enum;
  onChange: (forms: TranslatedForms<TrialExtensionOfferFormValues>) => void;
  onSubmit: (forms: TranslatedForms<TrialExtensionOfferFormValues>) => void;
  initialValues?: TranslatedForms<TrialExtensionOfferFormValues>;
  onChangeEditingLanguage: (language: language_enum) => void;
  isSubmitting: boolean;
  tags: string[];
}

const TrialExtensionOfferForm: React.FunctionComponent<
  TrialExtensionOfferFormProps
> = ({
  mode,
  platform,
  onChange,
  onSubmit,
  initialValues,
  onChangeEditingLanguage,
  isSubmitting,
  goal = offer_goal_enum.retention,
  tags,
}) => {
  const { defaultLanguage, enabledLanguages } = useTranslations();
  const defaultValues =
    initialValues ||
    getDefaultFormValues<TrialExtensionOfferFormValues>({
      type: "trial_extension",
      enabledLanguages,
      platform,
    });

  const {
    register,
    watch,
    formState,
    handleSubmit,
    control,
    editingLanguage,
    setEditingLanguage,
    forms,
    trigger,
  } = useTranslatableForm<TrialExtensionOfferFormValues>({
    defaultValues,
    initialLanguage: defaultLanguage,
    languages: enabledLanguages,
    defaultLanguage,
    translatableFields: getTranslatableFields("trial_extension"),
  });
  const values = watch();
  const [formRef, setFormRef] = useState<HTMLFormElement | null>(null);
  useFocusFirstEmptyInput(formRef);
  const validateName = useValidateName(formState.dirtyFields.name || false);
  const image = watch("image");
  const validateHeadline = useValidateHeadline(image);
  const { features } = useAccountFeatures();

  useEffect(() => {
    onChange(forms);
  }, [forms, onChange]);

  return (
    <form
      id="create-offer"
      onChange={() => onChange(forms)}
      ref={(ref) => setFormRef(ref)}
      onSubmit={handleSubmit(onSubmit)}
    >
      <fieldset disabled={isSubmitting}>
        <input {...register("type")} type="hidden" value="trial_extension" />
        <FieldRow>
          <FieldLabel>
            <label htmlFor="name">Name</label>
          </FieldLabel>
          <FieldInput>
            <TextInput
              {...register("name", {
                required: true,
                validate: validateName,
              })}
              id="name"
              width="full"
              fieldError={formState.errors.name}
            />
            <FieldError error={formState.errors.name} />
          </FieldInput>
        </FieldRow>
        <FieldRow>
          <FieldLabel>Tags</FieldLabel>
          <FieldInput>
            <Controller
              control={control}
              name="tags"
              render={({ field }) => (
                <TagsInput
                  tags={tags}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </FieldInput>
        </FieldRow>
        {goal === offer_goal_enum.conversion ? (
          <ConversionOfferFormPartial />
        ) : (
          <FieldRow>
            <FieldLabel>
              <label htmlFor="style">Style</label>
            </FieldLabel>
            <FieldInput>
              <SelectInput
                {...register("style")}
                id="style"
                defaultValue="modal"
              >
                <option value="modal">Modal</option>
                <option value="step">Step</option>
              </SelectInput>
              <FieldHint>
                {values.style === "modal" ? (
                  <>Offer will be shown in a pop-up modal dialog.</>
                ) : (
                  <>Offer will be shown as a full flow step.</>
                )}
              </FieldHint>
            </FieldInput>
          </FieldRow>
        )}
        <FieldRow>
          <FieldLabel>
            <label>
              Allow override{" "}
              <HelpIcon content="By default, an offer will not be displayed to a subscriber if they have previously accepted another offer. This setting will allow this offer to override any previously accepted offers." />
            </label>
          </FieldLabel>
          <FieldCheckboxInput>
            <input
              {...register("allowOverride")}
              id="allowOverride"
              type="checkbox"
            />
            <label htmlFor="allowOverride">Override other offers</label>
          </FieldCheckboxInput>
        </FieldRow>

        <FieldSet title="Extension details" disabled={mode === "edit"}>
          <FieldRow>
            <FieldLabel>
              <label>Days</label>
            </FieldLabel>
            <FieldInput>
              <TextInput
                {...register("days", {
                  required: true,
                  validate: (value) => Number(value) > 0,
                })}
                id="days"
                width="xs"
                fieldError={formState.errors.days}
              />
              <FieldHint>Days by which to extend the trial.</FieldHint>
            </FieldInput>
          </FieldRow>
        </FieldSet>

        <FieldSet title={`Offer ${values.style}`}>
          {values.style === "modal" && (
            <FieldRow>
              <FieldLabel>
                <label>Banner</label>
              </FieldLabel>
              <FieldCheckboxInput>
                <input
                  {...register("showBanner")}
                  id="showBanner"
                  type="checkbox"
                />
                <label htmlFor="showBanner">Show "Special Offer" banner</label>
              </FieldCheckboxInput>
            </FieldRow>
          )}
          {goal === offer_goal_enum.conversion && values.timerMode !== "" && (
            <FieldRow>
              <FieldLabel>
                <label>Countdown timer</label>
              </FieldLabel>
              <FieldCheckboxInput>
                <input
                  {...register("countdownTimer")}
                  id="countdownTimer"
                  type="checkbox"
                />
                <label htmlFor="countdownTimer">Show countdown timer</label>
              </FieldCheckboxInput>
            </FieldRow>
          )}
          {enabledLanguages.length > 1 && (
            <LanguageRadio
              value={editingLanguage}
              languages={enabledLanguages}
              defaultLanguage={defaultLanguage}
              onChange={(language) => {
                setEditingLanguage(language);
                onChangeEditingLanguage(language);
              }}
            />
          )}
          <FieldRow>
            <FieldLabel>
              <label htmlFor="headline">Headline</label>
            </FieldLabel>
            <FieldInput>
              <TextInput
                {...register("headline", { validate: validateHeadline })}
                id="headline"
                width="full"
                fieldError={formState.errors.headline}
              />
              <FieldError error={formState.errors.headline} />
            </FieldInput>
          </FieldRow>
          <FieldRow>
            <FieldLabel>
              <label>Image</label>
            </FieldLabel>
            <FieldInput>
              <Controller
                control={control}
                name="image"
                render={({ field }) => (
                  <ImageInput
                    value={field.value}
                    onChange={(logoUrl) => {
                      field.onChange(logoUrl);
                      if (formState.isSubmitted) {
                        trigger();
                      }
                    }}
                  />
                )}
              />
            </FieldInput>
          </FieldRow>
          <FieldRowBlock>
            <FieldLabel>
              <label>Content</label>
            </FieldLabel>
            <Controller
              control={control}
              name="content"
              render={({ field }) => {
                const form = forms[editingLanguage];
                if (!form) {
                  return <></>;
                }

                return (
                  <EditorSelector
                    initialValueKey={editingLanguage}
                    isOfferContent
                    hasCustomProperties={!!features.custom_properties}
                    format={form.contentFormat}
                    value={form.content}
                    onChange={(value) => {
                      field.onChange(value);
                      onChange({
                        ...forms,
                        [editingLanguage]: {
                          ...forms[editingLanguage],
                          content: value,
                        },
                      });
                    }}
                  />
                );
              }}
            />
          </FieldRowBlock>
          <FieldRow>
            <FieldLabel>
              <label>Button text</label>
            </FieldLabel>
            <FieldInput>
              <TextInput
                {...register("buttonText", { required: true })}
                id="buttonText"
                width="md"
                fieldError={formState.errors.buttonText}
              />
            </FieldInput>
          </FieldRow>
          {goal === "conversion" && (
            <>
              <FieldSetTitle>Confirmation modal</FieldSetTitle>
              <FieldRow>
                <FieldLabel>
                  <label>Enabled</label>
                </FieldLabel>
                <FieldCheckboxInput>
                  <input
                    {...register("confirmationEnabled")}
                    id="confirmationEnabled"
                    type="checkbox"
                  />
                  <label htmlFor="confirmationEnabled">
                    Show confirmation modal
                  </label>
                </FieldCheckboxInput>
              </FieldRow>
              <FieldRowBlock
                css={!values.confirmationEnabled ? tw`hidden` : undefined}
              >
                <FieldLabel>
                  <label>Content</label>
                </FieldLabel>
                <Controller
                  control={control}
                  name="confirmationContent"
                  render={({ field }) => (
                    <Editor
                      key={editingLanguage}
                      height="10rem"
                      tagsEnabled={false}
                      linksEnabled={false}
                      listsEnabled={false}
                      imagesEnabled={false}
                      videosEnabled={false}
                      initialValue={field.value}
                      initialValueKey={editingLanguage}
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                      fieldError={formState.errors.confirmationContent}
                    />
                  )}
                  rules={{
                    validate: (value) =>
                      values.confirmationEnabled
                        ? !!value && !isContentEmpty(JSON.parse(value))
                          ? true
                          : "Content is required."
                        : true,
                  }}
                />
              </FieldRowBlock>
            </>
          )}
        </FieldSet>

        <FieldSet title="Metadata">
          <FieldRow>
            <FieldInput>
              <Controller
                control={control}
                name="metadata"
                render={({ field }) => (
                  <MetadataInput
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
                rules={{
                  validate: validateMetadata,
                }}
              />
              <FieldError error={formState.errors.metadata} />
            </FieldInput>
          </FieldRow>
        </FieldSet>
      </fieldset>
    </form>
  );
};

export default TrialExtensionOfferForm;
