import { gql, useMutation, useQuery } from "@apollo/client";
import {
  faGripVertical,
  faInfoCircle,
  faPlus,
  faTimesCircle,
  faVial,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import classNames from "classnames";
import { cloneDeep, isEqual } from "lodash";
import { nanoid } from "nanoid";
import { useCallback, useEffect, useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
  ResponderProvided,
} from "react-beautiful-dnd";
import { useToasts } from "react-toast-notifications";
import { isPresent } from "ts-is-present";
import tw, { styled, theme } from "twin.macro";

import {
  AppPropertyFragment,
  AppSegmentGroupFragment,
  FlowRouteRuleFragment,
  FlowRoutesPanelDeleteEligibilityMessageMutation,
  FlowRoutesPanelDeleteEligibilityMessageMutationVariables,
  FlowRoutesPanelQuery,
  FlowRoutesPanelSaveUnsavedChangesMutation,
  FlowRoutesPanelSaveUnsavedChangesMutationVariables,
  SegmentConditionFragment,
  SegmentFragment,
} from "../../__generated__/graphql";
import Card from "../../common/card/Card";
import ConfirmationModal from "../../common/ConfirmationModal";
import Button from "../../common/form/Button";
import FieldHint from "../../common/form/FieldHint";
import FieldInput from "../../common/form/FieldInput";
import FieldLabel from "../../common/form/FieldLabel";
import FieldRow from "../../common/form/FieldRow";
import SelectInput from "../../common/form/input/SelectInput";
import TheFlowRouteRuleFragment from "../../common/fragments/FlowRouteRuleFragment";
import HelpBlock from "../../common/HelpBlock";
import Panel, { PanelProps } from "../../common/panel/Panel";
import PanelBody from "../../common/panel/PanelBody";
import PanelButtons from "../../common/panel/PanelButtons";
import PanelTitle from "../../common/panel/PanelTitle";
import RuleInlineSegmentFragment from "../../common/rules/fragments/RuleInlineSegmentFragment";
import RulePropertyFragment from "../../common/rules/fragments/RulePropertyFragment";
import useInsertInlineSegments from "../../common/rules/mutations/useInsertInlineSegments";
import {
  BoxContainer,
  EligibilityMessageBox,
} from "../../common/rules/RuleBoxes";
import RuleEditor, {
  RuleEditorFlowRouteRuleValue,
} from "../../common/rules/RuleEditor";
import RuleSegments from "../../common/rules/RuleSegments";
import StandardEternalLink from "../../common/StandardExternalLink";
import StandardLinkButton from "../../common/StandardLinkButton";
import useAccountFeatures from "../../common/useAccountFeatures";
import useViewer from "../../common/useViewer";
import PropertyPanel from "../properties/PropertyPanel";
import CreateSegmentPanel from "../segments/CreateSegmentPanel";
import SegmentGroupPanel from "../segments/SegmentGroupPanel";
import usePaidFeature from "../upgrade-account/usePaidFeature";
import { useUpsellBanner } from "../upgrade-account/useUpsellBanner";
import EligibilityMessagePanel from "./edit/EligibilityMessagePanel";

type FlowRoutesPanelProps = PanelProps & {
  onClose: () => void;
};

export interface Rule {
  segmentGroupIds: number[];
  segmentIds: number[];
  flowIds: number[];
  flowTestIds: number[];
}

export const defaultRule: FlowRouteRuleFragment = {
  __typename: "flow_route_rule",
  id: -1,
  segment_group_ids: [],
  segment_ids: [],
  flow_ids: [],
  flow_test_ids: [],
  eligibility_message_ids: [],
  flow_route_rule_segment_groups: [],
  flow_route_rule_segments: [],
  flow_route_rule_flows: [],
  flow_route_rule_flow_tests: [],
  flow_route_rule_eligibility_messages: [],
};

const dragHandleWidth = 40;
const deleteColumnWidth = 40;
const DeEpmphasizeColor = "#f9f9f9";

export const RuleHeader = styled.div`
  ${tw`flex border-b border-gray-200 rounded-t`}

  &.rule-is-editing {
    background: ${DeEpmphasizeColor};
  }
`;

export const RuleHeaderDragColumn = styled.div`
  width: ${dragHandleWidth}px;
`;

export const RuleHeaderColumn = tw.div`text-gray-600 font-semibold p-2 w-1/2`;

export const RuleHeaderDeleteColumn = styled.div`
  width: ${deleteColumnWidth}px;
`;

export const RulesZeroState = styled.div`
  ${tw`p-10 text-center text-gray-600`}
`;

export const Rules = styled.div`
  &.rule-is-editing {
    ${tw`rounded`}
    background: ${DeEpmphasizeColor};
  }
`;

export const RuleRow = styled.div`
  ${tw`bg-white border-b border-gray-200 border-dashed last:border-b-0 last:rounded-b`}

  &.rule-is-dragging {
    ${tw`border border-solid rounded`}
  }

  &.rule-is-editing {
    ${tw`bg-white border border-solid border-gray-400 shadow-lg relative transition-all duration-200 rounded-none`}
    transform: scale(1.01);

    .rule-columns {
      ${tw`cursor-default`}
    }
  }

  &.rule-editing-is-disabled {
    ${tw`opacity-25 cursor-not-allowed`}
  }
`;

export const RuleBody = tw.div`flex`;

export const RuleColumns = tw.div`w-full flex cursor-pointer`;

export const RuleColumn = tw.div`p-2 w-1/2 text-gray-400`;

export const RuleInstructions = tw.div`text-sm`;

export const RuleDrag = styled.div`
  ${tw`flex justify-center`}
  width: ${dragHandleWidth}px;
`;

export const RuleDelete = styled.div`
  ${tw`flex justify-center text-red-500 pt-3`}
  width: ${deleteColumnWidth}px;

  button {
    ${tw`flex justify-center text-red-500 opacity-75 hover:opacity-100 focus:outline-none`}
  }
`;

export const DragHandle = styled.div`
  ${tw`text-gray-400 p-2 focus:outline-none`}

  &.is-disabled {
    ${tw`opacity-50`}
  }
`;

export const Box = styled.div<{ isEditable: boolean; isOn: boolean }>`
  ${tw`font-semibold px-2 rounded-md text-white m-1`}
  ${(props) => !props.isOn && tw`bg-gray-400!`}
  ${(props) => props.isEditable && tw`cursor-pointer`}
`;

export const AnyBox = styled(Box)`
  ${tw`bg-gray-800`}
`;

export const SegmentBox = styled(Box)`
  ${tw`bg-purple-600`}
`;

export const FlowBox = styled(Box)`
  ${tw`bg-orange-300`}
`;

export const FlowTestBox = styled(Box)`
  ${tw`bg-orange-400`}
`;

export const RuleEditButtons = styled.div`
  ${tw`pl-1 py-2 flex flex-row-reverse`}
  button {
    ${tw`ml-2`}
  }

  margin: 0 ${deleteColumnWidth}px 0 ${dragHandleWidth}px;
`;

const FlowRoutesPanel: React.FunctionComponent<FlowRoutesPanelProps> = ({
  onClose,
  ...props
}) => {
  const { features } = useAccountFeatures();

  const [editingId, setEditingId] = useState<number | null>(null);
  const [editingNewRule, setEditingNewRule] = useState(false);
  const [stagedChanges, setStagedChanges] = useState<FlowRouteRuleFragment[]>(
    []
  );
  const [isSaving, setIsSaving] = useState(false);
  const [confirmSaveModalIsOpen, setConfirmSaveModalIsOpen] = useState(false);
  const [defaultFlowId, setDefaultFlowId] = useState<number>();
  const { addToast } = useToasts();
  const { viewer } = useViewer();

  const { setPaidFeatureRef, generateClassName } = usePaidFeature();
  const { enabled: isFreeMode } = useUpsellBanner(
    "Upgrade to access flow routes"
  );
  const [createSegmentGroupPanelKey, setCreateSegmentGroupPanelKey] = useState(
    nanoid()
  );
  const [createSegmentGroupPanelIsOpen, setCreateSegmentGroupPanelIsOpen] =
    useState(false);

  const [createPropertyPanelIsOpen, setCreatePropertyPanelIsOpen] =
    useState(false);
  const [createPropertyPanelKey, setCreatePropertyPanelKey] = useState(
    nanoid()
  );
  const [newProperty, setNewProperty] = useState<AppPropertyFragment>();
  const [defaultPropertyEntity, setDefaultPropertyEntity] = useState<string>();

  const [createSegmentPanelKey, setCreateSegmentPanelKey] = useState(nanoid());
  const [createSegmentPanelIsOpen, setCreateSegmentPanelIsOpen] =
    useState(false);

  const [createMessagePanelKey, setCreateMessagePanelKey] = useState(nanoid());
  const [createMessagePanelIsOpen, setCreateMessagePanelIsOpen] =
    useState(false);
  const [editingMessageId, setEditingMessageId] = useState<number | undefined>(
    undefined
  );

  const [newConditions, setNewConditions] = useState<
    Record<number, SegmentConditionFragment[]>
  >({});

  const insertInlineSegments = useInsertInlineSegments();

  const { data, refetch } = useQuery<FlowRoutesPanelQuery>(
    gql`
      query FlowRoutesPanelQuery {
        flow_route_rule(order_by: { position: asc }) {
          ...FlowRouteRuleFragment
        }

        segment_group {
          id
          name
          segment_group_segments {
            segment {
              id
              name
            }
          }
        }

        segment(where: { inline: { _eq: false } }, order_by: { name: asc }) {
          id
          name
          integration {
            id
            type
          }
        }

        inlineSegments: segment(where: { inline: { _eq: true } }) {
          ...RuleInlineSegmentFragment
        }

        property(where: { deleted_at: { _is_null: true } }) {
          ...RulePropertyFragment
        }

        flow(where: { deleted_at: { _is_null: true } }) {
          id
          title
        }

        flow_test(where: { deleted_at: { _is_null: true } }) {
          id
          name
        }

        eligibility_message(where: { deleted_at: { _is_null: true } }) {
          id
          name
        }
      }
      ${TheFlowRouteRuleFragment}
      ${RuleInlineSegmentFragment}
      ${RulePropertyFragment}
    `,
    { fetchPolicy: "cache-and-network" }
  );

  const [saveUnsavedChanges] = useMutation<
    FlowRoutesPanelSaveUnsavedChangesMutation,
    FlowRoutesPanelSaveUnsavedChangesMutationVariables
  >(gql`
    mutation FlowRoutesPanelSaveUnsavedChangesMutation(
      $rules: [flow_route_rule_insert_input!]!
      $accountId: Int!
      $defaultFlowId: Int!
    ) {
      delete_flow_route_rule(where: {}) {
        affected_rows
      }

      insert_flow_route_rule(objects: $rules) {
        affected_rows
      }

      update_account_by_pk(
        pk_columns: { id: $accountId }
        _set: { default_flow_id: $defaultFlowId }
      ) {
        id
        default_flow_id
      }
    }
  `);

  const nextId = useCallback(() => {
    if (stagedChanges.length < 1) {
      return 1;
    }

    return Math.max(...stagedChanges.map((r) => r.id)) + 1;
  }, [stagedChanges]);

  const setEditingRule = (rule: FlowRouteRuleFragment) => {
    setEditingId(rule.id);

    const ruleIndex = stagedChanges.findIndex((r) => r.id === rule.id);

    setRuleEditorValue({
      type: "flow_route_rule",
      segmentGroupIds: rule.segment_group_ids,
      segmentIds: rule.segment_ids,
      newConditions: newConditions[ruleIndex] || [],
      flowIds: rule.flow_ids,
      flowTestIds: rule.flow_test_ids,
      eligibilityMessageIds: rule.eligibility_message_ids,
    });
  };

  const handleAddRule = async (options?: {
    segmentGroupIds?: number[];
    segmentIds?: number[];
    flowIds?: number[];
    flowTestIds?: number[];
  }) => {
    if (editingId) {
      return;
    }

    const rule = { ...defaultRule, id: nextId() };

    setEditingRule(rule);
    setEditingNewRule(true);
  };

  const handleDeleteRule = async (ruleId: number) => {
    const ruleIndex = stagedChanges.findIndex((r) => r.id === ruleId);
    if (ruleIndex < 0) {
      throw new Error(`Rule ${ruleId} not found`);
    }

    setStagedChanges([
      ...stagedChanges.slice(0, ruleIndex),
      ...stagedChanges.slice(ruleIndex + 1),
    ]);

    const newNewConditions = { ...newConditions };

    let actualIndex = 0;
    for (const [index] of stagedChanges.entries()) {
      if (index === ruleIndex) {
        continue;
      } else {
        newNewConditions[actualIndex] = newConditions[index];
      }

      actualIndex++;
    }

    setNewConditions(newNewConditions);
  };

  const handleClickRule = (ruleId: number) => {
    if (!editingId) {
      const rule = stagedChanges.find((rule) => rule.id === ruleId);

      if (rule) {
        setEditingNewRule(false);
        setEditingRule(rule);
      }
    }
  };

  const handleCreateSegmentGroup = async (
    newSegmentGroup: AppSegmentGroupFragment
  ) => {
    if (!ruleEditorValue) {
      throw new Error();
    }

    await refetch();

    setRuleEditorValue({
      ...ruleEditorValue,
      segmentGroupIds: [...ruleEditorValue.segmentGroupIds, newSegmentGroup.id],
    });
  };

  const handleCreateSegment = async (newSegment: SegmentFragment) => {
    if (!ruleEditorValue) {
      throw new Error();
    }

    await refetch();

    setRuleEditorValue({
      ...ruleEditorValue,
      segmentIds: [...ruleEditorValue.segmentIds, newSegment.id],
    });
  };

  const handleCreateMessage = async (newMessage: any) => {
    if (!ruleEditorValue) {
      throw new Error();
    }

    await refetch();

    setRuleEditorValue({
      ...ruleEditorValue,
      flowIds: [],
      flowTestIds: [],
      eligibilityMessageIds: [newMessage.id],
    });
  };

  const handleCancel = () => {
    setEditingId(null);
  };

  const handleSave = async () => {
    if (
      !ruleEditorValue ||
      !editingId ||
      (ruleEditorValue.flowIds.length < 1 &&
        ruleEditorValue.flowTestIds.length < 1 &&
        ruleEditorValue.eligibilityMessageIds.length < 1)
    ) {
      return;
    }

    setIsSaving(true);
    let ruleIndex = -1;

    if (editingNewRule) {
      ruleIndex = stagedChanges.length;

      setStagedChanges([
        ...stagedChanges,
        {
          __typename: "flow_route_rule",
          id: editingId,
          segment_group_ids: ruleEditorValue.segmentGroupIds,
          segment_ids: ruleEditorValue.segmentIds,
          flow_ids: ruleEditorValue.flowIds,
          flow_test_ids: ruleEditorValue.flowTestIds,
          eligibility_message_ids: ruleEditorValue.eligibilityMessageIds,
          flow_route_rule_segment_groups: ruleEditorValue.segmentGroupIds.map(
            (id) => ({
              __typename: "flow_route_rule_segment_group",
              flow_route_rule_id: editingId,
              segment_group_id: id,
            })
          ),
          flow_route_rule_segments: ruleEditorValue.segmentIds.map((id) => ({
            __typename: "flow_route_rule_segment",
            flow_route_rule_id: editingId,
            segment_id: id,
          })),
          flow_route_rule_flows: ruleEditorValue.flowIds.map((id) => ({
            __typename: "flow_route_rule_flow",
            flow_route_rule_id: editingId,
            flow_id: id,
          })),
          flow_route_rule_flow_tests: ruleEditorValue.flowTestIds.map((id) => ({
            __typename: "flow_route_rule_flow_test",
            flow_route_rule_id: editingId,
            flow_test_id: id,
          })),
          flow_route_rule_eligibility_messages:
            ruleEditorValue.eligibilityMessageIds.map((id) => ({
              __typename: "flow_route_rule_eligibility_message",
              flow_route_rule_id: editingId,
              eligibility_message_id: id,
            })),
        },
      ]);
    } else if (editingRule) {
      ruleIndex = stagedChanges.findIndex((r) => r.id === editingRule.id);
      if (ruleIndex < 0) {
        throw new Error(`Rule ${editingRule.id} not found`);
      }

      setStagedChanges([
        ...stagedChanges.slice(0, ruleIndex),
        {
          __typename: "flow_route_rule",
          id: nextId(),
          segment_group_ids: ruleEditorValue.segmentGroupIds,
          segment_ids: ruleEditorValue.segmentIds,
          flow_ids: ruleEditorValue.flowIds,
          flow_test_ids: ruleEditorValue.flowTestIds,
          eligibility_message_ids: ruleEditorValue.eligibilityMessageIds,
          flow_route_rule_segment_groups: ruleEditorValue.segmentGroupIds.map(
            (id) => ({
              __typename: "flow_route_rule_segment_group",
              flow_route_rule_id: nextId(),
              segment_group_id: id,
            })
          ),
          flow_route_rule_segments: ruleEditorValue.segmentIds.map((id) => ({
            __typename: "flow_route_rule_segment",
            flow_route_rule_id: nextId(),
            segment_id: id,
          })),
          flow_route_rule_flows: ruleEditorValue.flowIds.map((id) => ({
            __typename: "flow_route_rule_flow",
            flow_route_rule_id: nextId(),
            flow_id: id,
          })),
          flow_route_rule_flow_tests: ruleEditorValue.flowTestIds.map((id) => ({
            __typename: "flow_route_rule_flow_test",
            flow_route_rule_id: nextId(),
            flow_test_id: id,
          })),
          flow_route_rule_eligibility_messages:
            ruleEditorValue.eligibilityMessageIds.map((id) => ({
              __typename: "flow_route_rule_eligibility_message",
              flow_route_rule_id: nextId(),
              eligibility_message_id: id,
            })),
        },
        ...stagedChanges.slice(ruleIndex + 1),
      ]);
    }

    setNewConditions({
      ...newConditions,
      [ruleIndex]: [...ruleEditorValue.newConditions],
    });

    setEditingId(null);

    await refetch();

    setIsSaving(false);
  };

  const handleSaveChanges = async (confirmed: boolean) => {
    if (!account || !defaultFlowId) {
      return;
    }

    if (!confirmed) {
      setConfirmSaveModalIsOpen(true);
      return;
    }

    setIsSaving(true);

    const changesToSave = Array.from(cloneDeep(stagedChanges));

    for (const [index, rule] of changesToSave.entries()) {
      const conditions = newConditions[index] || [];
      if (conditions.length) {
        const inlineSegments = await insertInlineSegments(conditions);
        changesToSave[index].segment_ids = [
          ...rule.segment_ids,
          ...inlineSegments.map((s) => s.id),
        ];
      }
    }

    await saveUnsavedChanges({
      variables: {
        accountId: account.id,
        defaultFlowId,
        rules: changesToSave.map((r, i) => ({
          position: i,
          segment_group_ids: r.segment_group_ids,
          segment_ids: r.segment_ids,
          flow_ids: r.flow_ids,
          flow_test_ids: r.flow_test_ids,
          eligibility_message_ids: r.eligibility_message_ids,
        })),
      },
    });

    await refetch();

    setIsSaving(false);
    setNewConditions({});

    addToast(<div>Flow route rules saved successfully.</div>, {
      appearance: "success",
    });
  };

  const handleDragEnd = (result: DropResult, provided: ResponderProvided) => {
    if (!result.destination) {
      return;
    }

    const reordered = Array.from(cloneDeep(stagedChanges));
    const [removed] = reordered.splice(result.source.index, 1);
    reordered.splice(result.destination.index, 0, removed);
    setStagedChanges(reordered);

    setNewConditions({
      ...newConditions,
      [result.source.index]: newConditions[result.destination.index] || [],
      [result.destination.index]: newConditions[result.source.index] || [],
    });
  };

  const account = viewer?.account;
  const platform = account?.platform_connection?.platform;
  const segments = data?.segment || [];
  const inlineSegments = data?.inlineSegments || [];
  const segmentGroups = data?.segment_group || [];
  const properties = data?.property || [];
  const flows = data?.flow || [];
  const flowTests = data?.flow_test || [];
  const eligibilityMessages = data?.eligibility_message || [];

  const actualRules = data?.flow_route_rule || [];

  const [ruleEditorValue, setRuleEditorValue] =
    useState<RuleEditorFlowRouteRuleValue>();

  const rules: FlowRouteRuleFragment[] =
    ruleEditorValue && editingNewRule && editingId !== null
      ? [
          ...stagedChanges,
          {
            __typename: "flow_route_rule",
            id: editingId,
            segment_group_ids: ruleEditorValue.segmentGroupIds,
            segment_ids: ruleEditorValue.segmentIds,
            flow_ids: ruleEditorValue.flowIds,
            flow_test_ids: ruleEditorValue.flowTestIds,
            eligibility_message_ids: ruleEditorValue.eligibilityMessageIds,
            flow_route_rule_segment_groups: ruleEditorValue.segmentGroupIds.map(
              (id) => ({
                __typename: "flow_route_rule_segment_group",
                flow_route_rule_id: editingId,
                segment_group_id: id,
              })
            ),
            flow_route_rule_segments: ruleEditorValue.segmentIds.map((id) => ({
              __typename: "flow_route_rule_segment",
              flow_route_rule_id: editingId,
              segment_id: id,
            })),
            flow_route_rule_flows: ruleEditorValue.flowIds.map((id) => ({
              __typename: "flow_route_rule_flow",
              flow_route_rule_id: editingId,
              flow_id: id,
            })),
            flow_route_rule_flow_tests: ruleEditorValue.flowTestIds.map(
              (id) => ({
                __typename: "flow_route_rule_flow_test",
                flow_route_rule_id: editingId,
                flow_test_id: id,
              })
            ),
            flow_route_rule_eligibility_messages:
              ruleEditorValue.eligibilityMessageIds.map((id) => ({
                __typename: "flow_route_rule_eligibility_message",
                flow_route_rule_id: editingId,
                eligibility_message_id: id,
              })),
          },
        ]
      : stagedChanges;

  const editingRule = editingId
    ? stagedChanges.find((rule) => rule.id === editingId)
    : null;

  useEffect(() => {
    setStagedChanges(data?.flow_route_rule || []);
    setDefaultFlowId(account?.default_flow_id || undefined);
  }, [account?.default_flow_id, data?.flow_route_rule]);

  const hasUnsavedChanges = (() => {
    if (defaultFlowId !== account?.default_flow_id) {
      return true;
    }

    if (stagedChanges.length !== actualRules.length) {
      return true;
    }

    for (const [i, rule] of stagedChanges.entries()) {
      if (!actualRules[i]) {
        return true;
      }

      const match = actualRules[i];
      if (
        !isEqual(rule.segment_group_ids, match.segment_group_ids) ||
        !isEqual(rule.segment_ids, match.segment_ids) ||
        !isEqual(rule.flow_ids, match.flow_ids) ||
        !isEqual(rule.flow_test_ids, match.flow_test_ids)
      ) {
        return true;
      }

      if (newConditions[i]?.length) {
        return true;
      }
    }

    return false;
  })();

  const [deleteMessage] = useMutation<
    FlowRoutesPanelDeleteEligibilityMessageMutation,
    FlowRoutesPanelDeleteEligibilityMessageMutationVariables
  >(gql`
    mutation FlowRoutesPanelDeleteEligibilityMessageMutation($id: Int!) {
      update_eligibility_message_by_pk(
        pk_columns: { id: $id }
        _set: { deleted_at: "now" }
      ) {
        id
      }
    }
  `);

  const handleDeleteMessage = async (id: number) => {
    if (!ruleEditorValue) {
      throw new Error();
    }

    await deleteMessage({ variables: { id } });
    await refetch();

    setRuleEditorValue({
      ...ruleEditorValue,
      eligibilityMessageIds: ruleEditorValue.eligibilityMessageIds.filter(
        (m) => m !== id
      ),
    });
  };

  return (
    <>
      <Panel
        width={650}
        {...props}
        header={
          <>
            <PanelTitle>Flow routes</PanelTitle>
            <PanelButtons>
              <Button
                buttonType="primary"
                form="flow-routes"
                isLoading={isSaving}
                disabled={!hasUnsavedChanges}
                onClick={() => handleSaveChanges(false)}
              >
                Save
              </Button>
              <Button type="button" buttonType="default" onClick={onClose}>
                Cancel
              </Button>
            </PanelButtons>
          </>
        }
      >
        <PanelBody tw="h-full" ref={(ref) => setPaidFeatureRef(ref)}>
          <DragDropContext onDragEnd={handleDragEnd}>
            <HelpBlock
              color="gray"
              content={
                <>
                  Route subscribers to a cancellation flow based on segments.
                  Rules are evaluated top down; the first matching rule will
                  determine the selected cancellation flow.{" "}
                  <StandardEternalLink
                    href="https://prosperstack.com/docs/multiple-flows/#routing-customers-to-a-flow"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    See the documentation.
                  </StandardEternalLink>
                </>
              }
              icon={
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  color={theme`colors.gray.400`}
                />
              }
              size="sm"
              tw="my-4"
            />
            <div tw="mb-4">
              <FieldRow
                tw="border-divider px-2"
                className={generateClassName(
                  "Upgrade to create multiple flows.",
                  "panel"
                )}
              >
                <FieldLabel>
                  <label>Default flow</label>
                </FieldLabel>
                <FieldInput>
                  <SelectInput
                    value={defaultFlowId}
                    onChange={(e) => {
                      setDefaultFlowId(Number(e.currentTarget.value));
                    }}
                  >
                    {flows.map((f) => (
                      <option key={f.id} value={f.id}>
                        {f.title}
                      </option>
                    ))}
                  </SelectInput>
                  <FieldHint>
                    Default flow to use when no rule matches.
                  </FieldHint>
                </FieldInput>
              </FieldRow>
            </div>
            <Card border tw="rounded p-0">
              <RuleHeader
                tw="rounded-t"
                className={editingId ? "rule-is-editing" : undefined}
              >
                <RuleHeaderDragColumn></RuleHeaderDragColumn>
                <RuleHeaderColumn>Condition</RuleHeaderColumn>
                <RuleHeaderColumn>Flow</RuleHeaderColumn>
                <RuleHeaderDeleteColumn></RuleHeaderDeleteColumn>
              </RuleHeader>
              {rules.length === 0 && (
                <RulesZeroState
                  className={generateClassName(
                    "Upgrade to create flow routing rules based on customer segments.",
                    "panel"
                  )}
                >
                  Route to a cancellation flow based on segments. Rules define
                  which customers will be routed to which cancellation flows.{" "}
                  <StandardLinkButton
                    onClick={() => (!isFreeMode ? handleAddRule() : undefined)}
                  >
                    Create a rule.
                  </StandardLinkButton>
                </RulesZeroState>
              )}

              <Droppable droppableId="flowRoutes">
                {(provided, droppableSnapshot) => (
                  <Rules
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className={classNames({ "rule-is-editing": !!editingId })}
                  >
                    {rules.map((rule, index) => (
                      <Draggable
                        key={rule.id}
                        draggableId={rule.id.toString()}
                        index={index}
                        isDragDisabled={!!editingId || rules.length === 1}
                      >
                        {(provided, snapshot) => {
                          const isEditing = rule.id === editingId;
                          return (
                            <RuleRow
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              className={classNames({
                                "rule-is-dragging": snapshot.isDragging,
                                "rule-is-editing": isEditing,
                                "rule-editing-is-disabled":
                                  editingId !== null && !isEditing,
                              })}
                            >
                              {isEditing ? (
                                <div tw="w-full h-full flex flex-col">
                                  <div tw="w-full h-full overflow-hidden">
                                    {ruleEditorValue && (
                                      <RuleEditor
                                        segmentGroupsEnabled={
                                          !!features.segment_groups
                                        }
                                        offerRuleGroupsEnabled={
                                          !!features.offer_rule_groups
                                        }
                                        platform={platform}
                                        segmentGroups={segmentGroups}
                                        segments={segments}
                                        inlineSegments={inlineSegments}
                                        properties={properties}
                                        flows={flows}
                                        flowTests={flowTests}
                                        eligibilityMessages={
                                          eligibilityMessages
                                        }
                                        hideHeading
                                        value={ruleEditorValue}
                                        onChange={(v) => {
                                          if (v.type !== "flow_route_rule") {
                                            throw new Error();
                                          }
                                          setRuleEditorValue(v);
                                        }}
                                        onClickCreateSegmentGroup={() =>
                                          setCreateSegmentGroupPanelIsOpen(true)
                                        }
                                        onClickCreateSegment={() =>
                                          setCreateSegmentPanelIsOpen(true)
                                        }
                                        onClickCreateMessage={(id) => {
                                          setEditingMessageId(id);
                                          setCreateMessagePanelIsOpen(true);
                                        }}
                                        onClickDeleteMessage={
                                          handleDeleteMessage
                                        }
                                      />
                                    )}
                                  </div>
                                  <div tw="flex items-center px-4 py-2 border-t border-divider">
                                    <div tw="ml-auto">
                                      <Button
                                        type="button"
                                        onClick={handleCancel}
                                        disabled={isSaving}
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        type="button"
                                        buttonType="primary"
                                        onClick={handleSave}
                                        disabled={
                                          isSaving ||
                                          (!ruleEditorValue?.flowIds.length &&
                                            !ruleEditorValue?.flowTestIds
                                              .length &&
                                            !ruleEditorValue
                                              ?.eligibilityMessageIds.length)
                                        }
                                        isLoading={isSaving}
                                      >
                                        Save
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <RuleBody>
                                  <RuleDrag>
                                    <DragHandle
                                      {...provided.dragHandleProps}
                                      className={classNames({
                                        "is-disabled":
                                          !!editingId || rules.length === 1,
                                      })}
                                    >
                                      <FontAwesomeIcon icon={faGripVertical} />
                                    </DragHandle>
                                  </RuleDrag>
                                  <RuleColumns
                                    className="rule-columns"
                                    onClick={() => handleClickRule(rule.id)}
                                  >
                                    <RuleColumn>
                                      <RuleSegments
                                        segmentGroups={rule.flow_route_rule_segment_groups
                                          .map((g) =>
                                            segmentGroups.find(
                                              (test) =>
                                                test.id === g.segment_group_id
                                            )
                                          )
                                          .filter(isPresent)}
                                        segments={rule.flow_route_rule_segments
                                          .map((s) =>
                                            segments.find(
                                              (test) => test.id === s.segment_id
                                            )
                                          )
                                          .filter(isPresent)}
                                        inlineSegments={rule.flow_route_rule_segments
                                          .map((s) =>
                                            inlineSegments.find(
                                              (test) => test.id === s.segment_id
                                            )
                                          )
                                          .filter(isPresent)}
                                        newConditions={
                                          newConditions[index] || []
                                        }
                                      />
                                    </RuleColumn>
                                    <RuleColumn>
                                      <BoxContainer>
                                        {flows.map((flow) => {
                                          const isOn =
                                            !!rule.flow_route_rule_flows.find(
                                              (f) => f.flow_id === flow.id
                                            );

                                          if (!isOn) {
                                            return null;
                                          }

                                          return (
                                            <FlowBox
                                              key={flow.id}
                                              isEditable={false}
                                              isOn={true}
                                            >
                                              {flow.title}
                                            </FlowBox>
                                          );
                                        })}
                                        {flowTests.map((flowTest) => {
                                          const isOn =
                                            !!rule.flow_route_rule_flow_tests.find(
                                              (f) =>
                                                f.flow_test_id === flowTest.id
                                            );

                                          if (!isOn) {
                                            return null;
                                          }

                                          return (
                                            <Tippy
                                              key={flowTest.id}
                                              content="A/B test"
                                            >
                                              <FlowTestBox
                                                isEditable={false}
                                                isOn={true}
                                              >
                                                <FontAwesomeIcon
                                                  icon={faVial}
                                                  transform="shrink-3"
                                                />{" "}
                                                {flowTest.name}
                                              </FlowTestBox>
                                            </Tippy>
                                          );
                                        })}
                                        {eligibilityMessages.map((message) => {
                                          const isOn =
                                            !!rule.flow_route_rule_eligibility_messages.find(
                                              (m) =>
                                                m.eligibility_message_id ===
                                                message.id
                                            );

                                          if (!isOn) {
                                            return null;
                                          }

                                          return (
                                            <EligibilityMessageBox
                                              key={message.id}
                                              isEditable={false}
                                              isOn={true}
                                            >
                                              {message.name}
                                            </EligibilityMessageBox>
                                          );
                                        })}
                                      </BoxContainer>
                                    </RuleColumn>
                                  </RuleColumns>
                                  <RuleDelete>
                                    {!editingId && (
                                      <button
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          handleDeleteRule(rule.id);
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faTimesCircle} />
                                      </button>
                                    )}
                                  </RuleDelete>
                                </RuleBody>
                              )}
                            </RuleRow>
                          );
                        }}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Rules>
                )}
              </Droppable>
            </Card>
          </DragDropContext>
          <div
            tw="flex"
            className={classNames({
              "rule-is-editing": !!editingId,
            })}
          >
            <div
              tw="shrink"
              className={generateClassName(
                "Upgrade to create flow routing rules based on customer segments.",
                "panel"
              )}
            >
              <Button
                buttonType="alternate-secondary"
                size="sm"
                disabled={!!editingId}
                onClick={() => (!isFreeMode ? handleAddRule() : undefined)}
              >
                <FontAwesomeIcon icon={faPlus} transform="left-1" /> Add rule
              </Button>
            </div>
          </div>
        </PanelBody>
      </Panel>
      <PropertyPanel
        key={createPropertyPanelKey}
        mode="create"
        isOpen={createPropertyPanelIsOpen}
        onClose={async (property) => {
          setCreatePropertyPanelIsOpen(false);
          if (property) {
            setNewProperty(property);
          }

          setTimeout(() => {
            setCreatePropertyPanelKey(nanoid());
          }, 500);
        }}
        defaultEntity={defaultPropertyEntity}
      />
      {platform && (
        <CreateSegmentPanel
          key={createSegmentPanelKey}
          mode="create"
          isOpen={createSegmentPanelIsOpen && !createPropertyPanelIsOpen}
          platform={platform}
          onClickCreateProperty={(entity) => {
            setDefaultPropertyEntity(entity);
            setCreatePropertyPanelIsOpen(true);
          }}
          newProperty={newProperty}
          onClose={(newSegment) => {
            setCreateSegmentPanelIsOpen(false);
            window.setTimeout(() => {
              setCreateSegmentPanelKey(nanoid());
            }, 733);

            if (newSegment) {
              handleCreateSegment(newSegment);
            }
          }}
        />
      )}
      <SegmentGroupPanel
        key={createSegmentGroupPanelKey}
        mode="create"
        isOpen={createSegmentGroupPanelIsOpen}
        onClose={(newSegmentGroup) => {
          setCreateSegmentGroupPanelIsOpen(false);
          window.setTimeout(() => {
            setCreateSegmentGroupPanelKey(nanoid());
          }, 733);

          if (newSegmentGroup) {
            handleCreateSegmentGroup(newSegmentGroup);
          }
        }}
      />
      <EligibilityMessagePanel
        key={createMessagePanelKey}
        mode={!!editingMessageId ? "edit" : "create"}
        eligibilityMessageId={editingMessageId}
        isOpen={createMessagePanelIsOpen}
        onClose={(newMessage) => {
          setCreateMessagePanelIsOpen(false);
          window.setTimeout(() => {
            setCreateMessagePanelKey(nanoid());
          }, 733);

          if (newMessage) {
            handleCreateMessage(newMessage);
          }
        }}
      />
      <ConfirmationModal
        isOpen={confirmSaveModalIsOpen}
        title="Save flow route changes"
        content="Are you sure you want to save your flow route changes? Your changes will take effect immediately."
        confirmText="Save changes"
        confirmButtonType="primary"
        onClose={async (confirmed) => {
          setConfirmSaveModalIsOpen(false);

          if (confirmed) {
            handleSaveChanges(true);
          }
        }}
      />
    </>
  );
};

export default FlowRoutesPanel;
