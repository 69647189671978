import { faPlus, faWeightHanging } from "@fortawesome/pro-regular-svg-icons";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import Fuse from "fuse.js";
import { useMemo, useState } from "react";
import { isPresent } from "ts-is-present";
import tw from "twin.macro";

import { RuleDeflectionFragment } from "../../__generated__/graphql";
import Button from "../form/Button";
import TextInput from "../form/input/TextInput";
import SearchInput from "../search/SearchInput";
import { BoxContainer, DeflectionBox, NoDeflectionBox } from "./RuleBoxes";

export interface RuleDeflectionPickerValue {
  groups: Array<{
    weight: number;
    deflectionIds: number[];
    includePresentNoOffer: boolean;
  }>;
}

interface RuleDeflectionPickerProps {
  ruleGroupsEnabled: boolean;
  value: RuleDeflectionPickerValue;
  deflections: RuleDeflectionFragment[];
  flowId: number;
  onChange: (value: RuleDeflectionPickerValue) => void;
  onClickCreateDeflection: (groupIndex: number) => void;
  onClickEditDeflection: (deflectionId: number) => void;
}

const RuleDeflectionPicker: React.FunctionComponent<
  RuleDeflectionPickerProps
> = ({
  ruleGroupsEnabled,
  value,
  deflections,
  flowId,
  onChange,
  onClickCreateDeflection,
  onClickEditDeflection = () => undefined,
}) => {
  const [searchValues, setSearchValues] = useState<Record<number, string>>({});
  const [searchResults, setSearchResults] = useState<
    Record<number, Array<RuleDeflectionFragment>>
  >({});

  const fuse = useMemo(
    () =>
      new Fuse([...deflections], {
        keys: ["name"],
        threshold: 0.4,
      }),
    [deflections]
  );

  const handleChangeWeight = (groupIndex: number, weight: number) => {
    const group = value.groups[groupIndex];
    if (weight < 1) {
      weight = 1;
    }
    if (weight > 100) {
      weight = 100;
    }

    const newGroup = {
      ...group,
      weight,
    };

    const newGroups = [...value.groups];
    newGroups[groupIndex] = newGroup;
    onChange({
      ...value,
      groups: newGroups,
    });
  };

  const handleToggleDeflection = (groupIndex: number, deflectionId: number) => {
    const group = value.groups[groupIndex];

    const newGroup = {
      ...group,
      deflectionIds: group.deflectionIds.includes(deflectionId)
        ? group.deflectionIds.filter((id) => id !== deflectionId)
        : [...group.deflectionIds, deflectionId],
    };

    if (group.includePresentNoOffer && newGroup.deflectionIds.length) {
      newGroup.includePresentNoOffer = false;
    }

    const newGroups = [...value.groups];
    newGroups[groupIndex] = newGroup;
    onChange({
      ...value,
      groups: newGroups,
    });
  };

  const handleTogglePresentNoDeflection = (groupIndex: number) => {
    const group = value.groups[groupIndex];
    const newGroup = { ...group };

    if (!group.includePresentNoOffer && group.deflectionIds.length) {
      newGroup.includePresentNoOffer = true;
    }

    if (group.includePresentNoOffer && group.deflectionIds.length) {
      newGroup.includePresentNoOffer = false;
      newGroup.deflectionIds = [];
    }

    const newGroups = [...value.groups];
    newGroups[groupIndex] = newGroup;
    onChange({
      ...value,
      groups: newGroups,
    });
  };

  const handleAddGroup = () => {
    const newGroup = {
      weight: 100,
      includePresentNoOffer: false,
      deflectionIds: [],
    };

    onChange({
      ...value,
      groups: [...value.groups, newGroup],
    });
  };

  const handleRemoveGroup = (groupIndex: number) => {
    onChange({
      ...value,
      groups: [
        ...value.groups.slice(0, groupIndex),
        ...value.groups.slice(groupIndex + 1),
      ],
    });
  };

  const handleSearchChange = (groupIndex: number, newSearchValue: string) => {
    const result = fuse.search(newSearchValue);

    setSearchValues({
      ...searchValues,
      [groupIndex]: newSearchValue,
    });
    setSearchResults({
      ...searchResults,
      [groupIndex]: result.map((r) => r.item),
    });
  };

  return (
    <div>
      {value.groups.map((group, i) => {
        const filteredDeflections = deflections.filter(
          (d) =>
            !searchValues[i] ||
            searchResults[i].find(
              (r) => r.__typename === "deflection" && r.id === d.id
            )
        );

        return (
          <div
            key={i}
            tw="relative pb-1 mb-3"
            css={
              value.groups.length > 1
                ? tw`border border-divider rounded-lg p-3`
                : undefined
            }
          >
            {value.groups.length > 1 && (
              <button
                tw="w-[26px] h-[26px] text-gray-600 hover:text-gray-800 hover:bg-gray-50 cursor-pointer rounded-full border border-divider absolute top-[-8px] left-[-8px] bg-white flex items-center justify-center transition-all duration-200"
                onClick={() => handleRemoveGroup(i)}
              >
                <FontAwesomeIcon icon={faTimes} transform="shrink-2" />
              </button>
            )}

            {value.groups.length > 1 && (
              <div tw="mb-3 border-b border-divider pb-2">
                <FontAwesomeIcon icon={faWeightHanging} /> Weight:{" "}
                <TextInput
                  type="number"
                  min={1}
                  max={100}
                  value={group.weight}
                  onChange={(e) =>
                    handleChangeWeight(i, Number(e.currentTarget.value))
                  }
                  tw="py-0 ml-2 pr-0 w-[60px]"
                />
              </div>
            )}

            {deflections.length >= 20 && (
              <div tw="mb-4">
                <SearchInput
                  tw="w-full"
                  value={searchValues[i]}
                  onChange={(e) => handleSearchChange(i, e.currentTarget.value)}
                  onClear={() => handleSearchChange(i, "")}
                />
              </div>
            )}

            <BoxContainer tw="mb-2">
              <NoDeflectionBox
                isOn={group.includePresentNoOffer}
                isReallyOn={!group.deflectionIds.length}
                onClick={() => handleTogglePresentNoDeflection(i)}
              >
                Present no deflection
              </NoDeflectionBox>
            </BoxContainer>

            <BoxContainer>
              {filteredDeflections.map((deflection) => {
                const reroutesToThisFlow = deflection.deflection_flow_actions
                  ?.map((a) => a?.flow_action?.reroute_to_flow_id)
                  .filter(isPresent)
                  .includes(flowId);

                return (
                  <Tippy
                    content="Cannot use this deflection because it reroutes to this flow."
                    disabled={!reroutesToThisFlow}
                    key={deflection.id}
                  >
                    <div>
                      <DeflectionBox
                        disabled={reroutesToThisFlow}
                        isOn={group.deflectionIds.includes(deflection.id)}
                        onClick={() => handleToggleDeflection(i, deflection.id)}
                        isEditable={true}
                        onClickEdit={() => onClickEditDeflection(deflection.id)}
                      >
                        {deflection.title}
                      </DeflectionBox>
                    </div>
                  </Tippy>
                );
              })}
            </BoxContainer>

            {!!searchValues[i] && !searchResults[i].length && (
              <span tw="text-type-light">No results.</span>
            )}

            {!!searchValues[i] && !searchResults[i].length && (
              <span tw="text-type-light">No results.</span>
            )}

            <div tw="border-t border-divider mt-3 pt-2 flex gap-2">
              <span>
                <Button
                  type="button"
                  buttonType="alternate-secondary"
                  size="sm"
                  onClick={() => onClickCreateDeflection(i)}
                >
                  <FontAwesomeIcon icon={faPlus} /> Create deflection
                </Button>
              </span>
            </div>
          </div>
        );
      })}
      {ruleGroupsEnabled && (
        <div tw="-ml-4 -mr-4 px-4 pt-5 border-t border-divider">
          <Button
            type="button"
            buttonType="alternate-secondary"
            onClick={handleAddGroup}
            size="sm"
          >
            <FontAwesomeIcon icon={faPlus} /> Add rule group
          </Button>
        </div>
      )}
    </div>
  );
};

export default RuleDeflectionPicker;
