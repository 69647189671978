import {
  faCcAmex,
  faCcDinersClub,
  faCcDiscover,
  faCcJcb,
  faCcMastercard,
  faCcVisa,
} from "@fortawesome/free-brands-svg-icons";
import { faCreditCardFront } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface CardBrandProps {
  brand: string;
}

const mapBrand = (brand: string) => {
  switch (brand) {
    case "american_express":
      return "American Express";

    case "diners_club":
      return "Diners Club";

    case "discover":
      return "Discover";

    case "eftpos":
      return "EFTPOS";

    case "jcb":
      return "JCB";

    case "mastercard":
      return "MasterCard";

    case "unionpay":
      return "UnionPay";

    case "visa":
      return "Visa";

    default:
      return "";
  }
};

const CardBrand: React.FunctionComponent<CardBrandProps> = ({ brand }) => {
  const icon = (() => {
    switch (brand) {
      case "american_express":
        return <FontAwesomeIcon icon={faCcAmex} />;

      case "diners_club":
        return <FontAwesomeIcon icon={faCcDinersClub} />;

      case "discover":
        return <FontAwesomeIcon icon={faCcDiscover} />;

      case "jcb":
        return <FontAwesomeIcon icon={faCcJcb} />;

      case "mastercard":
        return <FontAwesomeIcon icon={faCcMastercard} />;

      case "visa":
        return <FontAwesomeIcon icon={faCcVisa} />;

      default:
        return <FontAwesomeIcon icon={faCreditCardFront} />;
    }
  })();

  return (
    <>
      <span tw="mr-1">{icon}</span> {mapBrand(brand)}
    </>
  );
};

export default CardBrand;
