import gql from "graphql-tag";

export default gql`
  fragment FlowQuestionSettingVersionFragment on question_setting_version {
    id
    question_id
    key
    value
  }
`;
