import gql from "graphql-tag";

import AppDeflectionFragment from "../fragments/AppDeflectionFragment";

export default gql`
  query ExistingDeflectionsQuery {
    deflection(where: { deleted_at: { _is_null: true } }) {
      ...AppDeflectionFragment
    }
  }
  ${AppDeflectionFragment}
`;
