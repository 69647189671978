import gql from "graphql-tag";

import FlowSegmentFragment from "./FlowSegmentFragment";
import FlowSegmentGroupFragment from "./FlowSegmentGroupFragment";

export default gql`
  fragment FlowRouteRuleFragment on flow_route_rule {
    id
    segment_group_ids
    segment_ids
    flow_ids
    flow_test_ids
    eligibility_message_ids
    flow_route_rule_segment_groups {
      segment_group_id
      segment_group {
        ...FlowSegmentGroupFragment
      }
    }
    flow_route_rule_segments {
      segment_id
      segment {
        ...FlowSegmentFragment
      }
    }
    flow_route_rule_flows {
      flow_id
    }
    flow_route_rule_flow_tests {
      flow_test_id
      flow_test {
        id
        name
        control_weight
        winning_flow_id
        control_flow {
          id
        }
        flow_test_flows {
          flow_test_id
          flow_id
          weight
        }
      }
    }
    flow_route_rule_eligibility_messages {
      eligibility_message_id
      eligibility_message {
        id
        name
      }
    }
  }
  ${FlowSegmentGroupFragment}
  ${FlowSegmentFragment}
`;
