import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  RuleInlineSegmentFragment,
  RuleSegmentFragment,
  RuleSegmentGroupFragment,
  SegmentConditionFragment,
} from "../../__generated__/graphql";
import SegmentConditionDescription from "../../features/segments/SegmentConditionDescription";
import SegmentRuleBox from "../../features/segments/SegmentRuleBox";
import { psSegmentGroup } from "../icons/customIcons";
import { AnyBox, BoxContainer, SegmentGroupBox } from "./RuleBoxes";

interface RuleSegmentsProps {
  segmentGroups: RuleSegmentGroupFragment[];
  segments: RuleSegmentFragment[];
  inlineSegments: RuleInlineSegmentFragment[];
  newConditions: SegmentConditionFragment[];
}

const RuleSegments: React.FunctionComponent<RuleSegmentsProps> = ({
  segmentGroups,
  segments,
  inlineSegments,
  newConditions,
}) => (
  <BoxContainer>
    {!segmentGroups.length &&
      !segments.length &&
      !inlineSegments.length &&
      !newConditions.length && (
        <AnyBox isOn readOnly>
          Any
        </AnyBox>
      )}
    {segments.map((s) => (
      <SegmentRuleBox key={s.id} isEditable={false} isOn={true} segment={s} />
    ))}
    {segmentGroups.map((a) => (
      <SegmentGroupBox key={a.id} isOn readOnly>
        <FontAwesomeIcon
          icon={psSegmentGroup}
          color="white"
          transform="shrink-4 left-3 down-1"
        />
        {a.name}
      </SegmentGroupBox>
    ))}
    {inlineSegments.map((s) => {
      const condition =
        s.primary_segment_condition_group?.segment_condition_group_entries[0]
          .entry_segment_condition;
      if (!condition) {
        return null;
      }

      return (
        <SegmentConditionDescription
          key={s.id}
          condition={condition}
          active
          tw="m-1 inline-block"
        />
      );
    })}
    {newConditions.map((c, i) => (
      <SegmentConditionDescription
        key={i}
        condition={c}
        active
        tw="m-1 inline-block"
      />
    ))}
  </BoxContainer>
);

export default RuleSegments;
