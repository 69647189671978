import { css, styled, theme } from "twin.macro";

interface SpinnerProps {
  className?: string;
  height?: string;
  padding?: string;
  color?: string;
  opacity?: string;
  context?: "page" | "component";
  size?: "sm" | "md";
}

const StyledSpinner = styled.div<SpinnerProps>`
  width: 100%;
  height: ${(props) => (props.height ? props.height : "100%")};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(props) => (props.padding ? props.padding : "2rem 0")};

  ${(props) =>
    props.size === "sm" &&
    css`
      transform: scale(0.65);
    `}

  .bounce1,
  .bounce2,
  .bounce3 {
    width: 10px;
    height: 10px;
    background-color: ${(props) =>
      props.color ? props.color : theme`colors.gray.900`};
    margin-right: 6px;
    opacity: ${(props) => (props.opacity ? props.opacity : "50%")};

    border-radius: 4px;
    display: inline-block;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  .bounce1 {
    margin-left: 6px;
    animation-delay: -0.32s;
  }

  .bounce2 {
    animation-delay: -0.16s;
  }

  .bounde3 {
    margin-right: 0;
  }

  @keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
`;

const Spinner: React.FunctionComponent<SpinnerProps> = ({
  context = "component",
  height,
  ...props
}) => {
  height = !height
    ? context === "page"
      ? "20rem"
      : context === "component"
      ? "100%"
      : "undefined"
    : height;

  return (
    <StyledSpinner {...props} height={height}>
      <div tw="whitespace-nowrap">
        <div className="bounce1" />
        <div className="bounce2" />
        <div className="bounce3" />
      </div>
    </StyledSpinner>
  );
};

export default Spinner;
