import gql from "graphql-tag";

import FlowOfferRuleFragment from "./FlowOfferRuleFragment";

export default gql`
  fragment FlowOfferRuleGroupVersionFragment on offer_rule_group_version {
    id
    offer_rule_group_id
    offer_rule_ids
    offer_rule_group_version_offer_rules {
      offer_rule {
        ...FlowOfferRuleFragment
      }
    }
  }
  ${FlowOfferRuleFragment}
`;
