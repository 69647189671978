import {
  CustomerPortalSubscriptionFragment,
  platform_enum,
} from "../../../__generated__/graphql";
import {
  PlatformFeature,
  platformHasFeature,
} from "../../../common/platform/features";
import BillingDetails from "./BillingDetails";
import Card from "./Card";
import CardHeading from "./CardHeading";
import SubscriptionRow from "./SubscriptionRow";

interface SubscriptionsProps {
  clientId: string;
  sessionToken: string;
  platformCustomerId: string;
  subscriptions: CustomerPortalSubscriptionFragment[];
  platform: platform_enum;
}

const Subscriptions: React.FunctionComponent<SubscriptionsProps> = ({
  clientId,
  sessionToken,
  platformCustomerId,
  subscriptions,
  platform,
}) => (
  <Card tw="w-full md:w-[39rem]">
    <CardHeading>Active plans</CardHeading>
    <div>
      {subscriptions.map((subscription) => (
        <SubscriptionRow
          key={subscription.id}
          clientId={clientId}
          sessionToken={sessionToken}
          platformSubscriptionId={subscription.platform_id}
        />
      ))}
      {!subscriptions.length && (
        <div tw="py-3 px-4 text-type-light">No active plans.</div>
      )}
    </div>
    {platformHasFeature(
      platform,
      PlatformFeature.CustomerPortalBillingInformation
    ) && (
      <BillingDetails
        sessionToken={sessionToken}
        platformCustomerId={platformCustomerId}
      />
    )}
  </Card>
);

export default Subscriptions;
