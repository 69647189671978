import { SlatePlugin } from "@udecode/slate-plugins";

import OfferTagElement from "./OfferTagElement";
import { OFFER_TAG } from "./types";

export default function OfferTagPlugin(): SlatePlugin {
  return {
    renderElement: (props) => {
      if (props.element.type === OFFER_TAG) {
        return <OfferTagElement {...props} />;
      }
    },
  };
}
