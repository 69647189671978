import { isPresent } from "ts-is-present";

import {
  FlowFormFragment,
  FlowOfferRulesQuery,
} from "../../../../__generated__/graphql";
import formatQuestionConditions from "../../../../common/flow/formatQuestionConditions";
import { FormattedStepCondition } from "../../../../common/flow/formatStepConditions";
import getFlowObjectVersion from "../../../../common/flow/getFlowObjectVersion";
import showConditionalQuestion from "../../../../common/flow/showConditionalQuestion";
import { FlowVersion } from "../../../../common/flow/types";
import getOfferRuleConditionedQuestionIds from "./getOfferRuleConditionedQuestionIds";
import questionIsSatisfied from "./questionIsSatisfied";
import sanitizeAndFormatQuestionAnswers from "./sanitizeAndFormatQuestionAnswers";
import { FormStepQuestion, QuestionAnswer } from "./types";

interface GetFormQuestionsOptions {
  isLoading?: boolean;
  isEditing: boolean;
  form: FlowFormFragment;
  segmentMatches: number[];
  segmentGroupMatches: number[];
  questionAnswers: QuestionAnswer[];
  flowVersion: FlowVersion;
  stepConditions: FormattedStepCondition[];
  offerRules: FlowOfferRulesQuery | undefined | null;
}

export default function getFormQuestions({
  isLoading,
  isEditing,
  form,
  segmentMatches,
  segmentGroupMatches,
  questionAnswers,
  flowVersion,
  stepConditions,
  offerRules,
}: GetFormQuestionsOptions): FormStepQuestion[] {
  if (isLoading) {
    return [];
  }
  const formVersion = getFlowObjectVersion(form, flowVersion);

  const questions = formVersion.form_version_questions
    .map((formVersionQuestion) => formVersionQuestion.question)
    .filter(isPresent);

  const questionConditions = formatQuestionConditions(
    questions,
    segmentMatches,
    segmentGroupMatches,
    flowVersion
  );

  const isEnabled = (index: number) => {
    const question = questions[index];
    if (question.type !== "radio") {
      return true;
    }

    const options = getFlowObjectVersion(
      question,
      flowVersion
    ).question_version_question_options;

    return !!options.length;
  };

  const isHidden = (index: number) => {
    const question = questions[index];

    const condition = questionConditions.find(
      (condition) => condition.questionId === question.id
    );

    if (isEditing || !condition) {
      return false;
    }

    const previousRadioQuestions = questions
      .slice(0, index)
      .filter((q) => q.type === "radio");

    return !showConditionalQuestion(
      condition,
      previousRadioQuestions,
      sanitizeAndFormatQuestionAnswers(questions, questionAnswers, flowVersion)
    );
  };

  const {
    questionIds: conditionedQuestionIds,
    questionOptionIds: conditionedQuestionOptionIds,
    otherSpecifyQuestionIds: conditionedOtherSpecifyQuestionIds,
  } = getOfferRuleConditionedQuestionIds(offerRules);

  return questions.map((question, index) => {
    const enabled = isEnabled(index);
    const hidden = isHidden(index);

    const questionVersion = getFlowObjectVersion(question, flowVersion);

    return {
      formId: form.id,
      question: question,
      condition: questionConditions.find(
        (condition) => condition.questionId === question.id
      ),
      questionConditionsReferencedBy: questionConditions.filter(
        (condition) =>
          condition.questionOptions.some(
            (option) => option.questionId === question.id
          ) ||
          condition.includeOtherInQuestionIds.some((id) => id === question.id)
      ),
      stepConditionsReferencedBy: stepConditions.filter(
        (condition) =>
          condition.questionOptions.some(
            (option) => option.questionId === question.id
          ) ||
          condition.includeOtherInQuestionIds.some((id) => id === question.id)
      ),
      isReferencedByOfferRule: conditionedQuestionIds.includes(question.id),
      isOtherSpecifyReferencedByOfferRule:
        conditionedOtherSpecifyQuestionIds.includes(question.id),
      optionIdsReferencedByOfferRules: (
        (questionVersion.question_option_ids || []) as number[]
      ).filter((id) => conditionedQuestionOptionIds.includes(id)),
      isEnabled: enabled,
      isHidden: hidden,
      isSatisfied: questionIsSatisfied(question, questionAnswers, flowVersion),
    };
  });
}
