import { faEye } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import qs from "qs";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { createGlobalStyle } from "styled-components";

import completeImage from "../../../assets/preview-flow-complete.svg";
import env from "../../../common/env";
import StandardLinkButton from "../../../common/StandardLinkButton";
import useQueryParams from "../../../common/useQueryParams";

const MESSAGE_CLOSED = "closed";
const MESSAGE_COMPLETE = "complete";

const GlobalStyles = createGlobalStyle`
  html, body, #root {
    height: 100%;
  }
`;

const ShareFlowPreview: React.FunctionComponent = () => {
  const { token } = useParams<{ token: string }>();
  const [isClosed, setIsClosed] = useState(false);
  const [isRestarting, setIsRestarting] = useState(false);

  const query = useQueryParams();

  const newQuery = qs.stringify({
    previewMode: "true",
    previewSegments: query.get("segments"),
    previewCustomProperties: query.get("properties"),
    previewVersion: query.get("version"),
  });

  useEffect(() => {
    const listener = (e: MessageEvent) => {
      if (e.origin !== env("REACT_APP_APP_URL")) {
        return;
      }

      switch (e.data.message) {
        case MESSAGE_COMPLETE:
          if (e.data.close) {
            setIsClosed(true);
          }
          break;

        case MESSAGE_CLOSED:
          setIsClosed(true);
          break;
      }
    };

    window.addEventListener("message", listener);

    return () => {
      window.removeEventListener("message", listener);
    };
  }, []);

  const handleClickRestart = () => {
    setIsRestarting(true);
    window.location.reload();
  };

  return (
    <>
      <GlobalStyles />
      <div tw="w-full h-full">
        <div tw="bg-gray-50 h-[44px] flex items-center justify-center text-gray-400 text-sm font-semibold uppercase border-b border-divider">
          <FontAwesomeIcon icon={faEye} tw="mr-2" /> Flow preview
        </div>
        {!isClosed ? (
          <>
            <iframe
              tw="w-full h-[calc(100vh - 44px)]"
              title="Flow Preview"
              src={`${env("REACT_APP_APP_URL")}/p/flow/${token}?` + newQuery}
            />
          </>
        ) : (
          <div tw="w-full h-[calc(100vh - 44px)] flex flex-col items-center justify-center">
            <img
              src={completeImage}
              alt="Flow preview complete"
              tw="w-[400px]"
            />
            <div tw="max-w-[350px] text-center">
              <div tw="text-xl font-title font-semibold">Preview complete</div>
              <div tw="text-type mt-2">
                You've completed this flow preview.{" "}
                <StandardLinkButton
                  onClick={handleClickRestart}
                  disabled={isRestarting}
                >
                  Restart the flow
                </StandardLinkButton>{" "}
                to see it again.
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ShareFlowPreview;
