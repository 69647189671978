import { PropertyTag } from "../../../common/editor/lib/PropertyTagPlugin/types";

export default function propertyTagLabel(tag: PropertyTag) {
  switch (tag) {
    case "first_name":
      return "First Name";

    case "last_name":
      return "Last Name";

    case "email":
      return "Email";

    case "custom_property":
      return "Custom Property";

    case "segment_property":
      return "Segment Property";

    default:
      throw new Error(`Invalid property tag: ${tag}`);
  }
}
