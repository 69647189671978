import { gql, useMutation, useQuery } from "@apollo/client";
import { faRightFromBracket } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Route, Switch, useHistory, useParams } from "react-router-dom";

import {
  CustomerPortalHomeDeleteSessionMutation,
  CustomerPortalHomeDeleteSessionMutationVariables,
  CustomerPortalHomeQuery,
  CustomerPortalSubscriptionFragment,
} from "../../../__generated__/graphql";
import CustomerPortalPortalSessionFragment from "../../../common/fragments/CustomerPortalPortalSessionFragment";
import Spinner from "../../../common/Spinner";
import StandardLinkButton from "../../../common/StandardLinkButton";
import Header from "./Header";
import Subscription from "./Subscription";
import Subscriptions from "./Subscriptions";

const CustomerPortalHome: React.FunctionComponent = () => {
  const history = useHistory();
  const { clientId, sessionToken } = useParams<{
    clientId: string;
    sessionToken: string;
  }>();
  const { data, loading } = useQuery<CustomerPortalHomeQuery>(gql`
    query CustomerPortalHomeQuery {
      portal_session {
        ...CustomerPortalPortalSessionFragment
      }
    }
    ${CustomerPortalPortalSessionFragment}
  `);

  const [deleteSession] = useMutation<
    CustomerPortalHomeDeleteSessionMutation,
    CustomerPortalHomeDeleteSessionMutationVariables
  >(gql`
    mutation CustomerPortalHomeDeleteSessionMutation($sessionToken: String!) {
      update_portal_session(
        where: { token: { _eq: $sessionToken } }
        _set: { deleted_at: now }
      ) {
        affected_rows
      }
    }
  `);

  const portalSession = data?.portal_session.length
    ? data.portal_session[0]
    : undefined;

  const logOut = useCallback(
    async (clearSession: boolean) => {
      if (clearSession) {
        await deleteSession({
          variables: {
            sessionToken,
          },
        });
      }

      localStorage.removeItem(`session-${clientId}`);

      if (clearSession) {
        if (portalSession?.return_url) {
          window.location.href = portalSession.return_url;
        } else {
          history.replace(`/p/portal/${clientId}`);
        }

        return;
      }

      history.replace(`/p/portal/${clientId}/expired`);
    },
    [clientId, deleteSession, history, portalSession, sessionToken]
  );

  useEffect(() => {
    if (!loading && !portalSession) {
      logOut(false);
    }
  }, [loading, logOut, portalSession]);

  const account = portalSession?.account;
  const platform = account?.platform_connection?.platform;

  const logoUrl = account?.logo_url || account?.default_flow?.logo_url;

  const subscribers =
    portalSession?.portal_session_subscribers.map((s) => s.subscriber) || [];

  const subscriptions: CustomerPortalSubscriptionFragment[] = [];

  for (const subscriber of subscribers) {
    for (const subscription of subscriber.subscriptions) {
      subscriptions.push(subscription);
    }
  }

  return !subscribers.length ? (
    <Spinner context="page" />
  ) : (
    <>
      <Helmet>
        <title>{account?.title && `Billing - ${account.title}`}</title>
        {account?.favicon_url && (
          <link rel="icon" href={account.favicon_url}></link>
        )}
      </Helmet>
      <Header logoUrl={logoUrl} companyName={account?.title} />
      <Switch>
        <Route
          path="/p/portal/:clientId/session/:sessionToken/subscriptions/:subscriptionId"
          render={(props) =>
            !platform ? (
              <Spinner />
            ) : (
              <Subscription
                clientId={clientId}
                sessionToken={sessionToken}
                platformSubscriptionId={props.match.params["subscriptionId"]}
                platform={platform}
              />
            )
          }
        />
        <Route
          render={() =>
            !platform ? (
              <Spinner />
            ) : (
              <Subscriptions
                clientId={clientId}
                sessionToken={sessionToken}
                platformCustomerId={subscribers[0].platform_id}
                subscriptions={subscriptions}
                platform={platform!}
              />
            )
          }
        />
      </Switch>

      <div tw="mt-6 text-center">
        <StandardLinkButton
          tw="text-gray-600 hover:text-gray-900 hover:no-underline"
          onClick={() => logOut(true)}
        >
          Log out <FontAwesomeIcon icon={faRightFromBracket} tw="ml-1" />
        </StandardLinkButton>
      </div>
    </>
  );
};

export default CustomerPortalHome;
