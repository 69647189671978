import gql from "graphql-tag";

import FlowFormVersionFragment from "./FlowFormVersionFragment";

export default gql`
  fragment FlowFormFragment on form {
    id
    title
    published_version {
      ...FlowFormVersionFragment
    }
    draft_version @include(if: $includeDrafts) {
      ...FlowFormVersionFragment
    }
  }
  ${FlowFormVersionFragment}
`;
